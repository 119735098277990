import React from 'react'
import './styles/notification-delivery-builder.scss'
import { Well } from '@pushly/aqe/lib/components'
import Icon, { InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Switch, Popover, Input, Select, Skeleton } from 'antd'
import { NotificationExperienceModel } from '../../../models/notification/notification-experience.model'
import { calculateValueFromRawTtlSeconds } from '../../data-form-elements'
import { calculateRawSecondsFromValue, calculateValueFromRawSeconds } from '../../simple-timeframe/simple-timeframe'
import { getClassNames } from '../../../_utils/classnames'
import { DomainDto } from '../../../dtos/domain'
import { getVariantTtlConfig } from '../helpers'
import { FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE, FEAT_SILENT_DELIVERY_NOTIF } from '../../../constants'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../../stores/app'

interface INotificationDeliveryBuilder {
    className?: string
    domain: DomainDto
    disabled?: boolean
    value?: NotificationExperienceModel | any
    onChange?: (value: NotificationExperienceModel) => any
    loading?: boolean
    title?: string
    hideHeading?: boolean
    hideTtlSettings?: boolean
}

const NotificationExperienceOptions = (props: INotificationDeliveryBuilder) => {
    const appState = useService(AppState)
    const { className, domain, loading, disabled, value, onChange, title, hideHeading, hideTtlSettings } = props
    const [tmpValue, setTmpValue] = React.useState<NotificationExperienceModel>(
        NotificationExperienceModel.build(value ?? {}),
    )

    const workingModel = (!!value ? value : tmpValue).clone()

    const domainHasRIFlag = domain.flags?.includes(FEAT_NOTIF_REQUIRE_INTERACTION_TOGGLE)
    const ttlConfig = getVariantTtlConfig(domain, workingModel)
    const calculatedTtl = calculateValueFromRawSeconds(ttlConfig.seconds, ttlConfig.metric)

    const isSilentFlag = appState.flags.findActive(FEAT_SILENT_DELIVERY_NOTIF)
    const hasIsSilentFlag = isSilentFlag?.getIsActive() && domain.flags?.includes(isSilentFlag.getKey())

    return (
        <>
            <Well
                className={getClassNames('notification-experience-builder', className)}
                title={title ?? 'Delivery'}
                mode="ghost"
                hideHeader={hideHeading}
                hideFooter={true}
            >
                <Skeleton loading={loading} active={true} avatar={false} title={false}>
                    {hasIsSilentFlag && (
                        <div className="switch-row">
                            <div className="switch-left">
                                <Switch
                                    size="small"
                                    checked={workingModel.getIsSilent()}
                                    onChange={(isSilent) => {
                                        const update = workingModel.clone()
                                        update.setIsSilent(isSilent)

                                        setTmpValue(update)
                                        onChange?.(update)
                                    }}
                                />
                            </div>
                            <div className="switch-right">
                                <span>Silent Delivery</span>
                                <Popover
                                    overlayClassName={getClassNames(
                                        'notification-builder-popover',
                                        'is-silent-popover',
                                    )}
                                    content={
                                        <span>
                                            When enabled, this notification will be delivered with no alert sound or
                                            vibration on supported mobile devices.
                                        </span>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </div>
                        </div>
                    )}

                    {domainHasRIFlag && (
                        <div className="switch-row">
                            <div className="switch-left">
                                <Switch
                                    size="small"
                                    disabled={disabled}
                                    checked={workingModel.getRequireInteraction()}
                                    onChange={(requireInteraction) => {
                                        const update = workingModel.clone()
                                        update.setRequireInteraction(requireInteraction)

                                        setTmpValue(update)
                                        onChange?.(update)
                                    }}
                                />
                            </div>
                            <div className="switch-right">
                                <span>Require Interaction</span>
                                <Popover
                                    overlayClassName={getClassNames(
                                        'notification-builder-popover',
                                        'require-interaction-popover',
                                    )}
                                    content={
                                        <span>
                                            When disabled, this notification will minimize to the notification center
                                            after approximately 8 seconds. Chrome for Android is not affected as all
                                            notifications are minimized to the notification tray regardless of this
                                            setting.
                                        </span>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </div>
                        </div>
                    )}

                    {!hideTtlSettings && (
                        <Form.Item
                            className="lifespan"
                            label={
                                <span className="lifespan-label">
                                    Lifespan
                                    <Popover
                                        overlayClassName={getClassNames('notification-builder-popover', 'ttl-popover')}
                                        content={
                                            <div>
                                                <p>
                                                    Notification lifespan determines how long the notification will
                                                    attempt to be delivered to the subscriber.
                                                </p>
                                                <p>
                                                    <b>Example:</b> A notification with a lifespan of 1 day will not be
                                                    delivered to a user who does not become eligible for delivery within
                                                    24 hours of the notification being sent.
                                                </p>
                                            </div>
                                        }
                                    >
                                        <InfoCircleOutlined className="info-icon" />
                                    </Popover>
                                </span>
                            }
                        >
                            <Input
                                placeholder="0"
                                disabled={disabled}
                                defaultValue={calculatedTtl}
                                onChange={(ev) => {
                                    let v: any = ev.target.value
                                    const update = workingModel.clone()

                                    try {
                                        v = calculateRawSecondsFromValue(v, workingModel.getTtlMetric())
                                    } catch (_) {}
                                    update.setTtlSeconds(v)

                                    setTmpValue(update)
                                    onChange?.(update)
                                }}
                                addonAfter={
                                    <Select
                                        dropdownClassName="lifespan-metric-overlay"
                                        disabled={disabled}
                                        value={workingModel.getTtlMetric() ?? 'days'}
                                        onChange={(metric) => {
                                            const update = workingModel.clone()
                                            const previousTtl = calculateValueFromRawTtlSeconds(
                                                workingModel.getTtlSeconds(),
                                                workingModel.getTtlMetric(),
                                            )

                                            update.setTtlMetric(metric)
                                            try {
                                                update.setTtlSeconds(calculateRawSecondsFromValue(previousTtl, metric))
                                            } catch (_) {}

                                            setTmpValue(update)
                                            onChange?.(update)
                                        }}
                                    >
                                        <Select.Option value="minutes">Minute(s)</Select.Option>
                                        <Select.Option value="hours">Hour(s)</Select.Option>
                                        <Select.Option value="days">Day(s)</Select.Option>
                                    </Select>
                                }
                            />
                        </Form.Item>
                    )}
                </Skeleton>
            </Well>
        </>
    )
}

export default NotificationExperienceOptions
