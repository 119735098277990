import * as React from 'react'
import { TwitterPicker } from 'react-color'
import { Popover } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import './color-picker.scss'
import { Container } from 'typescript-ioc/es5'
import { AppService } from '../../services/index'
import autobind from 'autobind-decorator'
import { isFunction } from '../../_utils/utils'

interface IProps {
    value?: string
    onChange?: Function
    className?: string
}

interface IState {
    color: any
}

class ColorPickerPopoverButton extends BetterComponent<IProps, IState> {
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)

        this.state = {
            color: this.props.value,
        }
    }

    public render(): React.ReactNode {
        const classNames: string[] = ['color-popover']
        if (this.props.className) {
            classNames.push(this.props.className)
        }

        return (
            <div className={classNames.join(' ')}>
                <Popover
                    overlayClassName={classNames.map((name) => `${name}-overlay`).join(' ')}
                    content={<ColorPicker value={this.state.color} onChange={this.handleOnChange} />}
                    trigger="click"
                    getPopupContainer={this.appService.getAppContainer}
                >
                    <span
                        className="color-popover-btn"
                        style={{
                            backgroundColor: this.state.color,
                        }}
                    >
                        {this.props.children}
                    </span>
                </Popover>
            </div>
        )
    }

    @autobind
    private handleOnChange(color: any): void {
        this.setState({ color })

        if (this.props.onChange && isFunction(this.props.onChange)) {
            this.props.onChange(color)
        }
    }
}

export class ColorPicker extends BetterComponent<IProps, IState> {
    public static get Button(): typeof ColorPickerPopoverButton {
        return ColorPickerPopoverButton
    }

    public constructor(props: IProps) {
        super(props)

        this.state = {
            color: {
                hex: this.props.value,
            },
        }
    }

    public render(): React.ReactNode {
        return <TwitterPicker color={this.state.color.hex} onChangeComplete={this.handleColorChange} />
    }

    @autobind
    private handleColorChange(color: any): void {
        this.setState({ color })

        if (this.props.onChange && isFunction(this.props.onChange)) {
            this.props.onChange(color.hex)
        }
    }
}
