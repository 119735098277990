import * as React from 'react'
import styled from 'styled-components'
import { INode as IFCNode, INodeDefaultProps } from '@mrblenny/react-flow-chart'
import { CampaignEditableState, NodeType } from './enums'
import { getClassNames } from '../../_utils/classnames'

interface INode {
    getEditableState: () => CampaignEditableState
    onClick: (node: IFCNode) => void
}

export const Node = (props: INode) => {
    const CustomNode = styled.div.attrs({
        className: getClassNames('campaign-canvas-node'),
    })<any>``

    return React.forwardRef(({ node, children, ...otherProps }: INodeDefaultProps, ref: React.Ref<HTMLDivElement>) => {
        return (
            <CustomNode
                ref={ref}
                {...otherProps}
                className={getClassNames(null, {
                    [`node-${node.id}`]: true,
                    [`type-${node.type}`]: !!node.type,
                    [`condition-type-${node.properties?.[NodeType.CONDITION]?.type}`]:
                        !!node.properties?.[NodeType.CONDITION],
                    [`outgoing-ports-${Object.keys(node.ports).length - 1}`]: true,
                    ['ineditable']: props.getEditableState() !== CampaignEditableState.EDITABLE,
                })}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.onClick(node)
                }}
            >
                {children}
            </CustomNode>
        )
    })
}
