import React from 'react'

const AmplyIcon = React.memo((props: { className?: string; animateProps?: any }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
        viewBox="70 85 850 850"
        data-icon="amply-icon"
        height="1em"
        width="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path d="M527.57,136.87c1-1,2-2,3-2.94l-43.7-44-1.45,1.42c-232.71,232.71-232.71,610,0,842.7l40.47-40.47C318.16,684,318.7,345.74,527.57,136.87Z" />
        <path d="M603.42,212c.79-.8,1.61-1.57,2.41-2.36l-35-35.19c-.38.39-.78.76-1.16,1.14-186.17,186.16-186.17,488,0,674.16l32.37-32.38C435.89,649.76,436.32,379.13,603.42,212Z" />
        <path d="M679.26,287.2c.6-.6,1.23-1.14,1.83-1.74l-26.23-26.4c-.29.29-.59.55-.88.84-139.63,139.62-139.63,366,0,505.62l24.29-24.3C553.63,615.48,553.94,412.53,679.26,287.2Z" />

        {props.animateProps && <animate {...props.animateProps} />}
    </svg>
))

export default AmplyIcon
