import axios from 'axios'
import { Container, Singleton } from 'typescript-ioc/es5'
import { action } from 'mobx'
import { UserDto } from '../dtos/user'
import { IApiResponseSuccess } from '../models/api-response-success'
import { AppState } from '../stores/app'
import { AppService } from './app'
import { sendGAEvent } from '../components/ga-helper/ga-helper'
import { axiosFetch } from '../config/axios-setup'
import aqe from '@pushly/aqe'
import { shouldUseLegacyAuthHeader } from '../_utils/utils'
import { PushlyCookie } from '../enums/pushly-cookie.enum'
import { buildLogoutRoute, buildRouteFromHostname } from '../_utils/routing'
import { getPlatformIdentitySnapshot } from '../providers/platform-identity-provider/platform-identity-provider'
import { APP_BASE_ROUTE } from '../constants'

@Singleton
export class AuthService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    @action.bound
    public async login(email: string, password: string) {
        const response = await axios.post<IApiResponseSuccess<UserDto> & { token: string }>(
            `${aqe.defaults.publicApiDomain}/auth/login`,
            { email, password },
            {
                bypassGlobalErrorHandlers: true,
            } as any,
        )
        const responseData = response.data

        if (shouldUseLegacyAuthHeader()) {
            window.localStorage.setItem(PushlyCookie.PLATFORM_COOKIE, responseData.token)
        }

        this.appState.currentUser = UserDto.fromApiResponse(responseData.data)

        await this.appState.fetchUserDependencies()

        this.appState.isAuthenticated = true
        sendGAEvent('set_current_user', {}, this.appState)
        this.appService.reset404State()
    }

    @action.bound
    public async logout(expired: boolean = false) {
        this.appService.setAppLoading()

        try {
            if (!expired) {
                await axiosFetch(
                    'post',
                    {
                        url: `${aqe.defaults.publicApiDomain}/auth/logout`,
                    },
                    'logout',
                )
            }
        } catch (error) {
            throw new Error(error.message)
        }

        let label = this.appState?.whiteLabelSettings
        /**
         * Currently, the only known case of appState being undefined is particular
         * API calls being made at the same time as a MissingAuthToken/ExpireAuthToken error
         * triggers a logout.
         *
         * There may be other cases where {appState.whiteLabelSettings} are unavailable and
         * the snapshot from PlatformIdentityProvider HOC should be used.
         */
        if (!label) {
            const snapshot = getPlatformIdentitySnapshot()
            if (snapshot.loaded) {
                label = snapshot.whiteLabelSettings
            }
        }

        if (!label) {
            console.error('whitelabel settings not found')
            location.assign(APP_BASE_ROUTE)
        }

        this.appService.stopFetchingPlatformMessages()
        this.appService.reset404State()

        if (shouldUseLegacyAuthHeader()) {
            window.localStorage.removeItem(PushlyCookie.PLATFORM_COOKIE)
        }

        let redirectBase = buildRouteFromHostname(label?.getHostname()!)
        if (expired) {
            localStorage.setItem('refreshRedirectURI', location.pathname)
        }

        const nextRoute = buildLogoutRoute(label?.getAuth0HostedLoginDomain()!, true)
        const nextHref = [
            `${nextRoute}?client_id=${label?.getAuth0PlatformClientId()}`,
            `returnTo=${redirectBase}`,
        ].join('&')

        this.appState.currentUser = undefined
        this.appState.currentDomainJsonData = undefined
        this.appState.isAuthenticated = false
        this.appState.abilityStore.flush()

        location.assign(nextHref)
    }
}
