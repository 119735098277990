import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { AppService } from '../../../services'
import { DomainDto } from '../../../dtos/domain'
import { PromptDto } from '../../../dtos/prompt'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import './native-prompt-data-form.scss'

interface IProps extends FormComponentProps {
    prompt: PromptDto
    onFieldChange?: any
}

interface IState {
    domain?: DomainDto
}

@observer
export class SystemPromptDataForm extends BetterFormComponent<IProps, IState> {
    public state: IState = {}

    private appState: AppState
    private appService: AppService
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        return this.renderStyleEditorForm()
    }

    private renderStyleEditorForm(): React.ReactNode {
        return ''
    }

    private setDomainState(): void {
        this.setState({ domain: this.appState.currentDomain })
    }
}
