import axios, { AxiosResponse } from 'axios'
import aqe from '@pushly/aqe'
import { Singleton } from 'typescript-ioc/es5'
import { IUserPermissionsResponse } from '../interfaces/user-permissions'
import AccountUserModel from '../models/access-control/account-user.model'
import DomainUserModel from '../models/access-control/domain-user.model'
import { IApiCallOptions, IApiResponse } from '@pushly/aqe/lib/interfaces'
import { AppService } from './app'
import { Container } from 'typescript-ioc/es5'
import { ApiCallOptions } from '@pushly/aquarium-utils/lib/react/index'
import { axiosFetch } from '../config/axios-setup'
import { IAccessPolicy } from '../interfaces/access-policy'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

export interface IPermissionsChangeSet<T> {
    dirty: T
    clean?: T
}

const emptyPermissionsResponse: IUserPermissionsResponse = {
    accounts: [],
    domains: [],
}

@Singleton
class PermissionsService {
    protected readonly appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async loadUserPermissions(id: number): Promise<IApiResponse<IUserPermissionsResponse>> {
        let ok = false
        let data: IUserPermissionsResponse = emptyPermissionsResponse
        let meta: any = {}
        let cancelled: boolean = false
        let error: Error | undefined

        try {
            const res = await axios.get(`${aqe.defaults.publicApiDomain}/v2/users/${id}/permissions`)

            data = {
                accounts: res.data.data?.accounts ?? emptyPermissionsResponse.accounts,
                domains: res.data.data?.domains ?? emptyPermissionsResponse.domains,
            }
            ok = true
        } catch (err) {
            if (process.env.IS_LOCAL) {
                console.debug('permissions_error', err)
            }

            error = err
        }

        return { ok, data, meta, error, cancelled }
    }

    public async updateAccountUserPermissions(
        id: number,
        changeRecords: IPermissionsChangeSet<AccountUserModel>[],
        opts: IApiCallOptions = {},
    ) {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const endpoint = `${aqe.defaults.publicApiDomain}/v2/users/${id}/permissions/accounts`

        let createdRecords: AccountUserModel[] = []
        let updatedRecords: AccountUserModel[] = []
        const createSets = changeRecords.filter((set) => !set.clean || !set.dirty.getId())
        const updateSets = changeRecords.filter((set) => set.clean && set.dirty.getId())

        if (createSets.length) {
            try {
                const res = await axios.post(
                    endpoint,
                    createSets.map((set) => set.dirty.serialize()),
                )
                if (res.data.data) {
                    createdRecords = res.data.data.map(AccountUserModel.fromCached)
                }
            } catch (err) {
                throw err
            }
        }

        if (updateSets.length) {
            try {
                const res = await axios.patch(
                    endpoint,
                    updateSets.map((set) => set.dirty.serialize()),
                )
                if (res.data.data) {
                    updatedRecords = res.data.data.map(AccountUserModel.fromCached)
                }
            } catch (err) {
                throw err
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return [...createdRecords, ...updatedRecords]
    }

    public async updateDomainUserPermissions(
        id: number,
        changeRecords: IPermissionsChangeSet<DomainUserModel>[],
        opts: IApiCallOptions = {},
    ) {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const endpoint = `${aqe.defaults.publicApiDomain}/v2/users/${id}/permissions/domains`

        let createdRecords: DomainUserModel[] = []
        let updatedRecords: DomainUserModel[] = []
        const createSets = changeRecords.filter((set) => !set.clean || !set.dirty.getId())
        const updateSets = changeRecords.filter((set) => set.clean && set.dirty.getId())

        if (createSets.length) {
            try {
                const res = await axios.post(
                    endpoint,
                    createSets.map((set) => set.dirty.serialize()),
                )
                if (res.data.data) {
                    createdRecords = res.data.data.map(DomainUserModel.fromCached)
                }
            } catch (err) {
                throw err
            }
        }

        if (updateSets.length) {
            try {
                const res = await axios.patch(
                    endpoint,
                    updateSets.map((set) => set.dirty.serialize()),
                )
                if (res.data.data) {
                    updatedRecords = res.data.data.map(DomainUserModel.fromCached)
                }
            } catch (err) {
                throw err
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return [...createdRecords, ...updatedRecords]
    }

    public async fetchUserRoleAccessPolicies(
        userId: number,
        opts: ApiCallOptions = {},
    ): Promise<IApiResponse<(IAccessPolicy & { userRoleIds: number[] })[]>> {
        let ok = false
        let data: any[] = []
        let meta: any = {}
        let cancelled = false
        let error

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/users/${userId}/user-role-access-policies`,
                },
                opts.cancellationKey,
            )

            const { data: userRolePolicies, ...resMeta } = res.data

            ok = true
            data = userRolePolicies
            meta = resMeta
        } catch (err) {
            error = err
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data, meta, cancelled, error }
    }
}

export default PermissionsService
