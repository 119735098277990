import { Container, Singleton } from 'typescript-ioc/es5'
import * as querystring from 'query-string'
import { AppService } from './app'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { AppState } from '../stores/app'
import IApiCallOptions from '../interfaces/api-call-options'
import aqe from '@pushly/aqe'

@Singleton
export class DomainIntegrationService {
    public appService: AppService
    public appState: AppState

    public constructor() {
        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
    }

    public async fetchAll(domainId: number, opts: IApiCallOptions = {}): Promise<IServiceApiResponse<any[]>> {
        const res = {
            ok: false,
            data: [],
            meta: {},
            error: undefined,
            cancellation: false,
        }

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/integrations`,
                },
                opts?.cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData ?? []
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return res
    }

    public async fetchById(domainId: number, id: number, cancellationKey?: string): Promise<IServiceApiResponse<any>> {
        const res = {
            ok: false,
            data: undefined,
            meta: {},
            error: undefined,
            cancellation: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/integrations/${id}`,
                },
                cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        return res
    }

    public async updateById(
        domainId: number,
        id: number,
        update: any,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any>> {
        const res = {
            ok: false,
            data: undefined,
            meta: {},
            error: undefined,
            cancellation: false,
        }

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/integrations/${id}`,
                    data: update,
                },
                cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        return res
    }

    public async fetchIntegrationRedirectUrl(
        provider: string,
        domainId: number,
        data: any = {},
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<{ authUrl: string } | undefined>> {
        const res = {
            ok: false,
            data: undefined,
            meta: {},
            error: undefined,
            cancellation: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${
                        aqe.defaults.publicApiDomain
                    }/domains/${domainId}/integrations/providers/${provider}?${querystring.stringify(data ?? {})}`,
                    data,
                },
                cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        return res
    }

    public async completeIntegrationSetup(
        domainId: number,
        provider: string,
        oauthParams: any,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any>> {
        const res = {
            ok: false,
            data: undefined,
            meta: {},
            error: undefined,
            cancellation: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/integrations/providers/${provider}/oauth/connect`,
                    config: {
                        params: oauthParams,
                    },
                },
                cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        return res
    }

    public async removeIntegration(
        domainId: number,
        id: number,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any>> {
        const res = {
            ok: false,
            data: undefined,
            meta: {},
            error: undefined,
            cancellation: false,
        }

        try {
            const req = await axiosFetch(
                'delete',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/integrations/${id}`,
                },
                cancellationKey,
            )
            const { data: reqData, ...reqMeta } = req.data

            res.ok = true
            res.data = reqData
            res.meta = reqMeta
        } catch (err) {
            if (isAxiosCancellation(err)) {
                res.cancellation = true
            }
            res.error = err
        }

        return res
    }
}
