import * as React from 'react'
import * as changeCase from 'change-case'
import * as moment from 'moment-timezone'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Tooltip } from 'antd'
import { Cell as FDCell } from 'fixed-data-table-2'
import { arrayUnique, numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { BASE_TIME_ONLY_FORMAT, BASE_TIME_ONLY_FORMAT_WITHOUT_TZ, SHORT_DATE_FORMAT } from '../../../constants'
import { AppState } from '../../../stores/app'
import titleCase from 'title-case'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const DELIVERY_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        selectable: false,
        className: 'r-record-date',
        width: 140,
        columnKey: 'send_date',
        title: (reportConfig: any, colConfig: any) => {
            return reportConfig.level === 'CAMPAIGN' ? (
                <>
                    <span>Event Date</span>
                    <Tooltip title={<>The date the campaign event took place in the domain's time zone.</>}>
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </>
            ) : (
                <>
                    <span>Send Date</span>
                    <Tooltip title={<>The date the notification was sent in the domain's time zone.</>}>
                        <InfoCircleOutlined className="info-icon" />
                    </Tooltip>
                </>
            )
        },
        align: 'center',
        sortable: true,
        render(data: any, dataSet: any[], config: any) {
            const columnKey = config.level === 'CAMPAIGN' ? 'event_date' : 'send_date'

            let value = moment(data[columnKey]).format(SHORT_DATE_FORMAT)

            if (config.dateIncrement === 'MONTH') {
                value = moment(data[columnKey]).format('MMM, YYYY')
            } else if (config.dateIncrement === 'YEAR') {
                value = moment(data[columnKey]).format('YYYY')
            }

            return value
        },
    },
    ['NOTIFICATION.ID']: {
        className: 'r-id',
        width: 120,
        columnKey: 'notification.id',
        title: 'Notification ID',
        sortable: true,
        sortType: 'number',
    },
    ['PLACEMENT']: {
        selectable: false,
        className: 'r-title',
        width: 140,
        columnKey: 'placement',
        title: 'Device Type',
        sortable: true,
        render(data: any) {
            return changeCase.title(data.placement)
        },
    },
    ['BROWSER']: {
        selectable: false,
        className: 'r-browser',
        width: 140,
        columnKey: 'browser',
        title: 'Browser',
        sortable: true,
    },
    ['OPERATING_SYSTEM']: {
        selectable: false,
        className: 'r-os',
        width: 140,
        columnKey: 'operating_system',
        title: 'Operating System',
        sortable: true,
    },
    ['NOTIFICATION.CREATED_BY']: {
        selectable: false,
        className: 'r-creator',
        width: 140,
        columnKey: 'notification.created_by',
        title: 'Created By',
        sortable: false,
    },
    ['NOTIFICATION.AUDIENCES']: [
        {
            selectable: false,
            className: 'r-audiences',
            width: 240,
            columnKey: 'notification.audiences.targeted',
            title: 'Audiences Targeted',
            fixed: false,
            sortable: true,
            sort(a: any, b: any) {
                const aRecord = a.notification.audiences.targeted || []
                const bRecord = b.notification.audiences.targeted || []
                const extractor = (aud: any) => {
                    let label = 'All Subscribers'

                    if (aud.type && aud.type === 'subscriber_id') {
                        label = 'Subscriber IDs'
                    } else if (aud.label) {
                        label = aud.label.toUpperCase() === 'ALL' ? 'All Subscribers' : aud.label
                    }

                    return label
                }

                const aValue = arrayUnique(aRecord.map(extractor)).join(', ')
                const bValue = arrayUnique(bRecord.map(extractor)).join(', ')

                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
            },
            render(data: any, dataSet: any[]) {
                const audiences = data.notification.audiences.targeted || []
                const audiencesNames = arrayUnique(
                    audiences.map((aud: any) => {
                        let label = 'All Subscribers'

                        if (aud.type && aud.type === 'subscriber_id') {
                            label = 'Subscriber IDs'
                        } else if (aud.label) {
                            label = aud.label.toUpperCase() === 'ALL' ? 'All Subscribers' : aud.label
                        }

                        return label
                    }),
                ).join(', ')

                if (audiencesNames.length > 36 || audiences.length > 1) {
                    return (
                        <div>
                            <Tooltip
                                overlayClassName="report-col-value-overlay"
                                trigger="hover"
                                title={audiences.map((aud: any) => (
                                    <div key={aud.label}>
                                        <NoTranslate>{aud.label}</NoTranslate>
                                    </div>
                                ))}
                            >
                                <span className="has-hover-info">{audiences.length} Audiences</span>
                            </Tooltip>
                        </div>
                    )
                }

                return audiencesNames
            },
        },
        {
            selectable: false,
            className: 'r-audiences',
            width: 240,
            columnKey: 'notification.audiences.excluded',
            title: 'Audiences Excluded',
            fixed: false,
            sortable: true,
            sort(a: any, b: any) {
                const aRecord = a.notification.audiences.excluded || []
                const bRecord = b.notification.audiences.excluded || []
                const extractor = (aud: any) => aud.label

                const aValue = arrayUnique(aRecord.map(extractor)).join(', ')
                const bValue = arrayUnique(bRecord.map(extractor)).join(', ')

                return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
            },
            render(data: any, dataSet: any[]) {
                const audiences = data.notification.audiences.excluded || []

                const defaultValue = { label: 'No Exclusions' }
                if (audiences.length === 0) {
                    audiences.push(defaultValue)
                }

                const audiencesNames = audiences.map((aud: any) => aud.label).join(', ')

                if (audiencesNames.length > 36 || audiences.length > 1) {
                    return (
                        <div>
                            <Tooltip
                                overlayClassName="report-col-value-overlay"
                                trigger="hover"
                                title={audiences.map((aud: any) => (
                                    <div key={aud.label}>
                                        <NoTranslate>{aud.label}</NoTranslate>
                                    </div>
                                ))}
                            >
                                <span className="has-hover-info">{audiences.length} Audiences</span>
                            </Tooltip>
                        </div>
                    )
                }

                return audiencesNames
            },
        },
    ],
    ['BUTTON.ID']: {
        className: 'r-id',
        width: 120,
        columnKey: 'button.id',
        title: 'Button ID',
        sortable: true,
        sortType: 'number',
    },
    ['BUTTON.TYPE']: {
        className: 'r-btn-type',
        width: 120,
        columnKey: 'button.type',
        title: 'Button Type',
        sortable: true,
        sortType: 'string',
        render(data: any, dataSet: any[]) {
            return data.button.type === 'CLOSE' ? 'Close' : 'Open URL'
        },
    },
    ['BUTTON.LABEL']: {
        className: 'r-btn-label',
        width: 120,
        columnKey: 'button.label',
        title: 'Button Label',
        sortable: true,
        sortType: 'string',
    },
    ['BUTTON.LANDING_URL']: {
        className: 'r-btn-url',
        width: 120,
        columnKey: 'button.landing_url',
        title: 'Button Landing URL',
        sortable: true,
        sortType: 'string',
    },
    ['NOTIFICATION.BADGE_URL']: {
        selectable: false,
        className: 'r-icon',
        width: 64,
        columnKey: 'notification.badge_url',
        title: 'Badge',
        align: 'center',
        render(data: any, dataSet: any[]) {
            const badgeUrl = data.notification.badge_url

            return !badgeUrl ? (
                ''
            ) : (
                <div
                    className="row-image-thumb no-pop"
                    style={{
                        backgroundImage: `url(${badgeUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                />
            )
        },
    },
    ['NOTIFICATION.BODY']: {
        selectable: false,
        className: 'r-body',
        width: 240,
        columnKey: 'notification.body',
        title: 'Body',
        sortable: true,
        render(data: any) {
            const body = data.notification.body || ''

            return !!body ? (
                <span>
                    {body.length > 28 ? (
                        <Tooltip
                            overlayClassName="report-col-value-overlay"
                            trigger="hover"
                            title={<NoTranslate>{body}</NoTranslate>}
                        >
                            <span className="content">
                                <NoTranslate>{body}</NoTranslate>
                            </span>
                        </Tooltip>
                    ) : (
                        <NoTranslate>{body}</NoTranslate>
                    )}
                </span>
            ) : (
                <NoTranslate>{body}</NoTranslate>
            )
        },
    },
    ['CAMPAIGN.ID']: {
        selectable: false,
        className: 'r-campaign-id',
        width: 120,
        columnKey: 'campaign.id',
        title: 'Campaign ID',
        sortable: true,
        render(data: any, dataSet: any[]) {
            const campaign = data.campaign || {}
            return campaign.id || 'No Campaign'
        },
    },
    ['CAMPAIGN.NAME']: {
        selectable: false,
        className: 'r-campaign-name',
        width: 240,
        columnKey: 'campaign.name',
        title: 'Campaign Name',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.campaign || {}
            const bRecord = b.campaign || {}

            const aValue = aRecord.name || '-1'
            const bValue = bRecord.name || '-1'

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[]) {
            const campaign = data.campaign || {}
            return <NoTranslate>{campaign.name || 'No Campaign'}</NoTranslate>
        },
    },
    ['NOTIFICATION.ICON_URL']: {
        selectable: false,
        className: 'r-icon',
        width: 64,
        columnKey: 'notification.icon_url',
        title: 'Icon',
        align: 'center',
        render(data: any, dataSet: any[]) {
            const iconUrl = data.notification.icon_url

            return !iconUrl ? (
                ''
            ) : (
                <div
                    className="row-image-thumb no-pop"
                    style={{
                        backgroundImage: `url(${iconUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                />
            )
        },
    },
    ['NOTIFICATION.IMAGE_URL']: {
        selectable: false,
        className: 'r-image',
        width: 64,
        columnKey: 'notification.image_url',
        title: 'Image',
        align: 'center',
        render(data: any, dataSet: any[]) {
            const imageUrl = encodeURI(data.notification.image_url)

            return !imageUrl ? (
                ''
            ) : (
                <Popover content={<img src={imageUrl} width={250} />} trigger={'click'}>
                    <div
                        className="row-image-thumb"
                        style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    />
                </Popover>
            )
        },
    },
    ['NOTIFICATION.KEYWORDS']: {
        selectable: false,
        className: 'r-icon',
        width: 240,
        columnKey: 'notification.keywords',
        title: 'Keywords',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.notification.keywords || []
            const bRecord = b.notification.keywords || []

            const aValue = aRecord.join(', ')
            const bValue = bRecord.join(', ')

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[]) {
            const keywords = data.notification.keywords || []
            const keywordsStr = keywords.join(', ')

            return keywords.length > 0 ? (
                <span>
                    {keywordsStr.length > 28 || keywords.length > 3 ? (
                        <Tooltip
                            overlayClassName="report-col-value-overlay"
                            trigger="hover"
                            title={keywords.map((kw: string) => (
                                <div key={kw}>
                                    <NoTranslate>{kw}</NoTranslate>
                                </div>
                            ))}
                        >
                            {keywords.length} Keywords
                        </Tooltip>
                    ) : (
                        keywordsStr
                    )}
                </span>
            ) : (
                <span>No Keywords</span>
            )
        },
    },
    ['NOTIFICATION.LANDING_URL']: {
        selectable: false,
        className: 'r-landing-url',
        width: 280,
        columnKey: 'notification.landing_url',
        title: 'Landing URL',
        sortable: true,
        render(data: any) {
            const url = data.notification.landing_url

            return !!url ? (
                <span>
                    {url.length > 28 ? (
                        <Tooltip overlayClassName="report-col-value-overlay" trigger="hover" title={url}>
                            <a href={url} target="_blank">
                                {url}
                            </a>
                        </Tooltip>
                    ) : (
                        <a href={url} target="_blank">
                            {url}
                        </a>
                    )}
                </span>
            ) : (
                <span>No Destination</span>
            )
        },
    },
    ['NOTIFICATION.LIFESPAN']: {
        selectable: false,
        className: 'r-lifespan',
        width: 80,
        columnKey: 'notification.lifespan',
        title: 'Lifespan',
        sortable: true,
    },
    ['NOTIFICATION.DELIVERY_STRATEGY']: {
        selectable: false,
        className: 'r-strategy',
        width: 150,
        columnKey: 'notification.delivery_strategy',
        title: 'Delivery Strategy',
        sortable: true,
    },
    ['TEST.ID']: {
        selectable: false,
        className: 'r-test-id',
        width: 100,
        columnKey: 'test.id',
        title: 'A/B Test ID',
        sortable: true,
        sortType: 'number',
        sort(a: any, b: any) {
            const aRecord = a.test || {}
            const bRecord = b.test || {}

            const aValue = aRecord.id || -1
            const bValue = bRecord.id || -1

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[]) {
            const test = data.test || (data.notification || {}).test || {}
            return test.id
        },
    },
    ['TEST.NAME']: {
        selectable: false,
        className: 'r-test-name',
        width: 240,
        columnKey: 'test.name',
        title: 'A/B Test Name',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.test || {}
            const bRecord = b.test || {}

            const aValue = aRecord.name || '-1'
            const bValue = bRecord.name || '-1'

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[]) {
            const test = data.test || (data.notification || {}).test || {}
            return <NoTranslate>{test.name}</NoTranslate>
        },
    },
    ['TEST.CREATED_BY']: {
        selectable: false,
        className: 'r-creator',
        width: 140,
        columnKey: 'test.created_by',
        title: 'Created By',
        sortable: false,
    },
    ['NOTIFICATION.WEIGHT']: {
        selectable: false,
        className: 'r-test-weight',
        width: 100,
        columnKey: 'notification.weight',
        title: 'Test Weight',
        align: 'center',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return data.notification.weight || '--'
        },
    },
    ['NOTIFICATION.SCHEDULED_TIME']: {
        selectable: false,
        className: 'r-send-date',
        width: 180,
        columnKey: 'notification.scheduled_time',
        title: 'Scheduled Time',
        align: 'center',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.notification
            const bRecord = a.notification

            const aValue = aRecord.source === 'CAMPAIGN' ? moment().subtract(999, 'd') : moment(aRecord.scheduled_time)
            const bValue = bRecord.source === 'CAMPAIGN' ? moment().subtract(999, 'd') : moment(bRecord.scheduled_time)

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[], appState: AppState) {
            let timestamp = moment.utc(data.notification.scheduled_time)
            let timeDisplay

            if (data.notification.scheduled_time_zone === 'STZ') {
                timeDisplay = (
                    <>
                        {timestamp.format(BASE_TIME_ONLY_FORMAT_WITHOUT_TZ)}
                        <Tooltip title="Subscriber's Time Zone">
                            <span className="stz-label">STZ</span>
                        </Tooltip>
                    </>
                )
            } else if (data.notification.scheduled_time_zone === 'INF') {
                timeDisplay = (
                    <>
                        {timestamp.format(BASE_TIME_ONLY_FORMAT_WITHOUT_TZ)}
                        <Tooltip title="Informed Delivery">
                            <span className="stz-label">INF</span>
                        </Tooltip>
                    </>
                )
            } else {
                timestamp = moment.tz(data.notification.scheduled_time, data.notification.scheduled_time_zone)
                timeDisplay = timestamp.format(BASE_TIME_ONLY_FORMAT)
            }

            return data.notification.source !== 'CAMPAIGN' ? (
                <>
                    <div className="r-send-date-date">{timestamp.format(SHORT_DATE_FORMAT)}</div>
                    <div className="r-send-date-time">{timeDisplay}</div>
                </>
            ) : (
                <>
                    <div className="r-send-date-date">
                        <Tooltip title={data.notification.scheduled_time}>
                            <span className="stz-label">Campaign Schedule</span>
                        </Tooltip>
                    </div>
                    <div className="r-send-date-time"></div>
                </>
            )
        },
    },
    ['STEP.ID']: {
        selectable: false,
        className: 'r-step-id',
        width: 120,
        columnKey: 'step.id',
        title: 'Campaign Step ID',
        sortable: true,
        render(data: any, dataSet: any[]) {
            const step = data.step || {}
            return step.id || 'No Campaign'
        },
    },
    ['NOTIFICATION.TITLE']: {
        selectable: false,
        className: 'r-title',
        width: 240,
        columnKey: 'notification.title',
        render(data: any, dataSet: any[], config: any, appState: AppState) {
            const notificationId = data.notification.id

            return (
                <a href={`/domains/${appState.currentDomain!.id}/notifications/${notificationId}`} target="_blank">
                    <NoTranslate>{data.notification.title}</NoTranslate>
                </a>
            )
        },
        title: 'Title',
        sortable: true,
    },
    ['ENTRANCES']: {
        selectable: false,
        className: 'r-entrances',
        width: 120,
        columnKey: 'entrances',
        title: 'Entrances',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.entrances)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.entrances).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['DELIVERIES']: {
        selectable: false,
        className: 'r-deliveries',
        width: 120,
        columnKey: 'deliveries',
        title: 'Deliveries',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.deliveries)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.deliveries).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['IMPRESSIONS']: {
        selectable: false,
        className: 'r-impressions',
        width: 120,
        columnKey: 'impressions',
        title: 'Impressions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.impressions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CLICKS']: {
        selectable: false,
        className: 'r-clicks',
        width: 120,
        columnKey: 'clicks',
        title: 'Clicks',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.clicks)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CTR_DECIMAL']: {
        selectable: false,
        className: 'r-ctr',
        width: 120,
        columnKey: 'ctr_decimal',
        requiredColumnKeys: ['clicks', 'impressions'],
        title: 'CTR',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.impressions ? '--' : numberWithPercent(data.ctr_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggImps = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggClicks = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            const avgCTR = !aggImps ? '--' : numberWithPercent(aggClicks / aggImps)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgCTR}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['DELIVERY_RATE_DECIMAL']: {
        selectable: false,
        className: 'r-delivery-rate',
        width: 120,
        columnKey: 'delivery_rate_decimal',
        requiredColumnKeys: ['deliveries', 'impressions'],
        title: 'Impression Rate',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.deliveries ? '--' : numberWithPercent(data.delivery_rate_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggDeliveries = dataSet.map((s) => s.deliveries).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggImps = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)

            const avgDR = !aggDeliveries ? '--' : numberWithPercent(aggImps / aggDeliveries)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgDR}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['NOTIFICATIONS_SENT']: {
        selectable: false,
        className: 'r-notifications-sent',
        width: 120,
        columnKey: 'notifications_sent',
        title: 'Notifications Sent',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.notifications_sent)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.notifications_sent).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PURCHASES']: {
        selectable: false,
        className: 'r-purchases',
        width: 120,
        columnKey: 'purchases',
        title: 'Purchases',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.purchases)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.purchases).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PURCHASE_AMOUNT']: {
        selectable: false,
        className: 'r-purchase_amount',
        width: 120,
        columnKey: 'purchase_amount',
        title: 'Purchase Amount',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.purchase_amount
                ? '--'
                : Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(data.purchase_amount)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.purchase_amount).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">
                        {!data ? '--' : Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(data)}
                    </div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PURCHASE_RATE_DECIMAL']: {
        selectable: false,
        className: 'r-purchase_rate_decimal',
        width: 150,
        columnKey: 'purchase_rate_decimal',
        title: () => (
            <>
                <span>Purchase Rate</span>
                <Tooltip title={<>Purchase to Clicks Rate</>}>
                    <InfoCircleOutlined className="info-icon" />
                </Tooltip>
            </>
        ),
        requiredColumnKeys: ['purchases', 'clicks'],
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.purchase_rate_decimal ? '--' : numberWithPercent(data.purchase_rate_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggPurchases = dataSet
                .map((s) => (s.purchases !== 0 ? s.purchases : 0))
                .reduce((n: number, v: number) => n + (v || 0), 0)
            const aggClicks = dataSet
                .map((s) => (s.purchases !== 0 ? s.clicks : 0))
                .reduce((n: number, v: number) => n + (v || 0), 0)

            const avgPPC = !aggPurchases ? '--' : numberWithPercent(aggPurchases / aggClicks)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgPPC}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['SEGMENT_LAST_TARGETED_DATE_TZ']: {
        selectable: false,
        className: 'r-last_targeted_date_tz',
        width: 150,
        columnKey: 'last_targeted_date_tz',
        title: 'Last Targeted Date',
        sortable: true,
        render(data: any) {
            const columnKey = 'last_targeted_date_tz'
            return moment(data.segment[columnKey]).format(SHORT_DATE_FORMAT)
        },
    },
    ['SIZE']: {
        selectable: false,
        className: 'r-size',
        width: 120,
        columnKey: 'size',
        title: 'Size',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.size)
        },
        footer: (data, { ...props }) => {
            const dataSizes = data.map((s) => s.size)
            const total = dataSizes.reduce((a, b) => a + (b || 0), 0)
            return (
                <FDCell {...props}>
                    <div className="footer-primary">{!data ? '--' : numberWithCommas(total)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['SEGMENT.ID']: {
        selectable: false,
        className: 'r-segment_id',
        width: 120,
        columnKey: 'segment.id',
        title: 'Segment ID',
        sortable: true,
        sortType: 'number',
    },
    ['SEGMENT.NAME']: {
        selectable: false,
        className: 'r-segment_name',
        width: 120,
        columnKey: 'segment.name',
        title: 'Segment Name',
        sortable: true,
        sortType: 'string',
    },
    ['CLICKS_PER_SEND']: {
        selectable: false,
        className: 'r-cps',
        width: 120,
        columnKey: 'cps',
        requiredColumnKeys: ['notifications_sent'],
        title: () => (
            <>
                <span>CPS</span>
                <Tooltip title={<>Clicks Per Send</>}>
                    <InfoCircleOutlined className="info-icon" />
                </Tooltip>
            </>
        ),
        sortable: true,
        sortType: 'number',
        render: (data: any) => numberWithCommas(data.clicks_per_send),
        footer: (data, { ...props }) => {
            const dataSizes = data.map((s) => s.clicks_per_send)
            const total = dataSizes.reduce((a, b) => a + (b || 0), 0)
            const allNotifsSent = data.map((d) => d.notifications_sent).reduce((a, b) => a + b, 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{!data ? '--' : numberWithCommas(total / allNotifsSent)}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['NOTIFICATION_SOURCE']: {
        align: 'center',
        selectable: false,
        className: 'r-notification-source',
        width: 150,
        columnKey: 'ns',
        title: 'Source',
        sortable: true,
        sortType: 'string',
        render: (data: any) => titleCase(data.notification.source),
    },
    ['KEYWORD']: {
        align: 'left',
        selectable: false,
        className: 'r-keyword',
        width: 240,
        columnKey: 'kn',
        requiredColumnKeys: ['keyword'],
        title: 'Keyword',
        sortable: true,
        sortType: 'string',
        render: (data: any) => titleCase(data.keyword),
    },
    ['CHANNEL']: {
        align: 'left',
        selectable: false,
        classname: 'r-channel',
        width: 120,
        columnKey: 'ch',
        title: 'Delivery Channel',
        sortable: false,
        render: (data: any) => DeliveryChannel.getShortName(data.channel) ?? 'Unknown',
    },
    ['NOTIFICATION.TARGETED_CHANNELS']: {
        align: 'left',
        selectable: false,
        classname: 'r-targeted_channels',
        width: 120,
        columnKey: 't-ch',
        title: 'Targeted Channels',
        sortable: false,
        render: (data: any) =>
            data.notification.targeted_channels.map((ch) => DeliveryChannel.getShortName(ch)).join(', '),
    },
}
