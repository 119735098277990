import * as React from 'react'
import * as moment from 'moment'
import { BetterComponent } from '../better-component/better-component'
import { observer } from 'mobx-react'
import { observe } from 'mobx'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc'
import { AppService } from '../../services/index'
import { PlatformMessageDto } from '../../dtos/platform-message'
import { PlatformMessageItem } from './platform-message-item'
import './platform-message-bar.scss'
import { APPLICATION_VERSION } from '../../constants'

interface IProps {}
interface IState {
    messages: PlatformMessageDto[]
}

@observer
export class PlatformMessageBar extends BetterComponent<IProps, IState> {
    public state: IState = {
        messages: [],
    }

    private appState: AppState
    private appService: AppService
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount(): void {
        this.disposeObservers = [
            observe(this.appState, 'currentDomainJsonData', () => this.updatePlatformMessages()),
            observe(this.appState, 'platformMessages', () => this.updatePlatformMessages()),
            observe(this.appState, 'userPlatformMessages', () => this.updatePlatformMessages()),
        ]
    }

    public componentWillUnmount(): void {
        this.unmounting = true
    }

    public render(): React.ReactNode {
        const { messages } = this.state
        const hasMessages = messages && messages.length > 0

        const classNames: string[] = ['platform-message-bar']
        const shadowClassNames: string[] = ['platform-message-bar-shadow']
        if (hasMessages) {
            classNames.push('has-messages')
            shadowClassNames.push('has-messages')
        }

        const standardMessages = messages.filter((msg) => !msg.minVersionReq && !msg.maintenanceMode)
        const maintenanceMessages = messages.filter(
            (msg) => !!msg.maintenanceMode && (!msg.endTime || moment(msg.endTime) > moment()),
        )
        const refreshMessages = messages.filter((msg) => !!msg.minVersionReq && msg.minVersionReq > APPLICATION_VERSION)

        if (!!maintenanceMessages && maintenanceMessages.length > 0) {
            // SORT DESC BY minVersionReq
            maintenanceMessages.sort((msgA, msgB) => {
                return msgA.startTime! < msgB.startTime! ? 1 : msgA.startTime! > msgB.startTime! ? -1 : 0
            })

            const maintenanceMessage = maintenanceMessages[0]

            return (
                <div className="platform-maintenance-message">
                    <PlatformMessageItem key={maintenanceMessage.id} {...maintenanceMessage} isMaintenanceMode={true} />
                </div>
            )
        }

        if (!!refreshMessages && refreshMessages.length > 0) {
            // SORT DESC BY minVersionReq
            refreshMessages.sort((msgA, msgB) => {
                return msgA.minVersionReq! < msgB.minVersionReq!
                    ? 1
                    : msgA.minVersionReq! > msgB.minVersionReq!
                    ? -1
                    : 0
            })

            const refreshMessage = refreshMessages[0]

            return (
                <div className="platform-refresh-message">
                    <PlatformMessageItem key={refreshMessage.id} {...refreshMessage} isRefreshMode={true} />
                </div>
            )
        }

        const msgComponents = standardMessages.map((msg) => <PlatformMessageItem key={msg.id} {...msg} />)

        return (
            <React.Fragment>
                <div className={classNames.join(' ')}>{msgComponents}</div>
                <div className={shadowClassNames.join(' ')}>{msgComponents}</div>
            </React.Fragment>
        )
    }

    private updatePlatformMessages(): void {
        this.setState({
            messages: this.appService.getCurrentPlatformMessages(),
        })
    }
}
