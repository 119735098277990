import * as React from 'react'
import './ttl-element.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Select } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { getClassNames } from '../../../_utils/classnames'

type TtlMetric = 'days' | 'hours' | 'minutes' | 'seconds'

export function calculateRawTtlSecondsFromValue(value: number, metric: TtlMetric): number {
    let computed = value

    switch (metric) {
        case 'minutes':
            computed = computed * 60
            break
        case 'hours':
            computed = computed * 60 * 60
            break
        case 'days':
            computed = computed * 60 * 60 * 24
            break
    }

    return computed
}

export function calculateValueFromRawTtlSeconds(value: number, metric: TtlMetric): number {
    let computed = value

    switch (metric) {
        case 'minutes':
            computed = computed / 60
            break
        case 'hours':
            computed = computed / 60 / 60
            break
        case 'days':
            computed = computed / 24 / 60 / 60
            break
    }

    return computed
}

interface ITtlDisplayElement {
    label?: string
    entity: {
        defaultNotificationTtlSeconds: number
        displayMeta?: {
            default_notification_ttl_metric?: string
        }
    }
}

export class TtlDisplayElement extends React.PureComponent<ITtlDisplayElement, any> {
    public render() {
        const rawTtlSeconds = this.props.entity.defaultNotificationTtlSeconds ?? null
        const ttlMetric = this.props.entity.displayMeta?.default_notification_ttl_metric ?? 'days'
        const ttlValue = calculateValueFromRawTtlSeconds(rawTtlSeconds, ttlMetric as TtlMetric)

        return (
            <div className={getClassNames('form-layout-row')}>
                <div className={getClassNames('form-layout-row-label')}>{this.props.label || 'Default Lifespan'}</div>
                <div className={getClassNames('form-layout-row-value')}>
                    {rawTtlSeconds != null ? `${ttlValue} ${ttlMetric.replace(/s$/, '(s)')}` : 'No default'}
                </div>
                <div className={getClassNames('form-layout-row-sub')}>
                    <InfoCircleOutlined />
                    <span>System default is 7 days.</span>
                </div>
            </div>
        )
    }
}

interface ITtlEditorElement extends FormComponentProps {
    label?: string
    entity: {
        defaultNotificationTtlSeconds: number
        displayMeta?: {
            default_notification_ttl_metric?: string
        }
    }
}

export class TtlEditorElement extends React.Component<ITtlEditorElement, any> {
    public render() {
        const rawDefaultTtlSeconds = this.props.entity.defaultNotificationTtlSeconds
        const defaultTtlMetric = this.props.entity.displayMeta?.default_notification_ttl_metric || 'days'
        const defaultTtlValue = calculateValueFromRawTtlSeconds(rawDefaultTtlSeconds, defaultTtlMetric as TtlMetric)

        return (
            <Form.Item
                className={getClassNames('ttl-editor-element-lifespan')}
                label={this.props.label || 'Default Lifespan'}
            >
                {this.props.form.getFieldDecorator('ttlValue', {
                    initialValue: defaultTtlValue,
                })(
                    <Input
                        autoComplete="off"
                        addonAfter={this.props.form.getFieldDecorator('ttlMetric', {
                            initialValue: defaultTtlMetric,
                        })(
                            <Select>
                                <Select.Option value="minutes">minute(s)</Select.Option>
                                <Select.Option value="hours">hour(s)</Select.Option>
                                <Select.Option value="days">day(s)</Select.Option>
                            </Select>,
                        )}
                    />,
                )}
            </Form.Item>
        )
    }
}
