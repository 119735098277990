import { useRef } from 'react'

type DebounceCallback = () => any
export const useDebouncer = (debounce: number = 320): [(cb: DebounceCallback) => void, () => void] => {
    const debouncer = useRef<any>(undefined)

    const clearDebounce = () => {
        clearTimeout(debouncer.current)
    }

    const runWithDebounce = (cb: DebounceCallback) => {
        clearDebounce()
        debouncer.current = setTimeout(() => {
            cb()
        }, debounce)
    }

    return [runWithDebounce, clearDebounce]
}
