import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { AppService } from '../../services'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import './reporting.scss'
import { ReportBuilder } from './report-builder/report-builder'
import { ReportTable } from './report-table/report-table'
import { ReportStatusCode } from './enums/report-status-code'

interface IProps {}

interface IState {
    insights: any[]
    insightsConfig: any
    insightsStatus: any
    apiContract: any
}

export class ReportingV2 extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {
            insightsStatus: {},
            insightsConfig: {},
            insights: [],
            apiContract: {},
        }
    }

    public componentDidMount(): void {
        const reportId = this.queryString.reportId
        if (!!reportId) {
            this.setState({})
        }
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()
    }

    public render(): React.ReactNode {
        const { insightsStatus, insightsConfig, insights, apiContract } = this.state

        return (
            <div className="reporting-v2 page">
                <div className="report-display-wrapper">
                    <ReportBuilder
                        {...this.props}
                        onStatusUpdate={(status) => {
                            this.setState({ insightsStatus: status })
                        }}
                        onInsightsUpdate={(data, reportConfig, contract) => {
                            this.setState({
                                insights: data || [],
                                insightsConfig: reportConfig,
                                apiContract: contract,
                            })
                        }}
                    />
                    {insightsStatus.code === ReportStatusCode.ERROR ||
                    insightsStatus.code === ReportStatusCode.NO_DATA ||
                    insightsStatus.code === ReportStatusCode.NOT_READY ? (
                        <div className="message-wrapper">{insightsStatus.message}</div>
                    ) : (
                        <div className="insights-wrapper">
                            <ReportTable
                                loading={insightsStatus.code === ReportStatusCode.LOADING}
                                config={insightsConfig}
                                data={insights}
                                columns={apiContract.fields}
                                hiddenColumns={apiContract.addtRequiredFields}
                            />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
