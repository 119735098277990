import React from 'react'
import './clicks.widget.scss'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { Tooltip } from 'antd'

const PropsFragment = (props: any) => <>{props.children}</>

const ClicksWidget = ({ type, showType, yesterday, adYesterday }: any) => {
    const HoverWrapper = (yesterday.clicks ?? 0) <= 9999 ? PropsFragment : Tooltip

    return (
        <div className={`dashboard-widget clicks-widget vsplit type-${type?.toLowerCase() ?? 'content'}`}>
            <div className="dashboard-widget-content">
                <div className="widget-title">
                    <label>{showType !== false && type ? `${type} ` : ''}Clicks (CTR)</label>
                </div>
                <div className="widget-body yesterday">
                    <div className="widget-sub-title">
                        <label>Yesterday</label>
                    </div>
                    <span className="clicks">
                        <HoverWrapper title={numberWithCommas(yesterday.clicks ?? 0)}>
                            <span>
                                {yesterday.clicks === undefined
                                    ? '--'
                                    : numberWithCommas(yesterday.clicks, yesterday.clicks > 9999 ? '0.0a' : undefined)}
                            </span>
                        </HoverWrapper>
                        <span>({yesterday.ctr === 0 ? '--' : numberWithPercent(yesterday.ctr)})</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ClicksWidget
