import { convertCancellationKeyToToken } from '../config/axios-setup'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppState } from '../stores/app'
import { AppService } from './app'
import aqe from '@pushly/aqe'
import { HttpMethod } from '@pushly/aqe/lib/enums'
import { executeRequest } from '@pushly/aqe/lib/api/helpers'
import { IApiCallOptions, IApiResponse } from '@pushly/aqe/lib/interfaces'

@Singleton
export class AmplyCollectionsService {
    private readonly appState: AppState
    private readonly appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAll(opts?: IApiCallOptions): Promise<IApiResponse<any[]>> {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        await convertCancellationKeyToToken(opts)
        const res = await executeRequest<any[]>(
            HttpMethod.GET,
            `${aqe.defaults.publicApiDomain}/v3/integrations/providers/amply/collections`,
            null,
            opts ?? null,
        )

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return res
    }
}
