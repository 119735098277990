import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { Switch } from '../../components/router/router'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router'

interface IProps {}

interface IState {}

@observer
export class NotificationsFeature extends BetterComponent<IProps, IState> {
    public render() {
        return (
            <div className="module notifications">
                <ModuleLoadingScreen />

                <Switch>
                    <Redirect path={this.matchedPath} exact={true} to="/domain/notifications" />
                </Switch>
            </div>
        )
    }
}
