import * as React from 'react'
import { Redirect } from 'react-router'
import { BetterComponent } from '../../components/better-component/better-component'
import { Switch } from '../../components/router'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'

interface IProps {}

interface IState {}

export class UsersFeature extends BetterComponent<IProps, IState> {
    public render() {
        return (
            <div className="module users">
                <ModuleLoadingScreen />

                <Switch>
                    <Redirect path={this.matchedPath} exact={true} to="/domain/users" />
                </Switch>
            </div>
        )
    }
}
