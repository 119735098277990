import { IRuleBuilderSelectField } from '../../rule-builder/rule-builder'
import { baseOperators } from '../../rule-builder/operators'

export function buildLanguageCustomField(property: string, languageCodes: any[] = []) {
    let config: IRuleBuilderSelectField = {
        property,
        display: 'Device: Language',
        type: 'select',
        operators: [
            baseOperators.prefix.clone({ display: 'is' }),
            baseOperators.not_prefix.clone({ display: 'is not' }),
        ],
        options: languageCodes.map((data) => ({
            value: data.code,
            name: data.name,
        })),
        extra: {
            showSearch: true,
            optionFilterProp: 'label',
        },

        ruleTransformIn: (rule) => {
            let operator = baseOperators.prefix.value
            if (rule[property]?.[baseOperators.not_prefix.value]) {
                operator = baseOperators.not_prefix.value
            }

            const value = rule[property]?.[operator]
            const meta = rule[property]

            // ensure displayValue for rules without one - eg: loaded rules initial state
            if (value && !meta?.displayValue) {
                const code = languageCodes.find((lc) => lc.code === value)
                rule[property].meta = {
                    ...rule[property].meta,
                    displayValue: code.name,
                }
            }

            return rule
        },

        ruleTransformOut: (rule) => {
            let operator = baseOperators.prefix.value
            if (rule[property]?.[baseOperators.not_prefix.value]) {
                operator = baseOperators.not_prefix.value
            }

            const value = rule[property]?.[operator]

            // override existing displayValue
            if (value) {
                const code = languageCodes.find((lc) => lc.code === value)
                rule[property].meta = {
                    ...rule[property].meta,
                    displayValue: code.name,
                }
            }

            return rule
        },
    }

    return config
}
