import * as React from 'react'
import './segment-comparison.scss'
import { PageHeader } from '../page-header/page-header'
import { useService } from '../../hooks/use-service'
import { AppState } from '../../stores/app'
import { DownloadOutlined, SearchOutlined, EllipsisOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons'
import { numberWithCommas, numberWithPercent } from '../../_utils/numeral'
import { Select, Statistic, Button, Result, Divider, Dropdown, Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { Well, MatrixView, ComparisonView } from '@pushly/aqe/lib/components'
import { SegmentModel } from '../../models/segments/segment.model'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { SegmentService } from '../../services/segment'
import { AppService, DomainService } from '../../services'
import { InsightsService } from '../../services/insights'
import { ClicksColumnView } from '../clicks-column-view/clicks-column-view'
import { ISegmentStats, IStatsResponse } from './interfaces'
import SegmentComparisonDto from './comparison-dto'
import { fmtDateString, roundToNearest } from './utils'
import { equalArrays } from '../../_utils/array'
import { useActiveDomainChangeEffect } from '../../hooks/use-active-domain-change-effect'
import { DomainDto } from '../../dtos/domain'
import { AsyncButton } from '../async-button/async-button.component'
import { AbilityAction } from '../../enums/ability-action.enum'

const MAX_SELECTIONS = 5
const EMPTY_DATASET = []
const EMPTY_STATS = {
    genStats: [],
    overlaps: [],
}

function getIdCombinations(ids: number[]) {
    const uqIds = Array.from(new Set(ids))
    return uqIds.reduce<[number, number][]>((val, idA) => {
        return val.concat(uqIds.filter((idB) => idB !== idA).map((idB) => [idA, idB]))
    }, [])
}

const EXPORT_DETAILS_FILENAME = 'segment-details'

function buildInsightsPack(ids: number[], override: any = {}) {
    const pack: any = {
        entity: 'segments',
        date_preset: 'last_30_days',
        date_increment: 'lifetime',
        breakdowns: ['segment'],
        fields: ['segment.id', 'clicks', 'clicks_per_send', 'ctr', 'ctr_decimal'],
        filters: [
            {
                field: 'segment.id',
                operator: 'in',
                value: ids,
            },
        ],
        ...override,
    }

    return pack
}

interface ISegmentComparisonProps {}

interface ITabbedViewProps extends ISegmentComparisonProps {
    isActiveTab: boolean
}

function SegmentComparison(props: ISegmentComparisonProps) {
    const tabbedViewProps = props as ITabbedViewProps

    useActiveDomainChangeEffect((dm, svc) => {
        svc.routeWithin('domain', '/segments')
    })

    const appState = useService(AppState)
    const appSvc = useService(AppService)
    const domainSvc = useService(DomainService)
    const segmentSvc = useService(SegmentService)
    const insightsSvc = useService(InsightsService)

    const domain = appState.currentDomain!

    const [prevDomain, setPrevDomain] = React.useState<DomainDto | undefined>()
    const [segments, setSegments] = useLoadableDataState<SegmentModel[]>({ loading: false, data: EMPTY_DATASET })
    const [stats, setStats] = useLoadableDataState<IStatsResponse>({ loading: false, data: EMPTY_STATS })
    const [selectedSegments, setSelectedSegments] = React.useState<SegmentModel[]>([])
    const [segmentSelectValue, setSegmentSelectValue] = React.useState<number[]>([])

    function resetAllStates(resetSegments: boolean = false) {
        if (resetSegments) {
            setSegments({ loading: true, data: EMPTY_DATASET })
        }

        setStats({ loading: false, data: EMPTY_STATS })
        setSelectedSegments(EMPTY_DATASET)
        setSegmentSelectValue(EMPTY_DATASET)
    }

    const segmentIds = segments.data!.map((s) => s.getId())
    const selectedIds = selectedSegments.map((s) => s.getId())
    const statsIds = Array.from(new Set(stats.data!.overlaps.map((s) => s.segmentA.id)))

    // --------------------------- Dropdown Options ---------------------------
    const segmentOptions = React.useMemo(() => {
        const options = segments.data!.map((s) => ({
            value: s.getId(),
            label: s.getName(),
            title: `${s.getId()} ${s.getName()}`,
            disabled: segmentSelectValue.length >= MAX_SELECTIONS && !segmentSelectValue.includes(s.getId()),
        }))

        options.sort((a, b) => {
            const aLabel = a.label.toLowerCase()
            const bLabel = b.label.toLowerCase()
            return aLabel > bLabel ? 1 : aLabel < bLabel ? -1 : 0
        })

        return options
    }, [domain?.id, segmentIds, segmentSelectValue])

    // --------------------------- Data Source ---------------------------
    const dataSource: SegmentComparisonDto[] = React.useMemo(() => {
        const dtos: SegmentComparisonDto[] = []

        if (selectedSegments && selectedSegments.length) {
            selectedSegments.forEach((segment) => {
                dtos.push({
                    segment,
                    stats:
                        stats.data!.genStats.find((s: any) => s.segment.id === segment.getId()) ??
                        ({} as ISegmentStats),
                    reachOverlaps: stats.data!.overlaps.filter((s) => s.segmentA.id === segment.getId()) ?? [],
                })
            })
        }

        dtos.sort((a, b) => {
            const aName = a.segment.getName().toLowerCase()
            const bName = b.segment.getName().toLowerCase()
            return aName > bName ? 1 : aName < bName ? -1 : 0
        })

        return dtos
    }, [domain?.id, segmentIds, stats])

    // --------------------------- Fetch Segments ---------------------------
    React.useEffect(() => {
        const fetchSegments = async () => {
            resetAllStates(true)

            const { ok, data } = await domainSvc.fetchSegmentsByDomainId(domain.id, {
                query: {
                    includeDefault: false,
                    pagination: 0,
                    source: 'standard',
                },
                showLoadingScreen: true,
                cancellationKey: 'segment-compare',
            })

            if (ok && data) {
                setSegments({
                    loading: false,
                    data: data.map(SegmentModel.build),
                })
            }
        }

        if (!tabbedViewProps.isActiveTab) {
            // clear view state
            resetAllStates()
        } else if (domain?.id !== prevDomain?.id) {
            // update last used domain
            setPrevDomain(domain)
            // fetch assets
            fetchSegments()
        }
    }, [domain?.id, prevDomain?.id, tabbedViewProps.isActiveTab])

    // --------------------------- Fetch Segment Stats ---------------------------
    const fetchSegmentStats = React.useCallback(
        async (ids: number[]) => {
            if (ids.length >= 2) {
                setStats((curr) => ({ ...curr, loading: true }))

                const state: any = {
                    loading: false,
                    data: EMPTY_STATS,
                }

                const uqIds = Array.from(new Set(ids))
                const combinations = getIdCombinations(ids)

                await Promise.all([
                    segmentSvc
                        .fetchEstimatedReachWithOverlap(domain.id, combinations, {
                            cancellationKey: 'segment-compare-stats',
                        })
                        .then(({ ok, data }) => {
                            if (ok && data) {
                                state.data.overlaps = data
                            }
                        }),
                    insightsSvc
                        .fetch(buildInsightsPack(uqIds), false, 'segment-compare-gen-stats', false)
                        .then((data) => {
                            if (data) {
                                state.data.genStats = data
                            }
                        }),
                ])

                setStats(state)
            }
        },
        [domain?.id],
    )

    // --------------------------- Handle Compare Action ---------------------------
    const handleCompareActionClick = React.useCallback(async () => {
        const nextSelections = segmentSelectValue.map((id) => segments.data!.find((s) => s.getId() === id)!)
        const containsNewIds = segmentSelectValue.some((id) => !selectedIds.includes(id) && !statsIds.includes(id))

        setSelectedSegments(nextSelections)

        if (segmentSelectValue.length >= 2 && containsNewIds) {
            fetchSegmentStats(segmentSelectValue)
        }
    }, [domain?.id, segmentSelectValue, selectedIds, statsIds])

    // --------------------------- Handle Export Action ---------------------------
    const handleExportClick = React.useCallback(
        async (type: 'all' | 'details' | 'overlap') => {
            if (type === 'details') {
                await insightsSvc.fetch(
                    buildInsightsPack(segmentSelectValue, {
                        fields: [
                            'segment.id',
                            'segment.name',
                            'segment.last_targeted_date_tz',
                            'clicks',
                            'ctr',
                            'clicks_per_send',
                            'size',
                        ],
                        format: 'csv',
                        filename: EXPORT_DETAILS_FILENAME,
                    }),
                    false,
                    'segment-compare-gen-stats',
                    false,
                )
            } else {
                const combinations = getIdCombinations(segmentSelectValue)
                await segmentSvc.fetchEstimatedReachWithOverlap(domain.id, combinations, {
                    query: {
                        response_format: type === 'all' ? 'xlsx' : 'csv',
                        filename: type === 'all' ? 'segment-insights' : 'segment-overlaps',
                        include_details: type === 'all' ? 1 : 0,
                    },
                    cancellationKey: 'segment-compare-stats',
                })
            }
        },
        [domain?.id, segmentSelectValue],
    )

    // --------------------------- Handle Details Header Action ---------------------------
    const handleDetailsHeaderActionClick = React.useCallback(
        ({ key, item, domEvent }: MenuInfo) => {
            const segment: SegmentModel = (item as any).props['data-segment']
            domEvent.persist()
            const metaKey = domEvent.metaKey
            let finalLocation: string | undefined

            if (key === 'view') {
                finalLocation = appSvc.routeWithin('domain', `/segments/${segment.getId()}/summary`, true)
            } else if (key === 'edit') {
                finalLocation = appSvc.routeWithin('domain', `/segments/${segment.getId()}`, true)
            }

            if (finalLocation) {
                if (metaKey) {
                    window.open(finalLocation)
                } else {
                    appSvc.route(finalLocation)
                }
            }
        },
        [domain?.id],
    )

    const resultsLoaded = !segments.loading && !stats.loading
    const resultsViewCanShow = selectedIds.length >= 2

    return (
        <React.Fragment>
            <PageHeader
                browserTitle="Segment Comparison"
                title={
                    <React.Fragment>
                        <span className="segment-comparison-select-wrapper ant-input-group-wrapper ant-input-search ant-input-search-with-button">
                            <span className="ant-input-wrapper ant-input-group">
                                <span className="ant-input-affix-wrapper">
                                    <Select<number[]>
                                        className="segment-comparison-select"
                                        disabled={segments.loading}
                                        mode="multiple"
                                        placeholder="Select segments"
                                        optionFilterProp="title"
                                        options={segmentOptions}
                                        maxTagCount={MAX_SELECTIONS}
                                        maxTagTextLength={12}
                                        value={segmentSelectValue}
                                        onChange={(ids) => setSegmentSelectValue(ids)}
                                    />
                                </span>
                                <span className="ant-input-group-addon">
                                    <Button
                                        className="ant-input-search-button"
                                        type="primary"
                                        onClick={handleCompareActionClick}
                                        disabled={equalArrays(segmentSelectValue, selectedIds)}
                                    >
                                        <SearchOutlined />
                                    </Button>
                                </span>
                            </span>
                        </span>
                    </React.Fragment>
                }
                action={
                    resultsViewCanShow && (
                        <AsyncButton
                            key="export"
                            disabled={stats.loading}
                            onClick={() => handleExportClick('all')}
                            actions={[
                                {
                                    text: 'Export Details',
                                    onClick: () => handleExportClick('details'),
                                },
                                {
                                    text: 'Export Overlap',
                                    onClick: () => handleExportClick('overlap'),
                                },
                            ]}
                        >
                            <DownloadOutlined />
                            <span>Export</span>
                        </AsyncButton>
                    )
                }
            />

            <Divider />

            {!resultsViewCanShow ? (
                <Result title="Please select at least two segments to view results." />
            ) : (
                <React.Fragment>
                    <Well
                        title="Comparison Details"
                        className="type-2 nested details-well"
                        mode="ghost"
                        hideFooter={true}
                    >
                        <ComparisonView
                            dataSource={dataSource}
                            colKey={(dto) => dto.segment.getId()}
                            loading={!resultsLoaded}
                        >
                            <ComparisonView.HeaderRow
                                dataIndex={['segment', 'name']}
                                align="center"
                                render={(name, { segment }: SegmentComparisonDto) => {
                                    return (
                                        <React.Fragment>
                                            {name}

                                            <div className="comparison-details-header-actions">
                                                <Dropdown
                                                    className="comparison-details-header-actions-dropdown"
                                                    overlayClassName="comparison-details-header-actions-overlay"
                                                    trigger={['click']}
                                                    placement="bottomRight"
                                                    overlay={
                                                        <Menu onClick={handleDetailsHeaderActionClick}>
                                                            <Menu.Item
                                                                key="view"
                                                                data-segment-id={segment.getId()}
                                                                data-segment={segment}
                                                            >
                                                                <EyeOutlined />
                                                                <span>View</span>
                                                            </Menu.Item>
                                                            {appState.abilityStore.can(
                                                                AbilityAction.UPDATE,
                                                                segment,
                                                            ) && (
                                                                <Menu.Item
                                                                    key="edit"
                                                                    data-segment-id={segment.getId()}
                                                                    data-segment={segment}
                                                                >
                                                                    <EditOutlined />
                                                                    <span>Edit</span>
                                                                </Menu.Item>
                                                            )}
                                                        </Menu>
                                                    }
                                                >
                                                    <EllipsisOutlined />
                                                </Dropdown>
                                            </div>
                                        </React.Fragment>
                                    )
                                }}
                            />
                            <ComparisonView.Row
                                dataIndex={['segment', 'lastTargetedDateUtc']}
                                title="Last Targeted"
                                align="center"
                                render={(lastTargeted) =>
                                    lastTargeted === null ? '--' : fmtDateString(lastTargeted, 'll')
                                }
                            />
                            <ComparisonView.Row
                                className="clicks-ctr"
                                dataIndex={['stats', 'clicks']}
                                title={
                                    <React.Fragment>
                                        <div>Clicks / (CTR)</div>
                                        <div className="sub">Over the last 30 days</div>
                                    </React.Fragment>
                                }
                                align="center"
                                render={(clicks, dto: SegmentComparisonDto) => {
                                    return (
                                        <ClicksColumnView
                                            clicks={clicks}
                                            ctrDecimal={dto.stats.ctr_decimal}
                                            expanded={true}
                                        />
                                    )
                                }}
                            />
                            <ComparisonView.Row
                                className="clicks-per-send"
                                dataIndex={['stats', 'clicks_per_send']}
                                title={
                                    <React.Fragment>
                                        <div>Avg Clicks per Send</div>
                                        <div className="sub">Over the last 30 days</div>
                                    </React.Fragment>
                                }
                                align="center"
                                render={(clicksPerSend) => <ClicksColumnView clicks={clicksPerSend} expanded={true} />}
                            />
                            <ComparisonView.Row
                                dataIndex={['reachOverlaps']}
                                title="Segment Size"
                                align="center"
                                render={(overlaps, { segment }: SegmentComparisonDto) => {
                                    const data = overlaps.find((ov) => ov.segmentA.id === segment.getId())
                                    const size = data?.segmentA.reach ?? 0
                                    return numberWithCommas(size)
                                }}
                            />
                        </ComparisonView>
                    </Well>

                    <Well
                        title="Segment Overlap"
                        className="type-2 nested table-well overlap-well"
                        hideFooter={true}
                        loading={!resultsLoaded}
                    >
                        <MatrixView
                            gutter={1}
                            dataSource={dataSource}
                            recordKey={(dto) => dto.segment.getId()}
                            labelIndex={['segment', 'name']}
                            valueIndex={['reachOverlaps']}
                            valueClassName={(_, [recordA, recordB]) => {
                                const overlap = recordA.reachOverlaps.find(
                                    (o) =>
                                        o.segmentA.id === recordA.segment.getId() &&
                                        o.segmentB.id === recordB.segment.getId(),
                                )
                                const overlapReachPct = overlap?.overlapReachPct ?? 0

                                return `reach-range-${roundToNearest(overlapReachPct, 5)}`
                            }}
                            valueRender={(_, [recordA, recordB]) => {
                                const overlap = recordA.reachOverlaps.find(
                                    (o) =>
                                        o.segmentA.id === recordA.segment.getId() &&
                                        o.segmentB.id === recordB.segment.getId(),
                                )
                                const overlapReach = overlap?.overlapReach ?? null
                                const overlapReachPct = overlap?.overlapReachPct ?? null

                                return (
                                    <span>
                                        {overlapReachPct === null ? (
                                            '--'
                                        ) : (
                                            <React.Fragment>
                                                <Statistic
                                                    className="reach-pct"
                                                    value={numberWithPercent(Math.abs(overlapReachPct / 100), 0)}
                                                />
                                                <span className="reach-value">
                                                    {overlapReach === null ? '--' : numberWithCommas(overlapReach)}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </span>
                                )
                            }}
                        />
                    </Well>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default SegmentComparison
