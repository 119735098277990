import * as numeral from 'numeral'
import * as moment from 'moment'

export const setupLocalization = async () => {
    let locale = (navigator as any).userLanguage || navigator.language
    locale = locale.toLowerCase()

    // Numeral
    try {
        await import(`numeral/locales/${locale}.js`)
        numeral.locale(locale)
    } catch {}

    // Moment
    try {
        await import(`moment/locale/${locale}.js`)
        moment.locale(locale)
    } catch {}
}
