import * as React from 'react'
import * as numeral from 'numeral'
import { Col, Row, Tooltip } from 'antd'
import { Well } from '../../../components/well/well'
import { CampaignDto } from '../dtos/campaign-dto'
import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { getCalculatedStatus } from '../../../components/campaign-builder/helpers/campaign'
import { titleCase } from '../../../_utils/utils'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { extractCampaignTrigger } from '../../../_utils/campaigns'
import { DomainDto } from '../../../dtos/domain'
import { getDomainCurrencyCode } from '../../../_utils/domain'

interface IProps {
    loading: boolean
    domain?: DomainDto
    campaign: CampaignDto
    stats: any
}

export class CampaignGenStatsRow extends React.PureComponent<IProps> {
    public static tabName = 'stages'
    public static tabLabel = 'Stages'

    public render(): React.ReactNode {
        const trigger = extractCampaignTrigger(this.getCampaignModel())
        const hasRevenueStats = (this.props.stats.purchases ?? 0) > 0

        return (
            <div className="gen-stats-wrapper">
                <div className="gen-stats-row">
                    <div className="gen-stat">
                        <Well
                            className="thin gen-stats-well"
                            loading={this.props.loading}
                            showHeader={false}
                            showFooter={false}
                        >
                            <div className="chart-title">Status</div>
                            <div className="stat stat-status">{this.renderStatus()}</div>
                        </Well>
                    </div>
                    <div className="gen-stat">
                        <Well
                            className="thin gen-stats-well"
                            loading={this.props.loading}
                            showHeader={false}
                            showFooter={false}
                        >
                            <div className="chart-title">Subscribers Entered</div>
                            <div className="stat stat-subs-total">{this.renderTotalSubsEntered()}</div>
                        </Well>
                    </div>
                    <div className="gen-stat">
                        <Well
                            className="thin gen-stats-well"
                            loading={this.props.loading}
                            showHeader={false}
                            showFooter={false}
                            disabled={this.isViewDisabled()}
                        >
                            <div className="chart-title">Impressions</div>
                            <div className="stat stat-impressions">{this.renderImpressions()}</div>
                        </Well>
                    </div>
                    <div className="gen-stat click-stats">
                        <Well
                            className="thin gen-stats-well"
                            loading={this.props.loading}
                            showHeader={false}
                            showFooter={false}
                            disabled={this.isViewDisabled()}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <div className="chart-title">Clicks</div>
                                    <div className="stat stat-clicks">{this.renderClicks()}</div>
                                </Col>
                                <Col span={12}>
                                    <div className="chart-title">CTR</div>
                                    <div className="stat stat-ctr">{this.renderCTR()}</div>
                                </Col>
                            </Row>
                        </Well>
                    </div>
                    {hasRevenueStats && (
                        <div className="gen-stat revenue-stats">
                            <Well
                                className="thin gen-stats-well"
                                loading={this.props.loading}
                                showHeader={false}
                                showFooter={false}
                                disabled={this.isViewDisabled()}
                            >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <div className="chart-title">Purchases</div>
                                        <div className="stat stat-purchases">{this.renderPurchases()}</div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="chart-title">Revenue</div>
                                        <div className="stat stat-revenue">{this.renderRevenue()}</div>
                                    </Col>
                                </Row>
                            </Well>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    protected renderStatus(): React.ReactNode {
        const campaign = this.props.campaign
        const statusText = getCalculatedStatus(campaign)

        return <span className={`status-${statusText.toLowerCase()}`}>{titleCase(statusText)}</span>
    }

    protected renderTotalSubsEntered(): React.ReactNode {
        const entrances = this.props.stats.entrances || 0

        return entrances > 9999 ? (
            <Tooltip trigger="hover" title={numeral(entrances).format('0,0')}>
                {numeral(entrances).format('0,0.00a')}
            </Tooltip>
        ) : (
            numeral(entrances).format('0,0')
        )
    }

    protected renderDeliveries(): React.ReactNode {
        const deliveries = this.props.stats.deliveries || 0

        return deliveries > 9999 ? (
            <Tooltip trigger="hover" title={numeral(deliveries).format('0,0')}>
                {numeral(deliveries).format('0,0.00a')}
            </Tooltip>
        ) : (
            numeral(deliveries).format('0,0')
        )
    }

    protected renderImpressions(): React.ReactNode {
        const impressions = this.props.stats.impressions

        return impressions > 9999 ? (
            <Tooltip trigger="hover" title={numeral(impressions).format('0,0')}>
                {numeral(impressions).format('0,0.00a')}
            </Tooltip>
        ) : (
            numeral(impressions).format('0,0')
        )
    }

    protected renderClicks(): React.ReactNode {
        const clicks = this.props.stats.clicks

        return clicks > 9999 ? (
            <Tooltip trigger="hover" title={numeral(clicks).format('0,0')}>
                {numeral(clicks).format('0,0.00a')}
            </Tooltip>
        ) : (
            numeral(clicks).format('0,0')
        )
    }

    protected renderCTR(): React.ReactNode {
        const ctr = this.props.stats.ctr_decimal
        return !ctr ? '--' : numeral(ctr).format('0.00%')
    }

    protected renderPurchases() {
        const purchases = this.props.stats.purchases
        const purchaseNum = numeral(purchases)

        return purchases > 9999 ? (
            <Tooltip trigger="hover" title={purchaseNum.format('0,0')}>
                {purchaseNum.format('0,0.00a')}
            </Tooltip>
        ) : (
            purchaseNum.format('0,0a')
        )
    }

    protected renderRevenue() {
        const currency = getDomainCurrencyCode(this.props.domain)
        const amount = this.props.stats.purchase_amount
        return !amount ? '--' : Intl.NumberFormat('en-us', { style: 'currency', currency }).format(amount)
    }

    protected getCampaignModel(): CampaignModel {
        return CampaignModel.build(this.props.campaign)
    }

    protected isViewDisabled(): boolean {
        const isCancelled = this.props.campaign.status === 'CANCELLED'

        const now: Moment = moment()
        const dateStart: Moment = moment(this.props.campaign.dateStart)
        const isScheduled = dateStart > now

        return isCancelled || isScheduled
    }
}
