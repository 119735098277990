import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export class NotificationAudienceModel {
    public static build(props: NotificationAudienceModel | any): NotificationAudienceModel {
        const data = props instanceof NotificationAudienceModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationAudienceModel()
        model.setDomainIds(scData.domain_ids)
        model.setSegmentIds(scData.segment_ids)
        model.setExcludedSegmentIds(scData.excluded_segment_ids)
        model.setDefaultIconUrl(scData.default_icon_url)
        model.setIsAll(data._is_all ?? scData.is_all)

        return model
    }

    private domainIds: number[] | undefined
    private segmentIds: number[] | undefined
    private excludedSegmentIds: number[] | undefined
    private defaultIconUrl?: string
    private _is_all?: boolean

    public clone(): NotificationAudienceModel {
        return NotificationAudienceModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            domainIds: this.getDomainIds(),
            segment_ids: this.getSegmentIds(),
            excluded_segment_ids: this.getExcludedSegmentIds(),
            default_icon_url: this.getDefaultIconUrl(),
            _is_all: this.getIsAll(),
        })
    }

    public getDomainIds(): number[] | undefined {
        return this.domainIds
    }
    public setDomainIds(ids: number[] | undefined): void {
        this.domainIds = !ids ? undefined : Array.from(new Set(ids))
    }

    public getSegmentIds(): number[] | undefined {
        return this.segmentIds
    }
    public setSegmentIds(ids: number[] | undefined): void {
        this.segmentIds = !ids ? undefined : Array.from(new Set(ids))
    }

    public getExcludedSegmentIds(): number[] | undefined {
        return this.excludedSegmentIds
    }
    public setExcludedSegmentIds(ids: number[] | undefined): void {
        this.excludedSegmentIds = !ids ? undefined : Array.from(new Set(ids))
    }

    public getDefaultIconUrl(): string | undefined {
        return this.defaultIconUrl
    }
    public setDefaultIconUrl(url: string | null | undefined): void {
        this.defaultIconUrl = url ?? undefined
    }

    public getIsAll(): boolean {
        return this._is_all ?? false
    }
    public setIsAll(value: boolean): void {
        this._is_all = value ?? false
    }
}
