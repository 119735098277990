import * as React from 'react'
import { Button, Well } from '@pushly/aqe/lib/components'
import { observer } from 'mobx-react'
import { CloudDownloadOutlined, DownloadOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import { Table, Input } from 'antd'
import { Container } from 'typescript-ioc/es5'
import { ColumnProps } from 'antd/lib/table'
import titleCase from 'title-case'
import moment from 'moment-timezone'
import './style/account-billing-history-well.scss'
import { AccountDto } from '../../dtos/account.dto'
import { AccountService, AppService } from '../../services/index'
import { getClassNames } from '../../_utils/classnames'
import { SUPPORTED_BILLING_CURRENCIES } from '../../constants'
import { CompoundTable } from '../aqe/compound-table/compound-table'
import { AntdTableEmptyPlaceholder } from '../aqe/antd-table-empty-placeholder/antd-table-empty-placeholder'
import { numberAsCurrency } from '../../_utils/numeral'
import { AsyncButton } from '../async-button/async-button.component'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import SwSimpleLinkPagination from '../sw-simple-link-pagination/sw-simple-link-pagination'

class RecordTableDataDto {
    public static fromAccountDto(fields: any): RecordTableDataDto {
        const dto = new RecordTableDataDto()
        Object.assign(dto, fields, {
            key: fields.id,
        })
        return dto
    }

    public readonly key: number
}

interface IColumnProps extends ColumnProps<RecordTableDataDto & any> {
    dataKey?: string
}

interface IAccounts {
    loading: boolean
    account: AccountDto
}

interface IAccountsState {
    loading: boolean
    recordsLoaded: boolean
    records: any[]
    recordsMeta: any
    sortedInfo: any
    page: number
    pageSize: number
    query?: string
    paginationLinks?: IApiResponsePaginationLinks
}

@observer
export class AccountBillingHistoryWell extends React.Component<IAccounts, IAccountsState> {
    protected appService: AppService
    protected accountService: AccountService

    public constructor(props: any) {
        super(props)

        this.appService = Container.get(AppService)
        this.accountService = Container.get(AccountService)

        this.state = {
            page: 1,
            pageSize: 15,
            loading: true,
            recordsLoaded: false,
            records: [],
            recordsMeta: {},
            sortedInfo: {
                order: 'ascend',
                columnKey: 'name',
            },
        }
    }

    public componentDidMount(): void {
        this.ensureRecordsForList()
    }

    public async ensureRecordsForList() {
        await this.fetchRecords()
    }

    public render() {
        let { sortedInfo } = this.state
        sortedInfo = sortedInfo || {}

        const buildColumn = (config: IColumnProps): React.ReactNode => {
            const title = (config.title as string) || titleCase(config.dataKey || (config.key as string))
            if (config.sorter) {
                config.sortOrder = sortedInfo.columnKey === (config.dataKey || config.key) && sortedInfo.order
            }

            return (
                <Table.Column
                    {...config}
                    title={title}
                    dataIndex={config.dataKey}
                    key={config.dataKey || config.key}
                    className={config.className || config.dataKey || (config.key as string)}
                />
            )
        }

        return (
            <>
                <Well
                    className={getClassNames('account-billing-history', 'type-2')}
                    title="Billing History"
                    hideFooter={true}
                    actions={
                        <>
                            <Input.Search
                                size="small"
                                className="billing-search"
                                placeholder="Search"
                                enterButton={true}
                                onSearch={this.searchRecords}
                            />
                            <AsyncButton size="small" className="billing-download" onClick={this.downloadRecords}>
                                <CloudDownloadOutlined />
                                <span>Download</span>
                            </AsyncButton>
                        </>
                    }
                >
                    <CompoundTable>
                        <Table
                            rowKey={(row) => row.id}
                            dataSource={this.state.records}
                            onChange={this.handleChange}
                            pagination={false}
                            footer={(currentTableData) => (
                                <SwSimpleLinkPagination
                                    currentTableData={currentTableData}
                                    links={this.state.paginationLinks}
                                    onPrev={async (_ev, _link, config) => {
                                        this.setState({ page: config.current! }, () => this.fetchRecords())
                                    }}
                                    onNext={async (_ev, _link, config) => {
                                        this.setState({ page: config.current! }, () => this.fetchRecords())
                                    }}
                                />
                            )}
                            bordered={true}
                            loading={
                                this.isLoading && {
                                    indicator: <Loading3QuartersOutlined spin={true} />,
                                }
                            }
                            locale={{
                                emptyText: <AntdTableEmptyPlaceholder text="No Previous Charges" icon="dollar" />,
                            }}
                        >
                            {buildColumn({
                                dataKey: 'created_at',
                                title: 'Date',
                                render: (_, record) => {
                                    return moment.tz(record.createdAt, 'UTC').format('LL')
                                },
                            })}
                            {buildColumn({
                                className: 'amount',
                                dataKey: 'amount',
                                title: 'Amount',
                                render: (_, record) => {
                                    const symbol = SUPPORTED_BILLING_CURRENCIES.find(
                                        (cur) => cur.value === record.currency,
                                    )!.symbol
                                    return `${symbol}${numberAsCurrency(record.amount, 2)}`
                                },
                            })}
                            {buildColumn({
                                className: 'line_item_description',
                                dataKey: 'description',
                                title: 'Description',
                            })}
                            {buildColumn({
                                key: 'actions',
                                title: ' ',
                                render: this.renderRowActions,
                            })}
                        </Table>
                    </CompoundTable>
                </Well>
            </>
        )
    }

    protected get isLoading(): boolean {
        return this.props.loading || (this.state.loading && !this.state.recordsLoaded)
    }

    protected renderRowActions = (record: any): React.ReactNode => {
        return (
            record.receiptUrl && (
                <Button onClick={() => this.handleReceiptDownloadClick(record)} size="small">
                    <DownloadOutlined />
                    <span>Get Receipt</span>
                </Button>
            )
        )
    }

    protected handleChange = (pagination: any, filters: any, sorter: any) => {
        this.setState({
            sortedInfo: sorter,
        })
    }

    protected handleReceiptDownloadClick(record: any): void {
        window.open(record.receiptUrl)
    }

    protected searchRecords = async (data: string | any) => {
        const value = typeof data !== 'string' ? data.target.value : data

        if (!value || value.length === 0 || value.length >= 2) {
            await this.setState({ page: 1, query: value })
            await this.fetchRecords(value)
        }
    }

    protected downloadRecords = async () => {
        await this.fetchRecords(this.state.query, true)
    }

    protected async fetchRecords(search?: any, csv: boolean = false) {
        if (!this.props.account) {
            setTimeout(() => this.fetchRecords(), 10)
            return
        }

        this.setState({ loading: true, recordsLoaded: false })
        const update: Partial<IAccountsState> = {
            recordsLoaded: true,
        }

        const opts: any = {}
        if (!!search && !!search.toString().trim()) {
            opts.search = search
        }
        if (csv) {
            opts.format = 'csv'
        }

        const {
            ok,
            data: records,
            meta,
        } = await this.accountService.fetchBillingRecordsByAccountId(this.props.account.id, opts, false)

        if (!csv && ok) {
            update.records = records
            update.paginationLinks = meta.links
            update.recordsMeta = meta
        }

        return this.setState({
            loading: false,
            ...(update as any),
        })
    }
}
