import * as React from 'react'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import autobind from 'autobind-decorator'
import { AppService } from '../../services/index'
import { BetterComponent } from '../better-component/better-component'
import './sticky.scss'

interface IProps {
    threshold: number
    getContainer?: () => any
}

interface IState {
    scrollListenerSet: boolean
    stickyStateEnabled: boolean
    stickyMetrics: any
}

@observer
export class Sticky extends BetterComponent<IProps, IState> {
    public state: IState = {
        scrollListenerSet: false,
        stickyStateEnabled: false,
        stickyMetrics: {},
    }

    private appService: AppService
    private stickyWrapperRef: any
    private stickyRef: any

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public componentDidUpdate() {
        this.setScrollListener()
    }

    public componentWillUnmount() {
        this.unsetScrollListener()
    }

    public render(): React.ReactNode {
        const classNames: string[] = ['sticky-component']

        const threshold: number = this.props.threshold
        const stickyProps: any = {}

        if (this.state.stickyStateEnabled) {
            classNames.push('sticky')
            stickyProps.style = {
                top: threshold,
                width: this.state.stickyMetrics.width,
            }
        }

        return (
            <div className={classNames.map((cn) => `${cn}-wrapper`).join(' ')} ref={this.setStickyWrapperRef}>
                <div
                    className={classNames.join(' ')}
                    data-sticky-threshold={threshold}
                    ref={this.setStickyRef}
                    {...stickyProps}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }

    private get appContainer(): any {
        const propsContainer = !!this.props.getContainer ? this.props.getContainer() : null
        return !!propsContainer ? propsContainer : this.appService.contentContainerRef
    }

    @autobind
    private setStickyWrapperRef(input: any): void {
        this.stickyWrapperRef = input
    }

    @autobind
    private setStickyRef(input: any): void {
        this.stickyRef = input
    }

    private setScrollListener() {
        if (this.appContainer && !this.state.scrollListenerSet) {
            this.appContainer.addEventListener('scroll', this.handleScrollEvent)
            this.setState({ scrollListenerSet: true })
        }
    }

    private unsetScrollListener() {
        if (this.appContainer && this.state.scrollListenerSet) {
            this.appContainer.removeEventListener('scroll', this.handleScrollEvent)
        }
    }

    @autobind
    private handleScrollEvent() {
        const rect = this.stickyWrapperRef.getBoundingClientRect()
        const top = rect.top
        const threshold = this.stickyRef.getAttribute('data-sticky-threshold')
        let stickyStateEnabled = false

        if (top <= threshold) {
            stickyStateEnabled = true
        }
        this.setState({
            stickyStateEnabled,
            stickyMetrics: rect,
        })
    }
}
