import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import { AppService, CampaignV2Service, NotificationService } from '../../../services'
import { InsightsService } from '../../../services/insights'
import { Container } from 'typescript-ioc/es5'
import { CampaignBuilder } from '../../../components/campaign-builder/campaign-builder'
import './campaign-revisions.scss'
import clone from 'clone'
import { CampaignDto } from '../dtos/campaign-dto'
import { CampaignBuilderMode, NodeType } from '../../../components/campaign-builder/enums'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { ApiVersion } from '../../../enums/api-version.enum'

interface ICampaignWorkflowTabProps {
    campaign: any
    activeTab: any
}

interface ICampaignWorkflowTabState {
    loading: boolean
    initialStateLoaded: boolean
    campaign: CampaignDto
    revision: any
}

export class CampaignWorkflowTab extends BetterComponent<ICampaignWorkflowTabProps, ICampaignWorkflowTabState> {
    protected static tabName = 'workflow'
    protected static tabLabel = 'Workflow'

    private appState: AppState
    private appService: AppService
    private insightsService: InsightsService
    private campaignService: CampaignV2Service
    private notifService: NotificationService

    public constructor(props: ICampaignWorkflowTabProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)
        this.campaignService = Container.get(CampaignV2Service)
        this.notifService = Container.get(NotificationService)

        this.state = {
            loading: true,
            initialStateLoaded: false,
            campaign: this.props.campaign,
            revision: this.props.campaign.revision,
        }
    }

    public async componentDidMount() {
        const campaign = clone(this.props.campaign)
        if (this.props.campaign) {
            const steps = await this.attachActionParams(campaign.revision.steps)

            campaign.steps = steps
            campaign.revision.steps = steps

            return this.setState({ campaign, initialStateLoaded: true, loading: false })
        } else {
            return this.setState({ initialStateLoaded: true, loading: false })
        }
    }

    public render(): React.ReactNode {
        return (
            <div className="campaign-workflow-tab">
                <div className="campaign-workflow-tab-builder">
                    {!this.state.initialStateLoaded ? (
                        <Loading3QuartersOutlined
                            spin={this.isLoading}
                            style={{ fontSize: '24px', position: 'relative', top: '275px', left: '50%' }}
                        />
                    ) : (
                        <CampaignBuilder
                            key={this.props.activeTab ? `revision-${this.currentValue.id}` : 'hidden'}
                            loading={this.isLoading}
                            mode={CampaignBuilderMode.READONLY}
                            domain={this.appState.currentDomain!}
                            campaign={this.state.campaign}
                            title="Campaign Overview"
                        />
                    )}
                </div>
            </div>
        )
    }

    public get isLoading() {
        return this.state.loading || !this.state.initialStateLoaded
    }

    public get currentValue() {
        return this.state.campaign?.revision
    }

    protected attachActionParams = async (steps: any[]) => {
        let modifiedSteps = clone(steps)

        if (Array.isArray(modifiedSteps) && modifiedSteps.length > 0) {
            const resolvers = Promise.all(
                modifiedSteps.map(async (step) => {
                    const notificationId = step.configuration?.params?.notification_id
                    if (!notificationId || step.type !== NodeType.ACTION) {
                        return step
                    }

                    const query = {
                        include_schedules: false,
                    }
                    const notif = await this.notifService.fetchNotificationById(
                        this.appState.currentDomain!.id,
                        notificationId,
                        { query, version: ApiVersion.V4 },
                    )

                    if (notif) {
                        step.configuration.params.title = notif?.template?.channels?.default?.title
                        step.configuration.params.notification = notif
                    }

                    return step
                }),
            )

            modifiedSteps = await resolvers
        }

        return modifiedSteps
    }
}
