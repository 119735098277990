export const StaticMode: any = {}

StaticMode.onSetup = function () {
    // tslint:disable-next-line:no-invalid-this
    this.setActionableState() // default actionable state is false for all actions
    return {}
}

// tslint:disable-next-line:only-arrow-functions
StaticMode.toDisplayFeatures = function (state, geojson, display) {
    display(geojson)
}
