import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import autobind from 'autobind-decorator'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, Input, Select } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { AppService } from '../../../services'
import { DomainDto } from '../../../dtos/domain'
import { PromptDto } from '../../../dtos/prompt'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { ColorPicker } from '../../../components/color-picker/color-picker'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import './slide-prompt-data-form.scss'
import { ISlidePromptTheme, PromptSlideConfigDto } from '../../../dtos/prompt-slide-config-dto'
import { PromptStyle } from '../prompt-editor/prompt-editor'
import { IBellPromptTheme } from '../../../dtos/prompt-bell-config-dto'
import { FileUploader } from '@pushly/aqe/lib/components'
import { simpleNotification } from '../../../_utils/utils'

interface IProps extends FormComponentProps {
    onFieldChange?: any
    prompt?: PromptDto
    onUploadChange: (upload: any) => any
}

interface IState {
    domain?: DomainDto
    imageUpload?: any
}

@observer
export class SlidePromptDataForm extends BetterFormComponent<IProps, IState> {
    public state: IState = {}

    private appState: AppState
    private appService: AppService
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        return this.renderStyleEditorForm()
    }

    private renderStyleEditorForm(): React.ReactNode {
        const { getFieldDecorator, getFieldValue } = this.props.form
        const { prompt } = this.props

        const defaultAccentColor = '#555'

        let defaultLayout = 'normal'
        let defaultDesktopPosition = 'top'
        let defaultMobilePosition = 'bottom'
        let defaultBackgroundColor = '#FFFFFF'
        let defaultTitleColor = '#555'
        let defaultSubTitleColor = '#555'
        let defaultAllowText = 'Allow'
        let defaultAllowTextColor = defaultBackgroundColor
        let defaultAllowButtonColor = defaultAccentColor
        let defaultDismissText = 'Dismiss'
        let defaultDismissTextColor = defaultAccentColor
        let defaultDismissButtonColor = defaultBackgroundColor

        if (prompt && this.promptTheme) {
            if (prompt.style === PromptStyle.SLIDE) {
                defaultLayout = this.promptTheme.layout
                defaultDesktopPosition = this.promptTheme.position.desktop || defaultDesktopPosition
                defaultMobilePosition = this.promptTheme.position.mobile || defaultMobilePosition
                defaultTitleColor = this.promptTheme.title.textColor
                defaultSubTitleColor = this.promptTheme.subtitle.textColor
                defaultBackgroundColor = this.promptTheme.backgroundColor
                defaultAllowText = this.promptTheme.allowButton.text
                defaultAllowTextColor = this.promptTheme.allowButton.textColor
                defaultAllowButtonColor = this.promptTheme.allowButton.backgroundColor
                defaultDismissText = this.promptTheme.dismissButton.text
                defaultDismissTextColor = this.promptTheme.dismissButton.textColor
                defaultDismissButtonColor = this.promptTheme.dismissButton.backgroundColor
            } else if (prompt.style === PromptStyle.BELL) {
                const theme = this.promptTheme as any as IBellPromptTheme
                defaultAllowButtonColor = theme.primaryColor
                defaultAllowTextColor = theme.accentColor
                defaultDismissTextColor = theme.primaryColor
            }
        }

        return (
            <Well title="Theme" className="customizations-well" showFooter={false} ghost={true}>
                <React.Fragment>
                    <Form.Item label="Layout">
                        {getFieldDecorator('layout', {
                            initialValue: defaultLayout,
                        })(
                            <Select size="large" placeholder="Select a layout" onChange={this.props.onFieldChange}>
                                <Select.Option value="normal">Horizontal</Select.Option>
                                <Select.Option value="stacked">Vertical</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Desktop Position">
                        {getFieldDecorator('desktopPosition_slide', {
                            initialValue: defaultDesktopPosition,
                        })(
                            <Select size="large" placeholder="Select a position" onChange={this.props.onFieldChange}>
                                <Select.Option value="top">Top</Select.Option>
                                <Select.Option value="bottom">Bottom</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Mobile Position">
                        {getFieldDecorator('mobilePosition_slide', {
                            initialValue: defaultMobilePosition,
                        })(
                            <Select size="large" placeholder="Select a position" onChange={this.props.onFieldChange}>
                                <Select.Option value="top">Top</Select.Option>
                                <Select.Option value="bottom">Bottom</Select.Option>
                            </Select>,
                        )}
                    </Form.Item>
                    <Form.Item label="Title">
                        {getFieldDecorator('title', {
                            initialValue: !prompt ? undefined : (this.promptTheme.title || {}).text,
                            rules: [
                                {
                                    required: true,
                                    message: 'Title is required',
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder="Title"
                                onChange={this.props.onFieldChange}
                                addonAfter={getFieldDecorator('titleColor', {
                                    initialValue: defaultTitleColor,
                                })(<ColorPicker.Button onChange={this.props.onFieldChange} />)}
                            />,
                        )}
                    </Form.Item>
                    <Form.Item label="Subtitle">
                        {getFieldDecorator('subtitle', {
                            initialValue: !prompt ? undefined : (this.promptTheme.subtitle || {}).text,
                            rules: [
                                {
                                    required: true,
                                    message: 'Subtitle is required',
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder="Subtitle"
                                onChange={this.props.onFieldChange}
                                addonAfter={getFieldDecorator('subtitleColor', {
                                    initialValue: defaultSubTitleColor,
                                })(<ColorPicker.Button onChange={this.props.onFieldChange} />)}
                            />,
                        )}
                    </Form.Item>

                    <Row gutter={24}>
                        <Col span={18}>
                            <Form.Item label="Allow Button">
                                {getFieldDecorator('allowButtonText', {
                                    initialValue: defaultAllowText,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Allow button text is required',
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Allow"
                                        onChange={this.props.onFieldChange}
                                        addonAfter={getFieldDecorator('allowButtonTextColor', {
                                            initialValue: defaultAllowTextColor,
                                        })(<ColorPicker.Button onChange={this.props.onFieldChange} />)}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} className="btn-color-col">
                            <Form.Item label="Background">
                                {getFieldDecorator('allowButtonColor', {
                                    initialValue: defaultAllowButtonColor,
                                })(
                                    <ColorPicker.Button
                                        className="allow-btn-picker"
                                        onChange={this.props.onFieldChange}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={18}>
                            <Form.Item label="Dismiss Button">
                                {getFieldDecorator('dismissButtonText', {
                                    initialValue: defaultDismissText,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Allow button text is required',
                                        },
                                    ],
                                })(
                                    <Input
                                        size="large"
                                        placeholder="Dismiss"
                                        onChange={this.props.onFieldChange}
                                        addonAfter={getFieldDecorator('dismissButtonTextColor', {
                                            initialValue: defaultDismissTextColor,
                                        })(<ColorPicker.Button onChange={this.props.onFieldChange} />)}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6} className="btn-color-col">
                            <Form.Item label="Background">
                                {getFieldDecorator('dismissButtonColor', {
                                    initialValue: defaultDismissButtonColor,
                                })(
                                    <ColorPicker.Button
                                        className="cancel-btn-picker"
                                        onChange={this.props.onFieldChange}
                                    />,
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="customization-lower">
                        <div>
                            <Form.Item label="Background Color">
                                {getFieldDecorator('backgroundColor', {
                                    initialValue: defaultBackgroundColor,
                                })(
                                    <ColorPicker.Button
                                        className="prompt-background-picker"
                                        onChange={this.props.onFieldChange}
                                    />,
                                )}
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Image">
                                {getFieldDecorator('imageUrl', {
                                    initialValue: !this.promptImage
                                        ? undefined
                                        : {
                                              url: this.promptImage,
                                          },
                                })(
                                    <FileUploader
                                        className="image-upload"
                                        type="image"
                                        ownerType="domain"
                                        ownerId={this.appState.currentDomain!.id}
                                        imageConfig={{
                                            quality: this.appState.currentDomain!.mediaQuality,
                                        }}
                                        onSuccess={this.handleUploadSuccess}
                                        onRemove={this.handleUploadRemoval}
                                        onError={(res: any) => {
                                            const error = res?.error
                                            if (error && error.error_type === 'invalid_file_type_error') {
                                                return simpleNotification('error', error.message)
                                            }
                                        }}
                                    />,
                                )}
                            </Form.Item>
                        </div>
                    </div>
                </React.Fragment>
            </Well>
        )
    }

    private get promptTheme(): ISlidePromptTheme {
        const emptyTheme = {
            position: {},
            title: {},
            subtitle: {},
            allowButton: {},
            dismissButton: {},
        }

        return this.props.prompt
            ? ((this.props.prompt.config as PromptSlideConfigDto) || {}).theme || emptyTheme
            : (emptyTheme as ISlidePromptTheme)
    }

    private get promptImage(): string | undefined {
        const { domain } = this.state
        return this.promptTheme.image || (domain ? domain.defaultIconUrl : undefined)
    }

    @autobind
    private handleUploadSuccess(imageUpload: any) {
        this.setState({ imageUpload })

        if (this.props.onUploadChange) {
            this.props.onUploadChange(imageUpload)
        }
    }

    @autobind
    private handleUploadRemoval() {
        this.setState({ imageUpload: undefined })

        if (this.props.onUploadChange) {
            this.props.onUploadChange(undefined)
        }
    }

    private setDomainState(): void {
        this.setState({ domain: this.appState.currentDomain })
    }
}
