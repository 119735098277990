import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Component } from 'react'
import { preventBubbling } from '../../_utils/utils'

export interface ISwPortalProps {
    portalId: string
    container?: HTMLElement
    className?: string
    style?: any
}

export class SwPortal extends Component<ISwPortalProps, {}> {
    protected defaultClassNames: string[] = ['sw-portal']

    private portalElement: any

    public render(): React.ReactNode {
        return null
    }

    public componentDidMount(): void {
        let p: any = this.props.portalId && document.getElementById(this.props.portalId)

        if (!p) {
            p = document.createElement('div')
            p.id = this.props.portalId

            this.buildClassNames().forEach((className) => p.classList.add(className))

            if (this.props.style) p.style = this.props.style

            this.container.appendChild(p)
        }

        this.portalElement = p
        this.portalElement.addEventListener('mousedown', preventBubbling)
        this.componentDidUpdate()
    }

    public componentWillUnmount(): void {
        this.portalElement.removeEventListener('mousedown', preventBubbling)
        this.container.removeChild(this.portalElement)
    }

    public componentDidUpdate(): void {
        this.portalElement.style = this.props.style

        if (!!this.portalElement.classList && this.portalElement.classList.length > 0) {
            this.portalElement.classList.remove(...this.portalElement.classList)
        }
        this.buildClassNames().forEach((className) => this.portalElement.classList.add(className))

        ReactDOM.render(<React.Fragment>{this.props.children}</React.Fragment>, this.portalElement)
    }

    public get container(): HTMLElement {
        return this.props.container || document.body
    }

    protected buildClassNames(): string[] {
        const classNames = [...this.defaultClassNames]

        if (this.props.className) {
            this.props.className.split(' ').forEach((className) => classNames.push(className.trim()))
        }

        return classNames
    }
}
