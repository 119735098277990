import React from 'react'
import './show-single-domain-entities-toggle.scss'
import classnames from 'classnames'
import { Switch, SwitchProps } from 'antd'

interface IToggleProps extends SwitchProps {
    title: string
}

const ShowSingleDomainEntitiesToggle = (props: IToggleProps) => {
    const { size, className, title, ...switchProps } = props
    const switchRef = React.createRef<HTMLElement>()

    return (
        <div className={classnames('switch-row', 'show-single-domain-entities-toggle', className, {})}>
            <div className="switch-left">
                <Switch className="switch-toggle" ref={switchRef} {...switchProps} size={size ?? 'small'} />
            </div>
            <div className="switch-right">
                <span className="switch-title" onClick={() => switchRef.current?.click?.()}>
                    Show Single-Domain {title}
                </span>
            </div>
        </div>
    )
}

export default ShowSingleDomainEntitiesToggle
