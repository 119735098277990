import { StatusType } from '../../../enums/status-type'
import * as moment from 'moment-timezone'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class CampaignDto {
    public static parse(fields: Partial<CampaignDto>): CampaignDto {
        const { ...assignments } = fields

        const dto = new CampaignDto()
        Object.assign(dto, assignments)

        return dto
    }

    public id: number
    public channels: DeliveryChannel[]
    public domainId: number
    public name: string
    public type: string
    public statusId: number
    public status: string
    public configuration: any
    public dateTimeZone: string
    public dateStart: Date
    public dateStartUtc: Date
    public dateEnd: Date | null
    public dateEndUtc: Date | null
    public dateCompletedUtc: Date | null
    public manuallyCompleted: boolean
    public revisions: any[]
    public revision: any & {
        id: number
        steps: any[]
    }
    public createdAt: Date
    public createdByUserName: string

    public isActive(): boolean {
        return this.statusId === StatusType.ACTIVE.id
    }

    public isPaused(): boolean {
        return this.statusId === StatusType.PAUSED.id
    }

    public isCompleted(): boolean {
        let runDatesCompleted = false

        if (this.dateCompletedUtc || this.manuallyCompleted) {
            runDatesCompleted = true
        } else if (this.dateEnd) {
            const now = moment.utc()
            const dateEnd = moment.tz(this.dateEndUtc || this.dateEnd, 'utc')
            runDatesCompleted = dateEnd <= now
        }

        return (
            runDatesCompleted || this.statusId === StatusType.COMPLETED.id || this.statusId === StatusType.CANCELLED.id
        )
    }

    public getEndBehavior(): string {
        let behavior = (this.configuration || {}).end_behavior || 'bleed'
        if (behavior === 'stop') behavior += ':' + this.getEndStatus()

        return behavior
    }

    public getEndStatus(): string {
        return (this.configuration || {}).end_status || 'completed'
    }
}
