import { DomainDto } from '../dtos/domain'
import { AppState } from '../stores/app'
import { observe } from 'mobx'
import { AccountDto } from '../dtos/account.dto'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const onAccountIdChange = (appState: AppState, cb: () => void) => {
    return observe(appState, 'currentDomainJsonData', ({ oldValue, newValue }: any) => {
        try {
            const oldData = !oldValue ? undefined : DomainDto.fromApiResponse(JSON.parse(oldValue))
            const newData = !newValue ? undefined : DomainDto.fromApiResponse(JSON.parse(newValue))

            if (newData?.accountId?.toString() !== oldData?.accountId?.toString()) {
                // 50ms handles route rewrite
                setTimeout(cb, 50)
            }
        } catch (_) {
            // unable to parse domain json - do not run callback
        }
    })
}

export function accountIsBillable(account: any): boolean {
    return (
        !!account &&
        !!account.billingData &&
        account.billingData.type === 'CREDIT' &&
        !!account.billingData.stripe_customer_id
    )
}

export function isDeliveryChannelEnabled(
    account: { flags: string[] | ReadonlyArray<string> },
    channel: DeliveryChannel,
): boolean {
    return account.flags?.includes(DeliveryChannel.getAssociatedFlag(channel)!) ?? false
}

/**
 * TODO: should replace all usage of "initialChannels" using AccountDto.is<Channel>Enabled(), etc...
 */
export function getAccountEnabledDeliveryChannels(account: {
    flags: string[] | ReadonlyArray<string>
}): DeliveryChannel[] {
    return DeliveryChannel.getAllChannels()
        .map<[DeliveryChannel, boolean]>((ch) => [ch, isDeliveryChannelEnabled(account, ch)])
        .filter(([, enabled]) => enabled)
        .map(([ch]) => ch)
}
