import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import { HOLD_OUT_COMPUTED_STATUS, StatusType } from '../../enums/status-type'
import { titleCase } from '../../_utils/utils'
import './status-badge.scss'

/*
    "expanded" view forces hidden tooltip
    and displays the status text to the right
    of the badge
 */
interface IProps extends IBadgeProps {
    status: string
    expanded?: boolean
    holdOut: boolean
}

export const NotificationStatusBadge: React.StatelessComponent<IProps> = (props) => {
    const hideTooltip = props.hideTooltip || props.expanded

    let text = props.status
    let icon = 'carry-out'

    if (props.holdOut) {
        if (props.status === StatusType.DRAFT.name) {
            text = props.status
            icon = 'form'
        } else {
            text = HOLD_OUT_COMPUTED_STATUS
            icon = 'carry-out'
        }
    } else {
        switch (props.status) {
            case StatusType.COMPLETED.name:
                text = 'DELIVERED'
                break
            case StatusType.DELIVERING.name:
            case StatusType.QUEUED.name:
                text = 'DELIVERING'
                icon = 'wifi'
                break
            case StatusType.SCHEDULED.name:
            case StatusType.SCHEDULING.name:
                text = 'SCHEDULED'
                icon = 'clock-circle'
                break
            case StatusType.CANCELLED.name:
            case StatusType.CANCELLING.name:
                text = 'CANCELLED'
                icon = 'close-square'
                break
            case StatusType.FAILED.name:
                icon = 'warning'
                break
            case StatusType.DRAFT.name:
                icon = 'form'
                break
            default:
                break
        }
    }

    const badge = (
        <span className={`status-icon status-${text}`}>
            <LegacyIcon type={icon} />
        </span>
    )

    return (
        <span className="status-badge notification-status-badge">
            {hideTooltip ? (
                !props.expanded ? (
                    badge
                ) : (
                    <>
                        {badge}
                        <span className="status-name">{titleCase(text)}</span>
                    </>
                )
            ) : (
                <Tooltip title={props.tooltip || text}>{badge}</Tooltip>
            )}
        </span>
    )
}
