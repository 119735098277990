import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './content.scss'

interface IContentProps {}

interface IContentState {}

export class Content extends BetterComponent<IContentProps, IContentState> {
    public readonly defaultClassName: string = 'sw-mv-content'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IContentProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('body')}>{this.props.children}</div>
                </div>
            </div>
        )
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
