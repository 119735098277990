import clone from 'clone-deep'
import { IChart, INode } from '@mrblenny/react-flow-chart'
import { ConditionType, NodeType, PortId, PortType } from '../enums'
import { buildShadowNode } from '../node-inner'

export const calculateChartPositions = (chart: IChart): IChart => {
    chart = clone(chart)
    const nodeTree = buildNodeTree(chart)

    /**
     * Base properties
     *
     * The following should match their css counterparts:
     *   innerCanvasWidth
     *   baseNodeHeight
     *   baseNodeWidth
     */
    const innerCanvasWidth = 2000
    const baseNodeHeight = 50
    const baseNodeWidth = 240
    const nodeHeightGutter = 70
    const nodeWidthGutter = 70
    const relativeCenterX = innerCanvasWidth / 2 + nodeWidthGutter / 2

    /**
     * The base width/height should include the desired padding
     *
     * If the width/height needs to change depending on the node type, this should be done in
     *  calculateNodeDimensions.
     */
    const dimensions = calculateNodeDimensions(chart, nodeTree, {
        baseNodeHeight: baseNodeHeight + nodeHeightGutter,
        baseNodeWidth: baseNodeWidth + nodeWidthGutter,
    })

    /**
     * The offsetX/offsetY where the chart should be drawn.
     *
     * If the chart isn't balanced this will need to account for the relativeCenterX of the root node.
     */
    const rootNode = dimensions[nodeTree.id]
    const positions = calculateNodePositions(chart, nodeTree, dimensions, {
        baseNodeHeight: baseNodeHeight + nodeHeightGutter,
        baseNodeWidth: baseNodeWidth + nodeWidthGutter,
        offsetX: relativeCenterX - rootNode.width / 2,
        offsetY: nodeHeightGutter / 2,
    })

    // tslint:disable-next-line:forin
    for (const nodeId in positions) {
        // @ts-ignore
        chart.nodes[nodeId].position = positions[nodeId]
    }

    return chart
}

const buildNodeTree = (chart: IChart) => {
    const connections: any = {}

    // tslint:disable-next-line:forin
    for (const nodeName in chart.nodes) {
        connections[nodeName] = {
            in: null,
            out: [],
        }
    }

    // tslint:disable-next-line:forin
    for (const linkName in chart.links) {
        const link = chart.links[linkName]
        const fromNode = link.from
        const toNode = link.to

        connections[fromNode.nodeId].out.push({
            port: toNode.portId,
            node: toNode.nodeId,
        })
        connections[toNode.nodeId!].in = fromNode.nodeId
    }

    let topNode
    // tslint:disable-next-line:forin
    for (const nodeName in connections) {
        const node = connections[nodeName]
        if (!node.in) {
            topNode = nodeName
            break
        }
    }

    const _build = (nodeName: string) => {
        const node = connections[nodeName]
        const leaf: any = {
            id: nodeName,
            nodes: [],
        }

        node.out.forEach((n: any) => {
            leaf.nodes.push(_build(n.node))
        })

        sortLeafNodesByPortId(leaf, chart)

        return leaf
    }

    const tree = _build(topNode)

    return tree
}

const calculateNodeDimensions = (chart: IChart, nodeTree: any, options: any) => {
    const dimensions: any = {}

    const _recurse = (node: any) => {
        // Calculate widths
        const nodeRect = getRenderedDimensions(chart.nodes[node.id])
        const selfWidth = !nodeRect.width ? options.baseNodeWidth : nodeRect.width + 40
        const selfHeight = !nodeRect.height ? options.baseNodeHeight : nodeRect.height + 64

        let childWidths = 0
        node.nodes.forEach((n: any) => {
            _recurse(n)
            childWidths += dimensions[n.id].width
        })

        dimensions[node.id] = {
            height: selfHeight,
            width: Math.max(selfWidth, childWidths),
            selfWidth,
        }

        // Calculate relative center
        let leftMostCenterX = dimensions[node.id].width / 2
        let rightMostCenterX = dimensions[node.id].width / 2

        let movingWidths = 0
        node.nodes.forEach((n: any) => {
            const x = movingWidths + dimensions[n.id].relativeCenterX
            if (x < leftMostCenterX) {
                leftMostCenterX = x
            }
            if (x > rightMostCenterX) {
                rightMostCenterX = x
            }
            movingWidths += dimensions[n.id].width
        })

        dimensions[node.id].relativeCenterX = leftMostCenterX + (rightMostCenterX - leftMostCenterX) / 2
    }
    _recurse(nodeTree)

    return dimensions
}

const calculateNodePositions = (chart: IChart, nodeTree: any, dimensions: any, options: any) => {
    const positions: any = {}

    const _recurse = (node: any, parentX: number, parentY: number, parentNode?: any) => {
        const nodeConfig = chart.nodes[node.id]
        const siblingNodes = parentNode ? parentNode.nodes?.length - 1 : 0

        sortNodePortsById(nodeConfig)

        const nodeDimensions = dimensions[node.id]

        const trueParentX = parentNode ? positions[parentNode.id].x : parentX
        const centerX = parentX + dimensions[node.id].relativeCenterX
        let posX = !!parentNode && !siblingNodes ? trueParentX : centerX - nodeDimensions.selfWidth / 2

        /**
         * Adjusts x position to account for nodes wider than standard width
         *
         * trueBaseNodeWidth: scss (.[prefix]-campaign-canvas-node) defined width
         * heightAdjustment: scss (.node-ports .port) defined height (+ 2px for borders)
         * widthAdjustment: scss (.node-ports .port) defined width
         * delta: total difference in port width if greater than true width
         *
         */
        let childOffsetY = parentY + nodeDimensions.height

        if (
            nodeConfig.type === NodeType.CONDITION &&
            nodeConfig.properties?.[NodeType.CONDITION]?.type === ConditionType.BRANCH
        ) {
            const nodeWidth = nodeDimensions.selfWidth
            const parentWidth = dimensions[parentNode.id].selfWidth
            const widthDiff = nodeWidth - parentWidth

            if (nodeWidth > parentWidth && widthDiff > 0) {
                posX -= widthDiff / 2
            }

            const outNodeKeys = Object.keys(nodeConfig.ports).filter((k) => k !== PortId.INPUT)
            childOffsetY += Math.abs(nodeDimensions.height - options.baseNodeHeight)

            if (outNodeKeys.length > 3) {
                childOffsetY += (outNodeKeys.length - 1) * 4
            }

            calculateCaseChildLinkPositions(chart, nodeConfig, nodeDimensions)
        }

        positions[node.id] = {
            x: posX,
            y: parentY,
        }

        let offsetX = parentX
        node.nodes.forEach((n: any) => {
            _recurse(n, offsetX, childOffsetY, node)
            offsetX += dimensions[n.id].width
        })
    }
    _recurse(nodeTree, options.offsetX, options.offsetY)

    return positions
}

const calculateCaseChildLinkPositions = (chart: IChart, nodeConfig: INode, dimensions: any) => {
    const portKeys = Object.keys(nodeConfig.ports)
    const outPortKeys = portKeys.filter((port) => port !== PortType.INPUT)

    /**
     * Calculate parent node center and possible shift
     *
     * Even case sets need to account for +/- 50% shift from true center
     */
    let parentPortCenter = Math.round(outPortKeys.length / 2)
    const outKeysEvenNumbered = outPortKeys.length % 2 === 0
    if (outKeysEvenNumbered) {
        parentPortCenter += 0.5
    }

    const xCenterOffsets: Record<string, any> = {}
    const yCenterOffsets: Record<string, any> = {}
    const portWidth = dimensions.selfWidth / outPortKeys.length - (outPortKeys.length - 1) - 2

    /**
     * Condition node child link positions
     *
     * link.properties.xOffset: alters link start to center of case
     * link.properties.yOffset: alters link mid to prevent sibling overlap
     */
    // @ts-ignore
    outPortKeys.forEach((portKey, portKeyIdx) => {
        const port = nodeConfig.ports[portKey]
        const portN = portKeyIdx + 1
        const invertedPortN = outPortKeys.length - portKeyIdx

        xCenterOffsets[portKey] =
            portN === parentPortCenter
                ? 0
                : portN > parentPortCenter
                ? portN - parentPortCenter
                : -(parentPortCenter - portN)

        yCenterOffsets[portKey] =
            portN === parentPortCenter ? 0 : invertedPortN > parentPortCenter ? portN : outPortKeys.length - portKeyIdx

        const xOffset =
            xCenterOffsets[portKey] * portWidth -
            (Math.abs(xCenterOffsets[portKey]) < 1 ? 0 : Math.round(xCenterOffsets[portKey]) * 4)
        const yOffset = yCenterOffsets[portKey] < 1 ? 0 : yCenterOffsets[portKey] * 10 + yCenterOffsets[portKey] * 1.5

        port.properties = {
            ...port.properties,
            xOffset,
            yOffset,
        }

        nodeConfig.ports[portKey] = port
    })
}

const sortLeafNodesByPortId = (leaf: any, chart: IChart) => {
    const node = chart.nodes[leaf.id]
    const sortedPortKeys = getSortedNodePortIds(node)

    const sortedNodes: any = []
    for (const portKey of sortedPortKeys) {
        const link = Object.values(chart.links).find((l) => l.from.nodeId === node.id && l.from.portId === portKey)

        const leafNode = leaf.nodes.find((n: any) => n.id === link?.to?.nodeId)
        if (leafNode) {
            sortedNodes.push(leafNode)
        }
    }

    leaf.nodes = sortedNodes
}

const sortNodePortsById = (node: INode) => {
    const sortedPortKeys = getSortedNodePortIds(node)

    const sortedPorts: any = {}
    for (const key of sortedPortKeys) {
        sortedPorts[key] = node.ports[key]
    }

    node.ports = sortedPorts
}

const getSortedNodePortIds = (node: INode): string[] => {
    const portKeys = Object.keys(node.ports)
    const outPortKeys = portKeys.filter((p) => p !== PortId.INPUT)

    portKeys.sort((a, b) => {
        if (/^case_/.test(a) || /^case_/.test(b)) {
            return /default/.test(a) ? 1 : a > b ? 1 : a < b ? -1 : 0
        }
        if (!!a && !!b) {
            return a === b ? 0 : a === 'positive' ? -1 : 1
        }

        return a > b ? 1 : a < b ? -1 : 0
    })

    return portKeys
}

const getRenderedDimensions = (node: INode): DOMRect => {
    const shadowId = '53279360-fde8-5948-a415-2d5c90f418sw'
    const shadow = document.getElementById(shadowId)
    let rect = {} as DOMRect

    if (shadow) {
        const renderedNode = buildShadowNode(node)
        shadow.appendChild(renderedNode)

        if (renderedNode) {
            rect = renderedNode.getBoundingClientRect()
            shadow.removeChild(renderedNode)
        }
    }

    return rect
}
