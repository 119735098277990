import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import { AppService } from '../../../../services/index'
import { Container } from 'typescript-ioc/es5'
import { PromptService } from '../../../../services/prompt'
import { PromptDto } from '../../../../dtos/prompt'
import { AppState } from '../../../../stores/app'
import { noop } from '../../../../_utils/utils'
import { PromptSearchBar } from '../../../prompt-search-bar/prompt-search-bar'
import './prompt.input.scss'

interface IPromptInputProps {
    field: IRuleBuilderField
    rule: IRule
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
}

interface IPromptInputState {
    loadingPrompts: boolean
    prompts: PromptDto[]
}

export class PromptInput extends BetterComponent<IPromptInputProps, IPromptInputState> {
    public readonly defaultClassName = 'sw-v2-rb-prompt-input'
    public ref: any

    protected appService: AppService
    protected appState: AppState
    protected promptService: PromptService

    public constructor(props: IPromptInputProps) {
        super(props)

        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
        this.promptService = Container.get(PromptService)

        this.state = {
            loadingPrompts: true,
            prompts: [],
        }
    }

    public componentDidMount(): void {
        this.ensurePrompts().then(noop)
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName(this.isDisplayMode ? 'display' : 'editor')}>
                    <div className={this.buildClassName('prompt-select')}>
                        <PromptSearchBar
                            className="prompt-search"
                            mode={this.props.mode === 'display' ? 'display' : 'search'}
                            value={this.rule.value}
                            placeholder="Select a Prompt"
                            onChange={this.handlePromptChange}
                            getDropdownContainer={this.appService.getAppContainer}
                            displayStaticOptions={true}
                            loading={this.state.loadingPrompts}
                            options={this.state.prompts ?? []}
                        />
                    </div>
                </div>
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }

        if (!this.rule.value) {
            response.ok = false
            response.error = 'Please select a valid prompt.'
        }

        return response
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected handlePromptChange = async (promptId: number) => {
        this.emitChange(promptId)
    }

    protected async emitChange(value: number): Promise<void> {
        return this.props.onChange(value)
    }

    protected async ensurePrompts(): Promise<void> {
        const res: any = await this.promptService.fetchAllByDomainId(
            this.appState.currentDomain!.id,
            { pagination: 0 },
            false,
        )

        const rawPrompts = res.prompts
        const prompts: PromptDto[] = []

        for (const group of rawPrompts) {
            for (const prompt of group.prompts) {
                prompt.promptGroup = group
                prompts.push(prompt)
            }
        }

        return this.setState({ prompts, loadingPrompts: false })
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
