import * as React from 'react'
import autobind from 'autobind-decorator'
import * as queryString from 'query-string'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { DomainDto } from '../../dtos/domain'
import { GetFieldDecoratorOptions } from '@ant-design/compatible/lib/form/Form'

export interface IAppInjectedProps {
    history: any
    match: any
    location: any
}

export class BetterComponent<Props, State> extends React.Component<Props, State> {
    protected unmounting: boolean = false

    public componentWillUnmount() {
        this.unmounting = true
    }

    protected get injectedProps() {
        return this.props as any as IAppInjectedProps
    }

    protected get queryString() {
        try {
            return queryString.parse(this.injectedProps.location.search)
        } catch {
            return queryString.parse(location.search)
        }
    }

    protected get router() {
        return this.context.router
    }

    protected get matchedPath() {
        return this.injectedProps.match.path
    }

    protected get globalDomain(): DomainDto | undefined {
        let domain: DomainDto | undefined

        if ('appState' in this) {
            domain = (this as any).appState.currentDomain
        }

        return domain
    }

    protected get localDomain(): DomainDto | undefined {
        let domain: DomainDto | undefined

        if ('domain' in this.state) {
            domain = (this.state as any).domain
        }

        return domain
    }

    public setState<K extends keyof State>(
        stateUpdater: ((prevState: Readonly<State>, props: Props) => Pick<State, K> | State) | (Pick<State, K> | State),
        callback?: () => void,
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.unmounting) {
                super.setState(stateUpdater, resolve)
            } else {
                resolve()
            }
        })
    }

    @autobind
    protected async assignState<TKey extends keyof State, TNestedKey extends keyof State[TKey]>(
        key: TKey,
        value:
            | State[TKey]
            | Pick<State[TKey], TNestedKey>
            | ((prevState: Readonly<State>) => State[TKey] | Pick<State[TKey], TNestedKey>),
    ): Promise<void> {
        await this.setState((state) => {
            let nestedResult = value

            if (value !== null) {
                if (typeof value === 'function') {
                    nestedResult = Object.assign({}, state[key], (value as Function)(state))
                } else if (typeof value === 'object' && !Array.isArray(value)) {
                    nestedResult = Object.assign({}, state[key], value)
                }
            }

            return Object.assign({}, state, { [key]: nestedResult })
        })
    }

    @autobind
    protected noop() {}
}

// tslint:disable-next-line:max-classes-per-file
export class BetterFormComponent<Props, State> extends BetterComponent<Props & FormComponentProps, State> {
    protected async setFieldsAndState(values: any) {
        await this.setState(() => {
            return Object.assign({}, this.state, values)
        })
        this.setFieldsValues(values)
    }

    protected getFieldDecorator(
        id: string,
        options?: GetFieldDecoratorOptions,
    ): (node: React.ReactNode) => React.ReactNode {
        return this.props.form.getFieldDecorator(id, options)
    }

    protected getFieldValue(fieldName: string): any {
        return this.props.form.getFieldValue(fieldName)
    }

    protected validateFields(excludeFields: string[] = []): Promise<any> {
        const fieldNames = Object.keys(this.props.form.getFieldsValue()).filter((f) => excludeFields.indexOf(f) === -1)

        return new Promise((resolve, reject) => {
            this.props.form.validateFields(fieldNames, { force: true }, (errors: any, values: any) => {
                if (errors) {
                    reject(errors)
                } else {
                    resolve(values)
                }
            })
        })
    }

    protected passiveValidateFields(): Promise<any> {
        return new Promise((resolve) => {
            this.props.form.validateFields((errors: any, values: any) => {
                resolve(values)
            })
        })
    }

    protected setFieldsValues(fields: any) {
        this.props.form.setFieldsValue(fields)
        this.props.form.validateFields()
    }
}
