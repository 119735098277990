import * as React from 'react'
import * as CopyToClipboard from 'react-copy-to-clipboard'
import { simpleNotification } from '../../_utils/utils'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'

interface IAddUserSuccessMessage {
    responseData: any
    showMessage?: boolean
    message?: string | ((data: any) => string)
    type?: 'invite' | 'reinvite' | 'password'
}

interface IAddUserSuccessMessageState {
    copied: boolean
}

export class AddUserSuccessMessage extends React.Component<IAddUserSuccessMessage, IAddUserSuccessMessageState> {
    public state: IAddUserSuccessMessageState = {
        copied: false,
    }

    private readonly appState: AppState

    public constructor(props: IAddUserSuccessMessage) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const type = this.props.type || 'invite'
        const path = type === 'invite' ? 'confirm-invite' : 'reset-password'

        const actionUrl =
            this.props.responseData.ticketUrl ??
            `${this.appState.platformHost}/${path}?token=${this.props.responseData.token}`
        let message: any = 'User was successfully saved.'
        if (this.props.message) {
            message =
                typeof this.props.message === 'function'
                    ? this.props.message(this.props.responseData)
                    : this.props.message
        } else if (type === 'invite') {
            if (!this.props.responseData.ticketUrl) {
                message = (
                    <div>This user already had access to the platform and has been added to the selected domains.</div>
                )
            } else {
                message = (
                    <div>
                        The user has been successfully created and will receive a welcome email. If the user is having
                        trouble receiving the email, you may also
                        {
                            <CopyToClipboard onCopy={this.setCopiedState} text={actionUrl}>
                                <span className="highlight"> copy the invitation link </span>
                            </CopyToClipboard>
                        }
                        and provide it to them.
                    </div>
                )
            }
        } else if (type === 'reinvite') {
            message = (
                <div>
                    An new invitation email has been generated and sent in an email to the user. If the user is having
                    trouble receiving the email, you may also
                    {
                        <CopyToClipboard onCopy={this.setCopiedState} text={actionUrl}>
                            <span className="highlight"> copy the invitation link </span>
                        </CopyToClipboard>
                    }
                    and provide it to them.
                </div>
            )
        } else if (type === 'password') {
            message = (
                <div>
                    A password reset has been successfully generated and emailed to the user. If the user is having
                    trouble receiving the email, you may also
                    {
                        <CopyToClipboard onCopy={this.setCopiedState} text={actionUrl}>
                            <span className="highlight"> copy the password reset link </span>
                        </CopyToClipboard>
                    }
                    and provide it to them.
                </div>
            )
        }

        return <div>{message}</div>
    }

    private setCopiedState = () => {
        simpleNotification('success', 'Link successfully copied.')
        this.setState({ copied: true })
    }
}
