import * as queryString from 'query-string'

export const getQueryString = () => queryString.parse(location.search)
export const getQueryStringParam = <T extends unknown>(param: string, fmt?: (value: any) => T) => {
    let value: any

    const qs = getQueryString()
    if (param in qs) {
        value = !!fmt ? fmt(qs[param]) : qs[param]
    }

    return value
}
