import React from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../stores/app'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { AppService } from '../../services'
import { getPathClassName } from '../../_utils/get-path-classname'
import { Route, Switch } from '../../components/router'
import { Account } from '../post-auth/account'
import OrgNotificationSend from './org-notification-send'
import OrgNotification from './org-notification'
import Notifications from '../post-auth/notifications'
import Segments from '../post-auth/segments'
import Segment from '../post-auth/segment'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { ICaslSubject } from '../../stores/app-ability'
import { AccountDto } from '../../dtos/account.dto'
import { buildComponentCheck } from '../../_utils/routing'

@observer
export class OrganizationsFeature extends React.Component<RouteComponentProps<any>> {
    protected readonly appState: AppState
    protected readonly appService: AppService

    public constructor(props: RouteComponentProps<any>) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const pathClassName = getPathClassName(location.pathname)
        const matchedPath = this.props.match.path

        const { abilityStore } = this.appState

        return (
            <div className={classnames('module', 'organizations', pathClassName)}>
                <ModuleLoadingScreen />

                <Switch>
                    <Route
                        path={matchedPath}
                        exact={true}
                        component={buildComponentCheck(Account, [
                            [() => this.accessCheck(AbilityAction.UPDATE, abilityStore.currentOrgIdentity)],
                        ])}
                    />

                    <Route
                        path={`${matchedPath}/notifications`}
                        exact={true}
                        component={buildComponentCheck(Notifications, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.ORG_NOTIFICATION)],
                            [() => this.ensureOrgHasMultiDomainNotifications()],
                        ])}
                    />
                    <Route
                        path={`${matchedPath}/notifications/new`}
                        exact={true}
                        component={buildComponentCheck(OrgNotificationSend, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.ORG_NOTIFICATION)],
                            [() => this.ensureOrgHasMultiDomainNotifications()],
                        ])}
                    />
                    <Route
                        path={`${matchedPath}/notifications/:notificationId([0-9]+)/edit`}
                        exact={true}
                        component={buildComponentCheck(OrgNotificationSend, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.ORG_NOTIFICATION)],
                            [this.ensureOrgHasMultiDomainNotifications],
                        ])}
                    />
                    {/* "/notifications" path catch all - do not put any "/notifications/id..." paths beneath this */}
                    <Route
                        path={`${matchedPath}/notifications/:notificationId([0-9]+)`}
                        exact={false}
                        component={buildComponentCheck(OrgNotification, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.ORG_NOTIFICATION)],
                            [() => this.ensureOrgHasMultiDomainNotifications()],
                        ])}
                    />

                    {/*
                     Segments will not have a separate flag for multi-domain level.
                     If an org has access to multi-domain notifications they will
                     also implicitly have access to segmentation.
                     */}
                    <Route
                        path={`${matchedPath}/segments`}
                        exact={true}
                        component={buildComponentCheck(Segments, [
                            [() => this.accessCheck(AbilityAction.READ, SubjectEntity.ORG_SEGMENT)],
                            [this.ensureOrgHasMultiDomainNotifications],
                        ])}
                    />
                    <Route
                        path={`${matchedPath}/segments/new`}
                        exact={true}
                        component={buildComponentCheck(Segment, [
                            [() => this.accessCheck(AbilityAction.CREATE, SubjectEntity.ORG_SEGMENT)],
                            [this.ensureOrgHasMultiDomainNotifications],
                        ])}
                    />
                    {/* "/segments" path catch all - do not put any "/segments/id..." paths beneath this */}
                    <Route
                        path={`${matchedPath}/segments/:segmentId([0-9]+)`}
                        exact={false}
                        component={buildComponentCheck(Segment, [
                            [() => this.accessCheck(AbilityAction.UPDATE, SubjectEntity.ORG_SEGMENT)],
                            [this.ensureOrgHasMultiDomainNotifications],
                        ])}
                    />
                </Switch>
            </div>
        )
    }

    protected ensureOrgHasMultiDomainNotifications = () => {
        const mdNotifFlag = this.appState.flags.findActive('FEAT_MULTI_DOMAIN_NOTIFICATIONS')?.getKey()
        return !!mdNotifFlag && (this.appState.currentDomain?.accountFlags?.includes(mdNotifFlag) ?? false)
    }

    protected accessCheck = <T extends SubjectEntity>(
        action: AbilityAction,
        check: T | ICaslSubject<T> | AccountDto,
    ) => {
        const isOrgCheck =
            check instanceof AccountDto ||
            check === SubjectEntity.ORG ||
            (typeof check === 'object' && check.hasOwnProperty('__caslSubjectType__'))
        const entity = isOrgCheck ? check : this.appState.abilityStore.getOrgOwnedIdentityFor(check as T)

        return this.appState.abilityStore.can(action, entity as ICaslSubject<T> | AccountDto)
    }
}
