import * as React from 'react'
import { Input, Select } from 'antd'
import autobind from 'autobind-decorator'
import { isProxyEvent } from '../../../../_utils/utils'
import { BetterComponent } from '../../../better-component/better-component'
import { RuleBuilderOperator } from '../../../rule-builder/operators'
import './custom-field.input.scss'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import { TypeaheadTransform } from '../typeahead/typeahead'

interface ICustomFieldProps {
    rule: IRule
    fields: any[]
    operators: RuleBuilderOperator[]
    onChange: (value: any) => any
    typeaheadUrl?: string
    typeaheadTransform?: TypeaheadTransform
    suggestions?: string[]
    values?: string[]
    autofocus?: boolean
}

export class CustomFieldInput extends BetterComponent<ICustomFieldProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-custom-field-input'
    public ref: any
    public fieldRef: any
    public valueRef: any

    public componentDidMount(): void {
        this.autofocus()
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className="field">{this.renderPropertySelect()}</div>

                <div className="operator">
                    <Select
                        dropdownClassName={this.buildClassName('operator-dropdown')}
                        value={this.rule.operator}
                        onChange={this.handleOperatorChange}
                    >
                        {this.props.operators.map((opr) => (
                            <Select.Option key={opr.value} value={opr.value}>
                                {opr.longDisplay}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className="value">
                    {this.isExistenceOperatorSelected ? (
                        ''
                    ) : (
                        <Input
                            ref={(el) => (this.valueRef = el)}
                            value={this.rule.value}
                            onChange={(e) => this.handleValueChange(e.target.value)}
                        />
                    )}
                </div>
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected renderPropertySelect(): React.ReactNode {
        if (!this.props.fields || this.props.fields.length === 0) {
            return (
                <Input
                    ref={(el) => (this.fieldRef = el)}
                    placeholder="Provide a custom field"
                    value={this.cleanProfileProperty(this.rule.property)}
                    onChange={this.handlePropertyChange}
                />
            )
        }

        const fieldOptions = this.props.fields.map((field) => (
            <Select.Option key={field} value={field}>
                {field}
            </Select.Option>
        ))

        return (
            <Select
                placeholder="Select a custom field"
                value={this.cleanProfileProperty(this.rule.property)}
                onChange={this.handlePropertyChange}
            >
                {fieldOptions}
            </Select>
        )
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get isExistenceOperatorSelected(): boolean {
        return this.rule.operator === 'exists' || this.rule.operator === 'not_exists'
    }

    protected isSelectionExistenceOperator(selection: string): boolean {
        return selection === 'exists' || selection === 'not_exists'
    }

    protected autofocus(): void {
        if (this.props.autofocus) {
            if (this.isExistenceOperatorSelected || !this.rule.value) {
                this.fieldRef.focus()
            } else {
                this.valueRef?.focus()
            }
        }
    }

    protected cleanProfileProperty(property: string): string | undefined {
        return !property ? '' : property.replace(/^profile\./, '')
    }

    @autobind
    protected async handlePropertyChange(property: any): Promise<void> {
        if (isProxyEvent(property)) {
            property = property.target.value.trim()
        }

        this.emitChange({ property: `profile.${this.cleanProfileProperty(property)}` })
    }

    @autobind
    protected async handleOperatorChange(operator: string): Promise<void> {
        const changeset: any = {
            operator,
        }

        if (this.isSelectionExistenceOperator(changeset.operator)) {
            changeset.value = true
        }

        this.emitChange(changeset)
    }

    @autobind
    protected async handleValueChange(value: any): Promise<void> {
        this.emitChange({ value })
    }

    protected async emitChange(changes: any): Promise<void> {
        const pack = {
            property: changes.property ?? this.rule.property,
            operator: changes.operator || this.rule.operator,
            value: changes.value ?? this.rule.value,
        }

        if (this.isExistenceOperatorSelected) {
            pack.value = true
        }

        this.props.onChange(pack)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
