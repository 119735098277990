import * as numeral from 'numeral'

export const numberWithCommas = (input: number): string => {
    return numeral(input).format('O,O')
}

export const numberAsCurrency = (input: number, precision: number = 3): string => {
    return numeral(input).format(`O,O.${new Array(precision).fill('O').join('')}`)
}

export const numberWithPercent = (input: number, precision: number = 2): string => {
    let format = !Number.isFinite(precision) ? 'O.OO%' : `O.${new Array(precision).fill('O').join('')}%`
    return numeral(input).format(format)
}

export const numberWithSuffix = (input: number): string => {
    return numeral(input).format('Oo')
}
