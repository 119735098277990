export enum UserType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    INTERNAL_API = 'INTERNAL_API',
    EXTERNAL_API = 'EXTERNAL_API',
}

export enum UserTypeId {
    INTERNAL = 1,
    EXTERNAL = 2,
    INTERNAL_API = 3,
    EXTERNAL_API = 4,
}
