import * as React from 'react'
import './scheduling-datetime-picker.scss'
import * as moment from 'moment-timezone'
import { Moment } from 'moment-timezone/moment-timezone'
import * as clone from 'clone'
import classnames from 'classnames'
import { getClassNames } from '../../_utils/classnames'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Switch, DatePicker, TimePicker, Select } from 'antd'
import { DomainDto } from '../../dtos/domain'
import { getSupportedTimezones } from '../../_utils/moment'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { SwitchSize } from 'antd/lib/switch'

export interface ISchedulingDatetimePickerValue {
    dateTime?: Moment | string
    time?: Moment | string
    timeZone?: string
}

export interface ISchedulingDatetimePicker {
    className?: string
    loading?: boolean
    label?: string
    size?: SizeType
    switchSize?: SwitchSize
    mode?: 'default' | 'datetime' | 'time'
    timeZone?: string
    hideTimeZoneOptions?: boolean
    stzDisabled?: boolean
    stzTooltip?: React.ReactNode | string
    disabled?: boolean
    value?: ISchedulingDatetimePickerValue
    onChange?: (value: ISchedulingDatetimePickerValue) => any

    domain?: DomainDto
}

export interface IState {
    tmpValue?: ISchedulingDatetimePickerValue
}

export class SchedulingDatetimePicker extends React.Component<ISchedulingDatetimePicker, IState> {
    public state: IState = {}

    public componentDidMount() {
        const defaultValue = this.value
        if (!defaultValue.timeZone) {
            defaultValue.timeZone = this.suggestedTimeZone
        }

        this.setState({ tmpValue: defaultValue })
        this.props.onChange?.(defaultValue)
    }

    public render() {
        const { className, label, timeZone, hideTimeZoneOptions, stzDisabled, stzTooltip, disabled } = this.props

        const size = this.props.size ?? 'middle'
        const switchSize = this.props.switchSize ?? size === 'small' ? 'small' : 'default'
        const mode = this.props.mode ?? 'default'
        const isTimeMode = mode === 'time'
        const isSTZ = this.value.timeZone === 'STZ'

        return (
            <div
                className={getClassNames('sdtp-builder', className, `size-${size}`, {
                    ['disabled']: disabled,
                    ['stz-disabled']: stzDisabled,
                    ['mode-datetime']: !isTimeMode,
                    ['mode-time']: isTimeMode,
                })}
            >
                {!hideTimeZoneOptions && (
                    <div className="switch-row sdtp-stz-switch-row">
                        <div className="switch-left">
                            <Switch
                                size={switchSize}
                                checked={isSTZ}
                                disabled={disabled || stzDisabled}
                                onChange={async (checked) =>
                                    this.handleTimeZoneChange(checked ? 'STZ' : this.suggestedTimeZone)
                                }
                            />
                        </div>
                        <div className="switch-right">
                            <span className="sdtp-stz-label">
                                <span>Subscriber Time Zone Delivery (STZ)</span>
                                {!!stzTooltip && (
                                    <Popover
                                        className={getClassNames('sdtp-stz-popover')}
                                        overlayClassName={getClassNames('sdtp-stz-popover-overlay')}
                                        content={stzTooltip}
                                    >
                                        <span>
                                            <InfoCircleOutlined className="info-icon" />
                                        </span>
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                )}

                <div className="sdtp-dt-builder">
                    {!!label && (
                        <div className="sdtp-dt-picker-label">
                            <span className="label-icon" />
                            <span className="label-text">{label}</span>
                        </div>
                    )}

                    <div className="sdtp-dt-picker-wrapper">
                        {isTimeMode ? (
                            <TimePicker
                                className="sdtp-dt-picker"
                                size={size}
                                placeholder="Select Time"
                                disabled={disabled}
                                use12Hours={true}
                                format="h:mm A"
                                value={this.timeValue}
                                onChange={this.handleTimeChange}
                                onSelect={this.handleTimeChange}
                            />
                        ) : (
                            <DatePicker
                                className="sdtp-dt-picker"
                                size={size}
                                showTime={{
                                    format: 'hh:mm',
                                    use12Hours: true,
                                }}
                                format="ddd, MMM DD, YYYY \a\t h:mm a"
                                placeholder="Select Time"
                                disabled={disabled}
                                value={this.dateTimeValue}
                                onChange={this.handleDateTimeChange}
                            />
                        )}
                    </div>

                    <span
                        className={classnames('sdtp-tz-description', {
                            empty: !timeZone || !hideTimeZoneOptions,
                        })}
                    >
                        {this.getTZDescription(timeZone, hideTimeZoneOptions, isSTZ)}
                    </span>

                    {!hideTimeZoneOptions && !isSTZ && (
                        <div className="sdtp-tz-picker-wrapper">
                            <Select<string>
                                className="sdtp-tz-picker"
                                dropdownClassName={getClassNames('sdtp-tz-picker-dropdown')}
                                size={size}
                                disabled={disabled}
                                showSearch={true}
                                placeholder="Select a time zone"
                                value={this.timeZoneValue}
                                onChange={this.handleTimeZoneChange}
                            >
                                {this.timeZoneNames.map((tz) => (
                                    <Select.Option key={tz} value={tz}>
                                        {tz}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    protected get value(): ISchedulingDatetimePickerValue {
        const value = this.props.value ??
            this.state.tmpValue ?? {
                timeZone: this.suggestedTimeZone,
            }

        if (this.props.timeZone) {
            value.timeZone = this.props.timeZone
        }

        return clone(value)
    }

    protected get timeZoneNames(): string[] {
        return getSupportedTimezones()
    }

    protected get suggestedTimeZone(): string {
        return this.props.domain?.timezone ?? moment.tz.guess()
    }

    protected get timeZoneValue(): string {
        const tz = this.value.timeZone
        return !tz || tz === 'STZ' ? this.suggestedTimeZone : tz
    }

    protected get dateTimeValue(): Moment | undefined {
        return !this.value.dateTime ? undefined : moment.tz(this.value.dateTime, this.timeZoneValue)
    }

    protected get timeValue(): Moment | undefined {
        return !this.value.time ? undefined : this.value.time
    }

    protected getTZDescription(timeZone: string | undefined, hideTimeZoneOptions: boolean = false, isSTZ: boolean) {
        return !(timeZone && hideTimeZoneOptions)
            ? ''
            : isSTZ
            ? "in each subscriber's time zone"
            : `in the ${timeZone} time zone`
    }

    protected handleDateTimeChange = async (dtValue: Moment, _dtValueStr: string) => {
        await this.setState(({ tmpValue }) => ({
            tmpValue: {
                ...tmpValue,
                time: undefined,
                dateTime: dtValue,
            },
        }))

        this.props.onChange?.({
            ...this.value,
            time: undefined,
            dateTime: dtValue,
        })
    }

    protected handleTimeChange = async (timeValue: Moment) => {
        await this.setState(({ tmpValue }) => ({
            tmpValue: {
                ...tmpValue,
                dateTime: undefined,
                time: timeValue,
            },
        }))

        this.props.onChange?.({
            ...this.value,
            dateTime: undefined,
            time: timeValue,
        })
    }

    protected handleTimeZoneChange = async (tz: string | undefined) => {
        await this.setState(({ tmpValue }) => ({
            tmpValue: {
                ...tmpValue,
                timeZone: tz,
            },
        }))

        this.props.onChange?.({
            ...this.value,
            timeZone: tz,
        })
    }
}
