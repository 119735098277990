import * as i18next from 'i18next'
import { action } from 'mobx'
import { Container, Singleton } from 'typescript-ioc/es5'
import { deepmerge } from '../_utils/utils'
import { enTranslations } from '../translations/en'
import { AppState } from '../stores/app'

let TranslationServiceInstance: TranslationService

@Singleton
export class TranslationService {
    public static get instance(): TranslationService {
        return TranslationServiceInstance
    }

    private appState: AppState

    private i18n: any

    constructor() {
        this.appState = Container.get(AppState)

        this.i18n = i18next.init({
            lng: 'en',
            resources: {
                en: enTranslations,
                // js: this.backfill(jsTranslations),
            },
        })

        TranslationServiceInstance = this
    }

    @action.bound
    public setLocale(locale: string) {
        this.appState.locale = locale
    }

    public tr(key: string) {
        return this.i18n.t(key, { lng: this.appState.locale })
    }

    private backfill(translations: any) {
        return deepmerge({}, enTranslations, translations)
    }
}
