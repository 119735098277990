import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationActionType } from '../../interfaces/notification-action'
import { generateShortID } from '../../components/campaign-builder/helpers/uid'

export class NotificationActionModel {
    public static build(props: NotificationActionModel | any): NotificationActionModel {
        const data = props instanceof NotificationActionModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationActionModel()
        model.setId(scData.id)
        model.setOrdinal(scData.ordinal)
        model.setLabel(scData.label)
        model.setType(scData.type ?? model.getType())
        model.setLandingUrl(scData.landing_url)
        model.setUsePrimaryLandingUrl(scData.use_primary_landing_url)
        model.setDisplayMeta(data.displayMeta ?? data.display_meta)

        return model
    }

    private id: number | undefined
    private ordinal: number | undefined
    private label: string | undefined
    private type: NotificationActionType = NotificationActionType.OPEN_URL
    private landingUrl: string | undefined
    private usePrimaryLandingUrl: boolean
    private displayMeta: any = {}

    public clone(): NotificationActionModel {
        return NotificationActionModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            id: this.getId(),
            ordinal: this.getOrdinal(),
            label: this.getLabel(),
            type: this.getType(),
            landing_url: this.getLandingUrl(),
            use_primary_landing_url: this.getUsePrimaryLandingUrl(),
            display_meta: this.getDisplayMeta(),
        })
    }

    public getId(): number | undefined {
        return this.id
    }
    public setId(id: number | undefined): void {
        this.id = id
    }

    public getOrdinal(): number | undefined {
        return this.ordinal
    }
    public setOrdinal(ordinal: number | undefined): void {
        this.ordinal = ordinal
    }

    public getLabel(): string | undefined {
        return this.label
    }
    public setLabel(label: string | undefined): void {
        this.label = label
    }

    public getType(): NotificationActionType {
        return this.type
    }
    public setType(type: NotificationActionType): void {
        this.type = type
    }

    public getLandingUrl(): string | undefined {
        return this.landingUrl
    }
    public setLandingUrl(landingUrl: string | undefined): void {
        this.landingUrl = landingUrl
    }

    public getUsePrimaryLandingUrl(): boolean {
        return this.usePrimaryLandingUrl || false
    }
    public setUsePrimaryLandingUrl(usePrimaryLandingUrl: boolean): void {
        this.usePrimaryLandingUrl = usePrimaryLandingUrl ?? false
    }

    public getDisplayMeta(): any {
        return this.displayMeta
    }
    public setDisplayMeta(meta: any): void {
        this.displayMeta = meta ?? {
            // eid is a UI key
            eid: generateShortID(),
        }
    }
}
