import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps } from '../interfaces/filter-builder'
import { getClassNames } from '../../../_utils/classnames'
import { AppService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { NotificationTestSearchBar } from '../../../components/notification-test-search-bar/notification-test-search-bar'
import { PromptSearchBar } from '../../../components/prompt-search-bar/prompt-search-bar'
import './style/test-id.filter-builder.scss'
import clone from 'clone'

interface ITestIdFilterBuilder extends IFilterBuilderProps {
    testEntity: 'notifications' | 'prompts'
}

export class TestIdFilterBuilder extends BetterComponent<ITestIdFilterBuilder, any> {
    protected appService: AppService

    constructor(props: ITestIdFilterBuilder) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        return (
            <>
                <div className={getClassNames('filter', 'abid-filter')}>
                    <div className={getClassNames('filter-wrapper')}>
                        <div className={getClassNames('filter-display')}>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                        </div>
                        <div className={getClassNames('filter-editor')}>
                            <div className="filter-op">
                                <BetterSelect
                                    size="small"
                                    disableSearch={true}
                                    dropdownClassName="filter-op-dropdown"
                                    options={[
                                        { value: 'IN', label: 'is' },
                                        { value: 'NOT_IN', label: 'is not' },
                                    ]}
                                    defaultValue={['IN']}
                                    value={this.currentValue.operator}
                                    onChange={(operator: string) => {
                                        this.handleChange({ operator })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="filter-val">
                            {this.props.testEntity === 'notifications' ? (
                                <NotificationTestSearchBar
                                    className="notif-test-search"
                                    mode={this.props.mode === 'display' ? 'display' : 'search'}
                                    value={this.currentValueStr}
                                    placeholder="Search ID or Name"
                                    onChange={this.handleTestChange}
                                    getDropdownContainer={this.appService.getAppContainer}
                                    preload={true}
                                />
                            ) : (
                                <PromptSearchBar
                                    className="prompt-test-search"
                                    mode={this.props.mode === 'display' ? 'display' : 'search'}
                                    value={this.currentValueStr}
                                    placeholder="Search ID or Name"
                                    onChange={this.handleTestChange}
                                    getDropdownContainer={this.appService.getAppContainer}
                                    entityLevel="promptGroup"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return clone({
            field: 'TEST_ID',
            operator: propsValue.operator || 'IN',
            value: propsValue.value,
        })
    }

    protected get currentOperatorDisplay(): any {
        return this.currentValue.operator === 'IN' ? 'is' : 'is not'
    }

    protected get currentValueStr(): any {
        const value = this.currentValue.value || undefined
        return Array.isArray(value) ? value.join(',') : value
    }

    protected handleTestChange = (testId: number) => {
        this.handleChange({ value: !testId ? undefined : [testId] })
    }

    protected handleChange(config: any): any {
        if (!!config) {
            this.props.onChange({
                ...this.props.value,
                ...config,
                field: 'TEST_ID',
            })
        } else {
            this.props.onChange()
        }
    }
}
