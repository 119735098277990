import { Container, Singleton } from 'typescript-ioc/es5'
import { simpleNotification } from '../_utils/utils'
import { AppService } from './app'
import * as querystring from 'query-string'
import { AppState } from '../stores/app'
import { NotificationScheduleDto } from '../features/notifications/dtos/notification-schedule-dto'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { ServiceApiResponse } from '@pushly/aquarium-utils/lib/react'
import aqe from '@pushly/aqe'
import IApiCallOptions from '../interfaces/api-call-options'
import { ApiVersion } from '../enums/api-version.enum'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class NotificationScheduleService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAllByDomainId(
        domainId: number,
        opts: IApiCallOptions = {},
    ): Promise<ServiceApiResponse<NotificationScheduleDto[]>> {
        let response: ServiceApiResponse<NotificationScheduleDto[]> = {
            ok: false,
            data: [],
            meta: {},
            cancelled: false,
        }

        if (opts.showLoadingScreen) this.appService.setModuleLoading()
        const options = querystring.stringify(opts.query, { encode: false })

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/${
                        opts.version ?? ApiVersion.NONE
                    }domains/${domainId}/notification-schedules?${options}`,
                },
                opts.cancellationKey,
            )

            const { data: rawSchedules, ...resMeta } = res.data
            const schedules = rawSchedules.map(NotificationScheduleDto.parse)

            response.ok = true
            response.data = schedules
            response.meta = resMeta
        } catch (error) {
            if (isAxiosCancellation(error)) {
                response.cancelled = true
            }

            response.error = error
        }

        if (opts.showLoadingScreen) this.appService.unsetModuleLoading()

        return response
    }

    public async fetchById(
        domainId: number,
        scheduleId: number,
        cancellationKey?: string,
    ): Promise<NotificationScheduleDto | undefined> {
        this.appService.setModuleLoading()

        let schedule: NotificationScheduleDto | undefined
        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v4/domains/${domainId}/notification-schedules/${scheduleId}`,
                },
                cancellationKey,
            )

            schedule = NotificationScheduleDto.parse(req.data.data)
        } catch (error) {}

        this.appService.unsetModuleLoading()

        return schedule
    }

    public async cancelSchedule(schedule: NotificationScheduleDto, cancellationKey?: string): Promise<boolean> {
        this.appService.setModuleLoading()
        const domainId = this.appState.currentDomain!.id

        let cancelled = false
        try {
            await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-schedules/${schedule.id}`,
                    data: { status: 'cancelled' },
                },
                cancellationKey,
            )

            cancelled = true
            simpleNotification(
                'success',
                `Delivery of this notification has been cancelled. 
                Note: If this notification had recurrence or used user-timezone-based delivery 
                some users may have already received it.`.trim(),
            )
        } catch (error) {
            handleResponseErrorMessage(error, {
                fallbackMessage: 'Notification could not be cancelled at this time',
            })
        }

        this.appService.unsetModuleLoading()

        return cancelled
    }

    public async setScheduleDetails(
        schedule: NotificationScheduleDto,
        details: string | null,
        cancellationKey?: string,
    ): Promise<void> {
        this.appService.setModuleLoading()
        const domainId = this.appState.currentDomain!.id

        try {
            await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/notification-schedules/${schedule.id}`,
                    data: { additionalDetails: details },
                },
                cancellationKey,
            )

            simpleNotification('success', 'Notification details updated.')
        } catch (error) {
            simpleNotification('error', 'Notification could not be updated at this time.')
            throw error
        }

        this.appService.unsetModuleLoading()
    }
}
