import * as React from 'react'

export const useMountedRef = (): [React.MutableRefObject<boolean>, (cb: () => any) => any] => {
    const mounted = React.useRef(true)
    const runIfMounted = (cb: () => any) => {
        if (mounted.current) {
            cb()
        }
    }

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    })

    return [mounted, runIfMounted]
}
