import * as React from 'react'
import { Tooltip } from 'antd'

type FeatureDisplayWrapperType = 'tooltip' | any

interface IFDWProps {
    type: FeatureDisplayWrapperType
    forwardProps: any
    children: JSX.Element | JSX.Element[]
}

export const FeatureDisplayWrapper = ({ type, forwardProps, children }: IFDWProps) => {
    let Wrapper: React.ComponentType
    switch (type) {
        case 'tooltip':
            Wrapper = Tooltip
            break
        default:
            Wrapper = (_: any) => <>{_.children}</>
    }
    return <Wrapper {...forwardProps}>{children}</Wrapper>
}
