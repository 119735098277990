import * as React from 'react'
import autobind from 'autobind-decorator'
import './distribution-slider.scss'
import { Slider, Handles, Tracks, Rail, Ticks, SliderItem, SliderModeValue } from 'react-compound-slider'

type DistributionSliderType = 'split' | 'sample'

interface IDistributionSliderProps {
    className?: string
    type?: DistributionSliderType
    totalDistributions: number
    value?: number[]
    onChange: (value: number[]) => any
    stepSize?: number
    showSteps?: boolean
    distributionStyles?: string[]
    min?: number
    sampleMin?: number
    disabled?: boolean
    hideLegend?: boolean
    legendLabel?: string | React.ReactNode
    numericLabels?: boolean
}

interface IDistributionSliderState {
    value?: any[]
}

let CHANGE_DEBOUNCE: any

export class DistributionSlider extends React.Component<IDistributionSliderProps, IDistributionSliderState> {
    public readonly defaultClassName: string = 'sw-v2-dist-slider'

    protected containerRef: any

    public constructor(props: IDistributionSliderProps) {
        super(props)

        this.state = {}
    }

    public componentDidUpdate(prevProps: Readonly<IDistributionSliderProps>): void {
        if (this.props.totalDistributions !== prevProps.totalDistributions || this.props.type !== prevProps.type) {
            if (!!CHANGE_DEBOUNCE) clearTimeout(CHANGE_DEBOUNCE)
            CHANGE_DEBOUNCE = setTimeout(() => {
                this.handleChange(this.getDistributions(true, true))
            }, 10)
        }
    }

    public render(): React.ReactNode {
        return (
            <>
                <Slider
                    className={this.buildRootClassNames()}
                    domain={[0, this.max]}
                    values={this.getDistributions()}
                    step={this.stepSize}
                    mode={this.handleCoreUpdate}
                    onChange={this.handleChange}
                >
                    <Rail>
                        {({ getRailProps }) => <div className={this.buildClassName('rail')} {...getRailProps()} />}
                    </Rail>
                    <Handles>
                        {({ activeHandleID, handles, getHandleProps }) => (
                            <div className={this.buildClassName('handles')}>
                                {handles.map(({ id, percent, value }, idx) => (
                                    <div
                                        key={id}
                                        className={this.buildClassName(
                                            'handle',
                                            activeHandleID === id ? 'active' : 'inactive',
                                        )}
                                        style={{
                                            left: `${percent}%`,
                                        }}
                                        {...getHandleProps(id)}
                                    >
                                        <div className={this.buildClassName('handle-value')}>
                                            {this.parseHandleValue(idx, value, handles)}%
                                        </div>
                                    </div>
                                ))}
                                {this.type === 'sample' && (
                                    <div
                                        className={this.buildClassName('handle', 'sample-handle')}
                                        style={{
                                            left: `100%`,
                                        }}
                                    >
                                        <div className={this.buildClassName('handle-value')}>
                                            {100 - handles[handles.length - 1].value}%
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </Handles>
                    <Tracks right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className={this.buildClassName('tracks')}>
                                {tracks.map(({ id, source, target }, idx) => (
                                    <div
                                        key={id}
                                        className={this.buildClassName('track', this.getDistribtuionClassName(idx))}
                                        style={{
                                            left: `${source.percent}%`,
                                            width: `${target.percent - source.percent}%`,
                                        }}
                                        {...getTrackProps()}
                                    />
                                ))}
                                {this.type === 'sample' && (
                                    <div
                                        className={this.buildClassName('track', 'sample-track')}
                                        style={{
                                            left: `${tracks[tracks.length - 1].target.percent}%`,
                                            width: `${100 - tracks[tracks.length - 1].target.percent}%`,
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </Tracks>
                    {this.props.showSteps && (
                        <Ticks values={this.tickValues}>
                            {({ ticks }) => (
                                <div className={this.buildClassName('ticks')}>
                                    {ticks.map(({ id, percent, value }) => (
                                        <div key={id} className={this.buildClassName('tick')}>
                                            <div
                                                className={this.buildClassName('tick-line')}
                                                style={{
                                                    left: `${percent}%`,
                                                }}
                                            />
                                            <div
                                                className={this.buildClassName('tick-value')}
                                                style={{
                                                    marginLeft: `${-(100 / ticks.length) / 2}%`,
                                                    width: `${100 / ticks.length}%`,
                                                    left: `${percent}%`,
                                                }}
                                            >
                                                {value}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </Ticks>
                    )}

                    {!this.props.hideLegend && (
                        <div className={this.buildClassName('legend')}>
                            <div className={this.buildClassName('legend-wrapper')}>
                                {!!this.props.legendLabel && (
                                    <div className={this.buildClassName('legend-title')}>{this.props.legendLabel}</div>
                                )}
                                {this.getDistributions().map((d, idx) => (
                                    <div
                                        key={idx}
                                        className={this.buildClassName(
                                            'legend-item',
                                            this.getDistribtuionClassName(idx),
                                        )}
                                    >
                                        <span key={idx} className={this.buildClassName('legend-label')}>
                                            Variant {this.props.numericLabels ? idx + 1 : ['A', 'B', 'C', 'D'][idx]}
                                        </span>
                                    </div>
                                ))}
                                {this.type === 'sample' && (
                                    <div className={this.buildClassName('legend-item', 'sample-winner')}>
                                        <span className={this.buildClassName('legend-label')}>Winner</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Slider>
            </>
        )
    }

    public reset(): void {
        this.setState({ value: undefined })
    }

    protected get type(): DistributionSliderType {
        return this.props.type || 'split'
    }

    protected get containerRect(): any {
        return !this.containerRef ? {} : this.containerRef.getBoundingClientRect()
    }

    protected get min(): number {
        const min = this.props.min || 5
        return min < 0 ? 0 : min
    }

    protected get sampleMin(): number {
        const min = this.props.sampleMin || this.props.min || 10
        return min < 5 ? 5 : min
    }

    // TODO: should we support configurable min?
    protected get max(): number {
        return 100
    }

    protected get stepSize(): number {
        if (this.props.stepSize && this.props.stepSize * this.props.totalDistributions > this.max)
            throw new Error('Invalid stepSize! stepSize * distributions must not exceed specified max (default: 100).')

        return this.props.stepSize || 5
    }

    protected get tickValues(): number[] {
        const ticks: number[] = []
        const stepSize = this.stepSize

        for (let i = 0; i * stepSize <= this.max; i++) {
            ticks.push(i * stepSize)
        }

        return ticks
    }

    protected get defaultDistributions(): number[] {
        let dists: number[] = new Array(this.props.totalDistributions)
        dists.fill(0)

        if (this.type === 'split') {
            const seed = this.max / this.props.totalDistributions
            dists = dists.map((d, idx) => seed * (idx + 1))
        } else {
            const seed = 24 / this.props.totalDistributions
            dists = dists.map((d, idx) => seed * (idx + 1))
        }

        return dists
    }

    protected getDistributions(ignorePropsValue: boolean = false, throwErrors: boolean = false): number[] {
        let dists = this.defaultDistributions

        if (!ignorePropsValue && !!this.props.value && this.props.value.length !== 0) {
            if (this.props.value.length !== this.props.totalDistributions)
                if (throwErrors) throw new Error('Invalid value! Value array must contain value for each distribtion.')

            const distTotal = this.props.value.reduce((n, s) => s + n, 0)
            if (distTotal > 100) if (throwErrors) throw new Error('Invalid value! Total values must not exceed 100.')

            if (this.type === 'split') {
                if (distTotal < 100)
                    if (throwErrors)
                        throw new Error('Invalid value! Value array must contain value for each distribtion.')
            }

            dists = this.props.value.map((dist, idx) => this.parseDistributions(idx, dist, this.props.value!))
        }

        return this.state.value || dists
    }

    protected getDistribtuionClassName(idx: number): string | undefined {
        const styles = this.props.distributionStyles || []
        if (styles.length === 0) return

        return styles[idx % styles.length]
    }

    protected parseHandleValue(idx: number, value: number, handles: SliderItem[]): number {
        return idx === 0 ? value : value - handles[idx - 1].value
    }

    protected parseValue(idx: number, value: number, values: number[]): number {
        return idx === 0 ? Math.floor(value) : Math.floor(value - values[idx - 1])
    }

    protected parseDistributions(idx: number, value: number, values: number[]): number {
        return idx === 0
            ? Math.floor(value)
            : Math.floor(value + values.filter((v, x) => x < idx).reduce((n, s) => (s += n), 0))
    }

    @autobind
    protected handleCoreUpdate(
        curr: SliderModeValue[],
        next: SliderModeValue[],
        step: number,
        reverse: boolean,
        getValue: (x: number) => number,
    ): ReadonlyArray<SliderModeValue> {
        const nextContainsSub5 = next.some((item, idx) => {
            const pre = next[idx - 1]
            const post = next[idx + 1]

            return (
                item.val < this.min ||
                (pre !== undefined && item.val - pre.val < this.min) ||
                (post !== undefined && post.val - item.val < this.min)
            )
        })

        next = nextContainsSub5 ? curr : next

        // mode2 - prevent crossing
        for (let i = 0; i < curr.length; i++) {
            if (curr[i].key !== next[i].key) next = curr
            else if (next[i + 1] && next[i].val === next[i + 1].val) next = curr
        }

        if (this.type === 'sample') {
            const last = next[next.length - 1]
            if (100 - last.val < this.sampleMin) next = curr
        }

        return next
    }

    @autobind
    protected handleChange(values: number[]): void {
        values = values.map((dist, idx) => this.parseValue(idx, dist, values))

        // prevent a fractional value - only happens on 3 way distribution reset
        if (values.every((v) => v === 33)) {
            values[1] = 34
        }

        this.props.onChange(values)
    }

    protected emitChange(): void {
        this.props.onChange([])
    }

    protected buildClassName(className: string, extras?: string): string {
        className = `${this.defaultClassName}-${className}`
        if (!!extras) className = `${className} ${extras}`
        return className
    }

    protected buildRootClassNames(extras?: string): string {
        const classNames: string[] = [this.defaultClassName, `type-${this.type}`]

        if (!!extras) classNames.push(extras)
        if (this.props.showSteps) classNames.push('show-steps')
        if (!!this.props.className) classNames.push(this.props.className)
        if (this.props.disabled) classNames.push('disabled')

        return classNames.join(' ')
    }
}
