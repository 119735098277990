import { useRef, useState, MutableRefObject, Dispatch, SetStateAction } from 'react'

export const useRefState = <T extends any>(initialState: T): [MutableRefObject<T>, Dispatch<SetStateAction<T>>] => {
    const vRef = useRef<T>(initialState)
    const [_vState, _setState] = useState<T>(vRef.current)

    const setState: Dispatch<SetStateAction<T>> = async (value: T | ((prevState: T) => T)) => {
        vRef.current = typeof value === 'function' ? (value as Function)(vRef.current) : value
        _setState(vRef.current)
    }

    return [vRef, setState]
}
