import React from 'react'
import { ActionEditorProps } from '../interfaces'
import { NotificationDto } from '../../../features/notifications'
import { ActionType, CampaignEditableState } from '../enums'
import CampaignNotificationEditor from './campaign-notification.editor'
import { updateNodeParams } from '../helpers/node'
import { stripUndefined } from '../../../_utils/strip-undefined'
import { Skeleton } from 'antd'

const SendNotificationEditor = React.forwardRef<any, ActionEditorProps>(
    ({ domain, node, chart, onChange, ...props }, ref) => {
        const type = node.properties?.[node.type]?.type
        const strategy = node.properties?.[node.type]?.params?.item_strategy
        let template = node.properties?.[node.type]?.params?.notification
        if (template) {
            template = NotificationDto.parse(template)
        }

        const campaign = (chart as any).builder.campaign
        const isSendCartNotification = type === ActionType.SEND_CART_NOTIFICATION
        const isSendContentWebhookNotification = type === ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION

        const isReadonlyMode = props.editableState === CampaignEditableState.READONLY

        return (
            <div>
                <Skeleton
                    loading={!domain}
                    active={true}
                    title={false}
                    avatar={false}
                    paragraph={{
                        rows: 1,
                        width: '100%',
                    }}
                >
                    {!domain ? (
                        <></>
                    ) : (
                        <CampaignNotificationEditor
                            ref={ref}
                            className={`drawer-mode${isReadonlyMode ? ' readonly' : ''}`}
                            actionType={type}
                            actionIndex={node.actionIndex}
                            campaign={campaign}
                            domain={domain}
                            template={template}
                            defaultEcommStrategy={strategy}
                            hideImageMacroToggle={!isSendCartNotification}
                            showWebookOptions={isSendContentWebhookNotification}
                            previewMeta={{
                                campaign_id: campaign.id,
                                campaign_notification_type: isSendCartNotification
                                    ? 'cart_notification'
                                    : 'standard_notification',
                            }}
                            readonly={isReadonlyMode}
                            onSubmit={(notification, builder) => {
                                onChange(
                                    updateNodeParams(
                                        node,
                                        stripUndefined({
                                            title: notification?.template?.channels?.default?.title,
                                            notification,
                                            item_strategy: builder?.ecommItemPickStrategy,
                                        }),
                                        true,
                                    ),
                                )
                            }}
                        />
                    )}
                </Skeleton>
            </div>
        )
    },
)

export default SendNotificationEditor
