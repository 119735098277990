import { APPLICATION_VERSION } from '../constants'
import aqe from '@pushly/aqe'

export async function isApplicationVersionCurrent(): Promise<boolean | null> {
    const runningVersion = APPLICATION_VERSION
    let isCurrent: boolean | null = null

    const envLocal = process.env.LOCAL_ENV ?? 'false'
    const isLocal = envLocal.toString().toLowerCase() === 'true'

    if (isLocal) {
        // App should show always as current when running locally
        isCurrent = true
    } else {
        try {
            const req = await fetch(`https://${location.host}/index.html`)
            const html = await req.text()
            const parser = new DOMParser()
            const doc = parser.parseFromString(html, 'text/html')

            const currVersion = (doc.querySelector('meta[name="application-version"]') as HTMLMetaElement)?.content
            if (currVersion) {
                isCurrent = runningVersion === currVersion
            }
        } catch (err) {
            console.debug('ver verification failure', err)
        }
    }

    return isCurrent
}
