import React from 'react'
import { SegmentListState } from './interfaces'

export type SegmentListContext = SegmentListState & {
    onSearchClick(value: string): Promise<void>
    onFilterChange<
        FilterKey extends keyof SegmentListState['filters'],
        FilterValue extends SegmentListState['filters'][FilterKey],
    >(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void>
    onRefreshClick(ev: React.MouseEvent<HTMLElement>): Promise<void>
    onRefreshEnabledChange(enabled: boolean): void
}

export const SegmentListContext = React.createContext<SegmentListContext>({} as SegmentListContext)
