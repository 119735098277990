import * as React from 'react'

export const IconPushly = React.memo((props: { className?: string; animateProps?: any }) => (
    <svg
        className={props.className}
        viewBox="0 0 1024 1024"
        data-icon="pushly"
        height="1em"
        width="1em"
        fill="currentColor"
        aria-hidden="true"
    >
        <path
            d="M974.602,155.62c-19.456-28.467-44.726-50.823-75.131-66.504C868.37,73.101,833.89,65,796.94,65H627.57
		c-133.438,0-191.312,150.503-195.753,172.101l-137.34,633.557c-4.745,23.105-0.408,44.47,12.582,61.762l0.583,0.754
		c13.851,17.016,33.681,25.999,57.352,25.999c21.516,0,41.884-7.499,60.515-22.298l0.626-0.496
		c18.031-15.303,29.467-33.975,33.898-55.611L509.199,661h0.096l0.156-1H683.35c15.58,0,31.317-1.585,47.115-4.47
		c1.955-0.312,3.893-0.726,5.831-1.083c0.295-0.061,0.582-0.103,0.877-0.172c16.945-3.241,33.612-8.571,53.521-16.931
		c35.149-14.876,67.996-36.081,97.489-62.89c63.287-56.596,104.206-126.062,121.646-206.563
		C1027.156,286.257,1015.304,214.818,974.602,155.62z M844.334,357.59c-8.264,37.557-26.19,69.391-54.831,97.311
		c-4.814,4.549-9.603,8.563-14.399,12.308C753.041,484.05,731.056,492,708.376,492h-163.21l26.304-123.719l15.815-74.359
		c6.108-16.141,16.554-30.349,31.195-42.254l0.6-0.269C632.671,241.041,647.218,234,662.442,232h109.29
		c26.442,0,45.682,10.73,60.558,34.24C847.923,292.036,851.834,321.858,844.334,357.59z"
        />
        <path
            d="M355.313,182.636l-0.382-0.486c-8.838-10.845-21.473-16.58-36.558-16.58c-13.738,0-26.721,4.784-38.617,14.221
		l-0.382,0.317c-11.515,9.763-18.805,21.774-21.62,35.582L226.785,362h-0.209l-82.23,386.867
		c-3.032,14.736-0.261,28.353,8.021,39.371l0.383,0.491c8.828,10.853,21.472,16.567,36.557,16.567c13.739,0,26.729-4.78,38.6-14.218
		l0.392-0.32c11.514-9.76,18.787-21.706,21.637-35.514L280.904,609h0.199l82.239-386.928
		C366.367,207.33,363.586,193.667,355.313,182.636z"
        />
        <path
            d="M168.494,240.5l-0.339-0.43c-7.725-9.506-18.821-14.533-32.039-14.533c-12.035,0-23.418,4.192-33.837,12.461l-0.348,0.282
		c-10.08,8.56-16.476,18.842-18.952,30.942L78.321,287h-0.174L6.075,626.329c-2.659,12.931-0.226,24.976,7.021,34.647l0.339,0.482
		c7.734,9.506,18.831,14.558,32.048,14.558c12.044,0,23.427-4.175,33.838-12.456l0.339-0.262c10.097-8.56,16.476-19.02,18.96-31.133
		L125.768,504h0.182l49.584-228.937C178.184,262.141,175.759,250.164,168.494,240.5z"
        />
        {props.animateProps && <animate {...props.animateProps} />}
    </svg>
))
