import React from 'react'

const FirebaseIcon = React.memo((props: { className?: string; animateProps?: any }) => (
    <svg
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
        className={props.className}
        width="18"
        height="18"
        viewBox="5 -5 30 30.000001"
        version="1.0"
    >
        <defs>
            <clipPath id="id1">
                <path d="M 6.371094 2 L 19 2 L 19 22 L 6.371094 22 Z M 6.371094 2 " />
            </clipPath>
            <clipPath id="id2">
                <path d="M 6.371094 8 L 17 8 L 17 22 L 6.371094 22 Z M 6.371094 8 " />
            </clipPath>
            <clipPath id="id3">
                <path d="M 6.371094 6 L 23.789062 6 L 23.789062 25.8125 L 6.371094 25.8125 Z M 6.371094 6 " />
            </clipPath>
        </defs>
        <g clipPath="url(#id1)">
            <path
                fill="currentColor"
                d="M 15.628906 5.648438 C 15.417969 5.246094 14.832031 5.246094 14.625 5.648438 L 13.34375 8.003906 L 10.398438 2.445312 C 10.144531 1.980469 9.421875 2.097656 9.335938 2.617188 L 6.371094 21.3125 L 18.480469 11.070312 Z M 15.628906 5.648438 "
            />
        </g>
        <g clipPath="url(#id2)">
            <path
                fill="currentColor"
                d="M 16.054688 13.121094 L 13.34375 8.003906 L 6.371094 21.3125 Z M 16.054688 13.121094 "
            />
        </g>
        <g clipPath="url(#id3)">
            <path
                fill="currentColor"
                d="M 21.535156 7.390625 C 21.464844 6.964844 20.929688 6.792969 20.613281 7.101562 L 6.371094 21.316406 L 14.253906 25.367188 C 14.753906 25.640625 15.359375 25.640625 15.859375 25.367188 L 23.789062 21.316406 Z M 21.535156 7.390625 "
            />
        </g>
        {props.animateProps && <animate {...props.animateProps} />}
    </svg>
))

export default FirebaseIcon
