import clone from 'clone-deep'
import { stripUndefined } from '@pushly/aquarium-utils/lib/common/helpers/object'
import { WhiteLabelColorConfig } from '../../structs/white-label-color-config'
import { streamApplicationError } from '../../_utils/airbrake'

export interface IFaviconResource {
    rel: string
    href: string
}

export class WhitelabelConfigModel {
    public static build(props: WhitelabelConfigModel | any): WhitelabelConfigModel {
        const data = props instanceof WhitelabelConfigModel ? props.serialize() : clone(props)

        const model = new WhitelabelConfigModel()
        model.setId(data.id)
        model.setIsActive(data.is_active)
        model.setDisplayName(data.display_name)
        model.setHostname(data.hostname)
        model.setPreviewDomainId(data.preview_domain_id)
        model.setPreviewDomainKey(data.preview_domain_key)
        model.setDocumentationLink(data.documentation_link ?? null)
        model.setSupportEmailAddress(data.support_email_address ?? null)
        model.setFaviconResources(data.favicon_resources)
        model.setLogoUrl(data.logo_url ?? null)
        model.setPrimaryIconUrl(data.primary_icon_url ?? null)
        model.setSidebarIconUrl(data.sidebar_icon_url ?? null)
        model.setLoginBackgroundUrl(data.login_background_url ?? null)
        model.setPrimaryColorHex(data.primary_color_hex ?? null)
        model.setSecondaryColorHex(data.secondary_color_hex ?? null)
        model.setTertiaryColorHex(data.tertiary_color_hex ?? null)
        model.setAuth0TenantId(data.auth0_tenant_id)
        model.setAuth0HostedLoginDomain(data.auth0_hosted_login_domain)
        model.setAuth0PlatformClientId(data.auth0_platform_client_id)
        model.setAuth0AudienceName(data.auth0_audience_name)
        model.setCreatedAt(data.created_at)
        model.setCreatedBy(data.created_by)
        model.setCreatedByUserName(data.created_by_user_name)

        try {
            if (data.color_config && typeof data.color_config === 'object' && Object.keys(data.color_config).length) {
                model.setColorConfig(
                    WhiteLabelColorConfig.Builder.setPrimaryHex(data.color_config.primary_hex)
                        .setSecondaryHex(data.color_config.secondary_hex)
                        .setTertiaryHex(data.color_config.tertiary_hex)
                        .setPrimaryButtonHex(data.color_config.primary_button_hex)
                        .setPrimaryButtonHoverHex(data.color_config.primary_button_hover_hex)
                        .setPrimaryButtonActiveHex(data.color_config.primary_button_active_hex)
                        .setDomainSidebarHex(data.color_config.domain_sidebar_hex)
                        .setDomainSidebarHoverHex(data.color_config.domain_sidebar_hover_hex)
                        .setDomainSidebarActiveHex(data.color_config.domain_sidebar_active_hex)
                        .setOrgSidebarHex(data.color_config.org_sidebar_hex)
                        .setOrgSidebarHoverHex(data.color_config.org_sidebar_hover_hex)
                        .setOrgSidebarActiveHex(data.color_config.org_sidebar_active_hex)
                        .setTabHex(data.color_config.tab_hex)
                        .setSwitchHex(data.color_config.switch_hex)
                        .build(),
                )
            }
        } catch (err) {
            streamApplicationError(err, {
                detailedName: 'invalid_white_label_color_configuration',
                colorConfig: data.color_config,
            })
        }

        return model
    }

    private id: number
    private displayName: string
    private hostname: string
    private isActive: boolean
    private previewDomainId: number
    private previewDomainKey: string
    private documentationLink: string | null
    private supportEmailAddress: string | null
    private loginBackgroundUrl: string | null
    private faviconResources: IFaviconResource[]
    private logoUrl: string | null
    private primaryIconUrl: string | null
    private sidebarIconUrl: string | null
    private primaryColorHex: string | null
    private secondaryColorHex: string | null
    private tertiaryColorHex: string | null
    private colorConfig: WhiteLabelColorConfig | null
    private auth0TenantId: number
    private auth0HostedLoginDomain: string
    private auth0PlatformClientId: string
    private auth0AudienceName: string
    private createdAt: Date | string
    private createdBy: string
    private createdByUserName: string

    public clone(): WhitelabelConfigModel {
        return WhitelabelConfigModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            display_name: this.getDisplayName(),
            is_active: this.getIsActive(),
            hostname: this.getHostname(),
            preview_domain_id: this.getPreviewDomainId(),
            preview_domain_key: this.getPreviewDomainKey(),
            documentation_link: this.getDocumentationLink(),
            support_email_address: this.getSupportEmailAddress(),
            login_background_url: this.getLoginBackgroundUrl(),
            favicon_resources: this.getFaviconResources(),
            logo_url: this.getLogoUrl(),
            primary_icon_url: this.getPrimaryIconUrl(),
            sidebar_icon_url: this.getSidebarIconUrl(),
            primary_color_hex: this.getPrimaryColorHex(),
            secondary_color_hex: this.getSecondaryColorHex(),
            tertiary_color_hex: this.getTertiaryColorHex(),
            color_config: this.getColorConfig()?.serialize() ?? null,
            auth0_tenant_id: this.getAuth0TenantId(),
            auth0_hosted_login_domain: this.getAuth0HostedLoginDomain(),
            auth0_platform_client_id: this.getAuth0PlatformClientId(),
            auth0_audience_name: this.getAuth0AudienceName(),
            created_at: this.getCreatedAt(),
            created_by: this.getCreatedBy(),
            created_by_user_name: this.getCreatedByUserName(),
        })
    }

    public getId(): number {
        return this.id
    }
    public setId(id: number) {
        this.id = id
    }

    public getDisplayName() {
        return this.displayName
    }
    public setDisplayName(displayName: string) {
        this.displayName = displayName
    }

    public getHostname() {
        return this.hostname
    }
    public setHostname(hostname: string) {
        this.hostname = hostname
    }

    public getIsActive() {
        return this.isActive
    }
    public setIsActive(isActive: boolean) {
        this.isActive = isActive
    }

    public getPreviewDomainId() {
        return this.previewDomainId
    }
    public setPreviewDomainId(id: number) {
        this.previewDomainId = id
    }

    public getPreviewDomainKey() {
        return this.previewDomainKey
    }
    public setPreviewDomainKey(domainKey: string) {
        this.previewDomainKey = domainKey
    }

    public getDocumentationLink() {
        return this.documentationLink
    }
    public setDocumentationLink(link: string | null) {
        this.documentationLink = link
    }

    public getSupportEmailAddress() {
        return this.supportEmailAddress
    }
    public setSupportEmailAddress(addr: string | null) {
        this.supportEmailAddress = addr
    }

    public getLoginBackgroundUrl() {
        return this.loginBackgroundUrl
    }
    public setLoginBackgroundUrl(url: string | null) {
        this.loginBackgroundUrl = url
    }

    public getFaviconResources() {
        return this.faviconResources
    }
    public setFaviconResources(resources: any) {
        this.faviconResources = resources
    }

    public getLogoUrl() {
        return this.logoUrl
    }
    public setLogoUrl(url: string | null) {
        this.logoUrl = url
    }

    public getPrimaryIconUrl() {
        return this.primaryIconUrl
    }
    public setPrimaryIconUrl(url: string | null) {
        this.primaryIconUrl = url
    }

    public getSidebarIconUrl() {
        return this.sidebarIconUrl
    }
    public setSidebarIconUrl(url: string) {
        this.sidebarIconUrl = url
    }

    public getPrimaryColorHex() {
        return this.primaryColorHex
    }
    public setPrimaryColorHex(hex: string | null) {
        this.primaryColorHex = hex
    }

    public getSecondaryColorHex() {
        return this.secondaryColorHex
    }
    public setSecondaryColorHex(hex: string | null) {
        this.secondaryColorHex = hex
    }

    public getTertiaryColorHex() {
        return this.tertiaryColorHex
    }
    public setTertiaryColorHex(hex: string | null) {
        this.tertiaryColorHex = hex
    }

    public getColorConfig() {
        return this.colorConfig ?? null
    }
    public setColorConfig(config: WhiteLabelColorConfig | null) {
        this.colorConfig = config ?? null
    }

    public getAuth0TenantId(): number | null {
        return this.auth0TenantId
    }
    public setAuth0TenantId(value: number) {
        this.auth0TenantId = value
    }

    public getAuth0AudienceName(): string | null {
        return this.auth0AudienceName
    }
    public setAuth0AudienceName(value: string) {
        this.auth0AudienceName = value
    }

    public getAuth0HostedLoginDomain(): string | null {
        return this.auth0HostedLoginDomain
    }
    public setAuth0HostedLoginDomain(value: string) {
        this.auth0HostedLoginDomain = value
    }

    public getAuth0PlatformClientId(): string | null {
        return this.auth0PlatformClientId
    }
    public setAuth0PlatformClientId(value: string) {
        this.auth0PlatformClientId = value
    }

    public getCreatedAt() {
        return this.createdAt
    }
    public setCreatedAt(date: Date | string) {
        this.createdAt = date
    }

    public getCreatedBy() {
        return this.createdBy
    }
    public setCreatedBy(userId: string) {
        this.createdBy = userId
    }

    public getCreatedByUserName() {
        return this.createdByUserName
    }
    public setCreatedByUserName(userName: string) {
        this.createdByUserName = userName
    }
}
