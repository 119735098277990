import * as React from 'react'
import { observer } from 'mobx-react'
import { BetterComponent } from '../better-component/better-component'
import { cleanNumericDisplays } from '../../_utils/utils'
import * as numeral from 'numeral'

interface IProps {
    impressions: number
    clicks: number
    status?: string
}

interface IState {}

@observer
export class CtrColumnView extends BetterComponent<IProps, IState> {
    public render() {
        const clicks = this.props.clicks
        const impressions = this.props.impressions

        let value = numeral(impressions === 0 ? 0 : clicks / impressions).format('O.OO%')
        value = cleanNumericDisplays(value, 'pct')

        return (
            <div>
                <span>{!this.props.clicks ? '--' : value}</span>
            </div>
        )
    }
}
