import AirbrakeClient from 'airbrake-js'
import { NON_ACTIONABLE_ERRORS } from '../constants'
import { UserDto } from '../dtos/user'
import { DomainDto } from '../dtos/domain'

let airbrakeClient: AirbrakeClient | undefined
export const getAirbrakeClient = (): AirbrakeClient => {
    if (!airbrakeClient) {
        airbrakeClient = new AirbrakeClient({
            projectId: 477859,
            projectKey: '5aed6fdc6173c89e04aa4b4a40208bfc',
            environment: process.env.NODE_ENV ?? 'development',
            ignoreWindowError: true,
        })
    }

    return airbrakeClient
}

export const streamApplicationError = (error: any, info: any, currentUser?: UserDto, currentDomain?: DomainDto) => {
    if (process.env.NODE_ENV !== 'production') {
        console.error(error, info)
        return
    }

    /**
     * Do not send non-actionable errors to remote
     */
    if (error instanceof Error && NON_ACTIONABLE_ERRORS.some((r) => r.test(error.message))) {
        return
    }

    /**
     * Fix for Notifier.notify() ignoring passed
     * annotations when error is passed directly.
     */
    const wrappedError = new Error(error)
    if (error instanceof Error) {
        wrappedError.name = error.name ?? wrappedError.name
        wrappedError.message = error.message ?? wrappedError.message
        wrappedError.stack = error.stack ?? wrappedError.stack
    }

    /**
     * componentStack comes in as a line break
     * separated backtrace string. Break apart
     * to ensure better readability in airbrake
     * dashboard.
     */
    let componentStack = info?.componentStack
    if (typeof componentStack === 'string') {
        try {
            componentStack = componentStack
                .split(/\n/)
                .map((s) => s.trim())
                .filter((s) => s)
        } catch {}
    }

    /**
     * Inject any pertinent information for the
     * current client session.
     */
    const session = {
        user_id: currentUser?.id,
        domain_id: currentDomain?.id,
    }

    getAirbrakeClient().notify({
        error: wrappedError,
        params: {
            info: {
                ...info,
                componentStack,
            },
        },
        session,
    })
}
