import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { RecurrenceSchedule } from '../campaign/recurrence-schedule'
import { DeliverableStrategies } from '../../enums/deliverable-strategies.enum'

export interface IDeliveryStrategy {
    type: DeliverableStrategies
    to: string[]
}

export class ScheduleConfigurationModel {
    public static build(props: ScheduleConfigurationModel | any): ScheduleConfigurationModel {
        const data = props instanceof ScheduleConfigurationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new ScheduleConfigurationModel()
        model.setSchedule(
            scData.schedule ?? {
                type: 'daily',
                time_zone: 'UTC',
                allowed_days: ['monday'],
                specific_time: '07:00',
                frequency: 1,
            },
        )
        model.setDeliveryStrategies(scData.delivery_strategies ?? [])

        return model
    }

    private schedule?: RecurrenceSchedule
    private delivery_strategies: IDeliveryStrategy[]

    public clone(): ScheduleConfigurationModel {
        return ScheduleConfigurationModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            schedule: this.getSchedule()?.serialize(),
            delivery_strategies: this.getDeliveryStrategies(),
        })
    }

    public setSchedule(schedule: RecurrenceSchedule | any): void {
        this.schedule = RecurrenceSchedule.build(schedule ?? {})
    }
    public getSchedule(): RecurrenceSchedule | undefined {
        return this.schedule
    }

    public setDeliveryStrategies(delivery_strategies: IDeliveryStrategy[]): void {
        this.delivery_strategies = delivery_strategies
    }
    public getDeliveryStrategies(): IDeliveryStrategy[] {
        return this.delivery_strategies ?? []
    }
}
