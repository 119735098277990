import React from 'react'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { convertCancellationKeyToToken } from '../config/axios-setup'
import { simpleNotification, titleCase } from '../_utils/utils'
import { executeRequest } from '@pushly/aqe/lib/api/helpers'
import { HttpMethod } from '@pushly/aqe/lib/enums'
import { IApiResponse } from '@pushly/aqe/lib/interfaces'
import { OrgNotificationModel } from '../models/notification/org-notification.model'
import { StatusType } from '../enums/status-type'
import aqe from '@pushly/aqe'
import { ApiVersion } from '../enums/api-version.enum'
import IApiCallOptions from '../interfaces/api-call-options'

type IdParam = number | string

@Singleton
export class AccountNotificationService {
    private readonly appState: AppState
    private readonly appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public async fetchAll(accountId: IdParam, opts: IApiCallOptions = {}): Promise<IApiResponse<any[]>> {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        await convertCancellationKeyToToken(opts)
        const res = await executeRequest<any[]>(
            HttpMethod.GET,
            `${aqe.defaults.publicApiDomain}/${opts.version ?? ApiVersion.NONE}accounts/${accountId}/notifications`,
            null,
            opts ?? null,
        )

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return res
    }

    public async fetchById(
        accountId: IdParam,
        notifId: IdParam,
        opts: IApiCallOptions = {},
    ): Promise<IApiResponse<any>> {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        await convertCancellationKeyToToken(opts)
        const res = await executeRequest<any[]>(
            HttpMethod.GET,
            `${aqe.defaults.publicApiDomain}/${
                opts.version ?? ApiVersion.NONE
            }accounts/${accountId}/notifications/${notifId}`,
            null,
            opts ?? null,
        )

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return res
    }

    public async create(accountId: IdParam, notif: any, opts: IApiCallOptions = {}): Promise<IApiResponse<any>> {
        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        await convertCancellationKeyToToken(opts)
        const res = await executeRequest<any[]>(
            HttpMethod.POST,
            `${aqe.defaults.publicApiDomain}/${opts.version ?? ApiVersion.NONE}accounts/${accountId}/notifications`,
            notif,
            opts ?? null,
        )

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (res.ok) {
            simpleNotification('success', 'Notification has been successfully scheduled.')
        } else if (!!res.error && !res.cancelled) {
            this.handleErrorNotificationResponse(res.error)
        }

        return res
    }

    public async update(
        notification: OrgNotificationModel,
        updates: any,
        opts: IApiCallOptions = {},
    ): Promise<IApiResponse<any>> {
        const orgId = notification.getAccountId()
        const notifId = notification.getId()

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        await convertCancellationKeyToToken(opts)
        const res = await executeRequest<any[]>(
            HttpMethod.PATCH,
            `${aqe.defaults.publicApiDomain}/${
                opts.version ?? ApiVersion.NONE
            }accounts/${orgId}/notifications/${notifId}`,
            updates,
            opts ?? null,
        )

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        if (res.ok) {
            simpleNotification('success', 'Notification successfully updated.')
        } else if (!!res.error && !res.cancelled) {
            this.handleErrorNotificationResponse(res.error)
        }

        return res
    }

    public async updateNotes(
        notification: OrgNotificationModel,
        notes: null | string,
        opts?: IApiCallOptions,
    ): Promise<IApiResponse<any>> {
        return this.update(notification, { notes }, opts)
    }

    public async cancel(notification: OrgNotificationModel, opts?: IApiCallOptions): Promise<IApiResponse<any>> {
        return this.update(notification, { status: StatusType.CANCELLED.name }, opts)
    }

    protected handleErrorNotificationResponse(error: any) {
        const errMessage = error.response?.data?.message

        if (!!errMessage) {
            const message = errMessage.replace(/(?:template\.channels\.default\.)([^\s]+)/gi, (...args: any[]) => {
                if (args.length > 1) {
                    return titleCase(args[1])
                } else {
                    return args[0]
                }
            })
            const refUrl = error.response.data.reference_url

            simpleNotification(
                'error',
                !refUrl ? (
                    message
                ) : (
                    <>
                        {message.replace(/\.$/, '')}. See our{' '}
                        <a href={refUrl} target="_blank">
                            documentation
                        </a>{' '}
                        for more information on this error.
                    </>
                ),
            )
        } else {
            simpleNotification('error', `Notification could not be updated at this time.`)
        }
    }
}
