import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export type SubscriberFcapType = 'once' | 'once_per_criteria'
export type SubscriberFcapWindow = 'days' | 'weeks' | 'months' | 'years'

export class RecurrenceSubscriberFcap {
    public static build(props: RecurrenceSubscriberFcap | any): RecurrenceSubscriberFcap {
        const data = props instanceof RecurrenceSubscriberFcap ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new RecurrenceSubscriberFcap()
        model.setType(data.type)
        if (model.getType() === 'once_per_criteria') {
            model.setFrequency(scData.criteria?.frequency)
            model.setWindow(scData.criteria?.window)
        }

        return model
    }

    private type: SubscriberFcapType
    private frequency?: number
    private window?: SubscriberFcapWindow

    public clone(): RecurrenceSubscriberFcap {
        return RecurrenceSubscriberFcap.build(this)
    }

    public serialize(_: boolean = true): any {
        const serialized: any = {
            type: this.getType(),
        }

        if (serialized.type === 'once_per_criteria') {
            serialized.criteria = {
                frequency: this.getFrequency(),
                window: this.getWindow(),
            }
        }

        return stripUndefined(serialized)
    }

    public setType(type: SubscriberFcapType): void {
        this.type = type

        if (type === 'once') {
            this.frequency = undefined
            this.window = undefined
        }
    }
    public getType(): SubscriberFcapType {
        return this.type
    }

    public setFrequency(frequency: number | undefined): void {
        if (frequency !== undefined) {
            if (String(frequency) !== '' && !isNaN(frequency)) {
                frequency = parseInt(frequency.toString(), 10)
            }
        }

        this.frequency = frequency
    }
    public getFrequency(): number | undefined {
        return this.frequency
    }

    public setWindow(window: SubscriberFcapWindow | undefined): void {
        this.window = window
    }
    public getWindow(): SubscriberFcapWindow | undefined {
        return this.window
    }
}
