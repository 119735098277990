import { convertCase } from '../../_utils/utils'
import * as moment from 'moment'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { CampaignType } from '../../components/campaign-builder/enums'
import { CampaignConfigurationModel } from './campaign-configuration.model'
import { StatusType } from '../../enums/status-type'
import { CampaignRevision } from './campaign-revision'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class CampaignModel {
    public static build(props: CampaignModel | any): CampaignModel {
        const data = props instanceof CampaignModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new CampaignModel()
        model.setId(scData.id)
        model.setDomainId(scData.domain_id)
        model.setType(scData.campaign_type ?? scData.type)
        model.setName(scData.name)
        model.setStatusId(scData.status_id ?? StatusType.ACTIVE.id)
        model.setDateTimeZone(scData.date_time_zone)
        model.setDateStart(scData.date_start)
        model.date_start_utc = scData.date_start_utc
        model.setDateEnd(scData.date_end)
        model.date_end_utc = scData.date_end_utc
        model.date_completed_utc = scData.date_completed_utc
        model.manually_completed = scData.manually_completed
        model.setConfiguration(scData.configuration ?? {})
        model.setRevisions(scData.revisions)
        model.setCurrentRevision(scData.revision)

        const channels = (scData.channels ?? [])
            .map((ch: unknown) => DeliveryChannel.fromString(String(ch)))
            .filter((ch) => ch !== undefined)

        model.setChannels(channels)

        return model
    }

    private domain_id: number
    private id?: number
    private type: CampaignType
    private name?: string
    private status_id: number
    private date_time_zone?: string
    private date_start?: string
    private date_start_utc?: string
    private date_end?: string
    private date_end_utc?: string
    private date_completed_utc?: string
    private manually_completed: boolean | null
    private configuration: CampaignConfigurationModel
    private channels: DeliveryChannel[]
    private revisions: CampaignRevision[] = []
    private revision?: CampaignRevision

    public clone(): CampaignModel {
        return CampaignModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            domain_id: this.getDomainId(),
            id: this.getId(),
            campaign_type: this.getType(),
            name: this.getName(),
            status_id: this.getStatusId(),
            date_time_zone: this.getDateTimeZone(),
            date_start: this.getDateStart(),
            date_start_utc: this.getDateStartUtc(),
            date_end: this.getDateEnd(),
            date_end_utc: this.getDateEndUtc(),
            date_completed_utc: this.getDateCompletedUtc(),
            manually_completed: this.getManuallyCompleted(),
            configuration: this.getConfiguration().serialize(),
            channels: this.getChannels(),
            revisions: this.getRevisions().map((r) => r.serialize()),
            revision: this.getCurrentRevision()?.serialize(),
        })
    }

    public setDomainId(domainId: number) {
        this.domain_id = domainId
    }
    public getDomainId(): number {
        return this.domain_id
    }

    public setId(id: number | undefined) {
        this.id = id
    }
    public getId(): number | undefined {
        return this.id
    }

    public setType(type: CampaignType | string | undefined): void {
        type = type?.toString().trim().toLowerCase()
        this.type = type as CampaignType
    }
    public getType(): CampaignType {
        return this.type
    }

    public setName(name: string | undefined, trim: boolean = true): void {
        this.name = !name ? undefined : trim ? name.trim() : name
    }
    public getName(): string | undefined {
        return this.name
    }

    public setStatusId(id: number): void {
        this.status_id = parseInt(id.toString(), 10)
    }
    public getStatusId(): number {
        return this.status_id
    }

    public setConfiguration(config: CampaignConfigurationModel | any): void {
        this.configuration = CampaignConfigurationModel.build(config ?? {})
    }
    public getConfiguration(): CampaignConfigurationModel {
        return this.configuration
    }

    public setChannels(channels: DeliveryChannel[]): void {
        this.channels = channels
    }
    public getChannels(): DeliveryChannel[] {
        return this.channels
    }

    public setDateTimeZone(timeZone: string): void {
        this.date_time_zone = timeZone
    }
    public getDateTimeZone(): string | undefined {
        return this.date_time_zone
    }

    public setDateStart(dateStart: string | undefined): void {
        this.date_start = !dateStart ? undefined : moment(dateStart).format('YYYY-MM-DD HH:mm')
    }
    public getDateStart(): string | undefined {
        return this.date_start
    }
    public getDateStartUtc(): string | undefined {
        return this.date_start_utc
    }

    public setDateEnd(dateEnd: string | undefined): void {
        this.date_end = !dateEnd ? undefined : moment(dateEnd).format('YYYY-MM-DD HH:mm')
    }
    public getDateEnd(): string | undefined {
        return this.date_end
    }
    public getDateEndUtc(): string | undefined {
        return this.date_end_utc
    }

    public getDateCompletedUtc(): string | undefined {
        return this.date_completed_utc
    }
    public getManuallyCompleted(): boolean | null {
        return this.manually_completed ?? null
    }

    public setCurrentRevision(revision: CampaignRevision | any): void {
        this.revision = !revision ? undefined : CampaignRevision.build(revision)
    }
    public getCurrentRevision(): CampaignRevision | undefined {
        return this.revision
    }

    public setRevisions(revisions: (CampaignRevision | any)[]): void {
        revisions = revisions ?? []
        this.revisions = revisions.map((rev) => CampaignRevision.build(rev))
    }
    public getRevisions(): CampaignRevision[] {
        return this.revisions ?? []
    }
}
