import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import './style/account-data-form.scss'
import { AccountDto } from '../../dtos/account.dto'
import { AccountService, UserService } from '../../services/index'
import { AccountDetailsWell } from './account-details-well'
import { AccountBillingWell } from './account-billing-well'
import { AccountBillingHistoryWell } from './account-billing-history-well'
import { accountIsBillable } from '../../_utils/account'
import { getClassNames } from '../../_utils/classnames'

interface IAccountDataForm {
    accountId: number
    className?: string
}

interface IAccountDataFormState {
    account?: AccountDto
}

export class AccountDataForm extends React.Component<IAccountDataForm, IAccountDataFormState> {
    public readonly state: IAccountDataFormState = {}

    private accountService: AccountService
    private userService: UserService

    public constructor(props: any) {
        super(props)

        this.accountService = Container.get(AccountService)
        this.userService = Container.get(UserService)

        if (props.accountId) {
            this.fetchRequestedAccount()
        }
    }

    public render() {
        return (
            <div className={getClassNames('adf', this.props.className)}>
                <div className={getClassNames('adf-wrapper')}>
                    <AccountDetailsWell
                        loading={this.isLoading}
                        account={this.state.account!}
                        onAccountUpdated={this.handleAccountUpdated}
                    />

                    {this.canShowBilling && (
                        <>
                            <AccountBillingWell
                                loading={this.isLoading}
                                account={this.state.account!}
                                onAccountUpdated={this.handleAccountUpdated}
                            />

                            {accountIsBillable(this.state.account) && (
                                <AccountBillingHistoryWell loading={this.isLoading} account={this.state.account!} />
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }

    protected get isLoading(): boolean {
        return !!this.props.accountId && !this.state.account
    }

    protected get canShowBilling(): boolean {
        return (
            !!this.state.account && !!this.state.account.billingData && this.state.account.billingData.type === 'CREDIT'
        )
    }

    protected handleAccountUpdated = async (updates: Partial<AccountDto>) => {
        this.setState(({ account }) => ({
            account: {
                ...account,
                ...updates,
            } as AccountDto,
        }))
    }

    protected async fetchRequestedAccount() {
        const account = await this.accountService.fetchById(this.props.accountId, {}, false)

        this.setState({ account })
    }
}
