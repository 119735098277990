import React from 'react'
import './sw-simple-link-pagination.scss'
import classnames from 'classnames'
import { Button, Divider, TablePaginationConfig } from 'antd'
import { IApiResponsePaginationLinks } from '../../interfaces/api-response-pagination-links'
import { tryParseInt } from '../../_utils/try-parse'

interface IProps<RecordType extends object = any> {
    currentTableData: Readonly<RecordType[]>
    links?: IApiResponsePaginationLinks
    onPrev?: (ev: React.MouseEvent<HTMLElement>, link: string, config: Partial<TablePaginationConfig>) => any
    onNext?: (ev: React.MouseEvent<HTMLElement>, link: string, config: Partial<TablePaginationConfig>) => any
}

function SwSimpleLinkPagination<RecordType extends object = any>(props: IProps<RecordType>) {
    const paginationLinks: React.ReactNode[] = []

    const prevLink = props.links?.prev
    const nextLink = props.links?.next

    paginationLinks.push(
        <Button
            key="sw-table-pagination-prev"
            className={classnames('sw-table-pagination-prev', 'sw-table-pagination-link', {
                'sw-table-pagination-link-disabled': !prevLink,
            })}
            size="small"
            disabled={!prevLink}
            onClick={(ev) => {
                if (prevLink) {
                    const prevUrl = new URL(prevLink)
                    const prevPage = tryParseInt(prevUrl.searchParams.get('page')!)

                    props.onPrev?.(ev, prevLink, { current: prevPage })
                }
            }}
        >
            <span>Previous</span>
        </Button>,
    )

    paginationLinks.push(<Divider key="sw-table-pagination-div" className="sw-table-pagination-div" type="vertical" />)

    paginationLinks.push(
        <Button
            key="sw-table-pagination-next"
            className={classnames('sw-table-pagination-next', 'sw-table-pagination-link', {
                'sw-table-pagination-link-disabled': !nextLink,
            })}
            size="small"
            disabled={!nextLink}
            onClick={(ev) => {
                if (nextLink) {
                    const nextUrl = new URL(nextLink)
                    const nextPage = tryParseInt(nextUrl.searchParams.get('page')!)

                    props.onNext?.(ev, nextLink, { current: nextPage })
                }
            }}
        >
            <span>Next</span>
        </Button>,
    )

    return (
        <div
            className={classnames('sw-table-pagination', 'sw-simple-link-pagination', {
                'sw-table-pagination-has-prev': !!prevLink,
                'sw-table-pagination-has-next': !!nextLink,
                'sw-table-pagination-single-page': !prevLink && !nextLink,
            })}
        >
            {paginationLinks}
        </div>
    )
}

export default SwSimpleLinkPagination
