import React from 'react'
import './styles/notification-test-builder.scss'
import { Well } from '@pushly/aqe/lib/components'
import { InfoCircleOutlined, Loading3QuartersOutlined, WarningOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Radio, Input, Switch, Skeleton, Modal, Tooltip, Popover } from 'antd'
import { NotificationTestConfigurationModel } from '../../../models/notification/notification-test-configuration.model'
import { getClassNames } from '../../../_utils/classnames'
import { useInputState } from '../../../_utils/use-input-state'
import { isAllFeatTest } from '../helpers'

const Confirm = Modal.confirm

interface INotificationTestBuilder {
    value: NotificationTestConfigurationModel | undefined
    onChange: (value: NotificationTestConfigurationModel | null) => any
    loading?: boolean
    disabled?: boolean
    toggleDisabled?: boolean
    toggleDisabledReason?: string
}

const NotificationTestBuilder = (props: INotificationTestBuilder) => {
    const { loading, disabled, value, onChange, toggleDisabled, toggleDisabledReason } = props

    const workingModel: NotificationTestConfigurationModel = !!value
        ? value.clone()
        : NotificationTestConfigurationModel.build({})

    const [nameValue, setNameValue] = useInputState((name) => {
        const update = workingModel.clone()
        update.setName(name)
        onChange(update)
    })

    const testTypes = workingModel.getTestTypes()
    const distMap = workingModel.getDistributionMap()
    const totalDistributions = distMap?.length ?? 1
    const isSampleDistribution = workingModel.getDistributionType() === 'sample'

    const ToggleWrapper = !toggleDisabled || !toggleDisabledReason ? ({ children }: any) => children : Tooltip
    const DeliveryTypeWrapper = isSampleDistribution ? Popover : (_) => _.children
    const deliveryDisabledReason = 'Testing delivery cannot be enabled when using Sample Distribution.'

    return (
        <Well
            className={getClassNames('notification-test-builder', 'nested', {
                builderEnabled: !!value,
                collapsed: !value,
            })}
            title={
                <>
                    <span className="title">A/B Testing</span>

                    {loading ? (
                        <Loading3QuartersOutlined key="config-collapse-loading" spin={true} />
                    ) : (
                        <ToggleWrapper key="config-collapse" title={toggleDisabledReason}>
                            <Switch
                                size="small"
                                disabled={!!disabled || !!toggleDisabled}
                                checked={!!value}
                                onChange={(enabled) => {
                                    const update = !enabled ? undefined : workingModel
                                    if (!!update) {
                                        update.setDistributionMap([100])
                                    } else {
                                        setNameValue(undefined, false)
                                    }

                                    onChange(update!)
                                }}
                            />
                        </ToggleWrapper>
                    )}
                </>
            }
            hideFooter={true}
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                <Well mode="ghost" hideHeader={true} hideFooter={true}>
                    <Form.Item label="Name">
                        <Input
                            size="middle"
                            disabled={disabled}
                            value={nameValue ?? workingModel.getName()}
                            onChange={(ev) => {
                                const v = ev.target.value
                                setNameValue(v)
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                Type
                                <Popover
                                    overlayClassName={getClassNames(
                                        'notification-builder-popover',
                                        'test-type-popover',
                                    )}
                                    content={
                                        <>
                                            <p>Choose the elements of the notification you want to test:</p>

                                            <p>
                                                <b>Content:</b> Variants can be created with separate title, body,
                                                image, and url.
                                            </p>
                                            <p>
                                                <b>Delivery:</b> Variants can be created with separate delivery times
                                                and strategies.
                                            </p>
                                            <p>
                                                <b>Content & Delivery:</b> Variants allow testing all Content and
                                                Delivery components of the notification.
                                            </p>
                                        </>
                                    }
                                >
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                            </span>
                        }
                    >
                        <Radio.Group
                            size="small"
                            buttonStyle="solid"
                            disabled={disabled}
                            value={isAllFeatTest(testTypes) ? 'both' : workingModel.getTestTypes()[0]}
                            onChange={(ev) => {
                                const update = workingModel.clone()
                                const v = ev.target.value

                                if (v === 'both') {
                                    update.setTestTypes(['content', 'delivery'])
                                } else {
                                    update.setTestTypes([v])
                                }

                                if (totalDistributions > 1 && !isAllFeatTest(update.getTestTypes())) {
                                    Confirm({
                                        icon: <WarningOutlined />,
                                        title: 'Type Change',
                                        content: (
                                            <p>
                                                Changing test types while you have more than one variant configured will
                                                remove all variants except the one that is currently selected.
                                            </p>
                                        ),
                                        onOk: () => onChange(update),
                                        okText: 'Change',
                                        cancelText: 'Cancel',
                                    })
                                } else {
                                    onChange(update)
                                }
                            }}
                        >
                            <Radio.Button value="content">Content</Radio.Button>
                            <DeliveryTypeWrapper content={deliveryDisabledReason}>
                                <Radio.Button value="delivery" disabled={isSampleDistribution}>
                                    Delivery
                                </Radio.Button>
                                <Radio.Button value="both" disabled={isSampleDistribution}>
                                    Content & Delivery
                                </Radio.Button>
                            </DeliveryTypeWrapper>
                        </Radio.Group>
                    </Form.Item>
                </Well>
            </Skeleton>
        </Well>
    )
}

export default NotificationTestBuilder
