import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'

interface IResizeWatcherProps {
    onSizeChange: (bounds: IBounds) => void
}

interface IResizeWatcherState {}

export interface IBounds {
    elementWidth: number
    elementHeight: number
    windowWidth: number
    windowHeight: number
}

export class ResizeWatcher extends BetterComponent<IResizeWatcherProps, IResizeWatcherState> {
    private ref = React.createRef<HTMLDivElement>()
    private checkInterval: any

    private bounds: IBounds = {
        elementWidth: -1,
        elementHeight: -1,
        windowWidth: -1,
        windowHeight: -1,
    }

    public constructor(props: IResizeWatcherProps) {
        super(props)

        this.checkInterval = setInterval(this.checkSizeChange.bind(this), 100)
    }

    public render(): React.ReactNode {
        return (
            <div className="resize-watcher" ref={this.ref}>
                {' '}
            </div>
        )
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        clearInterval(this.checkInterval)
    }

    protected checkSizeChange() {
        const parentNode = this.ref?.current?.parentNode as any

        if (parentNode) {
            const elementWidth = parentNode.clientWidth
            const elementHeight = parentNode.clientHeight
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight

            if (
                elementWidth !== this.bounds.elementWidth ||
                elementHeight !== this.bounds.elementHeight ||
                windowWidth !== this.bounds.windowWidth ||
                windowHeight !== this.bounds.windowHeight
            ) {
                this.bounds.elementWidth = elementWidth
                this.bounds.elementHeight = elementHeight
                this.bounds.windowWidth = windowWidth
                this.bounds.windowHeight = windowHeight

                this.props.onSizeChange(this.bounds)
            }
        }
    }
}
