import * as React from 'react'
import './edit-campaign.scss'
import { PageHeader } from '@pushly/aqe/lib/components'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import {
    AppService,
    CampaignV2Service,
    DomainIntegrationService,
    DomainService,
    NotificationService,
} from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { CampaignBuilderMode, NodeType } from '../../../components/campaign-builder/enums'
import { ifDomainChanged } from '../../../_utils/domain'
import { CampaignWizard } from '../../../components/campaign-wizard/campaign-wizard'
import { DomainDto } from '../../../dtos/domain'
import { StatusType } from '../../../enums/status-type'
import { UserType } from '../../../enums/user-type'
import { DomainIntegrationProvider } from '../../../enums/domain-integration-provider'
import { IServiceApiResponse } from '../../../interfaces/service-api-response'
import { ApiVersion } from '../../../enums/api-version.enum'
import { onResponseError403 } from '../../../_utils/on-response-error-403'

interface IEditCampaign {}

interface IState {
    loading: boolean
    domain: DomainDto
    loadedCampaign?: any
    campaign?: any
    showAmplyExperience?: boolean
}

export class EditCampaign extends BetterComponent<IEditCampaign, IState> {
    private readonly appState: AppState
    private readonly appService: AppService
    private readonly domainService: DomainService
    private readonly campaignService: CampaignV2Service
    private readonly notifService: NotificationService
    private readonly integrationsSvc: DomainIntegrationService

    private disposeObservers: any[] = []

    public constructor(props: IEditCampaign) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.campaignService = Container.get(CampaignV2Service)
        this.notifService = Container.get(NotificationService)
        this.integrationsSvc = Container.get(DomainIntegrationService)

        this.state = {
            loading: false,
            showAmplyExperience: false,
            domain: this.appState.currentDomain!,
        }
    }

    public componentDidMount(): void {
        this.loadResources()

        this.disposeObservers.push(ifDomainChanged(this.appState, () => this.goBackToCampaignsList()))
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((disposeFn) => disposeFn())
    }

    public render(): React.ReactNode {
        const { loading, campaign } = this.state
        const isDraftCampaign = campaign?.status === StatusType.DRAFT.name
        const headerPrefix = isDraftCampaign ? 'Draft' : 'Edit'
        const headerName = !campaign ? 'Campaign' : campaign.name

        return (
            <div className="edit-campaign">
                <PageHeader
                    title={`${loading ? 'Loading...' : `${headerPrefix}: ${headerName}`}`}
                    append={<span>ID: {this.campaignId ?? '...'}</span>}
                    onTitleSet={this.appService.customizeTabTitle}
                />

                <CampaignWizard
                    loading={this.state.loading}
                    mode={CampaignBuilderMode.EDIT}
                    domain={this.state.domain}
                    campaign={this.state.campaign}
                    onChange={this.handleUpdate}
                    onSubmit={this.handleSubmit}
                    showAmplyExperience={this.state.showAmplyExperience}
                />
            </div>
        )
    }

    public get campaignId(): number {
        return this.injectedProps.match.params.campaignId
    }

    protected get templateId(): number {
        return this.queryString.template as any
    }

    protected goBackToCampaignsList() {
        this.appService.routeWithinDomain('/campaigns')
    }

    protected handleUpdate = async (campaign: any) => {
        return this.setState({ campaign })
    }

    protected handleSubmit = async (): Promise<any> => {
        // reload campaign and associated resources
        return this.loadResources()
    }

    protected loadResources = async (): Promise<void> => {
        let domain = this.state.domain
        const user = this.appState.currentUser

        await this.setState({
            campaign: undefined,
            loading: true,
        })

        const update: any = { loading: false }

        const loadRequests: any[] = []

        // fetch domain integrations for amply check
        const domainReqId = loadRequests.push(this.domainService.fetchById(domain.id)) - 1
        const integrationsReqId = loadRequests.push(this.integrationsSvc.fetchAll(domain.id)) - 1
        let campaignReqId: number | undefined

        if (this.campaignId) {
            campaignReqId =
                loadRequests.push(
                    this.campaignService.fetchById(this.campaignId, {
                        errorHandler: onResponseError403(() => {
                            this.goBackToCampaignsList()
                        }),
                    }),
                ) - 1
        }

        const responses = await Promise.all(loadRequests)

        const domainRes: IServiceApiResponse<DomainDto> = responses[domainReqId]
        const integrationsRes: IServiceApiResponse<any[]> = responses[integrationsReqId]

        let campaignRes: IServiceApiResponse<any> | undefined
        if (campaignReqId) {
            campaignRes = responses[campaignReqId]
        }

        if (domainRes.ok) {
            update.domain = domainRes.data
        }

        if (integrationsRes.ok) {
            const integrations = integrationsRes.data as any[]
            const amplyIntegration = integrations.find(
                (i) => i.isActive === true && i.provider === DomainIntegrationProvider.AMPLY,
            )

            // amply experience currently requires both an active integration and internal user access
            if (amplyIntegration && user?.userType === UserType.INTERNAL) {
                update.showAmplyExperience = true
            }
        }

        if (campaignRes?.ok) {
            const campaign = campaignRes.data
            campaign.campaign_type = campaign.type

            const steps = campaign.revision?.steps
            if (Array.isArray(steps) && steps.length > 0) {
                const actionSteps = steps.filter(
                    (s) => s.type === NodeType.ACTION && !!s.configuration?.params?.notification_id,
                )
                if (actionSteps.length > 0) {
                    await Promise.all(
                        actionSteps.map((s) => {
                            let actionLoader

                            const notificationId = s.configuration?.params?.notification_id
                            if (notificationId) {
                                actionLoader = this.notifService
                                    .fetchNotificationById(domain.id, notificationId, {
                                        query: { include_schedules: false },
                                        version: ApiVersion.V4,
                                    })
                                    .then((res) => {
                                        s.configuration.params.title = res?.template?.channels?.default?.title
                                        s.configuration.params.notification = res

                                        if (this.templateId) {
                                            delete s.configuration.params.notification_id
                                        }
                                    })
                            }

                            return actionLoader
                        }),
                    )
                }
            }

            campaign.steps = steps

            update.loadedCampaign = campaign
            update.campaign = campaign
        }

        this.setState(update)
    }
}
