export const NotificationApiColumns = {
    'NOTIFICATION.ID': 'notification.id',
    'NOTIFICATION.SCHEDULE_ID': 'notification.schedule_id',
    'NOTIFICATION.TITLE': 'notification.title',
    'NOTIFICATION.BODY': 'notification.body',
    'NOTIFICATION.TARGETED_CHANNELS': 'notification.targeted_channels',
    'NOTIFICATION.AUDIENCES': 'notification.audiences',
    'NOTIFICATION.BADGE_URL': 'notification.badge_url',
    'NOTIFICATION.ICON_URL': 'notification.icon_url',
    'NOTIFICATION.IMAGE_URL': 'notification.image_url',
    'NOTIFICATION.KEYWORDS': 'notification.keywords',
    'NOTIFICATION.CHANNEL': 'notification.channel',
    'NOTIFICATION.LANDING_URL': 'notification.landing_url',
    'NOTIFICATION.LIFESPAN': 'notification.lifespan',
    'NOTIFICATION.SCHEDULED_TIME': 'notification.scheduled_time',
    'NOTIFICATION.SCHEDULED_TIME_ZONE': 'notification.scheduled_time_zone',
    'NOTIFICATION.DELIVERY_STRATEGY': 'notification.delivery_strategy',
    'NOTIFICATION.CREATED_BY': 'notification.created_by',
    NOTIFICATION_SOURCE: 'notification_source',

    KEYWORD: 'keyword',
    CHANNEL: 'channel',

    'BUTTON.ID': 'button.id',
    'BUTTON.TYPE': 'button.type',
    'BUTTON.LABEL': 'button.label',
    'BUTTON.LANDING_URL': 'button.landing_url',

    'CAMPAIGN.ID': 'campaign.id',
    'CAMPAIGN.NAME': 'campaign.name',
    'STEP.ID': 'step.id',

    'TEST.NAME': 'test.name',
    'TEST.CREATED_BY': 'test.created_by',
    'NOTIFICATION.WEIGHT': 'notification.weight',

    ENTRANCES: 'entrances',
    DELIVERIES: 'deliveries',
    DELIVERY_RATE: 'delivery_rate_decimal',
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr_decimal',
    NOTIFICATIONS_SENT: 'notifications_sent',
    CPS: 'clicks_per_send',
    PURCHASES: 'purchases',
    PURCHASE_AMOUNT: 'purchase_amount',
    PURCHASE_RATE_DECIMAL: 'purchase_rate_decimal',
}
