import * as React from 'react'

export const IconMuted = () => (
    <svg viewBox="0 0 1024 1024" data-icon="muted" height="1em" width="1em" fill="currentColor" aria-hidden="true">
        <path
            d="M529.679,1.233c-11.647-1.087-25.279,1.728-41.343,11.456c0,0-266.875,232.829-278.522,244.284
						H62.998C27.672,257.036-1,285.708-1,321.036v392.121c0,35.454,28.672,63.998,63.998,63.998h146.878
						c11.647,11.585,278.522,236.155,278.522,236.155c16.063,9.664,29.695,12.543,41.343,11.456
						c23.681-2.239,40.319-22.08,45.376-43.008c0.191-2.048,0.191-935.409-0.129-937.457
						C569.998,23.313,553.295,3.473,529.679,1.233z"
        />
        <path
            d="M901.065,513.032l110.463-110.463c15.296-15.231,15.296-40,0-55.23
						c-15.231-15.231-39.999-15.231-55.23,0L845.835,457.864L735.309,347.339c-15.166-15.231-39.999-15.231-55.168,0
						c-15.23,15.231-15.23,39.999,0,55.23l110.463,110.463L680.141,623.558c-15.23,15.167-15.23,39.999,0,55.166
						c15.167,15.231,40,15.231,55.166,0l110.525-110.463l110.464,110.463c15.231,15.231,39.999,15.231,55.23,0
						c15.231-15.167,15.231-39.999,0-55.166L901.065,513.032z"
        />
    </svg>
)
