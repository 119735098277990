import React from 'react'
import { Alert } from 'antd'

const MultiDomainHeaderAlert = () => (
    <Alert
        className="multi-domain-segment-alert"
        type="info"
        closable={false}
        showIcon={true}
        message={false}
        description={
            <span>
                This is a multi-domain segment and can only be edited from the organization's multi-domain segment page.
            </span>
        }
    />
)

export default MultiDomainHeaderAlert
