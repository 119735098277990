import { PromptDto } from './prompt'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class PromptGroupDto {
    public static parse(fields: PromptGroupDto): PromptGroupDto {
        const dto = new PromptGroupDto()

        type AllowedType = keyof PromptGroupDto
        const fieldsToAssign: AllowedType[] = [
            'id',
            'channel',
            'name',
            'domainId',
            'conditions',
            'isActive',
            'isAbTest',
            'priority',
            'displayToPct',
            'prompts',
        ]

        fieldsToAssign.forEach((key) => {
            if (fields[key] !== undefined) {
                dto[key as any] = fields[key]
            }
        })

        if (dto.prompts && dto.prompts.length > 0) {
            dto.prompts = dto.prompts.map(PromptDto.parse)
        }

        return dto
    }

    public id: number
    public channel: DeliveryChannel
    public name: string
    public domainId: number
    public conditions: any
    public isActive: boolean
    public isAutoShow: boolean
    public isAbTest: boolean
    public priority: number
    public displayToPct: number

    public prompts: any[]

    public get pageCondtions(): any {
        return (this.conditions || {}).page
    }

    public get displayConditions(): any {
        return (this.conditions || {}).display
    }
}
