import * as React from 'react'
import { IBadgeProps } from './interfaces'
import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import { IconMuted } from '../svg/icon-muted'

export const NotificationSilentBadge: React.StatelessComponent<IBadgeProps> = (props) => {
    const badge = (
        <span className="icon-silent">
            <Icon component={IconMuted} />
        </span>
    )

    return (
        <span className="notification-silent-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || 'Silent Delivery'}>{badge}</Tooltip>}
        </span>
    )
}
