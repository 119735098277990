import { FlagModel } from '../models/domain/flag.model'

/**
 *  Data Structure to Organize Flags pulled via DB
 *  <br>
 *  <br>
 *  ***FlagList is not mutable***
 *  <br>
 */
export default class FlagList {
    public static from(arr: ArrayLike<FlagModel>): FlagList
    public static from(arr: ArrayLike<FlagModel>, mapFn: (v: FlagModel, k: number) => FlagModel): FlagList
    public static from(arr: ArrayLike<FlagModel>, mapFn?: (v: FlagModel, k: number) => FlagModel): FlagList {
        mapFn = mapFn ?? ((flag) => flag)
        return new FlagList(Array.from(arr).map(mapFn))
    }

    protected _flags: FlagModel[] = []
    protected _keyMap: { [key: string]: FlagModel } = {}

    protected constructor(arr: FlagModel[]) {
        this._flags = arr

        for (const flag of this._flags) {
            this._keyMap[flag.getKey()] = flag
        }
    }

    public [Symbol.iterator]() {
        return this._flags[Symbol.iterator]
    }

    public findByKey(key: string): FlagModel | undefined
    public findByKey(key: string, constraint: (flag: FlagModel) => boolean): FlagModel | undefined
    public findByKey(key: string, constraint?: (flag: FlagModel) => boolean): FlagModel | undefined {
        let flag: FlagModel | undefined = this._keyMap[key]
        if (flag && constraint && !constraint(flag)) {
            flag = undefined
        }

        return flag
    }

    public findActive(key: string) {
        return this.findByKey(key, (f) => f.getIsActive())
    }

    public getActiveFlags<T extends FlagModel>(): T[] {
        return this._flags.filter((flag) => flag.getIsActive()) as T[]
    }
}
