import { PromptGroupDto } from '../../../dtos/prompt-group'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export class PromptTableDataDto extends PromptGroupDto {
    public static fromPromptDto(fields: Partial<PromptTableDataDto>): PromptTableDataDto {
        const dto = new PromptTableDataDto()
        Object.assign(dto, fields, {
            key: fields.id,
        })
        return dto
    }

    public readonly key: number

    public shownPrompts: number
    public promptAllows: number
    public shownPermissions: number
    public subscriptions: number
    public promptAllowRate: number
    public permissionAllowRate: number
    public channel: DeliveryChannel
}
