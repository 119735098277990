import * as React from 'react'
import './typed-tag-select.scss'
import { arrayContains, arrayRemove, titleCase } from '../../../../_utils/utils'
import { Select } from 'antd'
import { SelectValue } from 'antd/lib/select'

const BASE_CLASSNAME = 'typed-tag-select'

const buildClassname = (append?: string, additionals?: string | string[]): string => {
    const classNames = [append ? `${BASE_CLASSNAME}-${append}` : BASE_CLASSNAME]
    if (additionals) {
        classNames.push(Array.isArray(additionals) ? additionals.join(' ') : additionals)
    }
    return classNames.join(' ')
}

type SelectedType = string | SelectValue
type TypedTagSelectOnChangeHandler = (value: ISelectionValue[] | undefined) => any

export interface ITypedTagSelectProps {
    className?: string
    types: string[]
    initialValue?: ISelectionValue[]
    value?: ISelectionValue[]
    onChange?: TypedTagSelectOnChangeHandler
    placeholder?: string
    options?: Array<{ value: string | number; label?: string }>
}

interface IState {
    classNames: {
        root: string[]
        inputWrapper: string[]
        input: string[]
        resultsWrapper: string[]
    }
    value?: ISelectionValue[]

    selectedType: SelectedType
}

export interface ISelectionValue {
    type: string
    value: string
}

const EMPTY_RESULTS_CLASSNAME = 'results-empty'

export class TypedTagSelect extends React.Component<ITypedTagSelectProps, IState> {
    private input: any

    public constructor(props: ITypedTagSelectProps) {
        super(props)

        const initialValue = this.props.value || this.props.initialValue
        const initialState: any = {
            classNames: {
                root: [],
                inputWrapper: [],
                input: [],
                resultsWrapper: [],
            },
            value: initialValue,
            selectedType: this.props.types[0],
        }

        if (!initialValue || initialValue.length === 0) {
            initialState.classNames.resultsWrapper.push(EMPTY_RESULTS_CLASSNAME)
        }

        this.state = initialState
        this.bindHandlers()
    }

    public async componentDidUpdate(): Promise<void> {
        if (!!this.props.value && this.props.value !== this.state.value) {
            await this.setState({ value: this.props.value })
        }
    }

    public render(): React.ReactNode {
        const { className } = this.props
        const { classNames } = this.state

        const rootClassNames: string[] = [...classNames.root]
        if (className) {
            rootClassNames.push(className)
        }

        const resultsWrapperClassNames: string[] = [...classNames.resultsWrapper]
        if (!this.state.value || this.state.value.length === 0) {
            resultsWrapperClassNames.push(EMPTY_RESULTS_CLASSNAME)
        } else {
            arrayRemove(resultsWrapperClassNames, EMPTY_RESULTS_CLASSNAME)
        }

        let selectedOptionValues: any[] = []
        if (this.props.options) {
            selectedOptionValues = (this.props.value || []).map((opt) => opt.value)
        }

        return (
            <div className={buildClassname(undefined, rootClassNames)}>
                <div className={buildClassname('window')}>
                    <div className={buildClassname('input-wrapper', classNames.inputWrapper)}>
                        <div className={buildClassname('action-frame')}>
                            <Select
                                defaultValue={this.state.selectedType}
                                onChange={(selectedType: SelectedType) => this.setState({ selectedType })}
                            >
                                {this.props.types.map((type) => (
                                    <Select.Option key={type} value={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div className={buildClassname('input-frame')} onClick={this.handleInputFrameClick}>
                            {this.props.options ? (
                                <Select
                                    ref={(el) => (this.input = el)}
                                    className={buildClassname('input', classNames.input)}
                                    placeholder={this.props.placeholder}
                                    showSearch={true}
                                    onChange={this.handleOptionChange}
                                >
                                    {this.props.options.map((opt) => {
                                        const disabled = selectedOptionValues.indexOf(opt.value) !== -1

                                        return (
                                            <Select.Option key={opt.value} value={opt.value} disabled={disabled}>
                                                {opt.label || opt.value}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            ) : (
                                <input
                                    ref={(el) => (this.input = el)}
                                    className={buildClassname('input', classNames.input)}
                                    type="text"
                                    placeholder={this.props.placeholder}
                                    onFocus={this.handleInputFocus}
                                    onBlur={this.handleInputBlur}
                                    onKeyDown={this.handleInputKeyDown}
                                />
                            )}
                        </div>
                    </div>
                    <div className={buildClassname('results-wrapper', resultsWrapperClassNames)}>
                        <div className={buildClassname('results-frame')}>
                            {(this.state.value || []).map((v, idx) => {
                                const resultClassNames: string[] = [buildClassname('result')]

                                if (this.props.options) {
                                    const availableValues = this.props.options.map((opt) => opt.value)

                                    if (!arrayContains(availableValues, v.value)) {
                                        resultClassNames.push('disabled')
                                    }
                                }

                                return (
                                    <div key={`${v.type}-${idx}`} className={resultClassNames.join(' ')}>
                                        <span className={buildClassname('result-type')}>{v.type}</span>
                                        <span className={buildClassname('result-value')}>{v.value}</span>
                                        <span
                                            className={buildClassname('result-close')}
                                            onClick={() => this.removeValue(v)}
                                        >
                                            x
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected bindHandlers(): void {
        this.handleInputFrameClick = this.handleInputFrameClick.bind(this)
        this.handleInputFocus = this.handleInputFocus.bind(this)
        this.handleInputBlur = this.handleInputBlur.bind(this)
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this)
        this.handleOptionChange = this.handleOptionChange.bind(this)
        this.removeValue = this.removeValue.bind(this)
    }

    protected handleInputFocus(ev: React.FocusEvent<HTMLInputElement>): void {
        this.setState(({ classNames }) => ({
            classNames: {
                ...classNames,
                inputWrapper: ['focused'],
            },
        }))
    }

    protected handleInputBlur(ev: React.FocusEvent<HTMLInputElement>): void {
        this.setState(({ classNames }) => ({
            classNames: {
                ...classNames,
                inputWrapper: [],
            },
        }))
    }

    protected handleInputFrameClick(ev: React.MouseEvent<HTMLDivElement>): void {
        this.input.focus()
    }

    protected async handleInputKeyDown(ev: React.KeyboardEvent<HTMLInputElement>): Promise<void> {
        if (ev.keyCode === 13) {
            ev.preventDefault()

            await this.addValue(this.input.value)
            return this.resetInput()
        }
    }

    protected async handleOptionChange(optionValue: string): Promise<void> {
        await this.addValue(optionValue)
        return this.resetInput()
    }

    protected async removeValue(selectedValue: ISelectionValue): Promise<void> {
        await this.setState(({ value }) => {
            value = !!value ? arrayRemove(value, (v: ISelectionValue) => v.value === selectedValue.value) : undefined

            return { value }
        })

        this.dispatchValueChange()
    }

    protected async addValue(inputValue: string): Promise<void> {
        if (!!inputValue && inputValue.length > 0) {
            await this.setState(({ value, selectedType }) => {
                value = value || []

                const type = selectedType as string
                const currentValues = value.map((v) => v.value)

                if (currentValues.indexOf(inputValue) === -1) {
                    value.unshift({
                        value: inputValue,
                        type,
                    })
                }

                return { value }
            })

            this.dispatchValueChange()
        }
    }

    protected dispatchValueChange(): void {
        if (this.props.onChange) {
            this.props.onChange(this.state.value)
        }
    }

    protected resetInput(): void {
        if ('rcSelect' in this.input) {
            this.input.rcSelect.setState({ value: [] })
        } else {
            this.input.value = ''
            this.input.focus()
        }
    }
}
