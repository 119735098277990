import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import autobind from 'autobind-decorator'
import { AppService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { PromptTableWell } from './prompt-table-well'
import './prompt-list.scss'
import { AppState } from '../../../stores/app'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IProps {
    listType: DeliveryChannel
}

interface IState {}

export class PromptList extends BetterComponent<any, IState> {
    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { abilityStore } = this.appState
        return (
            <div className="prompt-list">
                <PromptTableWell
                    showHeader={false}
                    showSearch={false}
                    showRefresh={false}
                    listType={this.props.listType}
                />
            </div>
        )
    }

    private get buildCreatePromptUrl(): string {
        return this.appService.routeWithinDomain('/prompts/new', true)
    }

    @autobind
    private jumpToCreatePrompt(): void {
        this.appService.route(this.buildCreatePromptUrl)
    }
}
