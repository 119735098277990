export class IdentityProviderTypeDto {
    public static fromApiResponse(fields: Partial<IdentityProviderTypeDto>): IdentityProviderTypeDto {
        const dto = new IdentityProviderTypeDto()
        Object.assign(dto, fields)
        return dto
    }

    public id: number
    public name: string
    public isInternal: boolean
    public auth0Strategy: string
}
