import { BetterComponent } from '../better-component/better-component'
import { Input, InputNumber } from 'antd'
import * as React from 'react'
import { InputProps } from 'antd/lib/input'
import './better-input.scss'

interface IInputProps extends InputProps {
    containerClassName?: string
    maxChars?: number
    minChars?: number
    charExceededMessage?: string
    autoSize?: boolean
}

interface IInputState {
    inputWidth: number
}

export class BetterInput extends BetterComponent<IInputProps, IInputState> {
    public state: IInputState = {
        inputWidth: 45,
    }

    public input: any

    public constructor(props: IInputProps) {
        super(props)
    }

    public componentDidMount(): void {
        this.resize()
    }

    public render(): React.ReactNode {
        const {
            containerClassName,
            maxChars = 0,
            minChars = 0,
            charExceededMessage,
            autoSize,
            type,
            ...props
        } = this.props

        const input = this.input || {}

        const hasCharLimit = !!input && (!!maxChars || !!minChars)
        let isOutsideLimits = false
        let charLength = 0
        const currentValue = (input.value || '').trim()
        const macroDetected = /{{/.test(currentValue)

        if (!macroDetected && hasCharLimit) {
            charLength = currentValue.length

            if (!isOutsideLimits && !!minChars) {
                if (charLength > 0 && charLength < minChars) {
                    isOutsideLimits = true
                }
            }

            if (!isOutsideLimits && !!maxChars) {
                if (charLength > 0 && charLength > maxChars) {
                    isOutsideLimits = true
                }
            }
        }

        const style =
            autoSize && this.state.inputWidth
                ? {
                      width: this.state.inputWidth,
                  }
                : undefined

        let InputComponent: any = Input
        if (type === 'number') {
            InputComponent = InputNumber
        }

        return (
            <div className={['sw-better-input', containerClassName || ''].join(' ').trim()}>
                <InputComponent
                    {...props}
                    style={style}
                    ref={(el: any) => {
                        if (el) {
                            if ('inputNumberRef' in el) {
                                this.input = el.inputNumberRef.input
                            } else {
                                this.input = el.input
                            }
                        }
                    }}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                        if (autoSize) {
                            this.resize(ev)
                        }

                        if (!!this.props.onChange) {
                            this.props.onChange(ev)
                        }
                    }}
                />
                {(macroDetected || hasCharLimit) && (
                    <div
                        className={['sw-better-input-char-counter', isOutsideLimits ? 'outside-limits' : '']
                            .join(' ')
                            .trim()}
                    >
                        {macroDetected ? (
                            <span>Unable to calculate characters due to dynamic macro length</span>
                        ) : (
                            <span>
                                {charLength} {charLength === 1 ? 'Character' : 'Characters'}
                                {isOutsideLimits && !!charExceededMessage ? `. ${charExceededMessage}` : ''}
                            </span>
                        )}
                    </div>
                )}
            </div>
        )
    }

    protected async resize(ev?: React.ChangeEvent<HTMLInputElement> | number): Promise<void> {
        let val
        if (ev) {
            if (typeof ev === 'object') {
                val = ev.target.value
            } else {
                val = ev
            }
        } else {
            if (this.input) {
                val = this.input.value
            }
        }

        const minWidth = 45
        let inputWidth = minWidth

        if (val) {
            const shadowSpan = document.createElement('span')
            shadowSpan.innerText = val
            shadowSpan.setAttribute('style', 'visibility: hidden;position:absolute;z-index:-1;top:0;left:0;')
            document.body.appendChild(shadowSpan)
            const shadowWidth = shadowSpan.offsetWidth
            document.body.removeChild(shadowSpan)

            const valLength = val.length
            inputWidth = shadowWidth + 36 + 1

            if (inputWidth < minWidth) {
                inputWidth = minWidth
            }
        }

        return this.setState({ inputWidth })
    }
}
