import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { ApartmentOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

interface IMultiDomainBadgeProps extends IBadgeProps {
    entityLabel?: string
}

export const MultiDomainBadge: React.FunctionComponent<IMultiDomainBadgeProps> = (props) => {
    const badge = (
        <span className="icon-multi-domain">
            <ApartmentOutlined />
        </span>
    )

    let tooltip = props.tooltip
    if (!props.hideTooltip && !tooltip) {
        tooltip = 'Multi-Domain'
        if (props.entityLabel) {
            tooltip += ` ${props.entityLabel}`
        }
    }

    return (
        <span className="multi-domain-notification-badge">
            {props.hideTooltip ? badge : <Tooltip title={tooltip}>{badge}</Tooltip>}
        </span>
    )
}
