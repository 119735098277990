import React from 'react'

const IconOrgFilled = () => (
    <svg viewBox="0 0 1024 1024" data-icon="org-filled" height="1em" width="1em" fill="currentColor" aria-hidden="true">
        <path
            d="M1010.434,655.213c-17.223-61.746-81.237-97.839-142.982-80.616c-21.342,5.952-39.616,17.498-53.687,32.605
	l-126.3-50.861c3.927-16.349,6.021-33.409,6.021-50.962c0-120.094-97.354-217.448-217.448-217.448
	c-9.941,0-19.723,0.681-29.311,1.973l-19.98-70.022c41.023-27.197,61.666-78.739,47.441-128.593
	c-17.59-61.642-81.819-97.353-143.461-79.764c-61.642,17.59-97.354,81.819-79.764,143.461
	c14.317,50.174,59.534,83.165,109.104,84.22l20.23,70.896c-72.085,35.409-121.708,109.542-121.708,195.276
	c0,51.818,18.137,99.394,48.393,136.751L182.156,796.291c-20.647-8.124-43.842-10.431-67.146-5.346
	c-62.627,13.669-102.315,75.519-88.648,138.146c13.667,62.626,75.517,102.315,138.144,88.648
	c62.626-13.67,102.316-75.518,88.648-138.145c-3.198-14.651-9.033-28.047-16.897-39.798L358.75,688.515
	c33.839,21.717,74.092,34.312,117.287,34.312c77.87,0,146.167-40.94,184.574-102.462l123.106,49.577
	c-2.223,15.462-1.351,31.625,3.116,47.637c17.223,61.746,81.237,97.839,142.983,80.616
	C991.563,780.974,1027.656,716.959,1010.434,655.213z"
        />
    </svg>
)

export default IconOrgFilled
