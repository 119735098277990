import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { AppService } from '../../services'
import { AppState } from '../../stores/app'
import 'fixed-data-table-2/dist/fixed-data-table.css'
import './reporting.scss'
import { ReportStatusCode } from './enums/report-status-code'
import { Tabs } from 'antd'
import { PageHeader } from '@pushly/aqe/lib/components'
import { ReportingV2 } from './reporting'
import { SavedReports } from './saved-reports-table/saved-reports'

interface IProps {
    match?: any
    location?: any
}

interface IState {
    insightsStatus: any
}

export class Insights extends React.Component<IProps, IState> {
    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {
            insightsStatus: {},
        }
    }

    public render(): React.ReactNode {
        const { insightsStatus } = this.state
        return (
            <div className="reporting-v2 page">
                <div className="report-display-wrapper">
                    <PageHeader title="Insights" onTitleSet={this.appService.customizeTabTitle} />
                    <Tabs
                        size="small"
                        animated={{ inkBar: false, tabPane: false }}
                        tabPosition="top"
                        activeKey={this.currentTabKey}
                        onChange={this.setCurrentTabKey}
                        destroyInactiveTabPane={true}
                    >
                        <Tabs.TabPane key="reporting" tab="Reporting">
                            <ReportingV2 />
                        </Tabs.TabPane>

                        <Tabs.TabPane key="saved" tab="Saved Reports">
                            <SavedReports
                                defaultFilters={{
                                    search: 'what',
                                }}
                                location={this.props.location.pathname}
                                onStatusUpdate={(status) => {
                                    this.setState({ insightsStatus: status })
                                }}
                            />
                            {insightsStatus.code === ReportStatusCode.ERROR ||
                                insightsStatus.code === ReportStatusCode.NO_DATA ||
                                (insightsStatus.code === ReportStatusCode.NOT_READY && (
                                    <div className="message-wrapper">{insightsStatus.message}</div>
                                ))}
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

    protected get requestedDomainId(): number {
        return this.props.match?.params?.domainId
    }

    protected get currentTabKey(): string {
        let key = 'reporting'

        if (this.props.location.hash) {
            key = this.props.location.hash.replace('#', '').toLowerCase() || key
        }
        return key
    }

    protected setCurrentTabKey = (key: string): void => {
        this.appService.route(`${this.props.location.pathname}#${key}`)
    }
}
