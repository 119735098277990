import * as React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { APP_BASE_ROUTE, AUTH_REDIRECT_PARAM } from '../../constants'
import { AppState } from '../../stores/app'
import { BetterComponent } from '../better-component/better-component'
import { AppService } from '../../services/index'
import { PlatformUserContext } from '../../providers/user-provider/user-provider'

interface IProps extends RouteProps {
    preAuth?: boolean
}

@observer
export class AppRoute extends BetterComponent<IProps, {}> {
    public static contextType = PlatformUserContext
    public context!: React.ContextType<typeof PlatformUserContext>

    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { preAuth = false, ...props } = this.props
        const location = this.props.location!
        const currentRoute = location.pathname
        const queryString = location.search

        let redirectTo: string | null = null

        if (!this.context.authenticated) {
            if (!preAuth) {
                redirectTo = APP_BASE_ROUTE

                if (currentRoute !== APP_BASE_ROUTE) {
                    redirectTo += `?${AUTH_REDIRECT_PARAM}=${currentRoute}`
                }
            }
        } else {
            if (preAuth) {
                const redirectRequest = queryString.match(`${AUTH_REDIRECT_PARAM}=([^&]+)`)
                redirectTo = redirectRequest ? redirectRequest[1] : APP_BASE_ROUTE
            }
        }

        const path: string = ((props.path as any) || '').toString()
        return redirectTo !== null ? <Redirect from={path} exact={true} to={redirectTo} /> : <Route {...props} />
    }
}
