import { CampaignDto } from './campaign-dto'

export class CampaignTableDto extends CampaignDto {
    public static fromCampaignDto(fields: Partial<CampaignTableDto>): CampaignTableDto {
        const dto = new CampaignTableDto()

        Object.assign(dto, fields, {
            key: fields.id,
        })

        return dto
    }

    public readonly key: number
    public entrances: number = 0
    public exits: number = 0
    public deliveries: number = 0
    public impressions: number = 0
    public clicks: number = 0
    public ctr?: number
    public deliveryRate?: number
}
