import { OpenIdIdentityProviderModel } from '../../models/openid-identity-provider.model'
import { Well } from '@pushly/aqe/lib/components/index'
import { Form, Input } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { InfoCircleOutlined } from '@ant-design/icons'
import StackedTagSelect from '@pushly/aqe/lib/components/stacked-tag-select/stacked-tag-select'
import * as React from 'react'
import { IdpFormMode } from '../../enums/idp-form-modes.enum'

interface IProps {
    workingModel: OpenIdIdentityProviderModel
    setWorkingModel: React.Dispatch<React.SetStateAction<OpenIdIdentityProviderModel>>
    loadingProvider: boolean
    formMode: string
}

export const MicrosoftAzureADProviderForm = (props: IProps) => {
    const { workingModel, setWorkingModel, loadingProvider, formMode } = props
    return (
        <div className="sso-provider-form">
            <Well title="Administrator Set Up Instructions" mode="ghost" hideFooter={true}>
                <p>
                    Your Microsoft Azure AD administrator will need to set up an App Registration within Azure to enable
                    this provider. Please provide a link to our&nbsp;
                    <a
                        href="https://documentation.pushly.com/platform/organizations/single-sign-on/microsoft-azure-ad"
                        target="_blank"
                    >
                        Microsoft Azure AD Provider documentation
                    </a>
                    &nbsp;to your Azure administrator.
                </p>
            </Well>

            <Well title="Provider Configuration" mode="ghost" hideFooter={true} loading={loadingProvider}>
                <Form.Item label="Name" className={getClassNames('idp-details-editor-name')}>
                    <Input
                        size="small"
                        placeholder="Microsoft Azure AD"
                        value={workingModel.getName() ?? undefined}
                        onChange={(ev) => {
                            const name = ev.target.value
                            const updated = workingModel.clone()
                            updated.setName(name)
                            setWorkingModel(updated)
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Azure AD Domain"
                    className={getClassNames('idp-details-editor-azure-workspace-domain')}
                >
                    <Input
                        size="small"
                        placeholder="your-azure-ad-domain.com"
                        disabled={formMode === IdpFormMode.EDIT}
                        value={workingModel.getDomain() ?? undefined}
                        onChange={(ev) => {
                            const azureDomain = ev.target.value
                            const updated = workingModel.clone()
                            updated.setDomain(azureDomain)
                            setWorkingModel(updated)
                        }}
                    />
                </Form.Item>
                <Form.Item label="Client ID" className={getClassNames('idp-details-editor-client-id')}>
                    <Input
                        size="small"
                        disabled={formMode === IdpFormMode.EDIT}
                        placeholder={formMode === IdpFormMode.EDIT ? '********' : undefined}
                        value={workingModel.getClientId() ?? undefined}
                        onChange={(ev) => {
                            const clientId = ev.target.value
                            const updated = workingModel.clone()
                            updated.setClientId(clientId)
                            setWorkingModel(updated)
                        }}
                    />
                </Form.Item>
                <Form.Item label="Client Secret" className={getClassNames('idp-details-editor-client-secret')}>
                    <Input
                        size="small"
                        disabled={formMode === IdpFormMode.EDIT}
                        placeholder={formMode === IdpFormMode.EDIT ? '********' : undefined}
                        value={workingModel.getClientSecret() ?? undefined}
                        onChange={(ev) => {
                            const clientSecret = ev.target.value
                            const updated = workingModel.clone()
                            updated.setClientSecret(clientSecret)
                            setWorkingModel(updated)
                        }}
                    />
                </Form.Item>
                <Form.Item label={'Email Domains'} className={getClassNames('idp-details-editor-email-domains')}>
                    <StackedTagSelect<string[]>
                        size="small"
                        mode="tags"
                        className="associated-domains"
                        dropdownClassName="associated-domains--select-dropdown"
                        placeholder="yourcompany.com"
                        tokenSeparators={[',', ';', ' ', '\n']}
                        showOptions={false}
                        notFoundContent=""
                        value={workingModel.getAssociatedEmailDomains()}
                        onChange={(v) => {
                            const associatedEmailDomains = v
                            const updated = workingModel.clone()
                            updated.setAssociatedEmailDomains(associatedEmailDomains)
                            setWorkingModel(updated)
                        }}
                    />
                    <div className={getClassNames('form-layout-row-sub')}>
                        <InfoCircleOutlined />
                        <span>
                            Enter each domain on a new line that should use this identity provider. For example: If your
                            email is user@yourcompany.com then you would enter yourcompany.com.
                        </span>
                    </div>
                </Form.Item>
            </Well>
        </div>
    )
}
