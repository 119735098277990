import * as React from 'react'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Select, Skeleton } from 'antd'
import { Input as AqeInput } from '@pushly/aqe/lib/components'
import { preventBubbling } from '../../_utils/utils'
import { axiosCancellationRequests } from '../../config/axios-setup'
import { SwPortal } from '../sw-portal/sw-portal'
import * as randomstring from 'randomstring'
import { PromptDto } from '../../dtos/prompt'
import { PromptService } from '../../services/prompt'
import './prompt-search-bar.scss'

interface IPromptSearchBarProps {
    value: number
    onChange?: (value: number) => any
    mode?: 'display' | 'search'
    autofocus?: boolean

    className?: string
    disabled?: boolean
    options?: PromptDto[]
    loading?: boolean
    displayStaticOptions?: boolean
    renderOption?: (prompt: PromptDto) => React.ReactNode
    getDropdownContainer?: () => HTMLElement | any

    entityLevel?: 'prompt' | 'promptGroup'
    placeholder?: string
    reactive?: boolean
}

interface IPromptSearchBarState {
    expanded: boolean
    searching: boolean
    prompts: PromptDto[]
    tmpValue?: number
}

export class PromptSearchBar extends BetterComponent<IPromptSearchBarProps, IPromptSearchBarState> {
    private defaultClassName = 'sw-v2-prompt-search-bar'
    private axiosCancellationKey = 'psb.searchPrompts'

    private appState: AppState
    private appService: AppService
    private promptService: PromptService

    private uid: string = randomstring.generate()
    private ref: any
    private inputRef: any
    private debounceTimer: any
    private debounceValue = 450

    public constructor(props: IPromptSearchBarProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.promptService = Container.get(PromptService)

        this.state = {
            expanded: false,
            searching: false,
            prompts: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.wireEventHandlers()

        if (this.hasValue) {
            return this.searchPrompts(this.props.value.toString(), true)
        } else if (this.props.autofocus && !!this.inputRef && !!this.inputRef.inputRef) {
            this.inputRef.inputRef.focus()
        }
    }

    public async componentDidUpdate(): Promise<void> {
        if (this.isDisplayMode && this.hasValue) {
            if (!this.state.tmpValue || this.props.value!.toString() !== this.state.tmpValue.toString()) {
                this.searchPrompts(this.props.value.toString(), true)
            }
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.unwireEventHandlers()
    }

    public render(): React.ReactNode {
        const showStaticOptions = this.props.displayStaticOptions && Array.isArray(this.props.options)
        const placeholder = this.props.placeholder || 'Search by ID or Title'

        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <span className={this.buildClassName('display')}>
                        {this.hasValue ? (
                            <>
                                <span className="option-id">#{this.props.value}:</span>
                                &nbsp;
                                <span className="option-title">
                                    {this.selectedPrompt ? (
                                        this.parseNameValue(this.selectedPrompt)
                                    ) : (
                                        <LoadingOutlined spin={true} />
                                    )}
                                </span>
                            </>
                        ) : (
                            '...'
                        )}
                    </span>

                    <div className={this.buildClassName('search')}>
                        {showStaticOptions ? (
                            <Select
                                dropdownClassName={this.buildRootClassNames('dropdown')}
                                placeholder={this.props.loading ? <LoadingOutlined spin={true} /> : placeholder}
                                value={this.props.value}
                                onChange={this.handleOptionSelect}
                                disabled={this.props.loading}
                            >
                                {this.props.options!.map((dto) => {
                                    return (
                                        <Select.Option key={dto.id} value={dto.id}>
                                            {!!this.props.renderOption
                                                ? this.props.renderOption(dto)
                                                : this.renderOption(dto)}
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        ) : (
                            <AqeInput
                                ref={(el) => (this.inputRef = el)}
                                className={this.buildClassName('search-input')}
                                reactive={this.props.reactive !== false}
                                onChange={this.handleInputChange}
                                onFocus={this.activate}
                                placeholder={placeholder}
                                maxWidth={400}
                                icon={
                                    (
                                        <LegacyIcon
                                            className="search-icon"
                                            type={this.state.searching ? 'loading' : 'search'}
                                            spin={this.state.searching}
                                        />
                                    ) as any
                                }
                            />
                        )}
                    </div>

                    <SwPortal
                        className={this.buildRootClassNames('portal')}
                        portalId={this.buildClassName(`portal-${this.uid}`)}
                        container={this.portalContainer}
                        style={this.getPortalPositioning()}
                    >
                        <div className={this.buildRootClassNames('dropdown')}>
                            <div className={this.buildClassName('dropdown-wrapper')}>
                                <div
                                    className={`${this.buildClassName('dropdown-options')}${
                                        !this.hasOptions ? ' empty' : ''
                                    }`}
                                >
                                    {!this.hasOptions ? (
                                        <span>{this.isSearching ? 'Searching ...' : 'No prompts found'}</span>
                                    ) : (
                                        this.state.prompts.map((n) => this.renderOption(n))
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwPortal>
                </div>
            </div>
        )
    }

    public renderOption(prompt: PromptDto): React.ReactNode {
        const selected = this.hasValue && this.props.value.toString() === prompt.id.toString()
        let option: any = ''

        if (this.entityLevelIsPrompt) {
            const group: any = (prompt as any).promptGroup || {}
            const groupIsAbTest = group.isAbTest || false
            const promptName = prompt.name || group.name

            option = (
                <div
                    key={prompt.id}
                    className={`${this.buildClassName('dropdown-option')}${selected ? ' selected' : ''}`}
                >
                    <div
                        className={this.buildClassName('dropdown-option-wrapper')}
                        onClick={() => this.handleValueChange(prompt)}
                    >
                        {!!this.props.renderOption ? (
                            this.props.renderOption(prompt)
                        ) : (
                            <>
                                <div className={this.buildClassName('dropdown-option-upper')}>
                                    <span className="option-id">#{prompt.id}</span>
                                    <span className="option-send-date">{groupIsAbTest && group.name}</span>
                                </div>
                                <div className={this.buildClassName('dropdown-option-lower')}>
                                    <span className="option-title">{promptName}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )
        } else {
            const group: any = prompt

            option = (
                <div
                    key={group.id}
                    className={`${this.buildClassName('dropdown-option')}${selected ? ' selected' : ''}`}
                >
                    <div
                        className={this.buildClassName('dropdown-option-wrapper')}
                        onClick={() => this.handleValueChange(group)}
                    >
                        {!!this.props.renderOption ? (
                            this.props.renderOption(group)
                        ) : (
                            <>
                                <div className={this.buildClassName('dropdown-option-upper')}>
                                    <span className="option-id">#{group.id}</span>
                                    <span className="option-send-date"></span>
                                </div>
                                <div className={this.buildClassName('dropdown-option-lower')}>
                                    <span className="option-title">{group.name}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )
        }

        return option
    }

    public getPortalPositioning(): any {
        if (this.ref) {
            const rect = this.ref.getBoundingClientRect()

            return [
                ['position', 'absolute'],
                ['top', `${Math.floor(rect.top) + Math.floor(rect.height)}px`],
                ['left', `${Math.floor(rect.left)}px`],
                ['width', `${Math.floor(rect.width)}px`],
            ]
                .map((p) => p.join(':'))
                .join(';')
        }
    }

    protected get isSearchMode(): boolean {
        return this.props.mode !== 'display'
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get isExpanded(): boolean {
        return this.state.expanded
    }

    protected get isSearching(): boolean {
        return this.state.searching
    }

    protected get hasOptions(): boolean {
        return this.state.prompts.length > 0 || (this.props.options ?? []).length > 0
    }

    protected get hasValue(): boolean {
        return !!this.props.value
    }

    protected get hasInput(): boolean {
        return !!this.inputRef && !!this.inputRef.input.value.trim()
    }

    protected get inputValue(): string {
        return this.hasInput ? this.inputRef.input.value.trim() : ''
    }

    protected get entityLevelIsPrompt(): boolean {
        return this.props.entityLevel !== 'promptGroup'
    }

    protected get selectedPrompt(): PromptDto | undefined {
        let prompt: PromptDto | undefined

        if (this.hasOptions && this.hasValue) {
            const options = this.props.options ?? this.state.prompts ?? []
            prompt = options.find((n) => n.id.toString() === this.props.value.toString())
        }

        return prompt
    }

    protected get portalContainer(): HTMLElement {
        return !this.props.getDropdownContainer ? document.body : this.props.getDropdownContainer()
    }

    protected parseNameValue(entity: PromptDto): string {
        let name = entity.name

        if (this.entityLevelIsPrompt) {
            name = entity.name || ((entity as any).promptGroup || {}).name
        }

        return name
    }

    protected updateInputRefValue(value: any): void {
        if (!!this.inputRef) {
            this.inputRef.inputRef.setState({ value })
            this.inputRef.calculateWidth(value)
        }
    }

    @autobind
    protected async handleValueChange(prompt: PromptDto): Promise<void> {
        // clear loaded tmpValue
        await this.setState({ tmpValue: undefined })

        this.emitChangeEvent(prompt.id)
        this.deactivate()

        this.updateInputRefValue(this.parseNameValue(prompt))
    }

    protected handleOptionSelect = async (promptId: number) => {
        this.props.onChange?.(promptId)
    }

    @autobind
    protected async handleInputChange(ev: React.ChangeEvent<HTMLInputElement>): Promise<void> {
        const value = ev.target.value

        this.emitChangeEvent(undefined as any)

        if (value.length >= 3) {
            this.searchPrompts(value)
        } else {
            this.deactivate()
        }
    }

    @autobind
    protected async activate(): Promise<void> {
        this.debounce(() => {
            if (this.hasInput && this.inputValue.length > 3) {
                this.searchPrompts(this.inputValue)
            }
        }, this.debounceValue / 2)
    }

    @autobind
    protected async deactivate(): Promise<void> {
        this.debounce(() => {
            this.cancelRequests()
            return this.setState(() => ({ expanded: false }))
        }, this.debounceValue / 2)
    }

    @autobind
    protected async cancelRequests(): Promise<void> {
        if (this.axiosCancellationKey in axiosCancellationRequests) {
            axiosCancellationRequests[this.axiosCancellationKey]()
        }

        return this.setState(() => ({
            searching: false,
            prompts: [],
        }))
    }

    protected async searchPrompts(query: string, reload: boolean = false): Promise<void> {
        this.setState(() => ({
            expanded: !reload,
            searching: true,
            tmpValue: reload ? (query as any) : undefined,
        }))

        this.debounce(async () => {
            const domainId = this.appState.currentDomain!.id
            const opts = {
                search: query,
                pagination: 0,
                isAbTest: !this.entityLevelIsPrompt,
            }

            const cancellationKey = `${this.axiosCancellationKey}-${this.uid}`
            let prompts = this.props.options
            let cancelled = false

            // Only perform API search if options are not explicitly passed
            if (!prompts) {
                const res = await this.promptService.fetchAllByDomainIdV2(domainId, opts, false, cancellationKey)

                prompts = res.prompts ?? []
                cancelled = res.cancelled
            }

            let compiledPrompts: PromptDto[] = []
            if (this.entityLevelIsPrompt) {
                for (const promptGroup of prompts) {
                    for (const prompt of (promptGroup as any).prompts ?? []) {
                        prompt.promptGroup = promptGroup
                        compiledPrompts.push(prompt)
                    }
                }
            } else {
                compiledPrompts = prompts
            }

            await this.setState(({ expanded, prompts: currentPrompts }) => ({
                prompts: cancelled ? currentPrompts : compiledPrompts,
                searching: expanded && cancelled,
            }))

            if (reload && !!this.selectedPrompt) {
                this.updateInputRefValue(
                    this.selectedPrompt.name || ((this.selectedPrompt as any).promptGroup || {}).name,
                )
            }
        })
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, !!timeout ? timeout : this.debounceValue)
    }

    protected async emitChangeEvent(value: number): Promise<void> {
        if (!!this.props.onChange) {
            this.props.onChange(value)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(append?: string): string {
        const classNames: string[] = [
            !!append ? `${this.defaultClassName}-${append}` : this.defaultClassName,
            this.isDisplayMode ? 'mode-display' : 'mode-search',
            this.isExpanded ? 'expanded' : 'collapsed',
            this.isSearching ? 'searching' : 'static',
        ]
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected wireEventHandlers(): void {
        const showStaticOptions = this.props.displayStaticOptions && Array.isArray(this.props.options)
        if (!this.props.loading && !showStaticOptions) {
            document.addEventListener('mousedown', this.handleDocumentClick.bind(this))
            this.ref.addEventListener('mousedown', preventBubbling)
        }
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.removeEventListener('mousedown', preventBubbling)
    }

    protected handleDocumentClick(event: MouseEvent): void {
        if (this.isExpanded) this.deactivate()
    }
}
