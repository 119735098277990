import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { stripUndefined } from '../../_utils/strip-undefined'
import { IAbstractApnsPrivateKeyConfiguration, IPushPackageConfiguration } from '../../interfaces/send-integrations'

export class WebApnsConfigurationModel {
    public static build(props: WebApnsConfigurationModel | any): WebApnsConfigurationModel {
        const data = props instanceof WebApnsConfigurationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new WebApnsConfigurationModel()
        model.setIsActive(scData.is_active ?? model.getIsActive())
        model.setPrivateKey(scData.private_key)
        model.setPushPackage(scData.push_package)
        model.setExpiration(scData.expiration)

        return model
    }

    private is_active: boolean = false
    private private_key: IAbstractApnsPrivateKeyConfiguration
    private expiration: string // ISO Date Format
    private push_package: IPushPackageConfiguration

    public clone(): WebApnsConfigurationModel {
        return WebApnsConfigurationModel.build(this)
    }

    public serialize(): any {
        if (!this.getIsActive()) {
            return { is_active: false }
        }

        return stripUndefined({
            is_active: this.getIsActive(),
            private_key: this.getPrivateKey(),
            expiration: this.getExpiration(),
            push_package: this.getPushPackage(),
        })
    }

    public getIsActive(): boolean {
        return this.is_active
    }
    public setIsActive(value: boolean) {
        this.is_active = value
    }

    public getPrivateKey(): IAbstractApnsPrivateKeyConfiguration {
        return this.private_key ?? this.buildPrivateKey({})
    }
    public setPrivateKey(value: any) {
        this.private_key = this.buildPrivateKey(value)
    }

    public getExpiration(): string {
        return this.expiration
    }
    public setExpiration(value: string) {
        this.expiration = value
    }

    public getPushPackage(): IPushPackageConfiguration {
        return this.push_package ?? this.buildPushPackage({})
    }
    public setPushPackage(value: Partial<IPushPackageConfiguration>) {
        this.push_package = this.buildPushPackage(value)
    }

    private buildPrivateKey(
        pack: Partial<IAbstractApnsPrivateKeyConfiguration> | any,
    ): IAbstractApnsPrivateKeyConfiguration {
        return {
            private_key_file_url: pack?.private_key_file_url,
            private_key_pass_phrase: pack?.private_key_pass_phrase,
        }
    }

    private buildPushPackage(pack: IPushPackageConfiguration | any): IPushPackageConfiguration {
        return {
            website_push_id: pack?.website_push_id,
            icon_source_url: pack?.icon_source_url,
        }
    }
}
