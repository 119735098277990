'use strict'

export const CONDITIONS_IN_PROP = '$in'

export function parseAccessPolicyConditions(conditions: object | null, context: any): any {
    let parsedConditions: any

    if (conditions && Object.keys(conditions).length) {
        parsedConditions = {}

        for (const key of Object.keys(conditions)) {
            parsedConditions[key] = extractAccessPolicyConditionValue(conditions, key, context)
        }
    }

    return parsedConditions
}

export function mergePolicyConditions(source: any, merge: any) {
    const result: any = {}

    if (source !== undefined && source !== null) {
        for (const key of Object.keys(source)) {
            const isNullOrUndef = source[key] === undefined || source[key] === null

            result[key] = Array.isArray(source[key])
                ? [...source[key]]
                : !isNullOrUndef && typeof source[key] === 'object' && !source[key].hasOwnProperty(CONDITIONS_IN_PROP)
                ? { ...source[key] }
                : source[key]

            if (!isNullOrUndef && typeof result[key] === 'object' && typeof merge[key] === 'object') {
                result[key] = mergePolicyConditions(result[key], merge[key])
                continue
            }

            if (
                result[key] === merge[key] ||
                (Array.isArray(result[key][CONDITIONS_IN_PROP]) && result[key][CONDITIONS_IN_PROP].includes(merge[key]))
            ) {
                continue
            }

            /**
             * uses "$in" for combined values in array format
             * https://casl.js.org/v4/en/guide/conditions-in-depth#supported-operators
             */
            if (!Array.isArray(result[key][CONDITIONS_IN_PROP])) {
                result[key] = { [CONDITIONS_IN_PROP]: [result[key]] }
            }

            result[key] = {
                [CONDITIONS_IN_PROP]: [...result[key][CONDITIONS_IN_PROP], merge[key]],
            }
        }
    }

    return result
}

export function extractAccessPolicyConditionValue(conditions: any, key: string, context: any): any {
    let value = conditions[key]

    if (typeof value === 'string' && value.startsWith('$ctx')) {
        const path = value.split('.').slice(1)

        let pathValue: any = context
        let next = path.shift()
        while (next) {
            // captures invalid tail key
            if (!path.length && !pathValue.hasOwnProperty(next)) {
                console.warn('InvalidConditionDefinitionError', conditions)
                break
            }
            // captures invalid mid key
            if (typeof pathValue !== 'object' || !pathValue.hasOwnProperty(next)) {
                break
            }

            pathValue = pathValue[next]
            next = path.shift()
        }

        if (path.length) {
            // didn't reach end of path
            console.warn('InvalidConditionDefinitionError', conditions)
        } else if (pathValue !== undefined) {
            // null values can be compared
            value = pathValue
        }
    }

    return value
}
