import * as React from 'react'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps, IFilterBuilderState } from '../interfaces/filter-builder'
import * as deepEqual from 'react-fast-compare'
import { getClassNames } from '../../../_utils/classnames'
import clone from 'clone'

export class BrowserFilterBuilder extends React.Component<IFilterBuilderProps, IFilterBuilderState> {
    public state: IFilterBuilderState = {
        operator: 'IN',
        value: undefined,
    }

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 'browser-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                            <span> </span>
                            <span className="filter-val">{this.currentValueDisplay}</span>
                        </span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'IN', label: 'is' },
                                    { value: 'NOT_IN', label: 'is not' },
                                ]}
                                defaultValue={['IN']}
                                value={this.currentValue.operator}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                        <div className="filter-val">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                disableSelectAll={true}
                                mode="multiple"
                                dropdownClassName="filter-val-dropdown"
                                placeholder="Select..."
                                maxDisplayCount={2}
                                maxDisplayFormatter={(options) => {
                                    return `${options.length} Browsers`
                                }}
                                options={[
                                    {
                                        label: 'Common',
                                        options: [
                                            { value: 'Chrome' },
                                            { value: 'Edge' },
                                            { value: 'Firefox' },
                                            { value: 'Safari' },
                                        ],
                                    },
                                    {
                                        label: 'Other',
                                        options: [
                                            { value: 'Internet Explorer' },
                                            { value: 'Opera' },
                                            { value: 'Vivaldi' },
                                            { value: 'Webview' },
                                            { value: 'Yandex Browser' },
                                            { value: 'UNKNOWN', label: 'Unknown' },
                                        ],
                                    },
                                ]}
                                value={this.currentValue.value}
                                onChange={this.handleValueChange}
                                onClose={this.handleValueChangeComplete}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return clone({
            field: 'BROWSER',
            operator: propsValue.operator || 'IN',
            value: this.state.value || propsValue.value,
        })
    }

    protected get currentOperatorDisplay(): string {
        const currentValue = this.currentValue.value || []

        return this.currentValue.operator === 'IN'
            ? currentValue.length === 1
                ? 'is'
                : 'is one of'
            : currentValue.length === 1
            ? 'is not'
            : 'is not one of'
    }

    protected get currentValueDisplay(): string {
        let display: string = ''

        if (!this.currentValue.value) {
            display = 'any browser'
        } else {
            const currentValue = clone(this.currentValue.value || [])
            if (currentValue.length <= 2) {
                display = currentValue.join(' or ')
            } else {
                const lastValue = currentValue.pop()
                display = currentValue.join(', ') + `, or ${lastValue}`
            }
        }

        return display
    }

    protected handleValueChange = async (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            value = undefined
        }

        await this.setState({ value })

        return value
    }

    protected handleValueChangeComplete = async (value: any) => {
        value = await this.handleValueChange(value)

        if (!deepEqual(value, (this.props.value || {}).value)) {
            this.handleChange({ value })
        }
    }

    protected handleChange(config: any): any {
        if (!!config) {
            this.props.onChange({
                field: 'BROWSER',
                operator: this.currentValue.operator,
                ...this.props.value,
                ...config,
            })
        } else {
            this.props.onChange()
        }

        this.setState({ value: undefined })
    }
}
