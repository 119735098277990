import React from 'react'
import './typeahead.scss'
import { LoadingOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, AutoComplete } from 'antd'
import { IRule } from '../../interfaces/rule'
import * as randomString from 'randomstring'
import { axiosFetch, cancelNamedAxiosRequest } from '../../../../config/axios-setup'
import { useDebouncer } from '../../../../_utils/use-debouncer'
import { useMountedRef } from '../../../../_utils/use-mounted-ref'

export type TypeaheadTransform = (response: any) => string[]

/**
 * typeaheadUrl:
 *
 * Should be formatted with %p and %q placeholders for the property and query values respectively.
 */
interface ITypeaheadProps {
    className?: string
    rule: IRule
    typeaheadUrl: string
    typeaheadTransform?: TypeaheadTransform
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
    minTypeaheadLength: number
    autofocus?: boolean
}

interface ITypeaheadState {
    isSearching: boolean
    suggestions: string[]
}

const TypeaheadInput = React.memo((props: ITypeaheadProps) => {
    const cancellationKey = React.useRef(randomString.generate())

    const [state, setState] = React.useState<Partial<ITypeaheadState>>({
        isSearching: false,
        suggestions: [],
    })

    const [runWithDebounce, clearDebounce] = useDebouncer()
    const [_mounted, runIfMounted] = useMountedRef()

    const setValue = (val: any, debounce: boolean = true) => {
        props.onChange(val)
        setTimeout(() => {
            if (val.length >= props.minTypeaheadLength && debounce && props.rule.rule.property) {
                runWithDebounce(async () => {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            isSearching: true,
                        }
                    })

                    const req = await axiosFetch(
                        'get',
                        {
                            url: props.typeaheadUrl.replace('%q', val).replace('%p', props.rule.rule.property),
                        },
                        cancellationKey.current,
                    )

                    runIfMounted(() => {
                        let resolvedSuggestions = req
                        if (props.typeaheadTransform) {
                            resolvedSuggestions = props.typeaheadTransform(req)
                        }

                        resolvedSuggestions = resolvedSuggestions.sort((a, b) =>
                            a.length < b.length ? -1 : b.length < a.length ? 1 : 0,
                        )

                        setState({
                            isSearching: false,
                            suggestions: resolvedSuggestions,
                        })
                    })
                })
            } else {
                clearDebounce()
                cancelNamedAxiosRequest(cancellationKey.current)

                setState({
                    isSearching: false,
                    suggestions: [],
                })
            }
        })
    }

    return (
        <div className="typeahead-wrapper">
            <AutoComplete
                ref={(ev) => props.autofocus && ev?.focus()}
                className={props.className}
                dataSource={state.suggestions}
                defaultActiveFirstOption={false}
                onSelect={(e) => setValue(e.toString(), false)}
                onSearch={(e) => setValue(e)}
                value={props.rule.rule.value}
                dropdownClassName="typeahead-suggestions"
            />
            {state.isSearching && <LoadingOutlined className="typeahead-loading" spin={true} />}
        </div>
    )
})

export default TypeaheadInput
