import { stripUndefined } from '../../_utils/strip-undefined'
import DomainUserModel from './domain-user.model'
import * as deepEqual from 'react-fast-compare'

export interface ICachedAccountUserRecord {
    id: number
    accountId: number
    userId: number
    roleId: number
    roleName: string
    flags: string[]
}

type NewUserAccountUserRecord = Omit<ICachedAccountUserRecord, 'id' | 'userId'>

class AccountUserModel {
    public static fromCached(value: ICachedAccountUserRecord): AccountUserModel {
        return new AccountUserModel(value)
    }

    protected id: number
    protected account_id: number
    protected user_id: number
    protected role_id: number
    protected role_name: string
    protected flags: string[]
    protected domainRecords = new Map<number, DomainUserModel>()

    public constructor(value: ICachedAccountUserRecord | NewUserAccountUserRecord) {
        this.account_id = value.accountId
        this.role_id = value.roleId
        this.role_name = value.roleName
        this.flags = value.flags

        if ('id' in value) {
            this.id = value.id
        }
        if ('userId' in value) {
            this.user_id = value.userId
        }
    }

    public clone(): AccountUserModel {
        return new AccountUserModel(this.serialize())
    }

    public serialize(): ICachedAccountUserRecord {
        return stripUndefined({
            id: this.id,
            accountId: this.account_id,
            userId: this.user_id,
            roleId: this.role_id,
            roleName: this.role_name,
            flags: this.flags,
        })
    }

    public matches(value: ICachedAccountUserRecord | AccountUserModel): boolean {
        const curr = this.serialize()
        const comparison = value instanceof AccountUserModel ? value.serialize() : value
        return deepEqual(curr, comparison)
    }

    public getId() {
        return this.id
    }
    public getAccountId() {
        return this.account_id
    }
    public getUserId() {
        return this.user_id
    }

    public setRoleId(id: number) {
        this.role_id = id
    }
    public getRoleId() {
        return this.role_id
    }

    public setRoleName(name: string) {
        this.role_name = name
    }
    public getRoleName() {
        return this.role_name
    }

    public setFlags(flags: string[]) {
        this.flags = flags
    }
    public getFlags() {
        return this.flags
    }

    public setDomainRecords(records: ArrayLike<DomainUserModel>) {
        this.domainRecords = new Map<number, DomainUserModel>(
            Array.from(records).map((domain) => [domain.getDomainId(), domain]),
        )
    }

    public getDomainRecords() {
        return Object.freeze(this.domainRecords)
    }

    public setDomainRecord(id: number, record: DomainUserModel) {
        if (id !== record.getDomainId()) {
            throw new Error('Domain permissions ID set mismatch')
        }

        this.domainRecords.set(id, record)
    }

    public getDomainRecord(id: number) {
        return this.domainRecords.get(id)
    }

    public hasDomainRecord(id: number) {
        return this.domainRecords.has(id)
    }

    public deleteDomainRecord(id: number) {
        return this.domainRecords.delete(id)
    }

    public serializeDomainRecords() {
        return Array.from(this.domainRecords.values()).map((record) => record.serialize())
    }

    public serializeDomainRecord(id: number) {
        return this.domainRecords.get(id)?.serialize()
    }
}

export default AccountUserModel
