export class WhiteLabelColorConfig {
    public static get Builder() {
        return new WhiteLabelColorConfigBuilder()
    }

    // ---------- legacy color configurations ----------
    public readonly primaryHex: string | null
    public readonly secondaryHex: string | null
    public readonly tertiaryHex: string | null
    // -------------------------------------------------

    // ---------- 202304 color configurations ----------
    public readonly primaryButtonHex: string | null
    public readonly primaryButtonHoverHex: string | null
    public readonly primaryButtonActiveHex: string | null

    public readonly domainSidebarHex: string | null
    public readonly domainSidebarHoverHex: string | null
    public readonly domainSidebarActiveHex: string | null

    public readonly orgSidebarHex: string | null
    public readonly orgSidebarHoverHex: string | null
    public readonly orgSidebarActiveHex: string | null

    public readonly tabHex: string | null
    public readonly switchHex: string | null
    // -------------------------------------------------

    public constructor(args: Partial<WhiteLabelColorConfig>) {
        this.primaryHex = args.primaryHex ?? null
        this.secondaryHex = args.secondaryHex ?? null
        this.tertiaryHex = args.tertiaryHex ?? null
        this.primaryButtonHex = args.primaryButtonHex ?? null
        this.primaryButtonHoverHex = args.primaryButtonHoverHex ?? null
        this.primaryButtonActiveHex = args.primaryButtonActiveHex ?? null
        this.domainSidebarHex = args.domainSidebarHex ?? null
        this.domainSidebarHoverHex = args.domainSidebarHoverHex ?? null
        this.domainSidebarActiveHex = args.domainSidebarActiveHex ?? null
        this.orgSidebarHex = args.orgSidebarHex ?? null
        this.orgSidebarHoverHex = args.orgSidebarHoverHex ?? null
        this.orgSidebarActiveHex = args.orgSidebarActiveHex ?? null
        this.tabHex = args.tabHex ?? null
        this.switchHex = args.switchHex ?? null
    }

    public serialize() {
        return {
            primary_hex: this.primaryHex,
            secondary_hex: this.secondaryHex,
            tertiary_hex: this.tertiaryHex,
            primary_button_hex: this.primaryButtonHex,
            primary_button_hover_hex: this.primaryButtonHoverHex,
            primary_button_active_hex: this.primaryButtonActiveHex,
            domain_sidebar_hex: this.domainSidebarHex,
            domain_sidebar_hover_hex: this.domainSidebarHoverHex,
            domain_sidebar_active_hex: this.domainSidebarActiveHex,
            org_sidebar_hex: this.orgSidebarHex,
            org_sidebar_hover_hex: this.orgSidebarHoverHex,
            org_sidebar_active_hex: this.orgSidebarActiveHex,
            tab_hex: this.tabHex,
            switch_hex: this.switchHex,
        }
    }
}

class WhiteLabelColorConfigBuilder implements Omit<WhiteLabelColorConfig, 'serialize'> {
    protected data: Partial<{ -readonly [k in keyof WhiteLabelColorConfig]: WhiteLabelColorConfig[k] }> = {}

    public get primaryHex(): string | null {
        return this.data.primaryHex ?? null
    }

    public setPrimaryHex(hex: string | null) {
        this.data.primaryHex = hex
        return this
    }

    public get secondaryHex(): string | null {
        return this.data.secondaryHex ?? null
    }

    public setSecondaryHex(hex: string | null) {
        this.data.secondaryHex = hex
        return this
    }

    public get tertiaryHex(): string | null {
        return this.data.tertiaryHex ?? null
    }

    public setTertiaryHex(hex: string | null) {
        this.data.tertiaryHex = hex
        return this
    }

    public get primaryButtonHex(): string | null {
        return this.data.primaryButtonHex ?? null
    }

    public setPrimaryButtonHex(hex: string | null) {
        this.data.primaryButtonHex = hex ?? null
        return this
    }

    public get primaryButtonHoverHex(): string | null {
        return this.data.primaryButtonHoverHex ?? null
    }

    public setPrimaryButtonHoverHex(hex: string | null) {
        this.data.primaryButtonHoverHex = hex ?? null
        return this
    }

    public get primaryButtonActiveHex(): string | null {
        return this.data.primaryButtonActiveHex ?? null
    }

    public setPrimaryButtonActiveHex(hex: string | null) {
        this.data.primaryButtonActiveHex = hex ?? null
        return this
    }

    public get domainSidebarHex(): string | null {
        return this.data.domainSidebarHex ?? null
    }

    public setDomainSidebarHex(hex: string | null) {
        this.data.domainSidebarHex = hex ?? null
        return this
    }

    public get domainSidebarHoverHex(): string | null {
        return this.data.domainSidebarHoverHex ?? null
    }

    public setDomainSidebarHoverHex(hex: string | null) {
        this.data.domainSidebarHoverHex = hex ?? null
        return this
    }

    public get domainSidebarActiveHex(): string | null {
        return this.data.domainSidebarActiveHex ?? null
    }

    public setDomainSidebarActiveHex(hex: string | null) {
        this.data.domainSidebarActiveHex = hex ?? null
        return this
    }

    public get orgSidebarHex(): string | null {
        return this.data.orgSidebarHex ?? null
    }

    public setOrgSidebarHex(hex: string | null) {
        this.data.orgSidebarHex = hex ?? null
        return this
    }

    public get orgSidebarHoverHex(): string | null {
        return this.data.orgSidebarHoverHex ?? null
    }

    public setOrgSidebarHoverHex(hex: string | null) {
        this.data.orgSidebarHoverHex = hex ?? null
        return this
    }

    public get orgSidebarActiveHex(): string | null {
        return this.data.orgSidebarActiveHex ?? null
    }

    public setOrgSidebarActiveHex(hex: string | null) {
        this.data.orgSidebarActiveHex = hex ?? null
        return this
    }

    public get tabHex(): string | null {
        return this.data.tabHex ?? null
    }

    public setTabHex(hex: string | null) {
        this.data.tabHex = hex ?? null
        return this
    }

    public get switchHex(): string | null {
        return this.data.switchHex ?? null
    }

    public setSwitchHex(hex: string | null) {
        this.data.switchHex = hex ?? null
        return this
    }

    public build() {
        return new WhiteLabelColorConfig({
            primaryHex: this.primaryHex,
            secondaryHex: this.secondaryHex,
            tertiaryHex: this.tertiaryHex,
            primaryButtonHex: this.primaryButtonHex,
            primaryButtonHoverHex: this.primaryButtonHoverHex,
            primaryButtonActiveHex: this.primaryButtonActiveHex,
            domainSidebarHex: this.domainSidebarHex,
            domainSidebarHoverHex: this.domainSidebarHoverHex,
            domainSidebarActiveHex: this.domainSidebarActiveHex,
            orgSidebarHex: this.orgSidebarHex,
            orgSidebarHoverHex: this.orgSidebarHoverHex,
            orgSidebarActiveHex: this.orgSidebarActiveHex,
            tabHex: this.tabHex,
            switchHex: this.switchHex,
        })
    }
}
