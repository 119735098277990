import { useEffect } from 'react'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../stores/app'
import { observe, IValueDidChange } from 'mobx'
import { AppService } from '../services'
import { DomainDto } from '../dtos/domain'

export const useActiveDomainChangeEffect = (onChange: (domain: DomainDto, appSvc: AppService) => any) => {
    const app: AppState = Container.get(AppState)

    useEffect(() => {
        return observe(app, 'currentDomainJsonData', (change: IValueDidChange<any>) => {
            const oldValue = JSON.parse(change.oldValue ?? '{}')
            const newValue = JSON.parse(change.newValue ?? '{}')

            if (oldValue.id !== newValue.id) {
                const svc: AppService = Container.get(AppService)
                onChange(app.currentDomain!, svc)
            }
        })
    })
}
