import * as React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { isDef } from '@pushly/aqe/lib/utils/is-def'

interface IAntdTableEmptyPlaceholder {
    icon?: string | React.ReactNode
    text?: string
}

export const AntdTableEmptyPlaceholder = React.memo<IAntdTableEmptyPlaceholder>((props) => (
    <div className="ant-empty ant-empty-normal">
        <div className="ant-empty-image">
            {!isDef(props.icon) ? (
                <InboxOutlined />
            ) : typeof props.icon === 'string' ? (
                <LegacyIcon type={props.icon} />
            ) : (
                props.icon
            )}
        </div>
        <p className="ant-empty-description">{!isDef(props.text) ? 'No Data' : props.text}</p>
    </div>
))
