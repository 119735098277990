import * as React from 'react'
import './table-row-entity-display.scss'

interface IEntityDisplayProps {
    title: string | React.ReactNode
    description?: React.ReactNode
    top?: React.ReactNode
    status?: React.ReactNode
    test?: React.ReactNode
    audience?: React.ReactNode
    badges?: React.ReactNode
}

export class TableRowEntityDisplay extends React.PureComponent<IEntityDisplayProps> {
    public static readonly baseClassName = 'sw-tr-ed'
    public static readonly leftContentClassName = 'sw-tr-ed-left'
    public static readonly rightContentClassName = 'sw-tr-ed-right'

    public render(): React.ReactNode {
        const showDescription = !!this.props.description || !!this.props.test
        const showBottom = !!this.props.badges || !!this.props.audience

        return (
            <div className="sw-tr-ed">
                <div className="sw-tr-ed-wrapper">
                    <div className="sw-tr-ed-top">
                        <div className="sw-tr-ed-top-wrapper">
                            <div className={TableRowEntityDisplay.leftContentClassName}>
                                <span className="sw-tr-ed-title">{this.props.title}</span>
                            </div>
                            <div className={TableRowEntityDisplay.rightContentClassName}>
                                <span className="sw-tr-ed-status">{this.props.status}</span>
                            </div>
                        </div>
                    </div>
                    {showDescription && (
                        <div className="sw-tr-ed-mid">
                            <div className="sw-tr-ed-mid-wrapper">
                                <div className={TableRowEntityDisplay.leftContentClassName}>
                                    <span className="sw-tr-ed-description">{this.props.description}</span>
                                </div>
                                <div className={TableRowEntityDisplay.rightContentClassName}>
                                    <span className="meta-wrapper">
                                        {!!this.props.test && <span className="sw-tr-ed-meta">{this.props.test}</span>}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {showBottom && (
                        <div className="sw-tr-ed-bottom">
                            <div className="sw-tr-ed-bottom-wrapper">
                                <div className={TableRowEntityDisplay.leftContentClassName}>
                                    <div className="sw-tr-ed-badges">{this.props.badges}</div>
                                </div>
                                <div className={TableRowEntityDisplay.rightContentClassName}>
                                    {!!this.props.audience && this.props.audience}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
