import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { stripUndefined } from '../../_utils/strip-undefined'
import { CampaignStep } from './campaign-step'

export class CampaignRevision {
    public static build(props: CampaignRevision | any): CampaignRevision {
        const data = props instanceof CampaignRevision ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const rev = new CampaignRevision()
        rev.setId(scData.id)
        rev.setSteps(scData.steps)

        return rev
    }

    private id: number
    private steps: CampaignStep[] = []

    public clone(): CampaignRevision {
        return CampaignRevision.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            steps: this.getSteps().map((step) => step.serialize()),
        })
    }

    public setId(id: number) {
        this.id = id
    }
    public getId(): number {
        return this.id
    }

    public setSteps(steps: (CampaignStep | any)[]) {
        steps = steps || []
        this.steps = steps.map((step) => CampaignStep.build(step))
    }
    public getSteps(): CampaignStep[] {
        return this.steps
    }
}
