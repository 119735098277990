import * as React from 'react'
import classnames from 'classnames'
import { ConfigurationContext } from './configuration-context'
import { getClassNames } from '../../../_utils/classnames'
import {
    CampaignEditableState,
    CampaignExitBehavior,
    CampaignExitStatus,
    CampaignType,
    NodeType,
    TriggerType,
} from '../enums'
import { BASE_TIME_FORMAT_WITHOUT_TZ, SHORT_DATE_FORMAT } from '../../../constants'
import moment from 'moment-timezone'
import { Input, Well } from '@pushly/aqe/lib/components'
import { HistoryOutlined, InfoCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Form, DatePicker, TimePicker, Radio, Select, Skeleton, Switch, Tooltip } from 'antd'
import { CampaignStep } from '../../../models/campaign/campaign-step'
import { CampaignRevision } from '../../../models/campaign/campaign-revision'
import { delay } from '../../../_utils/utils'
import { RadioChangeEvent } from 'antd/lib/radio'
import { Moment } from 'moment'
import { getSupportedTimezones } from '../../../_utils/moment'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { generateDefaultSubscriberFcap } from '../helpers/campaign'

interface IGenericDetailsWell {}

interface IState {
    name?: string
}

export class GenericDetailsWell extends React.Component<IGenericDetailsWell, IState> {
    public static contextType = ConfigurationContext
    public context!: React.ContextType<typeof ConfigurationContext>

    public state: IState = {}

    protected nameRef: any

    public render() {
        const { mode, loading, campaign, domain, editableState, flags } = this.context
        const campaignType = campaign.getType()
        const config = campaign.getConfiguration()
        const isCampaignEditable = editableState === CampaignEditableState.EDITABLE
        const isCampaignCompleted = editableState === CampaignEditableState.COMPLETED

        const isRecurring = campaignType === CampaignType.RECURRING

        const timezone = 'UTC' // forced UTC for working with date strings only
        const now = moment()
        const today = now.clone().startOf('day')
        const defaultEndDateValue = moment(campaign.getDateStart()).add(1, 'day').startOf('day')
        const endDateEnabled = flags.endDateEnabled ?? !!campaign.getDateEnd()

        const tzOptions = getSupportedTimezones()
        const dateTimeZone = campaign.getDateTimeZone() ?? 'STZ'
        const runStrategy = dateTimeZone === 'STZ' ? 'STZ' : 'FIXED'
        const dateStart = moment(campaign.getDateStart())
        const dateStartStr = dateStart.format(isRecurring ? SHORT_DATE_FORMAT : BASE_TIME_FORMAT_WITHOUT_TZ)
        const dateEnd = !campaign.getDateEnd() ? null : moment(campaign.getDateEnd())
        const dateEndStr = !dateEnd
            ? null
            : dateEnd.format(isRecurring ? SHORT_DATE_FORMAT : BASE_TIME_FORMAT_WITHOUT_TZ)

        return (
            <Well
                className={getClassNames(null, 'campaign-config-well', 'nested', `mode-${mode}`)}
                title="Details"
                hideFooter={true}
            >
                <Skeleton
                    loading={loading}
                    active={true}
                    title={false}
                    paragraph={{
                        rows: 1,
                        width: '100%',
                    }}
                >
                    <Well mode="ghost" title="Campaign Type" hideFooter={true}>
                        <Radio.Group
                            className="campaign-type-selection"
                            size="small"
                            buttonStyle="solid"
                            value={campaignType}
                            onChange={this.handleTypeChange}
                            disabled={!!campaign.getId()}
                        >
                            <Radio.Button value={CampaignType.TRIGGERED}>
                                <PlayCircleOutlined />
                                <span>Triggered</span>
                            </Radio.Button>
                            <Radio.Button value={CampaignType.RECURRING}>
                                <HistoryOutlined />
                                <span>Scheduled</span>
                            </Radio.Button>
                        </Radio.Group>
                    </Well>

                    {!!campaignType && (
                        <>
                            <Well mode="ghost" title="Name" hideFooter={true}>
                                <div className="campaign-name-wrapper">
                                    <Input
                                        ref={(el) => (this.nameRef = el)}
                                        className="campaign-name"
                                        size="small"
                                        autoComplete="off"
                                        minChars={3}
                                        maxChars={50}
                                        charExceededMessage="Campaign name must be 3-50 characters long."
                                        value={this.state.name ?? campaign.getName()}
                                        onChange={this.handleNameChange}
                                    />
                                </div>
                            </Well>

                            <Well mode="ghost" title="Run Dates" hideFooter={true}>
                                <div className="strategy-selection">
                                    <Form.Item label="Run Strategy">
                                        <Radio.Group
                                            size="small"
                                            buttonStyle="solid"
                                            value={runStrategy}
                                            onChange={(ev) => this.handleRunStrategyChange(ev.target.value)}
                                        >
                                            <Radio.Button value="FIXED">
                                                <span>Fixed</span>
                                            </Radio.Button>
                                            <Radio.Button value="STZ">Subscriber Time Zone</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>

                                <div className="run-dates-row">
                                    <Form.Item
                                        className={classnames('delivery-date-start', {
                                            'with-time': !isRecurring,
                                        })}
                                        label="Campaign Start"
                                    >
                                        <div className="delivery-senddate-picker">
                                            <DatePicker
                                                className="delivery-date-picker"
                                                format="ddd, MMM DD, YYYY"
                                                placeholder="Start Date"
                                                disabled={!isCampaignEditable}
                                                disabledDate={(current) => !!current && current < today}
                                                value={!dateStart ? moment().startOf('day') : dateStart}
                                                onChange={(value) => this.handleDateChange('DateStart', value)}
                                            />

                                            {!isRecurring && (
                                                <TimePicker
                                                    className="delivery-time-picker"
                                                    format="h:mm a"
                                                    use12Hours={true}
                                                    placeholder="Time"
                                                    disabled={!isCampaignEditable}
                                                    value={
                                                        !campaign.getDateStart() ? moment().startOf('day') : dateStart
                                                    }
                                                    onChange={(value) => this.handleTimeChange('DateStart', value)}
                                                    onSelect={(value) => this.handleTimeChange('DateStart', value)}
                                                />
                                            )}
                                        </div>
                                    </Form.Item>

                                    <Form.Item
                                        className={classnames('delivery-date-end', {
                                            'with-time': !isRecurring,
                                        })}
                                        label={
                                            <span>
                                                <span>Campaign End</span>

                                                <Switch
                                                    size="small"
                                                    disabled={isCampaignCompleted}
                                                    checked={endDateEnabled}
                                                    onChange={this.handleEndDateToggle}
                                                />
                                            </span>
                                        }
                                    >
                                        <div className="delivery-senddate-picker">
                                            <DatePicker
                                                className="delivery-date-picker"
                                                format="ddd, MMM DD, YYYY"
                                                placeholder="End Date"
                                                disabled={isCampaignCompleted || !endDateEnabled}
                                                disabledDate={(current) =>
                                                    !!dateStart && !!current && current < dateStart
                                                }
                                                value={!dateEnd ? undefined : dateEnd}
                                                onChange={(value) => this.handleDateChange('DateEnd', value)}
                                            />

                                            {!isRecurring && (
                                                <TimePicker
                                                    className="delivery-time-picker"
                                                    format="h:mm a"
                                                    use12Hours={true}
                                                    placeholder="Time"
                                                    disabled={isCampaignCompleted || !endDateEnabled}
                                                    value={!dateEnd ? undefined : dateEnd}
                                                    onChange={(value) => this.handleTimeChange('DateEnd', value)}
                                                    onSelect={(value) => this.handleTimeChange('DateEnd', value)}
                                                />
                                            )}
                                        </div>
                                    </Form.Item>

                                    {runStrategy === 'FIXED' && (
                                        <Form.Item className="delivery-time-zone" label="Time Zone">
                                            <Select
                                                className="delivery-tz-picker"
                                                dropdownClassName="delivery-tz-picker-overlay"
                                                disabled={!isCampaignEditable}
                                                showSearch={true}
                                                placeholder="Select a time zone"
                                                value={campaign.getDateTimeZone()}
                                                onChange={this.handleTimeZoneChange}
                                            >
                                                {tzOptions.map((tz) => (
                                                    <Select.Option key={tz} value={tz}>
                                                        {tz}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    )}
                                </div>

                                <span className="strategy-description">
                                    {this.getStrategyDescription(
                                        runStrategy,
                                        dateTimeZone,
                                        endDateEnabled,
                                        dateStartStr,
                                        dateEndStr,
                                    )}
                                </span>
                            </Well>

                            {endDateEnabled && (
                                <Well
                                    className="end-date-behavior"
                                    mode="ghost"
                                    title="End Date Behavior"
                                    hideFooter={true}
                                >
                                    <span className="end-date-behavior-label">
                                        When the campaign completes, all current active members will:
                                        <Tooltip
                                            title={
                                                <>
                                                    No additional subscribers will be added to the campaign when it is
                                                    completed. Choose one of the options to determine how members who
                                                    are currently active in the campaign will be treated when the
                                                    campaign is complete.
                                                </>
                                            }
                                        >
                                            <InfoCircleOutlined className="info-icon" />
                                        </Tooltip>
                                    </span>

                                    <div>
                                        <Select
                                            className="end-date-behavior-select"
                                            dropdownClassName="end-date-behavior-dropdown"
                                            defaultValue={config.getComputedEndBehavior()}
                                            disabled={isCampaignCompleted}
                                            onChange={this.handleEndDateBehaviorChange}
                                        >
                                            <Select.Option value={CampaignExitBehavior.BLEED}>
                                                Continue executing steps of the campaign
                                            </Select.Option>
                                            <Select.Option
                                                value={`${CampaignExitBehavior.STOP}:${CampaignExitStatus.COMPLETED}`}
                                            >
                                                Stop executing steps and have their status set to “Completed”
                                            </Select.Option>
                                            <Select.Option
                                                value={`${CampaignExitBehavior.STOP}:${CampaignExitStatus.REMOVED}`}
                                            >
                                                Stop executing steps and have their status set to “Removed”
                                            </Select.Option>
                                        </Select>
                                    </div>
                                </Well>
                            )}
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected getStrategyDescription(
        runStrategy: string,
        dateTimeZone: string,
        endDateEnabled: boolean,
        dateStartStr?: string,
        dateEndStr?: string,
    ) {
        const descriptors: string[] = [`The campaign will start on ${dateStartStr}`]
        if (endDateEnabled && dateEndStr) {
            descriptors.push(`and run through ${dateEndStr}`)
        }
        if (runStrategy === 'STZ') {
            descriptors.push(`in each subscriber's time zone`)
        } else {
            descriptors.push(`in the ${dateTimeZone} time zone`)
        }

        return `${descriptors.join(' ')}.`
    }

    protected handleTypeChange = async (ev: RadioChangeEvent) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        const audience = config.getAudience()
        audience.setSegmentIds(undefined)
        audience.setExcludedSegmentIds(undefined)

        const trigger = CampaignStep.build({ type: NodeType.TRIGGER })
        const rev = campaign.getCurrentRevision() ?? CampaignRevision.build({})

        const type = ev.target.value as CampaignType
        if (type === CampaignType.RECURRING) {
            config.setSchedule({
                time_zone: campaign.getDateTimeZone() ?? 'STZ',
                type: 'daily',
                frequency: 1,
                allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
            })

            audience.setSegmentIds(!this.context.domainDefaultSegment ? [] : [this.context.domainDefaultSegment?.id])

            // set initial recurring trigger
            trigger.setConfiguration({
                type: TriggerType.SEGMENT_EVALUATION,
                params: {},
            })

            config.setSubscriberFcap(generateDefaultSubscriberFcap())
        } else {
            config.setSchedule(undefined)
            config.setSubscriberFcap(undefined)

            // set initial triggered trigger
            trigger.setConfiguration({
                type: TriggerType.SUBSCRIBED,
                params: {
                    event_type: TriggerType.SUBSCRIBED,
                },
            })
        }

        campaign.setType(type)

        rev.setSteps([trigger])
        campaign.setCurrentRevision(rev)

        this.context.setCampaign(campaign)
        this.context.setFlags({ criteriaEnabled: false })

        return delay(() => {
            this.nameRef?.input.focus()
        }, 0.1)
    }

    protected handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const campaign = this.context.campaign.clone()

        const name = ev.target.value
        campaign.setName(name)

        this.setState({ name })
        this.context.setCampaign(campaign, true)
    }

    protected handleRunStrategyChange = (strategy: 'FIXED' | 'STZ') => {
        const { domain } = this.context

        const campaign = this.context.campaign.clone()
        const tz = strategy === 'STZ' ? 'STZ' : domain.timezone

        campaign.setDateTimeZone(tz)
        if (campaign.getType() === CampaignType.RECURRING) {
            this.applyTimeZoneToRecurringSchedule(campaign, tz)
        }

        this.context.setCampaign(campaign)
    }

    protected handleTimeZoneChange = (timeZone: string) => {
        const campaign = this.context.campaign.clone()

        campaign.setDateTimeZone(timeZone)
        if (campaign.getType() === CampaignType.RECURRING) {
            this.applyTimeZoneToRecurringSchedule(campaign, timeZone)
        }

        this.context.setCampaign(campaign)
    }

    protected applyTimeZoneToRecurringSchedule(campaign: CampaignModel, timeZone: string) {
        const config = campaign.getConfiguration()
        const schedule = config.getSchedule()

        if (schedule) {
            schedule.setTimeZone(timeZone)
        }
    }

    protected handleDateChange = (handlerKey: 'DateStart' | 'DateEnd', date: Moment | null) => {
        const campaign = this.context.campaign.clone()
        let nextDate = date

        const getter = campaign[`get${handlerKey}`].bind(campaign)
        const setter = campaign[`set${handlerKey}`].bind(campaign)

        if (nextDate && getter()) {
            const prevDate = moment(getter())
            nextDate.hour(prevDate.hour())
            nextDate.minute(prevDate.minute())
        }

        if (nextDate && campaign.getType() === CampaignType.RECURRING) {
            /**
             * recurring campaigns are locked, in the UI, as:
             * start - YYYY-MM-DD 00:00:00
             * end   - YYYY-MM-DD 11:59:59
             *
             * this allows the campaign to run through the selected end date
             */
            if (handlerKey === 'DateStart') {
                nextDate.startOf('day')
            } else {
                nextDate.endOf('day')
            }
        }

        const startDate = !nextDate ? undefined : nextDate.format('YYYY-MM-DD HH:mm:ss')
        setter(startDate)

        this.context.setCampaign(campaign)
    }

    protected handleTimeChange = (handlerKey: 'DateStart' | 'DateEnd', time: Moment | null) => {
        const campaign = this.context.campaign.clone()
        let nextDate = time

        const getter = campaign[`get${handlerKey}`].bind(campaign)
        const setter = campaign[`set${handlerKey}`].bind(campaign)

        if (time && getter()) {
            nextDate = moment(getter())
            nextDate!.hour(time.hour())
            nextDate!.minute(time.minute())
            nextDate!.second(59) // always run through the full minute
        }

        const startDate = !nextDate ? undefined : nextDate.format('YYYY-MM-DD HH:mm:ss')
        setter(startDate)

        this.context.setCampaign(campaign)
    }

    protected handleEndDateToggle = (endDateEnabled: boolean) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        if (!endDateEnabled) {
            campaign.setDateEnd(undefined)
            config.setEndBehavior(CampaignExitBehavior.BLEED)
            config.setEndStatus(CampaignExitStatus.COMPLETED)
        } else {
            const seed = campaign.getDateStart() ? moment(campaign.getDateStart()) : moment()

            seed.add(1, 'day').endOf('day')
            campaign.setDateEnd(seed.format('YYYY-MM-DD HH:mm:ss'))
        }

        this.context.setFlags({ endDateEnabled })
        this.context.setCampaign(campaign)
    }

    protected handleEndDateBehaviorChange = (endDateBehavior: string) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        let endBehavior = endDateBehavior
        let endStatus
        if (/:/.test(endDateBehavior)) {
            const parts = endDateBehavior.split(':')
            endBehavior = parts[0]
            endStatus = parts[1]
        }

        config.setEndBehavior(endBehavior)
        config.setEndStatus(endStatus)

        this.context.setCampaign(campaign)
    }
}
