import * as React from 'react'
import { Well } from '../../../components/well/well'
import { CampaignDto } from '../dtos/campaign-dto'
import * as moment from 'moment-timezone'
import {
    DEFAULT_HIGHCHARTS_CONFIG,
    INSIGHTS_CLICKS_COLOR,
    INSIGHTS_CTR_COLOR,
    INSIGHTS_IMPS_COLOR,
    INSIGHTS_SR_COLOR,
} from '../../../constants'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import autobind from 'autobind-decorator'
import { numberWithPercent } from '../../../_utils/utils'
import { DomainDto } from '../../../dtos/domain'
import { getDomainCurrencyCode } from '../../../_utils/domain'

interface IProps {
    campaign: CampaignDto
    domain?: DomainDto
    stats: any[]
    timeBreakdown: string
    loading: boolean
}

export class CampaignPerformanceGraph extends React.PureComponent<IProps> {
    public render(): React.ReactNode {
        return (
            <Well
                className="thin primary-stats-well performance-stats-well"
                title="Performance Summary by Day"
                showFooter={false}
                ghost={true}
            >
                {this.renderChart()}
            </Well>
        )
    }

    @autobind
    protected renderChart(): React.ReactNode {
        const { stats } = this.props
        if (!stats) {
            return
        }

        const xAxisData = stats.map((stat: any) => {
            return moment.tz(stat.event_date, 'UTC').format('MMM D')
        })

        const impsData = stats.map((stat: any) => stat.impressions || 0)
        const clicksData = stats.map((stat: any) => stat.clicks || 0)
        const ctrData = stats.map((stat: any) => parseFloat(numberWithPercent(stat.ctr_decimal)) || 0)
        const revData = stats.map((stat: any) => parseFloat(stat.purchase_amount) || 0)
        const currency = getDomainCurrencyCode(this.props.domain)

        const markerDefaults = {
            lineWidth: 2,
            fillColor: 'white',
        }

        const options = {
            ...DEFAULT_HIGHCHARTS_CONFIG,
            chart: {
                type: 'column',
                marginLeft: 50,
                marginRight: 50,
                marginTop: 10,
                padding: 10,
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: xAxisData,
            },
            yAxis: [
                {
                    type: 'logarithmic',
                    title: { text: '' },
                    allowDecimals: false,
                    startOnTick: 0,
                    minRange: 0,
                },
                {
                    title: { text: '' },
                    min: 0,
                    max: 100,
                    maxPadding: 0.2,
                    allowDecimals: false,
                    labels: {
                        format: '{value}%',
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                formatter: HighchartsUtils.defaultTooltipFormatter,
                shared: true,
            },
            series: [
                {
                    type: 'column',
                    name: 'Impressions',
                    data: impsData,
                    color: INSIGHTS_IMPS_COLOR,
                    tooltip: {
                        pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                    },
                },
                {
                    type: 'column',
                    name: 'Clicks',
                    data: clicksData,
                    color: INSIGHTS_CLICKS_COLOR,
                    tooltip: {
                        pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                    },
                },
                {
                    type: 'column',
                    name: 'Revenue',
                    data: revData,
                    color: INSIGHTS_SR_COLOR,
                    tooltip: {
                        pointFormatter: HighchartsUtils.currencyTooltipPointFormatter(currency),
                    },
                },
                {
                    type: 'spline',
                    name: 'CTR',
                    data: ctrData,
                    color: INSIGHTS_CTR_COLOR,
                    yAxis: 1,
                    marker: {
                        ...markerDefaults,
                        lineColor: INSIGHTS_CTR_COLOR,
                    },
                    tooltip: {
                        valueSuffix: '%',
                    },
                },
            ],
        }

        return <HighchartsReact highcharts={Highcharts} options={options} />
    }
}
