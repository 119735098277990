type VisibilityChangeHandler = (visible: boolean, ev?: Event) => void

let observers: { [name: string]: VisibilityChangeHandler } = {}
let observing = false

const browserPrefixes = ['moz', 'ms', 'webkit', 'o']
let hiddenPropName = 'hidden'
let visEventName = 'visibilitychange'

if (!(hiddenPropName in document)) {
    const match = browserPrefixes.find((pfx) => `${pfx}Hidden` in document)
    if (match) {
        hiddenPropName = `${match}Hidden`
        visEventName = `${match}${visEventName}`
    }
}

/**
 * When calculating isVisible we do not want to couple
 * document.hasFocus() as users might have the platform
 * open to a list page, such as the notifications list,
 * where they want auto-refresh to occur in the background
 * for monitoring purposes
 */
export function isVisible() {
    return !document[hiddenPropName]
}

let lastVisibleState = isVisible()

function handleVisibilityChange(ev: Event, visibleOverride?: boolean): void {
    const visibleState = isVisible()
    if (observing && visibleState !== lastVisibleState) {
        lastVisibleState = visibleState

        Object.entries(observers).forEach(([name, handler]) => {
            handler(visibleOverride ?? visibleState, ev)
        })
    }
}

function handleWindowFocus(ev: FocusEvent): void {
    handleVisibilityChange(ev, true)
}

function handleWindowBlur(ev: FocusEvent): void {
    handleVisibilityChange(ev, false)
}

export function startVisibilityObserver() {
    if (!observing) {
        observing = true
        document.addEventListener(visEventName, handleVisibilityChange)
        window.addEventListener('focus', handleWindowFocus)
        window.addEventListener('blur', handleWindowBlur)
    }
}

export function stopVisibilityObserver() {
    if (observing) {
        document.removeEventListener(visEventName, handleVisibilityChange)
        window.removeEventListener('focus', handleWindowFocus)
        window.removeEventListener('blur', handleWindowBlur)
        observing = false
    }
}

export function addVisibilityChangeHandler(handler: VisibilityChangeHandler)
export function addVisibilityChangeHandler(handler: VisibilityChangeHandler, immediate: boolean)
export function addVisibilityChangeHandler(name: string, handler: VisibilityChangeHandler)
export function addVisibilityChangeHandler(name: string, handler: VisibilityChangeHandler, immediate: boolean)
export function addVisibilityChangeHandler(
    nameOrHandler: string | VisibilityChangeHandler,
    handlerOrImmediate?: boolean | VisibilityChangeHandler,
    immediate: boolean = true,
): VisibilityAwareIntervalCleaner {
    const name = typeof nameOrHandler === 'string' ? nameOrHandler : btoa(nameOrHandler.toString())

    observers[name] =
        typeof nameOrHandler === 'string' ? (handlerOrImmediate as VisibilityChangeHandler) : nameOrHandler

    const invokeImmediately = typeof handlerOrImmediate === 'boolean' ? handlerOrImmediate : immediate

    if (invokeImmediately && isVisible()) {
        observers[name](!document.hidden)
    }

    return () => {
        removeVisibilityChangeHandler(name)
    }
}

export function removeVisibilityChangeHandler(handler: VisibilityChangeHandler)
export function removeVisibilityChangeHandler(name: string)
export function removeVisibilityChangeHandler(nameOrHandler: string | VisibilityChangeHandler) {
    let name = typeof nameOrHandler === 'string' ? nameOrHandler : btoa(nameOrHandler.toString())

    delete observers[name]
}

export function clearVisibilityChangeHandlers() {
    observers = {}
}

/**
 * Convenience Types
 */
export type VisibilityAwareIntervalRunner = () => any
export type VisibilityAwareIntervalCleaner = () => void
export type LastRunTimestampUpdater = () => void

export function addVisibilityAwareIntervalRunner(
    name: string,
    runner: VisibilityAwareIntervalRunner,
    intervalMS: number,
    skipInitialRun: boolean = false,
): [VisibilityAwareIntervalCleaner, LastRunTimestampUpdater] {
    const runInterval = intervalMS
    let runTimer: any
    let lastRunTimestamp: number = skipInitialRun ? new Date().getTime() : 0

    const _runner = async () => {
        if (!isVisible()) {
            return
        }

        const currTimestamp = new Date().getTime()
        const diff = currTimestamp - lastRunTimestamp

        if (diff >= runInterval) {
            await Promise.resolve(runner())
            lastRunTimestamp = new Date().getTime()
        }

        let timeoutMS = diff >= runInterval ? runInterval : runInterval - diff

        runTimer = setTimeout(_runner, timeoutMS)
    }

    addVisibilityChangeHandler(name, (visible) => {
        if (visible) {
            _runner()
        } else {
            clearTimeout(runTimer)
        }
    })

    /**
     * Returns
     * 1: cleanup fn which both clears the handler and any existing timeout
     * 2: fn which allows manual override of last run timestamp - useful for lists with manual refresh btn
     */
    const cleaner = () => {
        removeVisibilityChangeHandler(name)
        clearTimeout(runTimer)
    }

    const setLastRunTimestamp = () => {
        lastRunTimestamp = new Date().getTime()
    }

    return [cleaner, setLastRunTimestamp]
}
