import * as React from 'react'
import { AppState } from '../../../../stores/app'
import { AppService } from '../../../../services'
import { Container } from 'typescript-ioc'
import { Drawer, executeCleanDrawerRender, Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../../_utils/classnames'
import { FormInstance } from 'antd/lib/form'
import { SavedReportScheduleModel } from '../../../../models/saved-reports/saved-report-schedule.model'
import { SRScheduleContext } from '../saved-report-schedule-context'
import { SRSBuilderMode } from './enums'
import { SavedReportScheduleBuilder } from './saved-report-schedule-builder'
import { Form, Select } from 'antd'
import { DeliverableStrategies } from '../../../../enums/deliverable-strategies.enum'
import { NextOccurrencesPreview } from '../../../../components/campaign-builder/configuration-editor/entry-well'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../../../constants'
import { simpleFormErrorNotification } from '../../../../_utils/utils'
import { InsightsService } from '../../../../services/insights'
import { AsyncButton } from '../../../../components/async-button/async-button.component'

interface ISRSDProps {
    visible: boolean
    onClose: (report?: any, refresh?: boolean) => void
    onSubmit: (schedule: any, cancellationKey: string) => void
    report: any
    schedule: SavedReportScheduleModel
}

interface ISRSDState {
    tmpValue: SavedReportScheduleModel
    delivery_types?: string[]
}

let CHANGE_DEBOUNCE_TIMEOUT: any

export class SavedReportsScheduleDrawer extends React.Component<ISRSDProps, ISRSDState> {
    public savedReportSchedule: SavedReportScheduleModel

    public state: ISRSDState = {
        tmpValue: this.props.schedule,
    }

    protected readonly appState: AppState
    protected readonly appService: AppService
    protected readonly insightsService: InsightsService

    protected formRef = React.createRef<FormInstance>()

    public constructor(props: ISRSDProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)

        executeCleanDrawerRender(this)
    }

    public render() {
        const { report } = this.props
        const { tmpValue } = this.state

        const mode = this.props.schedule && this.props.schedule.getId() ? SRSBuilderMode.EDIT : SRSBuilderMode.CREATE

        let emails: any[] = []
        if (mode > 0) {
            emails = this.props.schedule.getConfiguration().getDeliveryStrategies()[0].to
        }

        return (
            <SRScheduleContext.Provider
                value={{
                    mode,
                    loading: false,
                    savedReport: report,
                    savedReportSchedule: tmpValue,
                    setSavedReportSchedule: this.emitChange,
                }}
            >
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('sr-schedule-drawer')}
                    title={`${mode > 0 ? 'Edit' : 'Create'} Saved Report Schedule`}
                    placement="right"
                    closable={true}
                    onClose={() => this.props.onClose({})}
                    onSubmit={this.handleSubmit}
                    visible={this.props.visible}
                    footer={this.renderScheduleFooter()}
                >
                    <Form
                        {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}
                        className="hide-inline-errors"
                        ref={this.formRef}
                        initialValues={{ delivery_type: ['EMAIL'], delivery_to_email: emails }}
                        onValuesChange={async (changed, values) => {
                            if (changed.delivery_type) {
                                await this.handleDeliveryTypes(changed.delivery_type)
                            }
                            if (changed.delivery_to_email) {
                                await this.handleDeliveryToEmail(changed.delivery_to_email)
                            }
                        }}
                    >
                        <Well className="destinations-builder" title="Destinations" mode="ghost" hideFooter={true}>
                            <Form.Item
                                name="delivery_type"
                                label="Delivery Type"
                                rules={[{ required: true }]}
                                hidden={true}
                            >
                                <Select mode="multiple" size="small" showSearch={false}>
                                    <Select.Option value={DeliverableStrategies.EMAIL}>Email</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="delivery_to_email"
                                label="Email Addresses"
                                rules={[
                                    {
                                        validator: this.validateEmails,
                                    },
                                ]}
                            >
                                <Select mode="tags" size="small" notFoundContent={null} tokenSeparators={[',']} />
                            </Form.Item>
                        </Well>
                        <Well
                            title="Report Delivery Schedule"
                            className="schedule-builder"
                            mode="ghost"
                            hideFooter={true}
                        >
                            <Form.Item
                                name="schedule"
                                rules={[
                                    { required: true },
                                    {
                                        validator: this.validateSchedule,
                                    },
                                ]}
                            >
                                <SavedReportScheduleBuilder schedule={this.state.tmpValue} />
                            </Form.Item>
                        </Well>

                        <div className="schedule-next-occurrences">
                            <NextOccurrencesPreview campaign={this.state.tmpValue} />
                        </div>
                    </Form>
                </Drawer>
            </SRScheduleContext.Provider>
        )
    }

    public renderScheduleFooter = () => {
        return (
            <div className="schedule-drawer-footer">
                <div className="schedule-delete">
                    {this.props?.schedule?.getId() && (
                        <AsyncButton className="ant-btn-primary" onClick={() => this.handleDelete(this.props.schedule)}>
                            <span>Delete Schedule</span>
                        </AsyncButton>
                    )}
                </div>
                <div className="schedule-submit">
                    <AsyncButton className="ant-btn-primary" onClick={this.handleSubmit}>
                        <span>Submit</span>
                    </AsyncButton>
                </div>
            </div>
        )
    }

    protected get formValues() {
        return this.formRef.current?.getFieldsValue()
    }

    protected emitChange = async (changes: SavedReportScheduleModel, debounce: boolean = false) => {
        clearTimeout(CHANGE_DEBOUNCE_TIMEOUT)
        CHANGE_DEBOUNCE_TIMEOUT = setTimeout(
            () => {
                this.setState({ tmpValue: changes })
            },
            debounce ? 320 : 0,
        )
    }

    protected handleSubmit = async () => {
        await this.formRef.current?.setFieldsValue({ schedule: this.state.tmpValue!.getConfiguration().getSchedule() })

        try {
            await this.formRef.current?.validateFields()

            const cancellationKey = 'sr.schedule'
            const schedule = this.state.tmpValue.clone()
            // manually ensure the proper report id is set
            schedule.setSavedReportId(this.props.report.id)

            this.props.onSubmit(schedule, cancellationKey)
        } catch (error) {
            if (error) {
                const errorObj = {
                    key: {
                        errors: [{ message: error.errorFields[0].errors[0] }],
                    },
                }
                simpleFormErrorNotification(errorObj)
            }
        }
    }

    private validateSchedule = async (_rule?, _value?, _cb?): Promise<any> => {
        const schedule = this.formRef.current?.getFieldValue('schedule')
        const scheduleType = schedule.getType()
        const scheduleSuperType = scheduleType.replace(/_relative|_specific/, '')

        if (!schedule) {
            await Promise.reject('Recurrence schedule is required.')
        } else if (!scheduleType) {
            await Promise.reject('Schedule type is required.')
        } else {
            if (scheduleSuperType === 'daily' || scheduleSuperType === 'weekly') {
                const frequency = schedule.getFrequency()
                const allowedDays = schedule.getAllowedDays()
                const isDaily = scheduleSuperType === 'daily'

                if (frequency === undefined) {
                    await Promise.reject(
                        isDaily ? 'Daily schedule frequency is required.' : 'Weekly schedule frequency is required.',
                    )
                } else if (isNaN(frequency)) {
                    await Promise.reject((isDaily ? 'Daily' : 'Weekly') + ' schedule frequency is not a valid number.')
                } else if (frequency <= 0) {
                    await Promise.reject((isDaily ? 'Daily' : 'Weekly') + ' schedule frequency must be greater than 0.')
                } else if (!allowedDays || allowedDays.length === 0) {
                    await Promise.reject(
                        isDaily
                            ? 'At least one day selection is required for the current daily schedule.'
                            : 'Day of the week is required for the current weekly schedule.',
                    )
                }
            } else if (scheduleType === 'monthly_specific') {
                if (!schedule.getSpecificDay()) {
                    await Promise.reject('Specific day of the month is required for the current schedule.')
                }
            } else if (scheduleType === 'monthly_relative') {
                const rType = schedule.getRelativeType()
                const ordinal = schedule.getRelativeDayOrdinal()
                const day = schedule.getRelativeDay()

                if (rType === 'relative_day' && (!ordinal || !day)) {
                    await Promise.reject(
                        'Invalid relative monthly schedule. Both week number and day of the week are required.',
                    )
                }
            }

            if (!schedule.getSpecificTime()) {
                await Promise.reject('Schedule delivery time is required.')
            }

            if (!schedule.getTimeZone()) {
                await Promise.reject('Schedule delivery time zone is required.')
            }
        }

        await Promise.resolve()
    }

    private async handleDeliveryTypes(delivery_type: any) {
        if (delivery_type.includes(DeliverableStrategies.EMAIL)) {
            const delivery_strategy = {
                type: DeliverableStrategies.EMAIL,
                to: [],
            }
            const schedule = this.state.tmpValue!.clone()
            const config = schedule.getConfiguration()
            config?.setDeliveryStrategies([delivery_strategy])

            await this.emitChange(schedule)
        }

        this.setState({ delivery_types: delivery_type })
    }

    private async handleDeliveryToEmail(delivery_to_email: any) {
        const schedule = this.state.tmpValue!.clone()
        const config = schedule.getConfiguration()
        let strategy = config?.getDeliveryStrategies().filter((strat) => strat.type === DeliverableStrategies.EMAIL)
        if (strategy.length === 1) {
            strategy[0].to = delivery_to_email
        } else {
            strategy.push({
                type: DeliverableStrategies.EMAIL,
                to: delivery_to_email,
            })
        }

        config.setDeliveryStrategies(strategy)
        await this.emitChange(schedule)
    }

    private validateEmails = async (_, emails: string[], callback: Function) => {
        const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i
        const emailsFailed = emails.filter((email) => !emailRegex.test(email))

        if (emailsFailed.length >= 1) {
            await Promise.reject(`The following emails are not valid: ${emailsFailed.join(', ')}`)
        } else if (emails.length === 0) {
            await Promise.reject(`At least 1 email is required to schedule a report for delivery`)
        } else {
            await Promise.resolve()
        }
    }

    private handleDelete = async (schedule: any) => {
        const domainId = this.appState.currentDomain!.id
        const scheduleId = schedule.id
        try {
            const cancellationKey = 'sr.delete-schedule'

            const ok = await this.insightsService.destroySavedReportSchedule(
                scheduleId,
                domainId,
                this.appState.currentUser!.id,
                false,
                cancellationKey,
            )

            if (ok) this.props.onClose({}, true)
        } catch (error) {
            if (error) {
                simpleFormErrorNotification(error)
            }
        }
    }
}
