import React from 'react'
import { FcmSendIntegrationApiVersion, IAbstractNativeFcmSendIntegration } from '../../interfaces/send-integrations'
import {
    NativeLegacyFcmConfiguration,
    NativeV1FcmConfiguration,
} from '../../models/send-integration-configurations/native-fcm-configurations.model'
import { FormLayoutRow } from '../form-layout-row/form-layout-row'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

type NativeFcmIntegrationWellProps = {
    fcmConfig: IAbstractNativeFcmSendIntegration
}

export const NativeFcmIntegrationWell = (props: NativeFcmIntegrationWellProps) => {
    let configClass: typeof NativeV1FcmConfiguration | typeof NativeLegacyFcmConfiguration | undefined
    switch (props.fcmConfig.apiVersion) {
        case FcmSendIntegrationApiVersion.V1:
            configClass = NativeV1FcmConfiguration
            break

        case FcmSendIntegrationApiVersion.LEGACY:
            configClass = NativeLegacyFcmConfiguration
            break
    }

    const config = configClass?.build(props.fcmConfig)
    const enabled = config?.getIsActive() ?? false

    return (
        <div>
            <div className="integrations-section-title">
                <span className={enabled ? 'enabled' : 'disabled'}>
                    {enabled ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                    <span>NATIVE</span>
                </span>
            </div>

            {!enabled ? (
                <FormLayoutRow label="Not Enabled" />
            ) : (
                <>
                    <FormLayoutRow label="Sender ID" value={config!.getSenderId()} copyEnabled={true} />

                    {config instanceof NativeV1FcmConfiguration && (
                        <FormLayoutRow
                            label="Service Account JSON File URL"
                            value={config.getServiceAccountJsonFileUrl()}
                            copyEnabled={true}
                        />
                    )}
                </>
            )}
        </div>
    )
}
