import * as React from 'react'
import './campaign-details.scss'
import { Skeleton } from 'antd'
import { AppState } from '../../../stores/app'
import { AppService, CampaignV2Service, DomainService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { TabbedView } from '../../../components/tabbed-view/tabbed-view'
import { CampaignSummaryTab } from './campaign-summary-tab'
import { CampaignDto } from '../dtos/campaign-dto'
import * as moment from 'moment-timezone'
import { BASE_TIME_FORMAT_WITHOUT_TZ, SHORT_DATE_FORMAT, TZ_PLATFORM_DEFAULT } from '../../../constants'
import { DomainDto } from '../../../dtos/domain'
import { Moment } from 'moment'
import { SegmentDto } from '../../../dtos/segment'
import { preventBubbling, titleCase } from '../../../_utils/utils'
import autobind from 'autobind-decorator'
import { observe } from 'mobx'
import { CampaignType, NodeType, TriggerType } from '../../../components/campaign-builder/enums'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { PageHeader } from '@pushly/aqe/lib/components'
import { CampaignWorkflowTab } from '../campaign-revisions/campaign-workflow-tab'
import { CampaignNotificationsComparisonsTab } from '../campaign-notifications/campaign-notifications-comparisons-tab'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { hasErrorResponseObject } from '../../../_utils/response-error-utils'
import { onResponseError403 } from '../../../_utils/on-response-error-403'
import { extractBooleanKeys } from '../../../_utils/object'
import { DeliveryChannel } from '@pushly/aqe/lib/interfaces/notification-channels'
import { determineChannelBadge } from '../../../components/badges/_utils'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'

interface IHeaderProps {
    domain: DomainDto
    campaign?: CampaignDto
    segments?: SegmentDto[]
}

class CampaignDetailsHeader extends React.PureComponent<IHeaderProps> {
    protected appState: AppState
    protected appService: AppService

    public constructor(props: IHeaderProps) {
        super(props)

        this.appService = Container.get(AppService)
        this.appState = Container.get(AppState)
    }

    public render(): React.ReactNode {
        const domain = this.appState.currentDomain!
        const activeDomainChannels = getEnabledDeliveryChannels(domain, true)
        return (
            <div className="details-header">
                <Skeleton loading={!this.campaign} active={true}>
                    {!!this.campaign && (
                        <div className="campaign-title">
                            <PageHeader
                                title={this.campaign.name}
                                append={
                                    <span>
                                        #{this.campaign.id} | Created by{' '}
                                        <NoTranslate>{this.campaign.createdByUserName}</NoTranslate> |{' '}
                                        {this.campaign.channels.map((ch) => (
                                            <span key={ch} className="campaign-title-channel-append">
                                                {determineChannelBadge(ch)}
                                            </span>
                                        ))}
                                    </span>
                                }
                                onTitleSet={this.appService.customizeTabTitle}
                            />
                            <div className="campaign-dates">{this.renderRunDates()}</div>

                            <div className="campaign-audience">{this.renderEntryTrigger()}</div>
                        </div>
                    )}
                </Skeleton>
            </div>
        )
    }

    protected get campaign(): CampaignDto {
        return this.props.campaign!
    }

    protected renderRunDates(): React.ReactNode {
        const campaign = this.campaign
        if (!campaign) {
            return ''
        }

        const isRecurring = campaign.type === CampaignType.RECURRING
        const isSTZ = campaign.dateTimeZone.toUpperCase() === 'STZ'
        const domainTz = this.appState.currentDomain?.timezone ?? TZ_PLATFORM_DEFAULT
        const tz = isSTZ ? domainTz : campaign.dateTimeZone

        const dateStart: Moment = moment.tz(campaign.dateStart, tz)
        const dateStartStr: String = dateStart.format(!isRecurring ? BASE_TIME_FORMAT_WITHOUT_TZ : SHORT_DATE_FORMAT)

        let dateEnd: Moment | null = null
        let dateEndStr: String | null = null
        if (campaign.dateEnd) {
            dateEnd = moment.tz(campaign.dateEnd, tz)
        } else if (campaign.dateCompletedUtc) {
            dateEnd = moment.tz(campaign.dateCompletedUtc, 'UTC').tz(domainTz)
        }
        if (dateEnd) {
            const useFullFmt = !isRecurring || campaign.manuallyCompleted
            dateEndStr = dateEnd.format(useFullFmt ? BASE_TIME_FORMAT_WITHOUT_TZ : SHORT_DATE_FORMAT)
        }

        return (
            <span>
                <b>Delivery</b> {dateStartStr} through {!dateEndStr ? 'Ongoing' : dateEndStr}
                {campaign.manuallyCompleted && <span className="lowlight"> (Manually Completed)</span>}
            </span>
        )
    }

    protected renderEntryTrigger(): React.ReactNode {
        const campaign = this.campaign
        if (!campaign) {
            return ''
        }

        const model = CampaignModel.build(campaign)
        let computedType = campaign.configuration.computed_trigger_type ?? campaign.configuration.computedTriggerType
        if (computedType === TriggerType.SUBSCRIBED) {
            computedType = 'User Subscription'
        } else if (computedType === TriggerType.ADD_TO_CART) {
            computedType = 'Add-to-Cart'
        } else {
            computedType = titleCase(computedType ?? model.getType())
        }

        let triggerActionStr: React.ReactNode = ''
        let triggerSegmentsView: React.ReactNode = ''

        if (Array.isArray(campaign.revision?.steps)) {
            const triggerStep = campaign.revision.steps.find((s) => s.type === NodeType.TRIGGER)
            if (!!triggerStep) {
                const trigger = triggerStep.configuration

                if (trigger.type === 'subscribed') {
                    triggerActionStr = (
                        <>
                            When a user <b>subscribes</b>
                        </>
                    )
                }

                if (!!trigger.params?.criteria) {
                    triggerSegmentsView = (
                        <span>
                            {' '}
                            and <b>matches criteria</b>
                        </span>
                    )
                }
            }
        }

        return (
            <>
                <div className="audience-section">
                    <div>
                        <b>Entry</b>
                    </div>
                    <div>
                        {computedType}
                        {triggerSegmentsView}
                    </div>
                </div>
            </>
        )
    }

    protected buildSegmentUrl(segment: any): string {
        return this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    @autobind
    protected jumpToSegment(segment: any, ev?: any): void {
        if (!ev.metaKey) {
            preventBubbling(ev)
            this.appService.route(this.buildSegmentUrl(segment))
        }
    }
}

interface ICampaignDetailsProps {}

interface ICampaignDetailsState {
    loading: boolean
    domain: DomainDto
    domainSegments?: SegmentDto[]
    campaign?: CampaignDto
}

export class CampaignDetails extends TabbedView<ICampaignDetailsProps, ICampaignDetailsState> {
    protected readonly appState: AppState
    protected readonly domainService: DomainService
    protected readonly campaignService: CampaignV2Service

    protected animated: boolean = false

    private disposeObservers: any[] = []

    public constructor(props: ICampaignDetailsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)
        this.campaignService = Container.get(CampaignV2Service)

        this.state = {
            loading: true,
            domain: this.appState.currentDomain!,
            tabs: [],
        }
    }

    public async componentDidMount() {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        await this.setDomainState()

        const remoteLoaders: Array<Promise<any>> = []
        remoteLoaders.push(this.loadRequestedCampaign())
        remoteLoaders.push(this.loadDomainSegments())
        await Promise.all(remoteLoaders)

        return this.loadTabsState()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        return this.state.loading ? null : (
            <div className="campaign-details">
                <CampaignDetailsHeader
                    domain={this.state.domain}
                    campaign={this.state.campaign}
                    segments={this.state.domainSegments}
                />

                {this.renderTabs()}
            </div>
        )
    }

    protected async setDomainState(): Promise<void> {
        if (!!this.globalDomain) {
            if (!this.localDomain) {
                await this.setState({ domain: this.globalDomain })
            } else if (this.globalDomain.id !== this.localDomain.id) {
                this.goBackToCampaignsList()
            }
        }
    }

    protected goBackToCampaignsList() {
        this.appService.routeWithinDomain('/campaigns')
    }

    protected getCampaignIdFromUrl(): number {
        return this.injectedProps.match.params.campaignId
    }

    protected async loadRequestedCampaign(): Promise<void> {
        const campaignId = this.getCampaignIdFromUrl()

        const res = await this.campaignService.fetchById(campaignId, {
            showLoadingScreen: false,
            cancellationKey: 'cd.loadRequestedCampaign',
            errorHandler: onResponseError403(() => {
                this.goBackToCampaignsList()
            }),
        })

        if (res.ok) {
            return this.setState(({ domainSegments }) => ({
                loading: !domainSegments,
                campaign: !res.data ? undefined : CampaignDto.parse(res.data),
            }))
        }
    }

    protected async loadDomainSegments(): Promise<void> {
        const domainId = this.localDomain!.id
        const { data: domainSegments } = await this.domainService.fetchSegmentsByDomainId(domainId, {
            query: {
                includeTreated: 1,
                includeDeleted: 1,
                pagination: 0,
                fields: 'id,name,source,computedStatus,isDefault,iconUrl',
            },
            cancellationKey: 'cd.loadDomainSegments',
        })

        return this.setState(({ campaign }) => ({
            loading: !campaign,
            domainSegments,
        }))
    }

    protected async loadTabsState(): Promise<void> {
        const tabs = [
            {
                component: CampaignSummaryTab,
                props: {
                    campaign: this.state.campaign,
                    domain: this.appState.currentDomain,
                },
            },
            {
                component: CampaignWorkflowTab,
                props: {
                    campaign: this.state.campaign,
                    activeTab: this.state.activeTab,
                },
            },
            {
                component: CampaignNotificationsComparisonsTab,
                props: {
                    campaign: this.state.campaign,
                    level: 'domain',
                    domainId: this.state.domain.id,
                },
            },
        ]

        const activeTab = this.determineActiveTab(tabs)

        return this.setState({
            tabs,
            activeTab,
        })
    }

    protected buildSegmentUrl(segment: SegmentDto): string {
        return this.appService.routeWithinDomain(`/segments/${segment.id}/summary`, true)
    }

    @autobind
    protected jumpToSegment(segment: SegmentDto, ev?: any): void {
        if (!ev.metaKey) {
            this.stopPropagation(ev)
            this.appService.route(this.buildSegmentUrl(segment))
        }
    }

    protected stopPropagation(ev: Event): void {
        ev.preventDefault()
        ev.stopPropagation()
    }
}
