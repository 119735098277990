import * as React from 'react'
import { SendIntegrationsContext } from './send-integrations-context'
import { Container } from 'typescript-ioc'
import { AppState } from '../../../stores/app'
import { FileUploader, Well } from '@pushly/aqe/lib/components'
import {
    NativeLegacyFcmConfiguration,
    NativeV1FcmConfiguration,
} from '../../../models/send-integration-configurations/native-fcm-configurations.model'
import { Button, Form, Input, Switch } from 'antd'
import { DomainSendIntegrationModel } from '../../../models/send-integration-configurations/domain-send-integration.model'
import { FcmSendIntegrationApiVersion } from '../../../interfaces/send-integrations'
import TextArea from 'antd/es/input/TextArea'
import { Radio } from 'antd/es'
import { IFilePickerValue } from '@pushly/aqe/lib/components/file-uploader/interfaces'
import { simpleNotification } from '../../../_utils/utils'
import FirebaseIcon from '../../icons/firebase-icon'
import { FEAT_CHANNEL_ANDROID } from '../../../constants'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getClassNames } from '../../../_utils/classnames'

interface IFcmSIWProps {
    integrations: DomainSendIntegrationModel
    className: string | undefined
    nativeFcmLegacy: boolean
}

interface IFcmSIWState {
    showKeyTextArea: boolean
}

export class NativeFcmIntegrationEditor extends React.Component<IFcmSIWProps, IFcmSIWState> {
    public static contextType = SendIntegrationsContext
    public context!: React.ContextType<typeof SendIntegrationsContext>

    protected appState: AppState

    public constructor(props: IFcmSIWProps) {
        super(props)

        this.state = {
            showKeyTextArea: !this.props.integrations.getNativeFcmConfiguration().clone().getIsActive(),
        }

        this.appState = Container.get(AppState)
    }

    public render() {
        const { domain } = this.context
        const { integrations } = this.props

        const nativeAndroidFlag = this.appState?.flags.findActive(FEAT_CHANNEL_ANDROID)?.getKey()
        const hasNativeAndroid = domain?.flags.includes(nativeAndroidFlag!)

        const nativeFcmConfig = integrations.getNativeFcmConfiguration().clone()
        const nativeEnabled = nativeFcmConfig.getIsActive()
        return (
            hasNativeAndroid && (
                <Well
                    className={this.prefixClassName('form')}
                    title={
                        <span>
                            <FirebaseIcon />
                            <span>Android</span>
                        </span>
                    }
                    hideFooter={true}
                >
                    <div className={this.prefixClassName('config-form')}>
                        <div className={this.prefixClassName('config-switch')}>
                            <Switch
                                size="small"
                                onChange={this.handleConfigEnabledChange}
                                defaultChecked={nativeEnabled}
                            />
                            <span>NATIVE</span>
                        </div>
                        {nativeEnabled && this.renderNativeEditor()}
                    </div>
                </Well>
            )
        )
    }

    protected handleConfigEnabledChange = (state: boolean) => {
        const integrations = this.context.sendIntegrations.clone()
        let config = integrations.getNativeFcmConfiguration().clone()

        config.setIsActive(state)
        integrations.setNativeFcmConfiguration(config)

        this.context.setSendIntegrations(integrations)
    }

    protected handleConfigInputChange = (
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldProp: 'server_key' | 'sender_id',
    ) => {
        const integrations = this.context.sendIntegrations.clone()
        let config = integrations.getNativeFcmConfiguration().clone()
        const change = ev.target.value

        if (change.length > 3) {
            switch (fieldProp) {
                case 'sender_id':
                    config.setSenderId(change)
                    break
                case 'server_key':
                    config = config as unknown as NativeLegacyFcmConfiguration
                    config.setServerApiKey(change)
            }
        }

        integrations.setNativeFcmConfiguration(config)
        this.context.setSendIntegrations(integrations)
    }

    protected handleFileUploadError = (res: any) => {
        const error = res?.error

        if (error && error.error_type === 'invalid_file_type_error') {
            return simpleNotification('error', error.message)
        }
    }

    protected handlePrivateKeyChange = async (upload: IFilePickerValue | undefined) => {
        const integrations = this.context.sendIntegrations.clone()
        const updateValue = upload?.url ?? undefined
        const config = integrations.getNativeFcmConfiguration().clone() as NativeV1FcmConfiguration

        config.setServiceAccountJsonFileUrl(updateValue)
        integrations.setNativeFcmConfiguration(config)

        this.context.setSendIntegrations(integrations)
    }

    protected renderNativeEditor() {
        const config = this.context.sendIntegrations.getNativeFcmConfiguration()
        const fileUploaderValue =
            config instanceof NativeV1FcmConfiguration
                ? config.getServiceAccountJsonFileUrl() !== undefined
                    ? { url: config.getServiceAccountJsonFileUrl()! }
                    : undefined
                : undefined

        return (
            <>
                <Form.Item label="Sender ID">
                    <Input
                        size="small"
                        defaultValue={config.getSenderId()}
                        onChange={(ev) => this.handleConfigInputChange(ev, 'sender_id')}
                    />
                </Form.Item>

                {this.props.nativeFcmLegacy && (
                        <Radio.Group
                            size="small"
                            buttonStyle="solid"
                            value={config.getApiVersion()}
                            onChange={(ev) => {
                                const integrations = this.context.sendIntegrations.clone()
                                const configUpdate = integrations.getNativeFcmConfiguration().clone()
                                const change = ev.target.value
                                if (change !== config.getApiVersion()) {
                                    configUpdate.setApiVersion(change)
                                    const update = configUpdate.serialize()

                                    integrations.setNativeFcmConfiguration(update)
                                }

                                this.context.setSendIntegrations(integrations)
                            }}
                        >
                            <Radio.Button value={FcmSendIntegrationApiVersion.LEGACY}>Legacy</Radio.Button>
                            <Radio.Button value={FcmSendIntegrationApiVersion.V1}>Version 1</Radio.Button>
                        </Radio.Group>
                )}

                {config instanceof NativeV1FcmConfiguration && (
                    <Form.Item
                        label="Service Account JSON File"
                        className={this.prefixClassName('native-certificate-upload')}
                    >
                        <FileUploader
                            className="credential-upload-service-account-json"
                            type="file"
                            private={true}
                            allowedMimeTypes={[...FileUploader.defaultFileTypes.files]}
                            ownerType="domain"
                            ownerId={this.context.domain.id}
                            buttonText="Upload your Service Account JSON File"
                            buttonIcon={null}
                            onSuccess={(upload) => this.handlePrivateKeyChange(upload)}
                            onRemove={(upload) => this.handlePrivateKeyChange(upload)}
                            onError={this.handleFileUploadError}
                            value={fileUploaderValue}
                        />

                        <div className={getClassNames('form-item-sub')}>
                            <InfoCircleOutlined />
                            <a
                                href="https://documentation.pushly.com/integration/implementation-steps/android/firebase-app-setup"
                                target="_blank"
                            >
                                &nbsp;Retrieving your Firebase Service Account JSON File
                            </a>
                        </div>
                    </Form.Item>
                )}

                {config instanceof NativeLegacyFcmConfiguration && this.props.nativeFcmLegacy && (
                    <Form.Item label="Server Key">
                        {!this.state.showKeyTextArea && (
                            <Button size="small" onClick={() => this.setState({ showKeyTextArea: true })}>
                                <span>Change Key</span>
                            </Button>
                        )}
                        {this.state.showKeyTextArea && (
                            <TextArea size="small" onChange={(ev) => this.handleConfigInputChange(ev, 'server_key')} />
                        )}
                    </Form.Item>
                )}
            </>
        )
    }

    protected prefixClassName = (classname: string) => {
        return `${this.props.className}-${classname}`
    }
}
