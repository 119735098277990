import * as React from 'react'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../better-component/better-component'
import { type IRule } from './interfaces/rule'
import * as randomstring from 'randomstring'
import { IRuleGroup } from './interfaces/rule-group'
import { RuleRow } from './rule-row'
import './styles/rule-group.scss'
import { IRuleBuilderField } from '../rule-builder/rule-builder'
import { RuleNodeType } from './enums/rule-node-type'
import { getFieldOperators } from './utils'
import { RuleUiCode } from './enums/rule-ui-code'
import { MinusCircleFilled, PlusCircleFilled, StarFilled } from '@ant-design/icons'
import { Tag } from 'antd'

interface IRuleGroupProps {
    group: IRuleGroup
    builderFields: IRuleBuilderField[]
    onChange: (group: IRuleGroup) => any
    onRemoveGroup: (group: IRuleGroup, source: 'group' | 'row') => any
    onAddGroup: () => any
    className?: string
    showActivator?: boolean
    autofocus?: boolean
    disabled?: boolean
}

interface IRuleGroupState {}

export class RuleGroup extends React.Component<IRuleGroupProps, IRuleGroupState> {
    public defaultClassName = 'sw-v2-rb-rule-group'
    public ref: any

    public constructor(props: IRuleGroupProps) {
        super(props)
    }

    public render(): React.ReactNode {
        const groupConnectorClasses: string[] = []
        const groupConnectorActions: any = {}

        if (this.showActivator) {
            groupConnectorClasses.push('activator')
            groupConnectorActions.onClick = this.handleAddGroup
        }

        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('content')}>
                        <div className={this.buildClassName('rules-wrapper')}>
                            <div className={this.buildClassName('rules-starter')}>
                                <div className={this.buildClassName('rules-starter-wrapper')}>
                                    <div className={this.buildClassName('rules-starter-content')}>
                                        <span>
                                            <Tag>
                                                <StarFilled />
                                                Any of the following are true
                                            </Tag>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={this.buildClassName('rules-list')}>{this.renderRules()}</div>
                        </div>
                    </div>

                    {!this.props.disabled && (
                        <>
                            <div className={this.buildClassName('row-connector')}>
                                <div className={this.buildClassName('row-connector-wrapper')}>
                                    <span>
                                        <Tag onClick={() => this.handleRowAdd()}>
                                            <PlusCircleFilled />
                                            {/*<span>Or</span>*/}
                                        </Tag>
                                    </span>
                                </div>
                            </div>

                            <div className={this.buildClassName('group-connector', groupConnectorClasses)}>
                                <div className={this.buildClassName('group-connector-wrapper')}>
                                    <span className={this.buildClassName('group-connector-handle')}>
                                        <span className="default">
                                            <Tag {...groupConnectorActions}>
                                                <PlusCircleFilled />
                                                <span>And</span>
                                            </Tag>
                                        </span>
                                        <span className="hover">
                                            <Tag onClick={() => this.handleRemoveGroup('group')}>
                                                <MinusCircleFilled />
                                            </Tag>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    protected renderRules(): React.ReactNode {
        const totalRules = this.props.group.rules.length
        const finalRowIdx = totalRules - 1

        return this.props.group.rules.map((rule, idx) => {
            const rowProps: any = {}

            // When a rule is passed with $ROW_STATE_NEW
            //  we want to ensure that the mode is forced
            //  into edit mode tiggering auto-focus
            if (!!rule.rule.meta && RuleUiCode.$ROW_STATE_NEW in rule.rule.meta) {
                rowProps.mode = 'edit'
            }

            return (
                <RuleRow
                    key={`rule-${rule.id}`}
                    className={idx === 0 ? 'row__first' : idx === finalRowIdx ? 'row__last' : undefined}
                    rule={rule}
                    onChange={this.handleRowChange}
                    onAddRow={this.handleRowAdd}
                    onRemoveRow={this.handleRowRemove}
                    builderFields={this.props.builderFields}
                    showAppendAction={idx === finalRowIdx}
                    autofocus={this.props.autofocus}
                    disabled={this.props.disabled}
                    {...rowProps}
                />
            )
        })
    }

    protected get showActivator(): boolean {
        return this.props.showActivator === true
    }

    @autobind
    protected handleRemoveGroup(source: 'group' | 'row' = 'group'): void {
        this.props.onRemoveGroup(this.props.group, source)
    }

    @autobind
    protected handleAddGroup(): void {
        this.props.onAddGroup()
    }

    @autobind
    protected handleRowChange(rule: IRule): void {
        const rules = [...this.props.group.rules]

        const affectedRuleIdx = rules.findIndex((r) => r.id === rule.id)
        if (affectedRuleIdx === undefined) {
            rules.push(rule)
        } else {
            rules.splice(affectedRuleIdx, 1, rule)
        }

        const groupUpdate = {
            ...this.props.group,
            rules,
        }

        this.emitChange(groupUpdate)
    }

    @autobind
    protected handleRowAdd(rule?: IRule): void {
        if (!rule) {
            const defaultField = this.props.builderFields[0]
            const defaultOperator = getFieldOperators(defaultField)[0]

            rule = {
                conditionType: RuleNodeType.OR,
                rule: {
                    builderProperty: defaultField.property,
                    property: defaultField.property,
                    operator: defaultOperator.value,
                    value: undefined,
                },
            } as IRule
        }

        rule.id = randomstring.generate()

        rule.rule.meta = {
            ...rule.rule.meta,
            [RuleUiCode.$ROW_STATE_NEW]: true,
        }

        const groupUpdate = {
            ...this.props.group,
            rules: [...this.props.group.rules, rule],
        }

        this.emitChange(groupUpdate)
    }

    @autobind
    protected handleRowRemove(rule: IRule): void {
        const rules = [...this.props.group.rules]
        const affectedRuleIdx = rules.findIndex((r) => r.id === rule.id)

        if (affectedRuleIdx !== undefined) {
            rules.splice(affectedRuleIdx, 1)

            const groupUpdate = {
                ...this.props.group,
                rules,
            }

            if (rules.length === 0) {
                this.handleRemoveGroup('row')
            } else {
                this.emitChange(groupUpdate)
            }
        }
    }

    protected emitChange(group: IRuleGroup): void {
        this.props.onChange(group)
    }

    protected buildClassName(className: string, additions?: string[]): string {
        return `${this.defaultClassName}-${className}${additions ? ` ${additions.join('')}` : ''}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.disabled) classNames.push('disabled')
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
