export const parseFloatTo = (n: number | string, toFixed: number) => {
    const rgx = new RegExp(`^([\\d].[\\d]{0,${toFixed}}).*`)
    return parseFloat(n.toString().replace(rgx, '$1'))
}

// isInt and isFloat will reject numeric string values
export const isFloat = (n) => Number(n) === n && n % 1 !== 0
export const isInt = (n) => Number(n) === n && n % 1 === 0
// isIntValue allows comparison of numbers and numeric strings
// tslint:disable-next-line:no-bitwise
export const isIntValue = (n) => Number(n) === (Number(n) | 0)
