import React from 'react'
import { useParams } from 'react-router-dom'
import SegmentBuilder from '../../components/segment-builder/segment-builder'
import { tryParseInt } from '../../_utils/try-parse'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { PageHeader } from '@pushly/aqe/lib/components'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppService } from '../../services'
import { getQueryStringParam } from '../../_utils/get-query-string'

const Segment = () => {
    const appSvc = useService(AppService)
    const { segmentId } = useParams<{ segmentId: string; template: string }>()
    const pathSegmentId = tryParseInt(segmentId)

    const templateId = getQueryStringParam('template')

    const pathOrgId = getPathEntityId('organization')
    const pathDomainId = getPathEntityId('domain')

    const builderProps: any = {}
    if (pathOrgId) {
        builderProps.level = 'org'
        builderProps.orgId = pathOrgId
    } else {
        builderProps.level = 'domain'
        builderProps.domainId = pathDomainId
    }

    const titleMode = pathSegmentId ? 'Edit' : 'Create'
    const titleEntity = pathOrgId ? 'Multi-Domain Segment' : 'Segment'

    return (
        <div>
            <PageHeader title={`${titleMode} ${titleEntity}`} onTitleSet={appSvc.customizeTabTitle} />

            <SegmentBuilder segmentId={pathSegmentId} templateId={templateId} {...builderProps} />
        </div>
    )
}

export default Segment
