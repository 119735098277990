import * as React from 'react'
import { observer } from 'mobx-react'
import { BetterComponent } from '../better-component/better-component'
import { Well } from '../well/well'
import '../../styles/globals.scss'
import '../../features/pre-auth/login/login.scss'
import '../../styles/503.scss'

interface IProps {}

interface IState {}

@observer
export class Maintenance503 extends BetterComponent<IProps, IState> {
    public constructor(props: IProps) {
        super(props)
    }

    public render() {
        return (
            <div id="pufferfish" className="mode-503 maintenance-mode">
                <div className="login-component">
                    <div className="mode-503-wrapper">
                        <Well ghost={true} showHeader={false} showFooter={false}>
                            <div className="mode-503-content">
                                <div className="header">
                                    We are currently down due to an issue with Amazon Web Services.
                                </div>
                                <div className="sub-header">We'll be back up as soon as possible.</div>
                            </div>
                        </Well>
                    </div>
                </div>
            </div>
        )
    }
}
