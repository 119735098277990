import React from 'react'
import aqe from '@pushly/aqe'
import { IApiCallOptions, IApiResponse } from '@pushly/aqe/lib/interfaces'
import { AbstractEntityService } from '@pushly/aqe/lib/services'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { simpleNotification } from '../_utils/utils'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'

@Singleton
export class OrgSegmentService extends AbstractEntityService {
    private readonly appService: AppService

    public constructor() {
        super()
        this.appService = Container.get(AppService)
    }

    public buildEndpoint(orgId: number, groupId?: number, v?: number): string {
        const baseEndpoint = `${aqe.defaults.publicApiDomain}${v ? `/v${v}` : ''}/accounts/${orgId}/segments`
        return !groupId ? baseEndpoint : `${baseEndpoint}/${groupId}`
    }

    public async fetchAll(orgId: number, options: IApiCallOptions = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execFetchMany(this.buildEndpoint(orgId, undefined, 3), options)
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            error: `Segments for Organization ID, ${orgId}, could not be loaded at this time.`,
        })

        return res
    }

    public async fetch(orgId: number, groupId: number, options: ApiCallOptionsWithCustomErrorHandler = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execFetchOne(this.buildEndpoint(orgId, groupId), options)
        this.handleLoadingAnimation('hide', options)

        const defaultHandler = () =>
            this.handleResponseMessages(res, {
                error: `Segment ID, ${groupId}, could not be loaded at this time.`,
            })
        if (options.errorHandler) {
            options.errorHandler(res.error!, defaultHandler)
        } else {
            defaultHandler()
        }

        return res
    }

    public async create(orgId: number, createDto: any, options: IApiCallOptions = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execCreate(this.buildEndpoint(orgId), createDto, options)
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            ok: `Segment, ${createDto.name}, has been successfully created.`,
            error: `Segment, ${createDto.name}, could not be saved at this time.`,
        })

        return res
    }

    public async update(
        orgId: number,
        group: { id: number; name: string },
        updateDto: any,
        options: IApiCallOptions = {},
    ) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execPatch(this.buildEndpoint(orgId, group.id), updateDto, options)
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            ok: `Segment, ${group.name}, has been successfully updated.`,
            error: `Segment, ${group.name}, could not be updated at this time.`,
        })

        return res
    }

    public async archive(orgId: number, group: { id: number; name: string }, options: IApiCallOptions = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execDelete(this.buildEndpoint(orgId, group.id), options)
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            ok: `Segment, ${group.name}, has been successfully archived.`,
            error: `Segment, ${group.name}, could not be archived at this time.`,
        })

        return res
    }

    public async fetchCalculatedFields(orgId: number, options: IApiCallOptions = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execGet(
            `${aqe.defaults.publicApiDomain}/v3/accounts/${orgId}/calculated-fields`,
            options,
        )
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            error: `Calculated fields for Organization, ${orgId}, could not be loaded at this time.`,
        })

        return res
    }

    public async fetchSegmentFields(orgId: number, options: IApiCallOptions = {}) {
        this.handleLoadingAnimation('show', options)
        const res = await this.execGet(`${aqe.defaults.publicApiDomain}/v3/accounts/${orgId}/segment-fields`, options)
        this.handleLoadingAnimation('hide', options)

        this.handleResponseMessages(res, {
            error: `Segment fields for Organization, ${orgId}, could not be loaded at this time.`,
        })

        return res
    }

    protected handleLoadingAnimation(state: 'show' | 'hide', options: IApiCallOptions = {}) {
        if (options.showLoadingScreen) {
            if (state === 'show') {
                this.appService.setModuleLoading()
            } else {
                this.appService.unsetModuleLoading()
            }
        }
    }

    protected handleResponseMessages(
        res: IApiResponse,
        messages: {
            ok?: string
            error?: string
        },
    ) {
        if (res.ok && messages.ok) {
            simpleNotification('success', messages.ok)
        } else if (!res.ok && !res.cancelled && res.error) {
            handleResponseErrorMessage(res.error, {
                fallbackMessage: messages.error,
            })
        }
    }
}
