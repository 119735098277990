import { isLocalEnv } from '../config/axios-setup'
import { ValidationTuple } from '../types/route-validation'
import { ErrorPage } from '../components/error-page/error-page'

export function asLinkAltClick(ev: any, onClick: Function) {
    if (!ev.metaKey) {
        if (ev) {
            ev.preventDefault()
        }
        onClick()
    }
}

export function buildRouteFromHostname(hostName: string, external: boolean = false) {
    let port = ''
    let addLocalSub = ''
    if (!external) {
        if (isLocalEnv()) {
            port = ':3000'

            if (!/^(local.)+/g.test(hostName)) {
                addLocalSub = 'local.'
            }
        }
    }

    return `https://${addLocalSub}${hostName}${port}`
}

export function buildAuthorizeRoute(hostName: string, external: boolean = false) {
    return `${buildRouteFromHostname(hostName, external)}/authorize`
}

export function buildLogoutRoute(hostName: string, external: boolean = true) {
    return `${buildRouteFromHostname(hostName, external)}/logout`
}

export function buildComponentCheck(component: any, validations: ValidationTuple[]) {
    let allowed = false

    for (const [check, altComponent] of validations) {
        allowed = check()

        if (!allowed) {
            return altComponent ?? (() => ErrorPage({ errorCode: 403 }))
        }
    }

    return component
}
