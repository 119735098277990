export const SegmentApiColumns = {
    'SEGMENT.ID': 'segment.id',
    'SEGMENT.NAME': 'segment.name',
    SEGMENT_LAST_TARGETED_DATE_TZ: 'segment_last_targeted_date_tz',

    DATE: 'date',

    DELIVERIES: 'deliveries',
    DELIVERY_RATE: 'delivery_rate_decimal',
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr_decimal',
    CPS: 'clicks_per_send',
    NOTIFICATIONS_SENT: 'notifications_sent',
    SIZE: 'size',
}
