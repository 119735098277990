import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NdfTestScheduleType } from '../../components/notification-data-form/enums/ndf-test-schedule-type.enum'

export class NotificationTestEvaluationScheduleModel {
    public static build(props: NotificationTestEvaluationScheduleModel | any): NotificationTestEvaluationScheduleModel {
        const data = props instanceof NotificationTestEvaluationScheduleModel ? props.serialize() : clone(props)

        const model = new NotificationTestEvaluationScheduleModel()
        model.setType(data.type)
        model.setElapsedSeconds(data.elapsed_seconds)
        model.setElapsedMetric(data.$elapsed_metric ?? data.elapsed_metric)
        model.setEvaluationDateUtc(data.evaluation_date_utc)
        model.setEvaluationDateTimeZone(data.$evaluation_date_timezone ?? data.evaluation_date_timezone)

        return model
    }

    private type!: NdfTestScheduleType
    private elapsedSeconds?: number
    private _elapsedMetric?: string
    private evaluationDateUtc?: string
    private _evaluationDateTimezone?: string

    public clone(): NotificationTestEvaluationScheduleModel {
        return NotificationTestEvaluationScheduleModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            type: this.getType(),
            elapsed_seconds: this.getElapsedSeconds(),
            $elapsed_metric: this.getElapsedMetric(),
            evaluation_date_utc: this.getEvaluationDateUtc(),
            $evaluation_date_timezone: this.getEvaluationDateTimeZone(),
        })
    }

    public getType(): NdfTestScheduleType {
        return this.type
    }
    public setType(type: NdfTestScheduleType): void {
        this.type = type
    }

    public getElapsedSeconds(): number | undefined {
        return this.elapsedSeconds
    }
    public setElapsedSeconds(seconds: number | undefined): void {
        this.elapsedSeconds = !seconds ? undefined : seconds
    }

    public getElapsedMetric(): string | undefined {
        return this._elapsedMetric
    }
    public setElapsedMetric(metric: string | undefined): void {
        this._elapsedMetric = !metric ? undefined : metric
    }

    public getEvaluationDateUtc(): string | undefined {
        return this.evaluationDateUtc
    }
    public setEvaluationDateUtc(date: string | undefined): void {
        this.evaluationDateUtc = !date ? undefined : date
    }

    public getEvaluationDateTimeZone(): string | undefined {
        return this._evaluationDateTimezone
    }
    public setEvaluationDateTimeZone(tz: string | undefined): void {
        this._evaluationDateTimezone = tz === undefined ? undefined : tz
    }
}
