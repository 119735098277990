import * as React from 'react'
import { Drawer } from '@pushly/aqe/lib/components'
import { IChart, INode } from '@mrblenny/react-flow-chart'
import { Container } from 'typescript-ioc/es5'
import * as deepEqual from 'fast-deep-equal'
import clone from 'clone-deep'
import titleCase from 'title-case'
import { ConditionSetEditor } from './editors/condition-set.editor'
import { AppService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { CampaignEditableState, NodeType } from './enums'
import { DelayEditor } from './editors/delay.editor'
import { ActionEditor } from './editors/action.editor'
import { ExitEditor } from './editors/exit.editor'
import { CampaignNode } from './interfaces'

interface INodeEditor {
    visible?: boolean
    domain?: any
    editableState: CampaignEditableState
    chart: IChart
    node?: CampaignNode
    branchId?: string
    onClose: () => void
    onSubmit: (node: INode, chart?: IChart) => void
    esSegmentFields?: any[]
    readonly?: boolean
}

export class NodeEditor extends React.Component<INodeEditor, any> {
    protected appService: AppService

    private editorRef: any

    public constructor(props: INodeEditor) {
        super(props)

        this.appService = Container.get(AppService)

        this.state = {
            value: this.props.node,
            chart: this.props.chart,
        }
    }

    public render() {
        let submitDisabled =
            deepEqual(this.props.node, this.currentValue) && deepEqual(this.props.chart, this.state.chart)

        // Action Forms are abstracted and only update state on submit
        if (this.props.node?.type === NodeType.ACTION || this.props.readonly) {
            submitDisabled = false
        }

        return (
            <Drawer
                getContainer={this.appService.getAppContainer}
                className={getClassNames(
                    'campaign-node-editor',
                    this.node?.type,
                    this.node?.properties?.[this.node?.type]?.type,
                    {
                        [`campaign-completed`]: this.props.editableState === CampaignEditableState.COMPLETED,
                        [`campaign-running`]: this.props.editableState === CampaignEditableState.RUNNING,
                        [`campaign-editable`]: this.props.editableState === CampaignEditableState.EDITABLE,
                        [`campaign-readonly`]: this.props.editableState === CampaignEditableState.READONLY,
                    },
                )}
                title={this.buildTitle()}
                placement="right"
                closable={true}
                maskClosable={false}
                submitText="Continue"
                onSubmit={this.handleSubmit}
                onClose={this.handleClose}
                visible={this.props.visible}
                disableSubmit={submitDisabled}
            >
                {this.buildEditor()}
            </Drawer>
        )
    }

    public get node(): CampaignNode {
        return this.props.node!
    }

    public get currentValue(): CampaignNode {
        return this.state.value ?? this.node
    }

    protected buildTitle() {
        let title = 'Node'
        if (this.node) {
            if (this.node.type === NodeType.TRIGGER) {
                title = 'Campaign Entry Conditions'
            } else if (this.node.type !== NodeType.CONDITION) {
                title = titleCase(this.node.type)
            } else {
                title = 'Condition Set'
            }
        }
        return `${this.props.readonly ? 'View' : 'Edit'} ${title}`
    }

    protected buildEditor() {
        let builder: any

        const builderProps = {
            domain: this.props.domain,
            chart: clone(this.state.chart ?? this.props.chart),
            editableState: this.props.editableState,
            readonly: this.props.readonly,
            node: this.currentValue,
            onChange: this.handleEditorChange,
            ref: (el: any) => (this.editorRef = el),
        }

        if (this.node) {
            // Triggers are not currently editable
            //
            // if (this.node.type === NodeType.TRIGGER) {
            //     builder = <TriggerEditor {...builderProps} esSegmentFields={this.props.esSegmentFields} />;
            // } else

            if (this.node.type === NodeType.DELAY) {
                builder = <DelayEditor {...builderProps} />
            } else if (this.node.type === NodeType.CONDITION) {
                builder = <ConditionSetEditor {...builderProps} initialBranchId={this.props.branchId} />
            } else if (this.node.type === NodeType.ACTION) {
                // node explicitly re-attached due to type guard issue with builderProps.node generic
                builder = <ActionEditor {...builderProps} node={this.node} />
            } else if (this.node.type === NodeType.CAMPAIGN_EXIT) {
                builder = <ExitEditor {...builderProps} />
            }

            if (this.props.readonly && this.node.type === NodeType.ACTION) {
                builder = this.buildReadonlyEditor(builder, builderProps)
            }
        }

        return builder
    }

    protected buildReadonlyEditor(builder: React.ReactNode, builderProps: any) {
        return <div>{builder}</div>
    }

    protected handleSubmit = async () => {
        if (this.editorRef?.handleSubmit) {
            const res = await this.editorRef.handleSubmit()
            if (!res) {
                return
            }
        }

        const node = clone(this.currentValue)
        this.props.onSubmit(node, this.state.chart)

        this.handleClose()
    }

    protected handleEditorChange = (node: INode, _chart?: IChart) => {
        this.setState(({ chart }: any) => ({
            value: node,
            chart: !_chart ? chart : _chart,
        }))
    }

    protected handleClose = () => {
        this.setState({ value: undefined })
        this.props.onClose()
    }
}
