import {
    FcmSendIntegrationApiVersion,
    NativeLegacyFcmSendIntegration,
    NativeV1FcmSendIntegration,
} from '../../interfaces/send-integrations'
import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { stripUndefined } from '../../_utils/strip-undefined'

export class AbstractNativeFcmConfiguration {
    private is_active: boolean = false
    private api_version: FcmSendIntegrationApiVersion | undefined
    private sender_id: string | undefined

    public getIsActive(): boolean {
        return this.is_active
    }
    public setIsActive(value: boolean) {
        this.is_active = value
    }

    public getApiVersion(): FcmSendIntegrationApiVersion | undefined {
        return this.api_version
    }
    public setApiVersion(value: FcmSendIntegrationApiVersion) {
        this.api_version = value
    }

    public getSenderId(): string | undefined {
        return this.sender_id
    }
    public setSenderId(value: string | undefined) {
        this.sender_id = value?.trim()
    }
}

export class NativeLegacyFcmConfiguration extends AbstractNativeFcmConfiguration {
    public static build(props: any | NativeLegacyFcmSendIntegration): NativeLegacyFcmConfiguration {
        const data = props instanceof NativeLegacyFcmConfiguration ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NativeLegacyFcmConfiguration()
        model.setIsActive(scData.is_active ?? model.getIsActive())
        model.setApiVersion(scData.api_version ?? FcmSendIntegrationApiVersion.LEGACY)
        model.setSenderId(scData.sender_id)
        model.setServerApiKey(scData.server_api_key)

        return model
    }

    private server_api_key: string | undefined

    public clone(): NativeLegacyFcmConfiguration {
        return NativeLegacyFcmConfiguration.build(this)
    }

    public serialize(_: boolean = true): any {
        if (!this.getIsActive()) {
            return { is_active: false }
        }

        return stripUndefined({
            is_active: this.getIsActive(),
            api_version: this.getApiVersion(),
            sender_id: this.getSenderId(),
            server_api_key: this.getServerApiKey(),
        })
    }

    public getServerApiKey(): string | undefined {
        return this.server_api_key
    }
    public setServerApiKey(value: string | undefined) {
        this.server_api_key = value?.trim()
    }
}

export class NativeV1FcmConfiguration extends AbstractNativeFcmConfiguration {
    public static build(props: any | NativeV1FcmSendIntegration): NativeV1FcmConfiguration {
        const data = props instanceof NativeV1FcmConfiguration ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NativeV1FcmConfiguration()
        model.setIsActive(scData.is_active ?? model.getIsActive())
        model.setApiVersion(scData.api_version ?? FcmSendIntegrationApiVersion.V1)
        model.setSenderId(scData.sender_id)
        model.setServiceAccountJsonFileUrl(scData.service_account_json_file_url)

        return model
    }

    private service_account_json_file_url: string | undefined

    public clone(): NativeV1FcmConfiguration {
        return NativeV1FcmConfiguration.build(this)
    }

    public serialize(_: boolean = true): any {
        if (!this.getIsActive()) {
            return { is_active: false }
        }

        return stripUndefined({
            is_active: this.getIsActive(),
            api_version: this.getApiVersion(),
            sender_id: this.getSenderId(),
            service_account_json_file_url: this.getServiceAccountJsonFileUrl(),
        })
    }

    public getServiceAccountJsonFileUrl(): string | undefined {
        return this.service_account_json_file_url
    }
    public setServiceAccountJsonFileUrl(value: string | undefined) {
        this.service_account_json_file_url = value?.trim()
    }
}
