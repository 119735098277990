import * as React from 'react'
import { Tabs } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'

export interface IExtendedComponentTab {
    tabName: string | null
    tabLabel: string
    props: Object
}

export interface IComponentTab<TComponent extends IExtendedComponentTab> {
    disabled?: boolean
    tabClassName?: string
    component: TComponent
    props: TComponent['props']
}

type GenericComponentTab = IComponentTab<any>

export interface ITabbedViewState {
    activeTab?: ITab | GenericComponentTab
    tabs: Array<ITab | GenericComponentTab>
}

export interface ITab {
    disabled?: boolean
    tabClassName?: string
    name: string
    label: string
    render: () => React.ReactNode
}

export type TabbedViewPathStyle = 'hash' | 'slash'

const DEFAULT_TAB_NAME = '__default__'

export class TabbedView<TProps, TState> extends BetterComponent<TProps, TState & ITabbedViewState> {
    protected animated = true
    protected pathStyle: TabbedViewPathStyle = 'slash'

    protected readonly appService: AppService
    protected tabRefs: any = {}

    public constructor(props) {
        super(props)

        this.appService = Container.get(AppService)
    }

    protected renderTabs(): React.ReactNode {
        const tabs = this.getTabs()
        let tabsFragment: React.ReactNode = <></>

        if (Array.isArray(tabs) && !!this.state.activeTab) {
            const activeTab = this.getCurrentTab()
            tabsFragment = (
                <Tabs
                    activeKey={this.getTabName(activeTab)}
                    onChange={this.handleTabChange}
                    animated={this.animated ?? true}
                >
                    {this.renderTabPanes()}
                </Tabs>
            )
        }

        return tabsFragment
    }

    protected getTabs() {
        return (this.props as any).tabs ?? this.state.tabs ?? []
    }

    protected getCurrentTab() {
        return this.state.activeTab as ITab | GenericComponentTab
    }

    protected determineActiveTab(tabs: Array<ITab | GenericComponentTab>): ITab | GenericComponentTab {
        const lastUrlPart = location.pathname.split('/').pop()
        const matchedTabs = tabs.filter((tab) => {
            const name = this.isComponentTab(tab) ? tab.component.tabName : tab.name
            return name === lastUrlPart
        })

        let activeTab: ITab | GenericComponentTab
        if (matchedTabs.length) {
            activeTab = matchedTabs[0]
        } else {
            activeTab = tabs[0]
        }

        return activeTab
    }

    protected isComponentTab(tab: ITab | GenericComponentTab): tab is GenericComponentTab {
        return !!(tab as GenericComponentTab).component
    }

    protected getTabName(tab: ITab | GenericComponentTab): string {
        return this.isComponentTab(tab) ? tab.component.tabName ?? DEFAULT_TAB_NAME : tab.name
    }

    protected renderTabPanes(): React.ReactNode[] {
        return this.getTabs().map((tab) => {
            const name = this.getTabName(tab)
            const label = this.isComponentTab(tab) ? tab.component.tabLabel : tab.label
            const currentTab = this.getCurrentTab()

            const view = this.isComponentTab(tab) ? (
                <tab.component
                    {...(typeof tab.props === 'function' ? tab.props() : tab.props)}
                    ref={(el) => (this.tabRefs[name] = el)}
                    isActiveTab={!currentTab ? undefined : name === this.getTabName(currentTab)}
                />
            ) : (
                tab.render.bind(this)()
            )

            return (
                <Tabs.TabPane key={name} tab={label} disabled={tab.disabled} className={tab.tabClassName}>
                    {view}
                </Tabs.TabPane>
            )
        })
    }

    protected handleTabChange = async (key: string): Promise<void> => {
        const tabs = this.getTabs()
        const lastPathName = location.pathname

        const nextActiveTab = tabs.filter((tab) => {
            const tabName = this.getTabName(tab) ?? 'null'
            return tabName === key
        })[0]
        const nextActiveTabName = this.getTabName(nextActiveTab)

        if (this.pathStyle === 'slash') {
            const pathParts = location.pathname.split('/')
            const tabNames = tabs.map((tab) => this.getTabName(tab)?.toString())
            if (tabNames.includes(pathParts[pathParts.length - 1])) {
                pathParts.pop()
            }
            if (nextActiveTabName !== DEFAULT_TAB_NAME) {
                pathParts.push(key)
            }

            const path = `${pathParts.join('/')}${location.search}`
            if (location.pathname !== path) {
                this.appService.route(path)
            }
        } else {
            const path = `${location.pathname}#${key}${location.search}`
            if (location.pathname !== path) {
                const tabUrl = `${location.origin}${path}`
                history.replaceState({}, document.title, tabUrl)
            }
        }

        if (location.pathname !== lastPathName) {
            await this.setState({
                activeTab: nextActiveTab,
            })

            tabs.forEach((tab) => {
                if (this.isComponentTab(tab) && this.tabRefs[tab.component.tabName]) {
                    const tabRef = this.tabRefs[tab.component.tabName]
                    tabRef.tabDidChange?.(key, nextActiveTab)
                }
            })
        }
    }
}
