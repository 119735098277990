import * as React from 'react'

type Effect<R> = () => R
type EffectUpdater = () => void
type AfterEffect<R> = (value: R) => void
export const useRefEffect = <R extends any>(
    effect: Effect<R>,
    deps?: any[],
    after?: AfterEffect<R>,
): [React.MutableRefObject<R>, EffectUpdater] => {
    const value = React.useRef<R>()
    const update = async () => {
        value.current = effect()
        after?.(value.current)
    }

    React.useEffect(() => {
        update()
    }, deps)

    return [value as React.MutableRefObject<R>, update]
}
