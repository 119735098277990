import randomstring from 'randomstring'
import aqe from '@pushly/aqe'
import { FileUploadService } from '@pushly/aqe/lib/services/file-upload.service'
import { Container } from 'typescript-ioc/es5'
import { stripUndefined } from './strip-undefined'
import { generateUID } from '../components/campaign-builder/helpers/uid'
import { noop } from './utils'

const getCacheBustedUrl = (url) => {
    const cbUrl = new URL(url)
    cbUrl.searchParams.append('_cb', randomstring.generate())
    return cbUrl.toString()
}

const getCacheBustedBlobFromUrl = (url) => {
    return fetch(getCacheBustedUrl(url)).then((res) => res.blob())
}

export const getFileBlobFromUrl = async (url) => {
    const fileUploadService = Container.get(FileUploadService)

    const fetchEndpoint = new URL(FileUploadService.endpoint)
    fetchEndpoint.searchParams.append('fetch', url)

    let fetchHandler: Promise<Blob | undefined>
    if (url.includes(aqe.defaults.publicMediaDomain)) {
        fetchHandler = getCacheBustedBlobFromUrl(url)
    } else {
        fetchHandler = fileUploadService
            .fetchRemoteSource(url)
            .then((res) => {
                if (!res.ok) {
                    throw res.error
                }

                return getCacheBustedBlobFromUrl(res.data!.url).then((blob) => ({ blob, metadata: res.data! }))
            })
            .then((res) => res.blob)
    }

    return fetchHandler
        .then((blob) => blob)
        .catch((err) => {
            console.warn('[getImageBlobFromURL] fetch error', err)
        })
}

export async function getImageFromBlob(blob: Blob): Promise<HTMLImageElement> {
    return getImageFromURL(URL.createObjectURL(blob))
}

export async function getImageFromURL(url: string): Promise<HTMLImageElement> {
    return new Promise((res, rej) => {
        const img = new Image()
        img.onerror = rej
        img.onload = function (this: HTMLImageElement) {
            res(this)
        }
        img.src = url
    })
}
