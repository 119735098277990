import aqe from '@pushly/aqe'

export function setupAqe() {
    let publicPlatformDomain = process.env.PUB_PLAT_URL!
    let internalPlatformDomain = process.env.INT_PLAT_URL!
    let publicApiDomain = process.env.PUB_API_URL!
    let internalApiDomain = process.env.INT_API_URL!
    let batchApiDomain = process.env.BATCH_API_URL!
    let insightsDomain = process.env.INSIGHTS_URL!

    aqe.set({
        appName: 'Pufferfish',
        storageDomain: process.env.PLATFORM_ROOT_DOMAIN,
        publicPlatformDomain: `https://${publicPlatformDomain}`,
        internalPlatformDomain: `https://${internalPlatformDomain}`,
        publicApiDomain: `https://${publicApiDomain}`,
        internalApiDomain: `https://${internalApiDomain}`,
        batchApiDomain: `https://${batchApiDomain}`,
        insightsDomain: `https://${insightsDomain}`,
        publicCdnDomain: `https://${process.env.CDN_URL}`,
        publicMediaDomain: `https://${process.env.MEDIA_URL}`,
    })
}
