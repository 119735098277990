import { IdentityProviderTypeDto } from './identity-provider-type.dto'

export class IdentityProviderDto {
    public static fromApiResponse(fields: Partial<IdentityProviderDto>): IdentityProviderDto {
        const dto = new IdentityProviderDto()
        Object.assign(dto, fields)
        return dto
    }

    public identityProviderType: IdentityProviderTypeDto
    public id: number
    public name: string
    public domain: string
    public associatedEmailDomains: string[]
}
