import * as React from 'react'
import './campaign-table-well.scss'
import { Container } from 'typescript-ioc/es5'
import { observe } from 'mobx'
import { Moment } from 'moment'
import * as moment from 'moment-timezone'
import { InfoCircleOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Table, Tooltip } from 'antd'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { DomainDto } from '../../../dtos/domain'
import { StatusType } from '../../../enums/status-type'
import { AppState } from '../../../stores/app'
import { AppService, CampaignV2Service, DomainService } from '../../../services'
import { isProxyEvent, simpleNotification, titleCase, simpleSort } from '../../../_utils/utils'
import { CampaignTableDto } from '../dtos/campaign-table-dto'
import { MultiSelectSize } from '../../../components/multi-select/interfaces'
import { BetterSelect } from '../../../components/better-select/better-select'
import { SHORT_DATE_FORMAT, TZ_PLATFORM_DEFAULT } from '../../../constants'
import { TableRowEntityDisplay } from '../../../components/table-row-entity-display/table-row-entity-display'
import { CampaignStatusBadge } from '../../../components/badges/campaign-status-badge'
import { getCalculatedStatus } from '../../../components/campaign-builder/helpers/campaign'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { TriggerType } from '../../../components/campaign-builder/enums'
import { BatchService } from '../../../services/batch'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { AbilityAction } from '../../../enums/ability-action.enum'
import { NoTranslate } from '../../../components/no-translate/no-translate'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { determineChannelBadge } from '../../../components/badges/_utils'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'

interface ICampaignTableWellProps {
    showSearch?: boolean
    showRefresh?: boolean
    showHeader?: boolean
    showMeta?: boolean
    title?: string
    actions?: React.ReactNode
    showAdvancedRowActions?: boolean
    pageSize?: number
    defaultStatusFilters?: string[]
}

interface ICampaignTableWellState {
    domain: DomainDto
    loading: boolean
    selectedStatusFilters: string[]
    campaignsLoaded: boolean
    campaigns: CampaignTableDto[]
    visibleCampaigns: CampaignTableDto[]
    pageSize: number
    page: number
    query?: string
    sortedInfo: any
}

function getStatusSortingValue(campaign) {
    const status = getCalculatedStatus(campaign)

    return status === StatusType.CANCELLED.name
        ? 1
        : status === StatusType.COMPLETED.name
        ? 2
        : status === StatusType.DRAFT.name
        ? 3
        : status === StatusType.SCHEDULED.name
        ? 4
        : status === StatusType.ACTIVE.name || status === StatusType.PAUSED.name
        ? 5
        : 0
}

export class CampaignTableWell extends BetterComponent<ICampaignTableWellProps, ICampaignTableWellState> {
    private readonly appState: AppState
    private readonly appService: AppService
    private readonly domainService: DomainService
    private readonly campaignService: CampaignV2Service
    private readonly batchService: BatchService

    private defaultStatusFilters: string[] = ['DRAFT', 'RUNNING', 'SCHEDULED', 'COMPLETED']

    private endBehaviorRef: any
    private disposeObservers: any

    public constructor(props: ICampaignTableWellProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)
        this.campaignService = Container.get(CampaignV2Service)
        this.batchService = Container.get(BatchService)

        this.state = {
            domain: this.appState.currentDomain!,
            loading: true,
            selectedStatusFilters: this.props.defaultStatusFilters || this.defaultStatusFilters,
            campaignsLoaded: false,
            campaigns: [],
            visibleCampaigns: [],
            pageSize: 1000,
            page: 1,
            sortedInfo: {
                columnKey: 'createdAt',
                order: 'descend',
            },
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.ensureCampaignsForList())]

        this.ensureCampaignsForList()
    }

    public componentWillUnmount(): void {
        super.componentWillUnmount()

        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        const wellProps: any = {
            showFooter: false,
            showHeader: false,
            title: 'Campaigns',
            loading: this.state.loading,
        }

        const domain = this.appState.currentDomain!
        const activeDomainChannels = getEnabledDeliveryChannels(domain, true)

        const wellActions = (
            <React.Fragment>
                {this.props.actions}

                {this.props.showRefresh !== false && (
                    <React.Fragment>
                        {this.props.showHeader !== false && this.renderStatusFilter('small')}

                        <Tooltip title="refresh">
                            <Button className="refresh-list" size="small" onClick={this.refreshCampaigns}>
                                <ReloadOutlined spin={this.state.loading} />
                            </Button>
                        </Tooltip>
                    </React.Fragment>
                )}
            </React.Fragment>
        )

        if (this.props.showHeader !== false) {
            wellProps.showHeader = true
            wellProps.action = wellActions

            if (this.props.title && this.props.title.trim()) {
                wellProps.title = this.props.title
            }
        }

        const wellClassNames: string[] = ['campaign-table-well', 'table-well']
        if (this.props.showHeader !== false) {
            wellClassNames.push('show-header')
        }
        if (this.props.showSearch === false) {
            wellClassNames.push('hide-search')
        }
        wellProps.className = wellClassNames.join(' ')

        return (
            <Well {...wellProps}>
                {this.props.showHeader === false && (
                    <div className="top-bar">
                        <div className="top-bar-row">
                            <div className="top">
                                <div>
                                    <strong>Search & Filter</strong>
                                </div>

                                <div className="list-actions-wrapper">{wellActions}</div>
                            </div>
                            <div className="bottom">
                                <div className="list-search-wrapper">
                                    <Input.Search
                                        placeholder="Search by title"
                                        enterButton={false}
                                        onSearch={this.searchCampaigns}
                                    />
                                </div>

                                <div className="list-filters-wrapper">{this.renderStatusFilter()}</div>

                                <div className="list-search-button-wrapper">
                                    <AsyncButton onClick={this.searchCampaigns} type="primary">
                                        <span>Search</span>
                                    </AsyncButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="list-content">
                    <Table
                        dataSource={this.state.visibleCampaigns}
                        onChange={this.handleTableChange}
                        pagination={false}
                        bordered={true}
                    >
                        <Table.Column className="campaign" title="Campaign" render={this.renderDisplay} />
                        <Table.Column
                            className="delivery"
                            title="Run Dates"
                            render={this.renderRunDates}
                            align="center"
                        />

                        {activeDomainChannels.length > 1 && (
                            <Table.Column
                                sorter={false}
                                className="delivery-channel"
                                dataIndex={['channels']}
                                title="Delivery Channels"
                                align="center"
                                render={(channels: DeliveryChannel[]) =>
                                    (channels ?? []).map((ch) => <span key={ch}>{determineChannelBadge(ch)}</span>)
                                }
                            />
                        )}

                        <Table.Column
                            className="actions"
                            title="Actions"
                            align="center"
                            render={this.renderRowActions}
                        />
                    </Table>
                </div>
            </Well>
        )
    }

    protected renderDisplay = (_text: string, campaign: CampaignTableDto): React.ReactNode => {
        const model = CampaignModel.build(campaign)
        const name = model.getName()
        const statusText = getCalculatedStatus(campaign, (status) => {
            return status === 'ACTIVE' ? 'RUNNING' : status
        })

        let computedType = campaign.configuration.computed_trigger_type
        if (computedType === TriggerType.SUBSCRIBED) {
            computedType = 'User Subscription'
        } else if (computedType === TriggerType.ADD_TO_CART) {
            computedType = 'Add-to-Cart'
        } else if (computedType === TriggerType.ABANDONED_VIEW) {
            computedType = 'Abandoned Browse'
        } else {
            computedType = titleCase(computedType ?? model.getType())
        }

        return (
            <TableRowEntityDisplay
                title={
                    <a href={this.buildDetailsUrl(campaign)}>
                        <NoTranslate>{name}</NoTranslate>
                    </a>
                }
                description={<span>Type: {computedType}</span>}
                status={<CampaignStatusBadge status={statusText} expanded={true} />}
            />
        )
    }

    protected renderRunDates = (_: string, campaign: CampaignTableDto): React.ReactNode => {
        const isSTZ = campaign.dateTimeZone.toUpperCase() === 'STZ'
        const domainTz = this.state.domain?.timezone ?? TZ_PLATFORM_DEFAULT
        const tz = isSTZ ? domainTz : campaign.dateTimeZone

        const dateStart: Moment = moment.tz(campaign.dateStart, tz)
        const dateStartStr: String = dateStart.format(SHORT_DATE_FORMAT)

        let dateEnd: Moment | null = null
        let dateEndStr: String | null = null
        if (campaign.dateEnd) {
            dateEnd = moment.tz(campaign.dateEnd, tz)
        } else if (campaign.dateCompletedUtc) {
            dateEnd = moment.tz(campaign.dateCompletedUtc, 'UTC').tz(domainTz)
        }
        if (dateEnd) {
            dateEndStr = dateEnd.format(SHORT_DATE_FORMAT)
        }

        return (
            <div className="composite-stack">
                <span>{dateStartStr}</span>
                {!dateEndStr ? (
                    <span>Ongoing</span>
                ) : (
                    <span>
                        <span className="lowlight">through</span> {dateEndStr}
                    </span>
                )}
            </div>
        )
    }

    protected renderStatusFilter(size: MultiSelectSize = 'default'): React.ReactNode {
        return (
            <BetterSelect
                className="status-filter-select"
                size={size}
                options={[
                    { value: 'DRAFT', label: 'Draft' },
                    { value: 'SCHEDULED', label: 'Scheduled' },
                    { value: 'RUNNING', label: 'Running' },
                    { value: 'COMPLETED', label: 'Completed' },
                ]}
                mode="multiple"
                onChange={async (selectedStatusFilters: any) => {
                    this.setState({ selectedStatusFilters })
                }}
                disableSelectAll={true}
                value={this.state.selectedStatusFilters}
                selectAllLabel="All Statuses"
                disableSearch={true}
                closeOnEscape={true}
                maxDisplayCount={2}
                maxDisplayFormatter={(options: any[]) => {
                    const multi = options.length > 1
                    return (
                        <Tooltip title={options.map((o) => o.label).join(', ')}>
                            {options.length} {multi ? 'Statuses' : 'Status'} Selected
                        </Tooltip>
                    )
                }}
            />
        )
    }

    protected renderRowActions = (campaign: CampaignTableDto | any): React.ReactNode => {
        const { abilityStore } = this.appState
        const canMutate = abilityStore.can(
            AbilityAction.UPDATE,
            abilityStore.getDomainOwnedIdentityFor(SubjectEntity.CAMPAIGN),
        )

        const isDraft = campaign.status === StatusType.DRAFT.name
        const isCancelled = campaign.status === StatusType.CANCELLED.name
        const isCompleted =
            campaign.status === StatusType.COMPLETED.name || getCalculatedStatus(campaign) === StatusType.COMPLETED.name

        const canBeArchived = isDraft || isCancelled || isCompleted

        let actions: any = []
        if (this.props.showAdvancedRowActions === false) {
            actions = undefined
        } else if (canMutate) {
            if (!isCompleted || isDraft) {
                actions.push({
                    text: 'Edit',
                    icon: 'edit',
                    altHref: this.buildEditUrl(campaign),
                    onClick: this.handleEditClick,
                    campaign,
                })
            }

            actions.push({
                text: 'Duplicate',
                icon: 'copy',
                altHref: this.buildDuplicateUrl(campaign),
                onClick: this.handleDuplicateClick,
                campaign,
            })

            if (!isDraft && !campaign.isCompleted()) {
                actions.push({ divider: true })
                actions.push({
                    text: 'Complete',
                    icon: 'info-circle',
                    onClick: () => this.handleComplete(campaign),
                    campaign,
                })
            }

            if (canBeArchived) {
                actions.push({ divider: true })
                actions.push({
                    text: 'Archive',
                    icon: 'folder',
                    onClick: this.handleArchiveClick,
                    campaign,
                    confirm: {
                        okText: 'Yes, Archive',
                        title: 'Archive Campaign',
                        className: 'completed-campaign-confirm',
                        iconType: 'info-circle',
                        content: (
                            <div>
                                Are you sure you want to archive the following campaign?
                                <p>
                                    <b>{campaign.name}</b>
                                </p>
                            </div>
                        ),
                    },
                })
            }
        }

        return (
            <AsyncButton
                actions={actions}
                onClick={() => this.handlePrimaryActionClick(campaign)}
                size="small"
                altHref={this.buildDetailsUrl(campaign)}
            >
                <span>View</span>
            </AsyncButton>
        )
    }

    protected handleComplete = async (campaign: any) => {
        Modal.confirm({
            title: 'Complete Campaign',
            className: 'campaign-completed-confirm',
            icon: <InfoCircleOutlined />,
            okText: 'Yes, Complete',
            content: (
                <div className="end-date-behavior">
                    <span className="end-date-behavior-label">
                        When the campaign completes, all current active members will:
                        <Tooltip
                            title={
                                <>
                                    No additional subscribers will be added to the campaign when it is completed. Choose
                                    one of the options to determine how members who are currently active in the campaign
                                    will be treated when the campaign is complete.
                                </>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                    </span>
                    <div>
                        <BetterSelect
                            ref={(el) => (this.endBehaviorRef = el)}
                            className="end-date-behavior-select"
                            defaultValue={[campaign.getEndBehavior()]}
                            options={[
                                {
                                    value: 'bleed',
                                    label: 'Continue executing steps of the campaign',
                                },
                                {
                                    value: 'stop:completed',
                                    label: 'Stop executing steps and have their status set to “Completed”',
                                },
                                {
                                    value: 'stop:removed',
                                    label: 'Stop executing steps and have their status set to “Removed”',
                                },
                            ]}
                            disabled={campaign.isCompleted()}
                            disableSearch={true}
                            disableSelectAll={true}
                        />
                    </div>
                </div>
            ),
            onOk: (_, confirmed) => this.handleCompleteClick(campaign, confirmed),
        })
    }

    protected buildDetailsUrl(campaign: CampaignTableDto): string {
        const url = `/campaigns/${campaign.id}/summary`
        return this.appService.routeWithinDomain(url, true)
    }

    protected buildDuplicateUrl(campaign: CampaignTableDto): string {
        const url = `/campaigns/new?template=${campaign.id}`
        return this.appService.routeWithinDomain(url, true)
    }

    protected buildEditUrl(campaign: CampaignTableDto): string {
        const url = `/campaigns/${campaign.id}/builder`
        return this.appService.routeWithinDomain(url, true)
    }

    protected handleTableChange = async (pagination: any, filters: any, sorter: any): Promise<void> => {
        return this.setState({
            page: pagination.current,
            sortedInfo: sorter,
        })
    }

    protected handlePrimaryActionClick(campaign: CampaignTableDto): void {
        this.appService.route(this.buildDetailsUrl(campaign))
    }

    protected handleEditClick = async (item: any): Promise<void> => {
        const { campaign } = item.props
        this.appService.route(this.buildEditUrl(campaign))
    }

    protected handleDuplicateClick = async (item: any): Promise<void> => {
        const { campaign } = item.props
        this.appService.route(this.buildDuplicateUrl(campaign))
    }

    protected handleCompleteClick = async (campaign: any, confirmed?: boolean): Promise<void> => {
        const value = this.endBehaviorRef.state.selectedOptions[0].value

        if (value !== campaign.getEndBehavior()) {
            const parts = value.split(':')
            const behavior: any = {
                end_behavior: parts[0],
                end_status: 'completed',
            }

            if (parts.length === 2) behavior.end_status = parts[1]

            campaign.configuration = {
                ...campaign.configuration,
                ...behavior,
            }
        }

        try {
            await this.campaignService.patch(
                campaign.id,
                {
                    status: StatusType.COMPLETED.name,
                    configuration: {
                        ...campaign.configuration,
                    },
                },
                {
                    showLoadingScreen: true,
                    cancellationKey: 'handleCompleteClick',
                },
            )

            const campaigns = [...this.state.campaigns]
            const idx = campaigns.findIndex((c) => c.id.toString() === campaign.id.toString())
            if (idx !== -1) {
                const campaignUpdate = campaigns[idx]
                campaignUpdate.statusId = StatusType.COMPLETED.id
                campaignUpdate.status = StatusType.COMPLETED.name
                campaigns.splice(idx, 1, campaignUpdate)

                this.setState({ campaigns })
            }

            simpleNotification(
                'success',
                'The campaign has been successfully completed with the selected end date behavior.',
            )
        } catch (e) {
            if (e) {
                simpleNotification('error', e)
            }
        }

        return this.searchCampaigns()
    }

    protected handleArchiveClick = async (item: any): Promise<void> => {
        const { campaign } = item.props

        await this.campaignService.destroy(campaign.id, {
            showLoadingScreen: true,
            cancellationKey: 'handleCompleteClick',
        })

        return this.searchCampaigns()
    }

    protected async ensureCampaignsForList() {
        const { domain } = this.state

        if (this.state.campaignsLoaded && !!domain && domain.id === this.appState.currentDomain!.id) {
            return
        }

        await this.setState({ loading: true, campaignsLoaded: false })

        return this.fetchCampaigns()
    }

    protected getSelectedStatusFilters(): string[] {
        return this.state.selectedStatusFilters || []
    }

    protected refreshCampaigns = async () => {
        return this.fetchCampaigns()
    }

    protected async fetchCampaigns(search?: any): Promise<void> {
        const { pageSize, page, query } = this.state

        await this.setState({
            loading: true,
        })

        try {
            const res = await this.campaignService.fetchAll({
                cancellationKey: 'cl.fetchAllCampaigns',
                query: {
                    pagination: 0,
                    limit: pageSize,
                    page,
                    search: search ? search : query,
                    status: this.getSelectedStatusFilters().join(','),
                },
            })

            if (res.ok && res.data.length > 0) {
                const tableDtos: CampaignTableDto[] = res.data.map(CampaignTableDto.fromCampaignDto)

                const defaultSortedTable = this.defaultSortCampaigns(tableDtos)

                return this.setState({
                    domain: this.appState.currentDomain!,
                    loading: false,
                    campaignsLoaded: true,
                    campaigns: defaultSortedTable,
                    visibleCampaigns: defaultSortedTable,
                })
            } else {
                return this.setState({
                    domain: this.appState.currentDomain!,
                    loading: false,
                    campaignsLoaded: true,
                    campaigns: [],
                    visibleCampaigns: [],
                })
            }
        } catch (_) {}
    }

    protected defaultSortCampaigns = (data: CampaignTableDto[]): CampaignTableDto[] => {
        const sorted = [...data]

        sorted.sort((a, b) => {
            const aStatus = getStatusSortingValue(a)
            const bStatus = getStatusSortingValue(b)
            const nameSort = simpleSort(a.name, b.name)

            const statusOrder = aStatus > bStatus ? -1 : aStatus < bStatus ? 1 : 0

            return statusOrder || nameSort
        })

        return sorted
    }

    protected searchCampaigns = async (event?: any) => {
        let value

        if (event) {
            value = isProxyEvent(event) ? event.target.value : event
        }

        const valueIsEmpty = !value || value.length === 0
        if (valueIsEmpty || value.length >= 2) {
            await this.setState({
                query: value,
                page: 1,
            })

            await this.fetchCampaigns(value)
        }
    }
}
