import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Component } from 'react'
import { ISelectPortalProps } from './interfaces'

const preventBubbling = (event: MouseEvent | any): void => {
    if (event) {
        event.stopPropagation()
    }
}

export class SelectPortal extends Component<ISelectPortalProps, {}> {
    private portalElement: any
    private defaultClassNames: string[] = ['aqe-select', 'aqe-select-dropdown-container']

    // @ts-ignore
    public render() {
        return null
    }

    public componentDidMount() {
        let p: any = this.props.portalId && document.getElementById(this.props.portalId)

        if (!p) {
            p = document.createElement('div')
            p.id = this.props.portalId

            this.buildClassNames().forEach((className) => p.classList.add(className))

            this.props.container.appendChild(p)
        }

        this.portalElement = p
        this.portalElement.addEventListener('mousedown', preventBubbling)
        this.componentDidUpdate()
    }

    public componentWillUnmount() {
        this.portalElement.removeEventListener('mousedown', preventBubbling)
        this.props.container.removeChild(this.portalElement)
    }

    public componentDidUpdate(): void {
        if (!!this.portalElement.classList && this.portalElement.classList.length > 0) {
            this.portalElement.classList.remove(...this.portalElement.classList)
        }
        this.buildClassNames().forEach((className) => this.portalElement.classList.add(className))

        ReactDOM.render(<React.Fragment>{this.props.children}</React.Fragment>, this.portalElement)
    }

    private buildClassNames(): string[] {
        const classNames = [...this.defaultClassNames]

        if (this.props.className) {
            this.props.className.split(' ').forEach((className) => classNames.push(className.trim()))
        }

        return classNames
    }
}
