import * as React from 'react'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../better-component/better-component'
import { IRule, IRuleDefinition } from './interfaces/rule'
import './styles/rule-row.scss'
import { IRuleBuilderField } from '../rule-builder/rule-builder'
import { baseOperators, RuleBuilderOperator } from '../rule-builder/operators'
import * as deepEqual from 'react-fast-compare'
import { Select } from 'antd'
import { getFieldOperators, stripDomainIdPattern } from './utils'

interface IRuleRowOperatorProps {
    mode: 'display' | 'edit'
    rule: IRule
    builderFields: IRuleBuilderField[]
    onChange: (operator: string, rule: IRule, operatorConfig?: any) => any
}

export class RuleRowOperator extends BetterComponent<IRuleRowOperatorProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-rule-row-operator'
    public ref: any

    public constructor(props: IRuleRowOperatorProps) {
        super(props)
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className="display-mode">{this.operatorDisplay}</div>

                <div className="edit-mode">
                    <Select
                        dropdownClassName={this.buildClassName('dropdown')}
                        defaultValue={this.defaultOperatorValue}
                        value={this.rule.operator}
                        onChange={this.handleChange}
                    >
                        {this.operatorOptions.map((opr) => (
                            <Select.Option key={opr.value} value={opr.value} data-sw-operator={JSON.stringify(opr)}>
                                {opr.longDisplay}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        )
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get field(): IRuleBuilderField {
        return this.props.builderFields.find((f) => {
            return (
                f.property === this.rule.builderProperty ||
                stripDomainIdPattern(f.property) === this.rule.builderProperty
            )
        })!
    }

    protected get operatorDisplay(): string {
        const operator = this.operatorOptions.find((o) => o.value === this.rule.operator)
        return !!operator ? operator.longDisplay : ''
    }

    protected get defaultOperatorValue(): string {
        return this.operatorOptions[0].value
    }

    protected get operatorOptions(): RuleBuilderOperator[] {
        return getFieldOperators(this.field)
    }

    @autobind
    protected async handleChange(value: any, opt: any): Promise<void> {
        if (!deepEqual(value, this.rule.operator)) {
            let oprConfig = {}
            try {
                oprConfig = JSON.parse(opt.props?.['data-sw-operator'] ?? {})
            } catch (_) {}
            this.props.onChange(value, this.props.rule, oprConfig)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
