import * as React from 'react'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { UserService, UserTokenType } from '../../../services/user'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Translate } from '../../../components/translate/translate'
import { UserDataForm, UserDataFormStyle } from '../../users'
import './confirm-user.scss'
import { simpleFormErrorNotification } from '_utils/utils'

export interface IConfirmUserFormOptions {
    name: string
    password: string
    passwordVerify: string
}

interface IProps {}

interface IState {
    loading: boolean
    hasError: boolean
    errorMessage: string
    user?: any
}

@observer
export class ConfirmUser extends BetterComponent<IProps, IState> {
    public state: IState = {
        loading: true,
        hasError: false,
        errorMessage: '',
    }

    private userService: UserService

    public constructor(props: IProps) {
        super(props)

        this.userService = Container.get(UserService)
    }

    public async componentDidMount() {
        try {
            const user = await this.userService.confirmUserToken(
                this.queryString.token as any,
                UserTokenType.INVITATION,
            )

            this.setState({ user })
        } catch (error) {
            this.setState({
                hasError: true,
                errorMessage: error.message,
            })
        } finally {
            this.setState({ loading: false })
        }
    }

    public render() {
        return this.state.loading ? (
            ''
        ) : (
            <div className="confirm-user-page">
                <div className="confirm-user-page-content">
                    <div className="content-header">
                        <h1>
                            <Translate name="userConfirmation.formHeader" />
                        </h1>
                    </div>

                    {this.state.hasError ? (
                        this.state.errorMessage
                    ) : (
                        <UserDataForm
                            className="confirm-user-form"
                            style={UserDataFormStyle.CONFIRM}
                            hideWell={true}
                            onSubmit={this.handleConfirmationSubmit}
                            submitText="Submit"
                            user={this.state.user}
                        />
                    )}
                </div>
            </div>
        )
    }

    @autobind
    private async handleConfirmationSubmit(error: any, values: IConfirmUserFormOptions) {
        if (!error) {
            return await this.userService.processNewUserConfirmation(
                this.queryString.token as any,
                this.state.user.id,
                values,
            )
        }
        if (error) {
            simpleFormErrorNotification(error)
        }
    }
}
