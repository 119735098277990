import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { PageHeader } from '../../components/page-header/page-header'
import { AccountService, AppService } from '../../services'
import { AppState } from '../../stores/app'
import { AccountDataForm } from '../../components/account-data-form/account-data-form'
import { Tabs } from 'antd'
import { UserList } from '../../components/user-list/user-list'
import { RouteComponentProps } from 'react-router-dom'
import { ProviderList } from '../../components/account-security-settings/sso-idp-list'
import { FEAT_ACCOUNT_SECURITY_TAB } from '../../constants'

interface IAccountRouteProps {
    accountId: string
    orgId: string
}

interface IAccountProps extends RouteComponentProps<IAccountRouteProps> {}

export class Account extends React.Component<IAccountProps> {
    protected appState: AppState
    protected appService: AppService
    protected accountService: AccountService

    protected disposeObservers: any[] = []

    public constructor(props: IAccountProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.accountService = Container.get(AccountService)
    }

    public render() {
        const accountSecurityTabFlag = this.appState.flags.findActive(FEAT_ACCOUNT_SECURITY_TAB)
        const accountSecurityTabEnabled =
            accountSecurityTabFlag &&
            this.appState.currentDomain?.accountFlags?.includes(accountSecurityTabFlag?.getKey())

        return (
            <div className="sw-page single-account">
                <div className="sw-page-wrapper">
                    <PageHeader title="Settings" append={<span>ID: {this.requestedAccountId}</span>} />

                    <Tabs
                        size="small"
                        animated={{ inkBar: false, tabPane: false }}
                        tabPosition="top"
                        activeKey={this.currentTabKey}
                        onChange={this.setCurrentTabKey}
                    >
                        <Tabs.TabPane key="settings" tab="Settings">
                            <AccountDataForm
                                key={`adf-${this.requestedAccountId}`}
                                accountId={this.requestedAccountId}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="users" tab="Users">
                            <UserList
                                key={`aul-${this.requestedAccountId}`}
                                options={{
                                    accountId: this.requestedAccountId,
                                }}
                            />
                        </Tabs.TabPane>
                        {accountSecurityTabEnabled && (
                            <Tabs.TabPane key="security" tab="Security">
                                <ProviderList
                                    key={`apl-${this.requestedAccountId}`}
                                    accountId={this.requestedAccountId}
                                />
                            </Tabs.TabPane>
                        )}
                    </Tabs>
                </div>
            </div>
        )
    }

    protected get requestedAccountId(): number {
        const id = this.props.match.params.accountId ?? this.props.match.params.orgId
        return parseInt(id, 10)
    }

    protected get currentTabKey(): string {
        let key = 'settings'

        if (this.props.location.hash) {
            key = this.props.location.hash.replace('#', '').toLowerCase() || key
        }

        return key
    }

    protected setCurrentTabKey = (key: string): void => {
        this.appService.route(`${this.props.location.pathname}#${key}`)
    }
}
