import { useState, useEffect, useRef } from 'react'
import { useMountedRef } from '../_utils/use-mounted-ref'

/**
 * Our answer to React's non-partial-friendly useState hook
 *
 * useSubjectivelyBetterState allows passing partials
 * and is component-is-mounted aware to prevent any
 * possible setState calls after the consumer has been
 * removed from scope.
 */
// @types/react: Unlike the class component setState, the updates are not allowed to be partial
// @types/react: type SetStateAction<S> = S | ((prevState: S) => S);

type SubjectivelyBetterSetStateAction<S> = S | Partial<S> | ((prevState: S) => S | Partial<S>)
type DispatchWithPromise<A, S> = (value: A) => Promise<S>

// This <S = undefined> enforces setting a type by making it impossible to use when undefined is given
export function useSubjectivelyBetterState<S = undefined>(): [
    S | undefined,
    DispatchWithPromise<SubjectivelyBetterSetStateAction<S | undefined>, S | undefined>,
]
export function useSubjectivelyBetterState<S>(
    initialState: S | (() => S),
): [S, DispatchWithPromise<SubjectivelyBetterSetStateAction<S>, S>]

// actual definition of above overloads
export function useSubjectivelyBetterState<S extends undefined>(
    initialState?: S | (() => S),
): [S | undefined, DispatchWithPromise<SubjectivelyBetterSetStateAction<S | undefined>, S | undefined>] {
    const [, runIfMounted] = useMountedRef()
    const [state, setState] = initialState ? useState<S>(initialState) : useState<S>()

    // callback supporters
    const callbackHandler = useRef<((state: S) => void) | undefined>()

    // post state change handler
    useEffect(() => {
        if (callbackHandler.current) {
            callbackHandler.current(state!)
        }

        // destroy handler at end of cycle
        callbackHandler.current = undefined
    }, [state])

    const wrappedSetState: DispatchWithPromise<SubjectivelyBetterSetStateAction<S | undefined>, S | undefined> = (
        value,
    ) => {
        return new Promise((res, rej) => {
            runIfMounted(() => {
                // setup new callback fallback
                callbackHandler.current = res

                try {
                    setState((prevState) => {
                        const nextState = typeof value === 'function' ? value(prevState) : value

                        return (
                            typeof nextState === 'object' &&
                            (typeof prevState === 'object' || prevState === undefined || prevState === null)
                                ? { ...((prevState ?? {}) as object), ...nextState }
                                : nextState
                        ) as S
                    })
                } catch (err) {
                    rej(err)
                }
            })
        })
    }

    return [state, wrappedSetState]
}
