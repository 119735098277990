import * as React from 'react'
import './custom-location.input.scss'
import { RadioChangeEvent } from 'antd/lib/radio'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { BetterComponent } from '../../../better-component/better-component'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import { DeleteOutlined, DragOutlined, EditOutlined, EyeOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Radio, Button } from 'antd'
import { getClassNames } from '../../../../_utils/classnames'
import { Well } from '@pushly/aqe/lib/components'
import { Drawer } from '../../../drawer/drawer'
import { Mapbox } from '../../../mapbox/mapbox'
import { StaticMode } from '../../../mapbox/static.mode'
import { baseOperators } from '../../../rule-builder/operators'
import { DRAW_STYLES } from '../../../mapbox/mapbox.constants'
import { ResizeWatcher } from '../../../resize-watcher/resize-watcher'

type DrawMode = 'static' | 'draw_polygon' | 'direct_select'

declare const mapboxgl: any
declare const MapboxDraw: any

interface ICustomLocationProps {
    field: IRuleBuilderField
    rule: IRule
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
}

interface ICustomLocationState {
    showDrawer: boolean
    drawMode: DrawMode
    workingValue: ICustomLocationValue | null
    preferredMapWidth: number
    preferredMapHeight: number
}

interface ICustomLocationValue {
    points: Array<[number, number]>
}

export class CustomLocationInput extends BetterComponent<ICustomLocationProps, ICustomLocationState> {
    public readonly defaultClassName = 'sw-v2-rb-custom-location-input'

    protected drawControl: any
    protected mapControl: any
    protected polygonId: any

    public constructor(props) {
        super(props)

        this.drawControl = new MapboxDraw({
            defaultMode: 'static',
            displayControlsDefault: false,
            styles: DRAW_STYLES,
            modes: {
                static: StaticMode,
                draw_polygon: MapboxDraw.modes.draw_polygon,
                direct_select: MapboxDraw.modes.direct_select,
                simple_select: MapboxDraw.modes.simple_select,
            },
        })

        this.state = {
            showDrawer: false,
            drawMode: 'static',
            workingValue: this.rule.value,
            preferredMapHeight: 0,
            preferredMapWidth: 0,
        }
    }

    public render() {
        return (
            <div className={this.buildRootClassNames()}>
                {this.isDisplayMode ? this.renderDisplay() : this.renderEditor()}
            </div>
        )
    }

    protected renderDisplay = (): React.ReactNode => {
        return (
            <div className={this.buildClassName('display')}>
                <div>
                    within Polygon
                    <Button size="small" onClick={this.openDrawer}>
                        <EyeOutlined />
                        <span>View</span>
                    </Button>
                    {this.renderDrawer()}
                </div>
            </div>
        )
    }

    protected renderEditor = (): React.ReactNode => {
        return (
            <div className={this.buildClassName('editor')}>
                <div>
                    within Polygon
                    <Button size="small" onClick={this.openDrawer}>
                        <EditOutlined />
                        <span>Edit In Map</span>
                    </Button>
                    {this.renderDrawer()}
                </div>
            </div>
        )
    }

    protected renderDrawer = (): React.ReactNode => {
        return (
            <Drawer
                className={getClassNames('custom-location-acd')}
                title={this.props.mode === 'edit' ? `Edit Polygon` : 'View Polygon'}
                placement="right"
                closable={true}
                hideFooter={this.props.mode === 'display'}
                submitText="Save Polygon"
                disableSubmit={this.state.workingValue === null || this.state.workingValue === this.rule.value}
                onSubmit={this.savePolygon}
                onClose={this.cancelPolygonEdit}
                visible={this.state.showDrawer}
            >
                {this.state.showDrawer && (
                    <React.Fragment>
                        <ResizeWatcher onSizeChange={this.handleDrawerSizeChange} />

                        <Well hideHeader={true} hideFooter={true}>
                            <Mapbox
                                lng={-55.8}
                                lat={36.5}
                                zoom={1}
                                minWidth={600}
                                width={this.state.preferredMapWidth}
                                minHeight={500}
                                height={this.state.preferredMapHeight}
                                controls={[this.drawControl]}
                                showNavigationControls={true}
                                listeners={{
                                    load: this._onMapLoad,
                                    'draw.modechange': this._onModeChange,
                                    'draw.create': this._onPolygonCreate,
                                    'draw.update': this._onPolygonUpdate,
                                }}
                            />

                            {this.props.mode === 'edit' && (
                                <div className="custom-location-drawer-tools">
                                    <div className="custom-location-drawer-tools-left">
                                        <Radio.Group onChange={this.changeMode}>
                                            <Radio.Button value="static" checked={this.state.drawMode === 'static'}>
                                                Pan Map
                                                <DragOutlined />
                                            </Radio.Button>
                                            {this.state.workingValue ? (
                                                <Radio.Button
                                                    value="direct_select"
                                                    checked={this.state.drawMode === 'direct_select'}
                                                >
                                                    Edit Polygon
                                                    <ShareAltOutlined />
                                                </Radio.Button>
                                            ) : (
                                                <Radio.Button
                                                    value="draw_polygon"
                                                    checked={this.state.drawMode === 'draw_polygon'}
                                                >
                                                    Create Polygon
                                                    <ShareAltOutlined />
                                                </Radio.Button>
                                            )}
                                        </Radio.Group>
                                    </div>
                                    <div className="custom-location-drawer-tools-right">
                                        <Button disabled={!this.state.workingValue} onClick={this.reset}>
                                            <span>Clear Polygon</span>
                                            <DeleteOutlined />
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Well>
                    </React.Fragment>
                )}
            </Drawer>
        )
    }

    protected _onMapLoad = async (e) => {
        this.mapControl = e.target

        if (this.rule.value) {
            // Init mapbox polygon
            const polygonFeature = this.customLocationValueToGeoJson(this.rule.value)
            this.drawControl.add(polygonFeature)

            const bounds = this.rule.value.points.reduce(
                (b, coord) => {
                    return b.extend(coord)
                },
                new mapboxgl.LngLatBounds(this.rule.value.points[0], this.rule.value.points[0]),
            )

            this.mapControl.fitBounds(bounds, {
                padding: 20,
            })
        }
    }

    protected _onModeChange = async (e) => {
        if (e.mode === 'simple_select') {
            this.drawControl.changeMode('direct_select', { featureId: this.polygonId })
        }
    }

    protected _onPolygonCreate = async (e) => {
        const newValue = this.geoJsonToCustomLocationValue(e)
        const oldValue = this.state.workingValue
        this.polygonId = e.features[0].id

        // Each time mapbox changes modes it emits a creation event.
        // Check if the event value matches the existing value to avoid infinite loop
        if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
            await this.setState({
                drawMode: 'static',
                workingValue: newValue,
            })

            this.drawControl.changeMode('static')
        }
    }

    protected _onPolygonUpdate = async (e) => {
        const newValue = this.geoJsonToCustomLocationValue(e)
        await this.setState({
            workingValue: newValue,
        })
    }

    protected reset = async () => {
        this.polygonId = null
        await this.setState({
            drawMode: 'draw_polygon',
            workingValue: null,
        })
        this.drawControl.deleteAll()
        this.drawControl.changeMode('draw_polygon')
    }

    protected changeMode = async (evt: RadioChangeEvent) => {
        await this.setState({
            drawMode: evt.target.value,
        })

        if (evt.target.value === 'simple_select' || evt.target.value === 'direct_select') {
            this.drawControl.changeMode('direct_select', { featureId: this.polygonId })
        } else {
            this.drawControl.changeMode(evt.target.value)
        }
    }

    protected savePolygon = async () => {
        await this.setState({
            showDrawer: false,
        })

        this.rule.value = this.state.workingValue
        this.rule.operator = baseOperators.geo_includes.value
        this.props.onChange(this.rule)
    }

    protected cancelPolygonEdit = async () => {
        await this.setState({
            showDrawer: false,
        })
    }

    protected openDrawer = async () => {
        await this.setState({
            drawMode: 'static',
            showDrawer: true,
            workingValue: this.rule.value,
        })
    }

    protected handleDrawerSizeChange = async (bounds: any) => {
        await this.setState({
            preferredMapWidth: Math.round(bounds.windowWidth * 0.7),
            preferredMapHeight: bounds.elementHeight - 80,
        })
    }

    protected customLocationValueToGeoJson(customLocationValue: ICustomLocationValue): any {
        this.polygonId = 'active_polygon'
        return {
            id: this.polygonId,
            type: 'Feature',
            properties: {
                active: true,
            },
            geometry: {
                type: 'Polygon',
                coordinates: [customLocationValue.points],
            },
        }
    }

    protected geoJsonToCustomLocationValue(geojson: any): ICustomLocationValue {
        return {
            points: geojson.features[0].geometry.coordinates[0],
        }
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
