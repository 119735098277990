import React from 'react'
import './input-keyword-clicked.scss'
import { ChainSelect, IChainOption } from '../../../chain-select/chain-select-component'
import { getClassNames } from '../../../../_utils/classnames'
import { Tooltip, InputNumber, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { NoTranslate } from '../../../no-translate/no-translate'

type Scope = '7d' | '14d' | '30d' | '60d'

interface IMeta {
    scope: Scope
    expression: string
}

const AttributionOptions: IChainOption[] = [
    {
        display: 'within the last',
        value: 'prev',
        options: [
            {
                display: '7 Days',
                value: '7d',
            },
            {
                display: '14 Days',
                value: '14d',
            },
            {
                display: '30 Days',
                value: '30d',
            },
            {
                display: '60 Days',
                value: '60d',
            },
            {
                display: '90 Days',
                value: '90d',
            },
        ],
    },
]

const OperatorOptions = [
    {
        display: 'at least',
        value: 'gte',
    },
    {
        display: 'exactly',
        value: 'eq',
    },
    {
        display: 'at most',
        value: 'lte',
    },
]

interface IInputKeywordClickedValue {
    builderProperty: string
    property: string
    operator: string
    value: number | undefined
    meta: IMeta | undefined
}

interface IInputKeywordClickedProps {
    mode: 'display' | 'edit'
    field: any
    value: IInputKeywordClickedValue
    operator: any
    onChange: (value: any) => void
    loading?: boolean
    keywords: string[]
}

interface IState {
    value: number
    operator: string
    meta: IMeta
}

class InputKeywordClicked extends React.PureComponent<IInputKeywordClickedProps, IState> {
    public state: IState = {
        value: 1,
        operator: 'gte',
        meta: {
            scope: '7d',
            expression: undefined!,
        },
    }

    private inputRef: any

    public UNSAFE_componentWillMount() {
        this.setInitialState(this.props.value)
    }

    /**
     * Handles initial / cancel state reset
     */
    public UNSAFE_componentWillReceiveProps(
        prevProps: IInputKeywordClickedProps,
        nextProps: IInputKeywordClickedProps,
    ) {
        if (this.props.mode === 'display') {
            const {
                value: { value, operator, meta },
            } = prevProps
            if (
                value !== this.state.value ||
                operator !== this.state.operator ||
                meta?.scope !== this.state.meta.scope ||
                meta?.expression !== this.state.meta.expression
            ) {
                this.setInitialState(nextProps.value ?? prevProps.value)
            }
        }
    }

    public render() {
        const { mode, value: currValue, keywords: kwOptions, loading } = this.props

        const { meta, operator } = this.state

        const isDisplayMode = mode === 'display'

        const selectedOperator = OperatorOptions.find((op) => op.value === operator) ?? OperatorOptions[0]

        const scopeContainer = AttributionOptions[0]
        const scopeOption = scopeContainer.options?.find((opt) => opt.value === meta.scope)
        let scopeDisplayValue = `${scopeContainer.display}`
        if (!!scopeOption) {
            scopeDisplayValue += ` ${scopeOption.display}`
        }

        return (
            <div className={getClassNames('input-keyword-clicked', `${mode ?? 'display'}-mode`)}>
                <span className="operator-display">equals</span>

                {isDisplayMode ? (
                    <span className="value-display">
                        <NoTranslate>{meta.expression}</NoTranslate>
                    </span>
                ) : (
                    <Select<string>
                        ref={(el) => (this.inputRef = el)}
                        className="value-edit value-select"
                        showSearch={true}
                        placeholder="Select a keyword"
                        loading={loading}
                        value={meta.expression}
                        onChange={this.handleKeywordChange}
                    >
                        {kwOptions.map((kw) => (
                            <Select.Option key={kw} value={kw}>
                                <NoTranslate>{kw}</NoTranslate>
                            </Select.Option>
                        ))}
                    </Select>
                )}

                <span className="connector-display"> and was clicked </span>
                {isDisplayMode ? (
                    <span className="operator-display">{selectedOperator.display}</span>
                ) : (
                    <Select
                        className="operator-edit"
                        onChange={this.handlerOperatorChange}
                        value={selectedOperator.value}
                    >
                        {OperatorOptions.map((op) => (
                            <Select.Option key={op.value} value={op.value}>
                                {op.display}
                            </Select.Option>
                        ))}
                    </Select>
                )}

                {isDisplayMode ? (
                    <>
                        <span> {currValue.value} time(s) </span>
                        <span className="scope-display">{scopeDisplayValue}</span>
                    </>
                ) : (
                    <>
                        <InputNumber
                            onChange={this.handleNumberInputChange}
                            defaultValue={1}
                            value={this.state.value}
                            min={0}
                            max={999}
                            type="number"
                        />

                        <span> time(s) </span>

                        <span className="scope-edit">
                            <ChainSelect
                                mode={mode}
                                value={meta.scope}
                                options={AttributionOptions}
                                onChange={this.handleScopeChange}
                            />
                        </span>
                    </>
                )}

                <Tooltip title="Time frame does not include clicks from the current day.">
                    <InfoCircleOutlined className="info-icon" />
                </Tooltip>
            </div>
        )
    }

    protected setInitialState(props: IInputKeywordClickedValue) {
        this.setState((currState) => ({
            ...currState,
            value: props?.value ?? currState.value,
            operator: props?.operator ?? currState.operator,
            meta: {
                ...currState.meta,
                ...(props?.meta ?? {}),
            },
        }))
    }

    protected handleKeywordChange = (keyword: string) => {
        this.setState(
            (currState) => ({
                ...currState,
                meta: {
                    ...currState.meta,
                    expression: keyword,
                },
            }),
            this.emitChange,
        )
    }

    protected handlerOperatorChange = (operator: string) => {
        this.setState(
            (currState) => ({
                ...currState,
                operator,
            }),
            this.emitChange,
        )
    }

    protected handleNumberInputChange = (value: number | string) => {
        const timesClicked = value === '' ? undefined : parseInt(value.toString(), 10)

        this.setState(
            (currState) => ({
                ...currState,
                value: timesClicked!,
            }),
            this.emitChange,
        )
    }

    protected handleScopeChange = (scope: Scope) => {
        this.setState(
            (currState) => ({
                ...currState,
                meta: {
                    ...currState.meta,
                    scope,
                },
            }),
            this.emitChange,
        )
    }

    protected emitChange = () => {
        const changes = {
            field: this.props.field.property,
            operator: this.state.operator,
            value: this.state.value,
            meta: this.state.meta,
        }

        this.props.onChange(changes)
    }
}

export default InputKeywordClicked
