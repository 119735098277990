import * as React from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { getClassNames } from '../../_utils/classnames'
import { Card, Modal, Result, Button, Skeleton, Descriptions } from 'antd'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { useService } from '../../hooks/use-service'
import { DomainIntegrationService } from '../../services'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { simpleNotification } from '../../_utils/utils'
import { IIntegrationRow, IAmplyIntegration } from './interfaces'
import { DomainIntegrationProvider } from '../../enums/domain-integration-provider'
import StackedTagSelect from '../stacked-tag-select/stacked-tag-select'
import validator from 'validator'
import { setModalButtonLoading, setModalOkButtonProps } from '../../_utils/antd'
import { Loading3QuartersOutlined, LinkOutlined, DisconnectOutlined } from '@ant-design/icons'
import { handleResponseErrorMessage } from '../../_utils/response-error-utils'

const AmplyIntegration = (props: IIntegrationRow<IAmplyIntegration>) => {
    const { domainId, loading, integration } = props

    const history = useHistory()
    const runIfMounted = useMountedRef()[1]
    const svc = useService<DomainIntegrationService>(DomainIntegrationService)

    const [value, setValue] = useLoadableDataState({
        loading: false,
        data: integration,
    })

    const domainTagsRef = React.useRef<{ state: { value: string[] | undefined } }>()
    const provider = DomainIntegrationProvider.AMPLY
    const qs = queryString.parse(location.search)

    React.useEffect(() => {
        const processOauthCallback = async () => {
            if (qs.provider === provider && !integration) {
                setValue({
                    loading: true,
                    data: {
                        ...(value.data ?? ({} as any)),
                        isActive: true,
                        data: {
                            ad_domain_names: qs.ad_domain_names,
                        },
                    },
                })

                Modal.destroyAll()
                const modal = Modal.success({
                    className: 'completing-oauth-dialog',
                    title: 'Integration Finalizing',
                    icon: <span className="amply-logo-color" />,
                    okText: 'Complete',
                    cancelText: 'Cancel',
                    footer: null,
                    content: (
                        <div>
                            <Loading3QuartersOutlined spin={true} />
                        </div>
                    ),
                } as any)

                const res = await svc.completeIntegrationSetup(domainId, provider, qs, `${provider}-integration`)

                runIfMounted(() => {
                    setValue({
                        loading: false,
                        data: res.data,
                    })

                    if (res.ok) {
                        modal.update({
                            className: 'completed-oauth-dialog',
                            title: 'Integration Completed',
                            content: (
                                <Result
                                    status="success"
                                    title={false}
                                    subTitle={<p>Your Amply integration has been completed.</p>}
                                />
                            ),
                            okText: 'Close',
                            onOk: () => {
                                history.replace({ search: '', hash: 'integrations' })
                                modal.destroy()
                                simpleNotification('success', 'Integration successfully completed.')
                            },
                        })
                    } else {
                        modal.update({
                            className: 'failed-oauth-dialog',
                            title: 'Integration Failed',
                            content: (
                                <Result
                                    status="warning"
                                    title={false}
                                    subTitle="An error occurred during your integration and we were unable to complete the setup."
                                />
                            ),
                            onOk: () => {
                                history.replace({ hash: 'integrations', search: '' })
                                modal.destroy()
                            },
                        })
                    }
                })
            }
        }

        processOauthCallback()
    }, [qs.state])

    const createIntegration = () => {
        setValue({
            loading: true,
            data: undefined,
        })

        const createModal = Modal.confirm({
            className: 'setup-oauth-dialog amply-setup-dialog',
            title: 'Integration Setup',
            icon: <span className="amply-logo-color" />,
            okText: 'Connect',
            cancelText: 'Cancel',
            cancelButtonProps: { className: 'cancel-btn' },
            okButtonProps: { disabled: true },
            content: (
                <div className="amply-domains-form-item">
                    <span>Domain Names</span>
                    <StackedTagSelect<string[]>
                        ref={domainTagsRef as any}
                        className="amply-domains-select"
                        dropdownClassName="amply-domains-select-dropdown"
                        mode="tags"
                        placeholder="my.amply-domain.com"
                        tokenSeparators={[',']}
                        autoFocus={true}
                        showOptions={false}
                        onChange={(v) => {
                            setModalOkButtonProps(createModal, {
                                disabled: !v.length,
                            })
                        }}
                    />
                </div>
            ),
            onOk: (closeModal) => {
                setModalButtonLoading(createModal, true)

                const handleOk = async () => {
                    const selectRef = domainTagsRef.current!
                    const invalidUrls: string[] = []
                    let urls: string[] = selectRef.state.value ?? []

                    if (urls.length !== 0) {
                        urls = urls.map((url) => url.toString().trim()).filter((url) => !!url)
                        urls.forEach((url) => {
                            if (!validator.isURL(url)) {
                                invalidUrls.push(url)
                            }
                        })

                        if (invalidUrls.length) {
                            simpleNotification(
                                'error',
                                <div>
                                    <span>The following URLs are invalid</span>

                                    {invalidUrls.map((url) => (
                                        <div key={url}>{url}</div>
                                    ))}
                                </div>,
                            )
                        } else {
                            const redirectUrl = await svc.fetchIntegrationRedirectUrl(provider, domainId, {
                                ad_domain_names: urls.join(',').trim(),
                            })

                            if (!redirectUrl.ok) {
                                const err = redirectUrl.error as any
                                handleResponseErrorMessage(err)
                            } else {
                                const { authUrl } = redirectUrl.data!
                                window.location.href = authUrl
                            }
                        }
                    }

                    return false
                }

                handleOk()
                    .then((res) => {
                        if (res && closeModal) {
                            closeModal()
                        }

                        return res
                    })
                    .catch((err) => {
                        console.warn(err)
                    })
                    .then((ok) => {
                        if (!ok) {
                            setModalButtonLoading(createModal, false)
                        }
                    })
            },
            onCancel: () => {
                setValue({
                    loading: false,
                    data: {
                        ...(value.data ?? ({} as any)),
                        isActive: false,
                    },
                })
            },
        })
    }

    const removeIntegration = async () => {
        if (!!integration || !!value.data) {
            setValue({
                ...value,
                loading: true,
            })

            const res = await svc.removeIntegration(domainId, value.data!.id!)
            if (res.ok) {
                simpleNotification('success', 'Integration successfully removed.')
                runIfMounted(() => {
                    setValue({
                        loading: false,
                        data: undefined,
                    })
                })
            }
        }
    }

    const isActive = value.data?.isActive

    return (
        <Card
            size="small"
            className={getClassNames('domain-integration', 'shopify', {
                active: isActive,
                empty: !value.data?.id,
            })}
            title={<span className="amply-logo-color" />}
            extra={
                <Button
                    className={getClassNames(null, 'integration-toggle', 'enabled', {
                        linked: !!value.data?.id,
                    })}
                    size="small"
                    loading={loading || value.loading}
                    icon={!!value.data?.id ? <DisconnectOutlined /> : <LinkOutlined />}
                    onClick={() => (!!value.data?.id ? removeIntegration() : createIntegration())}
                >
                    <span>{loading || value.loading ? 'Loading' : !value.data?.id ? 'New Connection' : 'Remove'}</span>
                </Button>
            }
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false} paragraph={{ rows: 1 }}>
                {!!value.data?.id && (
                    <Descriptions layout="vertical" column={1}>
                        <Descriptions.Item label="Domain Names">
                            {(value.data?.data?.adDomainNames ?? []).map((dn) => (
                                <div key={dn}>{dn}</div>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Skeleton>
        </Card>
    )
}

export default AmplyIntegration
