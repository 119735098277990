import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import { StatusType } from '../../enums/status-type'
import { titleCase } from '../../_utils/utils'
import './status-badge.scss'

/*
    "expanded" view forces hidden tooltip
    and displays the status text to the right
    of the badge
 */
interface IProps extends IBadgeProps {
    status: string
    expanded?: boolean
}

export const CampaignStatusBadge: React.StatelessComponent<IProps> = (props) => {
    const hideTooltip = props.hideTooltip || props.expanded

    let text = props.status
    let icon = 'check-circle'

    switch (props.status) {
        case StatusType.ACTIVE.name:
            icon = 'build'
            text = 'RUNNING'
            break
        case StatusType.DRAFT.name:
            icon = 'file-exclamation'
            break
        case StatusType.PAUSED.name:
            icon = 'pause-circle'
            break
        default:
            break
    }

    const badge = (
        <span className={`status-icon status-${text}`}>
            <LegacyIcon type={icon} />
        </span>
    )

    return (
        <span className="status-badge campaign-status-badge">
            {hideTooltip ? (
                !props.expanded ? (
                    badge
                ) : (
                    <>
                        {badge}
                        <span className="status-name">{titleCase(text)}</span>
                    </>
                )
            ) : (
                <Tooltip title={props.tooltip || text}>{badge}</Tooltip>
            )}
        </span>
    )
}
