import * as deepEqual from 'react-fast-compare'
import { RuleBuilderFieldType } from '../rule-builder/rule-builder'
import { SegmentModel } from '../../models/segments/segment.model'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { stripUndefined } from '../../_utils/strip-undefined'
import { SegmentBuilderContext } from './segment-builder-context'
import { IOrgSegmentCreateDto, IOrgSegmentUpdateDto, ISegmentCreateDto, ISegmentUpdateDto } from './interfaces'

export function getCustomSegmentFieldDerivedType(field: any): RuleBuilderFieldType {
    /*
     TYPES:
     string, boolean
     number, range
     date, daterange
     select, multiselect
     campaign, preferences, device-placement,
     custom
    */

    let type: RuleBuilderFieldType
    switch (field.type) {
        case 'text':
        case 'keyword':
            type = 'string'
            break
        case 'long':
        case 'integer':
        case 'short':
        case 'byte':
        case 'double':
        case 'float':
        case 'half_float':
        case 'scaled_float':
        case 'numeric':
            type = 'number'
            break
        case 'date':
        case 'date_nanos': // NOTE: not sure on this one
            type = 'date'
            break
        case 'boolean':
            type = 'boolean'
            break
        case 'integer_range':
        case 'float_range':
        case 'long_range':
        case 'double_range':
            type = 'range'
            break
        case 'date_range':
            type = 'daterange'
            break
        case 'array':
            type = 'array'
            break
        default:
            type = 'custom'
            break
    }

    return type
}

export function isBuilderSegmentValid(segment: OrgSegmentModel | SegmentModel): boolean {
    return segment.getFiltersJson().and?.length ?? 0
}

export function getUnsafeExecutionText(context?: SegmentBuilderContext) {
    if (context?.isDrawerMode) {
        return `One or more conditions in this segment require additional processing time to build. Due to the 
                    additional processing time required, this segment can not be created from the notification creation 
                    page.`
    } else {
        return `One of more conditions in this segment require additional processing time to calculate the size. 
                    During this time the size estimate will not be shown and the audience will not be available for 
                    targeting.`
    }
}

/**
 * A segment may be unsafe to use if it contains a new calculated property that we haven't calculated yet.
 * In this case, we will not show the audience size, or allow using the segment, until the initial calculation
 * has run.
 */
export function isUnsafeExecution(context: SegmentBuilderContext) {
    const seg: any = context.segment
    if (seg) {
        const filtersJson = seg.getFiltersJson()

        if (filtersJson && 'and' in filtersJson) {
            return groupContainsUnsafeCondition(filtersJson.and, context)
        }
    }

    return false
}

const delayedUpdateProperties = [
    'behaviors.page_audience',
    'behaviors.page_tag_audience',
    'behaviors.tag_clicked_audience',
]

function groupContainsUnsafeCondition(group: any, context: SegmentBuilderContext) {
    let foundUnsafeCondition = false

    for (const condition of group) {
        const subGroup = condition.and || condition.or
        if (subGroup) {
            foundUnsafeCondition = groupContainsUnsafeCondition(subGroup, context)
        } else {
            const property = Object.keys(condition)[0]
            if (delayedUpdateProperties.includes(property)) {
                // fields are unsafe until validated
                foundUnsafeCondition = true

                const expressionToLower = condition[property].meta.expression.toLowerCase()
                for (const calculatedField of context.calculatedFields) {
                    if (calculatedField.name === property && calculatedField.expression === expressionToLower) {
                        if (calculatedField.lastCalculatedAt || calculatedField.last_calculated_at) {
                            foundUnsafeCondition = false
                        }
                        // immediately break out if any of the conditions are unsafe
                        break
                    }
                }
            }
        }

        if (foundUnsafeCondition) {
            break
        }
    }

    return foundUnsafeCondition
}

export function isSegment(segment: OrgSegmentModel | SegmentModel): segment is SegmentModel {
    return segment instanceof SegmentModel
}
export function isOrgSegment(segment: OrgSegmentModel | SegmentModel): segment is OrgSegmentModel {
    return segment instanceof OrgSegmentModel
}

interface ISortableOption {
    label: string
}
export function domainOptionsSort(a: ISortableOption, b: ISortableOption) {
    const devlabelRgx = /^\((test|dev|stage|staging)\)\s+?/i
    const aLabel = a.label.toLowerCase().trim()
    const aLabelClean = aLabel.replace(devlabelRgx, '').trim()
    const bLabel = b.label.toLowerCase().trim()
    const bLabelClean = bLabel.replace(devlabelRgx, '').trim()

    const cleanSort = aLabelClean > bLabelClean ? 1 : aLabelClean < bLabelClean ? -1 : 0
    const rawSort = aLabel < bLabel ? 1 : aLabel > bLabel ? -1 : 0
    return aLabelClean === bLabelClean ? rawSort : cleanSort
}

export function getSegmentCreateDto(segment: SegmentModel): ISegmentCreateDto
export function getSegmentCreateDto(segment: OrgSegmentModel, domainIds: number[]): IOrgSegmentCreateDto
export function getSegmentCreateDto(segment: OrgSegmentModel | SegmentModel, domainIds?: number[]) {
    return stripUndefined<ISegmentCreateDto | IOrgSegmentCreateDto>({
        name: segment.getName(),
        source: segment.getSource(),
        filters_json: segment.getFiltersJson(),
        icon_url: segment.getIconUrl(),
        channels: segment.getChannels(),
        domain_ids: domainIds,
    })
}

export function getSegmentUpdateDto(segment: SegmentModel, original: SegmentModel): ISegmentUpdateDto
export function getSegmentUpdateDto(
    segment: OrgSegmentModel,
    original: OrgSegmentModel,
    domainIds: number[],
): IOrgSegmentUpdateDto
export function getSegmentUpdateDto(
    segment: OrgSegmentModel | SegmentModel,
    original: OrgSegmentModel | SegmentModel,
    domainIds?: number[],
): ISegmentUpdateDto | IOrgSegmentUpdateDto {
    const dto = {} as ISegmentUpdateDto | IOrgSegmentUpdateDto

    const name = segment.getName().trim()
    if (name !== original.getName()?.trim?.() ?? null) {
        dto.name = name
    }

    const iconUrl = segment.getIconUrl()?.trim?.() ?? null
    if (iconUrl !== (original.getIconUrl()?.trim?.() ?? null)) {
        dto.icon_url = iconUrl
    }

    const filters = segment.getFiltersJson()
    if (!deepEqual(filters, original.getFiltersJson())) {
        dto.filters_json = filters
    }

    if (Object.keys(dto).length) {
        dto.id = segment.getId()
    }

    const channels = segment.getChannels()
    if (channels) {
        const currChannels = original.getChannels().sort()
        const nextChannels = channels.sort()

        if (!deepEqual(nextChannels, currChannels)) {
            dto.channels = nextChannels
        }
    }

    if (domainIds) {
        const nextDomainIds = Array.from(domainIds)
        nextDomainIds.sort()
        const currDomainIds = (original as OrgSegmentModel).getDomainIds()
        currDomainIds.sort()

        if (!deepEqual(nextDomainIds, currDomainIds)) {
            const orgDto = dto as IOrgSegmentUpdateDto
            orgDto.domain_ids = nextDomainIds
        }
    }

    return stripUndefined<ISegmentUpdateDto | IOrgSegmentUpdateDto>(dto)
}
