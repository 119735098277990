import { isAxiosCancellation } from '../config/axios-setup'
import { simpleNotification } from './utils'

export const hasErrorResponseObject = (error: any): boolean => {
    return typeof error === 'object' && typeof error.response === 'object' && error.response.status !== null
}

export const extractResponseErrorMessageOrNull = (error: any): string | null => {
    if (
        typeof error !== 'object' ||
        typeof error.response !== 'object' ||
        typeof error.response.data !== 'object' ||
        typeof error.response.data.message !== 'string'
    ) {
        return null
    }

    return error.response.data.message
}

type ResponseHandlingOptions = {
    fallbackMessage?: string
    onCancelled?(): void
}

export const handleResponseErrorMessage = (error: any, options?: ResponseHandlingOptions) => {
    if (isAxiosCancellation(error)) {
        options?.onCancelled?.()
        return
    }

    const resErrorMessage = extractResponseErrorMessageOrNull(error)
    if (!resErrorMessage && !options?.fallbackMessage) {
        // Non-response/non-friendly errors should not be shown to users
        console.error(error)
        return
    }

    simpleNotification('error', resErrorMessage ?? options?.fallbackMessage)
}
