import * as React from 'react'
import * as CopyToClipboard from 'react-copy-to-clipboard'
import autobind from 'autobind-decorator'
import { isFunction } from '../../../_utils/utils'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'

interface IProps {
    responseData: any
    showMessage?: boolean
    message?: string | ((data: any) => string)
    type?: 'invite' | 'password'
}

interface IState {
    copied: boolean
}

export class InviteSuccessMessage extends BetterComponent<IProps, IState> {
    public state: IState = {
        copied: false,
    }

    protected readonly appState: AppState

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const type = this.props.type || 'invite'
        const path = type === 'invite' ? 'confirm-invite' : 'reset-password'

        const copyMessage = this.state.copied ? (
            <b>{type === 'invite' ? 'Invitation' : 'Password reset'} link copied</b>
        ) : (
            <a>Copy {type === 'invite' ? 'invitation' : 'password reset'} link</a>
        )

        let message = 'User was successfully saved.'
        if (this.props.message) {
            message = isFunction(this.props.message) ? this.props.message(this.props.responseData) : this.props.message
        }

        return (
            <span>
                {this.props.showMessage && message}
                <div>
                    <CopyToClipboard
                        onCopy={this.setCopiedState}
                        text={`${this.appState.platformHost}/${path}?token=${this.props.responseData.token}`}
                    >
                        {copyMessage}
                    </CopyToClipboard>
                </div>
            </span>
        )
    }

    protected setCopiedState = () => {
        this.setState({ copied: true })
    }
}
