import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import './403.scss'
import { Button } from '@pushly/aqe/lib/components'

interface IProps {
    loginError?: boolean
}

interface IState {}

export class AccessDenied403 extends BetterComponent<IProps, IState> {
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        return (
            <div className="pufferfish-403">
                Access Denied
                <div>
                    <small>
                        If you feel you have reached this page in error please reach out to your account manager.
                    </small>
                </div>
                {this.props.loginError && (
                    <Button type="primary" onClick={() => location.assign('/')}>
                        Return to Login
                    </Button>
                )}
            </div>
        )
    }
}
