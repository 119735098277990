import React from 'react'
import classnames from 'classnames'
import { Select } from 'antd'

export type DomainSelectorOption = {
    label: string
    title: string
    value: number
}
export interface IDomainAudienceSelect {
    disabled?: boolean
    value?: number[]
    domainSelectorOptions: DomainSelectorOption[]
    onChange: (selections: number[]) => void
    onClear: () => void
    onDeselect?: (selection: number) => void
    onDropdownVisibleChange?: (visible: boolean) => void
    handleDomainIdsChange: (domainIds: number[], requiresValidation?: boolean) => void
    domainIdChangeRequiresValidation?: boolean
}

const DomainAudienceSelect = (props: IDomainAudienceSelect) => {
    const [search, setSearch] = React.useState<string>('')
    const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false)

    const {
        value,
        disabled,
        domainSelectorOptions,
        handleDomainIdsChange,
        onChange,
        onClear,
        onDeselect,
        onDropdownVisibleChange,
        domainIdChangeRequiresValidation,
    } = props

    return (
        <Select<number[]>
            allowClear={true}
            onClear={onClear}
            className={classnames('org-notification-domain-audience-select', 'included-audience-select')}
            disabled={disabled}
            value={value}
            mode="multiple"
            placeholder="Select at least one domain to include"
            optionFilterProp="title"
            optionLabelProp="title"
            filterOption={(input: string, option: (typeof domainSelectorOptions)[number]) =>
                option.label.toString().toLowerCase().includes(input.toLowerCase())
            }
            onSearch={(v) => {
                setSearch(v)
            }}
            searchValue={search}
            options={domainSelectorOptions}
            dropdownClassName="org-notification-domain-audience-select-dropdown"
            open={dropdownOpen}
            onDeselect={(selection) => {
                if (onDeselect && !dropdownOpen) {
                    onDeselect(selection)
                }
            }}
            onDropdownVisibleChange={(open) => {
                setDropdownOpen(open)
                if (onDropdownVisibleChange) onDropdownVisibleChange(open)
            }}
            dropdownRender={(menu) => {
                if (search === '' || search === undefined) {
                    return menu
                }

                const matches = domainSelectorOptions.filter((child) => {
                    return child.label.toLowerCase().includes(search.toLowerCase())
                })

                if (!matches.length) {
                    return menu
                }
                return (
                    <>
                        <div
                            className="domain-select-all-option ant-select-item ant-select-item-option"
                            onClick={() => {
                                const currValue = value
                                let valueChange

                                if (domainSelectorOptions.length) {
                                    if (search !== '') {
                                        valueChange = domainSelectorOptions
                                            .filter((child) => {
                                                return child.label.toLowerCase().includes(search.toLowerCase())
                                            })
                                            .map((val) => val.value)
                                    } else {
                                        valueChange = domainSelectorOptions.map((child) => child.value)
                                    }
                                }

                                const uqValueChange = Array.from(new Set([...(currValue ?? []), ...valueChange]))

                                if (uqValueChange?.length) {
                                    handleDomainIdsChange(uqValueChange, domainIdChangeRequiresValidation)
                                    setSearch('')
                                    setDropdownOpen(false)
                                }
                            }}
                        >
                            <div>Select All</div>
                        </div>
                        {menu}
                    </>
                )
            }}
            onChange={onChange}
        />
    )
}

export default DomainAudienceSelect
