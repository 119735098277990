import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export class NotificationWebContentModel {
    public static build(props: NotificationWebContentModel | any): NotificationWebContentModel {
        const data = props instanceof NotificationWebContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationWebContentModel()
        model.setTitle(scData.title)
        model.setBody(scData.body)
        model.setLandingUrl(scData.landing_url)
        model.setImageUrl(scData.image_url)
        model.setIconUrl(scData.icon_url)

        return model
    }

    private title: string | undefined
    private body: string | undefined | null
    private landingUrl: string | undefined
    private imageUrl: string | undefined | null
    private iconUrl: string | undefined | null

    public clone(): NotificationWebContentModel {
        return NotificationWebContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            title: this.getTitle(),
            body: this.getBody(),
            landing_url: this.getLandingUrl(),
            image_url: this.getImageUrl(),
            icon_url: this.getIconUrl(),
        })
    }

    public getTitle(): string | undefined {
        return this.title
    }
    public setTitle(title: string | undefined): void {
        this.title = title
    }

    public getBody(): string | undefined | null {
        return this.body
    }
    public setBody(body: string | undefined): void {
        this.body = body
    }

    public getLandingUrl(): string | undefined {
        return this.landingUrl
    }
    public setLandingUrl(landingUrl: string | undefined): void {
        this.landingUrl = landingUrl
    }

    public getIconUrl(): string | undefined | null {
        return this.iconUrl
    }
    public setIconUrl(iconUrl: string | undefined | null): void {
        this.iconUrl = iconUrl
    }

    public getImageUrl(): string | undefined | null {
        return this.imageUrl
    }
    public setImageUrl(imageUrl: string | undefined | null): void {
        this.imageUrl = imageUrl
    }
}
