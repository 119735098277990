import React from 'react'
import { CampaignNotificationCompTabState } from './interfaces'

export type CampaignNotificationsComparisonContext = CampaignNotificationCompTabState & {
    onRefreshClick(ev: React.MouseEvent<HTMLElement>): Promise<void>
    onRefreshEnabledChange(enabled: boolean): void
    onCompareClick(): void
}

export const CampaignNotificationsComparisonContext = React.createContext<CampaignNotificationsComparisonContext>(
    {} as CampaignNotificationsComparisonContext,
)
