import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationTestEvaluationScheduleModel } from './notification-test-evaluation-schedule.model'

export class NotificationTestConfigurationModel {
    public static build(props: NotificationTestConfigurationModel | any): NotificationTestConfigurationModel {
        const data = props instanceof NotificationTestConfigurationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationTestConfigurationModel()
        model.setId(scData.id)
        model.setName(scData.name)
        model.setTestTypes(scData.test_types ?? model.getTestTypes())
        // delivery_type used in api response
        model.setDistributionType(scData.delivery_type ?? scData.distribution_type ?? model.getDistributionType())
        model.setDistributionMap(scData.distribution_map)
        model.setCtrThreshold(scData.ctr_threshold)
        model.setEvaluationSchedule(data.evaluationSchedule ?? data.evaluation_schedule)
        // pull meta from non case-modified source
        model.setDisplayMeta(data.displayMeta ?? data.display_meta)

        return model
    }

    private id: number | undefined
    private name: string | undefined
    private testTypes: string[] = ['content']
    private distributionType: string | undefined = 'split'
    private distributionMap: number[] | undefined
    private ctrThreshold: number | undefined
    private evaluationSchedule: NotificationTestEvaluationScheduleModel | undefined
    private displayMeta: any

    public clone(): NotificationTestConfigurationModel {
        return NotificationTestConfigurationModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            id: this.getId(),
            name: this.getName(),
            test_types: this.getTestTypes(),
            distribution_type: this.getDistributionType(),
            distribution_map: this.getDistributionMap(),
            ctr_threshold: this.getCtrThreshold(),
            evaluation_schedule: this.getEvaluationSchedule()?.serialize(),
            display_meta: this.getDisplayMeta(),
        })
    }

    public getId(): number | undefined {
        return this.id
    }
    public setId(id: number | undefined): void {
        this.id = !id ? undefined : id
    }

    public getName(): string | undefined {
        return this.name
    }
    public setName(name: string | undefined): void {
        this.name = !name ? undefined : name
    }

    public getTestTypes(): string[] {
        return this.testTypes ?? []
    }
    public setTestTypes(types: string[] | undefined): void {
        this.testTypes = !types ? [] : types
    }

    public getDistributionType(): string | undefined {
        return this.distributionType
    }
    public setDistributionType(type: string | undefined): void {
        this.distributionType = !type ? undefined : type
    }

    public getDistributionMap(): number[] | undefined {
        return this.distributionMap
    }
    public setDistributionMap(map: number[] | undefined): void {
        this.distributionMap = !map ? undefined : map
    }

    public getCtrThreshold(): number | undefined {
        return this.ctrThreshold
    }
    public setCtrThreshold(threshold: number | undefined): void {
        this.ctrThreshold = threshold === undefined ? undefined : threshold
    }

    public getEvaluationSchedule(): NotificationTestEvaluationScheduleModel | undefined {
        return this.evaluationSchedule
    }
    public setEvaluationSchedule(schedule: NotificationTestEvaluationScheduleModel | any | undefined): void {
        this.evaluationSchedule =
            schedule === undefined ? undefined : NotificationTestEvaluationScheduleModel.build(schedule)
    }

    public getDisplayMeta(): any {
        return this.displayMeta
    }
    public setDisplayMeta(meta: any): void {
        this.displayMeta = !meta ? undefined : meta
    }
}
