import React from 'react'
import classnames from 'classnames'
import { Button, TablePaginationConfig } from 'antd'

export function showTotal(total: number, range: [number, number]): React.ReactNode {
    return `Showing ${range[0]}-${range[1]} segment(s)`
}

export function itemRender(
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>,
): React.ReactNode {
    if (type === 'prev') {
        return (
            <Button size="small">
                <span>Previous</span>
            </Button>
        )
    }
    if (type === 'next') {
        return (
            <Button size="small">
                <span>Next</span>
            </Button>
        )
    }

    return originalElement
}

export function getTablePaginationConfig(config: Partial<TablePaginationConfig>): TablePaginationConfig {
    return {
        size: 'small',
        defaultCurrent: 1,
        current: 1,
        defaultPageSize: 20,
        pageSize: 20,
        itemRender,
        showTotal,
        simple: true,
        hideOnSinglePage: false,
        showSizeChanger: false,
        showLessItems: false,
        showQuickJumper: false,
        ...config,
        className: classnames('sw-table-pagination', 'ant-table-pagination', 'ant-table-pagination-right'),
    }
}
