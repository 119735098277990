import moment from 'moment-timezone'

export const roundToNearest = (num: number, increment: number) => {
    let rounded: number

    if (num % increment === 0) {
        rounded = parseInt(Math.floor(num / increment).toString(), 10) * increment
    } else {
        rounded = parseInt(Math.floor(num / increment).toString(), 10) * increment + increment
    }

    return rounded
}

export const fmtDateString = (date: string, format: string, tz?: string) => {
    const dateObj = new Date(date)
    const mm = !tz ? moment(dateObj) : moment.tz(dateObj, tz)
    return mm.format(format ?? 'LLL')
}
