import { DayOfWeek, RecurrenceSchedule } from '../models/campaign/recurrence-schedule'
import { titleCase } from './utils'
import moment from 'moment-timezone'

const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
}

const transformer = {
    monday: 'Mon',
    tuesday: 'Tues',
    wednesday: 'Wed',
    thursday: 'Thurs',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
}

export function buildRelativeLanguageSchedule(schedule: RecurrenceSchedule) {
    const scheduleType = schedule?.getType()
    let scheduleTypeDisplay =
        scheduleType === 'daily' || scheduleType === 'weekly' ? titleCase(scheduleType) : 'Monthly'

    const allowedDays = schedule.getAllowedDays()
    let allowedDaysDisplay = 'any day'

    const freq = schedule.getFrequency()

    if (allowedDays) {
        allowedDaysDisplay = buildAllowedDays(allowedDays)
        if (freq && freq > 1) {
            scheduleTypeDisplay = `Every ${freq} ${scheduleType === 'daily' ? 'days' : 'weeks'}`
        } else {
            scheduleTypeDisplay = ``
        }
    } else if (scheduleType === 'monthly_relative') {
        const relativeType = schedule.getRelativeType()

        if (relativeType === 'relative_day') {
            const day = schedule.getRelativeDay()
            const ordinal = schedule.getRelativeDayOrdinal()
            allowedDaysDisplay = `${ordinal} ${titleCase(day as string)}`
        } else {
            allowedDaysDisplay = `the ${relativeType!.split('_').join(' ')}`
        }
    } else if (scheduleType === 'monthly_specific') {
        const specificDay = schedule.getSpecificDay()!.toString()
        let superScript = 'th'
        switch (specificDay[specificDay.length - 1]) {
            case '1':
                superScript = 'st'
                break
            case '2':
                superScript = 'nd'
                break
            case '3':
                superScript = 'rd'
                break
            default:
                break
        }
        allowedDaysDisplay = `the ${specificDay}${superScript}`
    }

    let determiner =
        scheduleType === 'monthly_specific' && schedule.getRelativeType() === 'relative_day'
            ? 'every'
            : scheduleType === 'daily' || scheduleType === 'weekly'
            ? `on`
            : 'on the'
    if (freq && freq === 1) {
        determiner = ''
    }

    const specificTime = moment(schedule?.getSpecificTime(), 'H:mm').format('h:mm A')

    return `${scheduleTypeDisplay} ${determiner} ${allowedDaysDisplay}${
        scheduleType === 'daily'
            ? `${
                  allowedDaysDisplay === 'Wed' || allowedDaysDisplay[allowedDaysDisplay.length - 3] === 'W'
                      ? 'nesdays'
                      : allowedDaysDisplay === 'Sat' || allowedDaysDisplay[allowedDaysDisplay.length - 3] === 'S'
                      ? 'urdays'
                      : 'days'
              }`
            : ''
    } 
        at ${specificTime}`
}

function buildAllowedDays(days: DayOfWeek[]) {
    const allowedDays = days.sort((a, b) => sorter[a] - sorter[b]).map((day) => transformer[day])
    let lastDay
    if (allowedDays.length === 7) {
        return ''
    } else {
        if (allowedDays.length > 1) {
            lastDay = allowedDays.length > 1 ? allowedDays.pop() : allowedDays[0]
        }
    }

    return allowedDays.join(', ') + (allowedDays.length > 1 ? ' and ' + lastDay : '')
}
