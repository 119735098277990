import * as moment from 'moment-timezone'
import { Moment } from 'moment'
import { RangeValueRecordWithPreset } from '../../../types/range-value'

export const TIME_RANGE_OPTIONS: RangeValueRecordWithPreset<Moment> = {
    ['Today']: () => [moment(), moment(), 'TODAY'],
    ['Yesterday']: () => [moment().subtract(1, 'd'), moment().subtract(1, 'd'), 'YESTERDAY'],
    ['Last 7 Days']: () => [moment().subtract(8, 'd'), moment().subtract(1, 'd'), 'LAST_7_DAYS'],
    ['Last 30 Days']: () => [moment().subtract(31, 'd'), moment().subtract(1, 'd'), 'LAST_30_DAYS'],
    ['Last 45 Days']: () => [moment().subtract(46, 'd'), moment().subtract(1, 'd'), 'LAST_45_DAYS'],
    ['Last 90 Days']: () => [moment().subtract(91, 'd'), moment().subtract(1, 'd'), 'LAST_90_DAYS'],
    ['This Month']: () => [moment().startOf('month'), moment().endOf('month'), 'THIS_MONTH'],
    ['Last Month']: () => [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
        'LAST_MONTH',
    ],
    ['This Year']: () => [moment().startOf('year'), moment(), 'THIS_YEAR'],
    ['Last Year']: () => [
        moment().subtract(1, 'year').startOf('year'),
        moment().subtract(1, 'year').endOf('year'),
        'LAST_YEAR',
    ],
    ['All Time']: () => [null, null],
}
