import * as React from 'react'
import { useService } from '../../../hooks/use-service'
import type { ILoadableDataState } from '../../../hooks/use-loadable-data-ref-state'
import { AccountService } from '../../../services'
import { AccountDto } from '../../../dtos/account.dto'
import { ApiCallOptions } from '@pushly/aquarium-utils/lib/react'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'

interface IOrgNotificationLoaderProps {
    orgId: number
    opts?: ApiCallOptions
    children: (notification: React.MutableRefObject<ILoadableDataState<AccountDto | undefined>>) => JSX.Element
}

const OrgLoader = ({ orgId, opts, children }: IOrgNotificationLoaderProps) => {
    const svc = useService<AccountService>(AccountService)
    const [loader, setLoader] = useLoadableDataRefState<AccountDto | undefined>({ loading: true })

    React.useEffect(() => {
        const loadData = async () => {
            setLoader({ ...loader, loading: true })
            const update: any = { loading: false }

            if (orgId) {
                update.data = await svc.fetchById(orgId, opts?.query, opts?.showLoadingScreen, opts?.cancellationKey)
            }

            setLoader(update)
        }

        loadData()
    }, [orgId])

    return children(loader)
}

export default OrgLoader
