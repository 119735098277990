// noinspection TsLint

type StatusName =
    | 'ACTIVE'
    | 'INVITED'
    | 'DISABLED'
    | 'PAUSED'
    | 'DELETED'
    | 'SCHEDULED'
    | 'QUEUED'
    | 'DELIVERING'
    | 'COMPLETED'
    | 'FAILED'
    | 'PASSWORD_RESET'
    | 'CANCELLED'
    | 'COMPLETED_WITH_FAILURES'
    | 'DRAFT'
    | 'CANCELLING'
    | 'SCHEDULING'

export interface IStatusTypeValue {
    id: number
    name: string
    label?: string
}

export const StatusType: Record<StatusName, IStatusTypeValue> = {
    ACTIVE: { name: 'ACTIVE', id: 1 },
    INVITED: { name: 'INVITED', id: 2 },
    DISABLED: { name: 'DISABLED', id: 3 },
    PAUSED: { name: 'PAUSED', id: 4 },
    DELETED: { name: 'DELETED', id: 5 },
    SCHEDULED: { name: 'SCHEDULED', id: 6 },
    QUEUED: { name: 'QUEUED', id: 7 },
    DELIVERING: { name: 'DELIVERING', id: 8 },
    COMPLETED: { name: 'COMPLETED', label: 'Delivered', id: 9 },
    FAILED: { name: 'FAILED', id: 10 },
    PASSWORD_RESET: { name: 'PASSWORD_RESET', id: 11 },
    CANCELLED: { name: 'CANCELLED', id: 12 },
    COMPLETED_WITH_FAILURES: { name: 'COMPLETED_WITH_FAILURES', label: 'Delivered', id: 13 },
    DRAFT: { name: 'DRAFT', label: 'Draft', id: 14 },
    CANCELLING: { name: 'CANCELLING', label: 'Cancelling', id: 15 },
    SCHEDULING: { name: 'SCHEDULING', label: 'Scheduling', id: 16 },
}

export const HOLD_OUT_COMPUTED_STATUS = 'HOLD_OUT'
