import * as React from 'react'
import './style/campaign-builder.scss'
import * as clone from 'clone'
import * as deepEqual from 'react-fast-compare'
import { actions, FlowChart, IChart, ILink, INode } from '@mrblenny/react-flow-chart'
import mapValues from '@mrblenny/react-flow-chart/dist/container/utils/mapValues'
import { NodeEditor } from './node-editor'
import { CanvasOuter } from './canvas-outer'
import { CanvasInner } from './canvas-inner'
import { Node } from './node'
import { NodeInner } from './node-inner'
import { Port } from './port'
import { Link } from './link'
import {
    ActionType,
    CampaignBuilderMode,
    CampaignEditableState,
    ConditionSubType,
    ConditionType,
    DelayType,
    NodeType,
    TriggerType,
} from './enums'
import { addNode, createNode, removeNode } from './helpers/node'
import { calculateChartPositions } from './helpers/positioning'
import { Modal } from '@pushly/aqe/lib/components'
import {
    generateEmptyCampaign,
    getEditableState,
    parseCampaignFromChart,
    parseChartFromCampaign,
} from './helpers/campaign'
import { RemoveBooleanConditionConfirmationModal } from './modals/remove-boolean-condition-confirmation.modal'
import { RemoveBranchConditionConfirmationModal } from './modals/remove-branch-condition-confirmation.modal'
import { getClassNames } from '../../_utils/classnames'
import { CURRENT_BUILDER_ITERATION } from './constants'
import { simpleNotification } from '../../_utils/utils'
import { InsightsService } from '../../services/insights'
import { Container } from 'typescript-ioc/es5'
import { SegmentService } from '../../services/segment'
import { AppService } from '../../services'
import { StatusType } from '../../enums/status-type'
import { EcommItemPickStrategy } from '../../enums/ecomm-item-pick-strategy'
import { RemoveStepConfirmationModal } from './modals/remove-step-confirmation-modal'
import { orderCampaignSteps } from '../../_utils/campaigns'
import { CampaignNode } from './interfaces'

interface ICampaignBuilder {
    mode: CampaignBuilderMode
    loading?: boolean
    domain?: any
    campaign?: any
    showAmplyExperience?: boolean
    value?: any
    onChange?: (value: any) => any
    onSubmit?: (value: any, remove?: boolean) => any
    disableRouterPrompt?: boolean
    title?: string | React.ReactNode
    revision?: any
}

interface IInternalBuilderState {
    activeNode?: CampaignNode
    activeBranchId?: string
    endDateEnabled?: boolean
    loading: boolean
    _originalCampaign?: any
    campaign?: any
    campaignParsing?: boolean
    campaignParsed?: boolean
    loadingEsSegmentFields: boolean
    esSegmentFields?: any[]
    remove?: boolean
}

interface ICampaignBuilderState extends IChart {
    builder: IInternalBuilderState
    removedSteps: any[]
    showRemoveModal?: boolean
}

export class CampaignBuilder extends React.Component<ICampaignBuilder, ICampaignBuilderState> {
    protected stateActions: any = mapValues(
        { ...actions },
        (func: any) =>
            (...args: any) =>
                this.setState(func(...args)),
    )

    protected canvasInnerRef: any
    protected components: any = {}

    private readonly appService: AppService
    private readonly segmentService: SegmentService
    private readonly insightsService: InsightsService
    private unmounting: boolean = false

    constructor(props: ICampaignBuilder) {
        super(props)

        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)
        this.segmentService = Container.get(SegmentService)

        this.state = {
            ...this.propsValue,
            removedSteps: [],
            builder: {
                loading: false,
                loadingEsSegmentFields: true,
                campaign: this.props.campaign ?? {},
                remove: true,
            },
        }

        this.initializeCustomComponents()
    }

    public componentDidMount(): void {
        this.components.Canvas?.setState({ loading: this.props.loading })

        this.wireCanvasWellEvents()
        this.ensureDomainSegmentFieldsLoaded()
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.unwireCanvasWellEvents()
    }

    public async componentDidUpdate(prevProps: Readonly<ICampaignBuilder>): Promise<void> {
        // update canvas loading state
        if (prevProps.loading !== this.props.loading) {
            this.components.Canvas?.setState({ loading: this.props.loading })
        }

        const propsCampaignHasNotBeenParsed =
            (!this.state.builder?.campaignParsed || !prevProps.campaign) && !!this.props.campaign
        const propsRevisionHasChanged = prevProps.campaign?.revision?.id !== this.props.campaign?.revision?.id

        // reparse campaign
        if ((propsRevisionHasChanged && this.props.campaign) || propsCampaignHasNotBeenParsed) {
            await this.parsePassedCampaign()
        }
    }

    public render() {
        const chart = calculateChartPositions(this.currentValue)
        const editableState = this.props.mode === 2 ? CampaignEditableState.READONLY : this.editableState
        const changeState = this.detectChanges()

        chart.properties = {
            ...chart.properties,
            editableState,
        }

        const readonly = this.props.mode === CampaignBuilderMode.READONLY || false
        if (readonly) {
            chart.properties = {
                ...chart.properties,
                editableState: CampaignEditableState.READONLY,
            }
        }

        let activeNode = this.state.builder.activeNode
        if (activeNode?.type === NodeType.ACTION) {
            const orderedSteps = orderCampaignSteps(this.state.builder.campaign.steps ?? [])
            const orderedActionSteps = orderedSteps.filter((step) => step?.type === NodeType.ACTION)

            activeNode.actionIndex = orderedActionSteps.findIndex((step) => step.id === activeNode!.id)
        }

        return (
            <div
                className={getClassNames('campaign-builder', {
                    [`campaign-completed`]: editableState === CampaignEditableState.COMPLETED,
                    [`campaign-running`]: editableState === CampaignEditableState.RUNNING,
                    [`campaign-editable`]: editableState === CampaignEditableState.EDITABLE,
                })}
            >
                {/* TODO: investigate custom prompt wrapper for custom button text or save functionality on "Ok" */}
                {/*<Prompt*/}
                {/*    when={!this.props.disableRouterPrompt && changeState.changesDetected}*/}
                {/*    message="You have made changes to this campaign that are not yet saved. Do you want to save now?"*/}
                {/*/>*/}

                <FlowChart
                    config={{
                        readonly: true,
                        linkConfig: {
                            shape: 'linear',
                            outline: false,
                            marker: {
                                to: 'arrow',
                            },
                        },
                        zoomConfig: {
                            options: {
                                maxScale: 1,
                            },
                            pan: {
                                disableOnTarget: [
                                    getClassNames('campaign-canvas-node'),
                                    getClassNames('campaign-canvas-node-inner'),
                                    'node-content',
                                    'node-title',
                                    'node-description',
                                    'remove-node-toggle',
                                    getClassNames('campaign-canvas-link-options'),
                                    getClassNames('link-option-row'),
                                    getClassNames('expanded-options'),
                                    getClassNames('expanded-options-wrapper'),
                                    'btn-toggle-options',
                                    'btn-add-delay',
                                    'btn-add-action',
                                    'btn-add-filter',
                                    'node-stats',
                                    'anticon',
                                ],
                            } as any,
                            wheel: {
                                // Forces use of ctrl + wheel
                                wheelEnabled: false,
                            },
                            zoomOut: {
                                step: 4,
                            },
                            zoomIn: {
                                step: 4,
                            },
                            onPanning: () => {
                                this.components.Canvas.hideInstructions()
                            },
                            onZoomChange: () => {
                                this.components.Canvas.hideInstructions()
                            },
                        },
                    }}
                    Components={{
                        ...this.components,
                    }}
                    chart={chart}
                    callbacks={this.stateActions}
                />

                <NodeEditor
                    key={`node-${this.state.builder.activeNode?.id ?? '0'}`}
                    visible={!!this.state.builder.activeNode && !this.state.showRemoveModal}
                    domain={this.props.domain}
                    chart={chart}
                    node={this.state.builder.activeNode}
                    branchId={this.state.builder.activeBranchId}
                    onClose={this.handleEditorClose}
                    onSubmit={this.handleUpdateNode}
                    editableState={editableState}
                    esSegmentFields={this.state.builder.esSegmentFields}
                    readonly={this.editableState === CampaignEditableState.READONLY}
                />

                <RemoveStepConfirmationModal
                    changeSet={this.detectChanges()}
                    visible={this.state.showRemoveModal!}
                    onConfirm={this.handleStepModalConfirm}
                    onCancel={this.handleStepModalClose}
                />

                <div id="53279360-fde8-5948-a415-2d5c90f418sw" className={getClassNames('campaign-pos-shadow')} />
            </div>
        )
    }

    protected get propsValue(): IChart {
        return this.props.value ?? generateEmptyCampaign()
    }

    protected get currentValue(): IChart {
        return this.state
    }

    protected get editableState(): CampaignEditableState {
        const state = getEditableState(this.props.campaign)
        if (this.props.mode === CampaignBuilderMode.READONLY) {
            return CampaignEditableState.READONLY
        } else {
            return state < 2 ? CampaignEditableState.EDITABLE : CampaignEditableState.READONLY
        }
    }

    protected wireCanvasWellEvents() {
        this.appService.getAppContainer().addEventListener('wheel', this.handleWheel)
    }

    protected unwireCanvasWellEvents() {
        this.appService.getAppContainer()?.removeEventListener('wheel', this.handleWheel)
    }

    protected handleWheel = async (ev) => {
        if (ev.path) {
            const match = ev.path.some(
                (path) =>
                    /aqe-well-body/.test(path.className) &&
                    /campaign-canvas-outer/.test(path.offsetParent?.className ?? ''),
            )

            if (match && !ev.ctrlKey) {
                this.components.Canvas?.showInstructions()
            }
        }
    }

    protected initializeCustomComponents(): void {
        this.components = {
            CanvasOuter: CanvasOuter({
                ref: (el) => (this.components.Canvas = el),
                getIsLoading: () => this.props.loading ?? false,
                getZoomElementRef: () => this.canvasInnerRef,
                onClear: this.handleChartReset,
                getEditableState: () => this.editableState,
                getSubmitDisabledState: this.getSubmitDisabledState,
                getDomain: () => this.props.domain,
                getCampaign: () => this.props.campaign,
                title: this.props.title,
                onSubmit: this.handleEditorSaveClick,
            }),
            CanvasInner: CanvasInner({
                setRef: (el) => (this.canvasInnerRef = el),
            }),
            Node: Node({
                onClick: this.handleClickNode,
                getEditableState: () => this.editableState,
            }),
            NodeInner: NodeInner({
                getCampaign: () => this.props.campaign,
                getEditableState: () => this.editableState,
                onRemove: this.handleRemoveNode,
                onBranchClick: this.handleClickNode,
            }),
            Port: Port(),
            Link: Link({
                onAddNode: this.handleAddNode,
                getDomain: () => this.props.domain,
                getCampaign: () => this.props.campaign,
                getShowAmplyExperience: () => this.props.showAmplyExperience ?? false,
                getEditableState: () => this.editableState,
            }),
        }
    }

    protected handleDetailsChange = async (details: any) => {
        await this.setState(({ builder }) => ({
            builder: {
                ...builder,
                campaign: {
                    ...builder.campaign,
                    ...details,
                },
            },
        }))

        this.emitChange(this.currentValue)
    }

    protected handleChartReset = () => {
        const emptyCampaign = generateEmptyCampaign()

        const state: any = {
            ...emptyCampaign,
            builder: {
                ...this.state.builder,
                activeNode: undefined,
                activeBranchId: undefined,
            },
        }

        this.setState(state)
        this.emitChange(state)
    }

    protected handleAddNode = (nodeType: NodeType, link: ILink, actionType?: ActionType) => {
        const campaign = this.props.campaign
        const campaignType = campaign?.configuration?.computed_trigger_type
        const isEcommAbandonedCampaign =
            campaignType === TriggerType.ABANDONED_CART || campaignType === TriggerType.ABANDONED_VIEW

        // Initialize bas property set
        const nodeProperties: any = {
            [nodeType]: {
                params: {},
            },
        }

        if (nodeType === NodeType.CONDITION) {
            // Currently only supporting BRANCH conditions
            nodeProperties[nodeType] = {
                type: ConditionType.BRANCH,
                sub_type: ConditionSubType.PASSIVE,
            }
        } else if (nodeType === NodeType.ACTION) {
            if (CURRENT_BUILDER_ITERATION < 2) {
                const isInitialSendStep = !campaign.steps.some((step) => step.type === 'action')
                const actionParams = isEcommAbandonedCampaign
                    ? {
                          item_strategy: isInitialSendStep
                              ? EcommItemPickStrategy.RANDOM
                              : EcommItemPickStrategy.PREVIOUSLY_SELECTED,
                          notification: {
                              channels: campaign.channels,
                              template: {
                                  channels: {
                                      default: {
                                          title: '{{item.name}}',
                                          body: '{{item.description | default: ""}}',
                                          imageUrl: '{{item.image}}',
                                          landingUrl: '{{item.url}}',
                                      },
                                  },
                              },
                          },
                      }
                    : {}

                nodeProperties[nodeType] = {
                    type: actionType,
                    params: actionParams,
                }
            }
        } else if (nodeType === NodeType.DELAY) {
            // Currently only supporting RELATIVE delays
            nodeProperties[nodeType] = {
                type: DelayType.RELATIVE,
                params: {
                    delay_seconds: 60 * 60 * 24,
                    qualifier: 'days',
                },
            }
        }

        const node = createNode(nodeType, nodeProperties)
        const state = addNode(node, link, clone(this.currentValue)) as ICampaignBuilderState

        // typeof state.nodes is INode internal - we reassign type to CampaignNode
        // to ensure extensions are honored
        state.builder.activeNode = state.nodes[node.id] as CampaignNode

        this.setState(state)
        this.emitChange(state)
    }

    protected handleRemoveNode = (node: INode) => {
        let modalRef: any

        if (node.type !== NodeType.CONDITION) {
            const removedSteps = this.state.removedSteps ?? []
            removedSteps.push(node)
            this.removeNode(node)
        } else {
            const isBranchType = node.properties?.[NodeType.CONDITION].type === ConditionType.BRANCH

            Modal.confirmWithCustomBody({
                className: 'confirm-condition-removal-modal',
                title: 'Remove Condition',
                iconType: 'warning',
                okText: 'Remove',
                cancelText: 'Cancel',
                content: isBranchType ? (
                    <RemoveBranchConditionConfirmationModal ref={(el) => (modalRef = el)} node={node} />
                ) : (
                    <RemoveBooleanConditionConfirmationModal ref={(el) => (modalRef = el)} node={node} />
                ),
                confirm: () => {
                    this.removeNode(node, modalRef?.state.type ?? 'all')
                    return true
                },
            } as any)
        }
    }

    protected removeNode = (node: INode, branchPreference?: string | 'all') => {
        const state = removeNode(node, clone(this.currentValue), false, branchPreference)

        this.setState(state)
        this.emitChange(state)
    }

    protected handleClickNode = (node: CampaignNode, branchId?: string) => {
        this.setActiveNode(node, branchId)
    }

    protected handleUpdateNode = (node: INode, state?: IChart) => {
        if (!state) {
            state = clone(this.currentValue)
        }

        if (node.id in state!.nodes) {
            state!.nodes[node.id] = node

            this.setState(state!)
            this.emitChange(state)
        }
    }

    protected handleEditorClose = () => {
        const node = this.state.nodes[this.state.builder.activeNode?.id!]
        const params = node?.properties?.[node?.type]?.params ?? {}

        if (Object.keys(params).length === 0) {
            this.removeNode(node, 'all')
        }

        this.setActiveNode(undefined)
    }

    protected handleStepModalConfirm = async (campaign: any, removeSubs: boolean) => {
        this.handleStepModalClose()
        await this.handleSubmit(campaign, removeSubs)
    }

    protected handleStepModalClose = () => {
        this.setState({ showRemoveModal: false })
    }

    protected setActiveNode(node?: CampaignNode, branchId?: string) {
        this.setState(({ builder }) => ({
            builder: {
                ...builder,
                activeNode: node,
                activeBranchId: branchId,
            },
        }))
    }

    protected emitChange(value: any) {
        this.props.onChange?.(parseCampaignFromChart(value))
    }

    protected detectChanges(): { changesDetected: boolean; campaign: any } {
        const isCreateMode = this.props.mode === CampaignBuilderMode.CREATE
        const campaign = clone(this.props.campaign)
        const ogCampaign = this.state.builder._originalCampaign
        let changesDetected = isCreateMode

        if (!!campaign) {
            if (campaign.status === StatusType.DRAFT.name) {
                const steps = campaign.steps ?? []
                const triggerStep = steps.find((s) => s.type === NodeType.TRIGGER)
                const actionSteps = steps.filter((s) => s.type === NodeType.ACTION)

                if (!!triggerStep && actionSteps.length > 0) {
                    changesDetected = true
                }
            } else if (!!campaign.steps) {
                let stepChangesDetected = false

                const revSteps = clone(ogCampaign?.revision?.steps ?? [])
                const stepDtos = clone(campaign.steps)

                // clear current config
                campaign.steps = []

                // upsert steps
                stepDtos.forEach((dto) => {
                    if (!!dto.configuration?.stats) {
                        delete dto.configuration.stats
                    }

                    const revStep = revSteps.find((rs) => rs.id === dto.id)
                    if (!!revStep && deepEqual(revStep?.configuration, dto.configuration)) {
                        if (revStep.type === NodeType.ACTION && !!revStep.configuration?.params?.notification) {
                            // re-add notification_id when no changes exist
                            revStep.configuration.params.notification_id = revStep.configuration.params.notification.id
                            // and remove added builder params
                            delete revStep.configuration.params.title
                            delete revStep.configuration.params.notification
                        }

                        campaign.steps.push(revStep)
                    } else {
                        // remove previous notification value
                        if (dto.type === NodeType.ACTION) {
                            if (!!dto.configuration?.params?.notification?.template) {
                                const notif = clone(dto.configuration.params.notification)

                                dto.configuration.params.notification = {
                                    channels: dto.configuration.params.notification.channels,
                                    deliverySpec: notif.deliverySpec,
                                    template: notif.template,
                                }
                            }
                        }

                        stepChangesDetected = changesDetected = true
                        campaign.steps.push(dto)
                    }
                })

                if (!isCreateMode && !stepChangesDetected) {
                    delete campaign.steps
                }
            }

            // clear current config
            delete campaign.revisions
            delete campaign.revision
        }

        return { changesDetected, campaign }
    }

    // Return FALSE if changes are detected
    protected getSubmitDisabledState = (): boolean => {
        const res = this.detectChanges()
        return this.props.mode === CampaignBuilderMode.READONLY || !res.changesDetected
    }

    protected handleEditorSaveClick = async () => {
        const changeSet = this.detectChanges()

        if (changeSet.changesDetected) {
            const currentCampaignState = getEditableState(this.props.campaign)
            if (
                this.props.mode !== CampaignBuilderMode.CREATE &&
                currentCampaignState === CampaignEditableState.RUNNING &&
                this.state.removedSteps.length > 0
            ) {
                this.setState({ showRemoveModal: true })
            } else {
                await this.handleSubmit(changeSet.campaign)
            }
        }
    }

    protected handleSubmit = async (campaign: any, removeSubs?: boolean) => {
        try {
            const res = await this.props.onSubmit?.(campaign, removeSubs)

            if (res?.ok) {
                await this.parsePassedCampaign()
            }
        } catch (error) {
            simpleNotification('error', error.message)
        }
    }

    protected async parsePassedCampaign(): Promise<void> {
        if (!this.unmounting) {
            const { campaign } = this.props
            let chart = parseChartFromCampaign(campaign)

            if (!!campaign.id && !this.state.builder.campaignParsing) {
                this.components.Canvas?.setState({ loading: true })

                const runState = getEditableState(campaign)

                if (campaign.status === StatusType.DRAFT.name && (!campaign.steps || campaign.steps.length === 0)) {
                    // Currently partial campaigns have no revisions
                    chart = generateEmptyCampaign()
                }
            }

            if (!this.unmounting) {
                await this.setState({
                    ...chart,
                    builder: {
                        ...this.state.builder,
                        loading: false,
                        _originalCampaign: clone(campaign),
                        campaign: {
                            ...this.state.builder?.campaign,
                            ...campaign,
                        },
                        campaignParsing: false,
                        campaignParsed: true,
                    },
                })

                this.components.Canvas?.setState({ loading: false })
            }
        }
    }

    protected async ensureDomainSegmentFieldsLoaded() {
        const state: any = { loadingEsSegmentFields: true }
        await this.setState(state)

        const res = await this.segmentService.fetchSegmentFieldsByDomainId(this.props.domain?.id)

        state.builder = {
            ...this.state.builder,
            loadingEsSegmentFields: false,
            esSegmentFields: [],
        }

        if (res.ok) {
            state.builder.esSegmentFields = res.data
        }

        this.setState(state)
    }
}
