import * as React from 'react'
import '../style/campaign-configuration-form.scss'
import { CampaignBuilderMode, CampaignEditableState, CampaignType, NodeType, TriggerType } from '../enums'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { AudienceSelectionModel } from '../../combo-segment-selection/audience-selection.model'
import { SegmentDto } from '../../../dtos/segment'
import { DomainService } from '../../../services'
import { Container } from 'typescript-ioc/es5'
import { ConfigurationContext } from './configuration-context'
import { GenericDetailsWell } from './generic-details-well'
import { EntryWell } from './entry-well'
import { SendSettingsWell } from './send-settings-well'
import { AudienceWell } from './audience-well'
import { extractCampaignTrigger } from '../../../_utils/campaigns'
import { IConfigurationFlags } from './interfaces'

interface IConfigurationEditor {
    mode: CampaignBuilderMode
    loading?: boolean
    campaign: CampaignModel
    editableState?: CampaignEditableState
    domain?: any
    onChange?: (value: CampaignModel) => any
    // primary action button
    onSubmit?: (value: CampaignModel) => any
    // save only
    onSave?: (value: CampaignModel) => any
    disableSubmit?: boolean
    disableRouterPrompt?: boolean
}

interface IState {
    defaultSegment?: SegmentDto
    flags: IConfigurationFlags
}

let CHANGE_DEBOUNCE_TIMEOUT: any

export class ConfigurationEditor extends React.Component<IConfigurationEditor, IState> {
    protected domainService: DomainService

    private unmounting: boolean = false

    public constructor(props: IConfigurationEditor) {
        super(props)

        this.domainService = Container.get(DomainService)

        const campaign = this.props.campaign.clone()
        const campaignConfig = campaign.getConfiguration()
        const hasQuiteHours = !!campaignConfig.getQuietHoursStart()
        const hasItemGroups = !!campaignConfig.getItemGroupTargeting()?.getItemGroupIds()

        this.state = {
            flags: {
                quietHoursEnabled: hasQuiteHours,
                itemGroupsEnabled: hasItemGroups,
            },
        }
    }

    public componentDidMount() {
        // initial flags set
        this.setFlagsState()

        // initial call to load default segment
        this.fetchSegments()
    }

    public componentDidUpdate(prevProps: IConfigurationEditor) {
        // ensure flags are reset when parent passes changes
        this.setFlagsState(prevProps)
    }

    public componentWillUnmount() {
        this.unmounting = true
    }

    public render() {
        const {
            mode,
            loading,
            domain,
            campaign: _campaign,
            onSubmit,
            onSave,
            disableSubmit,
            editableState,
        } = this.props

        const campaign = _campaign.clone()
        const campaignType = campaign.getType()

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type
        const isSubscribedTrigger = triggerType === TriggerType.SUBSCRIBED

        const isCreateMode = mode === CampaignBuilderMode.CREATE
        const submitText = 'Save & Continue'
        const submitActions = isCreateMode
            ? undefined
            : [
                  {
                      text: 'Save',
                      onClick: (v) => {
                          return (onSave as any)(this.props.campaign, this.state.flags)
                      },
                  },
              ]

        const submitConfig = {
            onSubmit: (v) => {
                return (onSubmit as any)(this.props.campaign, this.state.flags)
            },
            submitText,
            submitActions,
            disableSubmit,
            hideCancel: true,
        }

        return (
            <ConfigurationContext.Provider
                value={{
                    mode,
                    loading: loading ?? false,
                    domain,
                    domainDefaultSegment: this.state.defaultSegment,
                    campaign,
                    setCampaign: this.emitChange,
                    editableState: editableState ?? CampaignEditableState.EDITABLE,
                    flags: this.state.flags,
                    setFlags: (_flags: Partial<IConfigurationFlags>) =>
                        this.setState(({ flags }) => ({
                            flags: {
                                ...flags,
                                ..._flags,
                            },
                        })),
                }}
            >
                <GenericDetailsWell />
                {!!campaignType && <EntryWell />}
                {!!campaignType && (
                    <AudienceWell
                        submitConfiguration={isSubscribedTrigger ? submitConfig : undefined}
                        getSegments={this.fetchSegments}
                    />
                )}
                {!!campaignType && !isSubscribedTrigger && <SendSettingsWell submitConfiguration={submitConfig} />}
            </ConfigurationContext.Provider>
        )
    }

    protected setFlagsState(prevProps?: IConfigurationEditor) {
        const flagUpdates: any = {}

        const prevCampaign = prevProps?.campaign?.clone()
        const prevCampaignConfig = prevCampaign?.getConfiguration()
        const prevHasQuietHours = !!prevCampaignConfig?.getQuietHoursStart()
        const prevHasItemGroups = !!prevCampaignConfig?.getItemGroupTargeting()?.getItemGroupIds()
        const prevTrigger = !!prevCampaign ? extractCampaignTrigger(prevCampaign) : undefined
        const prevTriggerParams = prevTrigger?.getConfiguration()?.params ?? {}

        const currCampaign = this.props.campaign.clone()
        const currCampaignConfig = currCampaign.getConfiguration()
        const currHasQuietHours = !!currCampaignConfig.getQuietHoursStart()
        const currHasItemGroups = !!currCampaignConfig.getItemGroupTargeting()?.getItemGroupIds()
        const currTrigger = extractCampaignTrigger(currCampaign)
        const currTriggerParams = currTrigger?.getConfiguration()?.params ?? {}

        if (!prevTriggerParams.criteria && currTriggerParams.criteria) {
            flagUpdates.criteriaEnabled = true
        }

        if (!prevHasQuietHours && currHasQuietHours) {
            flagUpdates.quietHoursEnabled = true
        }

        if (!prevHasItemGroups && currHasItemGroups) {
            flagUpdates.itemGroupsEnabled = true
        }

        if (!this.unmounting && Object.keys(flagUpdates).length > 0) {
            this.setState(({ flags }) => ({
                flags: {
                    ...flags,
                    ...flagUpdates,
                },
            }))
        }
    }

    protected emitChange = async (changes: CampaignModel, debounce: boolean = false) => {
        clearTimeout(CHANGE_DEBOUNCE_TIMEOUT)
        CHANGE_DEBOUNCE_TIMEOUT = setTimeout(
            () => {
                this.props.onChange?.(changes)
            },
            debounce ? 320 : 0,
        )
    }

    protected fetchSegments = async (): Promise<SegmentDto[]> => {
        const campaign = this.props.campaign.clone()
        const steps = campaign.getCurrentRevision()?.getSteps() ?? []
        const triggerStep = steps.find((s) => s.getType() === NodeType.TRIGGER)

        let segments: SegmentDto[] = []
        if (triggerStep) {
            const stepConfig = triggerStep.getConfiguration() ?? {}
            const params = stepConfig.params
            segments = params?.segments
        }

        const audience = AudienceSelectionModel.build(segments ?? {})

        const { data } = await this.domainService.fetchSegmentsByDomainId(this.props.domain.id, {
            query: {
                includeTreated: 0,
                pagination: 0,
                fields: 'id,name,source,computedStatus,isDefault,iconUrl',
            },
            showLoadingScreen: false,
        })

        if (!this.unmounting && !this.state.defaultSegment) {
            const defaultSegment = data.find((segment) => segment.isDefault)
            await this.setState({ defaultSegment })

            // ensure default all segment value
            const sIds = audience.getSegmentIds() ?? []
            const xIds = audience.getExcludedSegmentIds() ?? []
            if (defaultSegment && sIds.length === 0 && xIds.length === 0) {
                audience.setSegmentIds([defaultSegment.id])
            }
        }

        return data
    }
}
