import * as React from 'react'
import { useService } from '@pushly/aqe/lib/hooks'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FormInstance } from 'antd/es/form'
import { AccountService } from '../../../services/index'
import { Button, Form, Input, Tooltip } from 'antd'
import { InfoCircleOutlined, SyncOutlined, WarningOutlined } from '@ant-design/icons'
import { RuleObject } from 'antd/lib/form'
import { Modal, Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { simpleNotification } from '../../../_utils/utils'
import '../api-settings-well/api-settings-well.scss'
import { ApiKeyDto } from '../../../dtos/api-key.dto'
import * as randomstring from 'randomstring'
import moment from 'moment'

interface IASWProps {
    level: 'org' | 'domain'
    accountId: number
    userType: 'internal' | 'external'
}

export const ApiSettingWell: React.FC<IASWProps> = (props: IASWProps) => {
    const accountSvc = useService(AccountService)
    const [apiUsers, setApiUsers] = React.useState<ApiKeyDto[]>()

    const getApiUsers = async () => {
        const { data } = await accountSvc.fetchApiKeys(props.accountId)
        setApiUsers(data)
    }

    const renderApiUsers = () => {
        const displayRows: JSX.Element[] = []
        apiUsers?.forEach((api, idx) => {
            displayRows.push(
                <ApiKeyRow
                    className="external-row"
                    key={`${idx}-row`}
                    value={api}
                    level={props.level}
                    accountId={props.accountId}
                />,
            )
        })

        if (displayRows.length === 0) {
            displayRows.push(
                <ApiKeyRow
                    className="no-api-row"
                    key={`no-api-key-${randomstring.generate()}`}
                    level={props.level}
                    accountId={props.accountId}
                    value={undefined}
                />,
            )
        }
        return displayRows
    }

    React.useEffect(() => {
        if (!apiUsers) {
            getApiUsers()
        }
    }, [apiUsers])

    function render(): React.ReactElement {
        const internal = props.userType === 'internal'
        return (
            <Well
                title={
                    <div>
                        <div>
                            {internal ? 'INTERNAL' : 'Organization'} API Key{`${apiUsers?.length! > 1 ? 's' : ''}`}
                        </div>
                        <div>
                            {props.level === 'domain' && !internal && (
                                <>
                                    <Tooltip title="Management of API Keys can be accessed via this Domain's Organization View">
                                        <InfoCircleOutlined />
                                    </Tooltip>

                                    <a href={`https://developers.pushly.com/`} target="_blank">
                                        Pushly Developers Documentation
                                    </a>
                                </>
                            )}
                        </div>
                    </div>
                }
                mode="ghost"
                hideFooter={true}
                className="api-well"
            >
                {apiUsers && (
                    <>
                        <div className="last-used-at-header">
                            <span>Last Used At:</span>
                        </div>
                        {renderApiUsers()}
                    </>
                )}
            </Well>
        )
    }

    return render()
}

interface IApiKeyRowProps {
    className: string
    level: 'org' | 'domain'
    accountId: number
    value: ApiKeyDto | undefined
}

const ApiKeyRow: React.FC<IApiKeyRowProps> = (props: IApiKeyRowProps) => {
    const accountSvc = useService(AccountService)
    const formRef = React.createRef<FormInstance>()
    const { value, level, accountId, className } = props

    const [apiKey, setApiKey] = React.useState<ApiKeyDto | undefined>(value)
    const [isGeneratingKey, setIsGeneratingKey] = React.useState<boolean>(false)

    const regenerateKey = async () => {
        if (isGeneratingKey) {
            return
        }

        setIsGeneratingKey(true)

        const { data: newKey } = await accountSvc.regenerateApiKey(accountId, apiKey?.id!)

        if (newKey !== null) {
            setApiKey(newKey)
        }
        setIsGeneratingKey(false)

        return handleKeyGenerateSuccess(newKey!)
    }

    const handleKeyGenerateSuccess = async (newToken: ApiKeyDto) => {
        Modal.success({
            className: 'api-key-success-modal',
            title: 'New API Key',
            icon: <WarningOutlined />,
            okText: 'Got it',
            content: (
                <div className="api-key-success-modal-content">
                    <div>
                        <span>
                            The following API Key has been generated. Please copy and/or save this key. Once this window
                            is closed you will not be able to get this key back. A new one must be generated and then
                            this key will be deactivated.
                        </span>
                    </div>
                    <div className="api-key-viewable">
                        <CopyToClipboard
                            text={newToken?.token!}
                            onCopy={() => simpleNotification('success', 'Your API Key has been copied!')}
                        >
                            <span>{newToken.token}</span>
                        </CopyToClipboard>
                    </div>
                </div>
            ),
        })
    }

    const handleClick = async () => {
        if (!apiKey) {
            const { data: newToken } = await accountSvc.createApiKey(accountId, { showLoadingScreen: true })

            if (newToken) {
                return handleKeyGenerateSuccess(newToken as any)
            }
        } else {
            Modal.confirm({
                title: 'Regenerate API Key',
                icon: <WarningOutlined />,
                okText: 'Regenerate',
                onOk: async (close) => {
                    const form = formRef.current

                    if (form) {
                        const valid = await form.validateFields()

                        if (valid) {
                            await regenerateKey()
                        }
                    }

                    return true
                },
                content: (
                    <Form ref={formRef}>
                        <p>
                            Are you sure you want to revoke the current API Key? Once revoked the current key will no longer
                            be valid and any existing API calls using it will need to be updated to use the newly generated
                            key.
                        </p>

                        <p>
                            Please type, <b>REGENERATE</b>, to continue with this action.
                        </p>

                        <Form.Item
                            name="name"
                            validateTrigger={false}
                            rules={[
                                {
                                    validator: (_rule: RuleObject, val, error) => {
                                        if (!val?.trim()) {
                                            return Promise.reject(`Regenerate is required.`)
                                        } else if (val !== 'REGENERATE') {
                                            return Promise.reject(`You must type "Regenerate" to continue.`)
                                        } else {
                                            return Promise.resolve(true)
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input autoFocus={true} />
                        </Form.Item>
                    </Form>
                ),
            })
        }
    }

    function render() {
        const apiKeyLastUsed = apiKey?.lastUsedAt ? moment(apiKey?.lastUsedAt).format('LL') : 'N/A'

        return (
            <div className={className}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>
                        {apiKey ? 'API KEY' : <span>No Active API Keys</span>}
                    </div>

                    <div className={getClassNames('form-layout-row-value')}>
                        <div className="api-key-field">
                            {apiKey && (
                                <div className="api-key-field-value">
                                    <div>
                                        <Input
                                            size="small"
                                            value={randomstring.generate(64)}
                                            type="password"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="api-key-field-actions">
                                {level === 'org' && (
                                    <Button size="small" onClick={() => handleClick()}>
                                        {apiKey ? (
                                            <Tooltip title="Regenerate Key">
                                                <SyncOutlined />
                                            </Tooltip>
                                        ) : (
                                            <span>Generate</span>
                                        )}
                                    </Button>
                                )}
                            </div>
                            <div className="api-key-field-date">{apiKeyLastUsed && <span>{apiKeyLastUsed}</span>}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render()
}
