import { useEffect, useRef, MutableRefObject } from 'react'
import axios, { Canceler, CancelToken } from 'axios'

export const useAxiosCancelToken = (): [MutableRefObject<CancelToken | undefined>, (msg?: string) => CancelToken] => {
    const token = useRef<CancelToken | undefined>()

    let cancelToken: Canceler
    const generateToken = () =>
        new axios.CancelToken((c) => {
            cancelToken = c
        })

    const resetToken = (msg?: string): CancelToken => {
        cancelToken?.(msg)
        token.current = generateToken()
        return token.current
    }

    return [token, resetToken]
}

interface IAxiosCancelTokenSet {
    current: CancelToken | undefined
    reset: (msg?: string) => CancelToken
}
export const useAxiosCancelTokens = <T extends string>(
    ...names: T[]
): Record<'default', IAxiosCancelTokenSet> & Record<T, IAxiosCancelTokenSet> => {
    const defineTokenSet = (token: MutableRefObject<CancelToken | undefined>, resetter: (msg?: string) => void) => {
        const tokenSet = { reset: resetter }
        Object.defineProperty(tokenSet, 'current', {
            get: (): CancelToken | undefined => token.current,
        })

        return tokenSet
    }

    const [defaultToken, resetDefaultToken] = useAxiosCancelToken()
    const tokens: any = {
        default: defineTokenSet(defaultToken, resetDefaultToken),
    }

    names = names ?? []
    names.sort()

    for (const name of names) {
        // names are ordered to preserve hook call order
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [namedToken, resetNamedToken] = useAxiosCancelToken()
        tokens[name] = defineTokenSet(namedToken, resetNamedToken)
    }

    return tokens
}
