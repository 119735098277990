export const DEFAULT_DELAY_SECONDS = 60 * 60 * 24
export const DEFAULT_DELAY_QUALIFIER = 'days'
export const DEFAULT_EXCLUDED_DAYS = {
    sunday: true,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
}

export type DelayQualifier = 'days' | 'hours' | 'minutes'

export const calculateDelayQualifiedValue = (delaySeconds: number, qualifier: DelayQualifier): number => {
    let value = DEFAULT_DELAY_SECONDS

    switch (qualifier) {
        case 'days':
            value = delaySeconds / 60 / 60 / 24
            break
        case 'hours':
            value = delaySeconds / 60 / 60
            break
        case 'minutes':
            value = delaySeconds / 60
            break
    }

    return Math.round((value + Number.EPSILON) * 100) / 100
}

export const calculateDelaySecondsValue = (
    qualifiedValue: number | string,
    qualifier: 'days' | 'hours' | 'minutes',
): number => {
    let value = DEFAULT_DELAY_SECONDS

    let qv: any = qualifiedValue.toString()
    try {
        qv = parseFloat(qv)

        switch (qualifier) {
            case 'days':
                value = qv * 60 * 60 * 24
                break
            case 'hours':
                value = qv * 60 * 60
                break
            case 'minutes':
                value = qv * 60
                break
        }
    } catch (_err) {}

    return Math.round(value)
}
