import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'

interface INoNotifCardProps {
    className?: string
    loading?: boolean
}

interface INoNotifCardState {}

export class NoNotificationCard extends BetterComponent<INoNotifCardProps, INoNotifCardState> {
    public readonly defaultClassName: string = 'sw-mv-recent-notification-card'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: INoNotifCardProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('details-wrapper')}>
                        <div className={this.buildClassName('details')}>
                            <div className={this.buildClassName('title')}>No recent notifications found</div>
                            <div className={this.buildClassName('body')}>Send your first notification</div>
                        </div>
                    </div>
                </div>

                <div className={this.buildClassName('loader-wrapper')}>
                    <div className={this.buildClassName('loader')}>
                        <LoadingOutlined spin={true} />
                    </div>
                </div>
            </div>
        )
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [
            this.defaultClassName,
            'no-notification-card',
            this.props.loading ? 'loading' : 'loaded',
        ]

        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
