import * as React from 'react'
import { Table } from 'antd'
import { Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { InsightsService } from '../../../services/insights'
import { AppState } from '../../../stores/app'
import { LoadingOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import { ISavedReportsProps, ISavedReportsState } from './interfaces'
import { SavedReportsListContext } from './saved-reports-list-context'
import { SavedReportsListHeader } from './saved-reports-list-header'
import moment from 'moment-timezone'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { AppService } from '../../../services'
import './saved-reports.scss'
import { buildApiReportContract } from '../helpers/build-api-report-contract'
import { snakeCase } from 'change-case'
import { ReportStatusCode } from '../enums/report-status-code'
import clone from 'clone'
import { simpleNotification, titleCase } from '../../../_utils/utils'
import deepEqual from 'react-fast-compare'
import { SavedReportsActionsModal } from './saved-reports-actions-modal'
import { Container } from 'typescript-ioc'
import { SavedReportsScheduleDrawer } from './saved-reports-schedule/saved-reports-schedule-drawer'
import { SavedReportScheduleModel } from '../../../models/saved-reports/saved-report-schedule.model'
import * as randomstring from 'randomstring'
import { buildRelativeLanguageSchedule } from '../../../_utils/relative-schedule-builder'
import { TableRowEntityDisplay } from '../../../components/table-row-entity-display/table-row-entity-display'
import { asCaslSubject } from '../../../stores/app-ability'
import { SubjectEntity } from '../../../enums/ability-entity.enum'
import { AbilityAction } from '../../../enums/ability-action.enum'

type FilterKey = keyof ISavedReportsState['filters']

export class SavedReports extends React.Component<ISavedReportsProps, ISavedReportsState> {
    protected readonly appState: AppState
    protected readonly appService: AppService
    protected readonly insightsService: InsightsService

    public constructor(props: ISavedReportsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            loading: false,
            reports: [],
            pagination: {
                size: 'small',
                simple: true,
                hideOnSinglePage: true,
                pageSize: 15,
            },
            filters: {},
            scheduleDrawerVisible: false,
            sharePanelVisible: false,
            archivePanelVisible: false,
            defaultHover: false,
            sortOrder: {
                columnKey: 'created_at',
                field: 'created_at',
                order: 'descend',
            },
            reportToAction: {},
            schedule: SavedReportScheduleModel.build({}),
        }
    }

    public componentDidMount = async (): Promise<void> => {
        await this.fetchReports()
    }

    public render(): React.ReactNode {
        const { sharePanelVisible, archivePanelVisible, filters } = this.state

        const action = sharePanelVisible ? 'Share' : archivePanelVisible ? 'Archive' : ''

        return (
            <SavedReportsListContext.Provider
                value={{
                    ...this.state,
                    onSearchClick: this.handleSearchClick,
                    onFilterChange: this.handleFilterChange,
                    filters: filters ?? [],
                }}
            >
                <Well
                    className={getClassNames('saved-reports', 'nested', 'table-well')}
                    header={
                        <SavedReportsListHeader
                            title={this.props.title}
                            filterSize={this.props.filterSize}
                            hideSearch={false}
                        />
                    }
                    hideFooter={true}
                >
                    <div>
                        <Table
                            className={getClassNames('saved-reports-table')}
                            dataSource={this.state.reports}
                            pagination={this.state.pagination}
                            loading={
                                this.state.loading && {
                                    indicator: <LoadingOutlined spin={true} />,
                                }
                            }
                            size="small"
                            rowKey={(r: any) => r.id}
                        >
                            <Table.Column
                                key="default"
                                className="default"
                                title="Default"
                                align="center"
                                render={(data) => {
                                    return <StarBadgeButton report={data} onClick={this.handleSetDefaultReport} />
                                }}
                            />
                            <Table.Column
                                key="id"
                                className="reports"
                                title="Report Name"
                                dataIndex={['name']}
                                render={(id) => this.renderReportsDisplay(id)}
                            />
                            <Table.Column
                                key="schedule"
                                className="schedule"
                                title="Schedule"
                                dataIndex={['schedule']}
                                render={(schedule) => this.renderScheduleDisplay(schedule)}
                            />
                            <Table.Column
                                key="type"
                                className="type"
                                title="Type"
                                align="center"
                                dataIndex={['reportConfig', 'type']}
                                sortOrder="descend"
                                defaultSortOrder="descend"
                                render={(type) => this.renderTypeDisplay(type)}
                            />
                            <Table.Column
                                key="range"
                                className="date-range"
                                title="Date Range"
                                align="center"
                                dataIndex={['reportConfig']}
                                render={(reportConfig) => this.renderDateRangeDisplay(reportConfig)}
                            />
                            <Table.Column
                                key="actions"
                                className="actions"
                                title="Actions"
                                align="right"
                                render={(data) => this.renderRowActions(data)}
                            />
                        </Table>
                    </div>
                    <SavedReportsActionsModal
                        action={action}
                        visible={this.state.sharePanelVisible || this.state.archivePanelVisible}
                        onCancel={() => this.handleActionsModalVisible(action, {})}
                        onOk={this.handleArchiveReport}
                        getAppContainer={this.appService.getAppContainer}
                        report={this.state.reportToAction}
                        confirmLoading={this.state.loading}
                    />
                    <SavedReportsScheduleDrawer
                        key={this.state.drawerKey}
                        visible={this.state.scheduleDrawerVisible}
                        onClose={this.handleScheduleDrawer}
                        onSubmit={this.handleSubmit}
                        report={this.state.reportToAction}
                        schedule={this.state.schedule}
                    />
                </Well>
            </SavedReportsListContext.Provider>
        )
    }

    public handleScheduleDrawer = async (report: any, refresh?: boolean) => {
        await this.setState({
            scheduleDrawerVisible: !this.state.scheduleDrawerVisible,
            drawerKey: randomstring.generate(),
            reportToAction: report,
            schedule: report.schedule,
        })

        if (refresh) {
            await this.fetchReports()
        }
    }

    protected fetchReports = async (): Promise<void> => {
        this.setState({ loading: true })

        try {
            const { filters } = this.state

            const reports = await this.insightsService.getSavedReports(
                this.appState.currentDomain!.id,
                this.appState.currentUser!.id,
                true,
                { search: filters.search },
            )
            // build models for schedule
            reports.map(
                (report) =>
                    (report.schedule = SavedReportScheduleModel.build(
                        report.schedule ?? {},
                    ) as SavedReportScheduleModel),
            )

            this.setState({
                reports,
                loading: false,
            })
        } catch (error) {
            if (error) {
                simpleNotification('error', error)
            }
        }
    }

    protected handleActionsModalVisible = async (actionType: string, report: any) => {
        let panelKey
        if (actionType === 'Share') {
            panelKey = ['sharePanelVisible']
        } else if (actionType === 'Archive') {
            panelKey = ['archivePanelVisible']
        }

        await this.setState({
            ...this.state,
            [panelKey]: !this.state[panelKey],
            reportToAction: report,
        })
    }

    protected renderReportsDisplay(name: any): React.ReactNode {
        return <TableRowEntityDisplay title={name} />
    }

    protected renderScheduleDisplay(reportSchedule: SavedReportScheduleModel) {
        let display
        const isScheduled = reportSchedule.getId()

        if (isScheduled) {
            const config = reportSchedule.getConfiguration()
            const schedule = config.getSchedule()!
            display = buildRelativeLanguageSchedule(schedule)
        } else {
            display = 'Not Scheduled'
        }

        return <div>{display}</div>
    }

    protected handleSearchClick = async (value: string): Promise<void> => {
        this.setState({
            filters: {
                search: value,
            },
        })

        const emptyValues = !value.trim()
        if (!emptyValues) {
            await this.fetchReports()
        }
    }

    protected handleFilterChange = async <FilterValue extends ISavedReportsState['filters'][FilterKey]>(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void> => {
        const prevFilters = clone(this.state.filters)

        await this.setState({
            filters: {
                ...this.state.filters,
                [key]: value,
            },
        })

        if (!deepEqual(this.state.filters, prevFilters)) {
            await this.fetchReports()
        }
    }

    protected renderTypeDisplay = (type): React.ReactNode => {
        return <div>{titleCase(type)}</div>
    }

    protected renderDateRangeDisplay = (reportConfig: any): React.ReactNode => {
        let dateDisplay

        if ('dateRangePresetKey' in reportConfig) {
            dateDisplay = titleCase(reportConfig.dateRangePresetKey)
        }
        if ('dateRange' in reportConfig) {
            const dateRange = reportConfig.dateRange
            dateDisplay = `${moment(dateRange.since).format('ll')} - ${moment(dateRange.through).format('ll')}`
        }
        return <div>{dateDisplay}</div>
    }

    protected renderRowActions = (report: any): React.ReactNode => {
        let actions: any[] = []

        actions.push(
            {
                text: 'Share',
                icon: 'share-alt',
                onClick: () => this.handleActionsModalVisible('Share', report),
                report,
            },
            {
                text: 'Download',
                icon: 'cloud-download',
                onClick: () => this.handleDownload(report),
                report,
            },
        )

        if (!report.schedule.getId()) {
            actions.push({
                text: 'Schedule',
                icon: 'clock-circle',
                onClick: () => this.handleScheduleDrawer(report),
                report,
            })
        } else {
            actions.push({
                text: 'Edit Schedule',
                icon: 'clock-circle',
                onClick: () => this.handleScheduleDrawer(report),
                report,
            })
        }

        const reportCaslSubject = asCaslSubject(SubjectEntity.SAVED_REPORT, report)
        const canArchive = this.appState.abilityStore.can(AbilityAction.UPDATE, reportCaslSubject)

        if (canArchive) {
            actions.push(
                {
                    divider: true,
                },
                {
                    text: 'Archive',
                    icon: 'folder',
                    onClick: () => this.handleActionsModalVisible('Archive', report),
                    report,
                },
            )
        }

        return (
            <AsyncButton
                actions={actions}
                onClick={() => this.viewReport(report.id)}
                size="small"
                altHref={this.buildReportUrl(report.id)}
            >
                <span>View</span>
            </AsyncButton>
        )
    }

    protected viewReport(id: any): void {
        const key = 'reporting'

        this.appService.route(`${this.props.location}?report_id=${id}&shared=0#${key}`)
    }

    protected buildReportUrl(id: number): string {
        return this.appService.routeWithinDomain(`/insights?report_id=${id}&shared=0#reporting`, true)
    }

    protected handleDownload = async (report): Promise<void> => {
        const currentSavedReport = clone(report)
        const { reportConfig } = report
        const type = reportConfig.type!.toLowerCase()
        const level = reportConfig.level!.toLowerCase()
        const breakdown = reportConfig
            .breakdown!.toLowerCase()
            .split(',')
            .map((bd) => (bd === 'placement' ? 'device-type' : bd))
            .filter((bd) => bd !== 'none')
            .join('-')

        const contract = buildApiReportContract(reportConfig, this.appState.currentDomain!.id, type, level, true)

        contract.format = 'csv'
        contract.filename = !!currentSavedReport
            ? snakeCase(currentSavedReport.name)
            : `${type}_${level}${!!breakdown ? '_' + breakdown : ''}`

        if (!currentSavedReport && !!reportConfig.dateRange && !!reportConfig.dateRange.since) {
            const range: any = reportConfig.dateRange
            const since = range.since.replace(/-/gi, '')
            const through = range.through.replace(/-/gi, '')

            contract.filename = `${contract.filename}_${since}-${through}`
        }

        contract.filename = contract.filename + '_report'

        // CSV download exclusions
        const exclusions = ['domain.id', 'domain.name', 'group.id', 'group.name', 'notification.source']
        exclusions.forEach((exclusion) => {
            const exclusionIndex = contract.fields.findIndex((f: string) => f === exclusion)
            if (exclusionIndex !== -1) {
                contract.fields.splice(exclusionIndex, 1)
            }
        })

        try {
            await this.insightsService.fetch(contract, true, 'insights-request')
        } catch (err) {
            this.sendErrorStatus(err)
        }
    }

    protected sendErrorStatus(err: Error): void {
        this.props.onStatusUpdate({
            code: ReportStatusCode.ERROR,
            message: (
                <span>
                    An error has occurred while generating your report.
                    <br />
                    Please contact your account manager for assistance.
                </span>
            ),
        })
    }

    protected handleArchiveReport = async (report: any): Promise<void> => {
        try {
            this.setState({ loading: true })

            const archived = await this.insightsService.destroySavedReport(
                this.appState.currentDomain!.id,
                report.id,
                this.appState.currentUser!.id,
                true,
            )

            if (archived) {
                await this.fetchReports()
                simpleNotification('success', `${report.name} has been successfully archived.`)
                this.setState({ loading: false })

                this.handleActionsModalVisible('Archive', {})
            }
        } catch (error) {
            simpleNotification('error', error)
            this.setState({ loading: false })
        }
    }

    protected handleSetDefaultReport = async (report: any): Promise<void> => {
        this.setState({ loading: false })

        const savedReport = await this.insightsService.save(
            this.appState.currentDomain!.id,
            this.appState.currentUser!.id,
            report.id,
            report.name,
            !report.isDefault,
            report.reportConfig,
            true,
        )

        if (!!savedReport) {
            await this.fetchReports()

            simpleNotification(
                'success',
                savedReport.isDefault
                    ? `${savedReport.name} has been set as your default report.`
                    : `${savedReport.name} has been unset as your default report.`,
                undefined,
                'setDefaultReport',
            )

            this.setState({ loading: false })
        }
    }

    protected handleSubmit = async (schedule, cancellationKey) => {
        const ok = await this.insightsService.saveSavedReportSchedule(
            schedule,
            this.appState.currentDomain!.id,
            this.appState.currentUser!.id,
            false,
            cancellationKey,
        )

        if (ok) {
            await this.handleScheduleDrawer({})
        }

        await this.fetchReports()
    }
}

interface ISBBProps {
    report: any
    onClick: (data: any) => void
}

export const StarBadgeButton: React.FC<any> = ({ ...props }: ISBBProps) => {
    const [hover, setHover] = React.useState<boolean>(false)
    const isDefault = props.report.isDefault

    return (
        <span
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => props.onClick(props.report)}
        >
            {isDefault && !hover ? (
                <StarFilled className={getClassNames(`saved-reports-default-button${isDefault ? ' default' : ''}`)} />
            ) : !isDefault && !hover ? (
                <StarOutlined className={getClassNames(`saved-reports-default-button`)} />
            ) : (
                <StarFilled className={getClassNames(`saved-reports-default-button`)} />
            )}
        </span>
    )
}
