import * as React from 'react'
import '../style/action-editor.scss'
import { Well } from '@pushly/aqe/lib/components'
import { Select } from 'antd'
import { ActionType } from '../enums'
import { ActionEditorProps } from '../interfaces'
import { updateNodeTypeProps } from '../helpers/node'
import { CURRENT_BUILDER_ITERATION } from '../constants'
import { NdfSubmitRequestType } from '../../notification-data-form/interfaces/ndf-submit-request-type'
import SendNotificationEditor from './send-notification.editor'
import AmplyCollectionNotificationEditor from './amply-collection-notification.editor'
import ProfileUpdateEditor from './profile-update.editor'

export class ActionEditor extends React.PureComponent<ActionEditorProps> {
    private ndfRef: any

    public render() {
        const { domain, node, onChange, chart, editableState, readonly } = this.props
        const editorProps = { domain, node, onChange, chart, editableState, readonly }
        const nodeType = node.properties?.[node.type]?.type

        return (
            <>
                {CURRENT_BUILDER_ITERATION >= 3 && (
                    <Well mode="ghost" title="Action Type" hideFooter={true}>
                        <Select
                            className="campaign-action-editor-type-select"
                            dropdownClassName="campaign-action-editor-type-dropdown"
                            size="small"
                            placeholder="Please select an action"
                            defaultValue={nodeType}
                            onChange={(value: string) =>
                                onChange(
                                    updateNodeTypeProps(node, {
                                        type: value,
                                        params: {},
                                    }),
                                )
                            }
                        >
                            <Select.Option value={ActionType.SEND_NOTIFICATION}>
                                Send Notification to Subscriber
                            </Select.Option>
                            <Select.Option value={ActionType.SEND_CART_NOTIFICATION}>
                                Send Abandoned Item Notification
                            </Select.Option>
                            <Select.Option value={ActionType.UPDATE_PROFILE}>Add Subscriber Keywords</Select.Option>
                        </Select>
                    </Well>
                )}

                {nodeType === ActionType.SEND_NOTIFICATION && (
                    <SendNotificationEditor {...editorProps} ref={(el) => (this.ndfRef = el)} />
                )}

                {/* Ecomm Notifications use the standard notification form */}
                {nodeType === ActionType.SEND_CART_NOTIFICATION && (
                    <SendNotificationEditor {...editorProps} ref={(el) => (this.ndfRef = el)} />
                )}

                {/* Ecomm Notifications use the standard notification form */}
                {nodeType === ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION && (
                    <SendNotificationEditor {...editorProps} ref={(el) => (this.ndfRef = el)} />
                )}

                {/* Ad Notifications are a custom flow and do not use the notification form */}
                {nodeType === ActionType.SEND_AMPLY_COLLECTION_NOTIFICATION && (
                    <AmplyCollectionNotificationEditor {...editorProps} />
                )}

                {nodeType === ActionType.UPDATE_PROFILE && <ProfileUpdateEditor {...editorProps} />}
            </>
        )
    }

    protected handleSubmit = async () => {
        let response = true

        if (this.ndfRef) {
            response = this.ndfRef?._processSubmitRequest(NdfSubmitRequestType.STANDARD)
        }

        return response
    }
}
