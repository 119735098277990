import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'
import { Translate } from '../translate/translate'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import './404.scss'
import { Button } from '@pushly/aqe/lib/components/index'

interface IProps {
    label?: string
    showLogo?: boolean
    loginError?: boolean
}

interface IState {}

export class NotFound404 extends BetterComponent<IProps, IState> {
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        return (
            <div className="pufferfish-404">
                <div className="background-text">404</div>
                {this.props.showLogo && <div className="logo" />}
                {this.props.label ?? "Hmm, we couldn't find that page"}
                {this.props.loginError && (
                    <div>
                        <Button size="large" type="primary" onClick={() => location.assign('/')}>
                            Return to Platform
                        </Button>
                    </div>
                )}
            </div>
        )
    }
}
