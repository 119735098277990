import * as React from 'react'
import { observer } from 'mobx-react'
import { BetterComponent } from '../better-component/better-component'
import { Tooltip } from 'antd'
import { cleanNumericDisplays } from '../../_utils/utils'
import * as numeral from 'numeral'

interface IProps {
    impressions: number
    status?: string
}

interface IState {}

@observer
export class ImpressionsColumnView extends BetterComponent<IProps, IState> {
    public render() {
        const value = this.renderImpressions(this.props.impressions)
        const valueExpeanded = this.renderImpressions(this.props.impressions, 'O,O')

        return (
            <div>
                {value === valueExpeanded ? (
                    <span>{value}</span>
                ) : (
                    <Tooltip trigger={'click'} title={valueExpeanded}>
                        <span className="cursor-pointer">{value}</span>
                    </Tooltip>
                )}
            </div>
        )
    }

    private renderImpressions(impressions: number, format: string = 'O.OOa'): string {
        const value =
            impressions === undefined
                ? '--'
                : String(impressions).length > 4
                ? numeral(impressions).format(format)
                : numeral(impressions).format('O,O')

        return cleanNumericDisplays(value)
    }
}
