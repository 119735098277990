export const tryParseInt = (n: string, radix?: number): any => {
    try {
        return parseInt(n.toString(), radix)
    } catch (_) {
        return n
    }
}

export const tryParseFloat = (n: string): any => {
    try {
        return parseFloat(n.toString())
    } catch (_) {
        return n
    }
}
