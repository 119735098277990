export const enTranslations = {
    translation: {
        page1: {
            title: 'Page 1',
        },

        page2: {
            title: 'Page 2',
        },

        common: {
            submit: 'Submit',
            cancel: 'Cancel',

            passwordPopover: {
                newPasswordHeader: 'New Password',
                passwordChangeSuccessMessage: 'Your password has successfully been changed',
                passwordLengthRequirement: 'Be longer than 12 characters',
                passwordMismatchError: 'Your password does not match',
                passwordPopoverTitle: 'Your password must:',
                passwordRequiredError: 'Your password is required',
                passwordTooLongError: 'Your password may not be longer than 128 characters',
                passwordNotUserNameRequirement: 'Cannot be your name',
                passwordNotUserEmailRequirement: 'Cannot be your email',
                passwordIsMissingRequiredAlphaNumericCharacters:
                    'Your password must contain at least one of: lower case (a-z), upper case (A-Z) and numbers (0-9)',
                passwordBeStrong: 'Be complex enough to meet strength requirements',
            },
        },

        preauth: {
            loginHeader: 'Pushly',
        },

        settings: {
            userProfileHeader: 'User Profile',
        },

        users: {
            landingPageHeader: 'Users',
            inviteUserHeader: 'Create User',
            editUserHeader: 'Edit User',
        },

        resetPassword: {
            formHeader: 'Password Reset',
        },

        userConfirmation: {
            formHeader: 'Complete User Signup',
        },

        notifications: {
            sendPageHeader: 'Create Notification',
            welcomePageHeader: 'Welcome Notification',
        },

        domains: {
            createDomainHeader: 'Create Domain',
            manageDomainHeader: 'Manage Domain',
            getCodeHeader: 'Website Integration',
        },

        errors: {
            e404: 'Error 404, Page not found',
        },
    },
}
