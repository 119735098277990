import React from 'react'
import { Form, Skeleton, Switch } from 'antd'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc'
import { AppService, DomainService } from '../../services'
import { DomainDto } from '../../dtos/domain'
import { Button, Drawer, Well, executeCleanDrawerRender } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../_utils/classnames'
import randomstring from 'randomstring'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form'
import { StatusBadge } from '../badges/status-badge'
import * as deepEqual from 'react-fast-compare'

interface IDomainSegmentationEditor {
    domain: DomainDto
    visible: boolean
    onClose: (e: any) => void
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDomainSegmentationEditorState {
    defaultInlineSegSource?: boolean
}

class DomainSegmentationEditor extends React.Component<IDomainSegmentationEditor, IDomainSegmentationEditorState> {
    public state: IDomainSegmentationEditorState = {
        defaultInlineSegSource: this.props.domain.displayMeta?.default_inline_seg_source || false,
    }

    protected formRef = React.createRef<FormInstance>()

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    public constructor(props: IDomainSegmentationEditor) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        executeCleanDrawerRender(this)
    }

    public render() {
        return (
            <Drawer
                getContainer={this.appService.getAppContainer}
                className={getClassNames('ddf-segments-settings-editor')}
                title="Update General Settings"
                placement="right"
                closable={true}
                onSubmit={this.handleSubmit}
                onClose={this.handleDrawerClose}
                visible={this.props.visible}
            >
                <Form
                    {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}
                    ref={this.formRef}
                    name="segmentation-settings"
                    className="hide-inline-errors"
                    initialValues={{
                        defaultInlineSegSource: this.domain.displayMeta?.default_inline_seg_source || false,
                    }}
                >
                    <Well title="Inline Segmentation" mode="ghost" hideFooter={true}>
                        <Form.Item label="Single-Use Switch" name={'defaultInlineSegSource'}>
                            <div className={getClassNames('form-layout-row', 'single-use-value')}>
                                <div className={getClassNames('form-layout-row-value')}>
                                    <div className="switch-row segment-settings-adhoc-switch">
                                        <span className="switch-left">
                                            <Switch
                                                size="small"
                                                checked={this.state.defaultInlineSegSource}
                                                onChange={this.handleDefaultAdHocSwitch}
                                            />
                                        </span>
                                        <span className="switch-right">Enabled by Default</span>
                                    </div>
                                    <div className={getClassNames('form-layout-row-sub')}>
                                        <InfoCircleOutlined />
                                        <span>
                                            This setting determines the default position for the “Single-Use Segment”
                                            switch on the{' '}
                                            <a
                                                href={`${this.appState.documentationLink}/platform/notifications/in-line-segmentation`}
                                                target="_blank"
                                            >
                                                Inline Segmentation
                                            </a>{' '}
                                            slide-out. When this switched is enabled the switch on the slide-out will be
                                            enabled by default.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Form.Item>
                    </Well>
                </Form>
            </Drawer>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected handleDrawerClose = async () => {
        this.props.onClose(null)
    }

    protected handleDefaultAdHocSwitch = (isAdhoc: boolean): void => {
        const updateSegmentDefState = isAdhoc

        this.setState({ defaultInlineSegSource: updateSegmentDefState })
        this.formRef.current!.setFieldsValue({ defaultInlineSegSource: updateSegmentDefState })
    }

    protected getCurrentValues = (): Partial<DomainDto> => {
        const values: Partial<DomainDto> = {}
        const formValues = this.formRef.current!.getFieldsValue()

        // Inline Seg Default - DisplayMeta Prop
        const _displayMeta: any = {
            ...this.domain.displayMeta,
        }

        _displayMeta.default_inline_seg_source = formValues.defaultInlineSegSource

        if (!deepEqual(this.domain.displayMeta, _displayMeta)) {
            values.displayMeta = _displayMeta
        }

        return values
    }

    protected handleSubmit = async () => {
        try {
            await this.formRef.current!.validateFields()
            const mergedValues = this.getCurrentValues()

            const { ok, data } = await this.domainService.updateDomainById(this.domain.id, mergedValues)

            if (ok) {
                this.handleDrawerClose()
                this.props.onDomainUpdated(data)
            }
        } catch (err) {}
    }
}

interface IGeneralSettingsWell {
    loading?: boolean
    domain: DomainDto
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IState {
    showDrawer: boolean
    drawerKey?: string
}

export class GeneralSettingsWell extends React.Component<IGeneralSettingsWell, IState> {
    public state: IState = {
        showDrawer: false,
    }

    protected appState: AppState

    public constructor(props: IGeneralSettingsWell) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        return (
            <Well
                className="nested"
                title="General Settings"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.renderInlineSegWell()}

                            <DomainSegmentationEditor
                                key={this.state.drawerKey}
                                visible={this.state.showDrawer}
                                domain={this.domain}
                                onClose={this.handleDrawerClose}
                                onDomainUpdated={this.props.onDomainUpdated}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected renderInlineSegWell() {
        const enabled = this.domain.displayMeta?.default_inline_seg_source || false
        return (
            <Well title="Inline Segmentation" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Single-Use Switch Default</div>
                    <div className={getClassNames('form-layout-row-value')}>
                        <StatusBadge type="generic" expanded={true} status={enabled ? 'ENABLED' : 'DISABLED'} />
                    </div>
                </div>
            </Well>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }
}
