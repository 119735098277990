import * as React from 'react'
import { Component } from 'react'
import { IMultiSelectItemProps, IMultiSelectItemState, IMultiSelectOption } from './interfaces'

export class MultiSelectItem extends Component<IMultiSelectItemProps, IMultiSelectItemState> {
    public constructor(props: IMultiSelectItemProps) {
        super(props)
    }

    public render(): React.ReactNode {
        const handleClick = this.handleClick.bind(this)
        const label = this.props.label || this.props.value

        const classNames: string[] = ['ms-dropdown-option']
        if (!!this.props.checked) {
            classNames.push('ms-selected')
        }
        if (!!this.props.disabled) {
            classNames.push('disabled')
        }

        if (this.props.renderOptionItem) {
            return this.props.renderOptionItem(this.buildItemPackage(), !!this.props.checked, handleClick)
        }

        return (
            <div className={classNames.join(' ')} onClick={handleClick}>
                <span className="ms-dropdown-option-label">
                    <span>{label}</span>
                </span>
                <span className="ms-dropdown-option-toggle">
                    <input
                        type="checkbox"
                        checked={true}
                        readOnly={true}
                        tabIndex={-1}
                        disabled={this.props.disabled}
                    />
                </span>
            </div>
        )
    }

    protected handleClick(): void {
        this.props.onChange(this.buildItemPackage(), !this.props.checked)
    }

    private buildItemPackage(): IMultiSelectOption {
        return {
            value: this.props.value,
            label: this.props.label,
        }
    }
}
