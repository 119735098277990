export enum AccessRoleId {
    // no access
    INTERNAL_NO_ACCESS = 1,
    EXTERNAL_NO_ACCESS = 2,

    // internal roles
    INTERNAL_DEVELOPER = 3,
    INTERNAL_ACCOUNT_MANAGER_LEAD = 4,
    INTERNAL_ACCOUNT_MANAGER = 5,
    INTERNAL_SALES_ASSOCIATE = 6,
    INTERNAL_API_USER = 7,
    INTERNAL_READ_ONLY = 8,
    INTERNAL_USER = 9,

    // external roles
    EXTERNAL_ADMIN = 10,
    EXTERNAL_USER = 11,
    EXTERNAL_API_USER = 12,
    EXTERNAL_READ_ONLY = 13,
}

export const InternalAccessRoleIds = [
    AccessRoleId.INTERNAL_NO_ACCESS,
    AccessRoleId.INTERNAL_DEVELOPER,
    AccessRoleId.INTERNAL_ACCOUNT_MANAGER_LEAD,
    AccessRoleId.INTERNAL_ACCOUNT_MANAGER,
    AccessRoleId.INTERNAL_SALES_ASSOCIATE,
    AccessRoleId.INTERNAL_API_USER,
    AccessRoleId.INTERNAL_READ_ONLY,
    AccessRoleId.INTERNAL_USER,
]

export const ExternalAccessRoleIds = [
    AccessRoleId.EXTERNAL_ADMIN,
    AccessRoleId.EXTERNAL_USER,
    AccessRoleId.EXTERNAL_READ_ONLY,
    AccessRoleId.EXTERNAL_API_USER,
    AccessRoleId.EXTERNAL_NO_ACCESS,
]
