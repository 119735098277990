import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { notification } from 'antd'
import { getClassNames } from '../../_utils/classnames'
import { simpleNotification } from '../../_utils/utils'
import { InfoCircleOutlined } from '@ant-design/icons'

type FormLayoutRowProps = {
    label: string
    value?: string
    copyEnabled?: boolean
    copyValue?: string
    onCopied?: (text: string, result: boolean) => any
    info?: React.ReactNode
    infoIcon?: React.ReactNode
}

export const FormLayoutRow = (props: FormLayoutRowProps) => {
    const onCopied = React.useCallback(
        (text: string, result: boolean) => {
            notification.destroy()
            if (result) {
                simpleNotification('success', `Your ${[props.label]} has been copied!`)
            }
        },
        [props.label],
    )

    const ValueWrapper = props.value && props.copyEnabled ? CopyToClipboard : (_: any) => <>{_.children}</>

    return (
        <div className={getClassNames('form-layout-row')}>
            <div className={getClassNames('form-layout-row-label')}>{props.label}</div>
            {props.value && (
                <div
                    className={getClassNames('form-layout-row-value', {
                        ['can-copy']: props.copyEnabled,
                    })}
                >
                    <ValueWrapper
                        onCopy={props.onCopied ? props.onCopied : onCopied}
                        text={props.copyValue ?? props.value}
                    >
                        <span>{props.value}</span>
                    </ValueWrapper>
                </div>
            )}
            {props.info && (
                <div className={getClassNames('form-layout-row-sub')}>
                    {props.infoIcon ?? <InfoCircleOutlined />}
                    <span>{props.info}</span>
                </div>
            )}
        </div>
    )
}
