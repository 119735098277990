import React from 'react'
import { loadWhitelabelSettings } from '../../_utils/whitelabeling'
import { WhitelabelConfigModel } from '../../models/whitelabel-config/whitelabel-config.model'
import { ModuleLoadingScreen } from '../../components/module-loading-screen/module-loading-screen'
import { ErrorPage } from '../../components/error-page/error-page'
import { streamApplicationError } from '../../_utils/airbrake'
import { useService } from '@pushly/aqe/lib/hooks'
import { AppState } from '../../stores/app'

type PlatformIdentity =
    | {
          loaded: false
      }
    | {
          loaded: true
          whiteLabelSettings: WhitelabelConfigModel
      }

const initialPlatformIdentity: PlatformIdentity = { loaded: false }
export const PlatformIdentityContext = React.createContext<PlatformIdentity>(initialPlatformIdentity)
let platformIdentitySnapshot: Readonly<PlatformIdentity> = { ...initialPlatformIdentity }
export const getPlatformIdentitySnapshot = (): Readonly<PlatformIdentity> => platformIdentitySnapshot

function whiteLabelMissingRequiredProperties(label: WhitelabelConfigModel): boolean {
    return (
        !label.getAuth0AudienceName() ||
        !label.getAuth0TenantId() ||
        !label.getAuth0PlatformClientId() ||
        !label.getAuth0HostedLoginDomain()
    )
}

class PlatformIdentityException extends Error {
    public entity?: WhitelabelConfigModel

    public constructor(label?: WhitelabelConfigModel) {
        super('Platform Identity could not be determined.')
        Object.setPrototypeOf(this, PlatformIdentityException.prototype)

        this.entity = label
    }
}

export const PlatformIdentityProvider: React.FC<any> = ({ children }) => {
    const appState = useService(AppState)
    const [identity, _setIdentity] = React.useState<PlatformIdentity>(initialPlatformIdentity)
    const [loadError, setLoadError] = React.useState<Error | null>(null)

    const setIdentity = (value: PlatformIdentity) => {
        const snapshot = { ...value }
        Object.freeze(snapshot)

        platformIdentitySnapshot = snapshot
        _setIdentity(value)
    }

    React.useEffect(() => {
        const loadIdentity = async () => {
            try {
                const whiteLabelSettings = await loadWhitelabelSettings()
                if (!whiteLabelSettings || whiteLabelMissingRequiredProperties(whiteLabelSettings)) {
                    throw new PlatformIdentityException(whiteLabelSettings)
                }

                setIdentity({
                    loaded: true,
                    whiteLabelSettings,
                })

                appState.whiteLabelSettings = whiteLabelSettings
            } catch (err) {
                console.log(err)
                setLoadError(err)
            }
        }

        loadIdentity()
    }, [])

    if (loadError instanceof PlatformIdentityException) {
        streamApplicationError(loadError, {
            detailedName: 'loading_whitelabel_settings_error',
            platformIdentity: loadError.entity,
        })

        return <ErrorPage errorCode={500} />
    }

    return (
        <PlatformIdentityContext.Provider value={identity}>
            {loadError ? (
                <ErrorPage errorCode={404} showAction={true} />
            ) : !identity.loaded ? (
                <ModuleLoadingScreen loading={true} />
            ) : (
                children
            )}
        </PlatformIdentityContext.Provider>
    )
}
