import clone from 'clone-deep'
import { stripUndefined } from '@pushly/aquarium-utils/lib/common/helpers/object'
import { Auth0ConnectionStrategy } from '../enums/auth0-connection-strategy-type.enum'

export class OpenIdIdentityProviderModel {
    public static build(props: OpenIdIdentityProviderModel | any): OpenIdIdentityProviderModel {
        const data = props instanceof OpenIdIdentityProviderModel ? props.serialize() : clone(props)

        const model = new OpenIdIdentityProviderModel()
        model.setId(data.id)
        model.setName(data.name)
        model.setStrategy(data.strategy)
        model.setDomain(data.domain)
        model.setClientId(data.client_id)
        model.setClientSecret(data.client_secret)
        model.setAssociatedEmailDomains(data.associated_email_domains)

        return model
    }

    private id?: number
    private name?: string
    private strategy?: Auth0ConnectionStrategy
    private domain?: string
    private clientId?: string
    private clientSecret?: string
    private associatedEmailDomains?: string[]

    public clone(): OpenIdIdentityProviderModel {
        return OpenIdIdentityProviderModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            name: this.getName(),
            strategy: this.getStrategy(),
            domain: this.getDomain(),
            client_id: this.getClientId(),
            client_secret: this.getClientSecret(),
            associated_email_domains: this.getAssociatedEmailDomains(),
        })
    }

    public setId(id: number | undefined) {
        this.id = id
    }
    public getId(): number | undefined {
        return this.id
    }

    public getName(): string | undefined {
        return this.name
    }
    public setName(name: string): void {
        this.name = name
    }

    public getStrategy(): string | undefined {
        return this.strategy
    }
    public setStrategy(strategy: Auth0ConnectionStrategy | undefined): void {
        this.strategy = strategy
    }

    public getDomain(): string | undefined {
        return this.domain
    }
    public setDomain(domain: string): void {
        this.domain = domain
    }

    public getClientId(): string | undefined {
        return this.clientId
    }
    public setClientId(client_id: string): void {
        this.clientId = client_id
    }

    public getClientSecret(): string | undefined {
        return this.clientSecret
    }
    public setClientSecret(client_secret: string): void {
        this.clientSecret = client_secret
    }

    public getAssociatedEmailDomains(): string[] | undefined {
        return this.associatedEmailDomains
    }
    public setAssociatedEmailDomains(associated_email_domains: string[]): void {
        this.associatedEmailDomains = !associated_email_domains
            ? undefined
            : Array.from(new Set(associated_email_domains))
    }
}
