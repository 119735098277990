export enum CampaignBuilderMode {
    CREATE,
    EDIT,
    READONLY,
}

// Campaign Generics
export enum CampaignType {
    TRIGGERED = 'trigger',
    RECURRING = 'recurring',
}

export enum CampaignEditableState {
    EDITABLE,
    RUNNING,
    COMPLETED,
    // readonly editableState corresponds with READONLY CampaignBuilderMode
    READONLY,
}

// Generic Types
export enum PortId {
    INPUT = 'input',
    OUTPUT = 'output',
    OUTPUT_POSITIVE = 'positive',
    OUTPUT_NEGATIVE = 'negative',
}

export enum PortType {
    INPUT = 'input',
    OUTPUT = 'output',
    BOTTOM = 'bottom',
    OUTPUT_POSITIVE = 'left',
    OUTPUT_NEGATIVE = 'right',
}

export enum LinkType {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    BRANCH = 'branch',
}

export enum NodeType {
    GLOBAL_TRIGGER = 'global_trigger',
    TRIGGER = 'trigger',
    DELAY = 'delay',
    CONDITION = 'condition',
    ACTION = 'action',
    CAMPAIGN_EXIT = 'campaign_exit',
}

// Trigger Enums
export enum TriggerType {
    ABANDONED_CART = 'abandoned_cart',
    ABANDONED_VIEW = 'abandoned_view',
    ADD_TO_CART = 'add_to_cart',
    PROFILE = 'profile',
    SUBSCRIBED = 'subscribed',
    NOTIFICATION_CLICK = 'notification_click',
    NOTIFICATION_IMPRESSION = 'notification_impression',
    PAGE_VIEW = 'page_view',
    SEGMENT_EVALUATION = 'segment_evaluation',
}

export enum EcommItemType {
    PRODUCT = 'product',
    EVENT = 'event',
}

// Delay Enums
export enum DelayType {
    RELATIVE = 'relative',
    SPECIFIC = 'specific',
}

// Condition Enums
export enum ConditionType {
    BOOLEAN = 'boolean',
    BRANCH = 'branch',
}

export enum ConditionSubType {
    PASSIVE = 'passive',
    TRIGGERED = 'triggered',
}

export enum ConditionNodeStandardType {
    IN_SEGMENT = 'in_segment',
}

export enum ConditionNodeTriggeredType {
    NOTIFICATION_CLICK = 'notification_click',
    PROFILE = 'profile',
    ADD_TO_CART = 'add_to_cart',
}

// Action Enums
export enum ActionType {
    SEND_NOTIFICATION = 'send_notification',
    SEND_CART_NOTIFICATION = 'send_cart_notification',
    SEND_AMPLY_COLLECTION_NOTIFICATION = 'send_amply_collection_notification',
    SEND_CONTENT_WEBHOOK_NOTIFICATION = 'send_content_webhook_notification',
    UPDATE_PROFILE = 'update_profile',
}

// Campaign Exit Enums
export enum CampaignExitBehavior {
    BLEED = 'bleed',
    STOP = 'stop',
}

export enum CampaignExitStatus {
    COMPLETED = 'completed',
    REMOVED = 'removed',
}
