import React from 'react'
import TemplatizedTextField, { ITemplatizedTextField } from '../../../templatized-text-field/templatized-text-field'

interface ITemplateTitleField extends ITemplatizedTextField {}

const TemplateTitleField = (props: ITemplateTitleField) => {
    return (
        <TemplatizedTextField
            label="Title"
            {...props}
            limits={{
                max: 40,
                aboveMaxMessage: 'Title may be cut off or prevent the Body from showing on some devices.',
            }}
        />
    )
}

export default TemplateTitleField
