import * as React from 'react'
import { isDef } from '@pushly/aqe/lib/utils/is-def'
import { getClassNames } from '../../../_utils/classnames'
import './style/compound-table.scss'

interface IHeader {
    className?: string
    left?: React.ReactNode
    right?: React.ReactNode
}

export class CompoundTableHeader extends React.PureComponent<IHeader> {
    public render() {
        return (
            <div className={getClassNames('compound-table-header', this.props.className)}>
                <div className={getClassNames('compound-table-header-wrapper')}>
                    {!!this.props.children ? (
                        this.props.children
                    ) : (
                        <>
                            {isDef(this.props.left) && (
                                <div className={getClassNames('compound-table-header-left')}>{this.props.left}</div>
                            )}
                            {isDef(this.props.right) && (
                                <div className={getClassNames('compound-table-header-right')}>{this.props.right}</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }
}

export class CompoundTable extends React.PureComponent<{ className?: string }> {
    public render() {
        return (
            <div className={getClassNames('compound-table', this.props.className)}>
                <div className={getClassNames('compound-table-wrapper')}>{this.props.children}</div>
            </div>
        )
    }
}
