import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import * as queryString from 'query-string'
import { getClassNames } from '../../_utils/classnames'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Card, Modal, Result, Button, Skeleton, Descriptions, Switch } from 'antd'
import { useMountedRef } from '../../_utils/use-mounted-ref'
import { useService } from '../../hooks/use-service'
import { DomainIntegrationService } from '../../services'
import { useLoadableDataState } from '../../hooks/use-loadable-data-state'
import { simpleNotification } from '../../_utils/utils'
import { IIntegrationRow, ISlackIntegration } from './interfaces'
import { handleResponseErrorMessage } from '../../_utils/response-error-utils'

const SlackIntegration = (props: IIntegrationRow<ISlackIntegration>) => {
    const { domainId, loading, integration } = props

    const history = useHistory()
    const runIfMounted = useMountedRef()[1]
    const [value, setValue] = useLoadableDataState({
        loading: false,
        data: integration,
    })
    const svc = useService<DomainIntegrationService>(DomainIntegrationService)

    const qs = queryString.parse(location.search)
    React.useEffect(() => {
        const processOauthCallback = async () => {
            if (props.mode === 'create' && qs.provider === 'slack') {
                setValue({
                    loading: true,
                    data: {
                        ...(value.data ?? ({} as any)),
                        isActive: true,
                        data: {
                            teamName: qs.team_name,
                            incomingWebhook: {
                                channel: qs.channel,
                            },
                        },
                    },
                })

                Modal.destroyAll()
                const modal = Modal.success({
                    className: 'completing-oauth-dialog',
                    title: 'Integration Finalizing',
                    icon: <span className="slack-logo-color" />,
                    okText: 'Complete',
                    cancelText: 'Cancel',
                    footer: null,
                    content: (
                        <div>
                            <Loading3QuartersOutlined spin={true} />
                        </div>
                    ),
                } as any)

                const res = await svc.completeIntegrationSetup(domainId, 'slack', qs, 'slack-integration')

                runIfMounted(() => {
                    setValue({
                        loading: false,
                    })

                    if (res.ok) {
                        modal.update({
                            className: 'completed-oauth-dialog',
                            title: 'Integration Completed',
                            content: (
                                <Result
                                    status="success"
                                    title={false}
                                    subTitle={`Team name: ${res.data?.data?.teamName}; Channel: ${res.data?.data?.incomingWebhook?.channel}`}
                                />
                            ),
                            okText: 'Close',
                            onOk: () => {
                                history.replace({ hash: 'integrations', search: '' })
                                modal.destroy()
                                simpleNotification('success', 'Integration successfully completed.')
                                props.onAdd?.(res.data)
                            },
                        })
                    } else {
                        modal.update({
                            className: 'failed-oauth-dialog',
                            title: 'Integration Failed',
                            content: (
                                <Result
                                    status="warning"
                                    title={false}
                                    subTitle="An error occurred during your integration and we were unable to complete the setup."
                                />
                            ),
                            onOk: () => {
                                history.replace({ hash: 'integrations', search: '' })
                                modal.destroy()
                            },
                        })
                    }
                })
            }
        }

        processOauthCallback()
    }, [qs.state])

    const createIntegration = async () => {
        setValue({
            loading: true,
            data: !integration?.id ? undefined : integration,
        })

        const redirectUrl = await svc.fetchIntegrationRedirectUrl('slack', domainId)

        if (!redirectUrl.ok) {
            const err = redirectUrl.error as any
            handleResponseErrorMessage(err)
        } else {
            const { authUrl } = redirectUrl.data!
            window.location.href = authUrl
        }
    }

    const removeIntegration = async () => {
        if (!!integration || !!value.data) {
            setValue({
                ...value,
                loading: true,
            })

            const { channel, configurationUrl } = value.data!.data!.incomingWebhook ?? {}

            Modal.confirm({
                className: 'remove-integration-dialog',
                title: 'Remove Integration',
                icon: <span className="slack-logo-color" />,
                okText: 'Complete',
                cancelText: 'Cancel',
                content: (
                    <div>
                        <p>
                            Performing this step will remove the Slack integration for channel <b>{channel}</b> from the
                            platform. To completely remove Slack access visit the following URL.
                        </p>
                        <span className="config-url">
                            <a href={configurationUrl} target="_blank">
                                {configurationUrl}
                            </a>
                        </span>
                        <p>
                            Access can be modified from the <b>Authorizations</b> panel, or completely removed by using
                            the <b>Remove App</b> button, on the <b>Configuration</b> tab.
                        </p>
                    </div>
                ),
                onOk: async () => {
                    const res = await svc.removeIntegration(domainId, value.data!.id!)

                    if (res.ok) {
                        simpleNotification('success', 'Integration successfully removed.')
                        props.onRemove?.(value.data!)
                    } else {
                        setValue({
                            ...value,
                            loading: false,
                        })
                    }
                },
                onCancel: async () => {
                    setValue({
                        ...value,
                        loading: false,
                    })
                },
            })
        }
    }

    const toggleIntegration = async (enabled) => {
        const patch = { is_active: enabled }
        setValue((prev) => ({
            loading: true,
            data: {
                ...(prev.data ?? ({} as any)),
                isActive: enabled,
            },
        }))

        const res = await svc.updateById(domainId, value.data!.id!, patch)

        runIfMounted(() => {
            if (!res.ok) {
                setValue((prev) => ({
                    loading: false,
                    data: {
                        ...(prev.data ?? ({} as any)),
                        isActive: !enabled,
                    },
                }))
            } else {
                simpleNotification(
                    'success',
                    enabled ? 'Integration successfully enabled.' : 'Integration successfully disabled.',
                )

                setValue((prev) => ({
                    loading: false,
                    data: prev.data,
                }))
            }
        })
    }

    const isActive = value.data?.isActive

    return (
        <Card
            size="small"
            className={getClassNames('domain-integration', 'slack', props.className, {
                active: isActive,
                empty: !value.data?.id,
            })}
            title={<span className="slack-logo-color" />}
            extra={
                <Button
                    className={getClassNames(null, 'integration-toggle', 'enabled', {
                        linked: !!integration?.id,
                    })}
                    size="small"
                    loading={loading || value.loading}
                    icon={<LegacyIcon type={!!integration?.id ? 'disconnect' : 'link'} />}
                    onClick={() => (!!integration?.id ? removeIntegration() : createIntegration())}
                >
                    <span>{loading || value.loading ? 'Loading' : !integration?.id ? 'New Connection' : 'Remove'}</span>
                </Button>
            }
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false} paragraph={{ rows: 1 }}>
                {!!value.data?.id && (
                    <Descriptions layout="horizontal" column={1}>
                        <Descriptions.Item className="slack-enabled-toggle" label="Enabled">
                            <Switch size="small" checked={isActive} onChange={toggleIntegration} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Team Name">{value.data?.data?.teamName}</Descriptions.Item>
                        <Descriptions.Item label="Channel">
                            {value.data?.data?.incomingWebhook?.channel}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Skeleton>
        </Card>
    )
}

export default SlackIntegration
