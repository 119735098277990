import { DomainSendIntegrationModel } from '../../../models/send-integration-configurations/domain-send-integration.model'
import { convertCase } from '../../../_utils/utils'
import randomstring from 'randomstring'
import { getObjectIntersection } from '../../../_utils/object'
import { NativeApnsEnvironment } from '../../../enums/native-apns-environment'
import * as deepEqual from 'deep-equal'
import { DomainDto } from '../../../dtos/domain'

export function getIntegrationsValuesUpdate(domain: DomainDto, integrations: DomainSendIntegrationModel) {
    const inactiveConfig = { is_active: false }
    const update: any = {}

    // convert case all serialized models to override current Domain DTO config values
    const newWebApnsConfig = convertCase(integrations.getWebApnsConfiguration().serialize(), 'camel')
    if (!deepEqual(domain.apnsConfiguration, newWebApnsConfig)) {
        if (newWebApnsConfig.isActive) {
            update.apnsConfiguration = {
                ...newWebApnsConfig,
                iconSourceUrl: domain.defaultIconUrl,
                _cx: randomstring.generate(),
            }
        } else {
            update.apnsConfiguration = inactiveConfig
        }
    }

    const newNativeApnsConfig = convertCase(integrations.getNativeApnsConfiguration().serialize(), 'camel')
    const currNativeApnsConfig = getObjectIntersection(
        {
            // backfill default env value to ensure no accidental change-sets
            apnsEnvironment: NativeApnsEnvironment.PRODUCTION,
            ...domain.nativeApnsConfiguration,
        },
        newNativeApnsConfig,
    )
    if (!deepEqual(currNativeApnsConfig, newNativeApnsConfig)) {
        if (newNativeApnsConfig.isActive) {
            update.nativeApnsConfiguration = newNativeApnsConfig
        } else {
            update.nativeApnsConfiguration = inactiveConfig
        }
    }

    const newNativeFcmConfig = convertCase(integrations.getNativeFcmConfiguration().serialize(), 'camel')
    const currNativeFcmConfig = getObjectIntersection(domain.nativeFcmConfiguration, newNativeFcmConfig)
    if (!deepEqual(currNativeFcmConfig, newNativeFcmConfig)) {
        if (newNativeFcmConfig.isActive) {
            update.nativeFcmConfiguration = newNativeFcmConfig
        } else {
            update.nativeFcmConfiguration = inactiveConfig
        }
    }

    return update
}
