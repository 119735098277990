import * as React from 'react'
import './input-notification-clicks.scss'
import { IRuleBuilderField } from './rule-builder'
import { BetterComponent } from '../better-component/better-component'
import { ChainSelect, IChainOption } from '../chain-select/chain-select-component'
import autobind from 'autobind-decorator'
import { baseOperators } from './operators'
import { Tooltip, Select, InputNumber as AntInputNumber } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

type NotificationClicksScope = '1d' | '7d' | '14d' | '30d' | '60d'

/**
 * The operator is always `gte`.
 * This could potentially be any of our standard operators (eq, neq, gt, lt, etc.)
 */
const operatorKey: string = baseOperators.gte.value

interface INotificationClicksMeta {
    scope: NotificationClicksScope
}

interface INotificationClicksProps {
    mode: 'edit' | 'display'
    field: IRuleBuilderField
    value: any
    operator?: string
    onChange: any
}

interface INotificationClicksState {
    field: string
    value: number
    operator: string
    meta: any
}

const notificationClickOptions: IChainOption[] = [
    {
        display: 'within the last',
        value: 'prev',
        options: [
            {
                display: '1 Day',
                value: '1d',
            },
            {
                display: '7 Days',
                value: '7d',
            },
            {
                display: '14 Days',
                value: '14d',
            },
            {
                display: '30 Days',
                value: '30d',
            },
            {
                display: '60 Days',
                value: '60d',
            },
            {
                display: '90 Days',
                value: '90d',
            },
        ],
    },
]

const operatorOptions = [
    {
        display: 'at least',
        value: 'gte',
    },
    {
        display: 'exactly',
        value: 'eq',
    },
    {
        display: 'at most',
        value: 'lte',
    },
]

export class InputNotificationClicks extends BetterComponent<INotificationClicksProps, INotificationClicksState> {
    private inputRef: any

    public constructor(props: INotificationClicksProps) {
        super(props)
    }

    public async UNSAFE_componentWillReceiveProps(prevProps: any) {
        if (this.props.mode === 'display') {
            if (
                prevProps.value.value !== this.state.value ||
                prevProps.operator !== this.state.operator ||
                prevProps.value.meta.scope !== this.state.meta.scope
            ) {
                await this.initializeStateWithProps()
            }
        }
    }

    public async UNSAFE_componentWillMount() {
        await this.initializeStateWithProps()
    }

    public componentDidMount(): void {
        this.autofocus()
    }

    public render() {
        return this.props.mode === 'edit' ? (
            <div className="input-notification-clicks">
                <Select className="operator-select" onChange={this.onOperatorChange} value={this.state.operator}>
                    {this.renderOperatorOptions(operatorOptions)}
                </Select>

                <AntInputNumber
                    ref={(el) => (this.inputRef = el)}
                    onChange={this.onValueChange}
                    value={this.state.value}
                    min={0}
                    max={999}
                    type="number"
                />

                <div>time(s)</div>

                <span>
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={notificationClickOptions}
                        onChange={this.onScopeChange}
                    />
                </span>

                <Tooltip title="Time frame does not include clickers from the current day.">
                    <span className="info-icon">
                        <InfoCircleOutlined />
                    </span>
                </Tooltip>
            </div>
        ) : (
            <div className="input-notification-clicks">
                <span className="highlight">{this.getOperatorDisplay()}</span>
                {'\u00A0' /* nbps */}
                <span>{this.state.value} time(s)</span>
                {'\u00A0' /* nbps */}
                <span className="highlight">
                    <ChainSelect
                        mode={this.props.mode}
                        value={this.state.meta.scope}
                        options={notificationClickOptions}
                        onChange={this.onScopeChange}
                    />
                </span>

                <Tooltip title="Time frame does not include clickers from the current day.">
                    <span className="info-icon">
                        <InfoCircleOutlined />
                    </span>
                </Tooltip>
            </div>
        )
    }

    public renderOperatorOptions(options: any[]): React.ReactFragment[] {
        return options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    private async initializeStateWithProps() {
        const propsValue = this.props.value ? this.props.value : ({} as any)

        await this.setState({
            operator: this.props.operator || 'gte',
            value: propsValue.value !== undefined ? propsValue.value : 0,
            meta: propsValue.meta || {},
        })
    }

    private getOperatorDisplay(): string {
        if (this.state.operator) {
            return operatorOptions.filter((o) => o.value === this.state.operator)[0].display
        }
        return ''
    }

    private autofocus(): void {
        if (!!this.inputRef) {
            this.inputRef.focus()
        }
    }

    @autobind
    private async onOperatorChange(operator: string) {
        await this.setState({
            operator,
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onScopeChange(value: NotificationClicksScope) {
        await this.setState({
            meta: {
                scope: value,
            },
        })

        this.emitChangeEvent()
    }

    @autobind
    private async onValueChange(value: any) {
        await this.setState({
            value,
        })

        this.emitChangeEvent()
    }

    private emitChangeEvent() {
        this.props.onChange({
            field: this.props.field.property,
            operator: this.state.operator,
            value: this.state.value,
            meta: this.state.meta,
        })
    }
}
