import * as React from 'react'
import { SendIntegrationsContext } from './send-integrations-context'
import { Container } from 'typescript-ioc'
import { AppState } from '../../../stores/app'
import { FileUploader, Well } from '@pushly/aqe/lib/components'
import { AppleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Input, RadioChangeEvent, Switch } from 'antd'
import { IAbstractApnsPrivateKeyConfiguration, SendIntegrationChannel } from '../../../interfaces/send-integrations'
import { SendIntegrationPrivateKeyType } from '../../../enums/send-integration-private-key-type.enum'
import {
    NativeP12ApnsConfiguration,
    NativeP8ApnsConfiguration,
} from '../../../models/send-integration-configurations/native-apns-configurations.model'
import { DomainSendIntegrationModel } from '../../../models/send-integration-configurations/domain-send-integration.model'
import { Radio } from 'antd/es'
import { simpleNotification, titleCase } from '../../../_utils/utils'
import { IFilePickerValue } from '@pushly/aqe/lib/components/file-uploader/interfaces'
import { FEAT_CHANNEL_IOS } from '../../../constants'
import { NativeApnsEnvironment } from '../../../enums/native-apns-environment'
import { getClassNames } from '../../../_utils/classnames'
import { isDeliveryChannelEnabled } from '../../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

interface IApnsSIWProps {
    integrations: DomainSendIntegrationModel
    className?: string
}

interface IApnsSIWState {
    webPassEnabled: boolean
    nativePassEnabled: boolean
    p8Temp?: { team_id?: string; key_id?: string }
    p12Temp?: any
    p8UploadValue?: string | undefined
    p12UploadValue?: string | undefined
}

export class ApnsIntegrationEditor extends React.Component<IApnsSIWProps, IApnsSIWState> {
    public static contextType = SendIntegrationsContext
    public context!: React.ContextType<typeof SendIntegrationsContext>

    protected appState: AppState

    public constructor(props: IApnsSIWProps) {
        super(props)

        this.appState = Container.get(AppState)

        const nativeConfig = this.nativeConfig

        this.state = {
            webPassEnabled: false,
            nativePassEnabled:
                nativeConfig instanceof NativeP12ApnsConfiguration && !!nativeConfig.getPrivateKeyPassPhrase(),
        }
    }

    public componentDidMount() {
        if (this.webConfig.getPrivateKey()?.private_key_pass_phrase) {
            this.setState({
                webPassEnabled: true,
            })
        }

        if (this.nativeConfig instanceof NativeP12ApnsConfiguration) {
            this.setState({
                p12UploadValue: this.nativeConfig.getPrivateKeyFileUrl(),
                nativePassEnabled: !!this.nativeConfig.getPrivateKeyPassPhrase(),
            })
        } else {
            this.setState({
                p8UploadValue: this.nativeConfig.getPrivateKeyFileUrl(),
            })
        }
    }

    public render() {
        const { domain } = this.context
        const { integrations } = this.props
        const hasWebEnabled = isDeliveryChannelEnabled(domain, DeliveryChannel.WEB)
        const hasNativeEnabled = isDeliveryChannelEnabled(domain, DeliveryChannel.NATIVE_IOS)

        const webApnsConfig = integrations.getWebApnsConfiguration()
        const nativeApnsConfig = integrations.getNativeApnsConfiguration()
        const webEnabled = webApnsConfig.getIsActive()
        const nativeEnabled = nativeApnsConfig.getIsActive()

        return (
            <Well
                className={this.prefixClassName('form')}
                title={
                    <span>
                        <AppleFilled style={{ marginRight: '6px' }} />
                        <span>Apple</span>
                    </span>
                }
                hideFooter={true}
            >
                {hasWebEnabled && (
                    <div className={this.prefixClassName('config-form')}>
                        <div className={this.prefixClassName('config-switch')}>
                            <Switch
                                size="small"
                                onChange={(checked, _ev) =>
                                    this.handleConfigEnabledChange(checked, SendIntegrationChannel.WEB)
                                }
                                defaultChecked={webEnabled}
                            />
                            <span>WEB</span>
                        </div>
                        {webEnabled && this.renderWebEditor()}
                    </div>
                )}

                {hasNativeEnabled && (
                    <div className={this.prefixClassName('config-form')}>
                        <div className={this.prefixClassName('config-switch')}>
                            <Switch
                                size="small"
                                onChange={(checked, _ev) =>
                                    this.handleConfigEnabledChange(checked, SendIntegrationChannel.NATIVE)
                                }
                                defaultChecked={nativeEnabled}
                            />
                            <span>NATIVE</span>
                        </div>
                        {nativeEnabled && this.renderNativeEditor()}
                    </div>
                )}
            </Well>
        )
    }

    protected get webConfig() {
        return this.props.integrations.getWebApnsConfiguration().clone()
    }

    protected get nativeConfig() {
        return this.props.integrations.getNativeApnsConfiguration().clone()
    }

    protected handleConfigEnabledChange = (state: boolean, channel: SendIntegrationChannel) => {
        const integrations = this.context.sendIntegrations.clone()

        if (channel === SendIntegrationChannel.WEB) {
            const config = integrations.getWebApnsConfiguration().clone()
            config.setIsActive(state)
            integrations.setWebApnsConfiguration(config)
        } else if (channel === SendIntegrationChannel.NATIVE) {
            const config = integrations.getNativeApnsConfiguration().clone()
            config.setIsActive(state)
            integrations.setNativeApnsConfiguration(config)
        }

        this.context.setSendIntegrations(integrations)
    }

    protected handleAppBundleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const integrations = this.context.sendIntegrations.clone()
        const config = integrations.getNativeApnsConfiguration().clone()

        config.setAppBundleId(ev.target.value.trim())

        integrations.setNativeApnsConfiguration(config)
        this.context.setSendIntegrations(integrations, true)
    }

    protected handlePassPhraseEnabledChange = (
        channel: SendIntegrationChannel,
        checked: boolean,
        _event: MouseEvent,
    ) => {
        const integrations = this.context.sendIntegrations.clone()

        switch (channel) {
            case SendIntegrationChannel.WEB:
                const web = integrations.getWebApnsConfiguration().clone()

                const nextPrivateKey = web.getPrivateKey()
                nextPrivateKey.private_key_pass_phrase = undefined

                web.setPrivateKey(nextPrivateKey)
                integrations.setWebApnsConfiguration(web)

                this.setState({ webPassEnabled: checked })
                break

            case SendIntegrationChannel.NATIVE:
                const apns = integrations.getNativeApnsConfiguration().clone()

                if (apns instanceof NativeP12ApnsConfiguration) {
                    apns.setPrivateKeyPassPhrase(undefined)
                    integrations.setNativeApnsConfiguration(apns)

                    this.setState({ nativePassEnabled: checked })
                }

                break
        }

        this.context.setSendIntegrations(integrations)
    }

    protected handlePassPhraseChange = (
        ev: React.ChangeEvent<HTMLInputElement> | any,
        channel: SendIntegrationChannel,
    ) => {
        const integrations = this.context.sendIntegrations.clone()
        const nextPassPhrase = ev.target.value.trim()

        if (channel === SendIntegrationChannel.WEB) {
            const config = integrations.getWebApnsConfiguration().clone()

            const nextPrivateKeyConfig = config.getPrivateKey()
            nextPrivateKeyConfig.private_key_pass_phrase = nextPassPhrase

            config.setPrivateKey(nextPrivateKeyConfig)
            integrations.setWebApnsConfiguration(config)
        } else if (channel === SendIntegrationChannel.NATIVE) {
            const config = integrations.getNativeApnsConfiguration().clone() as NativeP12ApnsConfiguration
            config.setPrivateKeyPassPhrase(nextPassPhrase)
            integrations.setNativeApnsConfiguration(config)
        }

        this.context.setSendIntegrations(integrations)
    }

    protected handleFileUploadError = (res: any) => {
        const error = res?.error

        if (error && error.error_type === 'invalid_file_type_error') {
            return simpleNotification('error', error.message)
        }
    }

    protected handleNativePrivateKeyTypeChange = (ev: RadioChangeEvent) => {
        const nextKeyType: SendIntegrationPrivateKeyType = ev.target.value

        const nextIntegrations = this.context.sendIntegrations.clone()
        const currentConfig = nextIntegrations.getNativeApnsConfiguration()

        /**
         * this.appState.currentDomain.nativeApnsConfiguration is
         * not a parsed model property access is direct
         */
        const ogConfig = this.appState.currentDomain!.nativeApnsConfiguration

        const nextConfigClass =
            ev.target.value === SendIntegrationPrivateKeyType.P8
                ? NativeP8ApnsConfiguration
                : NativeP12ApnsConfiguration

        let nextConfig
        if (nextKeyType === ogConfig.privateKeyType) {
            // reset original values when swapping back to original type
            nextConfig = nextConfigClass.build(ogConfig)

            // reset native pass enabled state, if needed, when swapping back
            if (
                nextConfig.getPrivateKeyType() === SendIntegrationPrivateKeyType.P12 &&
                nextConfig.getPrivateKeyPassPhrase() &&
                !this.state.nativePassEnabled
            ) {
                this.setState({ nativePassEnabled: true })
            }
        } else {
            nextConfig = nextConfigClass.build({
                is_active: true,
                apns_environment: currentConfig.getApnsEnvironment(),
            })

            // new configurations should always default to no pass enabled
            this.setState({ nativePassEnabled: false })
        }

        nextIntegrations.setNativeApnsConfiguration(nextConfig)

        this.context.setSendIntegrations(nextIntegrations)
    }

    protected handlePrivateKeyChange = async (
        upload: IFilePickerValue | undefined,
        channel: SendIntegrationChannel,
    ) => {
        const integrations = this.context.sendIntegrations.clone()
        const updateValue = upload?.url ?? undefined

        if (channel === SendIntegrationChannel.WEB) {
            const config = integrations.getWebApnsConfiguration().clone()
            const pack: Partial<IAbstractApnsPrivateKeyConfiguration> = {
                ...config.getPrivateKey(),
                private_key_file_url: updateValue,
            }

            config.setPrivateKey(pack)
            integrations.setWebApnsConfiguration(config)
        } else if (channel === SendIntegrationChannel.NATIVE) {
            const config = integrations.getNativeApnsConfiguration().clone()
            const key = this.nativeConfig instanceof NativeP8ApnsConfiguration ? 'p8UploadValue' : 'p12UploadValue'

            await this.setState((state) => ({ ...state, [key]: updateValue }))

            config.setPrivateKeyFileUrl(updateValue)
            integrations.setNativeApnsConfiguration(config)
        }

        this.context.setSendIntegrations(integrations)
    }

    protected handleWebsitePushIdFieldChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const integrations = this.context.sendIntegrations.clone()
        const config = integrations.getWebApnsConfiguration().clone()
        const change = ev.target.value

        const pack = config.getPushPackage()
        config.setPushPackage({
            ...pack,
            website_push_id: change,
        })

        integrations.setWebApnsConfiguration(config)
        this.context.setSendIntegrations(integrations, true)
    }

    protected handleP8InputFieldChange = async (
        ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldProp: 'team_id' | 'key_id',
    ) => {
        const integrations = this.context.sendIntegrations.clone()
        const config: NativeP8ApnsConfiguration = integrations
            .getNativeApnsConfiguration()
            .clone() as NativeP8ApnsConfiguration
        const change = ev.target.value

        switch (fieldProp) {
            case 'team_id':
                config.setTeamId(change)
                this.setState({
                    p8Temp: {
                        ...this.state.p8Temp,
                        [fieldProp]: change,
                    },
                })
                break
            case 'key_id':
                config.setKeyId(change)
                this.setState({
                    p8Temp: {
                        ...this.state.p8Temp,
                        [fieldProp]: change,
                    },
                })
                break
        }

        integrations.setNativeApnsConfiguration(config)
        this.context.setSendIntegrations(integrations)
    }

    protected renderWebEditor() {
        const config = this.context.sendIntegrations.getWebApnsConfiguration()

        return (
            <>
                <Form.Item label="Website Push ID">
                    <Input
                        size="small"
                        defaultValue={config.getPushPackage()?.website_push_id}
                        onChange={this.handleWebsitePushIdFieldChange}
                    />
                </Form.Item>

                <Form.Item label="Certificate" className={this.prefixClassName('web-certificate-upload')}>
                    <FileUploader
                        className="credential-upload"
                        type="file"
                        private={true}
                        allowedMimeTypes={[...FileUploader.defaultFileTypes.pkcs12]}
                        ownerType="domain"
                        ownerId={this.context.domain.id}
                        buttonText="Upload your APNS private key file (.p12) certificate"
                        buttonIcon={null}
                        defaultValue={
                            config.getPrivateKey().private_key_file_url
                                ? { url: config.getPrivateKey().private_key_file_url! }
                                : undefined
                        }
                        onSuccess={(upload) => this.handlePrivateKeyChange(upload, SendIntegrationChannel.WEB)}
                        onRemove={(upload) => this.handlePrivateKeyChange(upload, SendIntegrationChannel.WEB)}
                        onError={this.handleFileUploadError}
                    />
                </Form.Item>

                <Form.Item label="Pass Phrase" className={this.prefixClassName('p12-cert-pass-phrase')}>
                    <div className={this.prefixClassName('p12-cert-pass-phrase-switch')}>
                        <Switch
                            size="small"
                            onChange={(checked, ev) =>
                                this.handlePassPhraseEnabledChange(SendIntegrationChannel.WEB, checked, ev)
                            }
                            defaultChecked={this.state.webPassEnabled}
                        />
                        <Input
                            size="small"
                            type="password"
                            disabled={!this.state.webPassEnabled}
                            placeholder={this.state.webPassEnabled ? '' : 'No pass phrase'}
                            onChange={(ev) => this.handlePassPhraseChange(ev, SendIntegrationChannel.WEB)}
                            value={config.getPrivateKey()?.private_key_pass_phrase}
                        />
                    </div>
                </Form.Item>
            </>
        )
    }

    protected renderNativeEditor() {
        const config = this.context.sendIntegrations.getNativeApnsConfiguration()
        const allowedFileType =
            config instanceof NativeP8ApnsConfiguration
                ? FileUploader.defaultFileTypes.pkcs8
                : FileUploader.defaultFileTypes.pkcs12
        const fileUploaderValue =
            config.getPrivateKeyFileUrl() ||
            this.state?.[`${this.nativeConfig.getPrivateKeyType()!.toLowerCase()}UploadValue`]
                ? { url: this.state?.[`${this.nativeConfig.getPrivateKeyType()!.toLowerCase()}UploadValue`] }
                : undefined

        return (
            <>
                <Radio.Group
                    className={this.prefixClassName('native-certificate-type-selector')}
                    size="small"
                    buttonStyle="solid"
                    value={config.getPrivateKeyType()}
                    onChange={this.handleNativePrivateKeyTypeChange}
                >
                    {Object.keys(SendIntegrationPrivateKeyType).map((type) => (
                        <Radio.Button key={type} value={type}>
                            {type} Cert
                        </Radio.Button>
                    ))}
                </Radio.Group>

                <Form.Item label="Certificate" className={this.prefixClassName('native-certificate-upload')}>
                    <FileUploader
                        key={config.getPrivateKeyType()}
                        className="credential-upload"
                        type="file"
                        private={true}
                        allowedMimeTypes={allowedFileType}
                        ownerType="domain"
                        ownerId={this.context.domain.id}
                        buttonText={`Upload your APNS private key file (.${config
                            .getPrivateKeyType()
                            ?.toLowerCase()}) certificate`}
                        buttonIcon={null}
                        onSuccess={(upload) => this.handlePrivateKeyChange(upload, SendIntegrationChannel.NATIVE)}
                        onRemove={(upload) => this.handlePrivateKeyChange(upload, SendIntegrationChannel.NATIVE)}
                        onError={this.handleFileUploadError}
                        defaultValue={fileUploaderValue}
                        value={fileUploaderValue}
                    />
                </Form.Item>

                {config instanceof NativeP12ApnsConfiguration && (
                    <>
                        <Form.Item label="Pass Phrase" className={this.prefixClassName('p12-cert-pass-phrase')}>
                            <div className={this.prefixClassName('p12-cert-pass-phrase-switch')}>
                                <Switch
                                    size="small"
                                    onChange={(checked, ev) =>
                                        this.handlePassPhraseEnabledChange(SendIntegrationChannel.NATIVE, checked, ev)
                                    }
                                    defaultChecked={this.state.nativePassEnabled}
                                />
                                <Input
                                    size="small"
                                    type="password"
                                    disabled={!this.state.nativePassEnabled}
                                    placeholder={this.state.nativePassEnabled ? '' : 'No pass phrase'}
                                    onChange={(ev) => this.handlePassPhraseChange(ev, SendIntegrationChannel.NATIVE)}
                                    value={config.getPrivateKeyPassPhrase()}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item label="App Bundle ID">
                            <Input
                                size="small"
                                defaultValue={
                                    this.context.domain.nativeApnsConfiguration.appBundleId ?? config.getAppBundleId()
                                }
                                onChange={this.handleAppBundleChange}
                            />
                        </Form.Item>
                    </>
                )}

                {config instanceof NativeP8ApnsConfiguration && (
                    <>
                        <Form.Item label="Team ID">
                            <Input
                                size="small"
                                defaultValue={this.context.domain.nativeApnsConfiguration.teamId ?? config.getTeamId()}
                                onChange={(ev) => this.handleP8InputFieldChange(ev, 'team_id')}
                                value={this.state?.p8Temp?.team_id}
                            />
                        </Form.Item>
                        <Form.Item label="Key ID">
                            <Input
                                size="small"
                                defaultValue={this.context.domain.nativeApnsConfiguration.keyId ?? config.getKeyId()}
                                onChange={(ev) => this.handleP8InputFieldChange(ev, 'key_id')}
                                value={this.state?.p8Temp?.key_id ?? config.getKeyId()}
                            />
                        </Form.Item>
                    </>
                )}

                <Form.Item label="Environment" className={this.prefixClassName('apns-env')}>
                    <Radio.Group
                        className={this.prefixClassName('apns-env-selector')}
                        size="small"
                        buttonStyle="solid"
                        value={config.getApnsEnvironment()}
                        onChange={(ev) => {
                            const nextIntegrations = this.context.sendIntegrations.clone()
                            const nextConfig = nextIntegrations.getNativeApnsConfiguration().clone()

                            nextConfig.setApnsEnvironment(
                                ev.target.value === NativeApnsEnvironment.PRODUCTION
                                    ? NativeApnsEnvironment.PRODUCTION
                                    : NativeApnsEnvironment.DEVELOPMENT,
                            )

                            nextIntegrations.setNativeApnsConfiguration(nextConfig)

                            this.context.setSendIntegrations(nextIntegrations)
                        }}
                    >
                        <Radio.Button value={NativeApnsEnvironment.PRODUCTION}>
                            {titleCase(NativeApnsEnvironment.PRODUCTION)}
                        </Radio.Button>
                        <Radio.Button value={NativeApnsEnvironment.DEVELOPMENT}>
                            {titleCase(NativeApnsEnvironment.DEVELOPMENT)} (sandbox)
                        </Radio.Button>
                    </Radio.Group>

                    <div className={getClassNames('form-item-sub')}>
                        <QuestionCircleOutlined />
                        <span>
                            See Apple's documentation for more information about&nbsp;
                            <a
                                href="https://developer.apple.com/documentation/bundleresources/entitlements/aps-environment"
                                target="_blank"
                            >
                                APNS Environment Entitlements
                            </a>
                            .
                        </span>
                    </div>
                </Form.Item>
            </>
        )
    }

    protected prefixClassName = (classname: string) => {
        return `${this.props.className}-${classname}`
    }
}
