import colorConvert from 'color-convert'
import { IFaviconResource, WhitelabelConfigModel } from '../models/whitelabel-config/whitelabel-config.model'
import { injectPushlySdk } from './pushly-sdk'
import aqe from '@pushly/aqe'
import { paramCase } from 'change-case'
import { WhiteLabelColorConfig } from '../structs/white-label-color-config'
import { addBodyClassName } from './document'

type V2ColorKey = {
    [K in keyof WhiteLabelColorConfig]: WhiteLabelColorConfig[K] extends Function ? never : K
}[keyof WhiteLabelColorConfig]

const removeFaviconResource = (resource: Element) => {
    document.head.removeChild(resource)
}

const createFaviconResource = (resourceConfig: IFaviconResource) => {
    const link = document.createElement('link')
    link.rel = resourceConfig.rel
    link.href = resourceConfig.href

    document.head.append(link)
}

const replaceFaviconResources = (config: WhitelabelConfigModel): void => {
    const resources = config.getFaviconResources()

    if (resources?.length > 0) {
        const selectors = 'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"]'
        const links = document.head.querySelectorAll(selectors)

        links.forEach(removeFaviconResource)
        resources.forEach(createFaviconResource)
    }
}

const fmtColorKey = (key: string) => {
    return paramCase(key.replace(/hex$/i, ''))
}

const setColorOverride = (
    element: HTMLElement,
    hex: string,
    colorKey: 'primary' | 'secondary' | 'tertiary' | V2ColorKey,
) => {
    const rgb = colorConvert.hex.rgb(hex)
    const hsl = colorConvert.hex.hsl(hex)
    const fmtdType = fmtColorKey(colorKey)

    element.style.setProperty(`--color-${fmtdType}-100`, hex)
    element.style.setProperty(`--color-${fmtdType}-100-rgb`, `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`)
    element.style.setProperty(`--color-${fmtdType}-100-r`, rgb[0].toString())
    element.style.setProperty(`--color-${fmtdType}-100-g`, rgb[1].toString())
    element.style.setProperty(`--color-${fmtdType}-100-b`, rgb[2].toString())
    element.style.setProperty(`--color-${fmtdType}-100-hsl`, `${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%`)
    element.style.setProperty(`--color-${fmtdType}-100-h`, hsl[0].toString())
    element.style.setProperty(`--color-${fmtdType}-100-s`, `${hsl[1].toString()}%`)
    element.style.setProperty(`--color-${fmtdType}-100-l`, `${hsl[2].toString()}%`)
}

const getV2ColorApplicator = (element: HTMLElement, config: WhiteLabelColorConfig) => (colorKey: V2ColorKey) => {
    const color = config[colorKey]
    if (color) {
        setColorOverride(element, color, colorKey)
        addBodyClassName(`wlcv2-${fmtColorKey(colorKey)}`)
    }
}

export const setGlobalCssVariables = (config: WhitelabelConfigModel): void => {
    const root = (document.querySelector(':root') as HTMLElement) ?? document.documentElement
    const v2ColorConfig = config.getColorConfig()

    if (v2ColorConfig) {
        addBodyClassName('wlcv2')

        const applyV2Color = getV2ColorApplicator(root, v2ColorConfig)

        for (const colorKey of Object.keys(v2ColorConfig)) {
            if (typeof v2ColorConfig[colorKey] !== 'function' && /hex$/i.test(colorKey)) {
                applyV2Color(colorKey as V2ColorKey)
            }
        }
    } else {
        if (config.getPrimaryColorHex()) {
            setColorOverride(root, config.getPrimaryColorHex()!, 'primary')
        }
        if (config.getSecondaryColorHex()) {
            setColorOverride(root, config.getSecondaryColorHex()!, 'secondary')
        }
        if (config.getTertiaryColorHex()) {
            setColorOverride(root, config.getTertiaryColorHex()!, 'tertiary')
        }
    }

    if (config.getLoginBackgroundUrl()) {
        root.style.setProperty('--login-background-url', `url("${config.getLoginBackgroundUrl()}")`)
    }

    if (config.getLogoUrl()) {
        root.style.setProperty('--logo-url', `url("${config.getLogoUrl()}")`)
    }

    if (config.getPrimaryIconUrl()) {
        root.style.setProperty('--primary-icon-url', `url("${config.getPrimaryIconUrl()}")`)
    }

    if (config.getSidebarIconUrl()) {
        root.style.setProperty('--sidebar-icon-url', `url("${config.getSidebarIconUrl()}")`)
    }
}

export const loadWhitelabelSettings = async (): Promise<WhitelabelConfigModel | undefined> => {
    const hostname = location.hostname
    let settingsKey = hostname

    const whiteLabelOverride = process.env.WHITELABEL_OVERRIDE?.trim()
    if (whiteLabelOverride) {
        settingsKey = whiteLabelOverride
    }

    // Fallback to using pushly platform domain
    let domainKey = process.env.PLATFORM_DOMAIN_KEY

    return new Promise<WhitelabelConfigModel | undefined>(async (res, rej) => {
        try {
            const settingsRes = await fetch(`${aqe.defaults.publicCdnDomain}/platform-settings/${settingsKey}`, {})

            if (settingsRes.ok) {
                const data = await settingsRes.json()
                const model = WhitelabelConfigModel.build(data)
                domainKey = model.getPreviewDomainKey() ?? domainKey

                if (whiteLabelOverride) {
                    model.setHostname(hostname)
                }

                if (model.getIsActive()) {
                    console.info(`Loading platform settings for ${settingsKey}`)

                    // start dom updates
                    document.title = model.getDisplayName()
                    addBodyClassName('whitelabeled')
                    setGlobalCssVariables(model)
                    replaceFaviconResources(model)

                    res(model)
                } else {
                    rej(`Platform settings for ${settingsKey} are not active.`)
                }
            } else {
                rej(`Platform settings for ${settingsKey} were not found.`)
            }
        } catch (err) {
            throw new Error(`Platform settings for ${settingsKey} failed to fetch with error: ${err.message}`)
        }

        // start sdk initialization
        injectPushlySdk(domainKey!)
    })
}
