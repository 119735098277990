import * as React from 'react'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from '../better-component/better-component'
import { TranslationService } from '../../services'

interface IProps {
    name: string
}

interface IState {}

@observer
export class Translate extends BetterComponent<IProps, IState> {
    private translationService: TranslationService

    public constructor(props: IProps) {
        super(props)

        this.translationService = Container.get(TranslationService)
    }

    public render() {
        const content = this.translationService.tr(this.props.name)

        return <span className="translation">{content}</span>
    }
}
