import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService, NotificationService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Input as AqeInput } from '@pushly/aqe/lib/components'
import { preventBubbling } from '../../_utils/utils'
import { axiosCancellationRequests } from '../../config/axios-setup'
import { SwPortal } from '../sw-portal/sw-portal'
import * as randomstring from 'randomstring'
import './notification-test-search-bar.scss'

interface INotificationTestSearchBarProps {
    value: number
    onChange?: (value: number) => any
    mode?: 'display' | 'search'
    autofocus?: boolean

    className?: string
    disabled?: boolean
    renderOption?: (test: any) => React.ReactNode
    getDropdownContainer?: () => HTMLElement | any

    placeholder?: string
    reactive?: boolean
    preload?: boolean
}

interface ITestOption {
    id: number
    name: string
}

interface IState {
    expanded: boolean
    searching: boolean
    tests: ITestOption[]
    visibleTests: ITestOption[]
    tmpValue?: number
}

export class NotificationTestSearchBar extends BetterComponent<INotificationTestSearchBarProps, IState> {
    private defaultClassName = 'sw-v2-notif-test-search-bar'
    private axiosCancellationKey = 'ntsb.searchTests'

    private appState: AppState
    private appService: AppService
    private notifService: NotificationService

    private uid: string = randomstring.generate()
    private ref: any
    private inputRef: any
    private debounceTimer: any
    private debounceValue = 450

    public constructor(props: INotificationTestSearchBarProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.notifService = Container.get(NotificationService)

        this.state = {
            expanded: false,
            searching: false,
            tests: [],
            visibleTests: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.wireEventHandlers()

        if (this.props.preload) {
            await this.preloadAbTests()
        }

        if (this.hasValue) {
            return this.searchAbTests(this.props.value.toString(), true)
        } else if (this.props.autofocus && !!this.inputRef && !!this.inputRef.inputRef) {
            this.inputRef.inputRef.focus()
        }
    }

    public async componentDidUpdate(): Promise<void> {
        if (this.isDisplayMode && this.hasValue) {
            if (!this.state.tmpValue || this.props.value!.toString() !== this.state.tmpValue.toString()) {
                this.searchAbTests(this.props.value.toString(), true)
            }
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.unwireEventHandlers()
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <span className={this.buildClassName('display')}>
                        {this.hasValue ? (
                            <>
                                <span className="option-id">#{this.props.value}:</span>
                                &nbsp;
                                <span className="option-title">
                                    {this.selectedTest ? this.selectedTest.name : <LoadingOutlined spin={true} />}
                                </span>
                            </>
                        ) : (
                            '...'
                        )}
                    </span>

                    <div className={this.buildClassName('search')}>
                        <AqeInput
                            ref={(el) => (this.inputRef = el)}
                            className={this.buildClassName('search-input')}
                            reactive={this.props.reactive !== false}
                            onChange={this.handleInputChange}
                            onFocus={this.activate}
                            placeholder={this.props.placeholder || 'Search by ID or Title'}
                            maxWidth={400}
                            icon={
                                (
                                    <LegacyIcon
                                        className="search-icon"
                                        type={this.state.searching ? 'loading' : 'search'}
                                        spin={this.state.searching}
                                    />
                                ) as any
                            }
                        />
                    </div>

                    <SwPortal
                        className={this.buildRootClassNames('portal')}
                        portalId={this.buildClassName(`portal-${this.uid}`)}
                        container={this.portalContainer}
                        style={this.getPortalPositioning()}
                    >
                        <div className={this.buildRootClassNames('dropdown')}>
                            <div className={this.buildClassName('dropdown-wrapper')}>
                                <div
                                    className={`${this.buildClassName('dropdown-options')}${
                                        !this.hasOptions ? ' empty' : ''
                                    }`}
                                >
                                    {!this.hasOptions ? (
                                        <span>{this.isSearching ? 'Searching ...' : 'No tests found'}</span>
                                    ) : (
                                        this.state.visibleTests.map((n) => this.renderOption(n))
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwPortal>
                </div>
            </div>
        )
    }

    public renderOption(test: any): React.ReactNode {
        const selected = this.hasValue && this.props.value.toString() === test.id.toString()

        return (
            <div key={test.id} className={`${this.buildClassName('dropdown-option')}${selected ? ' selected' : ''}`}>
                <div
                    className={this.buildClassName('dropdown-option-wrapper')}
                    onClick={() => this.handleValueChange(test)}
                >
                    {!!this.props.renderOption ? (
                        this.props.renderOption(test)
                    ) : (
                        <>
                            <div className={this.buildClassName('dropdown-option-upper')}>
                                <span className="option-id">#{test.id}</span>
                                <span className="option-send-date"></span>
                            </div>
                            <div className={this.buildClassName('dropdown-option-lower')}>
                                <span className="option-title">{test.name}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    public getPortalPositioning(): any {
        if (this.ref) {
            const rect = this.ref.getBoundingClientRect()

            return [
                ['position', 'absolute'],
                ['top', `${Math.floor(rect.top) + Math.floor(rect.height)}px`],
                ['left', `${Math.floor(rect.left)}px`],
                ['width', `${Math.floor(rect.width)}px`],
            ]
                .map((p) => p.join(':'))
                .join(';')
        }
    }

    protected get isSearchMode(): boolean {
        return this.props.mode !== 'display'
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get isExpanded(): boolean {
        return this.state.expanded
    }

    protected get isSearching(): boolean {
        return this.state.searching
    }

    protected get hasOptions(): boolean {
        return this.state.visibleTests.length > 0
    }

    protected get hasValue(): boolean {
        return !!this.props.value
    }

    protected get hasInput(): boolean {
        return !!this.inputRef && !!this.inputRef.input.value.trim()
    }

    protected get inputValue(): string {
        return this.hasInput ? this.inputRef.input.value.trim() : ''
    }

    protected get selectedTest(): any | undefined {
        let test: any | undefined

        if (this.hasOptions && this.hasValue) {
            test = this.state.tests.find((ab) => ab.id.toString() === this.props.value.toString())
        }

        return test
    }

    protected get portalContainer(): HTMLElement {
        return !this.props.getDropdownContainer ? document.body : this.props.getDropdownContainer()
    }

    protected updateInputRefValue(value: any): void {
        if (!!this.inputRef) {
            this.inputRef.inputRef.setState({ value })
            this.inputRef.calculateWidth(value)
        }
    }

    protected handleValueChange = async (test: any): Promise<void> => {
        // clear loaded tmpValue
        await this.setState({ tmpValue: undefined })

        this.emitChangeEvent(test.id)
        this.deactivate()

        this.updateInputRefValue(test.name)
    }

    protected handleTestSelect = (value: number) => {
        this.emitChangeEvent(value)
    }

    protected handleInputChange = async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const value = ev.target.value

        this.emitChangeEvent(undefined as any)

        if (this.props.preload || value.length >= 3) {
            this.searchAbTests(value)
        } else {
            this.deactivate()
        }
    }

    protected activate = async (): Promise<void> => {
        this.debounce(() => {
            if (this.props.preload || (this.hasInput && this.inputValue.length > 3)) {
                this.searchAbTests(this.inputValue)
            }
        }, this.debounceValue / 2)
    }

    protected deactivate = async (): Promise<void> => {
        this.debounce(() => {
            this.cancelRequests()
            return this.setState(() => ({ expanded: false }))
        }, this.debounceValue / 2)
    }

    protected cancelRequests = async (): Promise<void> => {
        if (this.axiosCancellationKey in axiosCancellationRequests) {
            axiosCancellationRequests[this.axiosCancellationKey]()
        }

        return this.setState(({ tests }) => ({
            searching: false,
            tests: this.props.preload ? tests : [],
            visibleTests: this.props.preload ? Array.from(tests) : [],
        }))
    }

    protected async preloadAbTests() {
        await this.setState(() => ({ searching: true }))

        const domainId = this.appState.currentDomain!.id

        const { ok, data: tests } = await this.notifService.fetchTestsByDomainId(
            domainId,
            {
                pagination: 0,
                fields: 'id,name',
            },
            false,
            'notif-test-search-preload',
        )

        if (ok) {
            tests.sort(this.alphaSortOptions)

            await this.setState(() => ({
                tests,
                visibleTests: Array.from(tests), // get copy of tests
            }))
        }

        this.setState(() => ({ searching: false }))
    }

    protected async searchAbTests(query: string, reload: boolean = false): Promise<void> {
        this.setState(() => ({
            expanded: !reload,
            searching: !this.props.preload,
            tmpValue: reload ? (query as any) : undefined,
        }))

        if (this.props.preload) {
            this.setState(({ tests }) => ({
                visibleTests: tests.filter(
                    (t) =>
                        !query || t.id.toString() === query || t.name.toLowerCase().indexOf(query.toLowerCase()) >= 0,
                ),
            }))
        } else {
            this.debounce(async () => {
                const domainId = this.appState.currentDomain!.id
                const opts = {
                    search: query,
                    pagination: 0,
                    fields: 'id,name',
                }

                const cancellationKey = `${this.axiosCancellationKey}-${this.uid}`
                const { ok, data: tests } = await this.notifService.fetchTestsByDomainId(
                    domainId,
                    opts,
                    false,
                    cancellationKey,
                )

                if (ok) {
                    tests.sort(this.alphaSortOptions)

                    await this.setState(({ expanded }) => ({
                        tests,
                        visibleTests: Array.from(tests),
                        searching: expanded || tests.length === 0,
                    }))
                }
            })
        }

        if (reload && this.selectedTest) {
            this.updateInputRefValue(this.selectedTest.name)
        }
    }

    protected alphaSortOptions(a: ITestOption, b: ITestOption) {
        const aName = a.name.toLowerCase()
        const bName = b.name.toLowerCase()

        return aName > bName ? 1 : aName < bName ? -1 : 0
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, !!timeout ? timeout : this.debounceValue)
    }

    protected async emitChangeEvent(value: number): Promise<void> {
        this.props.onChange?.(value)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(append?: string): string {
        const classNames: string[] = [
            !!append ? `${this.defaultClassName}-${append}` : this.defaultClassName,
            this.isDisplayMode ? 'mode-display' : 'mode-search',
            this.isExpanded ? 'expanded' : 'collapsed',
            this.isSearching ? 'searching' : 'static',
        ]
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected wireEventHandlers(): void {
        document.addEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.addEventListener('mousedown', preventBubbling)
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.removeEventListener('mousedown', preventBubbling)
    }

    protected handleDocumentClick(event: MouseEvent): void {
        if (this.isExpanded) this.deactivate()
    }
}
