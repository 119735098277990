import * as React from 'react'
import * as CopyToClipboard from 'react-copy-to-clipboard'
import * as randomstring from 'randomstring'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { CopyOutlined, DesktopOutlined, DownloadOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { Button, notification } from 'antd'
import { Well } from '@pushly/aqe/lib/components'
import { DomainService } from '../../../services/domain'
import { isFunction, simpleNotification } from '../../../_utils/utils'
import { AsyncButton } from '../../../components/async-button/async-button.component'
import { BetterComponent } from '../../../components/better-component/better-component'
import { AppState } from '../../../stores/app'
import './get-domain-code.scss'
import { observe } from 'mobx'
import { DomainDto } from '../../../dtos/domain'
import aqe from '@pushly/aqe'

interface IProps {}

interface IState {
    domain?: DomainDto
}

@observer
export class GetDomainCode extends BetterComponent<IProps, IState> {
    private appState: AppState
    private domainService: DomainService

    private codeBox: any
    private workerBox: any

    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)

        this.state = {
            domain: this.appState.currentDomain,
        }
    }

    public UNSAFE_componentWillMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.disposeObservers.forEach((f: any) => f())
    }

    public setDomainState() {
        this.setState({ domain: this.appState.currentDomain })
    }

    public render(): React.ReactNode {
        const { domain } = this.state

        return (
            <div className="get-domain-code-page">
                {this.renderSnippetWell()}
                {this.renderServiceWorkerWell()}
            </div>
        )
    }

    private renderSnippetWell(): React.ReactNode {
        return (
            <Well title="Javascript Code" actions={this.buildSnippetHeader()} hideFooter={true}>
                <pre className="code-snippet" ref={(input) => (this.codeBox = input)}>
                    {this.buildCodeSnippet()}
                </pre>
            </Well>
        )
    }

    private renderServiceWorkerWell(): React.ReactNode {
        return (
            <Well title="Service Worker" actions={this.buildWorkerHeader()} hideFooter={true}>
                <p className="code-description">
                    You will need to download our service worker and install it at the root of your website to ensure
                    your push notifications are delivered and so that users can opt-in to your site.
                </p>
                <p className="code-description">
                    Service workers are a key part of getting push notifications up and running. They offer offline
                    capabilities by providing a way for a web page to run scripts in the background without having the
                    page open.
                </p>
                {/*<pre*/}
                {/*className="code-snippet"*/}
                {/*ref={input => this.workerBox = input}*/}
                {/*>*/}
                {/*{this.buildWorkerExample()}*/}
                {/*</pre>*/}
            </Well>
        )
    }

    private isProxyIntegration(): boolean {
        return !!this.state.domain && !!this.state.domain.customAllowDomain
    }

    @autobind
    private showProxyPreview() {
        const { domain } = this.state

        const domainKey = domain!.domainKey
        let allowDomain = domain!.customAllowDomain
        // sanitize - remove protocol
        allowDomain = allowDomain.replace(/^http[s]?:\/\//i, '')

        const proxyUrl = `https://${allowDomain}/${domainKey}?pushly_domain_key=${domainKey}&swim=preview`
        const features = 'directories=0,toolbar=0,status=0,menubar=0,width=680,height=450'
        const name = 'Push Notification Registration'

        window.open(proxyUrl, name, features)
    }

    private buildCodeSnippet(): string {
        const { domain } = this.state
        const domainKey = domain!.domainKey

        return `
<script src="${aqe.defaults.publicCdnDomain}/pushly-sdk.min.js?domain_key=${domainKey}" async></script>
<script>
  var PushlySDK = window.PushlySDK || [];
  function pushly() { PushlySDK.push(arguments) }
  pushly('load', {
    domainKey: '${this.appState.currentDomain!.domainKey}',
  });
</script>
        `.trim()
    }

    private buildSnippetHeader(): React.ReactNode {
        return [
            <div key="actions" className="custom-actions">
                <AsyncButton
                    className="new-prompt-button"
                    size="small"
                    type="ghost"
                    onClick={this.jumpToDocumentation}
                    altHref={this.buildDocumentationUrl()}
                >
                    <QuestionCircleFilled />
                    <span>Full Documentation</span>
                </AsyncButton>

                {this.isProxyIntegration() && (
                    <Button size="small" type="ghost" onClick={this.showProxyPreview}>
                        <DesktopOutlined />
                        <span>Preview Pop-up</span>
                    </Button>
                )}

                <CopyToClipboard onCopy={this.copyCode} text={this.buildCodeSnippet()}>
                    <Button size="small" type="ghost" onClick={this.copyCode}>
                        <CopyOutlined />
                        <span>Copy Snippet</span>
                    </Button>
                </CopyToClipboard>

                <Button
                    size="small"
                    type="ghost"
                    onClick={() => this.downloadCode(this.codeBox, 'integration-code.txt', 'Javascript code ready!')}
                >
                    <DownloadOutlined />
                    <span>Download Snippet</span>
                </Button>
            </div>,
        ]
    }

    private buildWorkerExample(): string {
        return `
<script src="/pushly-sdk-worker.js" type="application/javascript" />
        `.trim()
    }

    private buildServiceWorker(): string {
        return `
importScripts("${aqe.defaults.publicCdnDomain}/pushly-sw.min.js" + (self.location || {}).search || '');
        `.trim()
    }

    private buildWorkerHeader(): React.ReactNode {
        return [
            <div key="actions" className="custom-actions">
                <Button
                    size="small"
                    type="ghost"
                    onClick={() =>
                        this.downloadCode(
                            this.buildServiceWorker,
                            'pushly-sdk-worker.js',
                            'Service worker ready!',
                            'application/javascript',
                        )
                    }
                >
                    <DownloadOutlined />
                    <span>pushly-sdk-worker.js</span>
                </Button>
            </div>,
        ]
    }

    private buildDocumentationUrl(): string {
        return this.appState.documentationLink
    }

    @autobind
    private jumpToDocumentation(): void {
        window.open(this.buildDocumentationUrl())
    }

    @autobind
    private copyCode(): void {
        notification.destroy()
        simpleNotification('success', 'Copied!')
    }

    @autobind
    private downloadCode(container: any, filename: string, message: string, mimeType?: string): void {
        mimeType = mimeType || 'text/plain'
        const element = document.createElement('a')
        const file = new Blob([isFunction(container) ? container() : container.innerText], { type: mimeType })
        element.href = URL.createObjectURL(file)
        element.download = filename
        element.id = randomstring.generate()
        element.setAttribute('style', 'display: none')
        document.body.appendChild(element)

        element.click()
        document.body.removeChild(element)

        notification.destroy()
        simpleNotification('success', message)
    }
}
