import * as React from 'react'
import { KeywordManagerContext } from './keyword-manager-context'
import classnames from 'classnames'
import { Button, Divider, Dropdown, Input, Menu } from 'antd'
import { KeywordManagerState } from './interfaces'
import { DownloadOutlined, DownOutlined, FolderOutlined } from '@ant-design/icons'
import { AsyncButton } from '../async-button/async-button.component'

type ActionKey = keyof KeywordManagerState['actions']

interface IKMLHProps {
    title?: string
    hideSearch?: boolean
    filterSize?: 'small' | 'middle' | 'large'
    baseClassName?: string
    hideActions?: ActionKey | ActionKey[] | boolean
}

export const KeywordManagerListHeader = (props: IKMLHProps) => {
    const context: React.ContextType<typeof KeywordManagerContext> = React.useContext(KeywordManagerContext)
    let liveTypeTimeout

    const {
        onExportClick,
        onAddKeywordsClick,
        onSearchClick,
        onFilterChange,
        onHandleBulkMenuClick,
        filters,
        selectedKeywords,
    } = context

    const { title, hideSearch, hideActions, filterSize } = props

    const baseClassName = props.baseClassName ?? 'keyword-manager'

    const allActions: ActionKey[] = ['addKeywords', 'bulkKeywordActions', 'exportKeywords']

    let actionsHidden: ActionKey[] = []
    if (hideActions === true) {
        actionsHidden = Array.from(allActions)
    } else if (hideActions) {
        actionsHidden = Array.isArray(hideActions) ? hideActions : [hideActions]
    }

    const hideAllActions = actionsHidden.length === allActions.length
    const allBulkActions = ['delete']

    const enabled = selectedKeywords.length > 0

    return (
        <div className={classnames(`${baseClassName}-list-header`)}>
            <div
                className={classnames(`${baseClassName}-list-title`, {
                    hidden: !title,
                })}
            >
                <span>{title}</span>
            </div>

            <div
                className={classnames(`${baseClassName}-list-filters`, {
                    hidden: hideSearch,
                })}
            >
                <Input.Search
                    className={classnames(`${baseClassName}-list-search`)}
                    size={filterSize ?? 'middle'}
                    enterButton={true}
                    placeholder="Search ..."
                    defaultValue={filters.search}
                    onSearch={onSearchClick}
                    onChange={(ev) => {
                        clearTimeout(liveTypeTimeout)
                        const value = ev.target.value
                        liveTypeTimeout = setTimeout(() => {
                            onFilterChange('search', value)
                        }, 320)
                    }}
                />
            </div>

            <div
                className={classnames(`${baseClassName}-list-actions`, {
                    hidden: hideAllActions,
                })}
            >
                {!actionsHidden.includes('addKeywords') && (
                    <Button size="middle" type="primary" onClick={onAddKeywordsClick}>
                        <span>Add Keywords</span>
                    </Button>
                )}

                {!actionsHidden.includes('bulkKeywordActions') && (
                    <Dropdown
                        disabled={!enabled}
                        trigger={['click']}
                        overlay={
                            <Menu onClick={onHandleBulkMenuClick}>
                                <Menu.Item key="action-archive">
                                    <FolderOutlined />
                                    Archive
                                </Menu.Item>
                            </Menu>
                        }
                    >
                        <Button size="middle" type={enabled ? 'primary' : 'default'}>
                            <span>Bulk Actions</span> <DownOutlined />
                        </Button>
                    </Dropdown>
                )}

                {!actionsHidden.includes('exportKeywords') && (
                    <>
                        <Divider className={`${baseClassName}-list-actions-divider`} type="vertical" />
                        <AsyncButton
                            className={`${baseClassName}-list-actions-export`}
                            key="export"
                            onClick={onExportClick}
                        >
                            <DownloadOutlined />
                            <span>Export</span>
                        </AsyncButton>
                    </>
                )}
            </div>
        </div>
    )
}
