import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

export class NotificationExperienceModel {
    public static build(props: NotificationExperienceModel | any): NotificationExperienceModel {
        const data = props instanceof NotificationExperienceModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationExperienceModel()
        model.setIsSilent(scData.is_silent)
        model.setRequireInteraction(scData.require_interaction)
        model.setTtlSeconds(scData.ttl_seconds)
        model.setTtlMetric(scData.ttl_metric)

        return model
    }

    private isSilent: boolean = false
    private requireInteraction: boolean = true
    private ttlSeconds: number | undefined
    private ttlMetric: string | undefined

    public clone(): NotificationExperienceModel {
        return NotificationExperienceModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            is_silent: this.getIsSilent(),
            require_interaction: this.getRequireInteraction(),
            ttl_seconds: this.getTtlSeconds(),
            ttl_metric: this.getTtlMetric(),
        })
    }

    public getIsSilent(): boolean | undefined {
        return this.isSilent
    }
    public setIsSilent(isSilent: boolean | undefined): void {
        this.isSilent = isSilent ?? false
    }

    public getRequireInteraction(): boolean | undefined {
        return this.requireInteraction
    }
    public setRequireInteraction(requireInteraction: boolean | undefined): void {
        this.requireInteraction = requireInteraction ?? true
    }

    public getTtlSeconds(): number | undefined {
        return this.ttlSeconds
    }
    public setTtlSeconds(seconds: number | undefined): void {
        this.ttlSeconds = seconds
    }

    public getTtlMetric(): string | undefined {
        return this.ttlMetric
    }
    public setTtlMetric(metric: string | undefined): void {
        this.ttlMetric = metric
    }
}
