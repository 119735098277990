import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { ChromeOutlined, CloseOutlined, IeOutlined } from '@ant-design/icons'
import '@ant-design/compatible/assets/index.css'
import { Radio } from 'antd'
import { BetterComponent } from '../../../components/better-component/better-component'
import { DomainDto } from '../../../dtos/domain'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import autobind from 'autobind-decorator'
import './system-prompt-preview-builder.scss'

interface IProps {}

interface IState {
    domain: DomainDto
    browser: string
}

@observer
export class SystemPromptPreviewBuilder extends BetterComponent<IProps, IState> {
    private appState: AppState

    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)

        this.state = {
            domain: this.appState.currentDomain!,
            browser: 'chrome',
        }
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.unmounting = true

        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        let preview: React.ReactNode = ''

        switch (this.state.browser) {
            case 'chrome':
                preview = this.renderChromePrompt()
                break
            case 'firefox':
                preview = this.renderFirefoxPrompt()
                break
            case 'edge':
                preview = this.renderEdgePrompt()
                break
        }

        return (
            <div className="system-prompt-preview-wrapper">
                <div>
                    <Radio.Group size="small" onChange={this.setBrowserState} value={this.state.browser}>
                        <Radio.Button value="chrome">
                            <ChromeOutlined />
                            <span>Chrome</span>
                        </Radio.Button>
                        <Radio.Button value="firefox">
                            <i className="firefox-browser-icon" />
                            <span>Firefox</span>
                        </Radio.Button>
                        <Radio.Button value="edge">
                            <IeOutlined />
                            <span>Edge</span>
                        </Radio.Button>
                    </Radio.Group>
                </div>

                <div className="system-prompt-preview">{preview}</div>
            </div>
        )
    }

    protected renderChromePrompt(): React.ReactNode {
        return (
            <div className="chrome-wrapper">
                <div className="chrome-dismiss">
                    <CloseOutlined />
                </div>

                <div className="chrome-inner">
                    <div className="chrome-starter">
                        <span className="domain-name">{this.state.domain.name}</span>
                        <span>wants to</span>
                    </div>

                    <div className="chrome-wants-to">
                        <i className="chrome-bell-icon" /> <span>Show notifications</span>
                    </div>

                    <div className="chrome-footer">
                        <div className="chrome-close">Block</div>
                        <div className="chrome-allow">Allow</div>
                    </div>
                </div>
            </div>
        )
    }

    protected renderFirefoxPrompt(): React.ReactNode {
        return (
            <div className="ff-wrapper">
                <div className="ff-inner">
                    <div className="ff-inner-left">
                        <img src="/assets/ff-chat-icon.png" />
                    </div>
                    <div className="ff-inner-right">
                        <div className="ff-starter">
                            <span>Will you allow </span>
                            <span className="domain-name">{this.state.domain.name}</span>
                            <span>to send notifications?</span>
                        </div>

                        <div className="ff-learn-more">Learn More...</div>
                    </div>
                </div>

                <div className="ff-footer">
                    <div className="ff-close">
                        <div className="ff-close-left">Not Now</div>
                        <div className="ff-close-right" />
                    </div>
                    <div className="ff-allow">Allow Notifications</div>
                </div>
            </div>
        )
    }

    protected renderEdgePrompt(): React.ReactNode {
        return (
            <div className="edge-wrapper">
                <div className="edge-inner">
                    <div className="edge-starter">
                        <div className="text-wrapper">
                            <span>Let </span>
                            <span className="domain-name">{this.state.domain.name}</span>
                            <span>show notifications?</span>
                        </div>
                    </div>

                    <div className="edge-footer">
                        <div className="edge-allow">Yes</div>
                        <div className="edge-close">No</div>
                    </div>

                    <div className="edge-dismiss">
                        <CloseOutlined />
                    </div>
                </div>
            </div>
        )
    }

    protected setDomainState(): void {
        if (this.appState.currentDomain) {
            this.setState({ domain: this.appState.currentDomain })
        }
    }

    protected setBrowserState = (e: any): void => {
        if (e && e.target) {
            this.setState({ browser: e.target.value })
        }
    }
}
