import React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { Select } from 'antd'
import { ITypeEditor } from '../interfaces'
import { updateNodeParams } from '../helpers/node'

const ProfileUpdateEditor = ({ node, onChange, readonly }: ITypeEditor) => {
    return (
        <>
            <Well mode="ghost" title="Add Keywords" hideFooter={true}>
                <Select
                    className="campaign-action-editor-tag-select"
                    dropdownClassName="campaign-action-editor-tag-dropdown"
                    size="small"
                    mode="tags"
                    value={node.properties?.[node.type]?.params?.tags}
                    onChange={(tags: string[]) =>
                        onChange(
                            updateNodeParams(node, {
                                tags,
                            }),
                        )
                    }
                    disabled={readonly}
                />
            </Well>
        </>
    )
}

export default ProfileUpdateEditor
