import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Select, Tooltip, DatePicker } from 'antd'
import autobind from 'autobind-decorator'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import { IRuleGroup } from '../../interfaces/rule-group'
import * as clone from 'clone'
import { BetterSelect } from '../../../better-select/better-select'
import { AppService, DomainService } from '../../../../services/index'
import { Container } from 'typescript-ioc/es5'
import * as randomstring from 'randomstring'
import { RuleNodeType } from '../../enums/rule-node-type'
import './subscriber-preferences.input.scss'
import { AppState } from '../../../../stores/app'
import * as moment from 'moment-timezone'
import { BASE_DATE_FORMAT } from '../../../../constants'
import { Moment } from 'moment'
import { baseOperators } from '../../../rule-builder/operators'

interface IPreferencesInputProps {
    field: IRuleBuilderField
    rule: IRule
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
}

interface IPreferencesInputState {
    preferences: string[]
    operator?: string
}

export enum PreferenceField {
    PREFERENCE = 'preference',
    DATE = 'added_date',
    RELATIONSHIP = 'relationship',
}

export class SubscriberPreferencesInput extends BetterComponent<IPreferencesInputProps, IPreferencesInputState> {
    public static operators = [baseOperators.bool_eq, baseOperators.bool_neq]

    public readonly defaultClassName = 'sw-v2-rb-preferences-input'
    public ref: any

    protected appState: AppState
    protected appService: AppService
    protected domainService: DomainService

    public constructor(props: IPreferencesInputProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.domainService = Container.get(DomainService)

        this.state = {
            preferences: [],
        }
    }

    public componentDidMount(): void {
        this.ensurePreferences()
    }

    public componentDidUpdate(prevProps: Readonly<IPreferencesInputProps>): void {
        // Clear temp operator when moving back to display mode
        if (this.props.mode === 'display' && prevProps.mode !== 'display') {
            this.setState({ operator: undefined })
        }
    }

    public render(): React.ReactNode {
        const tooltip = (
            <span>
                <Tooltip
                    overlayClassName="preference-relation-tooltip"
                    title={
                        <div>
                            Relationship describes how the preference was determined for the subscriber:
                            <ul>
                                <li>
                                    Inferred: The preference was set based on the subscriber’s browsing activity or
                                    interest in related preferences.
                                </li>
                                <li>
                                    Explicit: The subscriber specifically indicated they were interested in this
                                    preference.
                                </li>
                            </ul>
                        </div>
                    }
                >
                    <InfoCircleOutlined className="info-icon" />
                </Tooltip>
            </span>
        )

        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                {this.isDisplayMode ? (
                    <div className={this.buildClassName('display')}>
                        <span className="highlight">{this.currentOperatorDisplay} </span>

                        <span>{this.currentPreferenceDisplay} </span>

                        {this.currentOperatorValue === 'eq' && (
                            <>
                                <span className="highlight">and was set </span>

                                <span>
                                    <span>{this.currentDateTypeDisplay} </span>
                                    {this.currentDateTypeValue !== 'any' && <span>{this.currentDateDisplay} </span>}
                                </span>

                                <span>{this.currentRelationDisplay}</span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className={this.buildClassName('editor')}>
                        <div className="g1">
                            {/*
                                TODO: Unlock operators once new Nested NEQ support is released
                            */}
                            {/*<span className={this.buildClassName('locked-operator')}>*/}
                            {/*    {this.currentOperatorDisplay}*/}
                            {/*</span>*/}
                            <div className={this.buildClassName('operator-select')}>
                                <Select
                                    dropdownClassName={this.buildClassName('operator-dropdown')}
                                    onChange={this.handleOperatorChange}
                                    value={this.currentOperatorValue}
                                >
                                    {SubscriberPreferencesInput.operators.map((o) => (
                                        <Select.Option key={o.value} value={o.value}>
                                            {o.longDisplay}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>

                            <div className={this.buildClassName('preference-select')}>
                                <BetterSelect
                                    dropdownClassName={this.buildClassName('preferences-dropdown')}
                                    mode="single"
                                    placeholder="Select a Preference"
                                    value={!!this.currentPreferenceValue ? [this.currentPreferenceValue] : []}
                                    onChange={this.handlePreferenceChange}
                                    options={this.preferenceOptions}
                                    disableSearch={this.state.preferences.length <= 7}
                                    disableSelectAll={true}
                                />
                            </div>
                        </div>

                        {this.currentOperatorValue === 'eq' && (
                            <>
                                <div className="g2">
                                    <div>and was set</div>

                                    <div className={this.buildClassName('date-type-select')}>
                                        <Select
                                            dropdownClassName={this.buildClassName('date-type-dropdown')}
                                            onChange={this.handleDateTypeChange}
                                            value={this.currentDateTypeValue}
                                        >
                                            <Select.Option key="any" value="any">
                                                any time
                                            </Select.Option>
                                            <Select.Option key="before" value="before">
                                                before
                                            </Select.Option>
                                            <Select.Option key="after" value="after">
                                                after
                                            </Select.Option>
                                        </Select>
                                    </div>

                                    {this.currentDateTypeValue !== 'any' && (
                                        <>
                                            <div className={this.buildClassName('date-select')}>
                                                <DatePicker
                                                    dropdownClassName={this.buildClassName('date-dropdown')}
                                                    // @ts-ignore
                                                    value={this.currentDateValue}
                                                    onChange={this.handleDateChange}
                                                    format={BASE_DATE_FORMAT}
                                                />
                                            </div>

                                            {/*<div className="flex-row-break" />*/}
                                        </>
                                    )}
                                </div>

                                <div className="g3">
                                    <div>with relationship</div>

                                    <div className={this.buildClassName('relation-select')}>
                                        <Select
                                            dropdownClassName={this.buildClassName('relation-dropdown')}
                                            onChange={this.handleRelationChange}
                                            value={this.currentRelationValue}
                                        >
                                            <Select.Option key="any" value="any">
                                                any
                                            </Select.Option>
                                            <Select.Option key="inferred" value="inferred">
                                                inferred
                                            </Select.Option>
                                            <Select.Option key="explicit" value="explicit">
                                                explicit
                                            </Select.Option>
                                        </Select>
                                    </div>

                                    {tooltip}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }

        if (!this.currentPreferenceValue) {
            response.ok = false
            response.error = 'Please select at least one valid preference.'
        }

        if (this.currentDateTypeValue !== 'any' && !this.currentDateValue) {
            response.ok = false
            response.error = 'Please select a valid date.'
        }

        return response
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get preferenceGroupIndex(): number {
        return this.findGroupIndexByProperty(PreferenceField.PREFERENCE)
    }

    protected get dateGroupIndex(): number {
        return this.findGroupIndexByProperty(PreferenceField.DATE)
    }

    protected get relationGroupIndex(): number {
        return this.findGroupIndexByProperty(PreferenceField.RELATIONSHIP)
    }

    protected get preferenceRule(): IRule | undefined {
        return this.findRuleByProperty(PreferenceField.PREFERENCE)
    }

    protected get dateRule(): IRule | undefined {
        return this.findRuleByProperty(PreferenceField.DATE)
    }

    protected get relationRule(): IRule | undefined {
        return this.findRuleByProperty(PreferenceField.RELATIONSHIP)
    }

    protected get currentOperatorValue(): string {
        const pRule = this.preferenceRule
        return this.state.operator || (!!pRule ? pRule.rule.operator : 'eq')
    }

    protected get currentPreferenceValue(): string | undefined {
        const rule = this.preferenceRule
        let value: string | undefined

        if (!!rule && !!rule.rule.value) {
            value = rule.rule.value
        }

        return value
    }

    protected get currentDateTypeValue(): string {
        const rule = this.dateRule
        let value: string = 'any'

        if (!!rule && !!rule.rule.operator) {
            try {
                value = rule.rule.operator === 'gt' ? 'after' : 'before'
            } catch {}
        }

        return value
    }

    protected get currentDateValue(): Moment | null | undefined {
        const rule = this.dateRule
        let value: Moment | undefined

        if (!!rule && !!rule.rule.value) {
            try {
                value = moment(rule.rule.value)
            } catch {}
        }

        return value
    }

    protected get currentRelationValue(): string | undefined {
        const rule = this.relationRule
        let value: string = 'any'

        if (!!rule && !!rule.rule.value) {
            value = rule.rule.value
        }

        return value
    }

    protected get currentOperatorDisplay(): string {
        const value = this.currentOperatorValue
        return value === 'eq' ? 'is' : 'is not'
    }

    protected get currentPreferenceDisplay(): React.ReactNode {
        return this.currentPreferenceValue || 'No Preference Selected'
    }

    protected get currentDateTypeDisplay(): React.ReactNode {
        const value = this.currentDateTypeValue
        return value === 'any' ? 'any time' : value
    }

    protected get currentDateDisplay(): React.ReactNode {
        const value = this.currentDateValue
        return !!value ? value.format(BASE_DATE_FORMAT) : undefined
    }

    protected get currentRelationDisplay(): React.ReactNode {
        const value = this.currentRelationValue || 'any'

        return value === 'any' ? (
            <>
                <span className="highlight">with</span>
                <span> any relationship</span>
            </>
        ) : (
            <>
                <span className="highlight">with relationship</span>
                <span> {value}</span>
            </>
        )
    }

    protected get preferenceOptions(): any[] {
        return this.state.preferences.map((p) => ({
            value: p,
        }))
    }

    protected findGroupIndexByProperty(property: string): number {
        return this.rule.value.findIndex((group: IRuleGroup) => {
            const ruleIdx = group.rules.findIndex((r) => r.rule.property === property)
            return ruleIdx !== -1
        })
    }

    protected findRuleByProperty(property: string): IRule | undefined {
        const groupIdx = this.findGroupIndexByProperty(property)
        return groupIdx !== -1 ? clone(this.rule.value[groupIdx].rules[0]) : undefined
    }

    protected buildNestedRule(property: PreferenceField, rule: Partial<IRuleDefinition>): IRuleGroup {
        return {
            id: randomstring.generate(),
            conditionType: RuleNodeType.AND,
            rules: [
                {
                    id: randomstring.generate(),
                    conditionType: RuleNodeType.OR,
                    rule: {
                        ...rule,
                        builderProperty: property,
                        property,
                    } as IRuleDefinition,
                },
            ],
        }
    }

    @autobind
    protected async handleOperatorChange(value: string): Promise<void> {
        const rules = clone(this.rule.value)
        const pRule = this.preferenceRule
        const pGroupIdx = this.preferenceGroupIndex
        const dRule = this.dateRule
        const dGroupIdx = this.dateGroupIndex
        const rRule = this.relationRule
        const rGroupIdx = this.relationGroupIndex

        await this.setState({ operator: value })

        if (!!pRule) {
            rules[pGroupIdx].rules[0].rule.operator = value

            if (value === 'neq') {
                if (!!dRule) rules.splice(dGroupIdx)
                if (!!rRule) rules.splice(rGroupIdx)
            }
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handlePreferenceChange(value: string): Promise<void> {
        const rules = clone(this.rule.value)
        const rule = this.preferenceRule
        const groupIdx = this.preferenceGroupIndex

        if (!!rule) {
            if (!value) {
                rules.splice(groupIdx)
            } else {
                rules[groupIdx].rules[0].rule.value = value
            }
        } else {
            if (!!value) {
                rules.push(
                    this.buildNestedRule(PreferenceField.PREFERENCE, {
                        operator: this.currentOperatorValue,
                        value,
                    }),
                )
            }
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleDateTypeChange(value: string): Promise<void> {
        const rules = clone(this.rule.value)
        const rule = this.dateRule
        const groupIdx = this.dateGroupIndex
        const operator = value !== 'any' && value === 'before' ? 'lt' : 'gt'

        if (!!rule) {
            if (!value || value === 'any') {
                rules.splice(groupIdx)
            } else {
                rules[groupIdx].rules[0].rule.operator = operator
            }
        } else {
            rules.push(
                this.buildNestedRule(PreferenceField.DATE, {
                    operator,
                }),
            )
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleDateChange(value: Moment | null): Promise<void> {
        const rules = clone(this.rule.value)
        const rule = this.dateRule
        const groupIdx = this.dateGroupIndex

        if (rule) {
            if (!value) {
                delete rules[groupIdx].rules[0].rule.value
            } else {
                rules[groupIdx].rules[0].rule.value = value.format('YYYY-MM-DD')
            }
        } else {
            rules.push(
                this.buildNestedRule(PreferenceField.DATE, {
                    operator: 'any',
                    value: value?.format('YYYY-MM-DD'),
                }),
            )
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleRelationChange(value: string): Promise<void> {
        const rules = clone(this.rule.value)
        const rule = this.relationRule
        const groupIdx = this.relationGroupIndex

        if (!!rule) {
            if (!value || (value === 'any' && groupIdx !== -1)) {
                rules.splice(groupIdx)
            } else {
                rules[groupIdx].rules[0].rule.value = value
            }
        } else {
            rules.push(
                this.buildNestedRule(PreferenceField.RELATIONSHIP, {
                    operator: 'eq',
                    value,
                }),
            )
        }

        this.emitChange(rules)
    }

    protected cleanRules(rules: IRuleGroup[]): any {
        const response: IRuleGroup[] = []

        for (const group of rules) {
            if (group.rules.length > 0) {
                for (const rule of group.rules) {
                    if (!rule.rule || Object.keys(rule.rule).length === 0) {
                        const ruleIdx = group.rules.findIndex((r) => r.id === rule.id)
                        delete group.rules[ruleIdx]
                    }
                }

                response.push(group)
            }
        }

        return response
    }

    protected async emitChange(rules: IRuleGroup[]): Promise<void> {
        return this.props.onChange(this.cleanRules(rules))
    }

    protected async ensurePreferences(): Promise<void> {
        const preferences = await this.domainService.fetchSubscriberPreferencesByDomainId(
            this.appState.currentDomain!.id,
        )

        return this.setState({ preferences })
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.currentDateTypeValue !== 'any') classNames.push('stacked')

        return classNames.join(' ')
    }
}
