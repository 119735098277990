import * as React from 'react'
import { BetterComponent } from '../../../components/better-component/better-component'
import SegmentBuilder from '../../../components/segment-builder/segment-builder'
import { AppState } from '../../../stores/app'
import { NotFound404 } from '../../../components/404/404'
import { Container } from 'typescript-ioc'
import { SegmentModel } from '../../../models/segments/segment.model'
import { SegmentService } from '../../../services/segment'

interface ISCTProps {
    segment?: SegmentModel
}

interface ISCTState {
    segmentFields: any[]
}

export class SegmentConfigTab extends BetterComponent<ISCTProps, ISCTState> {
    protected static tabName = 'configuration'
    protected static tabLabel = 'Configuration'

    private appState: AppState
    private segmentSvc: SegmentService

    public constructor(props: ISCTProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.segmentSvc = Container.get(SegmentService)

        this.state = {
            segmentFields: [],
        }
    }

    public render() {
        const isEmptyConfig = !this.props.segment || Object.keys(this.props.segment.getFiltersJson()).length === 0

        return (
            <div className="segment-details-config">
                {isEmptyConfig ? (
                    <NotFound404 label="This segment targets all subscribers" />
                ) : (
                    <SegmentBuilder
                        mode={'standard'}
                        level={'domain'}
                        domainId={this.appState.currentDomain!.id}
                        segmentId={this.props.segment!.getId()}
                        readonly={true}
                        name={this.props.segment?.getName()}
                    />
                )}
            </div>
        )
    }
}
