import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { stripUndefined } from '../../_utils/strip-undefined'
import { SendIntegrationPrivateKeyType } from '../../enums/send-integration-private-key-type.enum'
import { NativeApnsEnvironment } from '../../enums/native-apns-environment'
import { ApnsEnvironment } from '@pushly/models/lib/enums/apns-environment'
import { NativeApnsP8SendIntegration } from '@pushly/models/lib/interfaces/native-apns-configurations'
import { P8NativeApnsSendIntegration } from '../../interfaces/send-integrations'

const DEFAULT_APNS_ENV = ApnsEnvironment.PRODUCTION

export class AbstractNativeApnsConfiguration {
    private is_active: boolean = false
    private app_bundle_id: string | undefined
    private private_key_type: SendIntegrationPrivateKeyType | undefined
    private private_key_file_url: string | undefined
    private apns_environment: ApnsEnvironment
    private additional_permissions: number

    public getIsActive(): boolean {
        return this.is_active
    }
    public setIsActive(value: boolean) {
        this.is_active = value
    }

    public getAppBundleId(): string | undefined {
        return this.app_bundle_id
    }
    public setAppBundleId(value: string | undefined) {
        this.app_bundle_id = value?.trim()
    }

    public getPrivateKeyType(): SendIntegrationPrivateKeyType | undefined {
        return this.private_key_type
    }
    public setPrivateKeyType(value: SendIntegrationPrivateKeyType | undefined) {
        this.private_key_type = value
    }

    public getPrivateKeyFileUrl(): string | undefined {
        return this.private_key_file_url
    }
    public setPrivateKeyFileUrl(value: string | undefined) {
        this.private_key_file_url = value?.trim()
    }

    public getAdditionalPermissions(): number {
        return this.additional_permissions
    }
    public setAdditionalPermissions(value: number) {
        this.additional_permissions = value
    }

    public getApnsEnvironment(): ApnsEnvironment {
        return this.apns_environment ?? DEFAULT_APNS_ENV
    }

    public setApnsEnvironment(env: ApnsEnvironment | NativeApnsEnvironment) {
        this.apns_environment = ApnsEnvironment[env]
    }

    public get isProductionApnsEnvironment(): boolean {
        const env = this.apns_environment ?? DEFAULT_APNS_ENV
        return env === ApnsEnvironment.PRODUCTION
    }
}

export class NativeP12ApnsConfiguration extends AbstractNativeApnsConfiguration {
    public static build(props: any | NativeP12ApnsConfiguration): NativeP12ApnsConfiguration {
        const data = props instanceof NativeP12ApnsConfiguration ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NativeP12ApnsConfiguration()
        model.setIsActive(scData.is_active ?? model.getIsActive())
        model.setAppBundleId(scData.app_bundle_id)
        model.setPrivateKeyFileUrl(scData.private_key_file_url)
        model.setPrivateKeyType(scData.private_key_type ?? SendIntegrationPrivateKeyType.P12)
        model.setPrivateKeyPassPhrase(scData.private_key_pass_phrase)
        model.setExpiration(scData.expiration)
        model.setApnsEnvironment(scData.apns_environment)
        model.setAdditionalPermissions(scData.additional_permissions)

        return model
    }

    private private_key_pass_phrase: string | undefined
    private expiration: string | undefined

    public clone(): NativeP12ApnsConfiguration {
        return NativeP12ApnsConfiguration.build(this)
    }

    public serialize(_: boolean = true): any {
        if (!this.getIsActive()) {
            return { is_active: false }
        }

        return stripUndefined({
            is_active: this.getIsActive(),
            app_bundle_id: this.getAppBundleId(),
            private_key_type: this.getPrivateKeyType(),
            private_key_file_url: this.getPrivateKeyFileUrl(),
            private_key_pass_phrase: this.getPrivateKeyPassPhrase() ?? null,
            expiration: this.getExpiration(),
            apns_environment: this.getApnsEnvironment(),
            additional_permissions: this.getAdditionalPermissions(),
        })
    }

    public getPrivateKeyPassPhrase(): string | undefined {
        return this.private_key_pass_phrase
    }
    public setPrivateKeyPassPhrase(value: string | undefined) {
        this.private_key_pass_phrase = value?.trim()
    }

    public getExpiration(): string | undefined {
        return this.expiration
    }
    public setExpiration(value: string) {
        this.expiration = value
    }
}

export class NativeP8ApnsConfiguration extends AbstractNativeApnsConfiguration {
    public static build(props: any | NativeP8ApnsConfiguration): NativeP8ApnsConfiguration {
        const data = props instanceof NativeP8ApnsConfiguration ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NativeP8ApnsConfiguration()
        model.setIsActive(scData.is_active ?? model.getIsActive())
        model.setPrivateKeyType(scData.private_key_type ?? SendIntegrationPrivateKeyType.P8)
        model.setPrivateKeyFileUrl(scData.private_key_file_url)
        model.setTeamId(scData.team_id)
        model.setKeyId(scData.key_id)
        model.setApnsEnvironment(scData.apns_environment)
        model.setAdditionalPermissions(scData.additional_permissions)

        return model
    }

    private team_id: string | undefined
    private key_id: string | undefined

    public clone(): NativeP8ApnsConfiguration {
        return NativeP8ApnsConfiguration.build(this)
    }

    public serialize(_: boolean = true): any {
        if (!this.getIsActive()) {
            return { is_active: false }
        }

        return stripUndefined({
            is_active: this.getIsActive(),
            app_bundle_id: this.getAppBundleId(),
            private_key_type: this.getPrivateKeyType(),
            private_key_file_url: this.getPrivateKeyFileUrl(),
            team_id: this.getTeamId(),
            key_id: this.getKeyId(),
            apns_environment: this.getApnsEnvironment(),
            additional_permissions: this.getAdditionalPermissions(),
        })
    }

    public getTeamId(): string | undefined {
        return this.team_id
    }
    public setTeamId(value: string) {
        this.team_id = value?.trim()
    }

    public getKeyId(): string | undefined {
        return this.key_id
    }
    public setKeyId(value: string) {
        this.key_id = value?.trim()
    }
}
