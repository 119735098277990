import * as React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Select } from 'antd'
import { Well } from '@pushly/aqe/lib/components'
import { ITypeEditor } from '../interfaces'
import { updateNodeParams } from '../helpers/node'
import { CampaignExitStatus } from '../enums'
import '../style/exit-editor.scss'

export class ExitEditor extends React.PureComponent<ITypeEditor> {
    public render() {
        const { node, onChange } = this.props
        const exitStatus = node.properties?.[node.type]?.params?.exit_state ?? CampaignExitStatus.COMPLETED

        return (
            <>
                <Well mode="ghost" title="Subscriber Exit State" hideFooter={true}>
                    <span>
                        When the subscriber exits the campaign from this <b>Exit Condition</b>
                        <br />
                        their campaign state should be set to:
                    </span>
                    <Form.Item>
                        <Select
                            className="campaign-exit-editor-state-selection"
                            dropdownClassName="campaign-exit-editor-state-dropdown"
                            value={exitStatus}
                            onChange={(value: string) =>
                                onChange(
                                    updateNodeParams(node, {
                                        exit_state: value,
                                    }),
                                )
                            }
                        >
                            <Select.Option value={CampaignExitStatus.COMPLETED}>Completed</Select.Option>
                            <Select.Option value={CampaignExitStatus.REMOVED}>Removed</Select.Option>
                        </Select>
                    </Form.Item>
                </Well>
            </>
        )
    }
}
