import * as React from 'react'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import autobind from 'autobind-decorator'
import { preventAll } from '@pushly/aqe/lib/utils/prevent'

interface IProps extends React.AnchorHTMLAttributes<any> {
    href: string
}

export class SwLink extends React.PureComponent<IProps> {
    protected appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        return (
            <a className={this.props.className} href={this.props.href} onClick={this.handleClick}>
                {this.props.children}
            </a>
        )
    }

    @autobind
    protected handleClick(ev: React.MouseEvent<HTMLAnchorElement>): void {
        if (!ev || !ev.metaKey) {
            preventAll(ev)
            this.appService.route(this.props.href)
        }
    }
}
