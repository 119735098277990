import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './home.scss'
import { PageHeader } from '../../components/page-header/page-header'
import { RecentNotifications } from '../recent-notifications/recent-notifications'
import { DomainStatsOverview } from '../domain-stats-overview/domain-stats-overview'

interface IHomeProps {}

interface IHomeState {}

export class Home extends BetterComponent<IHomeProps, IHomeState> {
    public readonly defaultClassName: string = 'sw-mv-home'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IHomeProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public UNSAFE_componentWillMount() {
        this.appService.resetContentScrollPosition()
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <PageHeader title="Dashboard" />

                <DomainStatsOverview />
                <RecentNotifications limit={5} />
            </div>
        )
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName, 'page-view']

        return classNames.join(' ')
    }
}
