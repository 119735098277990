import * as React from 'react'
import classnames from 'classnames'
import { Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { AppState } from '../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { AppService } from '../../services'
import { observer } from 'mobx-react'

@observer
class SidebarCollapseToggle extends React.Component {
    private appState: AppState
    private appService: AppService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { sidebar } = this.appState

        return (
            <div
                className={classnames('sidebar-toggle', {
                    open: !sidebar.collapsed,
                    collapsed: sidebar.collapsed,
                })}
                onClick={() => this.appService.toggleSidebar()}
            >
                <Button
                    className={classnames('sidebar-toggle-btn')}
                    size="small"
                    shape="circle"
                    icon={<LeftOutlined />}
                />
            </div>
        )
    }
}

export default SidebarCollapseToggle
