import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { GlobalOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

export const NotificationStzBadge: React.StatelessComponent<IBadgeProps> = (props) => {
    const badge = (
        <span className="icon-stz">
            <GlobalOutlined />
        </span>
    )

    return (
        <span className="notification-stz-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || "Subscriber's Time Zone"}>{badge}</Tooltip>}
        </span>
    )
}
