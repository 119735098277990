export interface ISlidePromptTheme {
    layout: 'normal' | 'stacked'
    position: {
        desktop: 'bottom' | 'top'
        mobile: 'bottom' | 'top'
    }
    title: {
        text: string
        textColor: string
    }
    subtitle: {
        text: string
        textColor: string
    }
    image?: string
    backgroundColor: string
    allowButton: {
        text: string
        textColor: string
        backgroundColor: string
    }
    dismissButton: {
        text: string
        textColor: string
        backgroundColor: string
    }
}

interface ISlidePromptBehaviors {
    cookieLengthSeconds?: number
}

export class PromptSlideConfigDto {
    public theme: ISlidePromptTheme
    public behavior: ISlidePromptBehaviors
}
