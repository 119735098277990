import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import './category-affinity.input.scss'
import { PageCategorySelectorInput } from '@pushly/aqe/lib/components'
import clone from 'clone'
import { Select } from 'antd'
import { ISelectOption } from '../../../aqe-select/interfaces'
import { IPageCategory, IPageCategorySubscriberCount } from '@pushly/aqe/lib/interfaces'

interface ICampaignInputProps {
    field: IRuleBuilderField
    rule: IRule
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
}

interface ICampaignInputState {}

const scopeOptions: ISelectOption[] = [
    {
        value: '60d',
        label: 'Last 60 Days',
    },
    {
        value: '30d',
        label: 'Last 30 Days',
    },
    {
        value: '14d',
        label: 'Last 14 Days',
    },
    {
        value: '7d',
        label: 'Last 7 Days',
    },
    {
        value: 'lifetime',
        label: "Subscriber's Lifetime",
    },
]

export class CategoryAffinityInput extends BetterComponent<ICampaignInputProps, ICampaignInputState> {
    public readonly defaultClassName = 'sw-v2-rb-category-affinity-input'
    public ref: any

    public constructor(props: ICampaignInputProps) {
        super(props)
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                {!!this.pageCategories?.length && (
                    <>
                        <div className={`${this.buildClassName('editor')}`}>
                            <div className={this.buildClassName('glue')}> for </div>
                            <div className={this.buildClassName('selection-type-select')}>
                                {this.props.mode === 'edit' ? (
                                    <PageCategorySelectorInput
                                        value={this.pageCategorySelectionId}
                                        allowClear={true}
                                        buttonLabel={this.pageCategorySelectionLabel}
                                        pageCategories={this.pageCategories || []}
                                        subscriberCounts={this.pageCategorySubscriberCounts}
                                        showEmpty="toggle"
                                        onChange={(value: IPageCategory) => {
                                            const newRule = clone(this.rule)
                                            newRule.meta = {
                                                scope: '60d',
                                                ...newRule.meta,
                                                category_id: value.id,
                                            }
                                            this.props.onChange(newRule)
                                        }}
                                    />
                                ) : (
                                    <div className={this.buildClassName('ro-value')}>
                                        {this.pageCategorySelectionLabel}
                                    </div>
                                )}
                            </div>
                            {!!this.pageCategorySelectionId && (
                                <>
                                    <div className={this.buildClassName('glue')}> is </div>

                                    {this.props.mode === 'edit' ? (
                                        <Select
                                            mode="multiple"
                                            dropdownClassName={this.buildClassName('dropdown')}
                                            placeholder="Select Affinity Levels"
                                            className={this.buildClassName('affinity-select')}
                                            value={this.rule.value}
                                            onChange={(value) => {
                                                const newRule = clone(this.rule)
                                                newRule.value = value
                                                this.props.onChange(newRule)
                                            }}
                                        >
                                            <Select.Option key="high" value="high">
                                                High
                                            </Select.Option>
                                            <Select.Option key="medium" value="medium">
                                                Medium
                                            </Select.Option>
                                            <Select.Option key="low" value="low">
                                                Low
                                            </Select.Option>
                                        </Select>
                                    ) : (
                                        <div className={this.buildClassName('ro-value')}>
                                            {this.affinityLevelsLabel}
                                        </div>
                                    )}

                                    <div className={this.buildClassName('glue')}> over the </div>

                                    {this.props.mode === 'edit' ? (
                                        <Select
                                            dropdownClassName={this.buildClassName('dropdown')}
                                            className={this.buildClassName('scope-select')}
                                            value={this.rule.meta?.scope}
                                            onChange={(value) => {
                                                const newRule = clone(this.rule)
                                                newRule.meta = {
                                                    ...newRule.meta,
                                                    scope: value,
                                                }
                                                this.props.onChange(newRule)
                                            }}
                                        >
                                            {scopeOptions.map((so) => {
                                                return (
                                                    <Select.Option key={so.value} value={'' + so.value}>
                                                        {so.label}
                                                    </Select.Option>
                                                )
                                            })}
                                        </Select>
                                    ) : (
                                        <div className={this.buildClassName('ro-value')}>{this.scopeLabel}</div>
                                    )}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }

        if (!(this.rule.meta && this.rule.meta.category_id)) {
            response.ok = false
            response.error = 'A content affinity category must be selected.'
        } else if (!(this.rule.value && this.rule.value.length)) {
            response.ok = false
            response.error = 'At least 1 affinity level must be selected.'
        } else if (!(this.rule.meta && this.rule.meta.scope)) {
            response.ok = false
            response.error = 'A content affinity time frame must be selected.'
        }

        return response
    }

    private get pageCategorySelectionLabel() {
        let label: string
        const selectedPageCategory = this.selectedPageCategory

        if (selectedPageCategory) {
            label = selectedPageCategory.name
        } else {
            label = 'Select Page Category'
        }

        return label
    }

    private get scopeLabel() {
        const scope = this.rule.meta.scope
        const scopeOption = scopeOptions.find((so) => so.value === scope)
        return scopeOption!.label
    }

    private get affinityLevelsLabel() {
        let label: string = ''
        const affinityLabels: string[] = this.rule.value.map((s) => {
            return s[0].toUpperCase() + s.substr(1)
        })

        if (affinityLabels.length > 1) {
            const start = affinityLabels.slice(0, -1)
            const end = affinityLabels.slice(-1)[0]
            const oxfordComma = start.length > 1 ? ',' : ''

            label = `${start.join(', ')}${oxfordComma} or ${end}`
        } else {
            label = affinityLabels[0]
        }

        return label
    }

    private get pageCategorySelectionId() {
        return this.rule.meta?.category_id
    }

    private get selectedPageCategory() {
        let pageCategory: IPageCategory | undefined
        const categoryId = this.pageCategorySelectionId

        if (categoryId) {
            pageCategory = this.pageCategories!.find((pc) => pc.id === categoryId)
        }

        return pageCategory
    }

    protected get pageCategories(): IPageCategory[] {
        return this.props.field.pageCategories!
    }

    protected get pageCategorySubscriberCounts(): IPageCategorySubscriberCount[] {
        return this.props.field.pageCategorySubscriberCounts!
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
