import * as React from 'react'
import styled from 'styled-components'
import { IPortDefaultProps } from '@mrblenny/react-flow-chart'
import { getClassNames } from '../../_utils/classnames'

export const Port = () => {
    return (_props: IPortDefaultProps) => {
        const CustomPort = styled.div.attrs({
            className: getClassNames('campaign-canvas-port', {
                [`type-${_props.port.type ?? 'default'}`]: true,
            }),
        })<IPortDefaultProps>``

        return <CustomPort {..._props} />
    }
}
