export enum NotificationBuilderTheme {
    STANDARD,
    MOBILE,
}

export enum NotificationBuilderLevel {
    ORG,
    DOMAIN,
    CAMPAIGN,
}

export enum NotificationBuilderSubmitType {
    STANDARD,
    PERSONAL_PREVIEW,
    TEAM_PREVIEW,
}

export enum IosInterruptionLevel {
    ACTIVE = 'ACTIVE',
    TIME_SENSITIVE = 'TIME_SENSITIVE',
    PASSIVE = 'PASSIVE',
    CRITICAL = 'CRITICAL',
}

export enum IosRelevanceScore {
    LOW = 0.1,
    NORMAL = 0.5,
    HIGH = 0.9,
}

export enum IosBadgeBehaviorAction {
    INCREMENT = 'INCREMENT',
    SET = 'SET',
    DO_NOTHING = 'DO_NOTHING',
}
