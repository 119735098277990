import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Tooltip } from 'antd'
import { StatusType } from '../../enums/status-type'
import { titleCase } from '../../_utils/utils'
import './status-badge.scss'

/*
    "expanded" view forces hidden tooltip
    and displays the status text to the right
    of the badge
 */
interface IProps extends IBadgeProps {
    status: string
    expanded?: boolean
}

export const PromptStatusBadge: React.StatelessComponent<IProps> = (props) => {
    const hideTooltip = props.hideTooltip || props.expanded

    const status = props.status || StatusType.ACTIVE.name
    const text = status
    const icon = status === StatusType.ACTIVE.name ? 'check-circle' : 'close-circle'

    const badge = (
        <span className={`status-icon status-${text}`}>
            <LegacyIcon type={icon} />
        </span>
    )

    return (
        <span className="status-badge prompt-status-badge">
            {hideTooltip ? (
                !props.expanded ? (
                    badge
                ) : (
                    <>
                        {badge}
                        <span className="status-name">{titleCase(text)}</span>
                    </>
                )
            ) : (
                <Tooltip title={props.tooltip || text}>{badge}</Tooltip>
            )}
        </span>
    )
}
