import * as React from 'react'
import { observer } from 'mobx-react'
import { BetterComponent } from '../better-component/better-component'
import { Tooltip } from 'antd'
import { cleanNumericDisplays } from '../../_utils/utils'
import * as numeral from 'numeral'
import { numberWithPercent } from '../../_utils/numeral'

interface IProps {
    clicks: number
    ctrDecimal?: number
    status?: string
    expanded?: boolean
}

interface IState {}

@observer
export class ClicksColumnView extends BetterComponent<IProps, IState> {
    public render() {
        const clicksValue = this.renderClicks(this.props.clicks)
        const clicksExpanded = this.renderClicks(this.props.clicks, 'O,O')

        let ctrValue: any = null
        if (this.props.ctrDecimal !== undefined) {
            ctrValue = (
                <span className="ctr-value">
                    {!this.props.clicks ? '--' : numberWithPercent(this.props.ctrDecimal)}
                </span>
            )
        }

        return (
            <div className="clicks-col-view">
                {this.props.expanded || clicksValue === clicksExpanded ? (
                    <React.Fragment>
                        <span className="clicks-value">{clicksExpanded}</span>
                        {ctrValue}
                    </React.Fragment>
                ) : (
                    <Tooltip trigger={'click'} title={clicksExpanded}>
                        <span className="clicks-value cursor-pointer">{clicksValue}</span>
                        {ctrValue}
                    </Tooltip>
                )}
            </div>
        )
    }

    private renderClicks(clicks: number, format: string = 'O.OOa'): string {
        const value =
            clicks === undefined
                ? '--'
                : String(clicks).length > 4
                ? numeral(clicks).format(format)
                : numeral(clicks).format('O,O')

        return cleanNumericDisplays(value)
    }
}
