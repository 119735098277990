import React from 'react'
import TemplatizedTextField, { ITemplatizedTextField } from '../../../templatized-text-field/templatized-text-field'

interface ITemplateLandingUrlField extends ITemplatizedTextField {}

const TemplateLandingUrlField = (props: ITemplateLandingUrlField) => {
    return (
        <TemplatizedTextField
            label={'Landing URL'}
            placeholder={'https://my.landing-page.domain/my-article'}
            {...props}
            hideEmojis={true}
            autoComplete="off"
        />
    )
}

export default TemplateLandingUrlField
