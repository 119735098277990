import React from 'react'
import './revenue.widget.scss'

const RevenueWidget = ({ type, showType, currency, amount }: any) => {
    return (
        <div className={`dashboard-widget rev-widget vsplit type-${type?.toLowerCase() ?? 'content'}`}>
            <div className="dashboard-widget-content">
                <div className="widget-title">
                    <label>{showType !== false && type ? `${type} ` : ''}Revenue</label>
                </div>
                <div className="widget-body yesterday">
                    <div className="widget-sub-title">
                        <label>Yesterday</label>
                    </div>
                    <span className="revenue">
                        {amount === undefined
                            ? '--'
                            : Intl.NumberFormat('en-us', {
                                  style: 'currency',
                                  currency: currency ?? 'usd',
                              }).format(amount ?? 0)}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default RevenueWidget
