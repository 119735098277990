import * as React from 'react'
import { PromptList } from './prompt-list/prompt-list'
import { PageHeader } from '@pushly/aqe/lib/components'
import { AppService } from '../../services'
import { CurrentUserCan } from '../../stores/app-ability'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AppState } from '../../stores/app'
import { IComponentTab, ITab, TabbedView } from '../../components/tabbed-view/tabbed-view'
import { Container } from 'typescript-ioc'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { getEnabledDeliveryChannels, isDeliveryChannelEnabled } from '../../_utils/domain'

interface IProps {}

interface IState {
    loading: boolean
}

export class Prompts extends TabbedView<IProps, IState> {
    protected readonly appState: AppState
    protected readonly appSvc: AppService

    protected animated = false

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appSvc = Container.get(AppService)

        this.state = {
            loading: true,
            tabs: [],
        }
    }

    public componentDidUpdate(prevProps: Readonly<any>) {
        const domainChanged =
            getPathEntityId('domain', prevProps.location.pathname) !==
            getPathEntityId('domain', this.injectedProps.location.pathname)

        if (domainChanged) {
            return this.loadTabsState()
        }

        return
    }

    public async componentDidMount() {
        return this.loadTabsState()
    }

    public render(): React.ReactNode {
        const canCreatePrompt = isDeliveryChannelEnabled(this.appState.currentDomain!, DeliveryChannel.WEB)
        return (
            <div className="prompts-feat-view">
                <PageHeader
                    title="Prompts"
                    onTitleSet={this.appSvc.customizeTabTitle}
                    action={
                        canCreatePrompt && (
                            <CurrentUserCan
                                do={AbilityAction.CREATE}
                                on={this.appState.abilityStore.getDomainOwnedIdentityFor(SubjectEntity.PROMPT)}
                            >
                                <AsyncButton
                                    className="new-prompt-button"
                                    type="primary"
                                    onClick={() => this.buildCreatePromptUrl(false)}
                                    altHref={this.buildCreatePromptUrl(true)}
                                >
                                    <span>Create Prompt</span>
                                </AsyncButton>
                            </CurrentUserCan>
                        )
                    }
                />

                {this.renderTabs()}
            </div>
        )
    }

    protected buildCreatePromptUrl = (buildOnly?: boolean): string => {
        return this.appService.routeWithinDomain(`/prompts/new`, buildOnly)
    }

    protected loadTabsState = async (): Promise<void> => {
        const domain = this.appState.currentDomain!
        const domainActiveChannels = getEnabledDeliveryChannels(domain).sort((a, b) => (a > b ? -1 : 1))

        let promptsFeatureTabs = domainActiveChannels.map((ch) => ({
            component: class PromptTabComponent extends PromptList {
                protected static tabLabel = DeliveryChannel.getLongName(ch)
                protected static tabName = ch === DeliveryChannel.WEB ? null : ch.toLowerCase()

                constructor(props: any) {
                    super(props)
                }
            },
            props: () => ({
                listType: DeliveryChannel[ch],
            }),
        }))

        const activeTab = this.determineActiveTab(promptsFeatureTabs)

        return this.setState({
            tabs: promptsFeatureTabs,
            activeTab,
        })
    }

    protected getCurrentTab(): ITab | IComponentTab<any> {
        return this.determineActiveTab(this.state.tabs) ?? this.state.activeTab
    }
}
