import { PromptStyle } from '../features/prompts/prompt-editor/prompt-editor'
import { PromptSystemConfigDto } from './prompt-system-config-dto'
import { PromptSlideConfigDto } from './prompt-slide-config-dto'
import { PromptBellConfigDto } from './prompt-bell-config-dto'

interface IGenericPromptConfig {
    theme?: object
    behavior?: object
}

export class PromptDto {
    public static parse(fields: PromptDto): PromptDto {
        const dto = new PromptDto()

        type AllowedType = keyof PromptDto
        const fieldsToAssign: AllowedType[] = [
            'id',
            'promptGroupId',
            'name',
            'computedName',
            'domainId',
            'style',
            'config',
            'conditions',
            'isAutoShow',
            'weight',
        ]

        fieldsToAssign.forEach((key) => {
            if (fields[key] !== undefined) {
                dto[key as any] = fields[key]
            }
        })

        if (dto.style.toUpperCase().trim() === 'NATIVE') {
            dto.style = PromptStyle.SYSTEM
        }
        return dto
    }

    public id: number
    public promptGroupId: number
    public name: string
    public computedName: string
    public domainId: number
    public style: string
    public config: PromptSystemConfigDto | PromptSlideConfigDto | PromptBellConfigDto | IGenericPromptConfig
    public conditions: any
    public isAutoShow: boolean
    public weight: number

    public get isNativeStyle(): boolean {
        return this.style === PromptStyle.SYSTEM
    }

    public get isSlideStyle(): boolean {
        return this.style === PromptStyle.SLIDE
    }

    public get isBellStyle(): boolean {
        return this.style === PromptStyle.BELL
    }

    public get pageCondtions(): any {
        return (this.conditions || {}).page
    }

    public get visitorConditions(): any {
        return (this.conditions || {}).visitor
    }
}
