import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { Sticky } from '../../components/sticky/sticky'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import './header.scss'
import autobind from 'autobind-decorator'
import { DomainSelector } from '../domain-selector/domain-selector'

interface IHeaderProps {}

interface IHeaderState {
    drawerOpen?: boolean
}

export class Header extends BetterComponent<IHeaderProps, IHeaderState> {
    public readonly defaultClassName: string = 'sw-mv-header'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IHeaderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {}
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <Sticky threshold={-82} getContainer={() => this.appService.contentContainerRef}>
                    <div className={this.buildClassName('wrapper')}>
                        <div className={this.buildClassName('left')}>
                            <DomainSelector />
                        </div>
                        <div className={this.buildClassName('mid')}></div>
                        <div className={this.buildClassName('right')}>
                            <div className={this.buildClassName('icon')} onClick={this.handleIconClick} />
                        </div>
                    </div>
                </Sticky>
            </div>
        )
    }

    protected get currentDomainDashboardUrl(): string {
        return this.appService.routeWithinDomain('/dashboard', true)
    }

    @autobind
    protected async handleIconClick(): Promise<void> {
        this.appService.route(this.currentDomainDashboardUrl)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
