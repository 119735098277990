import * as React from 'react'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Alert, Input } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { AuthService } from '../../../services/auth'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import './login.scss'
import { AppService } from '../../../services'
import { AppState } from '../../../stores/app'
import { getQueryStringParam } from '../../../_utils/get-query-string'
import { REF_QUERY_PARAM } from '../../../constants'
import { RefQueryValue } from '../../../enums/ref-query-value'

interface IProps extends FormComponentProps {}

interface IState {
    readonly hasError: boolean
    readonly errorMessage: string | React.ReactNode
    readonly submitting: boolean
}

@observer
class LoginComponent extends BetterFormComponent<IProps, IState> {
    public state: IState = {
        hasError: false,
        errorMessage: '',
        submitting: false,
    }

    private appState: AppState
    private appService: AppService
    private authService: AuthService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.authService = Container.get(AuthService)

        const expiredKickout = getQueryStringParam(REF_QUERY_PARAM) === RefQueryValue.EXPIRED_SESSION

        this.state = {
            submitting: false,
            hasError: expiredKickout,
            errorMessage: !expiredKickout ? '' : 'Your current session has expired.',
        }
    }

    public render() {
        return (
            <div className="login-component">
                <div className={this.buildClassNames('wrapper')}>
                    <Well
                        ghost={true}
                        showHeader={false}
                        onSubmit={this.login}
                        triggerSubmitOnEnter={true}
                        submitText="Log in"
                        submitting={this.state.submitting}
                    >
                        <div className="login-logo" />

                        <Form className="login-form hide-inline-errors no-inline-shift">
                            <Form.Item>
                                {this.getFieldDecorator('email', {
                                    rules: [
                                        {
                                            type: 'email',
                                            message: 'Email is not a valid email',
                                        },
                                    ],
                                })(<Input size="large" prefix={<UserOutlined />} placeholder="Email" />)}
                            </Form.Item>
                            <Form.Item>
                                {this.getFieldDecorator(
                                    'password',
                                    {},
                                )(
                                    <Input
                                        size="large"
                                        prefix={<LockOutlined />}
                                        type="password"
                                        placeholder="Password"
                                    />,
                                )}
                            </Form.Item>

                            {this.state.hasError && <Alert type="error" message={this.state.errorMessage} />}
                        </Form>
                    </Well>
                </div>
            </div>
        )
    }

    private buildClassNames(suffix?: string): string {
        suffix = suffix === undefined ? '' : `-${suffix}`

        const classNames: string[] = [`login-form${suffix}`]
        // if (this.props.className) { classNames.push(`${this.props.className}${suffix}`); }
        return classNames.join(' ')
    }

    @autobind
    private async login(): Promise<any> {
        this.setState({
            hasError: false,
            errorMessage: '',
            submitting: true,
        })

        let values: any
        const preflightErrors: string[] = []

        try {
            values = await this.validateFields()

            if (!values.email || values.email === '') {
                preflightErrors.push('Email is required')
            }
            if (!values.password || values.password === '') {
                preflightErrors.push('Password is required')
            }
        } catch (error) {
            if (error.email) {
                preflightErrors.push(error.email.errors.map((err: any) => err.message))
            }
            if (error.password) {
                preflightErrors.push(error.password.errors.map((err: any) => err.message))
            }
        }

        if (preflightErrors.length > 0) {
            this.setState({
                hasError: true,
                errorMessage: preflightErrors.join(', '),
            })

            return
        }

        try {
            return await this.authService.login(values.email, values.password)
        } catch (error) {
            const errStatus = error.response?.status ?? 500
            const errMessage = error.response?.data?.message ?? 'An error has occurred.'

            this.setState({
                hasError: true,
                errorMessage:
                    errStatus === 429 ? (
                        <div>
                            <div>Pushly was unable to complete your request.</div>
                            <div>{errMessage.replace(/\.$/, '')}.</div>
                        </div>
                    ) : (
                        errMessage
                    ),
            })
        }

        this.setState({ submitting: false })
    }
}

export const Login = Form.create<IProps>()(LoginComponent)
