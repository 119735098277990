import * as React from 'react'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import './module-loading-screen.scss'

interface IProps {
    loading?: boolean
}

@observer
export class ModuleLoadingScreen extends BetterComponent<IProps, {}> {
    private appState: AppState

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const loading = this.props.loading !== undefined ? this.props.loading : this.appState.moduleLoading

        return (
            <div className={`module-loading-screen${loading ? ' active' : ''}`}>
                <div className="module-loading-content">
                    <Spin indicator={<Loading3QuartersOutlined spin={true} />} />
                </div>
            </div>
        )
    }
}
