import * as clone from 'clone'
import { CampaignModel } from '../models/campaign/campaign.model'
import { CampaignRevision } from '../models/campaign/campaign-revision'
import { CampaignStep } from '../models/campaign/campaign-step'
import { NodeType, TriggerType } from '../components/campaign-builder/enums'
import { generateShortID } from '../components/campaign-builder/helpers/uid'

export const extractCampaignTrigger = (campaign: CampaignModel): CampaignStep => {
    const rev = campaign.getCurrentRevision() ?? CampaignRevision.build({})
    const steps = rev.getSteps()
    const triggerIdx = steps.findIndex((s) => s.getType() === NodeType.TRIGGER)
    return steps[triggerIdx]
}

export function isTriggerTypeOf(trigger: TriggerType, types: TriggerType | TriggerType[]): boolean {
    types = Array.isArray(types) ? types : [types]
    return types.includes(trigger)
}

export function reassignStepIds(steps: any[]): any[] {
    const reassigned = clone(steps)
    const updatedIdMap: any = {}

    reassigned.forEach((s) => {
        const newId = generateShortID()
        updatedIdMap[s.id] = newId
        s.id = newId
    })

    reassigned.forEach((s) => {
        if (!!s.configuration?.out_step_id) {
            s.configuration.out_step_id = updatedIdMap[s.configuration.out_step_id]
        }
    })

    return reassigned
}

export function orderCampaignSteps(steps: any[]): any[] {
    const stepMap = {}
    steps.forEach((s) => (stepMap[s.id] = s))

    // ensure all global triggers are added first
    const ordered = steps.filter((step) => {
        const type = step.type ?? step.stepType
        return type === NodeType.GLOBAL_TRIGGER
    })

    const trigger = steps.find((step) => {
        const type = step.type ?? step.stepType
        return type === NodeType.TRIGGER
    })
    if (!trigger) {
        console.warn('no trigger found')
    }
    ordered.push(trigger)

    let hand = trigger
    while (hand?.configuration?.out_step_id) {
        hand = stepMap[hand.configuration.out_step_id]
        if (hand) {
            ordered.push(hand)
        }
    }

    if (ordered.length !== steps.length) {
        console.warn('field steps is incomplete')
    }

    return ordered
}
