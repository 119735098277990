import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { Switch, Route } from '../../components/router'
import { Overview } from './overview/overview'

interface IProps {}

interface IState {}

export class Dashboard extends BetterComponent<IProps, IState> {
    public render() {
        return (
            <div className="module dashboard">
                <Switch>
                    <Route path={`${this.matchedPath}`} exact={true} component={Overview} />
                </Switch>
            </div>
        )
    }
}
