import { convertCase } from '../../_utils/utils'
import { NodeType } from '../../components/campaign-builder/enums'

export class CampaignStep {
    public static build(props: CampaignStep | any): CampaignStep {
        const data = props instanceof CampaignStep ? props.serialize() : props
        const scData = convertCase(data, 'snake')

        const step = new CampaignStep()
        step.setId(scData.id)
        step.setRevisionId(scData.revision_id)
        step.setType(scData.type)
        step.setConfiguration(scData.configuration)

        return step
    }

    private id: number
    private configuration: any = {}
    private revision_id!: number
    private type!: NodeType

    public clone(): CampaignStep {
        return CampaignStep.build(this)
    }

    public serialize(_: boolean = true): any {
        return {
            id: this.getId(),
            revision_id: this.getRevisionId(),
            type: this.getType(),
            configuration: this.getConfiguration(),
        }
    }

    public setId(id: number) {
        this.id = id
    }
    public getId(): number {
        return this.id
    }

    public setRevisionId(id: number): void {
        this.revision_id = id ? parseInt(id.toString(), 10) : (undefined as any)
    }
    public getRevisionId(): number {
        return this.revision_id
    }

    public setType(type: NodeType | string): void {
        type = type.toString().trim().toLowerCase()
        this.type = type as NodeType
    }
    public getType(): NodeType {
        return this.type
    }

    public setConfiguration<T>(configuration: any): void {
        this.configuration = configuration ?? {}
    }
    public getConfiguration(): any {
        return this.configuration
    }
}
