import * as React from 'react'
import { Button, Well, Drawer, executeCleanDrawerRender } from '@pushly/aqe/lib/components'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { notification, Select, Skeleton, Tag } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Container } from 'typescript-ioc/es5'
import cloneDeep from 'clone-deep'
import * as randomstring from 'randomstring'
import { DomainDto } from '../../dtos/domain'
import { AppState } from '../../stores/app'
import { AppService, DomainService } from '../../services'
import { simpleFormErrorNotification, simpleNotification } from '../../_utils/utils'
import { validateFields } from '../../_utils/antd'
import { getClassNames } from '../../_utils/classnames'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'

interface IDomainServerEditor extends FormComponentProps {
    domain: DomainDto
    visible: boolean
    onClose: (e: any) => void
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDSEState {}

export const DomainServerEditor = Form.create<IDomainServerEditor>()(
    class extends React.Component<IDomainServerEditor, IDSEState> {
        public state: IDSEState = {}

        protected appState: AppState
        protected appService: AppService
        protected domainService: DomainService

        public constructor(props: IDomainServerEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.domainService = Container.get(DomainService)

            executeCleanDrawerRender(this)
        }

        public render() {
            const currentUserIsDev = this.appState.currentUser?.isInternalDevUser
            const currentValues = this.getCurrentValues()

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('ddf-server-editor')}
                    title="Update Server Keys & Flags"
                    placement="right"
                    closable={true}
                    onSubmit={this.handleSubmit}
                    disableSubmit={Object.keys(currentValues).length === 0}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                        <Well title="Notification Keys" mode="ghost" hideFooter={true}>
                            <Form.Item label="Collapse Key" className={getClassNames('ddf-server-editor-ck')}>
                                {this.props.form.getFieldDecorator('collapseKeyType', {
                                    initialValue: this.domain.collapseKeyType,
                                })(
                                    <Select size="small">
                                        <Select.Option value="DOMAIN_ID">Domain Level</Select.Option>
                                        <Select.Option value="NOTIFICATION_SCHEDULE_ID">
                                            Notification Level
                                        </Select.Option>
                                    </Select>,
                                )}
                            </Form.Item>
                        </Well>
                    </Form>
                </Drawer>
            )
        }

        protected get domain(): DomainDto {
            return this.props.domain
        }

        protected getCurrentValues(): Partial<DomainDto> {
            const currentUserIsDev = this.appState.currentUser?.isInternalDevUser
            const values: Partial<DomainDto> = {}

            values.collapseKeyType = this.props.form.getFieldValue('collapseKeyType')
            if (values.collapseKeyType === this.domain.collapseKeyType) {
                delete values.collapseKeyType
            }

            return values
        }

        protected handleSubmit = async () => {
            try {
                await validateFields(this.props.form)
                const values = this.getCurrentValues()

                const { ok, data } = await this.domainService.updateDomainById(this.domain.id, values)

                if (ok) {
                    this.props.onDomainUpdated(data)
                    this.props.onClose(null)
                }
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }
    },
)

interface IDomainServerWell {
    loading?: boolean
    domain: DomainDto
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDSWState {
    showDrawer: boolean
    drawerKey?: string
}

export class DomainServerWell extends React.Component<IDomainServerWell, IDSWState> {
    public state: IDSWState = {
        showDrawer: false,
    }

    protected appState: AppState

    public constructor(props: IDomainServerWell) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        const currentUserIsDev = this.appState.currentUser?.isInternalDevUser

        return (
            <Well
                className="nested server-well"
                title="Server Keys & Flags"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.renderNotificationWell()}
                            {currentUserIsDev && this.renderFlagsWell()}

                            <DomainServerEditor
                                key={this.state.drawerKey}
                                visible={this.state.showDrawer}
                                domain={this.props.domain}
                                onClose={this.handleDrawerClose}
                                onDomainUpdated={this.props.onDomainUpdated}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected renderNotificationWell() {
        return (
            <Well title="Notification Keys" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>Collapse Key</div>
                    <div className={getClassNames('form-layout-row-value', 'can-copy')}>
                        <CopyToClipboard
                            onCopy={() => this.handleCopied('collapse key')}
                            text={this.domain.collapseKeyType}
                        >
                            <span>
                                {this.domain.collapseKeyType.toUpperCase() === 'DOMAIN_ID'
                                    ? 'Domain Level'
                                    : 'Notification Level'}
                            </span>
                        </CopyToClipboard>
                    </div>
                </div>
            </Well>
        )
    }

    protected renderFlagsWell() {
        return (
            <Well title="Flags" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>
                        Service & Feature Flags
                        <div className={getClassNames('form-layout-row-sub')}>
                            <InfoCircleOutlined />
                            <span>
                                View{' '}
                                <a
                                    href="https://pushly.atlassian.net/wiki/spaces/AQ/pages/372080658/Flags#Domain-Flags"
                                    target="_blank"
                                >
                                    Available Tags
                                </a>
                            </span>
                        </div>{' '}
                    </div>
                    <div className={getClassNames('form-layout-row-value')}>
                        {this.domain.flags?.map((flag, idx) => <Tag key={idx}>{flag}</Tag>) || 'No flags'}
                    </div>
                </div>
            </Well>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }

    protected handleCopied = (field: string) => {
        notification.destroy()
        simpleNotification('success', `Your ${field} has been copied!`)
    }
}
