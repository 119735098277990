import * as React from 'react'
import * as randomstring from 'randomstring'
import { Component } from 'react'
import { ISelect, ISelectState, ISelectOption, ISelectOptionGroup, SelectMode } from './interfaces'
import { SelectItem } from './select-item'
import { SelectPortal } from './select-portal'
import './select.scss'

const preventBubbling = (event: MouseEvent | any): void => {
    // if (event) { event.stopPropagation(); }
}

export class AqeSelect extends Component<ISelect, ISelectState> {
    public eid = randomstring.generate({ length: 6 })

    private defaultClassNames: string[] = ['aqe-select', 'aqe-select-container']

    private ref: any
    private dropdownRef: any
    private searchFieldRef: any
    private unmounting = false
    private muationObserver: MutationObserver
    private defaultPlaceholder: string = 'Select an option'
    private posLoader: any

    public constructor(props: ISelect) {
        super(props)

        this.state = {
            mode: this.props.mode || 'single',
            isOpen: false,
            selectedOptions: [],
            rect: {},
        }
    }

    public async componentDidMount(): Promise<void> {
        const W = window as any
        W.__swbdd__ = W.__swbdd__ || []
        W.__swbdd__.push(this)

        this.wireEventHandlers()

        const defaultValue = this.props.defaultValue
        let defaultValueSet = false

        if (!!defaultValue && defaultValue.length > 0) {
            let selectedOptions = defaultValue as ISelectOption[]

            if (typeof defaultValue[0] !== 'object') {
                selectedOptions = this.getUnnestedOptions().filter((opt) => {
                    return this.props.defaultValue!.indexOf(opt.value) !== -1
                })
            }

            await this.setState({ selectedOptions })
            defaultValueSet = true
        }

        await this.readValueFromProps()

        if (defaultValueSet) {
            let outputValue: any = this.state.selectedOptions.map((opt) => opt.value)
            if (this.props.mode !== 'multiple') {
                if (this.state.selectedOptions.length > 0) {
                    outputValue = this.state.selectedOptions[0].value
                }
            }

            if (!!this.props.onChange) {
                this.props.onChange(outputValue)
            } else {
                if (!!this.props.onClose) {
                    this.props.onClose(outputValue)
                }
            }
        }
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.unwireEventHandlers()
    }

    public componentDidUpdate(): void {
        this.readValueFromProps()
    }

    public async readValueFromProps(): Promise<void> {
        const hasOptions = Array.isArray(this.props.options) && this.props.options.length > 0

        if (hasOptions && this.props.value) {
            const value: any[] =
                this.state.mode === 'single'
                    ? Array.isArray(this.props.value)
                        ? this.props.value
                        : [this.props.value]
                    : this.props.value
            const optionValues = this.getUnnestedOptions().map((opt) => opt.value)

            const validValues = value.every((v) => optionValues.includes(v))
            if (!!validValues) {
                const propsValue = value.filter((v) => v !== undefined && v !== null)
                const currentSelectionValues = this.state.selectedOptions.map((opt) => opt.value)
                const stateValueSig = btoa(JSON.stringify(currentSelectionValues.sort()))
                const propsValueSig = btoa(JSON.stringify(propsValue.sort()))

                if (stateValueSig !== propsValueSig) {
                    const selectedOptions = this.getUnnestedOptions().filter((opt) => {
                        return propsValue.indexOf(opt.value) !== -1
                    })

                    await this.setState({
                        selectedOptions,
                    })
                }
            }
        }
    }

    public async setState<P extends ISelect, S extends ISelectState, K extends keyof S>(
        state: ((prevState: Readonly<S>, props: Readonly<P>) => Pick<S, K> | S | null) | (Pick<S, K> | S | null),
        callback?: () => void,
    ): Promise<void> {
        if (!this.unmounting) {
            return super.setState(state as any, callback)
        }
    }

    public render(): any {
        const { state, props } = this

        const handleOpenStateChange = this.handleOpenStateChange.bind(this)
        const handleOptionSearch = this.handleOptionSearch.bind(this)
        const handleSelectAllChange = this.handleSelectAllChange.bind(this)

        const allOptions = this.getUnnestedOptions()
        const visibleOptions = state.filteredOptions || this.buildFilteredOptionsArray(props.options)
        const selectedItemCount = state.selectedOptions.length
        const allItemsSelected = selectedItemCount > 0 && state.selectedOptions.length === allOptions.length

        const disableSelectAll = this.isMultiMode() ? props.disableSelectAll : true

        const dropdownClassNames = ['aqe-select-dropdown']

        if (selectedItemCount === 0) {
            dropdownClassNames.push('no-selections')
        }

        return (
            <React.Fragment>
                <div ref={(element) => (this.ref = element)} id={this.eid} className={this.buildClassNames()}>
                    <div className={dropdownClassNames.join(' ')}>
                        <div className="aqe-select-dropdown-heading" onClick={handleOpenStateChange}>
                            <span className="aqe-select-dropdown-title">{this.buildTitle()}</span>
                            <span
                                className={`aqe-select-dropdown-loading-indicator${
                                    props.loading ? ' aqe-select-loading' : ''
                                }`}
                            />
                            <span
                                className={`aqe-select-dropdown-arrow ${
                                    state.isOpen ? 'aqe-select-open' : 'aqe-select-closed'
                                }`}
                            />
                        </div>
                    </div>
                </div>

                <SelectPortal
                    portalId={`aqe-select-dropdown-container-${this.eid}`}
                    className={this.buildPortalClassNames()}
                    container={props.dropdownContainer || document.body}
                >
                    {this.state.isOpen && (
                        <div
                            ref={(el) => (this.dropdownRef = el)}
                            className="aqe-select-dropdown-contents-wrapper"
                            style={{
                                width: state.rect.width,
                                top: (state.rect.y || state.rect.top) + state.rect.height + 2,
                                left: state.rect.x || state.rect.left,
                            }}
                        >
                            {!props.disableSearch && (
                                <div className="aqe-select-dropdown-search">
                                    <input
                                        ref={(el) => (this.searchFieldRef = el)}
                                        type="text"
                                        onKeyUp={handleOptionSearch}
                                        placeholder={props.searchPlaceholder || 'Search'}
                                    />
                                </div>
                            )}
                            <div className="aqe-select-dropdown-option-list">
                                {!state.filteredOptions && !disableSelectAll && (
                                    <React.Fragment>
                                        <div
                                            className={`aqe-select-dropdown-option aqe-select-select-all${
                                                allItemsSelected ? ' aqe-select-selected' : ''
                                            }`}
                                            onClick={handleSelectAllChange}
                                        >
                                            <span className="aqe-select-dropdown-option-label">
                                                {this.props.selectAllLabel || 'Select All'}
                                            </span>
                                            <span className="aqe-select-dropdown-option-toggle">
                                                <input type="checkbox" checked={true} readOnly={true} tabIndex={-1} />
                                            </span>
                                        </div>
                                        <div className="aqe-select-dropdown-divider" />
                                    </React.Fragment>
                                )}
                                {visibleOptions.length > 0 ? (
                                    visibleOptions.map((option) => this.renderOptionItem(option))
                                ) : (
                                    <span className="aqe-select-dropdown-empty">
                                        {props.emptyOptionsPlaceholder || 'No options found'}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </SelectPortal>
            </React.Fragment>
        )
    }

    public close(updateState: boolean = true, isSelf: boolean = false): void {
        if (this.state.isOpen || isSelf) {
            if (updateState) {
                this.setState({
                    isOpen: false,
                    filteredOptions: undefined,
                    showDropdownBelow: undefined,
                })
            }

            if (!!this.props.onClose) {
                const values = this.state.selectedOptions.map((opt) => opt.value)
                this.props.onClose(this.isMultiMode() ? values : values[0])
            }
        }
    }

    protected getUnnestedOptions(withGroups: boolean = false): ISelectOption[] {
        if (withGroups) return this.props.options as any

        const opts = this.props.options.map((opt: any) => {
            if (opt.options) {
                return opt.options
            } else {
                return opt
            }
        })

        const flatOpts = opts.reduce((arr: any[], val: any) => arr.concat(val), [])

        return flatOpts
    }

    protected wireEventHandlers(): void {
        document.addEventListener('mousedown', this.handleDocumentClick.bind(this))

        if (this.props.closeOnEscape) {
            document.addEventListener('keyup', this.handleDocumentKeyUp.bind(this))
        }

        this.ref.addEventListener('mousedown', preventBubbling)

        this.muationObserver = new MutationObserver(() => {
            this.setState(() => ({ rect: this.ref.getBoundingClientRect() }))
        })

        this.muationObserver.observe(this.ref, {
            childList: true,
            subtree: true,
        })
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))

        if (this.props.closeOnEscape) {
            document.removeEventListener('keyup', this.handleDocumentKeyUp.bind(this))
        }

        this.ref.removeEventListener('mousedown', preventBubbling)

        this.muationObserver.disconnect()
    }

    protected buildTitle(): React.ReactNode {
        const selectAllLabel = (this.props.selectAllLabel || '').trim()
        const hasSelectAllLabel = !!selectAllLabel && selectAllLabel.length > 0
        const totalSelected = this.state.selectedOptions.length
        const allItemsSelected = totalSelected === this.getUnnestedOptions().length
        const maxDisplayCount = this.props.maxDisplayCount || 0
        const maxDisplayMet = maxDisplayCount > 0 && totalSelected > maxDisplayCount

        let prefix
        if (!!this.props.prefix && !!this.props.prefix.trim()) {
            prefix = `${this.props.prefix.trim()}: `
        }

        let title: React.ReactNode = (
            <span className="aqe-select-dropdown-placeholder">{this.props.placeholder || this.defaultPlaceholder}</span>
        )

        if (this.state.selectedOptions.length > 0) {
            if (allItemsSelected && hasSelectAllLabel) {
                title = (
                    <span className="aqe-select-dropdown-selections">
                        <span className="aqe-select-dropdown-selection">{this.props.selectAllLabel}</span>
                    </span>
                )
            } else {
                if (maxDisplayMet) {
                    title = this.handleMaxDisplayMet()
                } else {
                    const selectedValues = this.state.selectedOptions.map((opt) => opt.value)
                    const orderedSelections = this.getUnnestedOptions().filter(
                        (opt) => selectedValues.indexOf(opt.value) !== -1,
                    )

                    title = (
                        <span className="aqe-select-dropdown-selections">
                            {orderedSelections.map((option, index) => {
                                return this.renderSelectedItem(option, index, this.state.selectedOptions)
                            })}
                        </span>
                    )
                }
            }
        }

        if (this.props.disabled && !!this.props.disabledValue) {
            title = <span className="aqe-select-dropdown-prefix">{this.props.disabledValue}</span>
        }

        if (this.state.showInvalidMessage) {
            title = this.props.invalidValueMessage || 'Invalid Selection'
        }

        return (
            <>
                <span className="aqe-select-dropdown-prefix">{prefix}</span>
                {title}
            </>
        )
    }

    protected renderSelectedItem(option: ISelectOption, index: number, options: ISelectOption[]): React.ReactNode {
        if (this.props.renderSelectedItem) {
            return this.props.renderSelectedItem(option, index, options)
        }

        const isLastItem = index + 1 === options.length

        let display = (
            <span key={option.value} className="aqe-select-dropdown-selection">
                {!!option.renderSelectedItem ? option.renderSelectedItem() : option.label || option.value}
                {!isLastItem ? ', ' : ''}
            </span>
        )

        if (!!this.props.theme) {
            if (this.props.theme === 'tags') {
                display = (
                    <span
                        key={option.value}
                        className="aqe-select-dropdown-selection aqe-select-tag"
                        onClick={(ev) => this.handleTagClick(ev, option)}
                    >
                        {!!option.renderSelectedItem ? option.renderSelectedItem() : option.label || option.value}
                    </span>
                )
            }
        }

        return display
    }

    protected renderOptionItem(option: ISelectOption | ISelectOptionGroup): React.ReactNode {
        if ('options' in option) {
            return (
                <div key={option.label} className="aqe-select-dropdown-option-group">
                    <div className="aqe-select-dropdown-option-group-label">{option.label}</div>
                    {option.options.map((o) => this.renderOptionItem(o))}
                </div>
            )
        }

        const currentSelectedValues = this.state.selectedOptions.map((opt) => opt.value)
        const itemIsSelected = currentSelectedValues.indexOf(option.value) !== -1
        const handleItemChange = this.handleItemChange.bind(this)

        return (
            <SelectItem
                key={option.value}
                value={option.value}
                label={option.label}
                checked={itemIsSelected}
                onChange={handleItemChange}
                renderOptionItem={this.props.renderOptionItem}
                disabled={option.disabled}
            />
        )
    }

    protected async handleTagClick(ev: React.MouseEvent, option: ISelectOption): Promise<void> {
        if (ev) {
            ev.preventDefault()
            ev.stopPropagation()
        }

        if (this.props.disabled) {
            return
        }

        return this.setState(({ selectedOptions }) => {
            selectedOptions = selectedOptions.filter((o) => o.value !== option.value)

            return {
                selectedOptions,
            }
        })
    }

    protected handleMaxDisplayMet(): React.ReactNode {
        const { selectedOptions } = this.state
        if (this.props.maxDisplayFormatter) {
            return this.props.maxDisplayFormatter(selectedOptions)
        }

        const multi = selectedOptions.length > 1
        return `${selectedOptions.length} ${multi ? 'Items' : 'Item'} Selected`
    }

    protected handleSelectAllChange(): void {
        const allItemsSelected = this.state.selectedOptions.length === this.getUnnestedOptions().length
        let selectedOptions: ISelectOption[] = []

        if (!allItemsSelected) {
            selectedOptions = this.getUnnestedOptions()
        }

        this.setState(
            () => ({ selectedOptions }),
            () => {
                if (this.props.onChange) {
                    const values = selectedOptions.map((opt) => opt.value)
                    this.props.onChange(values)
                }
            },
        )
    }

    protected handleItemChange(option: ISelectOption, checked: boolean): void {
        this.setState(
            ({ selectedOptions, isOpen }) => {
                if (this.isMultiMode()) {
                    const optionIndex = selectedOptions.findIndex((opt) => opt.value === option.value)

                    if (checked) {
                        if (optionIndex === -1) {
                            selectedOptions.push(option)
                        }
                    } else {
                        if (optionIndex !== -1) {
                            selectedOptions.splice(optionIndex, 1)
                        }
                    }

                    return {
                        isOpen,
                        selectedOptions,
                    }
                } else {
                    return {
                        isOpen: !isOpen,
                        selectedOptions: [option],
                    }
                }
            },
            () => {
                if (this.props.onChange) {
                    const opts = this.state.selectedOptions
                    const values = opts.map((opt) => opt.value)
                    this.props.onChange(this.isMultiMode() ? values : values[0], this.isMultiMode() ? opts : opts[0])
                }
            },
        )
    }

    protected async handleOpenStateChange(ev: any): Promise<void> {
        if ('preventDefault' in ev) {
            ev.preventDefault()
            ev.stopPropagation()

            if ('nativeEvent' in ev) {
                ev.nativeEvent.preventDefault()
                ev.nativeEvent.stopPropagation()
            }
        }

        if (this.props.disabled) {
            return
        }

        await this.setState(({ isOpen, filteredOptions, showDropdownBelow }) => {
            return {
                isOpen: !isOpen,
                filteredOptions: isOpen ? undefined : filteredOptions,
                showDropdownBelow: !isOpen ? undefined : showDropdownBelow,
                rect: this.ref.getBoundingClientRect(),
            }
        })

        if (this.state.isOpen) {
            this.closeOtherDropdowns()

            if (!this.props.disableSearch) {
                setTimeout(() => this.searchFieldRef.focus(), 50)
            }
        } else {
            this.close(false, true)
        }
    }

    protected closeOtherDropdowns(): void {
        const W = window as any

        if (!!W.__swbdd__) {
            W.__swbdd__.filter((dd: AqeSelect) => dd.eid !== this.eid).forEach((dd: AqeSelect) => dd.close())
        }
    }

    protected buildClassNames(): string {
        const classNames = [...this.defaultClassNames]

        if (this.props.loading === true) {
            classNames.push('aqe-select-loading')
        }
        classNames.push(this.state.isOpen ? 'aqe-select-open' : 'aqe-select-closed')

        const size = this.props.size || 'default'
        classNames.push(`aqe-select-size-${size.toLowerCase()}`)

        if (!!this.props.theme) {
            classNames.push(`theme-${this.props.theme}`)
        }
        if (!!this.props.skeletonMode) {
            classNames.push('skeleton')
        }

        if (this.props.className && this.props.className.trim() !== '') {
            classNames.push(this.props.className.trim())
        }

        if (this.props.disabled) {
            classNames.push('aqe-select-disabled')
        }

        return classNames.join(' ')
    }

    protected buildPortalClassNames(): string {
        const classNames: string[] = []

        if (this.props.loading === true) {
            classNames.push('aqe-select-loading')
        }

        const size = this.props.size || 'default'
        classNames.push(`aqe-select-size-${size.toLowerCase()}`)

        if (!!this.props.theme) {
            classNames.push(`theme-${this.props.theme}`)
        }
        if (!!this.props.dropdownClassName) {
            classNames.push(this.props.dropdownClassName)
        }
        if (this.props.disabled) {
            classNames.push('aqe-select-disabled')
        }

        const docRect = document.body.getBoundingClientRect()
        if (this.state.showDropdownBelow === undefined) {
            classNames.push('pos-loading')
        } else if (!this.state.showDropdownBelow) {
            classNames.push('pos-above')
        }

        if (!this.props.loading && this.state.showDropdownBelow === undefined) {
            if (!!this.posLoader) clearTimeout(this.posLoader)
            this.posLoader = setTimeout(() => {
                if (!!this.dropdownRef) {
                    const ddRect = this.dropdownRef.getBoundingClientRect()
                    const ddBottomDiff = docRect.bottom - ddRect.bottom
                    const ddShowBelow = ddBottomDiff > 5

                    if (ddShowBelow) {
                        if (this.state.showDropdownBelow !== true) {
                            this.setState({ showDropdownBelow: true })
                        }
                    } else {
                        if (this.state.showDropdownBelow !== false) {
                            this.setState({ showDropdownBelow: false })
                        }
                    }
                }
            }, 50)
        }

        return classNames.join(' ')
    }

    protected buildFilteredOptionsArray(
        options: any[],
        filter: (opt: ISelectOption) => boolean = () => true,
        seed: any[] = [],
    ): any[] {
        options.forEach((opt) => {
            if ('options' in opt) {
                const group = {
                    label: opt.label,
                    value: opt.label,
                    disabled: opt.disabled,
                    options: this.buildFilteredOptionsArray(opt.options, filter),
                }

                if (group.options.length > 0) {
                    seed.push(group)
                }
            } else {
                if (filter(opt)) {
                    seed.push(opt)
                }
            }
        })

        return seed
    }

    private handleOptionSearch(event: KeyboardEvent): void {
        const target: any = event.target
        const searchValue = !!target.value ? target.value.trim() : undefined
        const allOptions = this.props.options
        let filteredOptions: any

        if (searchValue && searchValue.length > 0) {
            const searchRgx = new RegExp(searchValue, 'i')

            filteredOptions = this.buildFilteredOptionsArray(allOptions, (opt) => {
                const value = opt.value || ''
                const label = opt.label || value

                return searchRgx.test(label.toString()) || searchRgx.test(value.toString())
            })
        }

        this.setState(() => ({ filteredOptions }))
    }

    private handleDocumentKeyUp(event: KeyboardEvent): void {
        if (this.state.isOpen) {
            if (event.key && event.key.toUpperCase() === 'ESCAPE') {
                preventBubbling(event)
                this.close()
            }
        }
    }

    private handleDocumentClick(event: MouseEvent): void {
        if (this.state.isOpen) {
            let shouldExecute = true

            if (!!event.target) {
                const target: any = event.target

                if ('classList' in target) {
                    if (/aqe-select-/i.test(target.classList.toString())) {
                        shouldExecute = false
                    }
                }
            }

            if (shouldExecute) {
                this.close()
            }
        }
    }

    private isMultiMode(): boolean {
        return this.state.mode === 'multiple'
    }
}
