import React from 'react'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { AccountDto } from '../../dtos/account.dto'
import { DomainDto } from '../../dtos/domain'
import { SegmentModel } from '../../models/segments/segment.model'
import { IPageCategory, IPageCategorySubscriberCount } from '@pushly/aqe/lib/interfaces'
import { ISegmentField } from './interfaces'
import { TypeaheadTransform } from '../rule-builder-v2/inputs/typeahead/typeahead'
import { ReachByChannel } from '../notification-builder/elements/notification-builder-reach'

export interface IOrgSegmentBuilderContext {
    level: 'org'
    org: AccountDto
    segment: OrgSegmentModel
    selectedDomainIds: number[]
    confirmingDomainChange: boolean
}

export interface IDomainSegmentBuilderContext {
    level: 'domain'
    domain: DomainDto
    segment: SegmentModel
}

export type SegmentBuilderContext = (IOrgSegmentBuilderContext | IDomainSegmentBuilderContext) & {
    mode: 'standard' | 'drawer'
    isDrawerMode: boolean
    readonly: boolean

    loading: boolean // overall loading indicator
    levelDependenciesLoaded: boolean // org | domain loading indicator
    segmentDependenciesLoaded: boolean // segment loading indicator
    fieldDependenciesLoaded: boolean // field/rule props loading indicator

    languageCodesLoaded: boolean
    languageCodes: any[]

    keywordsLoaded: boolean
    keywords: string[]

    calculatedFieldsLoaded: boolean
    calculatedFields: any[]

    segmentFieldsLoaded: boolean
    segmentFields: ISegmentField[]
    segmentFieldTypeaheadUrl: string | (() => string | null)
    segmentFieldTypeaheadTransform: TypeaheadTransform

    pageCategoriesLoaded: boolean
    pageCategories: IPageCategory[]
    pageCategorySubscriberCountsLoaded: boolean
    pageCategorySubscriberCounts: IPageCategorySubscriberCount[]

    reachEstimateLoaded: boolean
    reachEstimate: ReachByChannel
    reachEstimateBreakdown: { [index: number]: number }

    defaultIconEnabled: boolean
    adhocEnabled: boolean
    canShowSubscriberPreferences: boolean
    builderDocumentIsValid: boolean
}

export const SegmentBuilderContext = React.createContext<SegmentBuilderContext>({
    loading: true,
    levelDependenciesLoaded: false,
    segmentDependenciesLoaded: false,
    fieldDependenciesLoaded: false,
    keywordsLoaded: false,
    pageTagsLoaded: false,
    calculatedFieldsLoaded: false,
    segmentFieldsLoaded: false,
    pageCategoriesLoaded: false,
    reachEstimateLoaded: false,
    defaultIconEnabled: false,
} as any)
