import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { PromptDto } from '../dtos/prompt'
import * as querystring from 'query-string'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { PromptGroupDto } from '../dtos/prompt-group'
import aqe from '@pushly/aqe'
import { CustomApiResponseErrorHandler } from '../types/api-call-options-with-custom-error-handler'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class PromptService {
    private appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async fetchAllByDomainId(
        domainId: number,
        opts: any = {},
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<{ prompts: PromptGroupDto[]; meta: any } | void> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/prompt-groups?${options}`,
                },
                cancellationKey,
            )
            const { data: prompts, ...data } = req.data
            const promptDtos = prompts.map(PromptGroupDto.parse)

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return {
                prompts: promptDtos,
                meta: data,
            }
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
        }
    }

    public async fetchAllByDomainIdV2(
        domainId: number,
        opts: any = {},
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<{ prompts: PromptDto[]; meta: any; cancelled: boolean }> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts || {})

        const response: any = {
            notifications: [],
            meta: {},
            cancelled: false,
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/v3/domains/${domainId}/prompt-groups?${options}`,
                },
                cancellationKey,
            )
            const { data: prompts, ...data } = req.data
            const promptDtos = prompts.map(PromptGroupDto.parse)

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            response.prompts = promptDtos
            response.meta = data
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }

            if (isAxiosCancellation(error)) {
                response.cancelled = true
            }
        }

        return response
    }

    public async fetchById(
        domainId: number,
        promptId: number,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
        errorHandler?: CustomApiResponseErrorHandler,
    ): Promise<PromptGroupDto | void> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/${promptId}`,
                },
                cancellationKey,
            )
            const { data: prompt, ...data } = req.data
            const promptDto = PromptGroupDto.parse(prompt)

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return promptDto
        } catch (error) {
            if (errorHandler) {
                const defaultHandler = () => handleResponseErrorMessage(error)
                errorHandler(error, defaultHandler)
            }

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
        }
    }

    public async create(
        domainId: number,
        createDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<PromptGroupDto | void> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups`,
                    data: createDto,
                },
                cancellationKey,
            )
            const { data: prompt, ...data } = req.data
            const promptDto = PromptGroupDto.parse(prompt)

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return promptDto
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            throw error
        }
    }

    public async update(
        domainId: number,
        promptId: number,
        updateDto: any,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<PromptGroupDto | void> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/${promptId}`,
                    data: updateDto,
                },
                cancellationKey,
            )
            const { data: prompt, ...data } = req.data
            const promptDto = PromptGroupDto.parse(prompt)

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return promptDto
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            throw error
        }
    }

    public async destroy(
        domainId: number,
        promptId: number,
        showLoadingScreen: boolean = true,
        cancellationKey?: string,
    ): Promise<boolean | void> {
        if (showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            await axiosFetch(
                'delete',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/${promptId}`,
                },
                cancellationKey,
            )

            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            return true
        } catch (error) {
            if (showLoadingScreen) {
                this.appService.unsetModuleLoading()
            }
            throw error
        }
    }

    public async updatePriorityMap(
        domainId: number,
        priorityMap: any[],
        cancellationKey?: string,
    ): Promise<any | void> {
        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/priorities`,
                    data: priorityMap,
                },
                cancellationKey,
            )
            const { data: map, ...data } = req.data

            return map
        } catch (error) {}
    }

    public async enablePromptById(
        domainId: number,
        promptId: number,
        cancellationKey?: string,
    ): Promise<PromptGroupDto | void> {
        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/${promptId}`,
                    data: { isActive: true },
                },
                cancellationKey,
            )
            const { data: prompt, ...data } = req.data

            return PromptGroupDto.parse(prompt)
        } catch (error) {}
    }

    public async disablePromptById(
        domainId: number,
        promptId: number,
        cancellationKey?: string,
    ): Promise<PromptGroupDto | void> {
        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/prompt-groups/${promptId}`,
                    data: { isActive: false },
                },
                cancellationKey,
            )
            const { data: prompt, ...data } = req.data

            return PromptGroupDto.parse(prompt)
        } catch (error) {}
    }

    public async promoteTestPrompt(
        group: PromptGroupDto,
        prompt: PromptDto,
        cancellationKey?: string,
    ): Promise<boolean | void> {
        try {
            await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${group.domainId}/prompt-groups/${group.id}/promote/${prompt.id}`,
                },
                cancellationKey,
            )

            return true
        } catch (error) {
            throw error
        }
    }
}
