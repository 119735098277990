import React from 'react'
import './domain-prompt-settings.scss'
import classnames from 'classnames'
import DomainSettingsTab, {
    IDomainSettingsTab,
    IDomainSettingsTabState,
} from '../abstract-domain-settings-tab/abstract-domain-settings-tab'
import { GeneralSettingsWell } from './general-settings-well'

interface IDomainPromptSettings extends IDomainSettingsTab {}

interface IState extends IDomainSettingsTabState {}

class DomainPromptSettings extends DomainSettingsTab<IDomainPromptSettings, IState> {
    public render() {
        return (
            <div className={classnames('domain-settings-tab', 'domain-prompt-settings')}>
                <GeneralSettingsWell
                    loading={this.state.loading}
                    domain={this.state.domain!}
                    onDomainUpdated={this.handleDomainUpdated}
                />
            </div>
        )
    }
}

export default DomainPromptSettings
