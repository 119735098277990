export const PromptApiColumns = {
    'DOMAIN.ID': 'domain.id',
    'DOMAIN.NAME': 'domain.name',

    'PROMPT.ID': 'prompt.id',
    'PROMPT.NAME': 'prompt.name',
    'PROMPT.STYLE': 'prompt.style',
    'PROMPT.PRIORITY': 'prompt.priority',
    'PROMPT.VISITOR_CONDITIONS': 'prompt.visitor_conditions',

    'GROUP.ID': 'group.id',
    'GROUP.NAME': 'group.name',

    'TEST.ID': 'test.id',
    'TEST.NAME': 'test.name',

    PERMISSIONS_SHOWN: 'permissions_shown',
    PERMISSIONS_DISMISSED: 'permissions_dismissed',
    PERMISSIONS_DENIED: 'permissions_denied',
    PROMPTS_SHOWN: 'prompts_shown',
    PROMPTS_SHOWN_NON_UNIQUE: 'prompts_shown_non_unique',
    PROMPTS_ACCEPTED: 'prompts_accepted',
    PROMPTS_ACCEPTED_NON_UNIQUE: 'prompts_accepted_non_unique',
    PROMPTS_DISMISSED: 'prompts_dismissed',
    PROMPTS_DISMISSED_NON_UNIQUE: 'prompts_dismissed_non_unique',
    PROMPT_ACCEPT_RATE: 'prompt_accept_rate_decimal',
    SUBSCRIPTIONS: 'subscriptions',
    SUBSCRIPTION_RATE: 'subscription_rate_decimal',
}
