import { Container, Singleton } from 'typescript-ioc/es5'
import * as querystring from 'query-string'
import { simpleNotification } from '../_utils/utils'
import { AppService } from './app'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import IApiCallOptions from '../interfaces/api-call-options'
import aqe from '@pushly/aqe'
import { IdentityProviderDto } from '../dtos/identity-provider.dto'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class IdentityProviderService {
    public appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async fetchByAccountId(
        accountId: number,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<IdentityProviderDto[]>> {
        let ok = false
        let dtos: IdentityProviderDto[] = []
        let meta: any

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/identity-providers`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            ok = true
            dtos = req.data.data.map(IdentityProviderDto.fromApiResponse)
            meta = req.data.meta
        } catch (error) {
            if (!isAxiosCancellation(error)) {
                simpleNotification('error', error?.response?.data?.message)
            }
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: dtos, meta }
    }

    public async fetchIdpById(
        accountId: number,
        idpId: number,
        opts: IApiCallOptions = {},
    ): Promise<{ data: IdentityProviderDto; meta: any; ok: boolean }> {
        let ok = false
        let identityProvider: IdentityProviderDto
        let meta: any

        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/identity-providers/${idpId}`

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts?.cancellationKey,
            )

            identityProvider = IdentityProviderDto.fromApiResponse(res.data.data)
            ok = true
            meta = res.data.meta
        } catch (error) {
            handleResponseErrorMessage(error)
        }
        return { ok, data: identityProvider!, meta }
    }

    public async archive(accountId: number, idpId: number, opts: IApiCallOptions = {}): Promise<boolean> {
        let ok = false

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/identity-providers/${idpId}`

        try {
            const req = await axiosFetch(
                'delete',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )

            ok = true

            simpleNotification('success', `Identity Provider has been successfully archived.`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return ok
    }

    public async create(
        accountId: number,
        createDto: any,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<IdentityProviderDto>> {
        let ok = false
        let identityProvider: IdentityProviderDto

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/identity-providers`

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: createDto,
                },
                opts.cancellationKey,
            )

            ok = true
            identityProvider = IdentityProviderDto.fromApiResponse(req.data.data)

            simpleNotification('success', `${identityProvider.name} has been successfully created.`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: identityProvider! }
    }

    public async edit(
        accountId: number,
        identityProviderId: number,
        editDto: any,
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<IdentityProviderDto>> {
        let ok = false
        let identityProvider: IdentityProviderDto

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/accounts/${accountId}/identity-providers/${identityProviderId}`

        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: serviceURL,
                    data: editDto,
                },
                opts.cancellationKey,
            )

            ok = true
            identityProvider = IdentityProviderDto.fromApiResponse(req.data.data)

            simpleNotification('success', `${identityProvider.name} has been successfully updated.`)
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: identityProvider! }
    }
}
