export const MonthlyUniquesApiColumns = {
    PLACEMENT: 'placement',
    CHANNEL: 'channel',
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr_decimal',
    PERMISSIONS_SHOWN: 'permissions_shown',
    SUBSCRIPTIONS: 'subscriptions',
    SUBSCRIPTION_RATE: 'subscription_rate_decimal',
}
