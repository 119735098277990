import React from 'react'
import './styles/notification-schedule-builder.scss'
import moment from 'moment-timezone'
import { getClassNames } from '../../../_utils/classnames'
import { Well } from '@pushly/aqe/lib/components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { DatePicker, Popover, Radio, Select, Skeleton, TimePicker, Tooltip } from 'antd'
import { NotificationDeliveryModel } from '../../../models/notification/notification-delivery.model'
import { NotificationDeliveryType } from '../../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../../enums/notification-delivery-window'
import { DomainDto } from '../../../dtos/domain'
import { Moment } from 'moment'
import { getSupportedTimezones } from '../../../_utils/moment'
import { INotificationBuilderState } from '../interfaces/notification-builder-state.interface'
import { isAllFeatTest, isContentTest, isDeliveryTest, isSingleDelivery } from '../helpers'
import { AccountDto } from '../../../dtos/account.dto'
import { NotificationBuilderLevel } from '../enums'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc'
import { FEAT_NOTIF_INF_DELIVERY } from '../../../constants'

interface INotificationDelivery {
    className?: string
    builder: INotificationBuilderState
    value: NotificationDeliveryModel
    onChange?: (value: NotificationDeliveryModel) => any
    domain: DomainDto
    account?: AccountDto
    loading?: boolean
    disabled?: boolean
    hideTypeLabel?: boolean
    defaultTimeZone?: string
}

const parseDateTimeInTz = (dt: string | undefined, tz: string): Moment | null | undefined => {
    return !dt ? undefined : moment.tz(new Date(dt), tz)
}

const DisabledComplexDeliveryWrapper: React.FunctionComponent<any> = (props) => (
    <Popover
        overlayClassName={getClassNames('notification-builder-popover', 'disabled-option-popover')}
        content="Delivery strategy not compatible with Sample Distribution."
    >
        {props.children}
    </Popover>
)

const NotificationDeliveryBuilder = (props: INotificationDelivery) => {
    const { className, loading, builder, value, onChange, disabled, hideTypeLabel, defaultTimeZone } = props

    const appState = Container.get(AppState)

    const testTypes = builder.test?.getTestTypes()
    const isSampleDelivery = builder.test?.getDistributionType() === 'sample'
    const disableComplexDelivery = isContentTest(testTypes) && isSingleDelivery(testTypes) && isSampleDelivery
    const ComplexDeliveryWrapper = !disableComplexDelivery ? (_: any) => _.children : DisabledComplexDeliveryWrapper

    const now = moment()
    const tzOptions = getSupportedTimezones()
    const defaultTZ = defaultTimeZone ?? moment.tz.guess()
    const workingModel = value.clone()
    const workingTz = workingModel.getTimeZone() ?? defaultTZ
    const workingSendDate = parseDateTimeInTz(workingModel.getSendDate(), workingTz)

    const applyTz = (model: NotificationDeliveryModel, tz: string) => {
        model.setTimeZone(tz)

        const newDate = parseDateTimeInTz(model.getSendDate() ?? new Date().toString(), workingTz)!
        // @ts-ignore
        newDate.tz(tz, true)

        model.setSendDate(newDate.toISOString())

        return model
    }

    const applyTime = (model: NotificationDeliveryModel, time: Moment | undefined | null) => {
        time = (!time ? now : time) as Moment

        const oldDate = parseDateTimeInTz(model.getSendDate(), workingTz)!
        oldDate.hour(time.hour())
        oldDate.minute(time.minute())

        model.setSendDate(oldDate.toISOString())

        return model
    }

    const infDelFlag = appState.flags.findActive(FEAT_NOTIF_INF_DELIVERY)?.getKey()
    const canSendInformedDeliveryNotif =
        (builder.level === NotificationBuilderLevel.DOMAIN &&
            !!infDelFlag &&
            props?.domain?.flags?.includes(infDelFlag)) ||
        (builder.level === NotificationBuilderLevel.ORG && !!infDelFlag && props?.account?.flags?.includes(infDelFlag))
    const isDeliveryOrContentAndDeliveryTest = isDeliveryTest(testTypes) || isAllFeatTest(testTypes)

    const isLiftTest = builder.variants?.reduce((acc, currentValue) => {
        if (currentValue.getDelivery().isUsingHoldOutStrategy) {
            acc = true
        }

        return acc
    }, false)

    const renderStrategyDescription = (param: NotificationDeliveryModel) => {
        if (param.isUsingFixedStrategy) {
            if (param.isUsingImmediateDelivery) {
                return <span>The notification will be delivered to the audience immediately.</span>
            } else {
                return <span>The notification will be delivered to the audience at the specified date and time.</span>
            }
        } else if (param.isUsingStzStrategy) {
            if (param.isUsingImmediateDelivery) {
                return (
                    <span>
                        The notification will be delivered at the most likely time the subscriber will interact with it.
                        Delivery may occur at any time within the next 24 hours.
                    </span>
                )
            } else {
                return (
                    <span>
                        The notification will be delivered to each subscriber at the specified date and time in their
                        time zone.
                    </span>
                )
            }
        } else if (param.isUsingHoldOutStrategy) {
            if (param.isUsingImmediateDelivery) {
                return (
                    <span>
                        The percentage of subscribers configured for this variant will not receive the notification. Use
                        this option to perform a lift test to better understand the causal impact of this notification.
                    </span>
                )
            } else {
                return (
                    <span>
                        The percentage of subscribers configured for this variant will not receive the notification. Use
                        this option to perform a lift test to better understand the causal impact of this notification.
                        The hold out audience will be calculated at the specified date and time.
                    </span>
                )
            }
            // Informed Strategy
        } else {
            if (param.isUsingImmediateDelivery) {
                return (
                    <span>
                        The notification will be delivered at the most likely time the subscriber will interact with it.
                        Delivery may occur at any time within the next 24 hours.
                    </span>
                )
            } else {
                return (
                    <span>
                        The notification will be delivered at the most likely time the subscriber will interact with it.
                        Delivery may occur at any time within the ensuing 24 hours after the specified date and time.
                    </span>
                )
            }
        }
    }

    return (
        <Well
            className={getClassNames('notification-delivery-builder', className)}
            mode="ghost"
            hideHeader={true}
            hideFooter={true}
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                <Form.Item className="delivery-type-picker" label={hideTypeLabel ? undefined : 'Delivery Type'}>
                    <Radio.Group
                        size="small"
                        buttonStyle="solid"
                        onChange={(ev) => {
                            const update = workingModel.clone()
                            const type = ev.target.value
                            update.setType(type)
                            update.setTimeZone(defaultTZ)
                            update.setSendDate(undefined)

                            if (type === NotificationDeliveryType.IMMEDIATE) {
                                if (!update.isUsingInformedStrategy) {
                                    update.setWindow(NotificationDeliveryWindow.STANDARD)
                                }
                            } else {
                                update.setSendDate(moment().tz(defaultTZ).add(15, 'minute').toISOString())
                            }

                            onChange?.(update)
                        }}
                        disabled={disabled}
                        value={workingModel.getType()}
                    >
                        <Radio.Button value={NotificationDeliveryType.IMMEDIATE}>Immediate</Radio.Button>
                        <Radio.Button value={NotificationDeliveryType.SCHEDULED}>Scheduled</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <div className="strategy-selection">
                    <Form.Item label="Delivery Strategy">
                        <Radio.Group
                            size="small"
                            buttonStyle="solid"
                            disabled={disabled}
                            value={workingModel.getWindow()}
                            onChange={(ev) => {
                                const strategy = ev.target.value

                                const update = workingModel.clone()
                                update.setWindow(strategy)

                                if (!update.isUsingImmediateDelivery) {
                                    applyTz(update, defaultTZ)

                                    if (update.isUsingInformedStrategy) {
                                        applyTime(update, now.clone().hour(0).minute(0))
                                    } else if (workingModel.isUsingInformedStrategy) {
                                        applyTime(update, now.clone().add(15, 'minute'))
                                    }
                                }

                                onChange?.(update)
                            }}
                        >
                            <Radio.Button value={NotificationDeliveryWindow.STANDARD}>
                                {!workingModel.isUsingImmediateDelivery ? 'Fixed' : 'Now'}
                            </Radio.Button>
                            <ComplexDeliveryWrapper>
                                {!workingModel.isUsingImmediateDelivery && (
                                    <Radio.Button
                                        value={NotificationDeliveryWindow.TIMEZONE}
                                        disabled={disableComplexDelivery}
                                    >
                                        Subscriber Time Zone
                                    </Radio.Button>
                                )}
                                {canSendInformedDeliveryNotif && (
                                    <Radio.Button
                                        value={NotificationDeliveryWindow.INFORMED}
                                        disabled={disableComplexDelivery}
                                    >
                                        Informed
                                    </Radio.Button>
                                )}
                                {isDeliveryOrContentAndDeliveryTest &&
                                    (!(
                                        isLiftTest &&
                                        builder.variants?.length > 1 &&
                                        workingModel.getWindow() !== NotificationDeliveryWindow.HOLD_OUT
                                    ) ? (
                                        <Radio.Button value={NotificationDeliveryWindow.HOLD_OUT}>
                                            Hold Out
                                        </Radio.Button>
                                    ) : (
                                        <Tooltip title="Only one variant in an A/B Test can use the “Hold Out” delivery strategy.">
                                            <Radio.Button
                                                value={NotificationDeliveryWindow.HOLD_OUT}
                                                disabled={
                                                    isLiftTest &&
                                                    builder.variants?.length > 1 &&
                                                    workingModel.getWindow() !== NotificationDeliveryWindow.HOLD_OUT
                                                }
                                            >
                                                Hold Out
                                            </Radio.Button>
                                        </Tooltip>
                                    ))}
                            </ComplexDeliveryWrapper>
                        </Radio.Group>

                        <div className="strategy-description">
                            {/*<Icon type="info-circle-o" className="info-icon" />*/}
                            {renderStrategyDescription(workingModel)}
                        </div>
                    </Form.Item>
                </div>

                {!workingModel.isUsingImmediateDelivery && (
                    <Form.Item
                        className="delivery-senddate"
                        label={workingModel.isUsingInformedStrategy ? 'Earliest Delivery' : 'Schedule Time'}
                    >
                        <div className="delivery-senddate-picker">
                            <DatePicker
                                className="delivery-date-picker"
                                format="ddd, MMM DD, YYYY"
                                placeholder="Send Date"
                                disabled={disabled}
                                // @ts-ignore
                                value={workingSendDate}
                                onChange={(v) => {
                                    const update = workingModel.clone()

                                    if (!v) {
                                        update.setSendDate(undefined)
                                        update.setTimeZone(defaultTZ)
                                    } else {
                                        const oldDate =
                                            parseDateTimeInTz(workingSendDate?.toISOString(), workingTz) ?? v
                                        v.hour(!workingSendDate ? now.hour() : oldDate.hour())
                                        v.minute(!workingSendDate ? now.minute() : oldDate.minute())

                                        update.setSendDate(v.toISOString())
                                    }

                                    onChange?.(update)
                                }}
                            />

                            <TimePicker
                                className="delivery-time-picker"
                                format="h:mm a"
                                use12Hours={true}
                                placeholder="Time"
                                disabled={disabled || !workingSendDate}
                                // @ts-ignore
                                value={workingSendDate ?? undefined}
                                onChange={(v) => {
                                    const update = workingModel.clone()
                                    applyTime(update, v)

                                    onChange?.(update)
                                }}
                                onSelect={(v) => {
                                    const update = workingModel.clone()
                                    applyTime(update, v)

                                    onChange?.(update)
                                }}
                            />

                            {!workingModel.isUsingStzStrategy && (
                                <Select
                                    className="delivery-tz-picker"
                                    dropdownClassName={getClassNames('delivery-tz-picker-overlay')}
                                    disabled={disabled || !workingSendDate}
                                    showSearch={true}
                                    placeholder="Select a time zone"
                                    value={workingTz}
                                    onChange={(tz) => {
                                        const update = workingModel.clone()
                                        applyTz(update, tz)

                                        onChange?.(update)
                                    }}
                                >
                                    {tzOptions.map((tz) => (
                                        <Select.Option key={tz} value={tz}>
                                            {tz}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </Form.Item>
                )}
            </Skeleton>
        </Well>
    )
}

export default NotificationDeliveryBuilder
