import React from 'react'
import moment from 'moment-timezone'
import {
    IAbstractNativeApnsSendIntegration,
    IWebApnsSendIntegration,
    SendIntegrationChannel,
} from '../../interfaces/send-integrations'
import { SendIntegrationPrivateKeyType } from '../../enums/send-integration-private-key-type.enum'
import {
    AbstractNativeApnsConfiguration,
    NativeP12ApnsConfiguration,
    NativeP8ApnsConfiguration,
} from '../../models/send-integration-configurations/native-apns-configurations.model'
import { WebApnsConfigurationModel } from '../../models/send-integration-configurations/web-apns-configuration.model'
import { FormLayoutRow } from '../form-layout-row/form-layout-row'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { titleCase } from '../../_utils/utils'
import { SHORT_DATE_FORMAT } from '../../constants'

type WebApnsProps = {
    channel: SendIntegrationChannel.WEB
    apnsConfiguration: IWebApnsSendIntegration
}

type NativeApnsProps = {
    channel: SendIntegrationChannel.NATIVE
    apnsConfiguration: IAbstractNativeApnsSendIntegration
}

type ApnsIntegrationWellProps = WebApnsProps | NativeApnsProps

export const ApnsIntegrationWell = (props: ApnsIntegrationWellProps) => {
    let configClass:
        | typeof NativeP8ApnsConfiguration
        | typeof NativeP12ApnsConfiguration
        | typeof WebApnsConfigurationModel
        | undefined
    if (props.channel === SendIntegrationChannel.WEB) {
        configClass = WebApnsConfigurationModel
    } else if (props.apnsConfiguration.privateKeyType === SendIntegrationPrivateKeyType.P8) {
        configClass = NativeP8ApnsConfiguration
    } else if (props.apnsConfiguration.privateKeyType === SendIntegrationPrivateKeyType.P12) {
        configClass = NativeP12ApnsConfiguration
    }

    const config = configClass?.build(props.apnsConfiguration)
    const enabled = config?.getIsActive() ?? false

    const isNativeView = props.channel === SendIntegrationChannel.NATIVE
    const firstSectionRenderValue =
        config instanceof WebApnsConfigurationModel
            ? config?.getPushPackage()?.website_push_id!
            : config?.getAppBundleId()!

    return (
        <div className="web-apns-well">
            <div className="integrations-section-title">
                <span className={enabled ? 'enabled' : 'disabled'}>
                    {enabled ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                </span>
                <span>{props.channel}</span>
            </div>

            {!enabled ? (
                <FormLayoutRow label="Not Enabled" />
            ) : (
                <>
                    {!(config instanceof NativeP8ApnsConfiguration) && (
                        <>
                            <FormLayoutRow
                                label={isNativeView ? 'App Bundle ID' : 'Website Push Id'}
                                value={firstSectionRenderValue}
                                copyEnabled={true}
                            />

                            <FormLayoutRow
                                label="Expiration"
                                value={moment(config?.getExpiration()).format(SHORT_DATE_FORMAT)}
                            />
                        </>
                    )}

                    {config instanceof NativeP8ApnsConfiguration && (
                        <>
                            <FormLayoutRow label="Team ID" value={config.getTeamId()} />

                            <FormLayoutRow label="Key ID" value={config.getKeyId()} />
                        </>
                    )}

                    {config instanceof AbstractNativeApnsConfiguration && (
                        <FormLayoutRow
                            label="Environment"
                            value={`${titleCase(config.getApnsEnvironment())}${
                                !config.isProductionApnsEnvironment ? ' (sandbox)' : ''
                            }`}
                        />
                    )}
                </>
            )}
        </div>
    )
}
