import * as React from 'react'
import * as queryString from 'query-string'
import { Redirect } from 'react-router-dom'
import { DomainIntegrationProvider } from '../../enums/domain-integration-provider'

const extractDomainIdFromState = (state: string | undefined): string | undefined => {
    let domainId: any
    if (!!state) {
        const stateParts = state.split(/:/g)
        domainId = stateParts[1]
    }
    return domainId
}

const extractOauthRequestModel = (
    provider: string | undefined,
    qs: any,
): any & { valid: boolean; domainId: string } => {
    const model: any = {
        valid: false,
        provider,
        ...qs,
    }

    if (provider === DomainIntegrationProvider.SHOPIFY) {
        model.domainId = extractDomainIdFromState(qs?.state?.toString())
        if (!!model.domainId && !!model.hmac && !!model.code && !!model.state && !!model.timestamp && !!model.shop) {
            model.valid = true
        }
    } else if (provider === DomainIntegrationProvider.SLACK) {
        model.domainId = extractDomainIdFromState(qs?.state?.toString())

        if (!!model.domainId && (!!model.error || !!model.code) && !!model.state) {
            model.valid = true
        }
    } else if (provider === DomainIntegrationProvider.AMPLY) {
        model.domainId = extractDomainIdFromState(qs?.state?.toString())
        if (!!model.domainId && (!!model.error || !!model.code) && !!model.state) {
            model.valid = true
        }
    }

    return model
}

const IntegrationsOauthInterceptor = () => {
    const qs = queryString.parse(location.search)
    const model = extractOauthRequestModel(qs.provider?.toString(), qs)

    if (model.valid) {
        return <Redirect from={'/integrations'} to={`/domains/${model.domainId}/${location.search}#integrations`} />
    }

    return <>Invalid OAuth redirect detected.</>
}

export default IntegrationsOauthInterceptor
