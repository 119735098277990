import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import * as moment from 'moment-timezone'
import { Moment } from 'moment-timezone/moment-timezone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { getDayName } from '../../_utils/moment'

function getCleanArray(arr: any[], transform?: (dto: any) => any) {
    arr = arr.filter((v) => v !== null && v !== undefined)
    if (transform) {
        arr = arr.map(transform)
    }
    return Array.from(new Set(arr))
}

export type CampaignRecurrenceType = 'daily' | 'weekly' | 'monthly_specific' | 'monthly_relative'
export type MonthlyRelativeType = 'first_day' | 'last_day' | 'relative_day'

export enum DayOrdinal {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
    FOURTH = 'fourth',
    FIFTH = 'fifth',
}

export enum DayOfWeek {
    MONDAY = 'monday',
    TUESDAY = 'tuesday',
    WEDNESDAY = 'wednesday',
    THURSDAY = 'thursday',
    FRIDAY = 'friday',
    SATURDAY = 'saturday',
    SUNDAY = 'sunday',
}

export class RecurrenceSchedule {
    public static build(props: RecurrenceSchedule | any): RecurrenceSchedule {
        const data = props instanceof RecurrenceSchedule ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new RecurrenceSchedule()
        model.setType(data.type)
        model.setSpecificTime(data.specific_time)
        model.setTimeZone(data.time_zone)

        if (model.getType() === 'daily' || model.getType() === 'weekly') {
            model.setFrequency(scData.frequency)
            model.setAllowedDays(scData.allowed_days)
        } else if (model.getType() === 'monthly_specific') {
            model.setSpecificDay(scData.specific_day)
        } else if (model.getType() === 'monthly_relative') {
            model.setRelativeType(scData.relative_type)
            model.setRelativeDayOrdinal(scData.relative_day?.ordinal || scData.relative_day_ordinal)
            model.setRelativeDay(scData.relative_day?.day || scData.relative_day)
        }

        return model
    }

    private type!: CampaignRecurrenceType
    private specific_time?: string
    private time_zone?: string
    private frequency?: number
    private allowed_days?: DayOfWeek[]
    private specific_day?: number
    private relative_type?: MonthlyRelativeType
    private relative_day_ordinal?: DayOrdinal
    private relative_day?: DayOfWeek

    public clone(): RecurrenceSchedule {
        return RecurrenceSchedule.build(this)
    }

    public serialize(_: boolean = true): any {
        const serialized: any = {
            type: this.getType(),
            specific_time: this.getSpecificTime(),
            time_zone: this.getTimeZone(),
            frequency: this.getFrequency(),
            allowed_days: this.getAllowedDays(),
            specific_day: this.getSpecificDay(),
            relative_type: this.getRelativeType(),
        }

        if (serialized.relative_type === 'relative_day') {
            serialized.relative_day = {
                ordinal: this.getRelativeDayOrdinal(),
                day: this.getRelativeDay(),
            }
        }

        return stripUndefined(serialized)
    }

    public setType(type: string | CampaignRecurrenceType): void {
        this.type = type as CampaignRecurrenceType

        if (type === 'daily' || type === 'weekly') {
            this.specific_day = undefined
            this.relative_type = undefined
            this.relative_day_ordinal = undefined
            this.relative_day = undefined
        } else {
            this.frequency = undefined
            this.allowed_days = undefined

            if (type === 'monthly_specific') {
                this.relative_day_ordinal = undefined
                this.relative_day = undefined
            } else if (type === 'monthly_relative') {
                this.relative_type = this.relative_type ?? undefined
            }
        }
    }
    public getType(): CampaignRecurrenceType {
        return this.type
    }

    public setSpecificTime(time: string | undefined): void {
        if (time !== undefined) {
            time = time.toString().trim()
            if (!/^[\d]{2}:[\d]{2}$/i.test(time)) {
                throw new Error('Invalid Argument. Argument time must be in a hh:mm format.')
            }
        }

        this.specific_time = time
    }
    public getSpecificTime(): string | undefined {
        return this.specific_time
    }

    public setTimeZone(tz: string | undefined): void {
        this.time_zone = !tz ? undefined : tz.trim()
    }
    public getTimeZone(): string | undefined {
        return this.time_zone
    }

    public setFrequency(frequency: number | undefined): void {
        if (frequency !== undefined) {
            if (String(frequency) !== '' && !isNaN(frequency)) {
                frequency = parseInt(frequency.toString(), 10)
            }
        }

        this.frequency = frequency
    }
    public getFrequency(): number | undefined {
        return this.frequency
    }

    public setAllowedDays(days: DayOfWeek[] | any[] | undefined): void {
        if (days !== undefined) {
            days = getCleanArray(days, (day) => day.toString().trim().toLowerCase())

            const invalid = days.some((day) => !DayOfWeek[day.toUpperCase()])
            if (invalid) {
                throw new Error('Invalid Argument. Argument days must contain valid days of the week.')
            }
        }

        this.allowed_days = days
    }
    public getAllowedDays(): DayOfWeek[] | undefined {
        return this.allowed_days?.sort()
    }

    public setSpecificDay(day: number): void {
        if (day !== undefined) {
            if (String(day) !== '' && !isNaN(day)) {
                day = parseInt(day.toString(), 10)
            }
        }

        this.specific_day = day
    }
    public getSpecificDay(): number | undefined {
        return this.specific_day
    }

    public setRelativeType(type: MonthlyRelativeType | undefined): void {
        if (type === 'first_day' || type === 'last_day') {
            this.relative_day_ordinal = undefined
            this.relative_day = undefined
        }

        this.relative_type = type
    }
    public getRelativeType(): MonthlyRelativeType | undefined {
        return this.relative_type
    }

    public setRelativeDayOrdinal(weekOrdinal: DayOrdinal | undefined): void {
        this.relative_day_ordinal = weekOrdinal
    }
    public getRelativeDayOrdinal(): DayOrdinal | undefined {
        return this.relative_day_ordinal
    }
    public getRelativeDayOrdinalNumber(): number | undefined {
        const name = this.getRelativeDayOrdinal()
        let ordinal: number | undefined

        switch (name) {
            case DayOrdinal.FIRST:
                ordinal = 0
                break
            case DayOrdinal.SECOND:
                ordinal = 1
                break
            case DayOrdinal.THIRD:
                ordinal = 2
                break
            case DayOrdinal.FOURTH:
                ordinal = 3
                break
            case DayOrdinal.FIFTH:
                ordinal = 4
                break
        }

        return ordinal
    }

    public setRelativeDay(dayOrdinal: DayOfWeek | string | undefined): void {
        this.relative_day = dayOrdinal as DayOfWeek | undefined
    }
    public getRelativeDay(): DayOfWeek | undefined {
        return this.relative_day
    }

    public getNextExecutionsFrom(
        n: number,
        dateFrom: Moment | string,
        dateTo?: Moment | string,
        fmt?: (m: Moment) => any,
    ): any[] {
        const timeZone = this.getTimeZone() === 'STZ' ? 'UTC' : this.getTimeZone() ?? 'UTC'

        const now = moment().tz(timeZone)

        dateFrom = moment.tz(dateFrom, timeZone)
        dateTo = moment.tz(dateTo ?? moment().add('years', 5), timeZone)

        const executions: Moment[] = []
        const type = this.getType()
        const freq = this.getFrequency()
        const allowedDays = this.getAllowedDays()

        const applyFmt = (mm: Moment) => (!fmt ? mm : fmt(mm))
        const applyTimeSettings = (mm: Moment) => {
            if (mm) {
                const specificTime = this.getSpecificTime() ?? '00:00'
                const st = moment(specificTime, 'HH:mm')
                mm.hour(st.hour()).minute(st.minute())
            }
        }

        if ((type === 'daily' || type === 'weekly') && freq !== undefined && !isNaN(freq)) {
            const startAdjustment = freq - 1 < 0 ? 0 : freq - 1
            // adjust start date to allow start today
            dateFrom.subtract(type === 'daily' ? 'day' : 'week', startAdjustment)
        }

        const startDay = dateFrom.clone().startOf('day')
        const startWeek = dateFrom.clone().startOf('week')
        const startMonth = dateFrom.clone().startOf('month')

        let iterations = 0
        while (executions.length < n) {
            if (iterations > 500) {
                break
            }

            if (type === 'daily' || type === 'weekly') {
                if (freq === undefined || isNaN(freq) || !allowedDays || allowedDays.length === 0) {
                    break
                }

                const nextExec = dateFrom.clone().add('day', iterations++ * (freq ?? 1))
                applyTimeSettings(nextExec)

                const nextExecDay = nextExec.clone().startOf('day')
                if (nextExecDay <= startDay) {
                    continue
                }

                if (type === 'weekly') {
                    const nextExecWeek = nextExec.clone().startOf('week')
                    if (nextExecWeek < startWeek) {
                        continue
                    }
                }

                if (nextExec > now && nextExec < dateTo && allowedDays?.includes(getDayName(nextExec).toLowerCase())) {
                    executions.push(nextExec)
                }
            } else if (type === 'monthly_specific') {
                if (!this.getSpecificDay()) {
                    break
                }

                const nextExec = startMonth.clone().add('month', iterations++)
                applyTimeSettings(nextExec)
                nextExec.month(nextExec.month()).date(this.getSpecificDay()!)

                if (nextExec > now && nextExec > startDay && nextExec.date() === this.getSpecificDay()) {
                    executions.push(nextExec)
                }
            } else if (type === 'monthly_relative') {
                const relativeType = this.getRelativeType()

                if (!relativeType) {
                    break
                }

                let nextExec = startMonth.clone().add('month', iterations++)
                if (relativeType === 'first_day') {
                    nextExec.startOf('month')
                } else if (relativeType === 'last_day') {
                    // moment.js will set to last day of month if < 31 days
                    //  https://momentjs.com/docs/#/get-set/date/
                    nextExec.endOf('month')
                } else if (relativeType === 'relative_day') {
                    const dayOrdinal = this.getRelativeDayOrdinalNumber()
                    const dayName = this.getRelativeDay()

                    if (!dayName || dayOrdinal === undefined) {
                        break
                    }

                    const nextExecMonth = nextExec.clone().startOf('month')
                    nextExec = undefined

                    const dayArr: Moment[] = []
                    let dayIteration = 0
                    while (dayArr.length < 5 && dayIteration < 31) {
                        const nextDay = nextExecMonth.clone().add('day', dayIteration++)
                        applyTimeSettings(nextDay)
                        if (
                            nextDay.month() === nextExecMonth.month() &&
                            getDayName(nextDay).toLowerCase() === dayName
                        ) {
                            dayArr.push(nextDay.clone())
                        }
                    }

                    if (dayArr.length > dayOrdinal) {
                        nextExec = dayArr[dayOrdinal].clone()
                    }
                } else {
                    break
                }

                applyTimeSettings(nextExec)
                if (!!nextExec && nextExec > now && nextExec > startDay && nextExec < dateTo) {
                    executions.push(nextExec)
                }
            } else {
                break
            }
        }

        return executions.map(applyFmt)
    }
}
