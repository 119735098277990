import * as React from 'react'
import * as moment from 'moment-timezone'
import { Cell as FDCell } from 'fixed-data-table-2'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { SHORT_DATE_FORMAT } from '../../../constants'

export const PUB_NETWORK_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        selectable: false,
        className: 'r-record-date',
        width: 140,
        columnKey: 'report_date',
        title: 'Report Date',
        align: 'center',
        sortable: true,
        render(data: any, dataSet: any[], config: any) {
            let value = moment(data.report_date).format(SHORT_DATE_FORMAT)

            if (config.dateIncrement === 'MONTH') {
                value = moment(data.report_date).format('MMM, YYYY')
            } else if (config.dateIncrement === 'YEAR') {
                value = moment(data.report_date).format('YYYY')
            }

            return value
        },
    },
    ['IMPRESSIONS']: {
        selectable: false,
        className: 'r-impressions',
        width: 120,
        columnKey: 'impressions',
        title: 'Impressions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.impressions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PAID_CLICKS']: {
        selectable: false,
        className: 'r-clicks',
        width: 120,
        columnKey: 'paid_clicks',
        title: 'Paid Clicks',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.paid_clicks)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.paid_clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PAID_CTR_DECIMAL']: {
        selectable: false,
        className: 'r-ctr',
        width: 120,
        columnKey: 'paid_ctr_decimal',
        requiredColumnKeys: ['paid_clicks', 'impressions'],
        title: 'Paid CTR',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.impressions ? '--' : numberWithPercent(data.paid_ctr_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggImps = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggClicks = dataSet.map((s) => s.paid_clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            const avgCTR = !aggImps ? '--' : numberWithPercent(aggClicks / aggImps)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgCTR}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['REVENUE']: {
        selectable: false,
        className: 'r-revenue',
        width: 120,
        columnKey: 'revenue',
        title: 'Revenue',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.revenue
                ? '--'
                : Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(data.revenue)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.revenue).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">
                        {!data ? '--' : Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' }).format(data)}
                    </div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
}
