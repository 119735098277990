import { INotificationTreatment } from '@pushly/aqe/lib/interfaces/notification-treatment'
import { NotificationDto } from '../features/notifications/index'
import { OrgNotificationModel } from '../models/notification/org-notification.model'
import randomstring from 'randomstring'

export function extractSummaryNotification(
    notification: NotificationDto | OrgNotificationModel,
): INotificationTreatment {
    return notification instanceof OrgNotificationModel
        ? {
              id: notification.getId(),
              title: notification.getVariant()?.getContent()?.getDefaultContent().getTitle(),
              body: notification.getVariant()?.getContent()?.getDefaultContent().getBody(),
              image: notification.getVariant()?.getContent()?.getDefaultContent().getImageUrl(),
              icon: notification.getVariant()?.getContent()?.getDefaultContent().getIconUrl(),
              badge: notification.getVariant()?.getContent()?.getDefaultContent().getBadgeUrl(),
              altNativeUrl: notification.getVariant()?.getContent()?.getDefaultContent()?.getNativeAltUrl(),
              landingUrl: notification.getVariant()?.getContent()?.getDefaultContent().getLandingUrl(),
              landingUrlDisabled: !notification.getVariant()?.getContent()?.getDefaultContent().getLandingUrl(),
              actions: notification
                  .getVariant()
                  ?.getContent()
                  ?.getDefaultContent()
                  .getActions()
                  ?.map((a) => a.serialize()),
              keywords: notification.getVariant()?.getContent()?.getDefaultContent().getKeywords(),
          }
        : ({
              id: (notification.id as any) || randomstring.generate(5),
              title: notification.defaultTemplate.title || notification.webTemplate.title || undefined,
              body: notification.defaultTemplate.body || notification.webTemplate.body || undefined,
              image: notification.defaultTemplate.imageUrl || notification.webTemplate.imageUrl || undefined,
              icon: notification.defaultTemplate.iconUrl || notification.webTemplate.iconUrl || undefined,
              badge: notification.defaultTemplate.badgeUrl || notification.webTemplate.badgeUrl || undefined,
              altNativeUrl: notification.defaultTemplate.altNativeUrl || undefined,
              landingUrl: notification.defaultTemplate.landingUrl || notification.webTemplate.landingUrl || undefined,
              landingUrlDisabled: !notification.webTemplate.landingUrl,
              actions: notification.defaultTemplate.actions || (notification.webTemplate as any).actions,
              keywords: notification.keywords,
          } as any)
}
