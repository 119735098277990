import React from 'react'
import './styles/notification-ecomm-options.scss'
import { Well } from '@pushly/aqe/lib/components'
import { Tooltip, Skeleton } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getClassNames } from '../../../_utils/classnames'
import { NotificationContentModel } from '../../../models/notification/notification-content.model'
import { PromiseableProp } from '../../../_utils/promiseable-prop'
import TemplatizedTextField from '../../templatized-text-field/templatized-text-field'
import { NotificationDefaultContentModel } from '../../../models/notification/notification-default-content.model'

interface INotificationWebhookOptionsProps {
    className?: string
    value: NotificationDefaultContentModel
    onChange: (value: NotificationDefaultContentModel) => any
    loading?: boolean
    disabled?: boolean
    customMacroOptions?: PromiseableProp<any>
}

const NotificationWebhookOptions = (props: INotificationWebhookOptionsProps) => {
    const { loading, value, onChange, disabled } = props

    const workingModel = value.clone()
    const macroTypes = ['location', 'notification', 'custom']

    return (
        <Well
            className={getClassNames('notification-webhook-options', {})}
            title="Content Webhook"
            mode="ghost"
            hideFooter={true}
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                <TemplatizedTextField
                    label="Webhook URL"
                    disabled={disabled}
                    hideEmojis={true}
                    macroTypes={macroTypes}
                    customMacroOptions={props.customMacroOptions}
                    value={workingModel.getContentWebhookUrl()}
                    onChange={(v) => {
                        workingModel.setContentWebhookUrl(v || null)
                        onChange(workingModel)
                    }}
                />
            </Skeleton>
        </Well>
    )
}

export default NotificationWebhookOptions
