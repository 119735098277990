import { useEffect, MutableRefObject } from 'react'

interface IExternalScript {
    src: string
    onunload?: () => void
}

export const injectExternalScript = <T, R extends HTMLElement>(
    { src, onload, onunload, dataset, ...script }: IExternalScript & Partial<HTMLScriptElement> & T,
    container?: MutableRefObject<R>,
): HTMLScriptElement => {
    const s = document.createElement('script')
    s.src = src
    if (onload) {
        s.onload = onload
    }

    const attrKeys = Object.keys(script ?? {})
    if (attrKeys.length > 0) {
        attrKeys.forEach((key) => {
            s.setAttribute(key, script[key])
        })
    }

    const wrapper = container?.current ?? document.body
    wrapper.appendChild(s)

    return s
}

export const useExternalScript = <T, R extends HTMLElement>(
    props: IExternalScript & Partial<HTMLScriptElement> & T,
    container?: MutableRefObject<R>,
) => {
    useEffect(() => {
        const s = injectExternalScript(props, container)

        return () => {
            s.remove()
            props.onunload?.()
        }
    }, [props.src, container?.current])
}
