import * as React from 'react'
import * as clone from 'clone'
import { Container } from 'typescript-ioc/es5'
import { Well, Button, Drawer } from '@pushly/aqe/lib/components'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Popover } from 'antd'
import { UserDto } from '../../dtos/user'
import { AccountService, AppService, UserService } from '../../services'
import { getClassNames } from '../../_utils/classnames'
import { simpleFormErrorNotification, simpleNotification } from '../../_utils/utils'
import { AppState } from '../../stores/app'
import randomstring from 'randomstring'
import { IUserAccountAccessView, UserAccountAccessView } from './user-access-view'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../constants'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AppAbility, asCaslSubject } from '../../stores/app-ability'
import AccessControlService from '../../services/access-control.service'
import { Ability } from '@casl/ability'
import { DomainDto } from '../../dtos/domain'
import { observer } from 'mobx-react'

interface IUserAccessEditor extends IUserAccountAccessView {
    visible: boolean
    onClose: (e: any) => void
    onUpdated?: (account: Partial<UserDto>) => void
}

@observer
class UserAccessEditor extends React.Component<IUserAccessEditor, any> {
    protected appState: AppState
    protected appService: AppService
    protected userService: UserService

    protected accountSnapshot: any

    public constructor(props: IUserAccessEditor) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.userService = Container.get(UserService)

        this.accountSnapshot = clone(this.props.account)
    }

    public componentDidUpdate() {
        if (this.props.account && !this.accountSnapshot) {
            this.accountSnapshot = clone(this.props.account)
        }
    }

    public render() {
        return (
            <Drawer
                getContainer={this.appService.getAppContainer}
                className={getClassNames('user-access-editor')}
                title={
                    <>
                        <span>Edit User Access</span>
                        <Popover
                            overlayClassName="user-management-info-popover"
                            content={
                                <>
                                    To learn more about access levels visit our{' '}
                                    <a
                                        href={`${this.appState.documentationLink}/platform/user-management#access-levels`}
                                        target="_blank"
                                    >
                                        User Managment documentation
                                    </a>
                                    .
                                </>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </>
                }
                placement="right"
                closable={true}
                onSubmit={this.handleSubmit}
                disableSubmit={!this.props.acs.hasChanges}
                onClose={this.props.onClose}
                visible={this.props.visible}
            >
                {this.props.account && (
                    <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                        <UserAccountAccessView
                            key={this.props.account?.id ?? 'NO_ACCT'}
                            mode="nested"
                            user={this.props.user}
                            acs={this.props.acs}
                            account={this.props.account}
                            domainId={this.props.domainId}
                            singleDomain={this.props.singleDomain ?? false}
                        />
                    </Form>
                )}
            </Drawer>
        )
    }

    protected get user(): UserDto {
        return this.props.user
    }

    protected handleSubmit = async () => {
        try {
            await this.props.acs.persistChanges()

            this.props.onUpdated?.({})

            this.props.onClose(null)
            simpleNotification('success', `User, ${this.props.user.name}, has been successfully updated.`)
        } catch (err) {
            simpleFormErrorNotification(err)
        }
    }
}

interface IUserAccessWell {
    user?: UserDto & any
    accountId?: number
    domainId?: number
    singleDomain?: boolean
    loading?: boolean
    onChange?: (permissions: any) => any
    onUpdated?: (account: Partial<UserDto>) => void
    mode?: 'default' | 'stand-alone' | 'nested' | 'read-only' | 'add-user'
    defaultValue?: any
}

interface IUserAccessWellState {
    account?: { id: number }
    domains: DomainDto[]
    loadingDependencies: boolean
    showDrawer: boolean
    drawerKey?: string
    propsUserACS: AccessControlService
    propsUserAbility?: AppAbility
}

@observer
export class UserAccessWell extends React.Component<IUserAccessWell, IUserAccessWellState> {
    protected appState: AppState
    protected appService: AppService
    protected accountService: AccountService
    protected userService: UserService

    public constructor(props: IUserAccessWell) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.accountService = Container.get(AccountService)
        this.userService = Container.get(UserService)

        this.state = {
            loadingDependencies: false,
            domains: [],
            showDrawer: false,
            propsUserACS: AccessControlService.forUser(props.user),
        }
    }

    public async componentDidMount(): Promise<void> {
        this.fetchDependencies()
    }

    public render() {
        const { mode } = this.props
        const modeIsDefault = mode !== 'nested'

        // account is guaranteed to be active status in public platform at this point
        const orgAbilityIdentity = asCaslSubject(SubjectEntity.ORG, {
            id: this.props.accountId,
            statusId: 1,
        })
        const userIsOrgAdmin = this.state.propsUserAbility?.can(AbilityAction.UPDATE, orgAbilityIdentity) ?? false
        const platformUserIsOrgAdmin = this.appState.abilityStore.can(AbilityAction.UPDATE, orgAbilityIdentity)

        return (
            <Well
                className={getClassNames('user-access-well', 'nested type-2', {
                    ['user-access-nested-well']: !modeIsDefault,
                })}
                title={
                    <>
                        <span>Domain Access</span>
                        <Popover
                            overlayClassName="user-management-info-popover"
                            content={
                                <>
                                    To learn more about access levels visit our{' '}
                                    <a
                                        href={`${this.appState.documentationLink}/platform/user-management#access-levels`}
                                        target="_blank"
                                    >
                                        User Management documentation
                                    </a>
                                    .
                                </>
                            }
                        >
                            <InfoCircleOutlined className="info-icon" />
                        </Popover>
                    </>
                }
                hideFooter={true}
                mode={modeIsDefault ? 'default' : 'ghost'}
                loading={this.isLoading}
                actions={!this.isLoading && this.buildActions()}
            >
                {!this.state.account ? (
                    <div className="no-account-selected-message">
                        Please select an organization to view permissions.
                    </div>
                ) : userIsOrgAdmin && !platformUserIsOrgAdmin ? (
                    <div className="no-account-selected-message">
                        You do not have access to modify this user's permissions.
                    </div>
                ) : (
                    <UserAccountAccessView
                        key={this.state.account?.id ?? 'NO_ACCT'}
                        mode={mode}
                        disabled={true}
                        user={this.user}
                        acs={this.state.propsUserACS}
                        account={this.state.account}
                        domainId={this.props.domainId}
                        singleDomain={this.props.singleDomain ?? false}
                        onChange={(acs) => {
                            this.props.onChange?.(acs.getDirtyRecords())
                        }}
                    />
                )}

                <UserAccessEditor
                    key={this.state.drawerKey}
                    visible={this.state.showDrawer}
                    onClose={this.handleDrawerClose}
                    onUpdated={this.props.onUpdated}
                    acs={this.state.propsUserACS}
                    mode={mode}
                    user={this.user}
                    account={this.state.account!}
                    domainId={this.props.domainId}
                    singleDomain={this.props.singleDomain ?? false}
                    onChange={(acs) => {
                        this.props.onChange?.(acs.getDirtyRecords())
                    }}
                />
            </Well>
        )
    }

    protected get isLoading(): boolean {
        return this.props.loading || this.state.loadingDependencies
    }

    protected get user(): UserDto {
        return this.props.user || new UserDto()
    }

    protected buildActions() {
        const { mode } = this.props
        const isNestedMode = mode === 'nested' || mode === 'add-user'

        return isNestedMode ? (
            ''
        ) : (
            <span key="edit">
                <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                    <EditOutlined />
                    <span>Edit</span>
                </Button>
            </span>
        )
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        await this.state.propsUserACS.revertUnsubmittedDomainRecords()
        return this.setState({ showDrawer: false })
    }

    protected async fetchDependencies(): Promise<void> {
        this.setState({ loadingDependencies: true })

        if (this.props.accountId) {
            this.setState({ account: { id: this.props.accountId } })
        }

        if (this.props.user) {
            await this.fetchPropsUserAbility()
        }

        this.setState({ loadingDependencies: false })
    }

    protected async fetchPropsUserAbility(): Promise<AppAbility> {
        let ability: AppAbility = new Ability()

        if (this.user.id) {
            ability = await this.state.propsUserACS.getAbilities()
        }

        this.setState({ propsUserAbility: ability })

        return ability
    }
}
