import * as React from 'react'
import './domain-ad-delivery-click-report.scss'
import * as moment from 'moment-timezone'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../../stores/app'
import { DomainService } from '../../../services'
import { DEFAULT_HIGHCHARTS_CONFIG } from '../../../constants'
import { Well } from '@pushly/aqe/lib/components'

const fmtStatDate = (date: string, timeBreakdown: string, isLifetimeBreakdown: boolean): string => {
    const dt = moment.tz(`${date}`, 'UTC')

    if (timeBreakdown === 'month' || isLifetimeBreakdown) {
        return dt.format('MMM')
    }

    return dt.format('MMM D')
}

interface IProps {
    stats: any[]
    isSingleDay: boolean
    isLifetime: boolean
    timeBreakdown: string
}

export class DomainAdDeliveryClickReport extends React.PureComponent<IProps> {
    private appState: AppState
    private domainService: DomainService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainService = Container.get(DomainService)
    }

    public render(): React.ReactNode {
        const { stats, timeBreakdown, isLifetime } = this.props
        if (!stats) {
            return
        }

        const xAxisData: string[] = stats.map((stat) => fmtStatDate(stat.report_date, timeBreakdown, isLifetime))
        const impressionData = stats.map((stat: any) => stat.impressions || 0)
        const clickData = stats.map((stat: any) => stat.paid_clicks || 0)
        const ctrData = stats.map((stat: any) => parseFloat((stat.paid_ctr || 0).toFixed(2)))
        const ctrMax = Math.max.apply(null, ctrData)

        const markerDefaults = {
            lineWidth: 2,
            fillColor: 'white',
        }

        const options = {
            ...DEFAULT_HIGHCHARTS_CONFIG,
            swContext: (): any => this.props,
            title: { text: '' },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: 300,
            },
            plotOptions: {
                column: {
                    groupPadding: 0.1275,
                },
            },
            xAxis: {
                categories: xAxisData,
                title: { enabled: false },
                showLegend: false,
                tickInterval: 1,
                lineWidth: 0,
                tickWidth: 0,
                margin: 0,
                minPadding: 0.065,
                maxPadding: 0.065,
            },
            yAxis: [
                {
                    type: 'logarithmic',
                    title: { text: '' },
                    allowDecimals: false,
                    startOnTick: 0,
                    minRange: 0,
                },
                {
                    title: { text: '' },
                    min: 0,
                    max: ctrMax,
                    maxPadding: 0.2,
                    allowDecimals: false,
                    labels: {
                        format: '{value}%',
                    },
                    opposite: true,
                },
            ],
            tooltip: {
                shared: true,
                formatter: HighchartsUtils.defaultTooltipFormatter,
            },
            series: [
                {
                    className: 'imps-col',
                    type: 'column',
                    name: 'Impressions',
                    data: impressionData,
                    color: '#5d676f',
                    tooltip: {
                        pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                    },
                },
                {
                    className: 'clicks-col',
                    type: 'column',
                    name: 'Paid Clicks',
                    data: clickData,
                    color: '#73cab6',
                    tooltip: {
                        pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                    },
                },
                {
                    className: 'ctr-line',
                    type: 'spline',
                    name: 'Paid CTR',
                    data: ctrData,
                    color: '#bde6dc',
                    yAxis: 1,
                    marker: {
                        ...markerDefaults,
                        lineColor: '#bde6dc',
                    },
                    tooltip: {
                        valueSuffix: '%',
                    },
                },
            ],
        }

        return (
            <Well className="domain-adc-report" title="Ad Delivery" mode="ghost" hideFooter={true}>
                <HighchartsReact highcharts={Highcharts} options={options} />
            </Well>
        )
    }
}
