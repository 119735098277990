import * as React from 'react'
import { AppService } from '../../../services/index'
import { Container } from 'typescript-ioc'
import { Drawer, executeCleanDrawerRender } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { SendIntegrationsEditor } from './send-integrations-editor'
import {
    DomainSendIntegrationModel,
    IDomainSendIntegrationModel,
} from '../../../models/send-integration-configurations/domain-send-integration.model'
import { DomainDto } from '../../../dtos/domain'
import { WebApnsConfigurationModel } from '../../../models/send-integration-configurations/web-apns-configuration.model'
import { Form } from 'antd'
import { DEFAULT_DRAWER_FORM_ITEM_LAYOUT } from '../../../constants'
import { simpleNotification } from '../../../_utils/utils'
import { FcmSendIntegrationApiVersion } from '../../../interfaces/send-integrations'
import { SendIntegrationPrivateKeyType } from '../../../enums/send-integration-private-key-type.enum'
import {
    NativeP12ApnsConfiguration,
    NativeP8ApnsConfiguration,
} from '../../../models/send-integration-configurations/native-apns-configurations.model'
import {
    NativeLegacyFcmConfiguration,
    NativeV1FcmConfiguration,
} from '../../../models/send-integration-configurations/native-fcm-configurations.model'
import { getIntegrationsValuesUpdate } from './helpers'

interface ISendIntegrationsEditorProps {
    visible: boolean
    onClose: () => void
    domain: DomainDto
    onSubmit: (value: any) => any
    submitDisabled?: (value: any) => boolean
    nativeFcmLegacy: boolean
}

interface ISendIntegrationsEditorState {
    tmpValue?: DomainSendIntegrationModel
}

export class SendIntegrationsDrawer extends React.Component<
    ISendIntegrationsEditorProps,
    ISendIntegrationsEditorState
> {
    protected appSvc: AppService

    public constructor(props: ISendIntegrationsEditorProps) {
        super(props)

        this.state = {
            tmpValue: DomainSendIntegrationModel.build(this.baseIntegrations),
        }

        this.appSvc = Container.get(AppService)

        executeCleanDrawerRender(this)
    }

    public render() {
        return (
            <Drawer
                getContainer={this.appSvc.getAppContainer}
                className={getClassNames('ddf-integrations-editor')}
                title="Update Integrations"
                onClose={this.props.onClose}
                placement="right"
                closable={true}
                visible={this.props.visible}
                disableSubmit={this.props.submitDisabled!(this.state.tmpValue)}
                onSubmit={this.handleSubmit}
            >
                <Form {...DEFAULT_DRAWER_FORM_ITEM_LAYOUT}>
                    <SendIntegrationsEditor
                        integrations={this.state.tmpValue!}
                        domain={this.props.domain}
                        loading={false}
                        onChange={this.handleFormChange}
                        className={getClassNames('ddf-integrations-editor')}
                        nativeFcmLegacy={this.props.nativeFcmLegacy}
                    />
                </Form>
            </Drawer>
        )
    }

    protected get baseIntegrations(): IDomainSendIntegrationModel {
        const { apnsConfiguration, nativeApnsConfiguration, nativeFcmConfiguration } = this.props.domain

        const nativeApnsType = nativeApnsConfiguration.privateKeyType
        const nativeApnsModel =
            nativeApnsType === SendIntegrationPrivateKeyType.P8 ? NativeP8ApnsConfiguration : NativeP12ApnsConfiguration

        const nativeFcmType = nativeFcmConfiguration.apiVersion
        const nativeFcmModel =
            nativeFcmType === FcmSendIntegrationApiVersion.LEGACY || this.props.nativeFcmLegacy ?
                NativeLegacyFcmConfiguration :
                NativeV1FcmConfiguration

        return {
            web_apns_configuration: WebApnsConfigurationModel.build(apnsConfiguration ?? {}),
            native_apns_configuration: nativeApnsModel.build(nativeApnsConfiguration ?? {}),
            native_fcm_configuration: nativeFcmModel.build(nativeFcmConfiguration ?? {}),
        }
    }

    protected get sendIntegrations(): DomainSendIntegrationModel {
        return this.state.tmpValue ?? DomainSendIntegrationModel.build(this.baseIntegrations ?? {})
    }

    protected handleFormChange = async (value: DomainSendIntegrationModel) => {
        await this.setState({ tmpValue: value })
    }

    protected validate(integrations: DomainSendIntegrationModel): { ok: boolean; error?: any } {
        const res: any = { ok: true, error: undefined }

        const setNotOk = (reason: string) => {
            res.ok = false
            res.error = reason
        }

        const pack = getIntegrationsValuesUpdate(this.props.domain, integrations)
        if (pack.webApnsConfiguration) {
            this.validateWebApnsConfig(integrations.getWebApnsConfiguration(), setNotOk)
        }
        if (pack.nativeApnsConfiguration) {
            this.validateNativeApnsConfig(integrations.getNativeApnsConfiguration(), setNotOk)
        }
        if (pack.nativeFcmConfiguration) {
            this.validateNativeFcmConfig(integrations.getNativeFcmConfiguration(), setNotOk)
        }

        return res
    }

    protected validateWebApnsConfig = (config: WebApnsConfigurationModel, throwCb: (reason: string) => void) => {
        if (config.getIsActive()) {
            const privateKey = config.getPrivateKey()
            if (!privateKey) {
                throwCb(`Web APNS Configuration is missing values. Missing certificate and pass phrase.`)
            }

            if (!privateKey.private_key_file_url) {
                throwCb(`Web APNS Configuration is missing values. Missing certificate.`)
            }

            const pushPackage = config.getPushPackage()

            if (!pushPackage.website_push_id) {
                throwCb(`Web APNS Configuration is missing values. Missing Website Push ID.`)
            }
        }
    }

    protected validateNativeApnsConfig = (
        config: NativeP8ApnsConfiguration | NativeP12ApnsConfiguration,
        throwCb: (reason: string) => void,
    ) => {
        const missingValueErrorMessage = (field: string) =>
            `Native APNS Configuration missing value: ${field} is required.`
        if (config.getIsActive()) {
            if (!config.getPrivateKeyFileUrl()) {
                throwCb(missingValueErrorMessage('Certificate'))
            }

            if (config instanceof NativeP12ApnsConfiguration) {
                if (!config.getAppBundleId()) {
                    throwCb(missingValueErrorMessage('App Bundle ID'))
                }
            }

            if (config instanceof NativeP8ApnsConfiguration) {
                if (!config.getTeamId()) {
                    throwCb(missingValueErrorMessage('Team ID'))
                }

                if (!config.getKeyId()) {
                    throwCb(missingValueErrorMessage('Key ID'))
                }
            }
        }
    }

    protected validateNativeFcmConfig = (
        config: NativeV1FcmConfiguration | NativeLegacyFcmConfiguration,
        throwCb: (reason: string) => void,
    ) => {
        const missingValueErrorMessage = (field: string) =>
            `Native FCM Configuration missing value: ${field} is required.`

        if (config.getIsActive()) {
            if (!config.getSenderId()) {
                throwCb(missingValueErrorMessage('Sender ID'))
            }

            if (config instanceof NativeV1FcmConfiguration) {
                if (!config.getServiceAccountJsonFileUrl()) {
                    throwCb(missingValueErrorMessage('Service Account JSON File'))
                }
            } else {
                if (!config.getServerApiKey()) {
                    throwCb(missingValueErrorMessage('Server API Key'))
                }
            }
        }
    }

    protected handleSubmit = async () => {
        const sendIntegrations = this.sendIntegrations

        const res = this.validate(sendIntegrations)
        if (!res.ok) {
            simpleNotification('error', res)
        } else {
            const submitRes = await this.props.onSubmit?.(sendIntegrations)
            if (submitRes.ok) {
                return this.props.onClose()
            }
        }
    }
}
