import React from 'react'
import TemplatizedTextField, { ITemplatizedTextField } from '../../../templatized-text-field/templatized-text-field'

interface ITemplateBodyField extends ITemplatizedTextField {}

const TemplateBodyField = (props: ITemplateBodyField) => {
    return (
        <TemplatizedTextField
            {...props}
            label="Body"
            limits={{
                max: 140,
                aboveMaxMessage: 'Body may be cut off on some devices.',
            }}
        />
    )
}

export default TemplateBodyField
