import * as React from 'react'
import { observe } from 'mobx'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Select } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { AppService } from '../../../services'
import { DomainDto } from '../../../dtos/domain'
import { PromptDto } from '../../../dtos/prompt'
import { BetterFormComponent } from '../../../components/better-component/better-component'
import { ColorPicker } from '../../../components/color-picker/color-picker'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import './bell-prompt-data-form.scss'
import { IBellPromptTheme, PromptBellConfigDto } from '../../../dtos/prompt-bell-config-dto'
import { PromptStyle } from '../prompt-editor/prompt-editor'
import { ISlidePromptTheme } from '../../../dtos/prompt-slide-config-dto'

interface IProps extends FormComponentProps {
    onFieldChange?: any
    prompt: PromptDto & { config: PromptBellConfigDto }
}

interface IState {
    domain?: DomainDto
}

@observer
export class BellPromptDataForm extends BetterFormComponent<IProps, IState> {
    public state: IState = {}

    private appState: AppState
    private appService: AppService
    private disposeObservers: any[]

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount(): void {
        this.disposeObservers = [observe(this.appState, 'currentDomainJsonData', () => this.setDomainState())]

        this.setDomainState()
    }

    public componentWillUnmount(): void {
        this.unmounting = true
        this.disposeObservers.forEach((f: any) => f())
    }

    public render(): React.ReactNode {
        return this.renderStyleEditorForm()
    }

    private renderStyleEditorForm(): React.ReactNode {
        const { getFieldDecorator } = this.props.form
        const { prompt } = this.props

        const defaultPosition = 'bottom-left'
        let defaultDesktopPosition = defaultPosition
        let defaultMobilePosition = defaultPosition

        const defaultSize = 45
        let defaultDesktopSize = defaultSize
        let defaultMobileSize = defaultSize

        let defaultPrimaryColor = '#0077A0'
        let defaultAccentColor = '#FFFFFF'

        if (prompt) {
            if (prompt.style === PromptStyle.BELL) {
                defaultDesktopPosition = this.promptTheme.position.desktop
                defaultMobilePosition = this.promptTheme.position.mobile
                defaultDesktopSize = this.promptTheme.desktopSize || this.promptTheme.size
                defaultMobileSize = this.promptTheme.mobileSize || this.promptTheme.size
                defaultPrimaryColor = this.promptTheme.primaryColor
                defaultAccentColor = this.promptTheme.accentColor
            } else if (prompt.style === PromptStyle.SLIDE) {
                const theme = this.promptTheme as any as ISlidePromptTheme
                defaultPrimaryColor = theme.allowButton.backgroundColor
                defaultAccentColor = theme.allowButton.textColor
            }
        }

        return (
            <Well title="Theme" className="customizations-well" showFooter={false} ghost={true}>
                <Form.Item label="Desktop Position">
                    {getFieldDecorator('desktopPosition_bell', {
                        initialValue: defaultDesktopPosition,
                    })(
                        <Select size="large" placeholder="Select a position" onChange={this.props.onFieldChange}>
                            <Select.Option value="bottom-left">Lower Left</Select.Option>
                            <Select.Option value="bottom-right">Lower Right</Select.Option>
                            <Select.Option value="top-left">Upper Left</Select.Option>
                            <Select.Option value="top-right">Upper Right</Select.Option>
                        </Select>,
                    )}
                </Form.Item>
                <Form.Item label="Mobile Position">
                    {getFieldDecorator('mobilePosition_bell', {
                        initialValue: defaultMobilePosition,
                    })(
                        <Select size="large" placeholder="Select a position" onChange={this.props.onFieldChange}>
                            <Select.Option value="bottom-left">Lower Left</Select.Option>
                            <Select.Option value="bottom-right">Lower Right</Select.Option>
                            <Select.Option value="top-left">Upper Left</Select.Option>
                            <Select.Option value="top-right">Upper Right</Select.Option>
                        </Select>,
                    )}
                </Form.Item>

                <Form.Item label="Pre-subscription Tooltip">
                    {getFieldDecorator('unsubscribedTooltip', {
                        initialValue: !this.props.prompt ? undefined : (this.promptTheme.tooltip || {}).unsubscribed,
                        rules: [
                            {
                                required:
                                    prompt.config.behavior.displayBehavior === 'ALWAYS' ||
                                    prompt.config.behavior.displayBehavior === 'PRE_SUBSCRIPTION',
                                message: 'Pre-subscription Tooltip is required for the current display behavior',
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            placeholder="Sign up for notifications!"
                            onChange={this.props.onFieldChange}
                        />,
                    )}
                </Form.Item>
                <Form.Item label="Post-subscription Tooltip">
                    {getFieldDecorator('subscribedTooltip', {
                        initialValue: !this.props.prompt ? undefined : (this.promptTheme.tooltip || {}).subscribed,
                        rules: [
                            {
                                required:
                                    prompt.config.behavior.displayBehavior === 'ALWAYS' ||
                                    prompt.config.behavior.displayBehavior === 'POST_SUBSCRIPTION',
                                message: 'Post-subscription Tooltip is required for the current display behavior',
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            placeholder="Thank you for signing up!"
                            onChange={this.props.onFieldChange}
                        />,
                    )}
                </Form.Item>

                <div className="customization-lower">
                    <div>
                        <Form.Item label="Desktop Size" className="prompt-size">
                            {getFieldDecorator('size', {
                                initialValue: defaultDesktopSize,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Desktop Prompt size is required',
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder="30"
                                    onChange={this.props.onFieldChange}
                                    addonAfter="px"
                                />,
                            )}
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Mobile Size" className="prompt-size">
                            {getFieldDecorator('mobileSize', {
                                initialValue: defaultMobileSize,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Mobile Prompt size is required',
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder="30"
                                    onChange={this.props.onFieldChange}
                                    addonAfter="px"
                                />,
                            )}
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Primary Color">
                            {getFieldDecorator('primaryColor', {
                                initialValue: defaultPrimaryColor,
                            })(
                                <ColorPicker.Button
                                    className="prompt-primary-picker"
                                    onChange={this.props.onFieldChange}
                                />,
                            )}
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item label="Accent Color">
                            {getFieldDecorator('accentColor', {
                                initialValue: defaultAccentColor,
                            })(
                                <ColorPicker.Button
                                    className="prompt-accent-picker"
                                    onChange={this.props.onFieldChange}
                                />,
                            )}
                        </Form.Item>
                    </div>
                </div>
            </Well>
        )
    }

    private get promptTheme(): IBellPromptTheme {
        return this.props.prompt
            ? ((this.props.prompt.config as PromptBellConfigDto) || {}).theme || {}
            : ({} as IBellPromptTheme)
    }

    private setDomainState(): void {
        this.setState({ domain: this.appState.currentDomain })
    }
}
