import { SRSBuilderMode } from './saved-reports-schedule/enums'
import { SavedReportScheduleModel } from '../../../models/saved-reports/saved-report-schedule.model'
import { createContext } from 'react'

interface ISavedReportScheduleContext {
    mode: SRSBuilderMode
    loading: boolean
    savedReport: any // SavedReportDto,
    savedReportSchedule: SavedReportScheduleModel
    setSavedReportSchedule: (changes: SavedReportScheduleModel, debounce?: boolean) => any
}

export const SRScheduleContext = createContext<ISavedReportScheduleContext>({} as ISavedReportScheduleContext)
