import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

function getCleanIdArray(arr: number[]) {
    arr = arr.filter((v) => v !== null && v !== undefined).map((v) => parseInt(v.toString(), 10))

    return Array.from(new Set(arr))
}

export class AudienceSelectionModel {
    public static build(data: AudienceSelectionModel | any): AudienceSelectionModel {
        const scData = convertCase(clone(data), 'snake')
        const model = new AudienceSelectionModel()

        model.setAllSubscribers(data.all_subscribers)
        model.setSegmentIds(scData.segment_ids)
        model.setExcludedSegmentIds(scData.excluded_segment_ids)
        model.setDefaultIconUrl(scData.default_icon_url)

        return model
    }

    private all_subscribers: boolean
    private segment_ids?: number[]
    private excluded_segment_ids?: number[]
    private default_icon_url?: string

    public clone(): AudienceSelectionModel {
        return AudienceSelectionModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            all_subscribers: this.getAllSubscribers(),
            segment_ids: this.getSegmentIds(),
            excluded_segment_ids: this.getExcludedSegmentIds(),
            default_icon_url: this.getDefaultIconUrl(),
        })
    }

    public setAllSubscribers(allSubscribers: boolean): void {
        this.all_subscribers = allSubscribers

        if (allSubscribers) {
            this.setSegmentIds(undefined)
            this.setExcludedSegmentIds(undefined)
        }
    }
    public getAllSubscribers(): boolean {
        let allSubs = this.all_subscribers ?? false
        if (!allSubs) {
            allSubs = !this.segment_ids && !this.excluded_segment_ids
        }

        return allSubs
    }

    public setSegmentIds(segmentIds: number[] | undefined): void {
        this.segment_ids = segmentIds === undefined ? undefined : getCleanIdArray(segmentIds)

        if (segmentIds !== undefined) {
            this.setAllSubscribers(false)
        }
    }
    public getSegmentIds(): number[] | undefined {
        return this.segment_ids
    }
    public addSegmentId(segmentId: number) {
        if (!this.segment_ids || !this.segment_ids.includes(segmentId)) {
            this.setSegmentIds([...(this.segment_ids ?? []), segmentId])
        }
    }

    public setExcludedSegmentIds(segmentIds: number[] | undefined): void {
        this.excluded_segment_ids = segmentIds === undefined ? undefined : getCleanIdArray(segmentIds)

        if (segmentIds !== undefined) {
            this.setAllSubscribers(false)
        }
    }
    public getExcludedSegmentIds(): number[] | undefined {
        return this.excluded_segment_ids
    }
    public addExcludedSegmentId(segmentId: number) {
        if (!this.excluded_segment_ids || !this.excluded_segment_ids.includes(segmentId)) {
            this.setExcludedSegmentIds([...(this.excluded_segment_ids ?? []), segmentId])
        }
    }

    public getDefaultIconUrl(): string | undefined {
        return this.default_icon_url
    }
    public setDefaultIconUrl(url: string | null | undefined): void {
        this.default_icon_url = url ?? undefined
    }
}
