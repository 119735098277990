import * as React from 'react'
import * as changeCase from 'change-case'
import { Cell as FDCell } from 'fixed-data-table-2'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import moment from 'moment-timezone'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const MONTHLY_UNIQUES_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        selectable: false,
        className: 'r-record-date',
        width: 140,
        columnKey: 'report_month',
        title: 'Month',
        align: 'center',
        sortable: true,
        render(data: any) {
            return moment(data.report_month).format('MMM, YYYY')
        },
    },
    ['PLACEMENT']: {
        className: 'r-title',
        width: 140,
        columnKey: 'placement',
        title: 'Device Type',
        sortable: true,
        render(data: any) {
            return changeCase.title(data.placement)
        },
    },
    ['CHANNEL']: {
        align: 'left',
        selectable: false,
        classname: 'r-channel',
        width: 120,
        columnKey: 'ch',
        title: 'Delivery Channel',
        sortable: false,
        render: (data: any) => DeliveryChannel.getShortName(data.channel) ?? 'Unknown',
    },
    ['IMPRESSIONS']: {
        className: 'r-impressions',
        width: 120,
        columnKey: 'impressions',
        title: 'Impressions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.impressions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CLICKS']: {
        className: 'r-clicks',
        width: 120,
        columnKey: 'clicks',
        title: 'Clicks',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.clicks)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['CTR_DECIMAL']: {
        selectable: false,
        className: 'r-ctr',
        width: 120,
        columnKey: 'ctr_decimal',
        requiredColumnKeys: ['clicks', 'impressions'],
        title: 'CTR',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.impressions ? '--' : numberWithPercent(data.ctr_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggImps = dataSet.map((s) => s.impressions).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggClicks = dataSet.map((s) => s.clicks).reduce((n: number, v: number) => n + (v || 0), 0)

            const avgCTR = !aggImps ? '--' : numberWithPercent(aggClicks / aggImps)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{avgCTR}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['PERMISSIONS_SHOWN']: {
        className: 'r-permissions-shown',
        width: 120,
        columnKey: 'permissions_shown',
        title: (
            <>
                Permissions
                <br />
                Shown
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.permissions_shown)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.permissions_shown).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['SUBSCRIPTIONS']: {
        className: 'r-subscriptions',
        width: 120,
        columnKey: 'subscriptions',
        title: 'Subscriptions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.subscriptions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.subscriptions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['SUBSCRIPTION_RATE_DECIMAL']: {
        className: 'r-subscription-rate',
        width: 120,
        columnKey: 'subscription_rate_decimal',
        requiredColumnKeys: ['permissions_shown', 'subscriptions'],
        title: (
            <>
                Subscription
                <br />
                Rate
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.permissions_shown ? '--' : numberWithPercent(data.subscription_rate_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggShown = dataSet.map((s) => s.permissions_shown).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggSubs = dataSet.map((s) => s.subscriptions).reduce((n: number, v: number) => n + (v || 0), 0)

            const data = !aggShown ? '--' : numberWithPercent(aggSubs / aggShown)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{data}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
}
