import * as React from 'react'
import { getClassNames } from '../../../_utils/classnames'
import { ICampaignWizard } from '../interfaces'
import { CampaignBuilder } from '../../campaign-builder/campaign-builder'
import { simpleNotification } from '../../../_utils/utils'
import { NodeType } from '../../campaign-builder/enums'

interface ICampaignBuilderTab extends ICampaignWizard {
    activeTab?: boolean
    disableRouterPrompt?: boolean
}

interface IState {
    tmpValue?: any
}

export class CampaignBuilderTab extends React.Component<ICampaignBuilderTab, IState> {
    public static tabName = 'builder'
    public static tabLabel = 'Visual Builder'

    public state: IState = {}

    public componentDidUpdate(prevProps: Readonly<ICampaignBuilderTab>) {
        if (!!prevProps.campaign !== !!this.props.campaign) {
            this.setState({ tmpValue: this.props.campaign })
        }
    }

    public render() {
        return (
            <div className={getClassNames('cwzd-builder')}>
                <CampaignBuilder
                    key={this.props.activeTab ? 'visible' : 'hidden'}
                    loading={this.props.loading}
                    mode={this.props.mode}
                    domain={this.props.domain}
                    campaign={this.currentCampaignState}
                    showAmplyExperience={this.props.showAmplyExperience}
                    onChange={this.handleUpdate}
                    onSubmit={this.handleSubmit}
                    disableRouterPrompt={this.props.disableRouterPrompt}
                />
            </div>
        )
    }

    protected get currentCampaignState() {
        return this.state.tmpValue ?? this.props.campaign
    }

    protected validate(campaign: any): { ok: boolean; error?: any } {
        const res: any = { ok: true, error: undefined }

        const setNotOk = (reason: string) => {
            res.ok = false
            res.error = reason
        }

        const steps = campaign.steps ?? []
        const trigger = steps.find((s) => s.type === NodeType.TRIGGER)
        const actions = steps.filter((s) => s.type === NodeType.ACTION)

        if (!trigger || !trigger.configuration?.type || !trigger.configuration?.params) {
            setNotOk('Campaign entry conditions have not been set.')
        } else if (actions.length === 0) {
            setNotOk('At least one campaign action is required.')
        }

        return res
    }

    protected handleUpdate = (campaign: any) => {
        this.setState({ tmpValue: campaign })
    }

    protected handleSubmit = async (campaign: any, remove?: boolean) => {
        const res = this.validate(campaign)

        if (!res.ok) {
            simpleNotification('error', res)
        } else {
            const submitRes = this.props.onSubmit?.(campaign, remove)
            if (submitRes.ok) {
                await this.setState({ tmpValue: submitRes.data })
            }

            return submitRes
        }

        return res
    }
}
