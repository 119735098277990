import * as querystring from 'query-string'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { axiosFetch } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import IApiCallOptions from '../interfaces/api-call-options'
import { DomainDto } from '../dtos/domain'
import aqe from '@pushly/aqe'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { ApiCallOptionsWithCustomErrorHandler } from '../types/api-call-options-with-custom-error-handler'

@Singleton
export class CampaignV2Service {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    protected get domain(): DomainDto {
        return this.appState.currentDomain!
    }

    public async fetchNextOccurrencesByQuery(query: any, opts?: IApiCallOptions): Promise<IServiceApiResponse<any[]>> {
        let ok = false
        let cancelled = false
        let occurrences: any[] = []
        let meta: any = {}

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const options = querystring.stringify(opts?.query ?? {})
            const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${this.domain.id}/campaigns/query-next-occurrences?${options}`

            const res = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: query,
                    config: {
                        cancelToken: opts?.cancellationToken,
                    },
                },
                opts?.cancellationKey,
            )
            const { data, ...metaData } = res.data

            ok = true
            occurrences = data
            meta = metaData
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
                fallbackMessage: 'An error has occurred',
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, meta, data: occurrences }
    }

    public async fetchAll(opts?: IApiCallOptions): Promise<IServiceApiResponse<any[]>> {
        let ok = false
        let cancelled = false
        let campaigns: any[] = []
        let meta: any = {}

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts?.query ?? {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/v3/domains/${this.domain.id}/campaigns?${options}`

        try {
            const res = await axiosFetch('get', { url: serviceURL }, opts?.cancellationKey)
            const { data, ...metaData } = res.data

            ok = true
            campaigns = data
            meta = metaData
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
                fallbackMessage: 'An error has occurred',
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, meta, data: campaigns }
    }

    public async fetchById(
        campaignId: number,
        opts?: ApiCallOptionsWithCustomErrorHandler,
    ): Promise<IServiceApiResponse<any | undefined>> {
        let ok = false
        let cancelled = false
        let campaign: any

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts?.query ?? {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${this.domain.id}/campaigns/${campaignId}?${options}`

        try {
            const res = await axiosFetch('get', { url: serviceURL }, opts?.cancellationKey)
            const { data } = res.data

            ok = true
            campaign = data
        } catch (error) {
            const defaultHandler = () =>
                handleResponseErrorMessage(error, {
                    onCancelled: () => (cancelled = true),
                    fallbackMessage: 'An error has occurred',
                })

            if (opts?.errorHandler) {
                opts?.errorHandler(error, defaultHandler)
            } else {
                defaultHandler()
            }
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, data: campaign }
    }

    public async create(campaignDto: any, opts?: IApiCallOptions): Promise<IServiceApiResponse<any | undefined>> {
        let ok = false
        let cancelled = false
        let campaign: any

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${this.domain.id}/campaigns`

        try {
            const res = await axiosFetch(
                'post',
                {
                    url: serviceURL,
                    data: campaignDto,
                },
                opts?.cancellationKey,
            )
            const { data } = res.data

            ok = true
            campaign = data
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
                fallbackMessage: 'An error has occurred',
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, data: campaign }
    }

    public async patch(
        campaignId: number,
        campaignDto: any,
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<any | undefined>> {
        let ok = false
        let cancelled = false
        let campaign: any

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${this.domain.id}/campaigns/${campaignId}`

        try {
            const res = await axiosFetch(
                'patch',
                {
                    url: serviceURL,
                    data: campaignDto,
                },
                opts?.cancellationKey,
            )
            const { data } = res.data

            ok = true
            campaign = data
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
                fallbackMessage: 'An error has occurred',
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, data: campaign }
    }

    public async destroy(campaignId: number, opts?: IApiCallOptions): Promise<IServiceApiResponse<void>> {
        let ok = false
        let cancelled = false

        if (opts?.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const serviceURL = `${aqe.defaults.publicApiDomain}/domains/${this.domain.id}/campaigns/${campaignId}`

        try {
            await axiosFetch(
                'delete',
                {
                    url: serviceURL,
                },
                opts?.cancellationKey,
            )

            ok = true
        } catch (error) {
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
                fallbackMessage: 'An error has occurred',
            })
        }

        if (opts?.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, cancelled, data: undefined }
    }
}
