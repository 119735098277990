import React from 'react'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { fetchDomain } from '../../_utils/utils'
import { DomainDto } from '../../dtos/domain'
import { Domain } from '@pushly/models/lib/structs/domain'

export interface IDomainSettingsTab {
    domainId: number
}

export interface IDomainSettingsTabState {
    loading: boolean
    domain?: DomainDto
}

class DomainSettingsTab<
    PropsType extends IDomainSettingsTab,
    StateType extends IDomainSettingsTabState,
> extends React.Component<PropsType, StateType> {
    protected readonly appState: AppState
    protected readonly appService: AppService

    protected unmounting = false
    private loadAttempts = 0

    public constructor(props: PropsType) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {
            loading: true,
        } as StateType
    }

    public componentDidMount() {
        this.fetchRequestedDomain()
    }

    public componentWillUnmount() {
        this.unmounting = true
    }

    public render(): any {
        return null
    }

    protected async fetchRequestedDomain(): Promise<void> {
        let domain: DomainDto | undefined

        if (!this.unmounting) {
            this.setState({ loading: true })

            if (!this.props.domainId && this.loadAttempts++ <= 10) {
                setTimeout(() => this.fetchRequestedDomain(), 10)
                return
            }

            try {
                domain = await fetchDomain(this.props.domainId)
            } catch (_) {}

            if (!this.unmounting) {
                this.setState({ loading: false, domain })
            }
        }
    }

    protected handleDomainUpdated = async (updates: Partial<DomainDto | Domain>) => {
        if (!this.unmounting) {
            if (updates instanceof DomainDto) {
                try {
                    updates = DomainDto.fromApiResponse(updates)
                } catch (_) {}
            }

            this.setState(({ domain }) => ({
                domain: {
                    ...domain,
                    ...updates,
                } as DomainDto,
            }))
        }
    }
}

export default DomainSettingsTab
