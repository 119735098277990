import * as React from 'react'

export const IconAb = () => (
    <svg viewBox="0 0 2048 1024" data-icon="ab" height="1em" width="2em" fill="currentColor" aria-hidden="true">
        <path
            d="M525.394,196.891c-3.675-9.005-9.599-16.28-17.529-21.568c-7.733-5.371-16.566-8.094-26.253-8.094
				c-9.696,0-18.529,2.724-26.176,8.041c-8.019,5.348-13.943,12.624-17.616,21.644l-239.436,594.99
				c-4.668,11.991-4.502,24.15,0.494,36.139c5.023,12.057,13.689,20.549,25.755,25.24c11.951,4.646,24.201,4.478,36.137-0.495
				c12.047-5.021,20.539-13.59,25.216-25.604L359.43,645h244.363l73.45,182.201c3.661,8.994,9.571,16.212,17.567,21.55
				c7.707,5.354,16.527,8.045,26.216,8.045c6.037,0,11.826-1.199,17.209-3.539c11.97-4.682,20.739-13.13,26.062-25.101l0.047-0.111
				c5.011-12.024,5.011-24.221,0.024-36.189L525.394,196.891z M566.021,551H397.202l84.41-210.196L566.021,551z"
        />
        <path
            d="M1695.352,475.802c34.576-34.851,52.096-77.448,52.096-126.712
				c0-50.042-17.905-93.438-53.218-128.758C1658.91,185.019,1615.78,167,1566.037,167h-178.212
				c-13.021,0-24.117,4.847-33.448,14.179c-9.034,9.034-13.377,20.332-13.377,33.352v595.387c0,13.021,4.343,24.318,13.44,33.415
				c9.318,8.993,20.393,13.667,33.385,13.667h184.512c57.804,0,107.895-20.74,148.895-61.434
				C1762.218,754.58,1783,704.428,1783,646.615C1783,574.92,1753.521,517.446,1695.352,475.802z M1435,436V262h131.037
				c23.722,0,44.497,8.519,61.718,25.454c16.945,16.946,25.538,37.67,25.538,61.677c0,23.729-8.602,44.31-25.509,61.496
				c-17.25,16.965-38.024,25.373-61.747,25.373H1435z M1654.758,728.721C1632.14,751.339,1604.409,763,1572.337,763H1435V531
				h137.337c32.065,0,59.797,11.22,82.42,33.838c22.619,22.624,34.088,50.174,34.088,81.941
				C1688.845,678.554,1677.376,706.104,1654.758,728.721z"
        />
        <path
            d="M1019,22c-22.607,0-41,17.753-41,39.574v900.852c0,21.821,18.393,39.574,41,39.574
				s41-17.753,41-39.574V61.574C1060,39.753,1041.607,22,1019,22z"
        />
    </svg>
)
