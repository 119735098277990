import * as React from 'react'
import { KeywordManagerState } from './interfaces'

export type KeywordManagerContext = KeywordManagerState & {
    onFilterChange<
        FilterKey extends keyof KeywordManagerState['filters'],
        FilterValue extends KeywordManagerState['filters'][FilterKey],
    >(
        key: FilterKey,
        value: FilterValue,
    ): Promise<void>
    onSearchClick(value: string): void
    onExportClick(): void
    onAddKeywordsClick(): void
    onHandleBulkMenuClick(e: any): void
}

export const KeywordManagerContext = React.createContext<KeywordManagerContext>({} as KeywordManagerContext)
