import * as React from 'react'
import clone from 'clone'
import { BetterComponent } from '../../../components/better-component/better-component'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps, IFilterBuilderState } from '../interfaces/filter-builder'
import * as deepEqual from 'react-fast-compare'
import { getClassNames } from '../../../_utils/classnames'

export class NotificationSourceFilterBuilder extends BetterComponent<IFilterBuilderProps, IFilterBuilderState> {
    public state: IFilterBuilderState = {
        operator: 'IN',
        value: undefined,
    }

    protected readonly defaultSelection = ['CAMPAIGN', 'FEED', 'MANUAL']

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 'nsource-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                            <span> </span>
                            <span className="filter-val">{this.currentValueDisplay}</span>
                        </span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'IN', label: 'is' },
                                    { value: 'NOT_IN', label: 'is not' },
                                ]}
                                defaultValue={['EQUALS']}
                                value={this.currentValue.operator}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                        <div className="filter-val">
                            <BetterSelect
                                size="small"
                                mode="multiple"
                                dropdownClassName="filter-val-dropdown"
                                placeholder="Select..."
                                disableSearch={true}
                                selectAllLabel="Any Source"
                                options={[
                                    { value: 'CAMPAIGN', label: 'Campaign' },
                                    { value: 'FEED', label: 'Feed' },
                                    { value: 'MANUAL', label: 'Manual' },
                                ]}
                                value={this.currentValue.value || this.defaultSelection}
                                onChange={this.handleValueChange}
                                onClose={this.handleValueChangeComplete}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}
        return {
            field: 'NOTIFICATION_SOURCE',
            operator: propsValue.operator || 'IN',
            value: this.state.value || propsValue.value,
        }
    }

    protected get currentOperatorDisplay(): string {
        return this.currentValue.operator === 'IN' ? 'is' : 'is not'
    }

    protected get currentValueDisplay(): string {
        let display: string = ''

        if (!this.currentValue.value || this.currentValue.value.length === 3) {
            display = 'any source'
        } else {
            const currentValue = clone(this.currentValue.value || []).map((v) => v.toString().toLowerCase())

            if (currentValue.length <= 2) {
                display = currentValue.join(' or ')
            } else {
                const lastValue = currentValue.pop()
                display = currentValue.join(', ') + `, or ${lastValue}`
            }
        }

        return display
    }

    protected handleValueChange = async (value: any) => {
        if (Array.isArray(value) && value.length === 0) {
            value = undefined
        }

        await this.setState({ value })

        return value
    }

    protected handleValueChangeComplete = async (value: any) => {
        value = await this.handleValueChange(value)

        if (!deepEqual(value, (this.props.value || {}).value)) {
            this.handleChange({ value })
        }
    }

    protected handleChange(config: any): any {
        if (!!config) {
            this.props.onChange({
                field: 'NOTIFICATION_SOURCE',
                operator: this.currentValue.operator,
                ...this.props.value,
                ...config,
            })
        } else {
            this.props.onChange()
        }

        this.setState({ value: undefined })
    }
}
