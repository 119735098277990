import * as React from 'react'
import moment from 'moment-timezone'
import { DEFAULT_HIGHCHARTS_CONFIG } from '../../../constants'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import { Well } from '@pushly/aqe/lib/components'

interface ISPGProps {
    stats: any
    contract?: any
    loading?: boolean
}

export const SegmentPerformanceGraph = ({ stats, contract, ...props }: ISPGProps) => {
    if (!stats) {
        stats = []
    }

    const xAxisData = stats.map((stat) => moment.tz(stat.send_date, 'UTC').format('MMM D'))
    const impressionData = stats.map((stat: any) => stat.impressions ?? 0)
    const clickData = stats.map((stat: any) => stat.clicks ?? 0)
    const ctrData = stats.map((stat: any) => (stat.ctr_decimal ?? 0) * 100)
    const ctrMax = Math.max.apply(null, ctrData)

    const markerDefaults = {
        lineWidth: 2,
        fillColor: 'white',
    }

    const options = {
        ...DEFAULT_HIGHCHARTS_CONFIG,
        swContext: (): any => props,
        title: { text: '' },
        chart: {
            type: 'column',
            backgroundColor: 'transparent',
            height: 300,
        },
        plotOptions: {
            column: {
                groupPadding: 0.1275,
            },
        },
        xAxis: {
            categories: xAxisData,
            title: { enabled: false },
            showLegend: false,
            tickInterval: 1,
            lineWidth: 0,
            tickWidth: 0,
            margin: 0,
            minPadding: 0.065,
            maxPadding: 0.065,
        },
        yAxis: [
            {
                type: 'logarithmic',
                title: { text: '' },
                allowDecimals: false,
                startOnTick: 0,
                minRange: 0,
            },
            {
                title: { text: '' },
                min: 0,
                max: ctrMax,
                maxPadding: 0.2,
                allowDecimals: false,
                labels: {
                    format: '{value}%',
                },
                opposite: true,
            },
        ],
        tooltip: {
            shared: true,
            formatter: HighchartsUtils.defaultTooltipFormatter,
        },
        series: [
            {
                className: 'imps-col',
                type: 'column',
                name: 'Impressions',
                data: impressionData,
                color: '#5d676f',
                tooltip: {
                    pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                },
            },
            {
                className: 'clicks-col',
                type: 'column',
                name: 'Clicks',
                data: clickData,
                color: '#8e58da',
                tooltip: {
                    pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                },
            },
            {
                className: 'ctr-line',
                type: 'spline',
                name: 'CTR',
                data: ctrData,
                color: '#c7abed',
                yAxis: 1,
                marker: {
                    ...markerDefaults,
                    lineColor: '#c7abed',
                },
                tooltip: {
                    pointFormatter(this: any) {
                        return `
<br/>
<span style="color: ${this.color}">●</span>
${this.series.name}: <b>${this.y === 0 ? 0 : this.y.toFixed(2)}%</b>
`.trim()
                    },
                },
            },
        ],
    }

    return (
        <Well
            className="thin performance-stats-well"
            title="Performance"
            hideFooter={true}
            mode="ghost"
            loading={props.loading}
        >
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Well>
    )
}
