import { IChart, ILink, INode } from '@mrblenny/react-flow-chart'
import { CampaignEditableState, NodeType, PortId, PortType } from '../enums'
import { generateUID } from './uid'
import clone from 'clone-deep'
import { CONDITION_BRANCH_ROOT } from '../constants'
import * as moment from 'moment-timezone'
import { StatusType } from '../../../enums/status-type'

export const getEditableState = (campaign: any): CampaignEditableState => {
    let state: CampaignEditableState = CampaignEditableState.EDITABLE

    if (campaign?.id && !isNaN(campaign.id) && campaign.statusId !== StatusType.DRAFT.id) {
        const now = moment.utc()
        const dateStartValue = campaign.dateStartUtc || campaign.dateStart
        const dateStart = moment.tz(dateStartValue, 'utc')
        const dateEndValue = campaign.dateEndUtc || campaign.dateEnd
        const dateEnd = !dateEndValue ? null : moment.tz(dateEndValue, 'utc')

        const isCompleted =
            campaign.statusId === StatusType.COMPLETED.id ||
            campaign.dateCompletedUtc ||
            campaign.manuallyCompleted ||
            (dateEnd && dateEnd <= now)

        if (isCompleted) {
            state = CampaignEditableState.COMPLETED
        } else if (dateStart <= now) {
            state = CampaignEditableState.RUNNING
        }
    }

    return state
}

export const getCalculatedStatus = (campaign: any, format?: (status: string, campaign) => string): string => {
    let computedStatus: string = StatusType.SCHEDULED.name

    const now = moment.utc()
    const dateStartValue = campaign.dateStartUtc || campaign.dateStart
    const dateStart = moment.tz(dateStartValue, 'utc')
    const dateEndValue = campaign.dateEndUtc || campaign.dateEnd
    const dateEnd = !dateEndValue ? null : moment.tz(dateEndValue, 'utc')

    const editableState = getEditableState(campaign)
    const editableStateIsCompleted = editableState === CampaignEditableState.COMPLETED

    const statusIsDraft = campaign.status === StatusType.DRAFT.name
    const statusIsCancelled =
        campaign.status === StatusType.CANCELLED.name || campaign.status === StatusType.CANCELLING.name
    const statusIsCompleted =
        campaign.isCompleted() || editableStateIsCompleted || (statusIsDraft && dateEnd && dateEnd <= now)

    if (statusIsCompleted) {
        computedStatus = StatusType.COMPLETED.name
    } else if (statusIsCancelled) {
        computedStatus = StatusType.CANCELLED.name
    } else if (statusIsDraft) {
        computedStatus = StatusType.DRAFT.name
    } else if (dateStart <= now) {
        computedStatus = StatusType.ACTIVE.name
    }

    return !format ? computedStatus : format(computedStatus, campaign)
}

export const generateDefaultSubscriberFcap = () => ({
    criteria: {
        frequency: 1,
        window: 'days',
    },
    type: 'once_per_criteria',
})

export const generateEmptyCampaign = (): IChart => {
    const entryId = generateUID()
    const exitId = generateUID()
    const linkId = generateUID()

    const initialLink = {
        id: linkId,
        from: {
            nodeId: entryId,
            portId: PortId.OUTPUT,
        },
        to: {
            nodeId: exitId,
            portId: PortId.INPUT,
        },
    }

    return {
        offset: {
            x: 0,
            y: 0,
        },
        scale: 1,
        nodes: {
            [entryId]: {
                id: entryId,
                type: NodeType.TRIGGER,
                properties: {
                    [NodeType.TRIGGER]: {},
                },
                ports: {
                    [PortId.OUTPUT]: {
                        id: PortId.OUTPUT,
                        type: PortType.OUTPUT,
                    },
                },
                position: {} as any,
            },
            [exitId]: {
                id: exitId,
                type: NodeType.CAMPAIGN_EXIT,
                properties: {
                    [NodeType.CAMPAIGN_EXIT]: {
                        params: {
                            exit_state: 'completed',
                        },
                    },
                },
                ports: {
                    [PortId.INPUT]: {
                        id: PortId.INPUT,
                        type: PortType.INPUT,
                    },
                },
                position: {} as any,
            },
        },
        links: {
            [initialLink.id]: initialLink,
        },
        selected: {},
        hovered: {},
    }
}

export const parseChartFromCampaign = (campaign: any): IChart => {
    const chart: IChart = {
        offset: { x: 0, y: 0 },
        scale: 1,
        selected: {},
        hovered: {},
        nodes: {},
        links: {},
    }

    const campaignSteps = campaign.steps ?? campaign.revision?.steps
    if (Array.isArray(campaignSteps) && campaignSteps.length > 0) {
        for (const step of campaignSteps) {
            if (step.type === NodeType.GLOBAL_TRIGGER) {
                continue
            }

            if (step.destroyedAt !== null) {
                continue
            }

            const node: INode = {
                id: step.id,
                type: step.type,
                properties: {
                    [step.type]: {
                        ...step.configuration,
                    },
                },
                ports: {},
                position: {} as any,
            }

            const inSteps = campaignSteps.filter((s: any) => {
                if (s.type === NodeType.GLOBAL_TRIGGER) {
                    return false
                }

                if (s.destroyedAt !== null) {
                    return false
                }

                let match

                if (s.type !== NodeType.CONDITION) {
                    match = s.configuration?.out_step_id === step.id
                } else {
                    match = s.configuration?.[CONDITION_BRANCH_ROOT]?.some((c: any) => c.out_step_id === step.id)
                }

                return !!match
            })

            if (inSteps.length > 0) {
                node.ports[PortId.INPUT] = {
                    id: PortId.INPUT,
                    type: PortType.INPUT,
                }

                inSteps.forEach((inStep, idx) => {
                    const link: ILink = {
                        id: `${node.id}_link_${idx}`,
                        from: {
                            nodeId: inStep.id,
                            portId: PortId.OUTPUT,
                        },
                        to: {
                            nodeId: node.id,
                            portId: PortId.INPUT,
                        },
                    }

                    if (inStep.type === NodeType.CONDITION) {
                        const inBranch = inStep.configuration[CONDITION_BRANCH_ROOT].find(
                            (c: any) => c.out_step_id === step.id,
                        )

                        if (inBranch) {
                            link.from.portId = inBranch.id

                            link.properties = {
                                type: 'branch',
                                branch: inBranch.id,
                            }
                        }
                    }

                    chart.links[link.id] = link
                })
            }

            if (step.type !== NodeType.CONDITION) {
                if (step.configuration.out_step_id) {
                    node.ports[PortId.OUTPUT] = {
                        id: PortId.OUTPUT,
                        type: PortType.OUTPUT,
                    }
                }
            } else {
                const conditions: any[] = step.configuration[CONDITION_BRANCH_ROOT] ?? []

                for (const condition of conditions) {
                    if (condition.out_step_id) {
                        node.ports[condition.id] = {
                            id: condition.id,
                            type: PortType.BOTTOM,
                        }
                    }
                }
            }

            chart.nodes[step.id] = node
        }
    }

    return chart
}

export const parseCampaignFromChart = (chart: IChart & { builder: { campaign?: any } }) => {
    const steps: any[] = []

    const campaign = clone(chart.builder?.campaign ?? {})
    const nodes = Object.values(chart.nodes)
    const links = Object.values(chart.links)

    const deletedSteps = campaign.steps.filter((step) => step.destroyedAt !== null)

    if (deletedSteps.length > 0) {
        deletedSteps.forEach((step) => {
            steps.push(step)
        })
    }

    for (const node of nodes) {
        const inLinks = links.filter((l) => l.to?.nodeId === node.id)
        const outLinks = links.filter((l) => l.from.nodeId === node.id)

        const step: any = {
            id: node.id,
            campaign_id: !campaign ? undefined : campaign.id,
            type: node.type,
            configuration: {
                in_step_ids: inLinks.map((l) => l.from.nodeId),
            },
        }

        if (node.type !== NodeType.CONDITION) {
            step.configuration = {
                ...node.properties?.[node.type],
            }

            if (outLinks.length > 0) {
                step.configuration.out_step_id = outLinks[0].to.nodeId
            }
        } else {
            step.configuration = {
                type: node.properties?.[node.type]?.type,
                sub_type: node.properties?.[node.type]?.sub_type,
                trigger_type: node.properties?.[node.type]?.trigger_type,
                [CONDITION_BRANCH_ROOT]: [],
            }

            for (const link of outLinks) {
                const paths: any[] = node.properties?.[node.type]?.[CONDITION_BRANCH_ROOT] ?? []
                const path = paths.find((p) => p.id === link.from.portId)

                step.configuration[CONDITION_BRANCH_ROOT].push({
                    id: link.from.portId,
                    out_step_id: link.to?.nodeId,
                    params: {
                        ...path.params,
                    },
                })
            }
        }

        steps.push(step)
    }

    campaign.steps = steps

    return campaign
}
