import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { LoadingOutlined } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Input as AqeInput } from '@pushly/aqe/lib/components'
import { preventBubbling } from '../../_utils/utils'
import { axiosCancellationRequests } from '../../config/axios-setup'
import { SwPortal } from '../sw-portal/sw-portal'
import * as randomstring from 'randomstring'
import { CampaignV2Service } from '../../services/campaign-v2'
import { CampaignDto } from '../../features/campaigns/dtos/campaign-dto'
import './campaign-search-bar.scss'

interface INotificationSearchBarProps {
    value?: number
    onChange?: (value: number, campaign: CampaignDto) => any
    mode?: 'display' | 'search'
    autofocus?: boolean

    className?: string
    disabled?: boolean
    renderOption?: (campaign: CampaignDto) => React.ReactNode
    getDropdownContainer?: () => HTMLElement | any

    placeholder?: string
    reactive?: boolean
}

interface INotificationSearchBarState {
    expanded: boolean
    searching: boolean
    campaigns: CampaignDto[]
    tmpValue?: number
}

export class CampaignSearchBar extends BetterComponent<INotificationSearchBarProps, INotificationSearchBarState> {
    private defaultClassName = 'sw-v2-campaign-search-bar'
    private axiosCancellationKey = 'csb.searchCampaigns'

    private appState: AppState
    private appService: AppService
    private campaignService: CampaignV2Service

    private uid: string = randomstring.generate()
    private ref: any
    private inputRef: any
    private dropdownRef: any
    private debounceTimer: any
    private debounceValue = 450

    public constructor(props: INotificationSearchBarProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.campaignService = Container.get(CampaignV2Service)

        this.state = {
            expanded: false,
            searching: false,
            campaigns: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.wireEventHandlers()

        if (this.hasValue) {
            return this.searchCampaigns(this.props.value!.toString(), true)
        } else if (this.props.autofocus && !!this.inputRef && !!this.inputRef.inputRef) {
            this.inputRef.inputRef.focus()
        }
    }

    public async componentDidUpdate(): Promise<void> {
        if (this.isDisplayMode && this.hasValue) {
            if (!this.state.tmpValue || this.props.value!.toString() !== this.state.tmpValue.toString()) {
                this.searchCampaigns(this.props.value!.toString(), true)
            }
        }
    }

    public componentWillUnmount() {
        super.componentWillUnmount()
        this.unwireEventHandlers()
    }

    public render(): React.ReactNode {
        const pos = this.getDropdownPositions()

        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <span className={this.buildClassName('display')}>
                        {this.hasValue ? (
                            <>
                                <span className="option-id">#{this.props.value}:</span>
                                &nbsp;
                                <span className="option-title">
                                    {this.selectedCampaign ? (
                                        this.selectedCampaign.name
                                    ) : (
                                        <LoadingOutlined spin={true} />
                                    )}
                                </span>
                            </>
                        ) : (
                            '...'
                        )}
                    </span>

                    <div className={this.buildClassName('search')}>
                        <AqeInput
                            ref={(el) => (this.inputRef = el)}
                            className={this.buildClassName('search-input')}
                            reactive={this.props.reactive !== false}
                            onChange={this.handleInputChange}
                            onFocus={this.activate}
                            placeholder={this.props.placeholder || 'Search by ID or Name'}
                            maxWidth={400}
                            icon={
                                (
                                    <LegacyIcon
                                        className="search-icon"
                                        type={this.state.searching ? 'loading' : 'search'}
                                        spin={this.state.searching}
                                    />
                                ) as any
                            }
                        />
                    </div>

                    <SwPortal
                        className={this.buildRootClassNames('portal')}
                        portalId={this.buildClassName(`portal-${this.uid}`)}
                        container={this.portalContainer}
                        style={pos.portal}
                    >
                        <div
                            ref={(el) => (this.dropdownRef = el)}
                            className={this.buildRootClassNames('dropdown')}
                            style={pos.dropdown}
                        >
                            <div className={this.buildClassName('dropdown-wrapper')}>
                                <div
                                    className={`${this.buildClassName('dropdown-options')}${
                                        !this.hasOptions ? ' empty' : ''
                                    }`}
                                >
                                    {!this.hasOptions ? (
                                        <span>{this.isSearching ? 'Searching ...' : 'No campaigns found'}</span>
                                    ) : (
                                        this.state.campaigns.map((n) => this.renderOption(n))
                                    )}
                                </div>
                            </div>
                        </div>
                    </SwPortal>
                </div>
            </div>
        )
    }

    public renderOption(campaign: CampaignDto): React.ReactNode {
        const selected = this.hasValue && this.props.value!.toString() === campaign.id.toString()

        return (
            <div
                key={campaign.id}
                className={`${this.buildClassName('dropdown-option')}${selected ? ' selected' : ''}`}
            >
                <div
                    className={this.buildClassName('dropdown-option-wrapper')}
                    onClick={() => this.handleValueChange(campaign)}
                >
                    {!!this.props.renderOption ? (
                        this.props.renderOption(campaign)
                    ) : (
                        <>
                            <div className={this.buildClassName('dropdown-option-upper')}>
                                <span className="option-id">#{campaign.id}</span>
                            </div>
                            <div className={this.buildClassName('dropdown-option-lower')}>
                                <span className="option-title">{campaign.name}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    public getDropdownPositions(): any {
        let response: any = {}

        if (this.ref) {
            const docRect = document.body.getBoundingClientRect()
            const rect = this.ref.getBoundingClientRect()

            const bottomDiff = docRect.bottom - rect.bottom
            const showBelow = bottomDiff > 300 + 12

            const rules = [
                ['position', 'absolute'],
                [
                    showBelow ? 'top' : 'bottom',
                    showBelow
                        ? `${Math.floor(rect.top) + Math.floor(rect.height)}px`
                        : `${Math.floor(bottomDiff) + Math.floor(rect.height) + 2}px`,
                ],
                ['left', `${Math.floor(rect.left)}px`],
                ['width', `${Math.floor(rect.width)}px`],
            ]

            response = {
                portal: rules.map((p) => p.join(':')).join(';'),
                dropdown: {},
            }

            if (!showBelow) {
                response.dropdown.transform = 'translateY(-100%)'
                response.dropdown.marginTop = '0'
            }
        }

        return response
    }

    protected get isSearchMode(): boolean {
        return this.props.mode !== 'display'
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get isExpanded(): boolean {
        return this.state.expanded
    }

    protected get isSearching(): boolean {
        return this.state.searching
    }

    protected get hasOptions(): boolean {
        return this.state.campaigns.length > 0
    }

    protected get hasValue(): boolean {
        return !!this.props.value
    }

    protected get hasInput(): boolean {
        return !!this.inputRef && !!this.inputRef.input.value.trim()
    }

    protected get inputValue(): string {
        return this.hasInput ? this.inputRef.input.value.trim() : ''
    }

    protected get selectedCampaign(): CampaignDto | undefined {
        let campaign: CampaignDto | undefined

        if (this.hasOptions && this.hasValue) {
            campaign = this.state.campaigns.find((n) => n.id.toString() === this.props.value!.toString())
        }

        return campaign
    }

    protected get portalContainer(): HTMLElement {
        return !this.props.getDropdownContainer ? document.body : this.props.getDropdownContainer()
    }

    protected updateInputRefValue(value: any): void {
        if (!!this.inputRef) {
            this.inputRef.inputRef.setState({ value })
            this.inputRef.calculateWidth(value)
        }
    }

    protected handleValueChange = async (campaign: CampaignDto): Promise<void> => {
        // clear loaded tmpValue
        await this.setState({ tmpValue: undefined })

        this.emitChangeEvent(campaign.id, campaign)
        this.deactivate()

        this.updateInputRefValue(campaign.name)
    }

    protected handleInputChange = async (ev: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const value = ev.target.value

        this.emitChangeEvent(undefined as any)

        if (value.length >= 3) {
            this.searchCampaigns(value)
        } else {
            this.deactivate()
        }
    }

    protected activate = async (): Promise<void> => {
        this.debounce(() => {
            if (this.hasInput && this.inputValue.length > 3) {
                this.searchCampaigns(this.inputValue)
            }
        }, this.debounceValue / 2)
    }

    protected deactivate = async (): Promise<void> => {
        this.debounce(() => {
            this.cancelRequests()
            return this.setState(() => ({ expanded: false }))
        }, this.debounceValue / 2)
    }

    protected cancelRequests = async (): Promise<void> => {
        if (this.axiosCancellationKey in axiosCancellationRequests) {
            axiosCancellationRequests[this.axiosCancellationKey]()
        }

        return this.setState(() => ({
            searching: false,
            campaigns: [],
        }))
    }

    protected async searchCampaigns(query: string, reload: boolean = false): Promise<void> {
        this.setState(() => ({
            expanded: !reload,
            searching: true,
            tmpValue: reload ? (query as any) : undefined,
        }))

        this.debounce(async () => {
            const opts = {
                query: {
                    search: query,
                    pagination: 0,
                },
                showLoadingScreen: false,
                cancellationKey: `${this.axiosCancellationKey}-${this.uid}`,
            }

            const res = await this.campaignService.fetchAll(opts)

            await this.setState(({ expanded, campaigns: currentCampaigns }) => ({
                campaigns: !res.ok ? currentCampaigns : res.data ?? [],
                searching: expanded && !res.ok,
            }))

            if (reload && !!this.selectedCampaign) {
                this.updateInputRefValue(this.selectedCampaign.name)
            }
        })
    }

    protected debounce(fn: Function, timeout?: number) {
        if (!!this.debounceTimer) clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(fn, !!timeout ? timeout : this.debounceValue)
    }

    protected async emitChangeEvent(value: number, campaign?: CampaignDto): Promise<void> {
        if (!!this.props.onChange) {
            this.props.onChange(value, campaign!)
        }
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(append?: string): string {
        const classNames: string[] = [
            !!append ? `${this.defaultClassName}-${append}` : this.defaultClassName,
            this.isDisplayMode ? 'mode-display' : 'mode-search',
            this.isExpanded ? 'expanded' : 'collapsed',
            this.isSearching ? 'searching' : 'static',
        ]
        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }

    protected wireEventHandlers(): void {
        document.addEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.addEventListener('mousedown', preventBubbling)
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))
        this.ref.removeEventListener('mousedown', preventBubbling)
    }

    protected handleDocumentClick(event: MouseEvent): void {
        if (this.isExpanded) this.deactivate()
    }
}
