import { DynamicJsxType } from '../../types'
import { ISubmitButtonAction } from './well'

export class WellConfiguration {
    public ghost: boolean = false
    public collapsible: boolean = false
    public collapsed: boolean = false
    public submitting: boolean = false
    public disabled: boolean = false
    public className?: string

    public showHeader: boolean = true
    public header?: DynamicJsxType | undefined
    public title?: string | undefined
    public action?: string | DynamicJsxType | undefined
    public onActionClick?: (() => any) | undefined

    public showFooter: boolean = true
    public footer?: DynamicJsxType | undefined
    public submitText: string
    public submitActions?: ISubmitButtonAction[]
    public onSubmit?: Function | undefined
    public cancelText: string
    public onCancel?: Function | undefined

    public constructor(fields?: Partial<WellConfiguration>) {
        if (fields) {
            Object.assign(this, fields)
        }
    }
}
