import * as React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Row, Col, Input } from 'antd'
import { Container } from 'typescript-ioc/es5'
import { AccountNotificationService } from '../../services/account-notification'
import { OrgNotificationModel } from '../../models/notification/org-notification.model'

interface IOrgNotificationNotesDataFormProps {
    getEntity: () => OrgNotificationModel | undefined
    onSubmit: Function
    onCancel: Function
}

interface IState {
    group: OrgNotificationModel | undefined
    initialDetails?: string | null
    details?: string
}

class OrgNotificationNotesDataForm extends React.Component<IOrgNotificationNotesDataFormProps, IState> {
    private acctNotifService: AccountNotificationService

    public constructor(props: IOrgNotificationNotesDataFormProps) {
        super(props)

        this.acctNotifService = Container.get(AccountNotificationService)

        this.state = {
            group: props.getEntity(),
            initialDetails: props.getEntity()?.getNotes(),
        }
    }

    public render() {
        const { group } = this.state

        return !!group ? (
            <>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Notification ID:</b>
                    </Col>
                    <Col span={18}>{group.getId()}</Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Domains:</b>
                    </Col>
                    <Col span={18}>
                        {group
                            .getNotifications()
                            ?.map((n) => n.domainDisplayName)
                            .join(', ')}
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={6}>
                        <b>Title:</b>
                    </Col>
                    <Col span={18}>{group.getVariant()?.getContent().getDefaultContent().getTitle()}</Col>
                </Row>

                <hr />

                <Well
                    className="additional-details-well thin"
                    mode="ghost"
                    title="Notes"
                    onSubmit={this.handleSubmit}
                    onCancel={this.props.onCancel}
                >
                    <Form>
                        <div className="fx-row">
                            <div className="fx-col">
                                Add additional notification details to display to the publisher.
                            </div>
                            <div className="fx-col fx-shrink">
                                <a onClick={this.clearDetails}>
                                    <span>Clear</span>
                                </a>
                            </div>
                        </div>

                        <Form.Item>
                            <Input.TextArea
                                onChange={this.updateDetailsState}
                                value={this.state.details ?? this.state.initialDetails ?? ''}
                            />
                        </Form.Item>
                    </Form>
                </Well>
            </>
        ) : (
            <></>
        )
    }

    protected updateDetailsState = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        return this.setState({
            initialDetails: undefined,
            details: ev.target.value,
        })
    }

    protected clearDetails = () => {
        return this.setState({
            initialDetails: undefined,
            details: undefined,
        })
    }

    protected handleSubmit = async () => {
        const { group } = this.state

        if (group) {
            try {
                await this.acctNotifService.updateNotes(group, !this.state.details ? null : this.state.details)

                this.props.getEntity()!.setNotes(this.state.details!)
                this.props.onSubmit()
            } catch {}
        }
    }
}

export default OrgNotificationNotesDataForm
