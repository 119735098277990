import { DomainSendIntegrationModel } from '../../../models/send-integration-configurations/domain-send-integration.model'
import { DomainDto } from '../../../dtos/domain'
import { createContext } from 'react'

interface ISendIntegrationsContext {
    loading: boolean
    domain: DomainDto
    sendIntegrations: DomainSendIntegrationModel
    setSendIntegrations: (changes: DomainSendIntegrationModel, debounce?: boolean) => any
}

export const SendIntegrationsContext = createContext<ISendIntegrationsContext>({} as ISendIntegrationsContext)
