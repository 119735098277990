import * as React from 'react'
import { AsyncButton } from '../../components/async-button/async-button.component'
import { AppService } from '../../services'
import { PageHeader } from '../../components/page-header/page-header'
import SegmentList from '../../components/segment-list/segment-list'
import { getPathEntityId } from '../../_utils/get-path-entity-id'
import { useService } from '@pushly/aqe/lib/hooks/use-service'
import { AppState } from '../../stores/app'
import SimpleTabbedView from '../../components/simple-tabbed-view/simple-tabbed-view'
import SegmentComparison from '../../components/segment-comparison/segment-comparison'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { AbilityAction } from '../../enums/ability-action.enum'
import { asCaslSubject, CurrentUserCan } from '../../stores/app-ability'

const Segments = (props) => {
    const appState = useService(AppState)
    const appService = useService(AppService)

    const pathOrgId = getPathEntityId('organization')
    const pathDomainId = getPathEntityId('domain')

    /**
     * Ensure injected props are passed down
     * for history container access
     */
    const tabProps: any = { ...props }
    let permissionEntity: SubjectEntity = SubjectEntity.SEGMENT
    let permissionConstraints: any = {}

    if (pathOrgId) {
        tabProps.level = 'org'
        tabProps.orgId = pathOrgId

        permissionEntity = SubjectEntity.ORG_SEGMENT
        permissionConstraints.accountId = pathOrgId
    } else {
        tabProps.level = 'domain'
        tabProps.domainId = pathDomainId

        permissionConstraints.domainId = pathDomainId
    }

    const newSegmentUrl = appService.routeWithin(tabProps.level, '/segments/new', true)
    const titleEntity = pathOrgId ? 'Multi-Domain Segments' : 'Segments'

    let orgName
    if (pathOrgId && appState.currentDomain?.accountId === pathOrgId) {
        orgName = appState.currentDomain.accountName
    }

    return (
        <div className="segment-list">
            <PageHeader
                title={titleEntity}
                append={orgName}
                action={
                    <CurrentUserCan
                        do={AbilityAction.CREATE}
                        on={asCaslSubject(permissionEntity, permissionConstraints)}
                    >
                        <AsyncButton
                            className="new-segments-button"
                            type="primary"
                            onClick={() => appService.route(newSegmentUrl)}
                            altHref={newSegmentUrl}
                        >
                            <span>Create Segment</span>
                        </AsyncButton>
                    </CurrentUserCan>
                }
            />

            {tabProps.level === 'org' ? (
                <SegmentList {...tabProps} />
            ) : (
                <SimpleTabbedView
                    pathStyle="slash"
                    animated={false}
                    tabs={[
                        {
                            tabClassName: 'segment-list-tab',
                            component: SimpleTabbedView.wrapComponentTab(SegmentList, {
                                tabName: null,
                                tabLabel: 'List',
                            }),
                            props: () => tabProps,
                        },
                        {
                            tabClassName: 'segment-comparison-tab',
                            component: SimpleTabbedView.wrapComponentTab(SegmentComparison, {
                                tabName: 'compare',
                                tabLabel: 'Compare',
                            }),
                            props: () => tabProps,
                        },
                    ]}
                />
            )}
        </div>
    )
}

export default Segments
