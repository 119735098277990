import * as React from 'react'
import './style/sidebar.scss'
import classnames from 'classnames'
import SidebarCollapseToggle from './sidebar-collapse-toggle'
import UserMenu from './user-menu'
import ActiveDomainSelect from './active-domain-select'
import AppMenu from './app-menu'
import SidebarActionItem from './sidebar-action-item'
import { QuestionCircleFilled } from '@ant-design/icons'
import { IconPushly } from '../svg/icon-pushly'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { observer } from 'mobx-react'
import { PlatformUserContext } from '../../providers/user-provider/user-provider'

interface ISidebarSection {
    section: string
}
const SidebarSection: React.FunctionComponent<ISidebarSection> = (props) => {
    return (
        <div className={classnames(`sidebar-section-${props.section}`)}>
            <div className={classnames('sidebar-section-content')}>{props.children}</div>
        </div>
    )
}

@observer
class Sidebar extends React.Component {
    public static contextType = PlatformUserContext
    public context!: React.ContextType<typeof PlatformUserContext>

    protected readonly appState: AppState
    protected readonly appService: AppService

    public constructor(props: any) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const { sidebar, currentDomain } = this.appState
        const canRender = this.context.authenticated

        return !canRender ? null : (
            <div
                className={classnames('app-sidebar', this.appState.abilityStore.acs.state, {
                    open: !sidebar.collapsed,
                    collapsed: sidebar.collapsed,
                    'context-org': this.appState.inOrgContext,
                    'context-domain': this.appState.inDomainContext,
                })}
            >
                <SidebarCollapseToggle />

                <SidebarSection section="upper">
                    <ActiveDomainSelect />
                </SidebarSection>

                <SidebarSection section="mid">
                    <AppMenu />
                </SidebarSection>

                <SidebarSection section="lower">
                    <SidebarActionItem
                        title="Help"
                        description={false}
                        icon={<QuestionCircleFilled />}
                        iconStyle="fill"
                        href={this.appState.documentationLink}
                    />

                    <UserMenu />
                </SidebarSection>

                <SidebarSection section="lower">
                    <span
                        className={classnames('sidebar-logo')}
                        onClick={() => {
                            if (!!currentDomain) {
                                this.appService.routeWithinDomain('/dashboard')
                            }
                        }}
                    >
                        <span className="sidebar-icon" />
                        <IconPushly className="sidebar-icon pushly-icon" />
                    </span>
                </SidebarSection>
            </div>
        )
    }
}

export default Sidebar
