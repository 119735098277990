import React from 'react'
import classnames from 'classnames'
import { SegmentBuilderContext } from './segment-builder-context'
import { Well, FileUploader } from '@pushly/aqe/lib/components'
import { Skeleton, Form, Input, Switch, Tooltip } from 'antd'
import { OrgSegmentModel } from '../../models/segments/org-segment.model'
import { SegmentModel } from '../../models/segments/segment.model'
import { QuestionCircleOutlined } from '@ant-design/icons'
import SegmentOrgDetailsBuilder from './segment-org-details-builder'
import MultiDomainHeaderAlert from './multi-domain-header-alert'
import { simpleNotification } from '../../_utils/utils'
import { SegmentSource } from '../../enums/segment-source.enum'

interface IIconFieldProps {
    onChange: (segment: OrgSegmentModel | SegmentModel) => void
    onEnabledChange: (enabled: boolean) => void
}

const SegmentIconField = ({ onChange, onEnabledChange }: IIconFieldProps) => {
    const context = React.useContext(SegmentBuilderContext)

    const owner = context.level === 'org' ? context.org : context.domain
    const segment = context.segment

    return (
        <div className="segment-builder-default-icon-field">
            <div className="switch-row size-small">
                <div className="switch-left">
                    <Switch
                        size="small"
                        checked={context.defaultIconEnabled}
                        disabled={context.readonly}
                        onChange={(enabled) => {
                            if (!enabled) {
                                const update = segment.clone()
                                update.setIconUrl(null)
                                onChange(update)
                            }

                            onEnabledChange(enabled)
                        }}
                    />
                </div>
                <div className="switch-right">Override default icon when targeting this segment.</div>
            </div>

            {context.defaultIconEnabled && (
                <FileUploader
                    className="icon-upload"
                    type="image"
                    buttonText="Icon"
                    disabled={context.readonly}
                    ownerType={context.level}
                    ownerId={owner.id}
                    defaultValue={
                        !segment.getIconUrl()
                            ? undefined
                            : {
                                  url: segment.getIconUrl()!,
                              }
                    }
                    imageConfig={{
                        resize: FileUploader.defaultDimensions.notificationIcon,
                        cropMax: FileUploader.defaultDimensions.notificationIcon,
                        cropRatio: 1,
                        quality: owner.mediaQuality,
                    }}
                    onSuccess={(iconUpload) => {
                        const update = segment.clone()
                        update.setIconUrl(iconUpload.url)
                        onChange(update)
                    }}
                    onRemove={() => {
                        const update = segment.clone()
                        update.setIconUrl(null)
                        onChange(update)
                    }}
                    onError={(res: any) => {
                        const error = res?.error
                        if (error && error.error_type === 'invalid_file_type_error') {
                            return simpleNotification('error', error.message)
                        }
                    }}
                />
            )}
        </div>
    )
}

interface ISingleUseFieldProps {
    onChange: (segment: OrgSegmentModel | SegmentModel) => void
    onEnabledChange: (enabled: boolean) => void
}

const SingleUseField = ({ onChange, onEnabledChange }: ISingleUseFieldProps) => {
    const context = React.useContext(SegmentBuilderContext)

    const segment = context.segment

    return (
        <div className="switch-row segment-build-adhoc-switch">
            <span className="switch-left">
                <Switch
                    size="small"
                    checked={context.adhocEnabled}
                    onChange={(enabled) => {
                        const update = segment.clone()
                        update.setSource(enabled ? SegmentSource.ADHOC : SegmentSource.STANDARD)

                        onChange(update)
                        onEnabledChange(enabled)
                    }}
                />
            </span>
            <span className="switch-right">
                <span>Single-Use Segment</span>
                <Tooltip
                    title={
                        <span>
                            Enabling marks this segment as a single-use segment. It will not be shown in the segment
                            dropdown or segment list after this use.
                        </span>
                    }
                >
                    <QuestionCircleOutlined className="info-icon" />
                </Tooltip>
            </span>
        </div>
    )
}

interface IDetailsBuilderProps {
    onChange: (segment: OrgSegmentModel | SegmentModel) => void
    onAdhocEnabledChange: (enabled: boolean) => void
    onDefaultIconEnabledChange: (enabled: boolean) => void
    onDomainIdsChange: (domainIds: number[], requiresValidation: boolean) => void
}

const SegmentDetailsBuilder = (props: IDetailsBuilderProps) => {
    const context = React.useContext(SegmentBuilderContext)
    const detailsDependenciesLoaded = context.levelDependenciesLoaded && context.segmentDependenciesLoaded

    const isMemberOfMultiDomain = context.segment instanceof SegmentModel && context.segment.getIsMultiDomain()

    return (
        <React.Fragment>
            {isMemberOfMultiDomain && <MultiDomainHeaderAlert />}

            <Well
                className={classnames('details-well', 'segment-builder-details-builder', {
                    blur: context.level === 'org' && context.confirmingDomainChange,
                })}
                title="Details"
                hideFooter={true}
            >
                <Skeleton loading={!detailsDependenciesLoaded} active={true}>
                    {detailsDependenciesLoaded && context.segment && (
                        <React.Fragment>
                            <Form.Item label="Name" name="name">
                                <Input
                                    size={context.mode === 'drawer' ? 'small' : 'large'}
                                    placeholder="Provide a unique name for your segment"
                                    defaultValue={context.segment.getName()}
                                    disabled={context.readonly}
                                    onChange={(ev) => {
                                        const update = context.segment.clone()
                                        update.setName(ev.target.value)

                                        props.onChange(update)
                                    }}
                                />
                            </Form.Item>

                            {/* Org Segment only fields */}
                            {context.level === 'org' && <SegmentOrgDetailsBuilder onChange={props.onDomainIdsChange} />}

                            {/* Drawer Mode only fields */}
                            {context.isDrawerMode && (
                                <SingleUseField
                                    onChange={props.onChange}
                                    onEnabledChange={props.onAdhocEnabledChange}
                                />
                            )}

                            {/* Standard Mode only fields */}
                            {!context.isDrawerMode && (
                                <SegmentIconField
                                    onChange={props.onChange}
                                    onEnabledChange={props.onDefaultIconEnabledChange}
                                />
                            )}
                        </React.Fragment>
                    )}
                </Skeleton>
            </Well>
        </React.Fragment>
    )
}

export default SegmentDetailsBuilder
