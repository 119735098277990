import * as React from 'react'
import { InputNumber as AntInputNumber, Select } from 'antd'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'

interface IInputNumberProps {
    rule: IRule
    onChange: (value: any) => any
    autofocus?: boolean

    metrics?: string[]
    min?: number
    max?: number
}

export class NumberInput extends BetterComponent<IInputNumberProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-number-input'
    public ref: any
    public inputRef: any

    public async componentDidMount() {
        if (this.props.autofocus) this.autofocus()
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className="value">
                    <AntInputNumber
                        ref={(el) => (this.inputRef = el)}
                        onChange={this.handleValueChange}
                        value={this.rule.value}
                        min={this.props.min}
                        max={this.props.max}
                        type="number"
                    />
                </div>

                {this.hasMetrics && <div className="metric">{this.renderMetricField()}</div>}
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected renderMetricField(): React.ReactNode {
        let metricField: React.ReactNode

        if (this.hasMetrics && this.props.metrics!.length > 0) {
            if (this.props.metrics!.length === 1) {
                return this.props.metrics![0]
            }

            metricField = (
                <Select
                    dropdownClassName={this.buildClassName('metric-dropdown')}
                    value={this.currentMetricState}
                    onChange={this.handleMetricChange}
                >
                    {this.props.metrics!.map((metric) => (
                        <Select.Option key={metric} value={metric}>
                            {metric}
                        </Select.Option>
                    ))}
                </Select>
            )
        }

        return metricField
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get hasMetrics(): boolean {
        return Array.isArray(this.props.metrics)
    }

    protected get currentMetricState(): string {
        const metricFallback = this.hasMetrics ? this.props.metrics![0] : undefined
        return !!this.rule.meta ? this.rule.meta.qualifier || metricFallback : metricFallback
    }

    protected autofocus(): void {
        if (!!this.inputRef) this.inputRef.focus()
    }

    @autobind
    protected async handleValueChange(value: number): Promise<void> {
        if (value === undefined || String(value) === '') {
            return
        }
        this.emitChange({ value })
    }

    @autobind
    protected async handleMetricChange(metric: string) {
        this.emitChange({ meta: { qualifier: metric } })
    }

    protected async emitChange(changes: any): Promise<void> {
        const value = {
            value: changes.value ?? this.rule.value,
            meta: {
                ...this.rule.meta,
                ...changes.meta,
            },
        }

        if (!!this.currentMetricState) {
            value.meta.qualifier = this.currentMetricState
        }

        this.props.onChange(value)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
