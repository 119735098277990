import * as React from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Radio, Select } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { INode } from '@mrblenny/react-flow-chart'
import titleCase from 'title-case'
import { DEFAULT_CONDITION_BRANCH_ID } from '../constants'
import { PortId } from '../enums'

interface IRemoveCaseConditionConfirmationModal {
    node: INode
}

interface IState {
    type: string
}

export class RemoveBranchConditionConfirmationModal extends React.Component<
    IRemoveCaseConditionConfirmationModal,
    IState
> {
    public state: IState = {
        type: 'all',
    }

    public render() {
        const outPortKeys = Object.keys(this.props.node.ports).filter((p) => p !== PortId.INPUT)

        return (
            <div>
                <p>You are removing a condition in the middle of an existing series of actions. To continue you can:</p>
                <div>
                    <Form.Item>
                        <Radio.Group
                            size="small"
                            onChange={(ev: RadioChangeEvent) => {
                                const type = ev.target.value === 'all' ? 'all' : DEFAULT_CONDITION_BRANCH_ID
                                this.setState({ type })
                            }}
                            value={this.state.type === 'all' ? 'all' : 'branch'}
                        >
                            <Radio.Button value="all">Remove All</Radio.Button>
                            <Radio.Button value="branch">Preserve Specific Branch</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    {this.state.type !== 'all' && (
                        <Form.Item>
                            <Select
                                size="small"
                                value={this.state.type}
                                onChange={(type: string) => this.setState({ type })}
                            >
                                {outPortKeys.map((key) => (
                                    <Select.Option key={key} value={key}>
                                        {key === DEFAULT_CONDITION_BRANCH_ID ? 'Default' : titleCase(key)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </div>
            </div>
        )
    }
}
