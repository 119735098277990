import { IDomainFrequencyCaps, IDomainGlobalPromptSettings, IDomainUtmParams } from '../../../dtos/domain'
import {
    IAbstractNativeApnsSendIntegration,
    IAbstractNativeFcmSendIntegration,
    IApnsConfiguration,
} from '../../../interfaces/send-integrations'

export class DomainUpdateRequestDto {
    public static parse(fields: DomainUpdateRequestDto): DomainUpdateRequestDto {
        const dto = new DomainUpdateRequestDto()

        type AllowedType = keyof DomainUpdateRequestDto
        const fieldsToAssign: AllowedType[] = [
            'displayName',
            'timezone',
            'defaultIconUrl',
            'defaultBadgeUrl',
            'senderId',
            'fcmServerKey',
            'fcmCredentialsJson',
            'frequencyCaps',
            'globalPromptSettings',
            'utmParams',
            'apnsConfiguration',
            'nativeApnsConfiguration',
            'nativeFcmConfiguration',
            'whitelistDomains',
            'sdkEventOnlyDomains',
            'landingUrlDomainWhitelist',
            'routingKey',
            'collapseKeyType',
            'addedNotificationQueryParams',
            'defaultNotificationTtlSeconds',
            'defaultNotificationActionLabels',
            'defaultNotificationActions',
            'fallbackNotificationTemplate',
            'notificationAutoSuggestFields',
            'displayMeta',
            'mediaQuality',
            'flags',
        ]

        fieldsToAssign.forEach((key) => {
            if (fields[key] !== undefined) {
                dto[key] = fields[key]
            }
        })

        return dto
    }

    public displayName?: string
    public timezone?: string
    public defaultIconUrl?: string
    public defaultBadgeUrl?: string
    public defaultNotificationTtlSeconds?: string
    public defaultNotificationActionLabels?: any
    public defaultNotificationActions?: any[]
    public fallbackNotificationTemplate?: any
    public senderId?: string
    public fcmServerKey?: string
    public fcmCredentialsJson?: any
    public frequencyCaps?: IDomainFrequencyCaps | null
    public utmParams?: IDomainUtmParams | null
    public globalPromptSettings?: IDomainGlobalPromptSettings
    public apnsConfiguration?: IApnsConfiguration | null
    public nativeApnsConfiguration?: IAbstractNativeApnsSendIntegration | null
    public nativeFcmConfiguration?: IAbstractNativeFcmSendIntegration | null
    public whitelistDomains?: string[] | null
    public sdkEventOnlyDomains?: string[]
    public landingUrlDomainWhitelist?: string[]
    public collapseKeyType?: string | null
    public routingKey?: string | null
    public addedNotificationQueryParams?: any | null
    public notificationAutoSuggestFields?: string[] | null
    public flags?: string[] | null
    public displayMeta?: any
    public mediaQuality?: number
}
