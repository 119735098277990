export const addBodyClassName = (className: string) => {
    let added = false

    try {
        if (!document.body.classList.contains(className)) {
            document.body.classList.add(className)
            added = true
        }
    } catch {}

    return added
}

export const removeBodyClassName = (className: string) => {
    let removed = false

    try {
        if (document.body.classList.contains(className)) {
            document.body.classList.remove(className)
            removed = true
        }
    } catch {}

    return removed
}
