import React from 'react'
import { Well } from '@pushly/aqe/lib/components'
import { Select, Skeleton } from 'antd'
import { ITypeEditor } from '../interfaces'
import { updateNodeParams } from '../helpers/node'
import { useLoadableDataRefState } from '../../../hooks/use-loadable-data-ref-state'
import { useService } from '../../../hooks/use-service'
import { AmplyCollectionsService } from '../../../services/amply-collections'

const AmplyCollectionNotificationEditor = ({ node, onChange, readonly }: ITypeEditor) => {
    const collectionsSvc = useService<AmplyCollectionsService>(AmplyCollectionsService)
    const [collections, setCollections] = useLoadableDataRefState<any[]>({ loading: true })

    const params = node.properties[node.type].params || {}
    const currCollectionId = params.pub_network_amply_collection_id

    React.useEffect(() => {
        const loadCollections = async () => {
            const update: any = { loading: false }

            const res = await collectionsSvc.fetchAll({
                query: { pagination: 0 },
            })

            if (res.ok) {
                update.data = res.data
            }

            setCollections(update)
        }

        loadCollections()
    }, [])

    return (
        <Well mode="ghost" title="Amply Collection" disabled={readonly} hideFooter={true}>
            <Skeleton
                loading={collections.current.loading}
                active={true}
                avatar={false}
                title={false}
                paragraph={{ rows: 1 }}
            >
                {!collections.current.loading && (
                    <Select
                        className="campaign-action-editor-amply-collection-select"
                        dropdownClassName="campaign-action-editor-amply-collection-dropdown"
                        size="small"
                        defaultValue={currCollectionId}
                        value={node.properties?.[node.type]?.params?.pub_network_collection}
                        onChange={(collectionId) => {
                            const coll = collections.current.data!.find((c) => c.id === collectionId)
                            return onChange(
                                updateNodeParams(node, {
                                    pub_network_amply_collection_id: coll.id,
                                    pub_network_amply_collection_name: coll.name,
                                }),
                            )
                        }}
                        showSearch={true}
                        filterOption={true}
                        optionFilterProp="title"
                        disabled={readonly}
                    >
                        {collections.current.data?.map((coll) => (
                            <Select.Option key={coll.id} value={coll.id} title={`${coll.id} ${coll.name}`}>
                                <span className="collection-id">{coll.id}</span>
                                <span className="collection-name">{coll.name}</span>
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Skeleton>
        </Well>
    )
}

export default AmplyCollectionNotificationEditor
