import * as React from 'react'
import { DomainSendIntegrationModel } from '../../../models/send-integration-configurations/domain-send-integration.model'
import { DomainDto } from '../../../dtos/domain'
import { SendIntegrationsContext } from './send-integrations-context'
import { ApnsIntegrationEditor } from './apns-integration-editor'
import { NativeFcmIntegrationEditor } from './native-fcm-integration-editor'

type SendIntegrationsEditorProps = {
    loading?: boolean
    integrations: DomainSendIntegrationModel
    domain: DomainDto
    onChange?: (value: DomainSendIntegrationModel) => any
    className?: string
    nativeFcmLegacy: boolean
}

let CHANGE_DEBOUNCE_TIMEOUT: any

export const SendIntegrationsEditor = (props: SendIntegrationsEditorProps) => {
    const { domain, integrations: _integrations, loading } = props

    const integrations = _integrations.clone()

    const emitChange = React.useCallback(
        async (changes: DomainSendIntegrationModel, debounce: boolean = false) => {
            clearTimeout(CHANGE_DEBOUNCE_TIMEOUT)

            if (props.onChange) {
                CHANGE_DEBOUNCE_TIMEOUT = setTimeout(
                    () => {
                        props.onChange!(changes)
                    },
                    debounce ? 320 : 0,
                )
            }
        },
        [!props.onChange],
    ) // only modify callback if fn existence changes

    return (
        <SendIntegrationsContext.Provider
            value={{
                loading: loading ?? false,
                domain,
                setSendIntegrations: emitChange,
                sendIntegrations: integrations,
            }}
        >
            <ApnsIntegrationEditor className={props.className} integrations={integrations} />
            <NativeFcmIntegrationEditor className={props.className} integrations={integrations} nativeFcmLegacy={props.nativeFcmLegacy}/>
        </SendIntegrationsContext.Provider>
    )
}
