import * as React from 'react'
import { AppState } from '../../stores/app'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { Skeleton } from 'antd'
import { SkeletonProps } from 'antd/lib/skeleton'
import './page-header.scss'

interface IPageHeaderProps {
    title: string | React.ReactNode
    browserTitle?: string
    append?: React.ReactNode
    action?: React.ReactNode
    loading?: boolean
    loaderConfig?: SkeletonProps
}

export class PageHeader extends React.Component<IPageHeaderProps, {}> {
    public defaultClassName: string = 'sw-v2-page-header'

    protected appState: AppState
    protected appService: AppService

    public constructor(props: IPageHeaderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public componentDidMount() {
        if (!!this.props.title) {
            if (this.appState.inDomainContext) {
                document.title = `${this.appState.currentDomain?.displayName} - ${
                    this.props.browserTitle ?? this.props.title
                }`
            }
            if (this.appState.inOrgContext) {
                document.title = `${this.appState.currentDomain?.accountName} - ${
                    this.props.browserTitle ?? this.props.title
                }`
            }
        } else {
            this.appService.customizeTabTitle()
        }
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <Skeleton
                    title={false}
                    paragraph={{ rows: 2 }}
                    active={true}
                    {...(this.props.loaderConfig || {})}
                    loading={this.props.loading}
                >
                    <div className={this.buildClassName('wrapper')}>
                        <div className={this.buildClassName('title-wrapper')}>
                            <h2 className={this.buildClassName('title')}>{this.props.title}</h2>

                            {!!this.props.append && (
                                <div className={this.buildClassName('title-append')}>{this.props.append}</div>
                            )}
                        </div>

                        {!!this.props.action && (
                            <div className={this.buildClassName('action-wrapper')}>{this.props.action}</div>
                        )}
                    </div>
                </Skeleton>
            </div>
        )
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.loading) classNames.push('loading')

        return classNames.join(' ')
    }
}
