import * as querystring from 'query-string'
import fileDownload from 'js-file-download'
import { Container, Singleton } from 'typescript-ioc/es5'
import { convertCase, simpleNotification } from '../_utils/utils'
import { AppService } from './app'
import { SegmentDto } from '../dtos/segment'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import aqe from '@pushly/aqe'
import IApiCallOptions from '../interfaces/api-call-options'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'
import { CustomApiResponseErrorHandler } from '../types/api-call-options-with-custom-error-handler'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays: any[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }

    return new Blob(byteArrays, { type: contentType })
}

@Singleton
export class SegmentService {
    private appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async fetchSegmentFieldsByDomainId(
        domainId: number,
        showLoadingScreen: boolean = false,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any[]>> {
        if (showLoadingScreen) this.appService.setModuleLoading()
        let ok = false
        let fields: any[] = []

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segment-fields`,
                },
                cancellationKey,
            )

            ok = true
            fields = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) this.appService.unsetModuleLoading()

        return { ok, data: fields }
    }

    public async fetchCalculatedFieldsByDomainId(
        domainId: number,
        showLoadingScreen: boolean = false,
        cancellationKey?: string,
    ): Promise<IServiceApiResponse<any[]>> {
        if (showLoadingScreen) this.appService.setModuleLoading()
        let ok = false
        let fields: any[] = []

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/calculated-fields`,
                },
                cancellationKey,
            )

            ok = true
            fields = req.data.data
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (showLoadingScreen) this.appService.unsetModuleLoading()

        return { ok, data: fields }
    }

    public async fetchSegmentById(
        domainId: number,
        segmentId: number,
        showLoadingScreen: boolean = false,
        cancellationKey?: string,
        errorHandler?: CustomApiResponseErrorHandler,
    ): Promise<SegmentDto | undefined> {
        if (showLoadingScreen) this.appService.setModuleLoading()

        let segment: SegmentDto | undefined
        try {
            const req = await axiosFetch(
                'get',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/${segmentId}`,
                },
                cancellationKey,
            )

            segment = SegmentDto.parse(req.data.data)
        } catch (error) {
            const defaultHandler = () => handleResponseErrorMessage(error)

            if (errorHandler) {
                errorHandler(error, defaultHandler)
            } else {
                defaultHandler()
            }
        }

        if (showLoadingScreen) this.appService.unsetModuleLoading()

        return segment
    }

    public async createSegmentForDomainId(
        domainId: number,
        createDto: SegmentDto,
        cancellationKey?: string,
    ): Promise<SegmentDto | undefined> {
        this.appService.setModuleLoading()

        let segment: SegmentDto | undefined
        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments`,
                    data: createDto,
                },
                cancellationKey,
            )

            segment = SegmentDto.parse(req.data.data)
            simpleNotification('success', 'Segment successfully created')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return segment
    }

    public async updateSegmentById(
        domainId: number,
        segmentId: number,
        updateDto: SegmentDto,
        cancellationKey?: string,
    ): Promise<SegmentDto | undefined> {
        this.appService.setModuleLoading()

        let segment: SegmentDto | undefined
        try {
            const req = await axiosFetch(
                'patch',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/${segmentId}`,
                    data: updateDto,
                },
                cancellationKey,
            )

            segment = SegmentDto.parse(req.data.data)
            simpleNotification('success', 'Segment successfully updated')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return segment
    }

    public async deleteSegmentById(domainId: number, segmentId: number, cancellationKey?: string): Promise<boolean> {
        this.appService.setModuleLoading()

        let deleted = false
        try {
            await axiosFetch(
                'delete',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/${segmentId}`,
                },
                cancellationKey,
            )

            deleted = true
            simpleNotification('success', 'Segment successfully deleted')
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        this.appService.unsetModuleLoading()

        return deleted
    }

    public async estimateReachByQuery(
        domainId: number,
        query: any,
        targetedChannels: string[],
        returnType: 'count_by_channel',
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<{ [key in DeliveryChannel]: number } | number | undefined>>
    public async estimateReachByQuery(
        domainId: number,
        query: any,
        targetedChannels: string[],
        returnType: 'count',
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<number | undefined>>
    public async estimateReachByQuery(
        domainId: number,
        query: any,
        targetedChannels: string[],
        returnType: 'count' | 'count_by_channel',
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<number | { [key in DeliveryChannel]: number } | undefined>> {
        let ok = false
        let cancelled = false
        let estimate: number | { [key in DeliveryChannel]: number } | undefined

        try {
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/estimate-reach`,
                    data: { query, targetedChannels, returnType },
                },
                opts?.cancellationKey,
            )

            ok = true
            estimate = convertCase(req.data.data?.reach, 'snake') ?? 0
        } catch (error) {
            cancelled = isAxiosCancellation(error)

            if (!cancelled) {
                console.warn(error)
            }
        }

        return { ok, data: estimate, cancelled }
    }

    public async fetchEstimatedReachWithOverlap(
        domainId: number,
        combinations: [number, number][],
        opts?: IApiCallOptions,
    ): Promise<IServiceApiResponse<any>> {
        const res = {
            ok: false,
            data: [],
            meta: {},
            cancelled: false,
            error: undefined,
        }

        try {
            const options = querystring.stringify(opts?.query ?? {})
            const req = await axiosFetch(
                'post',
                {
                    url: `${aqe.defaults.publicApiDomain}/domains/${domainId}/segments/estimate-reach-overlap?${options}`,
                    data: { combinations },
                },
                opts?.cancellationKey,
            )

            const contentType = req.request.getResponseHeader('content-type')
            const responseIsCSV = /text\/csv/i.test(contentType)
            const responseIsXLSX = /application\/vnd/i.test(contentType)
            const fileExt = opts?.query?.responseFormat ?? opts?.query?.response_format
            const fileName = `${opts?.query?.filename ?? 'reach-overlap'}.${fileExt}`

            if (responseIsCSV) {
                fileDownload(new Blob([req.data], { type: contentType }), fileName)
            } else if (responseIsXLSX) {
                fileDownload(b64toBlob(req.data, contentType), fileName)
            }

            res.ok = true
            res.data = req.data.data
        } catch (error) {
            res.error = error
            res.cancelled = isAxiosCancellation(error)
        }

        return res
    }
}
