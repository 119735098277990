import * as React from 'react'
import { ConfigurationContext } from './configuration-context'
import { CampaignEditableState, TriggerType } from '../enums'
import { getClassNames } from '../../../_utils/classnames'
import { Well } from '@pushly/aqe/lib/components'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Skeleton, Switch, Popover, TimePicker } from 'antd'
import { extractCampaignTrigger } from '../../../_utils/campaigns'
import moment, { Moment } from 'moment'

interface ISendSettingsWell {
    submitConfiguration?: any
}

interface IState {}

export class SendSettingsWell extends React.Component<ISendSettingsWell, IState> {
    public static contextType = ConfigurationContext
    public context!: React.ContextType<typeof ConfigurationContext>

    public state: IState = {}

    public render() {
        const { mode, loading, campaign, editableState, flags } = this.context
        const campaignType = campaign.getType()
        const config = campaign.getConfiguration()
        const isCampaignEditable = editableState === CampaignEditableState.EDITABLE
        const isCampaignCompleted = editableState === CampaignEditableState.COMPLETED

        let quietHoursStart
        if (config.getQuietHoursStart()) {
            quietHoursStart = moment(config.getQuietHoursStart(), 'hh:mm A')
        }

        let quietHoursEnd
        if (config.getQuietHoursEnd()) {
            quietHoursEnd = moment(config.getQuietHoursEnd(), 'hh:mm A')
        }

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type
        const isSubscribedTrigger = triggerType === TriggerType.SUBSCRIBED

        return (
            <Well
                className={getClassNames(null, 'campaign-send-settings-well', 'nested', `mode-${mode}`, {
                    ['final-well']: !!this.props.submitConfiguration,
                })}
                title="Send Settings"
                hideFooter={!this.props.submitConfiguration}
                {...this.props.submitConfiguration}
            >
                <Skeleton
                    loading={loading}
                    active={true}
                    title={false}
                    paragraph={{
                        rows: 1,
                        width: '100%',
                    }}
                >
                    {!isSubscribedTrigger && (
                        <Well
                            mode="ghost"
                            title={
                                <>
                                    <span>Quiet Hours</span>
                                    <Popover
                                        overlayClassName="quiet-hours-overlay"
                                        content={
                                            <p>
                                                Enabling Quiet Hours delays notifications from being sent during the
                                                chosen timeframe in the subscriber’s time zone. Any notifications that
                                                would have been sent during this time will be sent in the first hour
                                                after Quiet Hours ends.
                                            </p>
                                        }
                                    >
                                        <QuestionCircleOutlined className="info-icon" />
                                    </Popover>
                                </>
                            }
                            hideFooter={true}
                        >
                            <div className="switch-row">
                                <div className="switch-left">
                                    <Switch
                                        size="small"
                                        checked={flags.quietHoursEnabled ?? false}
                                        onChange={this.handleQuietHoursEnabledChange}
                                    />
                                </div>
                                <div className="switch-right">
                                    <span>Enable Quiet Hours</span>
                                </div>
                            </div>

                            {flags.quietHoursEnabled && (
                                <div className="quiet-hours-wrapper">
                                    <span>Do not send notifications between</span>
                                    <TimePicker
                                        className="quiet-hours-start-time"
                                        size="small"
                                        placeholder="Start time"
                                        use12Hours={true}
                                        format="hh:mm A"
                                        value={quietHoursStart}
                                        onChange={this.handleQuietHoursStartChange}
                                        onSelect={this.handleQuietHoursStartChange}
                                    />
                                    <span> and </span>
                                    <TimePicker
                                        className="quiet-hours-end-time"
                                        size="small"
                                        placeholder="End time"
                                        use12Hours={true}
                                        format="hh:mm A"
                                        value={quietHoursEnd}
                                        onChange={this.handleQuietHoursEndChange}
                                        onSelect={this.handleQuietHoursEndChange}
                                    />
                                    <span> in subscriber’s time zone.</span>
                                </div>
                            )}
                        </Well>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected handleQuietHoursEnabledChange = (quietHoursEnabled: boolean) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        config.setQuietHoursStart(undefined)
        config.setQuietHoursEnd(undefined)

        this.context.setCampaign(campaign)
        this.context.setFlags({ quietHoursEnabled })
    }

    protected handleQuietHoursStartChange = (start: Moment | null) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        config.setQuietHoursStart(start?.format('HH:mm'))

        this.context.setCampaign(campaign)
    }

    protected handleQuietHoursEndChange = (end: Moment | null) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        config.setQuietHoursEnd(end?.format('HH:mm'))

        this.context.setCampaign(campaign)
    }
}
