import * as React from 'react'
import { Button, Well, Drawer, executeCleanDrawerRender } from '@pushly/aqe/lib/components'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, notification, Select, Skeleton, Switch, Radio } from 'antd'
import { isDef } from '@pushly/aqe/lib/utils/is-def'
import titleCase from 'title-case'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Container } from 'typescript-ioc/es5'
import clone from 'clone'
import * as deepEqual from 'fast-deep-equal'
import * as randomstring from 'randomstring'
import { DomainDto } from '../../dtos/domain'
import { AppState } from '../../stores/app'
import { AppService, DomainService } from '../../services'
import {
    calculateToPromptFcapSeconds,
    getFcapAndReferrerConfigs,
    prepareGlobalPromptSettings,
} from '../../_utils/fcap-and-referrer-configs'
import { getClassNames } from '../../_utils/classnames'
import { delay, numberWithCommas, simpleFormErrorNotification, simpleNotification } from '../../_utils/utils'
import { PromptReferrerAllowType } from '../../dtos/domain'
import { TypedTagSelect } from '../../features/sandbox/components/typed-tag-select/typed-tag-select'
import { validateFields } from '../../_utils/antd'
import { StatusBadge } from '../badges/status-badge'

interface IDomainPromptEditor extends FormComponentProps {
    domain: DomainDto
    visible: boolean
    onClose: (e: any) => void
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IDPEState {}

export const DomainPromptEditor = Form.create<IDomainPromptEditor>()(
    class extends React.Component<IDomainPromptEditor, IDPEState> {
        public state: IDPEState = {}

        protected appState: AppState
        protected appService: AppService
        protected domainService: DomainService

        public constructor(props: IDomainPromptEditor) {
            super(props)

            this.appState = Container.get(AppState)
            this.appService = Container.get(AppService)
            this.domainService = Container.get(DomainService)

            executeCleanDrawerRender(this)
        }

        public render() {
            const currentValues = this.getCurrentValues()
            const fcapsEnabled = this.props.form.getFieldValue('fcapsEnabled') ?? !!this.domain.frequencyCaps?.prompt
            const referrerConditionsEnabled =
                this.props.form.getFieldValue('referrerConditionsEnabled') ??
                this.domain.globalPromptSettings?.referrers?.enabled ??
                false
            const config = getFcapAndReferrerConfigs(this.domain)

            return (
                <Drawer
                    getContainer={this.appService.getAppContainer}
                    className={getClassNames('ddf-prompt-settings-editor')}
                    title="Update General Settings"
                    placement="right"
                    closable={true}
                    onSubmit={this.handleSubmit}
                    disableSubmit={Object.keys(currentValues).length === 0}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                >
                    <Form>
                        <Well title="Frequency Caps" mode="ghost" hideFooter={true}>
                            <Form.Item className="fcap-prompt-field" label="Prompt Frequency Cap">
                                <div className="fcap-prompt-row">
                                    <div className="fcap-prompt-left">
                                        {this.props.form.getFieldDecorator('fcapsEnabled', {
                                            initialValue: !!this.domain.frequencyCaps?.prompt,
                                        })(
                                            <Switch
                                                defaultChecked={!!this.domain.frequencyCaps?.prompt}
                                                onChange={async () =>
                                                    delay(() => {
                                                        this.forceUpdate()
                                                    }, 0.05)
                                                }
                                            />,
                                        )}
                                    </div>
                                    <div className="fcap-prompt-right">
                                        {fcapsEnabled && (
                                            <>
                                                <span>A visitor to my site should be prompted</span>
                                                <div className="fcap-prompt-right-1">
                                                    {this.props.form.getFieldDecorator('promptFcapOccurrences', {
                                                        initialValue: config.defaultPromptFcapOccurrences,
                                                        rules: [
                                                            {
                                                                pattern: /^[\d]+$/i,
                                                                message: 'Occurrence count must be numeric',
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    value >= 1
                                                                        ? callback()
                                                                        : callback(new Error(rule.message))
                                                                },
                                                                message: 'Occurrence count must be 1 or greater',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            size="small"
                                                            className="fcap-prompt-field-input"
                                                            placeholder="1"
                                                            autoComplete="off"
                                                            addonAfter="time(s) every"
                                                        />,
                                                    )}
                                                </div>
                                                <div className="fcap-prompt-right-2">
                                                    {this.props.form.getFieldDecorator('promptFcap', {
                                                        initialValue: config.defaultPromptFcapFrequency,
                                                        rules: [
                                                            {
                                                                pattern: /^[\d]+$/i,
                                                                message: 'Frequency must be numeric',
                                                            },
                                                            {
                                                                validator: (rule, value, callback) => {
                                                                    value >= 1 && value <= 3650
                                                                        ? callback()
                                                                        : callback(new Error(rule.message))
                                                                },
                                                                message: 'Frequency must be between 1 and 3,650',
                                                            },
                                                        ],
                                                    })(
                                                        <Input
                                                            size="small"
                                                            className="fcap-prompt-field-input"
                                                            placeholder="1"
                                                            autoComplete="off"
                                                            addonAfter={this.props.form.getFieldDecorator(
                                                                'promptFcapDisplayMetric',
                                                                {
                                                                    initialValue: config.defaultPromptFcapDisplayMetric,
                                                                },
                                                            )(
                                                                <Select className="fcap-prompt-metric-input">
                                                                    <Select.Option value="days">day(s)</Select.Option>
                                                                    <Select.Option value="hours">hour(s)</Select.Option>
                                                                    <Select.Option value="minutes">
                                                                        minute(s)
                                                                    </Select.Option>
                                                                </Select>,
                                                            )}
                                                        />,
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={getClassNames('form-layout-row-sub')}>
                                    <InfoCircleOutlined />
                                    <span>
                                        The frequency cap will determine how often a user that does not interact
                                        (dismiss or allow) with a prompt will be prompted again to subscribe.
                                    </span>
                                </div>
                            </Form.Item>
                        </Well>

                        <Well title="Referrer Conditions" mode="ghost" hideFooter={true}>
                            <Form.Item className="referrer-field" label="Prompt Referrer Conditions">
                                <div className="referrer-field-row">
                                    {this.props.form.getFieldDecorator('referrerConditionsEnabled', {
                                        initialValue: this.domain.globalPromptSettings?.referrers?.enabled ?? false,
                                    })(
                                        <Switch
                                            defaultChecked={
                                                this.domain.globalPromptSettings?.referrers?.enabled ?? false
                                            }
                                            onChange={async () =>
                                                delay(() => {
                                                    this.forceUpdate()
                                                }, 0.05)
                                            }
                                        />,
                                    )}
                                </div>
                                {referrerConditionsEnabled && (
                                    <React.Fragment>
                                        <div className="referrer-field-row collapse">
                                            <div className="referrer-field-left">
                                                <h4>When referrer is blank?</h4>
                                                {this.props.form.getFieldDecorator('referrerWhenBlank', {
                                                    initialValue: config.defaultReferrerWhenBlank,
                                                })(
                                                    <Radio.Group size="small" className="referrer-when-blank-group">
                                                        <Radio.Button value={PromptReferrerAllowType.IGNORE}>
                                                            {PromptReferrerAllowType.IGNORE}
                                                        </Radio.Button>
                                                        <Radio.Button value={PromptReferrerAllowType.ALLOW}>
                                                            {PromptReferrerAllowType.ALLOW}
                                                        </Radio.Button>
                                                        <Radio.Button value={PromptReferrerAllowType.BLOCK}>
                                                            {PromptReferrerAllowType.BLOCK}
                                                        </Radio.Button>
                                                    </Radio.Group>,
                                                )}
                                            </div>
                                        </div>
                                        <div className="referrer-field-row">
                                            <div className="referrer-field-left">
                                                <h4>Allowed referrers:</h4>
                                                {this.props.form.getFieldDecorator('referrerAllow', {
                                                    initialValue: config.defaultReferrerAllowValue,
                                                })(
                                                    <TypedTagSelect
                                                        className="referrer-allow"
                                                        types={['exact', 'contains']}
                                                    />,
                                                )}
                                            </div>
                                            <div className="referrer-field-right">
                                                <h4>Blocked referrers:</h4>
                                                {this.props.form.getFieldDecorator('referrerBlock', {
                                                    initialValue: config.defaultReferrerBlockValue,
                                                })(
                                                    <TypedTagSelect
                                                        className="referrer-block"
                                                        types={['exact', 'contains']}
                                                    />,
                                                )}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className={getClassNames('form-layout-row-sub')}>
                                    <InfoCircleOutlined />
                                    <span>
                                        Referrer conditions determine if a user is able to see a prompt based on where
                                        they arrived from.
                                    </span>
                                </div>
                            </Form.Item>
                        </Well>
                    </Form>
                </Drawer>
            )
        }

        protected get domain(): DomainDto {
            return this.props.domain
        }

        protected getCurrentValues(): Partial<DomainDto> {
            const values: Partial<DomainDto> = {}
            const formValues = this.props.form.getFieldsValue()

            const fcapsEnabled = formValues.fcapsEnabled
            if (fcapsEnabled) {
                values.frequencyCaps = {
                    prompt: {
                        occurrences: formValues.promptFcapOccurrences,
                        fcapSeconds: calculateToPromptFcapSeconds(formValues),
                        displayMetric: formValues.promptFcapDisplayMetric,
                    },
                }
            } else {
                values.frequencyCaps = null as any
            }
            if (deepEqual(values.frequencyCaps, this.domain.frequencyCaps)) {
                delete values.frequencyCaps
            }

            const globalPromptSettings = prepareGlobalPromptSettings(
                formValues,
                clone(this.domain.globalPromptSettings),
            )
            if (!deepEqual(globalPromptSettings, this.domain.globalPromptSettings)) {
                values.globalPromptSettings = globalPromptSettings
            }

            return values
        }

        protected handleSubmit = async () => {
            try {
                await validateFields(this.props.form)
                const values = this.getCurrentValues()

                const { ok, data } = await this.domainService.updateDomainById(this.domain.id, values)

                if (ok) {
                    this.props.onDomainUpdated(data)
                    this.props.onClose(null)
                }
            } catch (err) {
                simpleFormErrorNotification(err)
            }
        }
    },
)

interface IGeneralSettingsWell {
    loading?: boolean
    domain: DomainDto
    title?: string
    onDomainUpdated: (domain: Partial<DomainDto>) => void
}

interface IState {
    showDrawer: boolean
    drawerKey?: string
}

export class GeneralSettingsWell extends React.Component<IGeneralSettingsWell, IState> {
    public state: IState = {
        showDrawer: false,
    }

    public render() {
        return (
            <Well
                className="nested"
                title="General Settings"
                actions={
                    <Button size="small" onClick={this.handleDrawerOpen} disabled={this.props.loading}>
                        <EditOutlined />
                        <span>Edit</span>
                    </Button>
                }
                hideFooter={true}
            >
                <Skeleton loading={this.props.loading} active={true} title={false}>
                    {!this.props.loading && (
                        <>
                            {this.renderFrequencyCaps()}
                            {this.renderReferrerConditions()}

                            <DomainPromptEditor
                                key={this.state.drawerKey}
                                visible={this.state.showDrawer}
                                domain={this.domain}
                                onClose={this.handleDrawerClose}
                                onDomainUpdated={this.props.onDomainUpdated}
                            />
                        </>
                    )}
                </Skeleton>
            </Well>
        )
    }

    protected renderFrequencyCaps() {
        const enabled = !!this.domain.frequencyCaps?.prompt
        const config = getFcapAndReferrerConfigs(this.domain)

        return (
            <Well title="Frequency Caps" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>
                        <StatusBadge type="generic" expanded={true} status={enabled ? 'ENABLED' : 'DISABLED'} />
                    </div>
                </div>

                {enabled && (
                    <>
                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Prompt visitors to my site</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {config.defaultPromptFcapOccurrences} time(s) every{' '}
                                {numberWithCommas(config.defaultPromptFcapFrequency)}{' '}
                                {config.defaultPromptFcapDisplayMetric.replace(/s$/, '(s)')}
                            </div>
                        </div>
                    </>
                )}
            </Well>
        )
    }

    protected renderReferrerConditions() {
        const enabled = this.domain.globalPromptSettings?.referrers?.enabled ?? false
        const config = getFcapAndReferrerConfigs(this.domain)

        return (
            <Well title="Referrer Conditions" mode="ghost" hideFooter={true}>
                <div className={getClassNames('form-layout-row')}>
                    <div className={getClassNames('form-layout-row-label')}>
                        <StatusBadge type="generic" expanded={true} status={enabled ? 'ENABLED' : 'DISABLED'} />
                    </div>
                </div>

                {enabled && (
                    <>
                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Blank Referrer Behavior</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {titleCase(config.defaultReferrerWhenBlank)}
                            </div>
                        </div>
                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Allowed Referrers</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {!isDef(config.defaultReferrerAllowValue)
                                    ? 'All Referrers'
                                    : config.defaultReferrerAllowValue.map((ref: any) => (
                                          <div key={ref.value}>
                                              <span>
                                                  {ref.type}: {ref.value}
                                              </span>
                                          </div>
                                      ))}
                            </div>
                        </div>
                        <div className={getClassNames('form-layout-row')}>
                            <div className={getClassNames('form-layout-row-label')}>Blocked Referrers</div>
                            <div className={getClassNames('form-layout-row-value')}>
                                {!isDef(config.defaultReferrerBlockValue)
                                    ? 'No Referrers'
                                    : config.defaultReferrerBlockValue.map((ref: any) => (
                                          <div key={ref.value}>
                                              <span>
                                                  {ref.type}: {ref.value}
                                              </span>
                                          </div>
                                      ))}
                            </div>
                        </div>
                    </>
                )}
            </Well>
        )
    }

    protected get domain(): DomainDto {
        return this.props.domain
    }

    protected handleDrawerOpen = async () => {
        return this.setState({ showDrawer: true, drawerKey: randomstring.generate() })
    }

    protected handleDrawerClose = async () => {
        return this.setState({ showDrawer: false })
    }

    protected handleCopied = (field: string) => {
        notification.destroy()
        simpleNotification('success', `Your ${field} has been copied!`)
    }
}
