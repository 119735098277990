import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationActionModel } from './notification-action.model'
import { NotificationExperienceModel } from './notification-experience.model'
import { INotificationFieldSuggestions } from '../../interfaces/notification-field-suggestions'

export class NotificationDefaultContentModel {
    public static build(props: NotificationDefaultContentModel | any): NotificationDefaultContentModel {
        const data = props instanceof NotificationDefaultContentModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new NotificationDefaultContentModel()
        model.setTitle(scData.title)
        model.setBody(scData.body)
        model.setKeywords(scData.keywords)
        model.setLandingUrl(scData.landing_url)
        model.setNativeAltUrl(scData.alt_native_url)
        model.setImageUrl(scData.image_url)
        model.setIconUrl(scData.icon_url)
        model.setIsUsingDomainDefaultIcon(scData.is_using_domain_default_icon)
        model.setSegmentDefaultIconUrl(data._segment_default_icon_url)
        model.setBadgeUrl(scData.badge_url)
        model.setIsUsingDomainDefaultBadge(scData.is_using_domain_default_badge)
        model.setActions(scData.actions)
        model.setCustomActionsEnabled(data._custom_actions_enabled)
        model.setExperienceOptions(scData.experience_options)
        model.setContentWebhookUrl(scData.content_webhook_url)
        model.setOverrideFallbackTemplate(scData.override_fallback_template)
        model.setAutoSuggestedFieldsResults(scData.auto_suggested_fields_results)

        return model
    }

    private title: string | undefined
    private body: string | undefined | null
    private keywords: string[] | undefined
    private landingUrl: string | undefined
    private altNativeUrl: string | undefined
    private imageUrl: string | undefined | null
    private iconUrl: string | undefined | null
    private isUsingDomainDefaultIcon: boolean
    private badgeUrl: string | undefined | null
    private isUsingDomainDefaultBadge: boolean
    private actions: NotificationActionModel[] | undefined | null
    private experienceOptions: NotificationExperienceModel
    private contentWebhookUrl: string | null
    private overrideFallbackTemplate: boolean
    private autoSuggestedFieldsResults: INotificationFieldSuggestions | null
    private _segment_default_icon_url?: string
    private _custom_actions_enabled?: boolean

    public clone(): NotificationDefaultContentModel {
        return NotificationDefaultContentModel.build(this)
    }

    public serialize(): any {
        return stripUndefined({
            title: this.getTitle(),
            body: this.getBody(),
            keywords: this.getKeywords(),
            landing_url: this.getLandingUrl(),
            alt_native_url: this.getNativeAltUrl(),
            image_url: this.getImageUrl(),
            icon_url: this.getIconUrl(),
            is_using_domain_default_icon: this.getIsUsingDomainDefaultIcon(),
            badge_url: this.getBadgeUrl(),
            is_using_domain_default_badge: this.getIsUsingDomainDefaultBadge(),
            actions: this.getActions()?.map((a) => a.serialize()),
            experience_options: this.getExperienceOptions().serialize(),
            content_webhook_url: this.getContentWebhookUrl(),
            override_fallback_template: this.getOverrideFallbackTemplate(),
            auto_suggested_fields_results: this.getAutoSuggestedFieldsResults(),
            _segment_default_icon_url: this.getSegmentDefaultIconUrl(),
            _custom_actions_enabled: this.getCustomActionsEnabled(),
        })
    }

    public getTitle(): string | undefined {
        return this.title
    }
    public setTitle(title: string | undefined): void {
        this.title = title
    }

    public getBody(): string | undefined | null {
        return this.body
    }
    public setBody(body: string | undefined): void {
        this.body = body
    }

    public getKeywords(): string[] | undefined {
        return this.keywords
    }
    public setKeywords(keywords: string[] | undefined): void {
        this.keywords = keywords
    }

    public getLandingUrl(): string | undefined {
        return this.landingUrl
    }
    public setLandingUrl(landingUrl: string | undefined): void {
        this.landingUrl = landingUrl
    }

    public getNativeAltUrl(): string | undefined {
        return this.altNativeUrl
    }
    public setNativeAltUrl(nativeAltUrl: string | undefined): void {
        // null may be returned from API on native landing url fields, but should not be set as the alt native url field
        this.altNativeUrl = nativeAltUrl === null ? undefined : nativeAltUrl
    }

    public getIconUrl(): string | undefined | null {
        return this.iconUrl
    }
    public setIconUrl(iconUrl: string | undefined | null, isSegmentIcon: boolean = false): void {
        this.iconUrl = iconUrl

        if (iconUrl && isSegmentIcon) {
            this.setSegmentDefaultIconUrl(iconUrl)
        } else {
            this.setSegmentDefaultIconUrl(undefined)
        }
    }

    public getIsUsingDomainDefaultIcon() {
        return this.isUsingDomainDefaultIcon
    }
    public setIsUsingDomainDefaultIcon(value: boolean) {
        this.isUsingDomainDefaultIcon = value ?? false
    }

    public getIsUsingDomainDefaultBadge() {
        return this.isUsingDomainDefaultBadge
    }
    public setIsUsingDomainDefaultBadge(value: boolean) {
        this.isUsingDomainDefaultBadge = value ?? false
    }

    public getSegmentDefaultIconUrl(): string | undefined {
        return this._segment_default_icon_url
    }
    public setSegmentDefaultIconUrl(url: string | undefined) {
        this._segment_default_icon_url = url
    }

    public getImageUrl(): string | undefined | null {
        return this.imageUrl
    }
    public setImageUrl(imageUrl: string | undefined | null): void {
        this.imageUrl = imageUrl
    }

    public getBadgeUrl(): string | undefined | null {
        return this.badgeUrl
    }
    public setBadgeUrl(badgeUrl: string | undefined | null): void {
        this.badgeUrl = badgeUrl
    }

    public getActions(): NotificationActionModel[] | undefined | null {
        return this.actions
    }
    public setActions(actions: NotificationActionModel[] | undefined | null): void {
        this.actions = actions?.map(NotificationActionModel.build)
    }

    public getCustomActionsEnabled(): boolean {
        return this._custom_actions_enabled ?? false
    }
    public setCustomActionsEnabled(enabled: boolean | undefined): void {
        this._custom_actions_enabled = enabled
    }

    public getExperienceOptions(): NotificationExperienceModel {
        return this.experienceOptions
    }
    public setExperienceOptions(options: NotificationExperienceModel | undefined): void {
        this.experienceOptions = NotificationExperienceModel.build(options ?? {})
    }

    public getContentWebhookUrl(): string | null {
        return this.contentWebhookUrl ?? null
    }
    public setContentWebhookUrl(url: string | undefined | null) {
        this.contentWebhookUrl = url ?? null
    }

    public getOverrideFallbackTemplate(): boolean {
        return this.overrideFallbackTemplate ?? false
    }
    public setOverrideFallbackTemplate(override: boolean) {
        this.overrideFallbackTemplate = override ?? false
    }

    public getAutoSuggestedFieldsResults(): INotificationFieldSuggestions | null {
        return this.autoSuggestedFieldsResults ?? null
    }
    public setAutoSuggestedFieldsResults(suggestions: INotificationFieldSuggestions | null) {
        this.autoSuggestedFieldsResults = suggestions ?? null
    }
}
