export const getPathClassName = (route: string) => {
    return (
        route
            .trim()
            .toLowerCase()
            .replace(/(^\/+)|(\/+$)/g, '')
            .replace(/\/[\d]+/g, '')
            .replace(/\/+/g, '-')
            .replace(/-$/, '') || 'root'
    )
}
