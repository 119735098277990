import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Select, Tooltip } from 'antd'
import autobind from 'autobind-decorator'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'
import { IRuleBuilderField } from '../../../rule-builder/rule-builder'
import { IRuleGroup } from '../../interfaces/rule-group'
import * as clone from 'clone'
import { CampaignDto } from '../../../../features/campaigns/dtos/campaign-dto'
import { BetterSelect } from '../../../better-select/better-select'
import { AppService } from '../../../../services/index'
import { Container } from 'typescript-ioc/es5'
import * as randomstring from 'randomstring'
import { RuleNodeType } from '../../enums/rule-node-type'
import { CampaignV2Service } from '../../../../services/campaign-v2'
import { type IBetterSelectOption } from '../../../better-select/interfaces'
import './campaign.input.scss'
import { TriggerType } from '../../../campaign-builder/enums'

interface ICampaignInputProps {
    field: IRuleBuilderField
    rule: IRule
    onChange: (value: any) => any
    mode?: 'edit' | 'display'
}

interface ICampaignInputState {
    campaigns: CampaignDto[]
}

export class CampaignInput extends BetterComponent<ICampaignInputProps, ICampaignInputState> {
    public static readonly statusOptions = [
        { value: 'active', label: 'Active' },
        { value: 'completed', label: 'Completed' },
        { value: 'removed', label: 'Removed' },
    ]

    public readonly defaultClassName = 'sw-v2-rb-campaign-input'
    public ref: any

    protected appService: AppService
    protected campaignService: CampaignV2Service

    public constructor(props: ICampaignInputProps) {
        super(props)

        this.appService = Container.get(AppService)
        this.campaignService = Container.get(CampaignV2Service)

        this.state = {
            campaigns: [],
        }
    }

    public componentDidMount(): void {
        this.ensureCampaigns()
    }

    public render(): React.ReactNode {
        const currentCampaignValue = this.currentCampaignValue
        const currentSelectionTypeValue = this.currentSelectionTypeValue
        const isSpecificRule = currentSelectionTypeValue === 'specific'
        const isTriggerTypeRule = currentSelectionTypeValue === 'triggered'

        const tooltip = (
            <span>
                <Tooltip
                    overlayClassName="campaign-status-tooltip"
                    title={
                        <div>
                            The following statuses match all subscribers who currently meet the stated requirements:
                            <ul>
                                <li>Entered: Entered the campaign regardless of their current status.</li>
                                <li>Active: Are currently active in the campaign.</li>
                                <li>
                                    Removed: Were removed from the campaign before it completed or were still active in
                                    a campaign that was configured to set all active members' status to “Removed” when
                                    it was completed.
                                </li>
                                <li>
                                    Completed: Completed the campaign or were still active in a campaign that was
                                    configured to set all active members' status to “Completed” when it finished.
                                </li>
                            </ul>
                        </div>
                    }
                >
                    <InfoCircleOutlined className="info-icon" />
                </Tooltip>
            </span>
        )

        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                {this.isDisplayMode ? (
                    <div className={this.buildClassName('display')}>
                        <span>
                            <span className="operator-display"> for</span>
                            <span className="selection-type-display"> {this.currentSelectionTypeDisplay}</span>
                            <span>&nbsp;</span>

                            {isTriggerTypeRule && this.currentTriggerTypeDisplay}
                            {isSpecificRule && this.nameDisplay}
                        </span>
                        <span>
                            <span className="operator-display"> {this.operatorDisplay} </span>
                            {this.statusDisplay}
                        </span>

                        {tooltip}
                    </div>
                ) : (
                    <div
                        className={`${this.buildClassName('editor')} selection-type-${
                            this.currentSelectionTypeValue ?? 'none'
                        }`}
                    >
                        <div> for </div>
                        <div className={this.buildClassName('selection-type-select')}>
                            <Select
                                dropdownClassName={this.buildClassName('selection-type-dropdown')}
                                onChange={this.handleSelectionTypeChange}
                                value={this.currentSelectionTypeValue}
                            >
                                <Select.Option value="any">Any Campaign</Select.Option>
                                <Select.Option value="triggered">Campaign with Trigger</Select.Option>
                                <Select.Option value="specific">Specific Campaign</Select.Option>
                            </Select>
                        </div>
                        <div className={this.buildClassName('trigger-type-select')}>
                            {isTriggerTypeRule && (
                                <Select
                                    dropdownClassName={this.buildClassName('trigger-type-dropdown')}
                                    placeholder="Select a Trigger"
                                    onChange={this.handleTriggerTypeChange}
                                    value={this.currentTriggerTypeValue}
                                >
                                    <Select.Option value={TriggerType.SUBSCRIBED}>
                                        Subscribing to Push Notifications
                                    </Select.Option>
                                    <Select.Option value={TriggerType.ABANDONED_CART}>Abandoning Cart</Select.Option>
                                    <Select.Option value={TriggerType.ABANDONED_VIEW}>Abandoning Browse</Select.Option>
                                </Select>
                            )}
                        </div>
                        <div className={this.buildClassName('campaign-select')}>
                            {isSpecificRule && (
                                <BetterSelect
                                    dropdownClassName={this.buildClassName('campaign-dropdown')}
                                    mode="single"
                                    placeholder="Select a Campaign"
                                    value={!!currentCampaignValue ? [currentCampaignValue] : undefined}
                                    onChange={this.handleCampaignSelectChange}
                                    options={this.campaignOptions}
                                    disableSearch={this.campaignOptions.length < 7}
                                    disableSelectAll={true}
                                />
                            )}
                        </div>
                        <div className={this.buildClassName('operator')}>
                            <Select
                                dropdownClassName={this.buildClassName('operator-dropdown')}
                                onChange={this.handleOperatorChange}
                                value={this.currentOperatorValue}
                            >
                                <Select.Option key="eq" value="eq">
                                    is equal to
                                </Select.Option>
                                <Select.Option key="neq" value="neq">
                                    is not equal to
                                </Select.Option>
                            </Select>
                        </div>
                        <div className={this.buildClassName('status-select')}>
                            <BetterSelect
                                dropdownClassName={this.buildClassName('status-dropdown')}
                                mode="multiple"
                                placeholder="Select a Status"
                                value={this.currentStatusValue}
                                onChange={this.handleStatusChange}
                                options={CampaignInput.statusOptions}
                                disableSearch={true}
                                disableSelectAll={true}
                                selectAllLabel="Entered"
                                maxDisplayCount={1}
                                maxDisplayFormatter={(options) => {
                                    return (
                                        <Tooltip title={this.statusDisplay}>
                                            <span>{options.length} Statuses</span>
                                        </Tooltip>
                                    )
                                }}
                            />
                        </div>

                        {tooltip}
                    </div>
                )}
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        const selectionType = this.currentSelectionTypeValue

        if (!this.currentStatusValue || this.currentStatusValue.length === 0) {
            response.ok = false
            response.error = 'Please select at least one valid status.'
        } else if (selectionType === 'specific' && !this.currentCampaignValue) {
            response.ok = false
            response.error = 'Please select a valid campaign.'
        } else if (selectionType === 'triggered' && !this.currentTriggerTypeValue) {
            response.ok = false
            response.error = 'Please select a valid trigger.'
        }

        return response
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get idGroupIndex(): number {
        return this.rule.value.findIndex((group: IRuleGroup) => {
            const ruleIdx = group.rules.findIndex((r) => r.rule.property === 'id')
            return ruleIdx !== -1
        })
    }

    protected get triggerTypeGroupIndex(): number {
        return this.rule.value.findIndex((group: IRuleGroup) => {
            const ruleIdx = group.rules.findIndex((r) => r.rule.property === 'computed_trigger_type')
            return ruleIdx !== -1
        })
    }

    protected get statusGroupIndex(): number {
        return this.rule.value.findIndex((group: IRuleGroup) => {
            const ruleIdx = group.rules.findIndex((r) => r.rule.property === 'status')
            return ruleIdx !== -1
        })
    }

    protected get idRules(): IRule[] {
        const groupIdx = this.idGroupIndex
        return groupIdx !== -1 ? clone(this.rule.value[groupIdx].rules) : []
    }

    protected get triggerTypeRules(): IRule[] {
        const groupIdx = this.triggerTypeGroupIndex
        return groupIdx !== -1 ? clone(this.rule.value[groupIdx].rules) : []
    }

    protected get statusRules(): IRule[] {
        const groupIdx = this.statusGroupIndex
        return groupIdx !== -1 ? clone(this.rule.value[groupIdx].rules) : []
    }

    protected get nameDisplay(): React.ReactNode {
        const values = this.idRules.map((r) => r.rule.meta.$name || r.rule.value)

        let display = 'No Campaign Selected'
        if (values.length > 0) {
            if (values.length > 2) {
                const finalValue = values.pop()
                display = values.join(', ') + `, or ${finalValue}`
            } else {
                display = values.join(' or ')
            }
        }

        return display
    }

    protected get currentSelectionTypeValue(): string {
        const idRules = this.idRules
        const triggerTypeRules = this.triggerTypeRules

        let value = 'any'
        if (idRules.length > 0) {
            value = 'specific'
        } else if (triggerTypeRules.length > 0) {
            value = 'triggered'
        }

        return value
    }

    protected get currentSelectionTypeDisplay(): string {
        const type = this.currentSelectionTypeValue

        return type === 'any' ? 'Any Campaign' : type === 'triggered' ? 'Campaign with Trigger' : ''
    }

    protected get currentTriggerTypeDisplay(): string {
        const type = this.currentTriggerTypeValue

        return type === TriggerType.SUBSCRIBED
            ? 'Subscribing to Push Notifications'
            : type === TriggerType.ABANDONED_CART
            ? 'Abandoning Cart'
            : 'Abandoning Browse'
    }

    protected get statusDisplay(): React.ReactNode {
        const values = this.currentStatusValue

        let display = 'No Status Selected'
        if (values.length > 0) {
            if (values.length === CampaignInput.statusOptions.length) {
                display = 'Entered'
            } else if (values.length > 2) {
                const finalValue = values.pop()
                display = values.join(', ') + `, or ${finalValue}`
            } else {
                display = values.join(' or ')
            }
        }

        return display
    }

    protected get operatorDisplay(): string {
        const operator = this.currentOperatorValue
        return operator === 'eq' ? 'is equal to' : 'is not equal to'
    }

    protected get currentOperatorValue(): string {
        const statusRules = this.statusRules
        const idRules = this.idRules

        let value = 'eq'

        if (idRules.length > 0) value = idRules[0].rule.operator

        if (statusRules.length > 0) value = statusRules[0].rule.operator

        return value
    }

    protected get currentStatusValue(): string[] {
        const statusRules = this.statusRules
        const operator = this.currentOperatorValue

        const value =
            statusRules.length > 0
                ? statusRules.map((r) => r.rule.value)
                : operator === 'neq'
                ? CampaignInput.statusOptions.map((o) => o.value)
                : []

        return value
    }

    protected get currentTriggerTypeValue(): string | undefined {
        const triggerRules = this.triggerTypeRules

        return triggerRules.length === 0 ? undefined : triggerRules[0].rule.value
    }

    protected get currentCampaignValue(): number | undefined {
        const idRules = this.idRules

        return idRules.length === 0 ? undefined : idRules[0].rule.value
    }

    protected get campaignOptions(): any[] {
        return this.state.campaigns.map((c) => ({
            value: c.id,
            label: c.name,
        }))
    }

    protected handleSelectionTypeChange = async (selectionType: string) => {
        const rules = clone(this.rule.value)
        const isAnyType = selectionType === 'any'
        const isSpecificType = selectionType === 'specific'
        const isTriggeredType = selectionType === 'triggered'

        // remove previous type rules
        const idGroupIdx = this.idGroupIndex
        if (idGroupIdx !== -1) {
            rules.splice(idGroupIdx, 1)
        }
        const triggerTypeGroupIdx = this.triggerTypeGroupIndex
        if (triggerTypeGroupIdx !== -1) {
            rules.splice(triggerTypeGroupIdx, 1)
        }

        if (isSpecificType || isTriggeredType) {
            const rule = {
                id: randomstring.generate(),
                conditionType: RuleNodeType.OR,
                rule: {
                    builderProperty: 'id',
                    property: 'id',
                    operator: 'eq',
                    value: undefined,
                    meta: {
                        $name: undefined,
                    },
                },
            }

            if (isTriggeredType) {
                rule.rule.builderProperty = 'computed_trigger_type'
                rule.rule.property = 'computed_trigger_type'
            }

            rules.unshift({
                id: randomstring.generate(),
                conditionType: RuleNodeType.AND,
                rules: [rule],
            })
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleOperatorChange(value: string): Promise<void> {
        const rules = clone(this.rule.value)
        const idGroupIdx = this.idGroupIndex
        const statusRules = this.statusRules
        const statusGroupIdx = this.statusGroupIndex
        const totalStatuses = CampaignInput.statusOptions.length

        if (idGroupIdx !== -1) {
            if (value === 'neq' && (statusRules.length === totalStatuses || statusRules.length === 0))
                rules[idGroupIdx].rules[0].rule.operator = value
            else rules[idGroupIdx].rules[0].rule.operator = 'eq'
        }

        if (statusRules.length > 0) {
            if (value === 'neq' && statusRules.length === totalStatuses) rules.splice(statusGroupIdx, 1)
            else rules[statusGroupIdx].rules.forEach((r: IRule) => (r.rule.operator = value))
        } else {
            if (value === 'eq' && this.currentOperatorValue === 'neq') {
                rules.push({
                    id: randomstring.generate(),
                    conditionType: RuleNodeType.AND,
                    rules: CampaignInput.statusOptions.map((o) => ({
                        id: randomstring.generate(),
                        conditionType: RuleNodeType.OR,
                        rule: {
                            builderProperty: 'status',
                            property: 'status',
                            operator: value,
                            value: o.value,
                        },
                    })),
                })
            }
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleStatusChange(values: string[]): Promise<void> {
        const rules = clone(this.rule.value)
        const idGroupIdx = this.idGroupIndex
        const statusGroupIdx = this.statusGroupIndex
        const operator = this.currentOperatorValue
        const totalStatuses = CampaignInput.statusOptions.length

        if (statusGroupIdx !== -1) rules.splice(statusGroupIdx, 1)

        if (idGroupIdx !== -1) rules[idGroupIdx].rules[0].rule.operator = operator

        if (values.length > 0) {
            rules.push({
                id: randomstring.generate(),
                conditionType: RuleNodeType.AND,
                rules: values.map((value) => ({
                    id: randomstring.generate(),
                    conditionType: RuleNodeType.OR,
                    rule: {
                        builderProperty: 'status',
                        property: 'status',
                        operator,
                        value,
                    },
                })),
            })

            if (idGroupIdx !== -1) {
                rules[idGroupIdx].rules[0].rule.operator = 'eq'
            }
        }

        this.emitChange(rules)
    }

    protected handleTriggerTypeChange = async (triggerType: TriggerType) => {
        const rules = clone(this.rule.value)

        const groupIdx = this.triggerTypeGroupIndex
        if (groupIdx !== -1) rules.splice(groupIdx, 1)

        rules.unshift({
            id: randomstring.generate(),
            conditionType: RuleNodeType.AND,
            rules: [
                {
                    id: randomstring.generate(),
                    conditionType: RuleNodeType.OR,
                    rule: {
                        builderProperty: 'computed_trigger_type',
                        property: 'computed_trigger_type',
                        operator: 'eq',
                        value: triggerType,
                        meta: {},
                    },
                },
            ],
        })

        this.emitChange(rules)
    }

    @autobind
    protected async handleCampaignSelectChange(campaignId: number, option: IBetterSelectOption): Promise<void> {
        const rules = clone(this.rule.value)

        const groupIdx = this.idGroupIndex
        if (groupIdx !== -1) rules.splice(groupIdx, 1)

        if (!!campaignId) {
            rules.unshift({
                id: randomstring.generate(),
                conditionType: RuleNodeType.AND,
                rules: [
                    {
                        id: randomstring.generate(),
                        conditionType: RuleNodeType.OR,
                        rule: {
                            builderProperty: 'id',
                            property: 'id',
                            operator: 'eq',
                            value: campaignId,
                            meta: {
                                $name: option.label,
                            },
                        },
                    },
                ],
            })
        }

        this.emitChange(rules)
    }

    @autobind
    protected async handleCampaignChange(campaignId: number, campaign: CampaignDto): Promise<void> {
        const rules = clone(this.rule.value)

        const groupIdx = this.idGroupIndex
        if (groupIdx !== -1) rules.splice(groupIdx, 1)

        if (campaignId) {
            rules.unshift({
                id: randomstring.generate(),
                conditionType: RuleNodeType.AND,
                rules: [
                    {
                        id: randomstring.generate(),
                        conditionType: RuleNodeType.OR,
                        rule: {
                            builderProperty: 'id',
                            property: 'id',
                            operator: 'eq',
                            value: campaignId,
                            meta: {
                                $name: campaign.name,
                            },
                        },
                    },
                ],
            })
        }

        this.emitChange(rules)
    }

    protected cleanRules(rules: IRuleGroup[]): any {
        const response: IRuleGroup[] = clone(rules)

        for (const group of rules) {
            for (const rule of group.rules) {
                if (!rule.rule || Object.keys(rule.rule).length === 0) {
                    const ruleIdx = group.rules.findIndex((r) => r.id === rule.id)
                    delete group.rules[ruleIdx]
                }
            }
        }

        return response
    }

    protected async emitChange(rules: IRuleGroup[]): Promise<void> {
        return this.props.onChange(this.cleanRules(rules))
    }

    protected async ensureCampaigns(): Promise<void> {
        const res = await this.campaignService.fetchAll({
            query: {
                pagination: 0,
            },
            showLoadingScreen: false,
            cancellationKey: 'rb.ensureCampaigns',
        })

        return this.setState({ campaigns: res.data })
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
