import * as React from 'react'
import styled from 'styled-components'
import { INode, INodeDefaultProps } from '@mrblenny/react-flow-chart'
import { CloseCircleFilled } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import {
    ActionType,
    CampaignEditableState,
    ConditionSubType,
    ConditionType,
    DelayType,
    NodeType,
    PortId,
    TriggerType,
} from './enums'
import titleCase from 'title-case'
import moment from 'moment-timezone'
import { calculateDelayQualifiedValue } from './helpers/delay-node'
import { CONDITION_BRANCH_ROOT, CURRENT_BUILDER_ITERATION, DEFAULT_CONDITION_BRANCH_ID } from './constants'
import { getClassNames } from '../../_utils/classnames'
import { CampaignDto } from '../../features/campaigns/dtos/campaign-dto'
import AmplyIcon from '../icons/amply-icon'
import IconWebhooks from '../icons/icon-webhooks'

const getNodeInnerDisplayConfig = (
    node: INode,
): {
    icon: any
    title: string
    description: string
    stats: any
} => {
    const icon: any = {}
    let title: any
    let description: any
    const stats: any = node.properties?.[node.type]?.stats

    if (node.type === NodeType.TRIGGER) {
        icon.type = 'play-circle'
        title = 'Campaign Entry'

        if (!!node.properties?.[node.type]) {
            const triggerType = node.properties[node.type].type
            const params = node.properties[node.type].params

            if (!triggerType) {
                description = 'Click to Configure'
            } else if (triggerType === TriggerType.SEGMENT_EVALUATION) {
                description = 'Recurring Schedule'
            } else if (triggerType === TriggerType.ABANDONED_CART) {
                description = 'E-Commerce: Abandoned Cart'
            } else if (triggerType === TriggerType.ABANDONED_VIEW) {
                description = 'E-Commerce: Abandoned Browse'
            } else if (triggerType === TriggerType.ADD_TO_CART) {
                description = 'E-Commerce: Add-to-Cart'
            } else if (triggerType === TriggerType.NOTIFICATION_CLICK) {
                description = 'Notification Click'
            } else if (triggerType === TriggerType.NOTIFICATION_IMPRESSION) {
                description = 'Notification Impression'
            } else if (triggerType === TriggerType.PROFILE) {
                description = 'Profile Update'
            } else if (triggerType === TriggerType.SUBSCRIBED) {
                description = 'User Subscribes'

                if (!!params.criteria) {
                    description += ' and matches criteria'
                }
            }
        }
    } else if (node.type === NodeType.DELAY) {
        icon.type = 'clock-circle'
        title = 'Delay'

        if (!!node.properties?.[node.type]) {
            const params = node.properties[node.type].params || {}

            if (node.properties[node.type].type === DelayType.RELATIVE) {
                const delaySeconds = params.delay_seconds || 86400
                const qualifier = params.qualifier ?? 'days'
                const excludedDays = params.excluded_days

                description = `${calculateDelayQualifiedValue(delaySeconds, qualifier)} ${qualifier.replace(
                    /s$/,
                    '(s)',
                )}`
                if (CURRENT_BUILDER_ITERATION >= 2 && !!excludedDays) {
                    description += ` but do not deliver on ${excludedDays
                        .map((dayStr: string) => moment().day(dayStr).format('ddd'))
                        .join(', ')}`
                }
            } else if (node.properties[node.type].type === DelayType.SPECIFIC) {
                title += ': Specific Date/Time'
            }
        }
    } else if (node.type === NodeType.ACTION) {
        icon.type = 'thunderbolt'
        icon.theme = 'filled'
        title = 'Action'

        if (!!node.properties?.[node.type]) {
            const params = node.properties[node.type].params || {}

            icon.theme = undefined

            if (node.properties[node.type].type === ActionType.SEND_NOTIFICATION) {
                icon.type = 'wifi'
                title = 'Send Notification'

                if (!!params.title) {
                    description = params.title
                }
            } else if (node.properties[node.type].type === ActionType.SEND_CART_NOTIFICATION) {
                icon.type = 'shopping-cart'
                title = 'Send Item Notification'

                if (!!params.title) {
                    description = params.title
                }
            } else if (node.properties[node.type].type === ActionType.SEND_AMPLY_COLLECTION_NOTIFICATION) {
                icon.component = AmplyIcon
                title = 'Send Collection Notification'

                if (!!params.pub_network_amply_collection_name) {
                    description = params.pub_network_amply_collection_name
                }
            } else if (node.properties[node.type].type === ActionType.SEND_CONTENT_WEBHOOK_NOTIFICATION) {
                icon.component = IconWebhooks
                title = 'Send Webhook Notification'
                description = params.notification?.template?.channels?.web?.contentWebhookUrl
            } else if (node.properties[node.type].type === ActionType.UPDATE_PROFILE) {
                title = 'Add Subscriber Keywords'
                description = params.tags?.join(', ')
            }
        }
    } else if (node.type === NodeType.CONDITION) {
        icon.type = 'apartment'
        title = 'Condition Set'

        if (!!node.properties?.[node.type]) {
            if (node.properties[node.type].sub_type === ConditionSubType.TRIGGERED) {
                if (node.properties[node.type].trigger_type === TriggerType.ADD_TO_CART) {
                    title += ': Add-to-Cart'
                } else if (node.properties[node.type].trigger_type === TriggerType.PROFILE) {
                    title += ': Profile Property'
                }
            }
        }
    } else if (node.type === NodeType.CAMPAIGN_EXIT) {
        icon.type = 'logout'
        title = 'Exit Campaign'

        if (CURRENT_BUILDER_ITERATION >= 3) {
            description = `Status: ${titleCase(node.properties?.[node.type]?.params?.exit_state ?? 'completed')}`
        }
    }

    return {
        icon,
        title,
        description,
        stats,
    }
}

interface INodeInner {
    getCampaign: () => CampaignDto
    getEditableState: () => CampaignEditableState
    onRemove: (node: INode) => void
    onBranchClick?: (node: INode, branchId: string) => void
}

export const NodeInner = (props: INodeInner) => {
    return ({ node, config, ...otherProps }: INodeDefaultProps, _ref: React.Ref<HTMLDivElement>) => {
        const Outer = styled.div.attrs({
            className: getClassNames('campaign-canvas-node-inner', {
                [`node-${node.id}`]: true,
                [`type-${node.type}`]: !!node.type,
                [node.properties?.[node.type]?.type]: !!node.properties?.[node.type]?.type,
                [`condition-type-${node.properties?.[NodeType.CONDITION]?.type}`]:
                    !!node.properties?.[NodeType.CONDITION],
                [`outgoing-ports-${Object.keys(node.ports).length - 1}`]: true,
                ['ineditable']: props.getEditableState() !== CampaignEditableState.EDITABLE,
            }),
        })`
            padding: 30px;
        `

        const display = getNodeInnerDisplayConfig(node)
        const isConditionNode = node.type === NodeType.CONDITION
        const conditionType = node.properties?.[NodeType.CONDITION]?.type
        const portKeys = Object.keys(node.ports)
        const outPortKeys = portKeys.filter((p) => p !== PortId.INPUT)

        return (
            <Outer>
                <div
                    className={getClassNames(null, {
                        ['node-content']: true,
                        ['with-description']: !!display.description,
                    })}
                >
                    <div className="remove-node-toggle">
                        <CloseCircleFilled
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onRemove(node)
                            }}
                        />
                    </div>
                    <LegacyIcon {...display.icon} />
                    <span className="node-title">{display.title}</span>
                    {display.description && <span className="node-description">{display.description}</span>}
                </div>
                {isConditionNode && conditionType === ConditionType.BRANCH && (
                    <div className="node-ports">
                        {outPortKeys.map((key) => {
                            const params = node.properties?.[node.type]?.[CONDITION_BRANCH_ROOT]?.find(
                                (c: any) => c.id === key,
                            )

                            return (
                                <span
                                    key={key}
                                    className="port"
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        ev.stopPropagation()
                                        props.onBranchClick?.(node, key)
                                    }}
                                >
                                    {key === DEFAULT_CONDITION_BRANCH_ID
                                        ? 'Default'
                                        : !params
                                        ? titleCase(key)
                                        : params.name}
                                </span>
                            )
                        })}
                    </div>
                )}
            </Outer>
        )
    }
}

export const buildShadowNode = (node: INode): Element => {
    const display = getNodeInnerDisplayConfig(node)
    const isConditionNode = node.type === NodeType.CONDITION
    const conditionType = node.properties?.[NodeType.CONDITION]?.type
    const portKeys = Object.keys(node.ports)
    const outPortKeys = portKeys.filter((p) => p !== PortId.INPUT)

    const primaryClassNames = {
        [`node-${node.id}`]: true,
        [`type-${node.type}`]: !!node.type,
        [`condition-type-${node.properties?.[NodeType.CONDITION]?.type}`]: !!node.properties?.[NodeType.CONDITION],
        [`outgoing-ports-${Object.keys(node.ports).length - 1}`]: true,
    }

    const Wrapper = document.createElement('div')
    Wrapper.className = getClassNames('campaign-canvas-node', primaryClassNames)

    const Outer = document.createElement('div')
    Wrapper.appendChild(Outer)
    Outer.className = getClassNames('campaign-canvas-node-inner', primaryClassNames)

    const Inner = document.createElement('div')
    Outer.appendChild(Inner)
    Inner.className = getClassNames(null, {
        ['node-content']: true,
        ['with-description']: !!display.description,
    })

    const RemoveToggle = document.createElement('div')
    Inner.appendChild(RemoveToggle)
    RemoveToggle.className = 'remove-node-toggle'

    const PseudoIcon = document.createElement('i')
    Inner.appendChild(PseudoIcon)
    PseudoIcon.className = 'anticon'

    const Title = document.createElement('span')
    Inner.appendChild(Title)
    Title.className = 'node-title'
    Title.innerText = display.title

    if (display.description) {
        const Description = document.createElement('span')
        Inner.appendChild(Description)
        Description.className = 'node-description'
        Description.innerText = display.description
    }

    if (isConditionNode && conditionType === ConditionType.BRANCH) {
        const PortsWrapper = document.createElement('div')
        Outer.appendChild(PortsWrapper)
        PortsWrapper.className = 'node-ports'

        outPortKeys.forEach((key) => {
            const params = node.properties?.[node.type]?.[CONDITION_BRANCH_ROOT]?.find((c: any) => c.id === key)

            const Port = document.createElement('span')
            PortsWrapper.appendChild(Port)
            Port.className = 'port'
            Port.innerText = key === DEFAULT_CONDITION_BRANCH_ID ? 'Default' : !params ? titleCase(key) : params.name
        })
    }

    if (!!display?.stats) {
        const StatsWrapper = document.createElement('div')
        Outer.appendChild(StatsWrapper)
        StatsWrapper.className = 'node-stats'

        const Stats = document.createElement('div')
        StatsWrapper.appendChild(Stats)
        Stats.className = 'node-stats-details'

        if (node.type === NodeType.TRIGGER && !!display.stats.entered) {
            const EnteredStat = document.createElement('span')
            Stats.appendChild(EnteredStat)
            EnteredStat.className = 'node-stats-entered'
            EnteredStat.innerHTML = `<span class="label">Entered:</span> {numberWithCommas(display.stats.entered)}`
        }
    }

    return Wrapper
}
