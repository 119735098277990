import * as React from 'react'
import { DomainDto } from '../../../dtos/domain'
import { Well } from '@pushly/aqe/lib/components'
import { getClassNames } from '../../../_utils/classnames'
import { Collapse, Skeleton } from 'antd'
import './styles/notification-channel-settings.scss'
import { ChannelSwitch } from '../../channel-switch/channel-switch'
import { NotificationIosContent } from './notification-ios-content'
import { NotificationNativeIosContentModel } from '../../../models/notification/notification-native-ios-content.model'
import { NotificationAndroidContent } from './notification-android-content'
import { NotificationNativeAndroidContentModel } from '../../../models/notification/notification-native-android-content.model'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'

interface INotificationChannelSettings {
    className?: string
    loading?: boolean
    title: string | React.ReactNode
    domain: DomainDto
    disabled?: boolean
    iosValue?: NotificationNativeIosContentModel
    androidValue?: NotificationNativeAndroidContentModel
    onChange: (value: NotificationNativeIosContentModel | NotificationNativeAndroidContentModel) => void
    hideNativeIos?: boolean
    hideNativeAndroid?: boolean
    hideEnableToggle: boolean
}

export const NotificationChannelSettings: React.FC<INotificationChannelSettings> = (
    props: INotificationChannelSettings,
) => {
    const {
        className,
        loading,
        title,
        iosValue,
        androidValue,
        hideNativeAndroid,
        hideNativeIos,
        hideEnableToggle,
        domain,
    } = props

    const [activeChannels, setActiveChannels] = React.useState<DeliveryChannel[]>(getEnabledDeliveryChannels(domain))

    const generateToggle = (channel: DeliveryChannel) => (
        <div onClick={(ev) => ev.stopPropagation()}>
            <ChannelSwitch
                className="selector-embedded"
                value={activeChannels}
                channel={channel}
                onChange={handleChannelChange}
                label="Enabled"
            />
        </div>
    )

    const handleChannelChange = (change: DeliveryChannel[]) => {
        setActiveChannels(change)
    }

    return (
        <Well
            className={getClassNames('notification-channel-settings', className + ' nested')}
            title={title}
            mode="ghost"
            hideHeader={false}
            hideFooter={true}
        >
            <Skeleton loading={loading} active={true} avatar={false} title={false}>
                <Collapse>
                    {!hideNativeIos && (
                        <Collapse.Panel
                            key="native-ios"
                            header="Native: iOS"
                            extra={!hideEnableToggle && generateToggle(DeliveryChannel.NATIVE_IOS)}
                        >
                            <NotificationIosContent
                                domain={domain}
                                value={iosValue!}
                                onChange={(v) => {
                                    const update = v.clone()

                                    props.onChange(update)
                                }}
                            />
                        </Collapse.Panel>
                    )}

                    {!hideNativeAndroid && false && (
                        <Collapse.Panel
                            key="native-android"
                            header="Native: Android"
                            extra={!hideEnableToggle && generateToggle(DeliveryChannel.NATIVE_ANDROID)}
                        >
                            <NotificationAndroidContent
                                value={androidValue}
                                onChange={(v) => {
                                    const update = v.clone()

                                    props.onChange(update)
                                }}
                            />
                        </Collapse.Panel>
                    )}
                </Collapse>
            </Skeleton>
        </Well>
    )
}
