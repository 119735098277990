import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { Select, SelectProps } from 'antd'
import { IRuleBuilderFieldSelectOption, IRuleBuilderSelectField } from '../../../rule-builder/rule-builder'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'

interface ISelectInputProps {
    rule: IRule
    field: IRuleBuilderSelectField
    onChange: (value: boolean) => any
    options: IRuleBuilderFieldSelectOption[]
    showSearch?: boolean
    extra?: SelectProps<any>
}

export class SelectInput extends BetterComponent<ISelectInputProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-select-input'
    public ref: any

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <Select
                    dropdownClassName={this.buildClassName('value-dropdown')}
                    placeholder="Select a value"
                    value={this.rule.value}
                    onChange={this.handleChange}
                    showSearch={this.props.showSearch}
                    {...this.props.extra}
                >
                    {this.props.options.map((opt) => (
                        <Select.Option key={opt.value} value={opt.value} label={opt.name} title={opt.title}>
                            {opt.name}
                        </Select.Option>
                    ))}
                </Select>
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected handleChange = async (value: string): Promise<void> => {
        this.emitChange({ value })
    }

    protected async emitChange(changes: any): Promise<void> {
        this.props.onChange(changes)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
