import * as React from 'react'
import { Component } from 'react'
import { IMultiSelectProps, IMultiSelectState, IMultiSelectOption } from './interfaces'
import { MultiSelectItem } from './multi-select-item'
import { MultiSelectPortal } from './multi-select-portal'
import './multi-select.scss'
import { escapeRegExpString } from '../../_utils/regexp'

const preventBubbling = (event: MouseEvent | any): void => {
    if (event) {
        event.stopPropagation()
    }
}

export class MultiSelect extends Component<IMultiSelectProps, IMultiSelectState> {
    private defaultClassNames: string[] = ['multi-select', 'ms-container']

    private ref: any
    private muationObserver: MutationObserver
    private defaultPlaceholder: string = 'Select an option'

    public constructor(props: IMultiSelectProps) {
        super(props)

        let selectedOptions: IMultiSelectOption[] = []
        if (this.props.value) {
            selectedOptions = this.props.options.filter((opt) => {
                return this.props.value!.indexOf(opt.value) !== -1
            })
        }

        this.state = {
            isOpen: false,
            selectedOptions,
            rect: {},
        }
    }

    public componentDidMount(): void {
        this.wireEventHandlers()
    }

    public componentWillUnmount(): void {
        this.unwireEventHandlers()
    }

    public render(): React.ReactNode {
        const { state, props } = this
        const handleOpenStateChange = this.handleOpenStateChange.bind(this)
        const handleOptionSearch = this.handleOptionSearch.bind(this)
        const handleSelectAllChange = this.handleSelectAllChange.bind(this)
        const visibleOptions = state.filteredOptions || props.options
        const allItemsSelected =
            state.selectedOptions.length > 0 && state.selectedOptions.length === props.options.length

        return (
            <React.Fragment>
                <div ref={(element) => (this.ref = element)} className={this.buildClassNames()}>
                    <div className="ms-dropdown">
                        <div className="ms-dropdown-heading" onClick={handleOpenStateChange}>
                            <span className="ms-dropdown-title">{this.buildTitle()}</span>
                            <span className={`ms-dropdown-loading-indicator${props.loading ? ' ms-loading' : ''}`} />
                            <span className={`ms-dropdown-arrow ${state.isOpen ? 'ms-open' : 'ms-closed'}`} />
                        </div>
                    </div>
                </div>

                <MultiSelectPortal
                    portalId={`ms-dropdown-container-${new Date().getTime()}`}
                    className={this.props.dropdownClassName}
                    container={this.props.dropdownContainer || document.body}
                >
                    {this.state.isOpen && (
                        <div
                            className="ms-dropdown-option-list"
                            style={{
                                width: state.rect.width,
                                top: (state.rect.y || state.rect.top) + state.rect.height + 2,
                                left: state.rect.x || state.rect.left,
                            }}
                        >
                            {!this.props.disableSearch && (
                                <div className="ms-dropdown-search">
                                    <input
                                        type="text"
                                        onKeyUp={handleOptionSearch}
                                        placeholder={this.props.searchPlaceholder || 'Search'}
                                    />
                                </div>
                            )}
                            {!state.filteredOptions && !this.props.disableSelectAll && (
                                <React.Fragment>
                                    <div
                                        className={`ms-dropdown-option ms-select-all${
                                            allItemsSelected ? ' ms-selected' : ''
                                        }`}
                                        onClick={handleSelectAllChange}
                                    >
                                        <span className="ms-dropdown-option-label">
                                            {this.props.selectAllLabel || 'Select All'}
                                        </span>
                                        <span className="ms-dropdown-option-toggle">
                                            <input type="checkbox" checked={true} readOnly={true} tabIndex={-1} />
                                        </span>
                                    </div>
                                    <div className="ms-dropdown-divider" />
                                </React.Fragment>
                            )}
                            {visibleOptions.length > 0 ? (
                                visibleOptions.map((option) => this.renderOptionItem(option))
                            ) : (
                                <span className="ms-dropdown-empty">
                                    {this.props.emptyOptionsPlaceholder || 'No options found'}
                                </span>
                            )}
                        </div>
                    )}
                </MultiSelectPortal>
            </React.Fragment>
        )
    }

    protected wireEventHandlers(): void {
        document.addEventListener('mousedown', this.handleDocumentClick.bind(this))

        if (this.props.closeOnEscape) {
            document.addEventListener('keyup', this.handleDocumentKeyUp.bind(this))
        }

        this.ref.addEventListener('mousedown', preventBubbling)

        this.muationObserver = new MutationObserver(() => {
            this.setState(() => ({ rect: this.ref.getBoundingClientRect() }))
        })

        this.muationObserver.observe(this.ref, {
            childList: true,
            subtree: true,
        })
    }

    protected unwireEventHandlers(): void {
        document.removeEventListener('mousedown', this.handleDocumentClick.bind(this))

        if (this.props.closeOnEscape) {
            document.removeEventListener('keyup', this.handleDocumentKeyUp.bind(this))
        }

        this.ref.removeEventListener('mousedown', preventBubbling)

        this.muationObserver.disconnect()
    }

    protected buildTitle(): React.ReactNode {
        const selectAllLabel = (this.props.selectAllLabel || '').trim()
        const hasSelectAllLabel = !!selectAllLabel && selectAllLabel.length > 0
        const totalSelected = this.state.selectedOptions.length
        const allItemsSelected = totalSelected === this.props.options.length
        const maxDisplayCount = this.props.maxDisplayCount || 0
        const maxDisplayMet = maxDisplayCount > 0 && totalSelected > maxDisplayCount

        let title: React.ReactNode = (
            <span className="ms-dropdown-placeholder">{this.props.placeholder || this.defaultPlaceholder}</span>
        )

        if (this.state.selectedOptions.length > 0) {
            if (allItemsSelected && hasSelectAllLabel) {
                title = (
                    <span className="ms-dropdown-selections">
                        <span className="ms-dropdown-selection">{this.props.selectAllLabel}</span>
                    </span>
                )
            } else {
                if (maxDisplayMet) {
                    title = this.handleMaxDisplayMet()
                } else {
                    const selectedValues = this.state.selectedOptions.map((opt) => opt.value)
                    const orderedSelections = this.props.options.filter(
                        (opt) => selectedValues.indexOf(opt.value) !== -1,
                    )

                    title = (
                        <span className="ms-dropdown-selections">
                            {orderedSelections.map((option, index) => {
                                return this.renderSelectedItem(option, index, this.state.selectedOptions)
                            })}
                        </span>
                    )
                }
            }
        }

        return title
    }

    protected renderSelectedItem(
        option: IMultiSelectOption,
        index: number,
        options: IMultiSelectOption[],
    ): React.ReactNode {
        if (this.props.renderSelectedItem) {
            return this.props.renderSelectedItem(option, index, options)
        }

        const isLastItem = index + 1 === options.length

        return (
            <span key={option.value} className="ms-dropdown-selection">
                {option.label || option.value}
                {!isLastItem ? ', ' : ''}
            </span>
        )
    }

    protected renderOptionItem(option: IMultiSelectOption): React.ReactNode {
        const currentSelectedValues = this.state.selectedOptions.map((opt) => opt.value)
        const itemIsSelected = currentSelectedValues.indexOf(option.value) !== -1
        const handleItemChange = this.handleItemChange.bind(this)

        return (
            <MultiSelectItem
                key={option.value}
                value={option.value}
                label={option.label}
                checked={itemIsSelected}
                onChange={handleItemChange}
                renderOptionItem={this.props.renderOptionItem}
                disabled={option.disabled}
            />
        )
    }

    protected handleMaxDisplayMet(): React.ReactNode {
        const { selectedOptions } = this.state
        if (this.props.maxDisplayFormatter) {
            return this.props.maxDisplayFormatter(selectedOptions)
        }

        const multi = selectedOptions.length > 1
        return `${selectedOptions.length} ${multi ? 'Items' : 'Item'} Selected`
    }

    protected handleSelectAllChange(): void {
        const allItemsSelected = this.state.selectedOptions.length === this.props.options.length
        let selectedOptions: IMultiSelectOption[] = []

        if (!allItemsSelected) {
            selectedOptions = this.props.options
        }

        this.setState(
            () => ({ selectedOptions }),
            () => {
                if (this.props.onChange) {
                    const values = selectedOptions.map((opt) => opt.value)
                    this.props.onChange(values)
                }
            },
        )
    }

    protected handleItemChange(option: IMultiSelectOption, checked: boolean): void {
        this.setState(
            ({ selectedOptions }) => {
                const optionIndex = selectedOptions.findIndex((opt) => opt.value === option.value)

                if (checked) {
                    if (optionIndex === -1) {
                        selectedOptions.push(option)
                    }
                } else {
                    if (optionIndex !== -1) {
                        selectedOptions.splice(optionIndex, 1)
                    }
                }

                return { selectedOptions }
            },
            () => {
                if (this.props.onChange) {
                    const values = this.state.selectedOptions.map((opt) => opt.value)
                    this.props.onChange(values)
                }
            },
        )
    }

    protected handleOpenStateChange(): void {
        this.setState(({ isOpen }) => ({
            isOpen: !isOpen,
            rect: this.ref.getBoundingClientRect(),
        }))
    }

    protected buildClassNames(): string {
        const classNames = [...this.defaultClassNames]

        if (this.props.loading === true) {
            classNames.push('ms-loading')
        }
        classNames.push(this.state.isOpen ? 'ms-open' : 'ms-closed')

        const size = this.props.size || 'default'
        classNames.push(`ms-size-${size.toLowerCase()}`)

        if (this.props.className && this.props.className.trim() !== '') {
            classNames.push(this.props.className.trim())
        }

        return classNames.join(' ')
    }

    private handleOptionSearch(event: KeyboardEvent): void {
        const target: any = event.target
        const searchValue = !!target.value ? target.value.trim() : undefined
        const allOptions = this.props.options
        let filteredOptions: any

        if (searchValue && searchValue.length > 0) {
            const searchRgx = new RegExp(escapeRegExpString(searchValue), 'i')

            filteredOptions = allOptions.filter((opt) => {
                const label = opt.label || opt.value
                return searchRgx.test(label.toString()) || searchRgx.test(opt.value.toString())
            })
        }

        this.setState(() => ({ filteredOptions }))
    }

    private handleDocumentKeyUp(event: KeyboardEvent): void {
        if (this.state.isOpen) {
            if (event.key && event.key.toUpperCase() === 'ESCAPE') {
                preventBubbling(event)
                this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
            }
        }
    }

    private handleDocumentClick(event: MouseEvent): void {
        if (this.state.isOpen) {
            this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
        }
    }
}
