import * as randomstring from 'randomstring'
import clone from 'clone-deep'
import { IEventBus } from '@pushly/aqe/lib/interfaces'

const __GLOBAL_EVENT_BUSES__: {
    [name: string]: EventBus
} = {}

export class EventBus implements IEventBus {
    public static get(name: string): IEventBus {
        let bus = __GLOBAL_EVENT_BUSES__[name]
        if (!bus) {
            bus = new EventBus({ name })
            __GLOBAL_EVENT_BUSES__[name] = bus
        }

        return bus
    }

    public name: string = randomstring.generate()
    protected _observers: Record<string, Function[]> = {}

    protected constructor(props: Partial<Pick<IEventBus, 'name'>>) {
        this.name = props.name ?? this.name
    }

    public observe(event: string, callback: Function) {
        const observers = this.getObservers(event)
        const current = observers.find((cb) => cb === callback)

        if (!current) {
            observers.push(callback)
            this._observers[event] = observers
        }

        return this
    }

    public unobserve(event: string, callback?: Function) {
        if (!callback) {
            if (event in this._observers) {
                delete this._observers[event]
            }
        } else {
            const observers = this.getObservers(event)
            const currentIdx = observers.findIndex((cb) => cb === callback)

            if (currentIdx !== -1) {
                observers.splice(currentIdx, 1)
                this._observers[event] = observers
            }
        }

        return this
    }

    public dispatch(event: string, ...args: any[]) {
        const observers = this.getObservers(event)

        for (const observer of observers) {
            observer(...args)
        }

        return this
    }

    protected getObservers(event: string): Function[] {
        return clone(this._observers[event] ?? [])
    }
}
