import * as React from 'react'
import { Button } from 'antd'
import { useContext, useState } from 'react'
import { LogoutOutlined } from '@ant-design/icons'
import { AuthService } from '../../services'
import { useService } from '../../hooks/use-service'
import LinkTag from '../html-element-wrappers/link-tag'
import { PlatformIdentityContext } from '../../providers/platform-identity-provider/platform-identity-provider'

interface ILogoutButton {
    mode: 'button' | 'link'
}

const LogoutButton = (props: ILogoutButton) => {
    const authService = useService<AuthService>(AuthService)
    const idpContext = useContext(PlatformIdentityContext)

    const [loggingOut, setLoggingOut] = useState(false)
    const Component = props.mode === 'link' ? LinkTag : Button

    return (
        <Component
            loading={loggingOut}
            onClick={async (ev: any) => {
                ev.preventDefault()

                setLoggingOut(true)

                try {
                    await authService.logout(false)
                } catch (_) {
                    setLoggingOut(false)
                }
            }}
        >
            <LogoutOutlined />
            <span>Logout</span>
        </Component>
    )
}

export default LogoutButton
