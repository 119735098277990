import * as React from 'react'
import styled from 'styled-components'
import { ICanvasInnerDefaultProps } from '@mrblenny/react-flow-chart'
import { getClassNames } from '../../_utils/classnames'

interface ICanvasInner {
    setRef: (el: any) => void
}

export const CanvasInner = (props: ICanvasInner) => {
    const Inner = styled.div.attrs({
        className: getClassNames('campaign-canvas-inner'),
    })<ICanvasInnerDefaultProps>``

    return React.forwardRef(
        ({ children, ...otherProps }: ICanvasInnerDefaultProps, _ref: React.Ref<HTMLDivElement>) => {
            return (
                <Inner ref={props.setRef} {...(otherProps as any)}>
                    {children}
                </Inner>
            )
        },
    )
}
