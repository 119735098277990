import * as React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { AsyncButton } from '../async-button/async-button.component'
import { BetterComponent } from '../better-component/better-component'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc'
import { AppService } from '../../services/index'
import { PlatformMessageDto } from '../../dtos/platform-message'
import autobind from 'autobind-decorator'
import { delay } from '../../_utils/utils'

interface IProps extends PlatformMessageDto {
    isMaintenanceMode?: boolean
    isRefreshMode?: boolean
}

interface IState {
    dismissed: boolean
}

@observer
export class PlatformMessageItem extends BetterComponent<IProps, IState> {
    public state: IState = {
        dismissed: false,
    }

    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render(): React.ReactNode {
        const classNames: string[] = ['platform-message', 'open']
        if (this.state.dismissed) {
            classNames.push('dismiss')
        }

        return (
            <div className={classNames.join(' ')}>
                {this.props.title && <div className="title">{this.props.title}</div>}
                <div className="message" dangerouslySetInnerHTML={{ __html: this.props.message }} />
                {this.props.isMaintenanceMode && <div className="maintenance-wrapper"></div>}
                {this.props.isRefreshMode && (
                    <div className="refresh-wrapper">
                        <AsyncButton
                            type="primary"
                            onClick={() => {
                                location.reload()
                            }}
                        >
                            <span>Refresh</span>
                        </AsyncButton>
                    </div>
                )}
                <div className="close" onClick={this.handleClick}>
                    <CloseOutlined />
                </div>
            </div>
        )
    }

    @autobind
    private handleClick(): void {
        this.setState({ dismissed: true })
        delay(() => this.appService.setPlatformMessageAsSeen(this.props.id), 0.32)
    }
}
