import * as clone from 'clone'
import { convertCase } from '../../_utils/utils'
import { WebApnsConfigurationModel } from './web-apns-configuration.model'
import { NativeP12ApnsConfiguration, NativeP8ApnsConfiguration } from './native-apns-configurations.model'
import { NativeLegacyFcmConfiguration, NativeV1FcmConfiguration } from './native-fcm-configurations.model'
import { stripUndefined } from '../../_utils/strip-undefined'
import {
    FcmSendIntegrationApiVersion,
    IAbstractNativeApnsSendIntegration,
    IAbstractNativeFcmSendIntegration,
    IWebApnsSendIntegration,
} from '../../interfaces/send-integrations'
import { SendIntegrationPrivateKeyType } from '../../enums/send-integration-private-key-type.enum'

export interface IDomainSendIntegrationModel {
    web_apns_configuration: IWebApnsSendIntegration | any
    native_apns_configuration: IAbstractNativeApnsSendIntegration | any
    native_fcm_configuration: IAbstractNativeFcmSendIntegration | any
}

export class DomainSendIntegrationModel {
    public static build(props: any | DomainSendIntegrationModel): DomainSendIntegrationModel {
        const data = props instanceof DomainSendIntegrationModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new DomainSendIntegrationModel()
        model.setWebApnsConfiguration(scData.web_apns_configuration)
        model.setNativeApnsConfiguration(scData.native_apns_configuration)
        model.setNativeFcmConfiguration(scData.native_fcm_configuration)

        return model
    }

    private web_apns_configuration: WebApnsConfigurationModel
    private native_apns_configuration: NativeP8ApnsConfiguration | NativeP12ApnsConfiguration
    private native_fcm_configuration: NativeV1FcmConfiguration | NativeLegacyFcmConfiguration

    public clone(): DomainSendIntegrationModel {
        return DomainSendIntegrationModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            web_apns_configuration: this.getWebApnsConfiguration(),
            native_apns_configuration: this.getNativeApnsConfiguration(),
            native_fcm_configuration: this.getNativeFcmConfiguration(),
        })
    }

    public getWebApnsConfiguration(): WebApnsConfigurationModel {
        return this.web_apns_configuration
    }
    public setWebApnsConfiguration(configuration: WebApnsConfigurationModel | any): void {
        this.web_apns_configuration = WebApnsConfigurationModel.build(configuration ?? {})
    }

    public getNativeApnsConfiguration(): NativeP12ApnsConfiguration | NativeP8ApnsConfiguration {
        return this.native_apns_configuration
    }
    public setNativeApnsConfiguration(
        configuration: NativeP8ApnsConfiguration | NativeP12ApnsConfiguration | any,
    ): void {
        const privateKeyType = configuration?.getPrivateKeyType?.() ?? configuration?.private_key_type
        if (privateKeyType === SendIntegrationPrivateKeyType.P12) {
            this.native_apns_configuration = NativeP12ApnsConfiguration.build(configuration)
        } else {
            this.native_apns_configuration = NativeP8ApnsConfiguration.build(configuration ?? {})
        }
    }

    public getNativeFcmConfiguration(): NativeV1FcmConfiguration | NativeLegacyFcmConfiguration {
        return this.native_fcm_configuration
    }
    public setNativeFcmConfiguration(
        configuration: NativeV1FcmConfiguration | NativeLegacyFcmConfiguration | any,
    ): void {
        const apiVersion = configuration?.getApiVersion?.() ?? configuration?.api_version

        if (apiVersion === FcmSendIntegrationApiVersion.V1) {
            this.native_fcm_configuration = NativeV1FcmConfiguration.build(configuration)
        } else {
            this.native_fcm_configuration = NativeLegacyFcmConfiguration.build(configuration ?? {})
        }
    }
}
