import * as React from 'react'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { axiosFetch } from '../config/axios-setup'
import aqe from '@pushly/aqe'
import { ServiceApiResponse, ApiCallOptions } from '@pushly/aquarium-utils/lib/react'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class DictionaryService {
    private appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public get baseEndpoint(): string {
        return `${aqe.defaults.publicApiDomain}/dictionary`
    }

    public async fetchLanguageCodes(opts: ApiCallOptions = {}): Promise<ServiceApiResponse<any[]>> {
        let ok = false
        let data: any[] = []
        let meta: any = {}
        let cancelled = false
        let error

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const res = await axiosFetch(
                'get',
                {
                    url: `${this.baseEndpoint}/language-codes`,
                },
                opts.cancellationKey,
            )

            const { data: codes, ...resMeta } = res.data

            ok = true
            data = codes
            meta = resMeta
        } catch (err) {
            error = err
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data, meta, cancelled, error }
    }
}
