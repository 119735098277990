import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'

function getCleanIdArray(arr: number[]) {
    arr = arr.filter((v) => v !== null && v !== undefined).map((v) => parseInt(v.toString(), 10))

    return Array.from(new Set(arr))
}

export class CampaignItemGroupTargetingModel {
    public static build(data: CampaignItemGroupTargetingModel | any): CampaignItemGroupTargetingModel {
        const scData = convertCase(clone(data), 'snake')

        const model = new CampaignItemGroupTargetingModel()
        model.setItemGroupIds(scData.item_group_ids)
        model.setExcludedItemGroupIds(scData.excluded_item_group_ids)

        return model
    }

    private item_group_ids?: number[]
    private excluded_item_group_ids?: number[]

    public clone(): CampaignItemGroupTargetingModel {
        return CampaignItemGroupTargetingModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            item_group_ids: this.getItemGroupIds(),
            excluded_item_group_ids: this.getExcludedItemGroupIds(),
        })
    }

    public setItemGroupIds(itemGroupIds: number[] | undefined): void {
        this.item_group_ids = itemGroupIds === undefined ? undefined : getCleanIdArray(itemGroupIds)
    }
    public getItemGroupIds(): number[] | undefined {
        return this.item_group_ids
    }
    public addItemGroupId(itemGroupId: number) {
        if (!this.item_group_ids || !this.item_group_ids.includes(itemGroupId)) {
            this.setItemGroupIds([...(this.item_group_ids ?? []), itemGroupId])
        }
    }

    public setExcludedItemGroupIds(itemGroupIds: number[] | undefined): void {
        this.excluded_item_group_ids = itemGroupIds === undefined ? undefined : getCleanIdArray(itemGroupIds)
    }
    public getExcludedItemGroupIds(): number[] | undefined {
        return this.excluded_item_group_ids
    }
    public addExcludedItemGroupId(itemGroupId: number) {
        if (!this.excluded_item_group_ids || !this.excluded_item_group_ids.includes(itemGroupId)) {
            this.setExcludedItemGroupIds([...(this.excluded_item_group_ids ?? []), itemGroupId])
        }
    }
}
