import { daysToSeconds } from '../../../_utils/utils'

export class UpdateNotificationRequestDto {
    public static parse(fields: UpdateNotificationRequestDto): UpdateNotificationRequestDto {
        const dto = new UpdateNotificationRequestDto()

        type AllowedType = keyof UpdateNotificationRequestDto
        const fieldsToAssign: AllowedType[] = ['title', 'message', 'iconUrl', 'imageUrl', 'badgeUrl', 'landingUrl']

        fieldsToAssign.forEach((key) => {
            if (fields[key] !== undefined) {
                dto[key] = fields[key] as any
            }
        })

        return dto
    }

    public title?: string
    public message?: string
    public iconUrl?: string
    public imageUrl?: string
    public badgeUrl?: string
    public landingUrl?: string
    public ttlSeconds?: number = daysToSeconds(3)
}
