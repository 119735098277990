import { useState } from 'react'
import { useDebouncer } from './use-debouncer'

type SetDebouncedValue<T> = (value: T, emit?: boolean) => void
export const useDebouncedValue = <T extends any>(
    emitter: (value: T) => any,
    defaultValue?: T | undefined,
    debounce: number = 320,
): [T, SetDebouncedValue<T>] => {
    const [value, setValue] = useState<T>(defaultValue as T)
    const [runWithDebounce, clearDebouncer] = useDebouncer(debounce)

    const setValueAndEmit = (v: T, emit: boolean = true) => {
        if (emit) {
            runWithDebounce(() => emitter(v))
        } else {
            clearDebouncer()
        }

        setValue(v)
    }

    return [value, setValueAndEmit]
}
