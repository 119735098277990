import * as React from 'react'
import * as moment from 'moment-timezone'
import autobind from 'autobind-decorator'
import { Moment } from 'moment'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Skeleton, Select } from 'antd'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import { AppService } from '../../../services'
import { CampaignDto } from '../dtos/campaign-dto'
import { CampaignGenStatsRow } from './campaign-gen-stats-row'
import { CampaignSubscriberGraph } from './campaign-subscriber-graph'
import { CampaignPerformanceGraph } from './campaign-performance-graph'
import { InsightsService } from '../../../services/insights'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { DomainDto } from '../../../dtos/domain'
import { getDomainCurrencyCode } from '../../../_utils/domain'

type TimeBreakdown = 'day' | 'week' | 'month'

export interface ICampaignSummaryTabProps {
    campaign: CampaignDto
    domain?: DomainDto
}

export interface ICampaignSummaryTabState {
    timeBreakdown: TimeBreakdown
    loadingLifetimeStats: boolean
    loadingBreakdownStats: boolean
    lifetimeStats: any[]
    breakdownStats: any[]
}

export class CampaignSummaryTab extends BetterComponent<ICampaignSummaryTabProps, ICampaignSummaryTabState> {
    protected static tabName = 'summary'
    protected static tabLabel = 'Summary'

    private appState: AppState
    private appService: AppService
    private insightsService: InsightsService

    public constructor(props: ICampaignSummaryTabProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            timeBreakdown: 'day',
            loadingLifetimeStats: true,
            loadingBreakdownStats: true,
            lifetimeStats: [],
            breakdownStats: [],
        }
    }

    public async componentDidMount(): Promise<void> {
        this.loadStats()
    }

    public render(): React.ReactNode {
        return (
            <div>
                <Skeleton active={true} loading={false}>
                    <CampaignGenStatsRow
                        loading={this.state.loadingLifetimeStats}
                        domain={this.appState.currentDomain}
                        campaign={this.props.campaign}
                        stats={this.state.lifetimeStats}
                    />

                    <Well
                        loading={this.state.loadingBreakdownStats}
                        className="performance-overview-well"
                        title="Performance Overview"
                        action={this.renderStatsActions()}
                        showFooter={false}
                        disabled={this.isViewDisabled()}
                    >
                        <CampaignSubscriberGraph
                            loading={this.state.loadingBreakdownStats}
                            campaign={this.props.campaign}
                            stats={this.state.breakdownStats}
                            timeBreakdown={this.state.timeBreakdown}
                        />
                        <CampaignPerformanceGraph
                            loading={this.state.loadingBreakdownStats}
                            campaign={this.props.campaign}
                            domain={this.appState.currentDomain}
                            stats={this.state.breakdownStats}
                            timeBreakdown={this.state.timeBreakdown}
                        />
                    </Well>
                </Skeleton>
            </div>
        )
    }

    protected renderStatsActions(): React.ReactNode {
        return (
            <Form>
                <Form.Item label="Breakdown">
                    <Select size="small" value={this.state.timeBreakdown} onChange={this.setTimeBreakdownState}>
                        <Select.Option value="day">Daily</Select.Option>
                        <Select.Option value="week">Weekly</Select.Option>
                        <Select.Option value="month">Monthly</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        )
    }

    protected getCampaignModel(): CampaignModel {
        return CampaignModel.build(this.props.campaign)
    }

    @autobind
    protected async setTimeBreakdownState(value: TimeBreakdown) {
        await this.setState({ timeBreakdown: value })
        return this.loadCampaignBreakdownStats()
    }

    @autobind
    protected async loadStats(): Promise<any> {
        const statLoaders: Array<Promise<void>> = []

        statLoaders.push(this.loadCampaignLifetimeStats())
        statLoaders.push(this.loadCampaignBreakdownStats())

        return Promise.all(statLoaders)
    }

    @autobind
    protected async loadCampaignLifetimeStats(): Promise<any> {
        await this.setState({
            loadingLifetimeStats: true,
        })

        const stats = await this.insightsService.fetch(
            {
                datePreset: 'lifetime',
                dateIncrement: 'lifetime',
                entity: 'campaigns',
                breakdowns: ['campaign'],
                fields: [
                    'campaign_id',
                    'entrances',
                    'exits',
                    'deliveries',
                    'impressions',
                    'clicks',
                    'delivery_rate_decimal',
                    'ctr_decimal',
                    'purchases',
                    'purchase_amount',
                    'currency_code',
                ],
                filters: [
                    {
                        field: 'domain.id',
                        operator: 'eq',
                        value: this.globalDomain!.id,
                    },
                    {
                        field: 'campaign.id',
                        operator: 'eq',
                        value: this.props.campaign.id,
                    },
                ],
            },
            false,
            `cl.fetchCampaignStats.${this.props.campaign.id}`,
        )

        return this.setState({
            loadingLifetimeStats: false,
            lifetimeStats: Array.isArray(stats) && stats.length > 0 ? stats[0] : {},
        }).then(() => stats)
    }

    @autobind
    protected async loadCampaignBreakdownStats(): Promise<any> {
        await this.setState({
            loadingBreakdownStats: true,
        })

        const stats = await this.insightsService.fetch(
            {
                datePreset: 'lifetime',
                dateIncrement: this.state.timeBreakdown,
                entity: 'campaigns',
                breakdowns: ['campaign'],
                fields: [
                    'campaign_id',
                    'entrances',
                    'exits',
                    'deliveries',
                    'impressions',
                    'delivery_rate',
                    'delivery_rate_decimal',
                    'clicks',
                    'ctr',
                    'ctr_decimal',
                    'purchases',
                    'purchase_amount',
                    'currency_code',
                ],
                filters: [
                    {
                        field: 'domain.id',
                        operator: 'eq',
                        value: this.globalDomain!.id,
                    },
                    {
                        field: 'campaign.id',
                        operator: 'eq',
                        value: this.props.campaign.id,
                    },
                ],
                order: {
                    field: 'event_date',
                    direction: 'asc',
                },
            },
            false,
            `cl.fetchCampaignBreakdownStats.${this.props.campaign.id}`,
        )

        let backfilledStats: any[] = []
        if (stats.length > 0) {
            backfilledStats = [stats[0]]

            const lastStat = stats[stats.length - 1]
            const statDateCap = moment(`${lastStat.event_date} 00:00:00`)
            const currency = getDomainCurrencyCode(this.props.domain)

            let previousStat: any = stats[0]

            while (true) {
                const nextStatDate: Moment = moment(`${previousStat.event_date} 00:00:00`).add(
                    1,
                    this.state.timeBreakdown,
                )
                const nextEventDate = nextStatDate.format('YYYY-MM-DD')

                let nextStat: any = stats.find((s: any) => s.event_date === nextEventDate)
                if (!nextStat) {
                    nextStat = {
                        event_date: nextEventDate,
                        campaign_id: this.props.campaign.id,
                    }

                    nextStat = {
                        ...nextStat,
                        entrances: 0,
                        exits: 0,
                        deliveries: 0,
                        impressions: 0,
                        clicks: 0,
                        delivery_rate: 0,
                        ctr: 0,
                        purchases: 0,
                        purchase_count: null,

                        // TODO: replace backfill with domain.currencyCode
                        //  or appropriate column once created and deployed
                        currency_code: currency,
                    }
                }

                if (nextStatDate > statDateCap) {
                    break
                }

                backfilledStats.push(nextStat)
                previousStat = nextStat
            }
        }

        return this.setState({
            loadingBreakdownStats: false,
            breakdownStats: backfilledStats,
        }).then(() => backfilledStats)
    }

    protected isViewDisabled(): boolean {
        const isCancelled = this.props.campaign.status === 'CANCELLED'

        const now: Moment = moment()
        const dateStart: Moment = moment(this.props.campaign.dateStart)
        const isScheduled = dateStart > now

        return isCancelled || isScheduled
    }
}
