import * as React from 'react'
import { Switch } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Container } from 'typescript-ioc/es5'
import { AppState } from '../../stores/app'
import { ErrorPage } from '../error-page/error-page'

@observer
export class AppSwitch extends Switch {
    private appState: AppState

    public constructor(props: {}) {
        super(props)

        this.appState = Container.get(AppState)
    }

    public render() {
        let { children } = this.props

        if (this.appState.isAuthenticated && this.appState.showing404Count > 0) {
            children = []
        }

        return (
            <Switch>
                {children}
                {!this.appState.appIsLoading && <ErrorPage errorCode={404} hideLogo={false} />}
            </Switch>
        )
    }
}
