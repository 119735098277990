import * as React from 'react'
import { observer } from 'mobx-react'
import autobind from 'autobind-decorator'
import { Container } from 'typescript-ioc/es5'
import { UserService, UserTokenType } from '../../../services/user'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Translate } from '../../../components/translate/translate'
import { UserDataForm, UserDataFormStyle } from '../../users'
import './reset-password.scss'
import { Alert } from 'antd'

export interface IPasswordResetFormOptions {
    password: string
    passwordVerify: string
}

interface IProps {}

interface IState {
    loading: boolean
    hasError: boolean
    errorMessage: string
    user?: any
}

@observer
export class ResetPassword extends BetterComponent<IProps, IState> {
    public state: IState = {
        loading: true,
        hasError: false,
        errorMessage: '',
    }

    private userService: UserService

    public constructor(props: IProps) {
        super(props)

        this.userService = Container.get(UserService)
    }

    public async componentDidMount() {
        try {
            const user = await this.userService.confirmUserToken(
                this.queryString.token as any,
                UserTokenType.PASSWORD_RESET,
            )

            this.setState({ user })
        } catch (error) {
            this.setState({
                hasError: true,
                errorMessage: error.message,
            })
        } finally {
            this.setState({ loading: false })
        }
    }

    public render() {
        return this.state.loading ? (
            ''
        ) : (
            <div className="reset-password-page">
                <div className="reset-password-page-content">
                    <div className="content-header">
                        <h1>
                            <Translate name="resetPassword.formHeader" />
                        </h1>
                    </div>

                    <UserDataForm
                        className="reset-password-form"
                        style={UserDataFormStyle.PASSWORD_RESET}
                        hideWell={true}
                        onSubmit={this.handleOnSubmit}
                        submitText="Submit"
                        user={this.state.user}
                    />

                    {this.state.hasError && (
                        <Alert className="reset-password-alert" type="error" message={this.state.errorMessage} />
                    )}
                </div>
            </div>
        )
    }

    @autobind
    private async handleOnSubmit(error: any, values: IPasswordResetFormOptions): Promise<any> {
        if (!error) {
            try {
                await this.userService.processUserPasswordReset(
                    this.queryString.token as any,
                    this.state.user.id,
                    values,
                )
            } catch (err) {
                const errStatus = err.response.status
                let errMessage = err.response.data?.message ?? 'An error has occurred.'

                if (err.response.data?.details) {
                    const details = err.response.data?.details
                    errMessage = Array.isArray(details) ? details.join(' ') : details
                }

                this.setState({
                    hasError: true,
                    errorMessage:
                        errStatus === 429 ? (
                            <div>
                                <div>Pushly was unable to complete your request.</div>
                                <div>{errMessage.replace(/\.$/, '')}.</div>
                            </div>
                        ) : (
                            errMessage
                        ),
                })
            }
        }
    }
}
