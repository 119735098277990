import * as React from 'react'
import { Container } from 'typescript-ioc'
import { pause, simpleNotification } from './utils'
import { UserService } from '../services'
import { injectExternalScript } from '../hooks/use-external-script'
import aqe from '@pushly/aqe'

export const injectPushlySdk = (domainKey: string) => {
    console.debug(`Initializing sdk for ${domainKey}`)

    window.PlatformNotifications = {
        supported: true,
        configured: true,
    }

    window.PushlySDK = window.PushlySDK || []
    window.pushly = function (this: any) {
        window.PushlySDK.push(arguments)
    }

    injectExternalScript({
        src: `${aqe.defaults.publicCdnDomain}/pushly-sdk.min.js?domain_key=${domainKey}`,
        async: true,
    })

    window.pushly('load', { domainKey })

    window.pushly('on_web_push_unsupported', () => {
        window.PlatformNotifications!.supported = false
    })

    window.pushly('on_web_push_not_configured', () => {
        window.PlatformNotifications!.configured = false
    })
}

export const getPushlySdk = (): any | undefined => {
    return window.PushlySDK
}

export const registerUserCurrentPiid = async (
    user: { id: number; puuids: string[] },
    domain: { id: number },
): Promise<void> => {
    const sdk: any = getPushlySdk()
    if (sdk) {
        const sdkUser: any = sdk.context?.user ?? {}
        const sdkDomain: any = sdk.context?.domain ?? {}

        const currentRegistrations: any[] = user.puuids || []

        const permission = await new Promise<string>(async (res, _rej) => {
            if (!(await sdkUser.isSubscribed({ websitePushId: sdkDomain.getWebsitePushId() }))) {
                sdk.on('subscribed', async () => {
                    // Allow 3 secs for ES doc to be updated
                    await pause(3000)
                    res('granted')
                })

                sdk.on('permission_dismissed', () => res('dismissed'))
                sdk.on('permission_denied', () => res('denied'))
                sdk.push(['show_prompt'])
            } else {
                res('granted')
            }
        })

        if (permission === 'granted') {
            const existingRegistration = currentRegistrations.find(
                (r) => r.puuid === sdkUser.puuid && r.domainId === domain.id,
            )

            if (!existingRegistration) {
                const service: UserService = Container.get(UserService)
                await service.registerPuuid(user.id, domain.id, sdkUser.puuid)
            }
        } else if (permission === 'denied') {
            simpleNotification(
                'error',
                <>
                    We are unable to send you a preview of this notification because you have denied notification
                    permissions. Please manually reset your browser permissions and attempt to send the preview again.
                </>,
            )
        }
    }
}
