import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { WebBadge } from './web-badge'
import { NativeAndroidBadge } from './native-android-badge'
import { NativeIosBadge } from './native-ios-badge'
import React from 'react'

export const determineChannelBadge = (channel, props?: any) => {
    switch (channel) {
        case DeliveryChannel.WEB:
            return <WebBadge {...props} />
        case DeliveryChannel.NATIVE_ANDROID:
            return <NativeAndroidBadge {...props} />
        case DeliveryChannel.NATIVE_IOS:
            return <NativeIosBadge {...props} />
        default:
            return ''
    }
}
