import * as clone from 'clone'
import { DomainDto } from '../../dtos/domain'
import { NotificationSource } from '../../enums/notification-source'
import { getDefaultNotificationActions } from '../../_utils/domain'
import { NotificationDto } from '../../features/notifications'
import { NotificationBuilderSubmitType } from '../notification-builder/enums'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'

/**
 * Returns the api-response-form default value
 * that can be passed to NotificationDto.parse()
 */
function getSystemDefaultFallbackNotificationTemplate(domain: DomainDto): any {
    const defaultActions = getDefaultNotificationActions(domain)

    return {
        channels: {
            default: {
                landingUrl: 'https://{{domain_url}}',
                title: 'New Content Available',
                actions: defaultActions,
                overrideDefaultActions: false,
                iconUrl: domain.defaultIconUrl,
                isUsingDomainDefaultIcon: true,
                badgeUrl: domain.defaultBadgeUrl,
                isUsingDomainDefaultBadge: true,
                isSilent: false,
                requireInteraction: true,
            },
        },
        autoKeywordDiscovery: false,
        keywords: [],
    }
}

/**
 * Returns a NotificationDto with the existing
 * domain fallback - returns system default if null
 */
export function getDomainFallbackNotification(domain: DomainDto): NotificationDto {
    const template = domain.fallbackNotificationTemplate ?? getSystemDefaultFallbackNotificationTemplate(domain)
    if (
        template?.channels?.default?.overrideDefaultActions === false &&
        (template.channels.default.actions?.length ?? 0) === 0
    ) {
        template.channels.default.actions = getDefaultNotificationActions(domain)
    }

    return NotificationDto.parse({
        template,
        audience: {},
        deliverySpec: {} as any,
    })
}

export function getFallbackDisplayPreviewBuild(
    domain: DomainDto,
    type: NotificationBuilderSubmitType,
    notification: NotificationDto,
): any {
    const template = clone(notification.template)
    // @ts-ignore -- todo: rework with models
    delete template?.channels?.default?.id
    // @ts-ignore -- todo: rework with models
    delete template?.channels?.default?.domainId

    return {
        source: NotificationSource.PREVIEW,
        template,
        audience:
            type === NotificationBuilderSubmitType.PERSONAL_PREVIEW ? { personalPreview: true } : { teamPreview: true },
        deliverySpec: {
            type: NotificationDeliveryType.IMMEDIATE,
            window: NotificationDeliveryWindow.STANDARD,
            ttlSeconds: domain.defaultNotificationTtlSeconds ?? 7 * 60 * 60 * 24,
            ttlMetric: domain.displayMeta.default_notification_ttl_metric ?? 'days',
        },
        meta: {
            origin_domain_id: domain.id,
            preview_type: type === NotificationBuilderSubmitType.PERSONAL_PREVIEW ? 'personal-preview' : 'team-preview',
        },
    }
}
