import { UserType } from '../enums/user-type'

type ApiKeyType = UserType.EXTERNAL | UserType.INTERNAL
interface IApiKey {
    id: string
    name: string
    token?: string
    lastUsedAt: Date | string
}

export class ApiKeyDto implements IApiKey {
    public static fromApiResponse(apiKey: any): ApiKeyDto {
        const dto = new ApiKeyDto()

        dto.id = apiKey.id
        dto.lastUsedAt = apiKey.lastUsedAt
        dto.name = apiKey.name
        dto.token = apiKey.token

        return dto
    }

    public id: string
    public name: string
    public token?: string
    public lastUsedAt: Date | string
}
