import React from 'react'
import classnames from 'classnames'
import * as deepEqual from 'react-fast-compare'
import { NotificationListContext } from './notification-list-context'
import { Button, Input, Select, Switch, Tooltip } from 'antd'
import { REFRESH_OPTIONS } from './constants'
import { BetterSelect } from '../better-select/better-select'
import { NotificationSource } from '../../enums/notification-source'
import { HOLD_OUT_COMPUTED_STATUS, StatusType } from '../../enums/status-type'
import { ReloadOutlined } from '@ant-design/icons'
import { NotificationListState } from './interfaces'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import ShowSingleDomainEntitiesToggle from '../show-single-domain-entities-toggle/show-single-domain-entities-toggle'
import { NotificationListTypes } from './enums'

type FilterKey = keyof NotificationListState['filters']

interface INotificationListHeaderProps {
    title?: string
    filterSize?: SizeType
    filtersAddonBefore?: React.ReactNode
    filtersAddonAfter?: React.ReactNode
    hideRefresh?: boolean
    hideAutoRefreshOptions?: boolean
    hideFilters?: FilterKey | FilterKey[] | boolean
    filtersDisabled?: FilterKey | FilterKey[] | boolean
    listType?: NotificationListTypes
}

interface IState {
    status?: string[]
    source?: string[]
}

class NotificationListHeader extends React.Component<INotificationListHeaderProps, IState> {
    public static contextType = NotificationListContext
    public context!: React.ContextType<typeof NotificationListContext>

    public state: IState = {}

    protected readonly appService: AppService

    protected liveTypeTimeout: any
    protected prevContext?: React.ContextType<typeof NotificationListContext>

    public constructor(props: INotificationListHeaderProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    /**
     * Manually implemented context observer
     *
     * This check is required for when the parent context
     * is updated to account for domain changes in source visiblity
     * and availabilty
     */
    public componentDidUpdate() {
        const contextChanged = !deepEqual(this.prevContext, this.context)
        const statusChangedExternally = !deepEqual(this.state.status, this.context.filters.status)
        const sourceChangedExternally = !deepEqual(this.state.source, this.context.filters.source)

        if (contextChanged && (sourceChangedExternally || statusChangedExternally)) {
            this.setState({
                source: this.context.filters.source,
                status: this.context.filters.status,
            })
        }

        this.prevContext = this.context
    }

    public render() {
        const {
            levelDependenciesLoaded,
            dataSourceLoaded,
            refreshing,
            filters,
            onSearchClick,
            onFilterChange,
            onRefreshClick,
            refreshEnabled,
            refreshTimeout,
            onRefreshEnabledChange,
            onRefreshTimeoutChange,
        } = this.context

        const allFilters: FilterKey[] = ['source', 'status', 'search', 'showAllNotifications']
        const filterSize = this.props.filterSize ?? 'middle'
        const { listType } = this.props

        let filtersHidden: FilterKey[] = []
        if (this.props.hideFilters === true) {
            filtersHidden = Array.from(allFilters)
        } else if (this.props.hideFilters) {
            filtersHidden = Array.isArray(this.props.hideFilters) ? this.props.hideFilters : [this.props.hideFilters]
        }

        let filtersDisabled: FilterKey[] = []
        if (this.props.filtersDisabled === true) {
            filtersDisabled = Array.from(allFilters)
        } else if (this.props.filtersDisabled) {
            filtersDisabled = Array.isArray(this.props.filtersDisabled)
                ? this.props.filtersDisabled
                : [this.props.filtersDisabled]
        }

        const hideAllFilters = filtersHidden.length === allFilters.length
        const hideAllRefresh = this.props.hideRefresh && this.props.hideAutoRefreshOptions

        const loading = !levelDependenciesLoaded || !dataSourceLoaded || refreshing

        return (
            <div className={classnames('notification-list-header')}>
                <div
                    className={classnames('notification-list-title', {
                        hidden: !this.props.title,
                    })}
                >
                    <span>{this.props.title}</span>
                </div>

                <div
                    className={classnames('notification-list-filters', {
                        hidden: hideAllFilters,
                    })}
                >
                    {this.props.filtersAddonBefore}

                    {!filtersHidden.includes('search') && (
                        <Input.Search
                            className={classnames('notification-list-search')}
                            size={filterSize}
                            placeholder="Search by ID or Notification Content"
                            disabled={filtersDisabled.includes('search')}
                            enterButton={true}
                            defaultValue={filters.search}
                            onSearch={onSearchClick}
                            onChange={(ev) => {
                                clearTimeout(this.liveTypeTimeout)

                                const value = ev.target.value
                                this.liveTypeTimeout = setTimeout(() => {
                                    onFilterChange('search', value)
                                }, 320)
                            }}
                        />
                    )}

                    {!filtersHidden.includes('status') && listType === NotificationListTypes.NOTIFICATION && (
                        <BetterSelect
                            className="status-filter-select"
                            disabled={loading || filtersDisabled.includes('status')}
                            size={filterSize === 'middle' ? 'default' : filterSize}
                            mode="multiple"
                            prefix="Status"
                            placeholder="Select Statuses"
                            defaultValue={filters.status}
                            value={this.state.status}
                            options={[
                                { value: StatusType.COMPLETED.name, label: 'Delivered' },
                                { value: StatusType.SCHEDULED.name, label: 'Scheduled' },
                                { value: StatusType.DELIVERING.name, label: 'Delivering' },
                                { value: HOLD_OUT_COMPUTED_STATUS, label: 'Hold Out' },
                                { value: StatusType.CANCELLED.name, label: 'Cancelled' },
                                { value: StatusType.FAILED.name, label: 'Failed' },
                            ]}
                            onChange={(value: string[]) => {
                                this.setState({ status: value })
                            }}
                            onClose={(value: string[]) => {
                                this.setState({ status: value }, () => {
                                    onFilterChange('status', value)
                                })
                            }}
                            selectAllLabel="All Statuses"
                            disableSearch={true}
                            closeOnEscape={true}
                            maxDisplayCount={2}
                            maxDisplayFormatter={(options: any[]) => {
                                const multi = options.length > 1
                                return (
                                    <Tooltip title={options.map((o) => o.label).join(', ')}>
                                        {options.length} {multi ? 'Statuses' : 'Status'}
                                    </Tooltip>
                                )
                            }}
                        />
                    )}

                    {!filtersHidden.includes('source') && listType === NotificationListTypes.NOTIFICATION && (
                        <BetterSelect
                            className="source-filter-select"
                            disabled={loading || filtersDisabled.includes('source')}
                            size={filterSize === 'middle' ? 'default' : filterSize}
                            mode="multiple"
                            prefix="Source"
                            placeholder="Select Sources"
                            defaultValue={filters.source}
                            value={this.state.source}
                            options={[
                                { value: NotificationSource.MANUAL, label: 'Manual' },
                                { value: NotificationSource.FEED, label: 'Feed' },
                            ]}
                            onChange={(value: string[]) => {
                                this.setState({ source: value })
                            }}
                            onClose={(value: string[]) => {
                                this.setState({ source: value }, () => {
                                    onFilterChange('source', value)
                                })
                            }}
                            disableSelectAll={true}
                            disableSearch={true}
                            closeOnEscape={true}
                            maxDisplayCount={2}
                            maxDisplayFormatter={(options: any[]) => {
                                const multi = options.length > 1
                                return (
                                    <Tooltip title={options.map((o) => o.label).join(', ')}>
                                        {options.length} {multi ? 'Sources' : 'Source'}
                                    </Tooltip>
                                )
                            }}
                        />
                    )}

                    {!filtersHidden.includes('showAllNotifications') && (
                        <ShowSingleDomainEntitiesToggle
                            className="show-all-notifications-filter"
                            title="Notifications"
                            defaultChecked={filters.showAllNotifications}
                            onChange={(enabled) => {
                                onFilterChange('showAllNotifications', enabled)
                            }}
                        />
                    )}

                    {this.props.filtersAddonAfter}
                </div>

                <div
                    className={classnames('notification-list-refresh', {
                        hidden: hideAllRefresh,
                    })}
                >
                    {!this.props.hideAutoRefreshOptions && (
                        <>
                            <Switch size="small" checked={refreshEnabled} onChange={onRefreshEnabledChange} />
                            <span>Auto-Refresh</span>

                            <Select
                                className={classnames('notification-list-timeout-select')}
                                size="small"
                                disabled={!levelDependenciesLoaded || !refreshEnabled}
                                options={REFRESH_OPTIONS}
                                value={refreshTimeout}
                                onChange={onRefreshTimeoutChange}
                            />
                        </>
                    )}

                    {!this.props.hideRefresh && (
                        <Tooltip title="Refresh">
                            <Button
                                className={classnames('notification-list-refresh-toggle')}
                                size="small"
                                shape="round"
                                onClick={onRefreshClick}
                                disabled={!levelDependenciesLoaded || !refreshEnabled}
                            >
                                <ReloadOutlined spin={loading || refreshing} />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>
        )
    }

    protected handleStatusChange = (value: string[]) => {
        this.setState({ status: value })
    }
}

export default NotificationListHeader
