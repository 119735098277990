import { AppState } from '../../stores/app'
import { UserDto } from '../../dtos/user'

const GA_TRACKING_ID: string = process.env.GA_TRACKING_ID!
const GA_TRACKING_ENABLED: string = process.env.GA_TRACKING_ENABLED!

let lastGaConfigState = ''

export const GAHelper = (appState?: AppState): any => {
    const scope: any = window

    const configUpdate: any = {
        page_path: window.location.pathname + window.location.hash,
        page_location: window.location.href,
        ...getGAPackageAddons(appState),
    }

    const nextGaConfigState = JSON.stringify(configUpdate)
    if (lastGaConfigState !== nextGaConfigState) {
        lastGaConfigState = nextGaConfigState

        if (GA_TRACKING_ENABLED) {
            scope.gtag('config', GA_TRACKING_ID, nextGaConfigState)
        } else {
            console.log('config', GA_TRACKING_ID, configUpdate)
        }
    }

    return ''
}

const getGAPackageAddons = (appState?: AppState): any => {
    let addons: any = {}

    if (appState) {
        if (appState.currentDomain) {
            addons = {
                ...addons,
                domain_id: appState.currentDomain.id,
                domain_name: appState.currentDomain.displayName,
                account_name: appState.currentDomain.accountName || 'Unknown',
            }
        }

        if (appState.currentUser) {
            addons = {
                ...addons,
                user_id: appState.currentUser.id,
                user_is_internal: appState.currentUser.isInternalUser,
            }
        }
    }

    return addons
}

const tryParseJson = (jsonData: any, defaultValue?: any): any => {
    let data
    try {
        data = JSON.parse(jsonData)
    } catch {}
    return data || defaultValue
}

export const setCurrentGAUserScope = (user: UserDto): void => {
    const configPack = {
        user_id: user.id,
        user_is_internal: user.isInternalUser,
    }

    sendGAEvent('set_current_user', configPack)
}

export const sendGAEvent = (event: string, eventData: any, appState?: AppState): void => {
    const scope: any = window
    const eventPack = {
        send_to: GA_TRACKING_ID,
        ...eventData,
        ...getGAPackageAddons(appState),
    }

    if (GA_TRACKING_ENABLED) {
        scope.gtag('event', event, eventPack)
    } else {
        console.log('event', event, eventPack)
    }
}
