import * as React from 'react'
import { InputNumber as AntInputNumber, Select, DatePicker } from 'antd'
import './chain-select-component.scss'
import { BetterComponent } from '../better-component/better-component'
import { SelectValue } from 'antd/lib/select'
import { ReactElement } from 'react'
import autobind from 'autobind-decorator'

export interface IChainOption {
    display: string
    value?: any
    options?: IChainOption[]
}

export interface IChainSelectProps<ValueType> {
    mode: 'edit' | 'display'
    value: any
    options: IChainOption[]

    onChange?: (value: ValueType) => void
}

export interface IChainSelectState {
    value: any
    options: IChainOption[]
    chain: OptionChain
}

export class OptionChain {
    public items: IChainOption[] = []
    public columns: IChainOption[][] = []

    public contains(value: any): boolean {
        return this.index(value) !== -1
    }

    public index(value: any): number {
        return this.items.findIndex((i) => i.value === value)
    }

    public push(option: IChainOption, column: IChainOption[]) {
        this.items.push(option)
        this.columns.push(column)
    }

    public extend(chain: OptionChain) {
        for (let i = 0; i < chain.items.length; i++) {
            this.push(chain.items[i], chain.columns[i])
        }
    }

    public first() {
        return this.items[0]
    }

    public last() {
        return this.items[this.items.length - 1]
    }
}

export class ChainSelect<ValueType extends any> extends BetterComponent<
    IChainSelectProps<ValueType>,
    IChainSelectState
> {
    public constructor(props: IChainSelectProps<ValueType>) {
        super(props)

        this.state = {} as any
    }

    public async UNSAFE_componentWillReceiveProps() {
        if (this.props.value && this.props.value !== this.state.value) {
            this.handleSelectChange(this.props.value)
        }
    }

    public async componentDidMount() {
        this.handleSelectChange(this.props.value || this.props.options[0].value)
    }

    /**
     * Recursive depth-first search for an option in the chain of options with a matching value.
     * Once an option with a matching value is matched, that chain is returned.
     * For this reason, option values should be unique. If multiple options share a value, the wrong chain may
     *  be returned.
     *
     * Returns: IChainOption[]
     */
    public getOptionChain(options: IChainOption[], value: any): OptionChain | undefined {
        let chain: OptionChain | undefined

        if (value !== undefined) {
            for (let i = 0; i < options.length && !chain; i++) {
                const option = options[i]

                if (option.value && option.value === value) {
                    chain = new OptionChain()
                    chain.push(option, options)
                } else if (option.options) {
                    const subChain = this.getOptionChain(option.options, value)
                    if (subChain) {
                        chain = new OptionChain()
                        chain.push(option, options)
                        chain.extend(subChain)
                    }
                }
            }
        } else {
            chain = new OptionChain()
            chain.push(value, options)
        }

        return chain
    }

    @autobind
    public handleSelectChange(value: SelectValue) {
        const chain = this.getOptionChain(this.props.options, value) as OptionChain
        const option = chain.last()

        /**
         * If the selected option has child options, and the selected option does not
         * have a placeholder value, find child option that doesn't have options (or the first
         * available option) and make that the selected option.
         */
        if (option.options) {
            const newOption = option.options.filter((o) => !o.options)[0] || option.options[0]
            const newValue = newOption.value

            this.handleSelectChange(newValue)
        } else {
            this.setState({
                value: option.value,
                chain,
            })

            const valueHasChanged = value !== this.props.value
            if (!option.options && this.props.onChange && valueHasChanged) {
                this.props.onChange(value as ValueType)
            }
        }
    }

    public renderSelect(options: IChainOption[]): React.ReactNode {
        let selectedValue

        for (let i = 0; i < options.length && !selectedValue; i++) {
            const option = options[i]
            if (this.state.chain.contains(option.value)) {
                selectedValue = option.value
                break
            }
        }

        return options.length === 1 ? (
            <span className="single-option-label">{options[0].display}</span>
        ) : (
            <Select dropdownClassName="chain-dropdown-overlay" onChange={this.handleSelectChange} value={selectedValue}>
                {this.renderOptions(options)}
            </Select>
        )
    }

    public renderOptions(options: IChainOption[]): React.ReactFragment[] {
        return options.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    public render(): React.ReactNode {
        return (
            !!this.state.chain &&
            (this.props.mode === 'edit' ? (
                <div className="chain-select edit">
                    {this.state.chain.columns.map((options, i) => (
                        <React.Fragment key={i}>{this.renderSelect(options)}</React.Fragment>
                    ))}
                    {this.state.chain.last().options ? (
                        this.renderSelect(this.state.chain.last().options!)
                    ) : (
                        <React.Fragment />
                    )}
                </div>
            ) : (
                <div className="chain-select display">
                    {this.state.chain.items.map((item, i) => item.display).join(' ')}
                </div>
            ))
        )
    }
}
