import { MutableRefObject, Dispatch, SetStateAction } from 'react'
import { useRefState } from './use-ref-state'

export interface ILoadableDataState<T> {
    data?: T
    loading: boolean
}
export const useLoadableDataRefState = <T extends any>(
    initialState?: Partial<ILoadableDataState<T>>,
): [MutableRefObject<ILoadableDataState<T>>, Dispatch<SetStateAction<Partial<ILoadableDataState<T>>>>] => {
    const [currState, setCurrState] = useRefState({
        loading: false,
        ...(initialState ?? {}),
    })

    const setState = (state: Partial<ILoadableDataState<T>>) => {
        setCurrState({
            ...(currState.current ?? {}),
            ...(state ?? {}),
        } as ILoadableDataState<T>)
    }

    return [currState, setState]
}
