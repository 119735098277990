import React from 'react'
import './styles/notification-field-suggestions.scss'
import classnames from 'classnames'
import { Drawer } from '@pushly/aqe/lib/components'
import { FileUploadService } from '@pushly/aqe/lib/services/file-upload.service'
import { PinturaInternals } from '@pushly/aqe/lib/components/file-uploader/utils'
import { Form, Checkbox, Alert, Col, Row } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { NotificationService } from '../../services'
import {
    CompositeImage,
    INotificationFieldSuggestions,
    NotificationSuggestionField,
} from '../../interfaces/notification-field-suggestions'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { chunkArray } from '../../_utils/array'
import { useService } from '../../hooks/use-service'
import { extract } from '../../_utils/object'
import { DomainDto } from '../../dtos/domain'
import { NotificationAutoSuggestFields } from '../../enums/notification-auto-suggest-fields.enum'
import { useSubjectivelyBetterState } from '../../hooks/use-subjectively-better-state'
import { getFileBlobFromUrl, getImageFromBlob } from '../../_utils/images'
import { generateUID } from '../campaign-builder/helpers/uid'
import { noop } from '../../_utils/utils'

const { processImage, createDefaultImageReader, createDefaultImageWriter } = PinturaInternals

const getEmptyFieldSuggestions = (): INotificationFieldSuggestions => ({
    title: null,
    body: null,
    imageUrl: null,
    compositeImage: null,
    keywords: null,
})

const canShowField = (domain: DomainDto, field: keyof INotificationFieldSuggestions): boolean => {
    const allowedFields = domain.notificationAutoSuggestFields
    const key = (field === 'imageUrl' ? 'image_url' : field) as NotificationAutoSuggestFields
    return allowedFields === null ? true : allowedFields?.includes(key) ?? false
}

/**
 * NoneFoundAddOn component
 *   Re-usable post-label add on
 */
const NoneFoundAddOn = React.memo(() => <span className="none-found-addon">(No suggestions found)</span>)

/**
 * SuggestedField component (supports children)
 *   Generic field component handles selection and value display
 *   Value display can be overriden by passing child components
 */
interface ISuggestedFieldProps<F extends NotificationSuggestionField> {
    className?: string
    label: string
    field: F
    source: INotificationFieldSuggestions
    form: FormInstance<any>
    initialValue?: boolean
    indeterminate?: boolean
    onCheckboxChange?: (checked: boolean) => any
    contentClickable?: boolean
}

function SuggestedField<F extends NotificationSuggestionField>({
    className,
    label,
    field,
    source,
    form,
    initialValue,
    onCheckboxChange,
    indeterminate,
    contentClickable,
    children,
}: React.PropsWithChildren<ISuggestedFieldProps<F>>) {
    const [checked, setChecked] = React.useState(initialValue)
    const suggestion = extract(source, field)

    return (
        <div className={classnames('suggestion-section', className)}>
            <Form.Item name={field} valuePropName="checked" initialValue={checked}>
                <Checkbox
                    disabled={!suggestion}
                    indeterminate={indeterminate}
                    onChange={({ target: { checked: value } }) => {
                        onCheckboxChange?.(value)
                        setChecked(value)
                    }}
                >
                    {label} {!suggestion ? <NoneFoundAddOn /> : null}
                </Checkbox>
            </Form.Item>
            {suggestion === null ? null : (
                <div
                    className={classnames('field-found-suggestion', {
                        'content-clickable': contentClickable,
                    })}
                    onClick={
                        !contentClickable
                            ? undefined
                            : () => {
                                  onCheckboxChange?.(!checked)
                                  setChecked(!checked)
                                  form.setFieldsValue({ [field]: !checked })
                              }
                    }
                >
                    {children ?? <span>{suggestion}</span>}
                </div>
            )}
        </div>
    )
}

interface IProps {
    domain: DomainDto
    url?: string | null
    onClose?: () => any
    onAccept?: (selections: Partial<INotificationFieldSuggestions>) => any
    onSuggestionsLoaded?: (suggestions: INotificationFieldSuggestions) => any
}

interface IState {
    loading: boolean
    suggestions: INotificationFieldSuggestions
    selectedKeywords: string[] | null
    initialSelectionsMade: boolean
    lastParsedUrl?: string
    compositeImage: CompositeImage | null
}

const initialState: IState = {
    loading: false,
    suggestions: getEmptyFieldSuggestions(),
    initialSelectionsMade: false,
    selectedKeywords: null,
    compositeImage: null,
}

const NotificationFieldSuggestions = (props: IProps) => {
    const { domain, url, onClose, onAccept, onSuggestionsLoaded } = props

    const notifSvc = useService(NotificationService)
    const fileUploadSvc = useService(FileUploadService)
    const [form] = Form.useForm()
    const [state, setState] = useSubjectivelyBetterState(initialState)
    const { lastParsedUrl, suggestions, selectedKeywords, loading, initialSelectionsMade } = state

    const loadCompositeImage = React.useCallback(
        async (data: INotificationFieldSuggestions): Promise<CompositeImage | null> => {
            if (!data.imageUrl) {
                return null
            }

            try {
                const blob = await getFileBlobFromUrl(data.imageUrl)
                if (!blob) {
                    return null
                }

                const img = await getImageFromBlob(blob)

                const imageCropAspectRatio = 2
                let width = img.width
                let height = img.height

                if (width / height !== imageCropAspectRatio) {
                    if (width > height) {
                        width =
                            img.width / img.height > imageCropAspectRatio
                                ? img.height * imageCropAspectRatio
                                : img.width
                    }

                    height = width / imageCropAspectRatio
                }

                const processed = await processImage(blob as any, {
                    imageReader: createDefaultImageReader(),
                    imageWriter: createDefaultImageWriter({
                        targetSize: {
                            width: 400,
                            height: 200,
                            fit: 'cover',
                            upscale: false,
                        },
                        quality: domain.mediaQuality,
                    }),
                    imageCropAspectRatio: 2,
                    imageCrop: {
                        x: img.width / 2 - width / 2,
                        y: img.height / 2 - height / 2,
                        width,
                        height,
                    },
                    imageTargetSize: {
                        width: 400,
                        height: 200,
                    },
                })

                const uploaded = await fileUploadSvc.processFormDataUpload(
                    'image',
                    'domain',
                    domain.id,
                    'file',
                    processed.dest,
                    'public-read',
                    {
                        tmpId: generateUID(),
                        originalMediaUrl: data.imageUrl,
                        quality: domain.mediaQuality ?? 40,
                        progressive: true,
                    },
                    noop,
                )

                return {
                    ...processed,
                    url: uploaded.url,
                    filename: uploaded.filename,
                    originalMediaUrl: data.imageUrl,
                }
            } catch (err) {
                console.error('Suggested Image Loading Error', err)
            }

            return null
        },
        [domain.id],
    )

    /**
     * fetchSuggestions effect
     *   Fetch should only occur when both a domainId and url
     *   are present and the url is not the last parsed url.
     *
     *   On fetch completion the results are returned to the
     *   consumer.
     */
    React.useEffect(() => {
        const fetchSuggestions = async () => {
            setState({
                loading: true,
                initialSelectionsMade: false,
            })

            const res = await notifSvc.analyzeLandingUrl(domain.id, url!, {
                showLoadingScreen: false,
                cancellationKey: 'nfs.analyze',
            })

            let compositeImage = await loadCompositeImage(res.data)
            if (compositeImage) {
                res.data.imageUrl = compositeImage.url
            }

            setState((prevState) => ({
                loading: false,
                lastParsedUrl: url!,
                suggestions: res.data,
                selectedKeywords:
                    !prevState.initialSelectionsMade && Array.isArray(res.data?.keywords) ? res.data.keywords : null,
                compositeImage,
            }))

            onSuggestionsLoaded?.(res.ok ? res.data : getEmptyFieldSuggestions())
        }

        if (domain.id && url && url !== lastParsedUrl) {
            fetchSuggestions().then()
        }
    }, [domain.id, url, lastParsedUrl])

    /**
     * resetForm effect
     *   Anytime parse input changes or initialSelections are
     *   set to true the form should be reset back to initialValues.
     *
     *   This ensures the checkbox elements are properly reset.
     */
    React.useEffect(() => {
        if (initialSelectionsMade || url !== lastParsedUrl) {
            form.resetFields()
        }
    }, [initialSelectionsMade, url, lastParsedUrl])

    const noSuggestions = Object.values(suggestions ?? {}).every((v) => v === null)
    const showDrawerContent = !!url && !loading && !noSuggestions

    const hasTitleSuggestion = !!suggestions?.title
    const hasBodySuggestion = !!suggestions?.body
    const hasImageSuggestion = !!suggestions?.imageUrl
    const hasKwSuggestions = Array.isArray(suggestions?.keywords) && suggestions!.keywords.length > 0

    let suggestedImage: string | null
    if (state.compositeImage) {
        suggestedImage = extract(state.compositeImage, 'url')
    } else {
        suggestedImage = extract(suggestions, 'imageUrl')
    }

    const suggestedKws = extract(suggestions, 'keywords', new Array<string>())

    const totalSuggestedKws = suggestions?.keywords?.length ?? 0
    const totalSelectedKws = selectedKeywords?.length ?? 0
    const allKwSelection = hasKwSuggestions && totalSelectedKws === totalSuggestedKws
    const paritalKwSelection = hasKwSuggestions && !allKwSelection && totalSelectedKws > 0

    const ttlKwCunks = 3
    const kwChunks = chunkArray(suggestedKws, ttlKwCunks)

    return (
        <Drawer
            className={classnames('notification-field-suggestions-drawer')}
            visible={!!url}
            title="Content Suggestions"
            onClose={onClose}
            onSubmit={() => {
                const values = form.getFieldsValue()
                let selections: Partial<INotificationFieldSuggestions> = {}

                if (values.title) {
                    selections.title = suggestions!.title
                }
                if (values.body) {
                    selections.body = suggestions!.body
                }
                if (values.imageUrl) {
                    if (state.compositeImage) {
                        selections.compositeImage = state.compositeImage
                        selections.imageUrl = state.compositeImage.url
                    } else {
                        selections.imageUrl = suggestions!.imageUrl
                    }
                }
                if ((selectedKeywords?.length ?? 0) > 0) {
                    selections.keywords = selectedKeywords
                }

                onAccept?.(selections)
                setState({
                    initialSelectionsMade: true,
                    selectedKeywords: null,
                })
            }}
            submitText={!loading && noSuggestions ? 'Go Back' : 'Accept Suggestions'}
            disableSubmit={loading}
        >
            <div className="pre-form-details">
                <Alert
                    className={classnames({
                        'alert-loading': loading,
                    })}
                    type={!loading && noSuggestions ? 'warning' : 'success'}
                    icon={!loading ? undefined : <Loading3QuartersOutlined spin={true} />}
                    showIcon={true}
                    message={
                        loading ? (
                            <span>Loading suggestions...</span>
                        ) : noSuggestions ? (
                            <span>We were unable to find any suggestions for the designated Landing URL.</span>
                        ) : (
                            <span>
                                We found the following suggestions for the designated Landing URL. Please review and
                                select which items you would like to use.
                            </span>
                        )
                    }
                />
            </div>

            {showDrawerContent && (
                <Form form={form}>
                    {canShowField(domain, 'title') && (
                        <SuggestedField
                            label="Title"
                            field="title"
                            source={suggestions!}
                            form={form}
                            initialValue={!hasTitleSuggestion ? undefined : !initialSelectionsMade}
                            contentClickable={true}
                        />
                    )}

                    {canShowField(domain, 'body') && (
                        <SuggestedField
                            label="Body"
                            field="body"
                            source={suggestions!}
                            form={form}
                            initialValue={!hasBodySuggestion ? undefined : !initialSelectionsMade}
                            contentClickable={true}
                        />
                    )}

                    {canShowField(domain, 'imageUrl') && (
                        <SuggestedField
                            label="Image"
                            field="imageUrl"
                            source={suggestions!}
                            form={form}
                            initialValue={!hasImageSuggestion ? undefined : !initialSelectionsMade}
                            contentClickable={true}
                        >
                            <div className="suggested-image-wrapper">
                                <div
                                    className="suggested-image"
                                    style={{ backgroundImage: `url('${suggestedImage}')` }}
                                />
                            </div>
                        </SuggestedField>
                    )}

                    {canShowField(domain, 'keywords') && (
                        <SuggestedField
                            className="kw-suggestions"
                            label="Keywords"
                            field="keywords"
                            source={suggestions!}
                            form={form}
                            initialValue={!hasKwSuggestions ? undefined : !initialSelectionsMade}
                            indeterminate={paritalKwSelection}
                            onCheckboxChange={(checked) =>
                                setState({
                                    selectedKeywords: checked ? suggestions?.keywords : null,
                                })
                            }
                        >
                            <Checkbox.Group
                                value={selectedKeywords as string[]}
                                onChange={(keywords: string[]) => {
                                    setState({ selectedKeywords: keywords })
                                    form.setFieldsValue({ keywords: keywords.length === suggestedKws.length })
                                }}
                            >
                                {kwChunks.map((kws, gidx) => (
                                    <Col key={gidx} span={Math.floor(24 / ttlKwCunks)}>
                                        {kws.map((kw, idx) => (
                                            <Row key={idx}>
                                                <Checkbox value={kw}>{kw}</Checkbox>
                                            </Row>
                                        ))}
                                    </Col>
                                ))}
                            </Checkbox.Group>
                        </SuggestedField>
                    )}
                </Form>
            )}
        </Drawer>
    )
}

export default NotificationFieldSuggestions
