import * as React from 'react'
import { IBadgeProps } from './interfaces'
import { PictureFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'

export const NotificationImageBadge: React.StatelessComponent<IBadgeProps> = (props) => {
    const badge = (
        <span className="icon-image">
            <PictureFilled />
        </span>
    )

    return (
        <span className="notification-image-badge">
            {props.hideTooltip ? badge : <Tooltip title={props.tooltip || 'Contains Image'}>{badge}</Tooltip>}
        </span>
    )
}
