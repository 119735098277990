import { convertCase } from '../../_utils/utils'
import * as clone from 'clone'
import { stripUndefined } from '../../_utils/strip-undefined'
import { getDate } from '../../_utils/moment'
import { ScheduleConfigurationModel } from './schedule-configuration.model'
import * as moment from 'moment-timezone'

export class SavedReportScheduleModel {
    public static build(props: SavedReportScheduleModel | any): SavedReportScheduleModel {
        const data = props instanceof SavedReportScheduleModel ? props.serialize() : clone(props)
        const scData = convertCase(data, 'snake')

        const model = new SavedReportScheduleModel()
        model.setId(scData.id)
        model.setSavedReportId(scData.saved_report_id)
        model.setConfiguration(scData.configuration)
        model.setDateStart(scData.date_start ?? moment(new Date()))
        model.setDateEnd(scData.date_end)

        return model
    }

    private id?: number
    private saved_report_id: number
    private configuration: ScheduleConfigurationModel
    private date_start?: string
    private date_end?: string

    public clone(): SavedReportScheduleModel {
        return SavedReportScheduleModel.build(this)
    }

    public serialize(_: boolean = true): any {
        return stripUndefined({
            id: this.getId(),
            saved_report_id: this.getSavedReportId(),
            configuration: this.getConfiguration().serialize(),
            date_start: this.getDateStart(),
            date_end: this.getDateEnd(),
        })
    }

    public setId(id: number | undefined) {
        this.id = id
    }
    public getId(): number | undefined {
        return this.id
    }

    public setSavedReportId(savedReportId: number) {
        this.saved_report_id = savedReportId
    }
    public getSavedReportId(): number {
        return this.saved_report_id
    }

    public setConfiguration(config: ScheduleConfigurationModel | any): void {
        this.configuration = ScheduleConfigurationModel.build(config ?? {})
    }
    public getConfiguration(): ScheduleConfigurationModel {
        return this.configuration
    }

    public setDateStart(dateStart: string | undefined): void {
        this.date_start = !dateStart ? undefined : getDate(dateStart, 'UTC', 'YYYY-MM-DD')
    }
    public getDateStart(): string | undefined {
        return this.date_start
    }

    public setDateEnd(dateEnd: string | undefined): void {
        this.date_end = !dateEnd ? undefined : getDate(dateEnd, 'UTC', 'YYYY-MM-DD')
    }
    public getDateEnd(): string | undefined {
        return this.date_end
    }
}
