import * as React from 'react'
import './notification-details.scss'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Tabs } from 'antd'
import autobind from 'autobind-decorator'
import { AppService } from '../../../services'

export interface IBaseNotificationDetailsProps {}

export interface IBaseNotificationDetailsState {
    activeTab?: ITab
    tabs: ITab[]
}

export interface ITab {
    name: string
    label: string
    render: () => React.ReactNode
}

export abstract class BaseNotificationDetails<
    IProps extends IBaseNotificationDetailsProps,
    IState extends IBaseNotificationDetailsState,
> extends BetterComponent<IProps, IState> {
    protected abstract appService: AppService

    protected renderTabs(): React.ReactNode {
        let tabsFragment: React.ReactNode

        if (this.state.tabs && this.state.activeTab) {
            tabsFragment = (
                <Tabs activeKey={this.state.activeTab.name} onChange={this.onTabChange} animated={true}>
                    {this.renderTabPanes()}
                </Tabs>
            )
        } else {
            tabsFragment = <React.Fragment></React.Fragment>
        }

        return tabsFragment
    }

    protected determineActiveTab(tabs: ITab[]) {
        const lastUrlPart = location.pathname.split('/').pop()
        const matchedTabs = tabs.filter((t) => t.name === lastUrlPart)

        let activeTab: ITab
        if (matchedTabs.length) {
            activeTab = matchedTabs[0]
        } else {
            activeTab = tabs[0]
        }

        return activeTab
    }

    private renderTabPanes(): React.ReactNode[] {
        const renderedTabs: React.ReactNode[] = []

        this.state.tabs.forEach((t) => {
            const tabRender = (
                <Tabs.TabPane key={t.name} tab={t.label}>
                    {t.render.bind(this)()}
                </Tabs.TabPane>
            )
            renderedTabs.push(tabRender)
        })
        return renderedTabs
    }

    @autobind
    private async onTabChange(key: string) {
        const activeTab = this.state.tabs.filter((t) => t.name === key)[0]

        await this.setState({
            activeTab,
        })

        const pathParts = location.pathname.split('/')
        pathParts.pop()
        pathParts.push(key)

        const tabUrl = pathParts.join('/')
        // history.replaceState({}, document.title, tabUrl);
        this.appService.route(tabUrl)
    }
}
