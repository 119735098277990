import React from 'react'
import './styles/variant-manager.scss'
import { getClassNames } from '../../../_utils/classnames'
import { CloseOutlined, Loading3QuartersOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Skeleton } from 'antd'
import clone from 'clone'
import { useRefState } from '../../../hooks/use-ref-state'
import { useMountedRef } from '../../../_utils/use-mounted-ref'

interface IVariantManager<T> {
    className?: string
    defaultValue?: T[]
    value?: T[]
    loading?: boolean
    selectedIndex?: number
    onChange?: (value: T[], selectedIdx: number) => any
    onAdd?: (variant: T) => any
    onRemove?: (variant: T, idx: number, selectedIdx: number) => any
    onSelectedIndexChange?: (index: number) => any
}

const MIN_VARIANTS = 1
const MAX_VARIANTS = 4
const VARIANT_LETTER = ['a', 'b', 'c', 'd']

const AddVariantButton = ({ onClick }: any) => {
    const [loading, setLoading] = useRefState(false)
    const runIfMounted = useMountedRef()[1]

    return (
        <span
            onClick={async () => {
                setLoading(true)
                await onClick()
                runIfMounted(() => setLoading(false))
            }}
        >
            {loading.current ? <Loading3QuartersOutlined spin={true} /> : <PlusCircleFilled />}
        </span>
    )
}

const VariantManager = <T extends any>(props: IVariantManager<T>) => {
    const runIfMounted = useMountedRef()[1]
    const [tmpVariants, setTmpVariants] = React.useState<T[]>(props.value ?? props.defaultValue ?? [{} as T])
    const [tmpSelectedIdx, setTmpSelectedIdx] = React.useState(props.selectedIndex ?? 0)

    const workingVariants = props.value !== undefined ? clone(props.value) : clone(tmpVariants)
    const workingCount = workingVariants.length
    const workingIdx = props.selectedIndex !== undefined ? props.selectedIndex : tmpSelectedIdx

    const handleTreatmentAdd = async () => {
        if (workingCount >= MAX_VARIANTS) {
            return
        }

        const newVariant = clone(workingVariants[workingIdx] ?? workingVariants[workingVariants.length - 1])
        const newVariants = [...workingVariants, newVariant]

        await props.onAdd?.(newVariant)
        props.onChange?.(newVariants, workingCount)

        runIfMounted(() => {
            setTmpVariants(newVariants)
        })
    }

    const handleTreatmentRemove = (idx) => {
        if (workingCount <= MIN_VARIANTS) {
            return
        }

        const rmVariant = workingVariants.splice(idx, 1)[0]
        const newCount = workingVariants.length

        const rmIdxIsFirstItem = idx === 0
        const rmIdxIsLastItem = idx >= newCount
        const rmIdxIsSelectedItem = idx === workingIdx
        const wkIdxIsLastItem = workingIdx >= newCount

        const newIdx =
            rmIdxIsSelectedItem && rmIdxIsFirstItem
                ? 0
                : rmIdxIsSelectedItem && rmIdxIsLastItem
                ? newCount - 1
                : rmIdxIsSelectedItem || !wkIdxIsLastItem
                ? workingIdx
                : workingIdx - 1

        props.onRemove?.(rmVariant, idx, newIdx)
        props.onChange?.(workingVariants, newIdx)

        runIfMounted(() => {
            setTmpVariants(workingVariants)
        })
    }

    const [validatingVidx, setValidatingVidx] = useRefState<number | undefined>(undefined)
    const handleSelectedIndexChange = async (idx) => {
        setValidatingVidx(workingIdx)
        await props.onSelectedIndexChange?.(idx)

        runIfMounted(() => {
            setTmpSelectedIdx(idx)
            setValidatingVidx(undefined)
        })
    }

    return (
        <div
            className={getClassNames(null, 'variant-manager', props.className, {
                empty: workingCount === 0,
                full: workingCount >= MAX_VARIANTS,
            })}
        >
            <Skeleton loading={props.loading} active={false} avatar={false} title={false} paragraph={{ rows: 1 }}>
                <div className={getClassNames(null, 'variant-manager-variant-selectors')}>
                    {workingVariants.map((v, idx) => {
                        const variantId = idx + 1

                        return (
                            <div
                                key={variantId}
                                className={getClassNames(
                                    null,
                                    'variant-manager-variant-selector',
                                    `variant-${VARIANT_LETTER[idx]}`,
                                    {
                                        selected: idx === workingIdx,
                                        disabled: workingCount === 1,
                                    },
                                )}
                                onClick={() => handleSelectedIndexChange(idx)}
                            >
                                <label>Variant {variantId}</label>
                                <span
                                    onClick={(ev) => {
                                        ev.stopPropagation()
                                        ev.preventDefault()

                                        handleTreatmentRemove(idx)
                                    }}
                                >
                                    {idx === validatingVidx.current ? (
                                        <Loading3QuartersOutlined spin={true} />
                                    ) : (
                                        <CloseOutlined />
                                    )}
                                </span>
                            </div>
                        )
                    })}
                    <div className={getClassNames(null, 'variant-manager-variant-selector', 'new-variant-btn')}>
                        <AddVariantButton onClick={handleTreatmentAdd} />
                    </div>
                </div>
            </Skeleton>
        </div>
    )
}

export default VariantManager
