import * as React from 'react'
import { CampaignNotificationsComparisonContext } from './campaign-notifications-comparison-context'
import classnames from 'classnames'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { CampaignNotificationCompTabState } from './interfaces'
import { Button, Divider, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

type FilterKey = keyof CampaignNotificationCompTabState['filters']

interface ICNLHProps {
    title: string
    filterSize?: SizeType
    filtersAddonBefore?: React.ReactNode
    filtersAddonAfter?: React.ReactNode
    hideRefresh?: boolean
    hideRowSelection?: boolean
    hideFilters?: FilterKey | FilterKey[] | boolean
    filtersDisabled?: FilterKey | FilterKey[] | boolean
    hideCompare?: boolean
    compareLabel?: string
    refreshing?: boolean
}

export const CampaignNotificationsListHeader = (props: ICNLHProps) => {
    const context: React.ContextType<typeof CampaignNotificationsComparisonContext> = React.useContext(
        CampaignNotificationsComparisonContext,
    )

    const { filters, onRefreshClick, refreshEnabled, onCompareClick } = context

    const { title, filtersAddonBefore, filterSize, hideFilters, hideRefresh, hideCompare, compareLabel, refreshing } =
        props

    const compareDisabled = context.selectedNotifications.length < 2
    return (
        <div className={classnames('campaign-notifs-list-header')}>
            <div className={classnames('campaign-notifs-list-title')}>
                <span>{title}</span>
            </div>

            <div
                className={classnames('campaign-notifs-list-compare', {
                    hidden: hideCompare,
                })}
            >
                <span className="compare-label">{compareLabel}</span>
                <Button className="ant-btn" disabled={compareDisabled} size={filterSize} onClick={onCompareClick}>
                    <span>Compare</span>
                </Button>
            </div>
            {!hideRefresh && (
                <>
                    <Divider type="vertical" orientation="center" />

                    <div
                        className={classnames('campaign-notifs-list-refresh', {
                            hidden: hideRefresh,
                        })}
                    >
                        <Tooltip title="Refresh">
                            <Button
                                className={classnames('campaign-notifs-list-refresh-toggle')}
                                disabled={!refreshEnabled}
                                size="small"
                                shape="round"
                                onClick={onRefreshClick}
                            >
                                <ReloadOutlined spin={refreshing} />
                            </Button>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    )
}
