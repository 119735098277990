import { PlatformFlagEntities } from '../../enums/platform-flag-entities'
import { stripUndefined } from '../../_utils/strip-undefined'

export class FlagModel {
    public static build(data: any | any): FlagModel {
        data = data instanceof FlagModel ? data.serialize() : data

        const model = new FlagModel()
        model.setKey(data.key)
        model.setDisplayName(data.displayName)
        model.setDescription(data.description)
        model.setApplicableEntities(data.applicableEntities ?? [])
        model.setDefaultForEntities(data.defaultForEntities ?? [])
        model.setIsActive(data.isActive)
        model.setIsDevOnly(data.isDevOnly)

        return model
    }

    private key: string
    private displayName: string
    private description: string
    private applicableEntities: PlatformFlagEntities[]
    private defaultForEntities: PlatformFlagEntities[]
    private isActive: boolean
    private isDevOnly: boolean

    public serialize(): any {
        return stripUndefined({
            key: this.getKey(),
            displayName: this.getDisplayName(),
            description: this.getDescription(),
            applicableEntities: this.getApplicableEntities(),
            defaultForEntities: this.getDefaultForEntities(),
            isActive: this.getIsActive(),
            isDevOnly: this.getIsDevOnly(),
        })
    }

    public clone() {
        return FlagModel.build(this)
    }

    public setKey(key: string): void {
        this.key = key
    }
    public getKey(): string {
        return this.key
    }

    public setDisplayName(displayName: string): void {
        this.displayName = displayName
    }
    public getDisplayName(): string {
        return this.displayName
    }

    public setDescription(description: string): void {
        this.description = description
    }
    public getDescription(): string {
        return this.description
    }

    public setApplicableEntities(applicableEntities: PlatformFlagEntities[]): void {
        this.applicableEntities = applicableEntities
    }
    public getApplicableEntities(): PlatformFlagEntities[] {
        return this.applicableEntities
    }

    public setDefaultForEntities(defaultForEntities: PlatformFlagEntities[]): void {
        this.defaultForEntities = defaultForEntities
    }
    public getDefaultForEntities(): PlatformFlagEntities[] {
        return this.defaultForEntities
    }

    public setIsActive(isActive: boolean): void {
        this.isActive = isActive
    }
    public getIsActive(): boolean {
        return this.isActive
    }

    public setIsDevOnly(isDevOnly: boolean): void {
        this.isDevOnly = isDevOnly
    }
    public getIsDevOnly(): boolean {
        return this.isDevOnly
    }
}
