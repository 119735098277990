import * as React from 'react'
import { BetterComponent } from '../../components/better-component/better-component'
import { AppState } from '../../stores/app'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { InsightsService } from '../../services/insights'
import './domain-stats-overview.scss'
import { observe } from 'mobx'
import autobind from 'autobind-decorator'
import * as Highcharts from 'highcharts'
import * as moment from 'moment-timezone'
import { arrayUnique, numberWithCommas } from '../../_utils/utils'
import HighchartsReact from 'highcharts-react-official'
import { LoadingOutlined } from '@ant-design/icons'

interface IRecentNotifsProps {
    className?: string
    limit?: number
}

interface IRecentNotifsState {
    loading: boolean
    stats: any[]
}

export class DomainStatsOverview extends BetterComponent<IRecentNotifsProps, IRecentNotifsState> {
    public static readonly defaultNotificationLimit: number = 3

    public readonly defaultClassName: string = 'sw-mv-domain-stats-overview'

    protected appState: AppState
    protected appService: AppService
    protected insightsService: InsightsService

    protected disposeObservers: any[] = []

    public constructor(props: IRecentNotifsProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
        this.insightsService = Container.get(InsightsService)

        this.state = {
            loading: true,
            stats: [],
        }
    }

    public UNSAFE_componentWillMount() {
        this.disposeObservers.push(observe(this.appState, 'currentDomainJsonData', () => this.fetchStats(true)))
    }

    public componentDidMount(): void {
        this.fetchStats()
    }

    public componentWillUnmount() {
        super.componentWillUnmount()

        this.disposeObservers.forEach((fn) => fn())
    }

    public render(): React.ReactNode {
        return (
            <div className={this.buildRootClassNames()}>
                <div className={this.buildClassName('wrapper')}>
                    <div className={this.buildClassName('widgets')}>
                        <div className={this.buildClassName('widgets-wrapper')}>
                            {this.renderRecentSessionsWidget()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected renderRecentSessionsWidget(): React.ReactNode {
        return (
            <div className={`${this.buildClassName('widget')} ${this.state.loading ? 'loading' : 'loaded'}`}>
                <div className={this.buildClassName('widget-wrapper')}>
                    <div className={this.buildClassName('recent-sessions')}>
                        <div className={this.buildClassName('recent-sessions-wrapper')}>
                            <div className="info">
                                <span className="data">{this.yesterdaysClicks}</span>
                                <span className="title">Clicks Yesterday</span>
                            </div>

                            {this.renderRecentSesssionsGraph()}
                        </div>
                    </div>
                </div>

                <div className={this.buildClassName('widget-loader-wrapper')}>
                    <div className={this.buildClassName('widget-loader')}>
                        <LoadingOutlined spin={true} />
                    </div>
                </div>
            </div>
        )
    }

    protected renderRecentSesssionsGraph(): React.ReactNode {
        if (this.stats.length === 0) {
            return
        }

        const clicksData = this.stats.map((stat: any) => stat.clicks || 0)

        const options = {
            chart: {
                type: 'area',
                backgroundColor: 'transparent',
                height: 48,
                spacing: 0,
                margin: 0,
                plotBorderWidth: 0,
                borderWidth: 0,
                showAxes: false,
            },
            responsive: true,
            title: {
                text: '',
            },
            subtitle: {
                text: '',
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                enabled: false,
                lineWidth: 0,
                gridLineWidth: 0,
                minPadding: 0,
                maxPadding: 0,
                margin: 0,
                lineColor: 'transparent',
                allowDecimals: false,
                labels: {
                    enabled: false,
                },
                title: {
                    text: '',
                },
            },
            yAxis: {
                enabled: false,
                lineWidth: 0,
                gridLineWidth: 0,
                minPadding: 0,
                maxPadding: 0,
                margin: 0,
                lineColor: 'transparent',
                labels: {
                    enabled: false,
                },
                title: {
                    text: '',
                },
            },
            plotOptions: {
                area: {
                    pointStart: 1940,
                    marker: {
                        enabled: false,
                    },
                },
            },
            series: [
                {
                    name: 'a',
                    data: clicksData,
                    color: '#fff',
                },
            ],
        }

        return <HighchartsReact highcharts={Highcharts} options={options} />
    }

    protected get stats(): any[] {
        return this.state.stats || []
    }

    protected get yesterdaysClicks(): string {
        let clicks = 0

        const yesterdayDate = moment().subtract(1, 'd').format('YYYY-MM-DD')
        const yesterdayStat = this.stats.find((s: any) => s.send_date === yesterdayDate)
        if (!!yesterdayStat) {
            clicks = yesterdayStat.clicks || clicks
        }

        return numberWithCommas(clicks)
    }

    @autobind
    protected async fetchStats(reloadReplace: boolean = false): Promise<void> {
        if (!this.appState.currentDomain) {
            setTimeout(() => this.fetchStats(), 100)
            return
        }

        if (reloadReplace) {
            this.setState({
                loading: true,
            })
        }

        const stats = await this.insightsService.fetch(
            {
                entity: 'notifications',
                date_preset: 'last_14_days',
                date_increment: 'day',
                action_attribution: 'send_time',
                breakdowns: ['domain'],
                fields: ['deliveries', 'impressions', 'clicks', 'delivery_rate_decimal', 'ctr_decimal'],
                filters: [{ field: 'domain.id', operator: 'eq', value: this.appState.currentDomain!.id }],
            },
            false,
            'dso.fetch',
        )

        if (!stats) {
            // Handles a canceled call
            return
        }

        const compiledStats: any[] = []
        const today = moment().format('YYYY-MM-DD')
        const days: string[] = arrayUnique(stats.map((s: any) => s.send_date))
        days.forEach((day) => {
            if (day !== today) {
                const dayClicks = stats
                    .filter((s: any) => s.send_date === day)
                    .reduce((n: number, stat: any) => (n += stat.clicks), 0)

                compiledStats.push({
                    send_date: day,
                    clicks: dayClicks,
                })
            }
        })

        compiledStats.sort((a: any, b: any) => (a.send_date > b.send_date ? 1 : a.send_date < b.send_date ? -1 : 0))

        this.setState({
            stats: compiledStats,
            loading: false,
        })
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        if (this.props.className) classNames.push(this.props.className)

        return classNames.join(' ')
    }
}
