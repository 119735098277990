export const getPathEntityId = (entity: string, pathname?: string): number | undefined => {
    const regex = new RegExp(`\\/${entity}s\\/(?<id>[\\d]+)`, 'i')
    const match = regex.exec(pathname ?? window.location.pathname)
    let id
    if (match !== null) {
        id = match.groups?.id
        try {
            if (entity !== 'notification') {
                id = parseInt(id, 10)
            }
        } catch (_) {}
    }
    return id
}
