export interface IBellPromptTheme {
    position: {
        desktop: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right' | 'disabled'
        mobile: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right' | 'disabled'
    }
    tooltip?: {
        unsubscribed?: string
        subscribed?: string
    }
    size: number
    desktopSize?: number
    mobileSize: number
    primaryColor: string
    accentColor: string
}

interface IBellPromptBehaviors {
    displayBehavior: 'ALWAYS' | 'PRE_SUBSCRIPTION' | 'POST_SUBSCRIPTION'
}

export class PromptBellConfigDto {
    public theme: IBellPromptTheme
    public behavior: IBellPromptBehaviors
}
