import * as React from 'react'

export const IconMacro = () => (
    <svg viewBox="0 0 1024 1024" data-icon="macro" height="1em" width="1em" fill="currentColor" aria-hidden="true">
        <path
            d="M425.313,27h-15.906c-77.037,0-139.312,2.018-184.535,26.573
		C174.305,81.029,151,133.881,151,219.757v164.369c0,42.831-5.291,65.283-17.653,77.495c-12.764,12.608-36.744,18.224-72.854,26.726
		c-8.917,2.099-19.224,4.479-29.932,7.156C23.884,497.172,19,503.172,19,510.055v2.651c0,6.883,4.822,12.883,11.499,14.552
		c10.708,2.677,20.883,5.057,29.801,7.156c36.11,8.501,60.249,14.117,73.012,26.725C145.675,573.352,151,595.803,151,638.634
		v164.369c0,85.875,23.305,138.847,73.872,166.304C270.096,993.861,332.371,996,409.407,996h15.906
		c8.284,0,14.687-7.089,14.687-15.373v-74.231c0-8.284-6.559-15-14.843-15c-57.242,0-99.563-1.155-125.914-16.106
		C273.675,860.781,263,831.631,263,777.816V646.587c0-2.433-0.15-4.849-0.146-7.247c0.056-27.714,0.03-53.892-7.446-77.712
		c-6.364-20.275-17.798-36.868-34.632-50.248c16.834-13.38,28.21-29.973,34.574-50.249c7.476-23.82,7.569-49.998,7.514-77.712
		c-0.005-2.398,0.136-4.814,0.136-7.247v-131.23c0-53.814,10.754-82.964,36.321-97.472c26.351-14.952,68.671-16.107,125.913-16.107
		c8.284,0,14.766-6.716,14.766-15V42.133C440,33.849,433.598,27,425.313,27z"
        />
        <path
            d="M991.518,495.503c-10.707-2.677-20.875-5.056-29.791-7.156
		c-36.111-8.501-59.763-14.118-72.526-26.725c-12.362-12.212-17.2-34.665-17.2-77.496V219.757
		c0-85.876-23.787-138.728-74.355-166.185C752.422,29.018,689.664,27,612.627,27h-15.906C588.437,27,582,33.849,582,42.133v74.231
		c0,8.284,6.576,15,14.86,15c57.242,0,99.554,1.155,125.905,16.107C748.334,161.979,759,191.129,759,244.943v131.23
		c0,2.433,0.168,4.85,0.163,7.248c-0.056,27.714-0.022,53.891,7.454,77.711c6.363,20.275,17.803,36.867,34.637,50.248
		c-16.834,13.379-28.208,29.972-34.571,50.247c-7.477,23.82-7.586,49.997-7.53,77.712c0.005,2.398-0.152,4.814-0.152,7.248v131.229
		c0,53.813-10.736,82.965-36.305,97.473c-26.351,14.951-68.68,16.106-125.922,16.106c-8.283,0-14.773,6.716-14.773,15v74.231
		c0,8.284,6.437,15.373,14.721,15.373h15.906c77.036,0,139.793-2.139,185.018-26.693C848.213,941.85,872,888.878,872,803.003
		V638.634c0-42.831,4.809-65.283,17.17-77.495c12.764-12.607,36.503-18.224,72.614-26.725c8.917-2.1,19.085-4.479,29.792-7.156
		c6.678-1.669,11.424-7.669,11.424-14.552v-2.651C1003,503.172,998.195,497.172,991.518,495.503z"
        />
    </svg>
)
