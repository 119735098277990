import * as React from 'react'
import * as changeCase from 'change-case'
import * as moment from 'moment'
import { Cell as FDCell } from 'fixed-data-table-2'
import { numberWithCommas, numberWithPercent } from '../../../_utils/utils'
import { SHORT_DATE_FORMAT } from '../../../constants'
import { AppState } from '../../../stores/app'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

export const SUBSCRIBER_TABLE_COLUMN_DEFINITIONS = {
    ['DATE']: {
        className: 'r-record-date',
        width: 140,
        columnKey: 'event_date',
        title: 'Event Date',
        align: 'center',
        sortable: true,
        render(data: any, dataSet: any[], config: any) {
            let value = moment(data.event_date).format(SHORT_DATE_FORMAT)

            if (config.dateIncrement === 'MONTH') {
                value = moment(data.event_date).format('MMM, YYYY')
            } else if (config.dateIncrement === 'YEAR') {
                value = moment(data.event_date).format('YYYY')
            }

            return value
        },
    },
    ['PROMPT.ID']: {
        className: 'r-id',
        width: 120,
        columnKey: 'prompt.id',
        title: 'Prompt ID',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.prompt || !data.prompt.id ? '--' : data.prompt.id
        },
    },
    ['PLACEMENT']: {
        className: 'r-title',
        width: 140,
        columnKey: 'placement',
        title: 'Device Type',
        sortable: true,
        render(data: any) {
            return changeCase.title(data.placement)
        },
    },
    ['BROWSER']: {
        className: 'r-browser',
        width: 140,
        columnKey: 'browser',
        title: 'Browser',
        sortable: true,
    },
    ['OPERATING_SYSTEM']: {
        className: 'r-os',
        width: 140,
        columnKey: 'operating_system',
        title: 'Operating System',
        sortable: true,
    },
    ['PROMPT.NAME']: {
        className: 'r-prompt-name',
        width: 320,
        columnKey: 'prompt.name',
        title: 'Name',
        sortable: true,
        render(data: any, dataSet: any[], config: any, appState: AppState) {
            const promptId = data.prompt.id
            const promptGroupId = (data.group || {}).id

            return !promptId || !promptGroupId ? (
                data.prompt.name
            ) : (
                <a href={`/domains/${appState.currentDomain!.id}/prompts/${promptGroupId}`} target="_blank">
                    {data.prompt.name}
                </a>
            )
        },
    },
    ['PROMPT.STYLE']: {
        className: 'r-style',
        width: 100,
        columnKey: 'prompt.style',
        title: 'Style',
        align: 'center',
        sortable: true,
        render(data: any) {
            return changeCase.title(data.prompt.style)
        },
    },
    ['PROMPT.PRIORITY']: {
        className: 'r-style',
        width: 100,
        columnKey: 'prompt.priority',
        title: 'Priority',
        align: 'center',
        sortable: true,
        sortType: 'number',
    },
    ['PROMPT.VISITOR_CONDITIONS']: {
        className: 'r-v-conditions',
        width: 240,
        columnKey: 'prompt.visitor_conditions',
        title: 'Visitor Conditions',
        sortable: true,
        sort(a: any, b: any) {
            const aRecord = a.prompt.visitor_conditions || {}
            const bRecord = b.prompt.visitor_conditions || {}

            const extractor = (conditions: any) => {
                let response: any = 'On Page Load'

                if (conditions.time_on_page_seconds) {
                    response = `Time on Page: ${conditions.time_on_page_seconds} Seconds`
                } else if (conditions.session_time_on_site_seconds) {
                    response = `Time on Site: ${conditions.session_time_on_site_seconds} Seconds`
                } else if (conditions.session_page_views) {
                    response = `# Page Views: ${conditions.session_page_views} Views`
                }

                return response
            }

            const aValue = extractor(aRecord)
            const bValue = extractor(bRecord)

            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
        },
        render(data: any, dataSet: any[]) {
            const conditions = data.prompt.visitor_conditions || {}

            let response: any = 'On Page Load'

            if (conditions.time_on_page_seconds) {
                response = `Time on Page: ${conditions.time_on_page_seconds} Seconds`
            } else if (conditions.session_time_on_site_seconds) {
                response = `Time on Site: ${conditions.session_time_on_site_seconds} Seconds`
            } else if (conditions.session_page_views) {
                response = `# Page Views: ${conditions.session_page_views} Views`
            }

            return response
        },
    },
    ['TEST.ID']: {
        className: 'r-test-id',
        width: 100,
        columnKey: 'test.id',
        title: 'A/B Test ID',
        sortable: true,
        sortType: 'number',
        render(data: any, dataSet: any[]) {
            const test = data.test || {}
            return test.id
        },
    },
    ['TEST.NAME']: {
        className: 'r-test-name',
        width: 240,
        columnKey: 'test.name',
        title: 'A/B Test Name',
        sortable: true,
        render(data: any, dataSet: any[]) {
            const test = data.test || {}
            return test.name
        },
    },
    ['PERMISSIONS_SHOWN']: {
        className: 'r-perms-shown',
        width: 120,
        columnKey: 'permissions_shown',
        title: (
            <>
                Permissions
                <br />
                Shown
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.permissions_shown)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.permissions_shown).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PERMISSIONS_DISMISSED']: {
        className: 'r-perms-dismissed',
        width: 120,
        columnKey: 'permissions_dismissed',
        title: (
            <>
                Permissions
                <br />
                Dismissed
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.permissions_dismissed)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.permissions_dismissed).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PERMISSIONS_DENIED']: {
        className: 'r-perms-denied',
        width: 120,
        columnKey: 'permissions_denied',
        title: (
            <>
                Permissions
                <br />
                Denied
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.permissions_denied)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.permissions_denied).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_SHOWN']: {
        className: 'r-prompts-shown',
        width: 120,
        columnKey: 'prompts_shown',
        title: (
            <>
                Prompts
                <br />
                Shown
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_shown)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.prompts_shown).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_SHOWN_NON_UNIQUE']: {
        className: 'r-prompts-shown-nuq',
        width: 120,
        columnKey: 'prompts_shown_non_unique',
        title: (
            <>
                Prompts Shown
                <br />
                (Non-Unique)
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_shown_non_unique)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet
                .map((s) => s.prompts_shown_non_unique)
                .reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_ACCEPTED']: {
        className: 'r-prompts-accepted',
        width: 120,
        columnKey: 'prompts_accepted',
        title: (
            <>
                Prompts
                <br />
                Accepted
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_accepted)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.prompts_accepted).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_ACCEPTED_NON_UNIQUE']: {
        className: 'r-prompts-accepted-nuq',
        width: 120,
        columnKey: 'prompts_accepted_non_unique',
        title: (
            <>
                Prompts Accepted
                <br />
                (Non-Unique)
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_accepted_non_unique)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet
                .map((s) => s.prompts_accepted_non_unique)
                .reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_DISMISSED']: {
        className: 'r-prompts-dismissed',
        width: 120,
        columnKey: 'prompts_dismissed',
        title: (
            <>
                Prompts
                <br />
                Dismissed
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_dismissed)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.prompts_dismissed).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPTS_DISMISSED_NON_UNIQUE']: {
        className: 'r-prompts-dismissed-nuq',
        width: 120,
        columnKey: 'prompts_dismissed_non_unique',
        title: (
            <>
                Prompts Dismissed
                <br />
                (Non-Unique)
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.prompts_dismissed_non_unique)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet
                .map((s) => s.prompts_dismissed_non_unique)
                .reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['PROMPT_ACCEPT_RATE_DECIMAL']: {
        className: 'r-prompt-accept-rate',
        width: 120,
        columnKey: 'prompt_accept_rate_decimal',
        requiredColumnKeys: ['prompts_shown', 'prompts_accepted'],
        title: (
            <>
                Prompt Accept
                <br />
                Rate
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.prompts_shown ? '--' : numberWithPercent(data.prompt_accept_rate_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggShown = dataSet.map((s) => s.prompts_shown).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggAccepts = dataSet.map((s) => s.prompts_accepted).reduce((n: number, v: number) => n + (v || 0), 0)

            const data = !aggShown ? '--' : numberWithPercent(aggAccepts / aggShown)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{data}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['SUBSCRIPTIONS']: {
        className: 'r-subscriptions',
        width: 120,
        columnKey: 'subscriptions',
        title: 'Subscriptions',
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return numberWithCommas(data.subscriptions)
        },
        footer: (dataSet: any[], { ...props }) => {
            const data = dataSet.map((s) => s.subscriptions).reduce((n: number, v: number) => n + (v || 0), 0)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{numberWithCommas(data)}</div>
                    <div className="footer-secondary">Total</div>
                </FDCell>
            )
        },
    },
    ['SUBSCRIPTION_RATE_DECIMAL']: {
        className: 'r-subscription-rate',
        width: 120,
        columnKey: 'subscription_rate_decimal',
        requiredColumnKeys: ['permissions_shown', 'subscriptions'],
        title: (
            <>
                Subscription
                <br />
                Rate
            </>
        ),
        sortable: true,
        sortType: 'number',
        render(data: any) {
            return !data.permissions_shown ? '--' : numberWithPercent(data.subscription_rate_decimal)
        },
        footer: (dataSet: any[], { ...props }) => {
            const aggShown = dataSet.map((s) => s.permissions_shown).reduce((n: number, v: number) => n + (v || 0), 0)
            const aggSubs = dataSet.map((s) => s.subscriptions).reduce((n: number, v: number) => n + (v || 0), 0)

            const data = !aggShown ? '--' : numberWithPercent(aggSubs / aggShown)

            return (
                <FDCell {...props}>
                    <div className="footer-primary">{data}</div>
                    <div className="footer-secondary">Average</div>
                </FDCell>
            )
        },
    },
    ['CHANNEL']: {
        align: 'left',
        selectable: false,
        classname: 'r-channel',
        width: 120,
        columnKey: 'ch',
        title: 'Delivery Channel',
        sortable: false,
        render: (data: any) => DeliveryChannel.getShortName(data.channel) ?? 'Unknown',
    },
}
