import * as React from 'react'

type SetInputState = (value: string | undefined, emit?: boolean) => void

export const useInputState = (emitter: (value: string) => any, debounce: number = 320): [string, SetInputState] => {
    const [value, setValue] = React.useState<string | undefined>()
    const debouncer = React.useRef<any>(undefined)

    const setValueAndEmit = (v: string, emit: boolean = true) => {
        clearTimeout(debouncer.current)
        if (emit) {
            debouncer.current = setTimeout(() => {
                emitter(v)
            }, debounce)
        }

        setValue(v)
    }

    return [value!, setValueAndEmit]
}
