import { PasswordStrength, commonPasswords, trigraphs } from 'tai-password-strength'
import { UserDto } from '../dtos/user'

enum PasswordStrengthCode {
    VERY_WEAK = 'VERY_WEAK',
    WEAK = 'WEAK',
    REASONABLE = 'REASONABLE',
    STRONG = 'STRONG',
    VERY_STRONG = 'VERY_STRONG',
}

export class PasswordValidators {
    private user: UserDto
    private pwdStrengthValidator = new PasswordStrength()

    constructor(user: UserDto) {
        this.user = user

        this.pwdStrengthValidator.addCommonPasswords(commonPasswords)
        this.pwdStrengthValidator.addTrigraphMap(trigraphs)
    }

    public tooShort = (password = '') => password.length < 12

    public isMissingRequiredCharacters(password = '') {
        return valueMeetsCharacterReqs(password)
    }

    public isSameAsUserEmail = (password = ''): boolean => {
        return password.toLowerCase() === this.user?.email?.toLowerCase()
    }

    public isSameAsUserName = (password = ''): boolean => {
        return password.toLowerCase() === this.user?.name?.toLowerCase()
    }

    public isWeak = (password = ''): boolean => {
        const pwdCheck = this.pwdStrengthValidator.check(password)
        const strength = pwdCheck.strengthCode

        return strength === PasswordStrengthCode.VERY_WEAK || strength === PasswordStrengthCode.WEAK
    }

    public score = (password = ''): string => {
        return this.readableScore(this.pwdStrengthValidator.check(password).strengthCode)
    }

    public readableScore = (strength = ''): string => {
        switch (strength) {
            case PasswordStrengthCode.VERY_STRONG:
                return 'very strong'
            case PasswordStrengthCode.STRONG:
                return 'strong'
            case PasswordStrengthCode.REASONABLE:
                return 'medium'
            case PasswordStrengthCode.WEAK:
                return 'weak'
            case PasswordStrengthCode.VERY_WEAK:
            default:
                return 'very weak'
        }
    }

    public validate = (rule: any, value: any, callback: Function) => {
        value = value || ''
        const currentErrors: any[] = []

        if (!value) {
            currentErrors.push('Password may not be left empty.')
        }

        const errors: Array<{ condition: boolean | object; message: string }> = [
            {
                // tslint:disable-next-line:no-invalid-this
                condition: this.tooShort(value),
                message: 'Your password must be at least 12 characters',
            },
            {
                condition: this.isMissingRequiredCharacters(value),
                message:
                    'Passwords must contain at least 3 from the 4 following requirements: lower case (a-z), upper case (A-Z), numbers (0-9) and special characters (!@#$%^&*)',
            },
            {
                condition: this.isSameAsUserEmail(value),
                message: 'Passwords may not be the same as your email',
            },
            {
                condition: this.isSameAsUserName(value),
                message: 'Passwords may not be the same as your name',
            },
            {
                condition: this.isWeak(value),
                message: 'Your password must be stronger',
            },
        ]

        errors.some((err): boolean => {
            if (err.condition && value !== '') {
                currentErrors.push('Password does not meet requirements.')
                return true
            }

            return false
        })

        callback(currentErrors)
    }
}

export function valueMeetsCharacterReqs(value: string, getRaw?: boolean) {
    const specialCharacters = /['!@#$%^&*']/g
    const lowercase = /[a-z]/g
    const uppercase = /[A-Z]/g
    const number = /[0-9]/

    const requirements = {
        lowercase: false,
        uppercase: false,
        number: false,
        special: false,
    }

    if (!requirements.lowercase && lowercase.test(value)) {
        requirements.lowercase = true
    }

    if (!requirements.uppercase && uppercase.test(value)) {
        requirements.uppercase = true
    }

    if (!requirements.number && number.test(value)) {
        requirements.number = true
    }

    if (!requirements.special && specialCharacters.test(value)) {
        requirements.special = true
    }

    return getRaw ? requirements : Object.keys(requirements).filter((req) => requirements[req] === true).length <= 3
}
