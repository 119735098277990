import React from 'react'
import './stacked-tag-select.scss'
import { Select as AntSelect } from 'antd'
import { SelectProps, SelectValue } from 'antd/lib/select'
import { getClassNames } from '../../_utils/classnames'

const Select = AntSelect as any
interface IStackedSelectProps<T = SelectValue> extends SelectProps<T> {
    showOptions?: boolean
}
interface IState<T = SelectValue> {
    value: T | undefined
}
class StackedTagSelect<T = SelectValue> extends React.PureComponent<IStackedSelectProps<T>, IState> {
    public constructor(props: IStackedSelectProps<T>) {
        super(props)

        this.state = {
            value: (props.value ?? props.defaultValue) as any,
        }
    }

    public render() {
        const { className, dropdownClassName, showOptions, ...props } = this.props

        return (
            <Select<T>
                {...props}
                className={getClassNames('stacked-tag-select', className, {
                    'hide-options': showOptions === false,
                })}
                dropdownClassName={getClassNames('stacked-tag-select-dropdown', dropdownClassName, {
                    'hide-options': showOptions === false,
                })}
                onChange={(value, option) => {
                    this.setState({ value })
                    props.onChange?.(value, option)
                }}
            />
        )
    }
}

export default StackedTagSelect
