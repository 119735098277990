import * as React from 'react'
import { BetterComponent } from '../../../better-component/better-component'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { Select } from 'antd'
import autobind from 'autobind-decorator'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'

interface IDevicePlacementInput {
    rule: IRule
    onChange: (value: string) => any
}

export class DevicePlacementInput extends BetterComponent<IDevicePlacementInput, {}> {
    public readonly defaultClassName = 'sw-v2-rb-device-placement-input'
    public ref: any

    public render(): React.ReactNode {
        return (
            <Select
                ref={(el) => (this.ref = el)}
                className={this.buildRootClassNames()}
                dropdownClassName={this.buildClassName('dropdown')}
                onChange={this.handleChange}
                value={this.rule.value}
            >
                <Select.Option value="desktop">desktop</Select.Option>
                <Select.Option value="mobile">mobile</Select.Option>
                <Select.Option value="tablet">tablet</Select.Option>
            </Select>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    @autobind
    protected async handleChange(value: string): Promise<void> {
        this.props.onChange(value)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
