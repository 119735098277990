import * as React from 'react'
import clone from 'clone-deep'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Select, Tabs, Switch } from 'antd'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import titleCase from 'title-case'
import { Button, Input, Well } from '@pushly/aqe/lib/components'
import { CONDITION_BRANCH_ROOT, DEFAULT_CONDITION_BRANCH_ID } from '../constants'
import { ITypeEditor } from '../interfaces'
import { ConditionSubType, NodeType, PortId, TriggerType } from '../enums'
import { addConditionBranch, createNode, removeConditionBranch, updateNodeTypeProps } from '../helpers/node'
import { getBranchId } from '../helpers/conditons'
import { RelativeDelayPicker } from './delay.editor'
import '../style/condition-editor.scss'

interface IBranchEditor {
    node: any
    condition: any
    onChange: (updates: any, condition: any) => void
    readonly?: boolean
}

const AddToCartEditor = ({ node, condition, onChange, readonly }: IBranchEditor) => {
    const eventBody: any = condition.params?.event_body ?? {}
    const eventKeys = Object.keys(eventBody)
    const itemKey = eventKeys.length === 0 ? 'product_id' : eventKeys[0]
    const itemValue = eventBody[itemKey]

    return (
        <>
            <Form.Item label="Cart Item Key">
                <Input
                    size="small"
                    defaultValue={itemKey}
                    disabled={readonly}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(
                            {
                                params: {
                                    ...condition.params,
                                    event_body: {
                                        [ev.target.value]: itemValue,
                                    },
                                },
                            },
                            condition,
                        )
                    }
                />
            </Form.Item>

            <Form.Item label="Cart Item Value">
                <Input
                    size="small"
                    defaultValue={itemValue}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(
                            {
                                params: {
                                    ...condition.params,
                                    event_body: {
                                        [itemKey]: ev.target.value,
                                    },
                                },
                            },
                            condition,
                        )
                    }
                />
            </Form.Item>
        </>
    )
}

export const BranchEditor = ({ node, condition, onChange, readonly }: IBranchEditor) => {
    const props = node.properties?.[node.type] ?? {}

    return (
        <>
            <Form.Item label="Label">
                <Input
                    size="small"
                    defaultValue={condition.name}
                    maxChars={45}
                    disabled={readonly}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                        onChange({ name: ev.target.value }, condition)
                    }
                />
            </Form.Item>

            {props.trigger_type === TriggerType.ADD_TO_CART && (
                <AddToCartEditor node={node} condition={condition} onChange={onChange} />
            )}

            <br />
        </>
    )
}

interface IConditionSetEditor extends ITypeEditor, FormComponentProps {
    initialBranchId?: string
}

export const ConditionSetEditor = Form.create<IConditionSetEditor>()(
    class extends React.Component<IConditionSetEditor, any> {
        constructor(props: IConditionSetEditor) {
            super(props)

            this.state = {
                activePane: props.initialBranchId ?? getBranchId(1),
            }
        }

        public render() {
            const { node, form } = this.props
            const formValues = form.getFieldsValue()
            const config: any = node.properties?.[node.type] ?? {}
            const subType = config.sub_type ?? ConditionSubType.TRIGGERED
            const hasExpiration = !!config.expire_flag || !!config.expires

            return (
                <div className="campaign-condition-editor">
                    <Well title="Set Details" mode="ghost" hideFooter={true}>
                        <Form.Item label="Condition type">
                            {form.getFieldDecorator('conditionSubType', {
                                initialValue: subType,
                            })(
                                <Select
                                    className="campaign-condition-editor-type-selection"
                                    dropdownClassName="campaign-condition-editor-type-dropdown"
                                    size="small"
                                >
                                    <Select.Option value={ConditionSubType.TRIGGERED}>Triggered</Select.Option>
                                    <Select.Option value={ConditionSubType.PASSIVE}>Passive</Select.Option>
                                </Select>,
                            )}
                        </Form.Item>

                        <Form.Item label="Evaluation">
                            {form.getFieldDecorator('triggerType', {
                                initialValue: config.trigger_type,
                            })(
                                <Select
                                    className="campaign-condition-editor-type-selection"
                                    dropdownClassName="campaign-condition-editor-type-dropdown"
                                    size="small"
                                >
                                    <Select.Option value={TriggerType.PROFILE}>Profile Update</Select.Option>
                                    <Select.Option value={TriggerType.ADD_TO_CART}>Add-to-Cart</Select.Option>
                                </Select>,
                            )}
                        </Form.Item>

                        <div className="switch-row">
                            <div className="switch-left">
                                Conditions expire
                                <Switch onChange={this.handleExpirationToggle} />
                            </div>
                            <div className="switch-right">
                                {hasExpiration && (
                                    <RelativeDelayPicker
                                        value={{}}
                                        onChange={(updates: any) => {
                                            console.log(updates)
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </Well>

                    <Well title="Conditions" mode="ghost" hideFooter={true}>
                        <Tabs
                            type="editable-card"
                            animated={false}
                            hideAdd={true}
                            onEdit={(targetKey, action) => {
                                if (action === 'remove') {
                                    this.handleRemoveBranch(targetKey.toString())
                                }
                            }}
                            onTabClick={(targetKey: string) => {
                                this.setState({ activePane: targetKey })
                            }}
                            activeKey={this.state.activePane}
                            tabBarExtraContent={
                                <>
                                    <Button
                                        className="btn-add-branch"
                                        disabled={this.nonDefaultBranchKeys.length >= 3}
                                        size="small"
                                        icon="plus"
                                        onClick={this.handleAddBranch}
                                    />
                                    <span />
                                    <Button
                                        className="btn-add-default"
                                        disabled={this.branchKeys.includes(DEFAULT_CONDITION_BRANCH_ID)}
                                        size="small"
                                        onClick={this.handleAddDefaultBranch}
                                    >
                                        <PlusOutlined />
                                        <span>Default</span>
                                    </Button>
                                </>
                            }
                        >
                            {this.branchKeys.map((key) => {
                                const condition = this.branchConfigs.find((c) => c.id === key)

                                let closeable = true
                                if (key !== DEFAULT_CONDITION_BRANCH_ID) {
                                    if (this.nonDefaultBranchKeys.length === 1) {
                                        closeable = false
                                    }
                                } else {
                                    if (hasExpiration) {
                                        closeable = false
                                    }
                                }

                                return (
                                    <Tabs.TabPane
                                        key={condition.id}
                                        tab={
                                            condition.id === DEFAULT_CONDITION_BRANCH_ID
                                                ? 'Default'
                                                : titleCase(condition.id)
                                        }
                                        closable={closeable}
                                    >
                                        {condition.id === DEFAULT_CONDITION_BRANCH_ID ? (
                                            <div className="default-branch-message">
                                                <p>
                                                    <InfoCircleOutlined />
                                                    <span>
                                                        The Default condition will capture all users who do not match
                                                        any other conditions.
                                                    </span>
                                                </p>
                                                {hasExpiration && (
                                                    <p>
                                                        <InfoCircleOutlined />
                                                        <span>
                                                            Cannot be removed when an expiration date is enabled.
                                                        </span>
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <BranchEditor
                                                node={node}
                                                condition={condition}
                                                onChange={this.handleBranchChange}
                                            />
                                        )}
                                    </Tabs.TabPane>
                                )
                            })}
                        </Tabs>
                    </Well>
                </div>
            )
        }

        protected get branchKeys(): string[] {
            const portKeys = Object.keys(this.props.node.ports)
            const keys = portKeys.filter((key) => key !== PortId.INPUT)

            keys.sort((a, b) => {
                return /default/.test(a) ? 1 : a > b ? 1 : a < b ? -1 : 0
            })

            return keys
        }

        protected get nonDefaultBranchKeys(): string[] {
            return this.branchKeys.filter((key) => key !== DEFAULT_CONDITION_BRANCH_ID)
        }

        protected get branchConfigs(): any[] {
            const { node } = this.props
            return node.properties[node.type][CONDITION_BRANCH_ROOT] ?? []
        }
        protected handleExpirationToggle = (expires: boolean) => {
            this.props.onChange(
                updateNodeTypeProps(this.props.node, {
                    expires,
                }),
            )

            if (expires && !this.branchKeys.includes(DEFAULT_CONDITION_BRANCH_ID)) {
                this.addBranch('default', false)
            }
        }

        protected handleAddDefaultBranch = () => {
            this.addBranch('default')
        }

        protected handleAddBranch = () => {
            this.addBranch(this.nonDefaultBranchKeys.length + 1)
        }

        protected addBranch(branch: string | number, setActiveBranch: boolean = true) {
            const update = addConditionBranch(
                clone(this.props.node),
                createNode(NodeType.CAMPAIGN_EXIT),
                clone(this.props.chart),
                branch as any,
            )

            this.props.onChange(update.nodes[this.props.node.id], update)

            if (setActiveBranch) {
                this.setState({ activePane: getBranchId(branch) })
            }
        }

        protected handleRemoveBranch(branchId: string) {
            const update = removeConditionBranch(clone(this.props.node), branchId, clone(this.props.chart))

            const node = update.nodes[this.props.node.id]
            const branches: any[] = node.properties[node.type][CONDITION_BRANCH_ROOT]

            this.props.onChange(node, update)

            let activePane = branchId
            const branchIdx = branches.findIndex((c: any) => c.id === branchId)
            if (branchIdx === -1) {
                activePane = branches[branches.length - 1].id
            }
            this.setState({ activePane })
        }

        protected handleBranchChange = (updates: any, condition: any) => {
            const node = clone(this.props.node)

            const branchIdx = this.branchConfigs.findIndex((c) => c.id === condition.id)
            if (branchIdx !== -1) {
                node.properties[node.type][CONDITION_BRANCH_ROOT][branchIdx] = {
                    ...condition,
                    ...updates,
                }

                this.props.onChange(node)
            }
        }
    },
)
