import * as React from 'react'
import './styles/notification-builder-aside.scss'
import { Sticky } from '../../sticky/sticky'
import { getClassNames } from '../../../_utils/classnames'

interface INotificationBuilderAside {
    className?: string
}

const NotificationBuilderAside: React.FunctionComponent<INotificationBuilderAside> = (props) => {
    const { className, children } = props

    return (
        <div className={getClassNames('notification-builder-aside-wrapper')}>
            <Sticky
                // getContainer={!props.stickyContainer ? undefined : () => props.stickyContainer}
                threshold={28}
            >
                <div className={getClassNames('notification-builder-aside', className)}>{children}</div>
            </Sticky>
        </div>
    )
}

export default NotificationBuilderAside
