export const DEFAULT_OPEN_URL_LABEL = 'Visit Site'
export const DEFAULT_CLOSE_LABEL = 'Dismiss'

export enum NotificationActionType {
    OPEN_URL = 'OPEN_URL',
    CLOSE = 'CLOSE',
}

export interface INotificationAction {
    id?: number
    type: NotificationActionType
    ordinal: number
    label: string
    landingUrl?: string
    usePrimaryLandingUrl?: boolean
    displayMeta?: any
}
