export class PufferfishException extends Error {
    protected constructor(message?: string, proto = PufferfishException) {
        super(message)

        this.name = proto.name ?? 'PufferfishException'
        try {
            Object.setPrototypeOf(this, proto.prototype)
        } catch {}
    }
}
