import { hasErrorResponseObject } from './response-error-utils'
import { CustomApiResponseErrorHandler } from '../types/api-call-options-with-custom-error-handler'
export function onResponseError403(cb: () => any, disableDefault: boolean = false): CustomApiResponseErrorHandler {
    return (error, defaultHandler) => {
        if (hasErrorResponseObject(error) && (error as any).response.status === 403) {
            cb()
        } else if (!disableDefault) {
            defaultHandler()
        }
    }
}
