import { Container, Singleton } from 'typescript-ioc/es5'
import * as querystring from 'query-string'
import { simpleNotification } from '../_utils/utils'
import { AppService } from './app'
import { axiosFetch, isAxiosCancellation } from '../config/axios-setup'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import { AppState } from '../stores/app'
import IApiCallOptions from '../interfaces/api-call-options'
import aqe from '@pushly/aqe'
import { IdentityProviderTypeDto } from '../dtos/identity-provider-type.dto'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class IdentityProviderTypeService {
    public appService: AppService

    public constructor() {
        this.appService = Container.get(AppService)
    }

    public async fetchIdentityProviderTypes(
        opts: IApiCallOptions = {},
    ): Promise<IServiceApiResponse<IdentityProviderTypeDto[]>> {
        let ok = false
        let dtos: IdentityProviderTypeDto[] = []
        let meta: any

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }
        const options = querystring.stringify(opts.query || {})
        const serviceURL = `${aqe.defaults.publicApiDomain}/identity-provider-types`

        try {
            const req = await axiosFetch(
                'get',
                {
                    url: serviceURL,
                },
                opts.cancellationKey,
            )
            ok = true
            dtos = req.data.data.map(IdentityProviderTypeDto.fromApiResponse)
            meta = req.data.meta
        } catch (error) {
            handleResponseErrorMessage(error)
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data: dtos, meta }
    }
}
