export class ZoomUtils {
    public static zoom(el: any, relX: number, relY: number, delta: number) {
        this.cancelZoom()
        this.zoomInterval = setInterval(() => {
            this.generateScrollEventAt(el, relX, relY, delta)
        }, 1)
    }

    public static cancelZoom() {
        clearInterval(this.zoomInterval)
    }

    public static findZoomContainer(element: HTMLElement): HTMLElement | undefined {
        let parent: any

        if (element) {
            const _parent = element.parentElement

            if (_parent && _parent.classList) {
                if (
                    _parent.classList.contains('react-transform-element') ||
                    _parent.classList.contains('react-transform-component')
                ) {
                    return ZoomUtils.findZoomContainer(_parent)
                }
            }

            parent = _parent
        }

        return parent
    }

    private static zoomInterval: any

    private static generateScrollEventAt(element: HTMLElement, relX: number, relY: number, delta: number) {
        if (element) {
            const wheelProps: any = {
                deltaY: delta,
                bubbles: true,
                clientX: 0,
                clientY: 0,
                ctrlKey: true,
            }

            const elContainer = ZoomUtils.findZoomContainer(element)
            if (elContainer) {
                const elContainerRect = elContainer.getBoundingClientRect()
                wheelProps.clientX = elContainerRect.width * (relX / 100) + elContainerRect.left
                wheelProps.clientY = elContainerRect.height * (relY / 100) + elContainerRect.top

                element.dispatchEvent(new WheelEvent('wheel', wheelProps))
            }
        }
    }
}
