import * as React from 'react'
import { PlatformIdentityContext } from '../../providers/platform-identity-provider/platform-identity-provider'
import { buildAuthorizeRoute } from '../../_utils/routing'
import randomstring from 'randomstring'

export const Auth0Redirect = () => {
    const idpContext = React.useContext(PlatformIdentityContext)

    if (idpContext.loaded) {
        const label = idpContext.whiteLabelSettings
        const authorizeRoute = buildAuthorizeRoute(label?.getAuth0HostedLoginDomain()!, true)
        const clientId = label.getAuth0PlatformClientId() ?? 'g9Cd1SD4Tj0df4lQaF3KJT1PLLZsNktq'
        const audience = label.getAuth0AudienceName()
        const redirectURI = buildAuthorizeRoute(label.getHostname())

        const state = randomstring.generate()
        // consider using JWT
        window.sessionStorage.setItem('sw_a0st', state)

        // possible params from auth0 invitation link
        const auth0Params = new URL(window.location.toString()).searchParams
        let invitationId
        let orgId
        let orgName

        if (auth0Params) {
            invitationId = auth0Params.get('invitation')
            orgId = auth0Params.get('organization')
            orgName = auth0Params.get('organization_name')
        }

        if (!window.location.pathname.includes('authorize')) {
            const redirectURL = [
                `${authorizeRoute}?response_type=code`,
                `client_id=${clientId}`,
                `audience=${audience}`,
                `redirect_uri=${redirectURI}`,
                'scope=profile%20email%20offline_access',
                `state=${state}`,
            ]

            if (invitationId && orgId && orgName) {
                redirectURL.push(`invitation=${invitationId}`, `organization=${orgId}`, `organization_name=${orgName}`)
            }

            window.location.href = redirectURL.join('&')
        }
    }

    return null
}
