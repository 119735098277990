import { useEffect } from 'react'

export const useListenerEvent = (event: any, callback: (ev: any) => void, element: HTMLElement | string) => {
    useEffect(() => {
        const resolvedElement: HTMLElement | null =
            typeof element === 'string' ? document.querySelector(element) : element

        if (resolvedElement) {
            resolvedElement.addEventListener(event, callback)
            return () => {
                resolvedElement.removeEventListener(event, callback)
            }
        }

        return
    }, [event, element])
}
