import React from 'react'
import classnames from 'classnames'
import { SegmentListContext } from './segment-list-context'
import { Button, Dropdown, Input, Menu, Modal, Select, Tooltip } from 'antd'
import { DownOutlined, FolderOutlined, GlobalOutlined, ReloadOutlined } from '@ant-design/icons'
import { IOrgSegmentListState, SegmentListState } from './interfaces'
import { AppService } from '../../services'
import { Container } from 'typescript-ioc/es5'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { BetterSelect } from '../better-select/better-select'
import { SegmentSource } from '../../enums/segment-source.enum'
import { AppState } from '../../stores/app'
import { simpleSort } from '../../_utils/utils'
import ShowSingleDomainEntitiesToggle from '../show-single-domain-entities-toggle/show-single-domain-entities-toggle'
import { FEAT_INLINE_SEG } from '../../constants'
import { AbilityAction } from '../../enums/ability-action.enum'
import { SubjectEntity } from '../../enums/ability-entity.enum'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'

type FilterKey = keyof SegmentListState['filters']

interface ISegmentListHeaderProps {
    title?: string
    filterSize?: SizeType
    filtersAddonBefore?: React.ReactNode
    filtersAddonAfter?: React.ReactNode
    hideRefresh?: boolean
    hideRowSelection?: boolean
    hideFilters?: FilterKey | FilterKey[] | boolean
    filtersDisabled?: FilterKey | FilterKey[] | boolean
    onArchiveClick: () => void
    onAddDomains: (domainIds: number[]) => any
}

interface IState {
    status?: string[]
    domainIdsToAdd?: number[]
}

class SegmentListHeader extends React.Component<ISegmentListHeaderProps, IState> {
    public static contextType = SegmentListContext
    public context!: React.ContextType<typeof SegmentListContext>

    public state: IState = {}

    protected readonly appState: AppState
    protected readonly appService: AppService

    protected liveTypeTimeout: any

    public constructor(props: ISegmentListHeaderProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public render() {
        const {
            levelDependenciesLoaded,
            refreshing,
            filters,
            onSearchClick,
            onFilterChange,
            onRefreshClick,
            refreshEnabled,
        } = this.context

        const allFilters: FilterKey[] = ['search', 'source', 'showAllSegments']
        const filterSize = this.props.filterSize ?? 'middle'

        let filtersHidden: FilterKey[] = []
        if (this.props.hideFilters === true) {
            filtersHidden = Array.from(allFilters)
        } else if (this.props.hideFilters) {
            filtersHidden = Array.isArray(this.props.hideFilters) ? this.props.hideFilters : [this.props.hideFilters]
        }

        let filtersDisabled: FilterKey[] = []
        if (this.props.filtersDisabled === true) {
            filtersDisabled = Array.from(allFilters)
        } else if (this.props.filtersDisabled) {
            filtersDisabled = Array.isArray(this.props.filtersDisabled)
                ? this.props.filtersDisabled
                : [this.props.filtersDisabled]
        }

        const hideAllFilters = filtersHidden.length === allFilters.length

        let hasInlineSegFeat = false
        if (this.context.level === 'domain' && this.context.domain) {
            const inlineSegFlag = this.appState.flags.findActive(FEAT_INLINE_SEG)?.getKey()
            hasInlineSegFeat = !!inlineSegFlag && this.context.domain?.flags?.includes(inlineSegFlag)
        }

        const totalSelected = this.context.selectedIds.length
        const bulkActionsEnabled = totalSelected > 0

        return (
            <div className={classnames('segment-list-header')}>
                <div
                    className={classnames('segment-list-title', {
                        hidden: !this.props.title,
                    })}
                >
                    <span>{this.props.title}</span>
                </div>

                <div
                    className={classnames('segment-list-filters', {
                        hidden: hideAllFilters,
                    })}
                >
                    {this.props.filtersAddonBefore}

                    {!filtersHidden.includes('search') && (
                        <Input.Search
                            className={classnames('segment-list-search')}
                            size={filterSize}
                            placeholder="Search by segment name"
                            disabled={filtersDisabled.includes('search')}
                            enterButton={true}
                            defaultValue={filters.search}
                            onSearch={onSearchClick}
                            onChange={(ev) => {
                                clearTimeout(this.liveTypeTimeout)

                                const value = ev.target.value
                                this.liveTypeTimeout = setTimeout(() => {
                                    onFilterChange('search', value)
                                }, 320)
                            }}
                        />
                    )}

                    {!filtersHidden.includes('source') && hasInlineSegFeat && (
                        <BetterSelect
                            className="segment-list-source-filter"
                            dropdownClassName="segment-list-source-filter-dropdown"
                            mode="multiple"
                            prefix="Source"
                            placeholder="Select a Source"
                            defaultValue={this.context.filters.source}
                            options={[
                                { value: SegmentSource.STANDARD, label: 'Standard' },
                                { value: SegmentSource.INLINE, label: 'Inline' },
                            ]}
                            onClose={(value: SegmentSource[]) => {
                                onFilterChange('source', value)
                            }}
                            disableSelectAll={true}
                            disableSearch={true}
                            maxDisplayCount={2}
                            maxDisplayFormatter={(options: any[]) => {
                                const multi = options.length > 1

                                return (
                                    <Tooltip title={options.map((o) => o.label).join(', ')}>
                                        {options.length} {multi ? 'Sources' : 'Source'} Selected
                                    </Tooltip>
                                )
                            }}
                        />
                    )}

                    {!filtersHidden.includes('channels') && (
                        <BetterSelect
                            className="segment-list-channel-filter"
                            dropdownClassName="segment-list-channel-filter-dropdown"
                            mode="multiple"
                            prefix="Channel"
                            placeholder="Select a Channel"
                            defaultValue={this.context.filters.channels}
                            selectAllLabel="All Channels"
                            options={[
                                { value: DeliveryChannel.WEB, label: DeliveryChannel.getLongName(DeliveryChannel.WEB) },
                                {
                                    value: DeliveryChannel.NATIVE_IOS,
                                    label: DeliveryChannel.getLongName(DeliveryChannel.NATIVE_IOS),
                                },
                                {
                                    value: DeliveryChannel.NATIVE_ANDROID,
                                    label: DeliveryChannel.getLongName(DeliveryChannel.NATIVE_ANDROID),
                                },
                            ]}
                            onClose={(value: DeliveryChannel[]) => {
                                onFilterChange('channels', value)
                            }}
                            disableSelectAll={true}
                            disableSearch={true}
                            maxDisplayCount={2}
                            maxDisplayFormatter={(options: any[]) => {
                                const multi = options.length > 1

                                return (
                                    <Tooltip title={options.map((o) => o.label).join(', ')}>
                                        {options.length} {multi ? 'Channels' : 'Channel'} Selected
                                    </Tooltip>
                                )
                            }}
                        />
                    )}

                    {!filtersHidden.includes('showAllSegments') && (
                        <ShowSingleDomainEntitiesToggle
                            className="show-all-segments-filter"
                            title="Segments"
                            defaultChecked={this.context.filters.showAllSegments}
                            onChange={(enabled) => {
                                onFilterChange('showAllSegments', enabled)
                            }}
                        />
                    )}

                    {this.props.filtersAddonAfter}
                </div>

                {this.appState.abilityStore.can(AbilityAction.UPDATE, SubjectEntity.SEGMENT) && (
                    <div
                        className={classnames('segment-list-bulk-actions', {
                            hidden: this.props.hideRowSelection,
                        })}
                    >
                        {!this.props.hideRowSelection && (
                            <Dropdown
                                disabled={!bulkActionsEnabled}
                                trigger={['click']}
                                overlay={
                                    <Menu onClick={this.handleBulkMenuClick}>
                                        {this.context.level === 'org' && (
                                            <Menu.Item key="action-add-domains">
                                                <GlobalOutlined />
                                                Add Domains
                                            </Menu.Item>
                                        )}
                                        <Menu.Item key="action-archive">
                                            <FolderOutlined />
                                            Archive
                                        </Menu.Item>
                                    </Menu>
                                }
                            >
                                <Button size="small" type={bulkActionsEnabled ? 'primary' : 'default'}>
                                    <span>Bulk Actions</span> <DownOutlined />
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                )}

                <div
                    className={classnames('segment-list-refresh', {
                        hidden: this.props.hideRefresh,
                    })}
                >
                    {!this.props.hideRefresh && (
                        <Tooltip title="Refresh">
                            <Button
                                className={classnames('segment-list-refresh-toggle')}
                                disabled={!refreshEnabled}
                                size="small"
                                shape="round"
                                onClick={onRefreshClick}
                            >
                                <ReloadOutlined spin={!levelDependenciesLoaded || refreshing} />
                            </Button>
                        </Tooltip>
                    )}
                </div>
            </div>
        )
    }

    protected handleBulkMenuClick = (e: any) => {
        switch (e.key) {
            case 'action-archive':
                this.confirmBulkArchive()
                break
            case 'action-add-domains':
                this.confirmBulkDomains()
                break
        }
    }

    protected confirmBulkArchive() {
        const checkedSegmentIds = Array.from(new Set(this.context.selectedIds))
        const totalSelected = checkedSegmentIds.length
        const itemText = totalSelected === 1 ? 'Segment' : 'Segments'

        Modal.confirm({
            title: 'Bulk Archive',
            okText: 'Yes, Archive',
            onOk: this.props.onArchiveClick,
            content: (
                <div>
                    Are you sure you want to archive <b>{totalSelected}</b> {itemText}?
                    <p>These segments will no longer be available to target during notification creation.</p>
                </div>
            ),
        })
    }

    protected confirmBulkDomains() {
        const context = this.context as IOrgSegmentListState
        const accessibleDomains =
            this.appState.currentUserDomains?.filter((d) => d.accountId?.toString() === context.org.id.toString()) ?? []

        const selectedSegments = context.dataSource.filter((s) => this.context.selectedIds.includes(s.getId()))

        // build map of domain ids by segment share
        const selectedDomainIdMap: { [index: number]: number[] } = {}
        selectedSegments.forEach((segment) => {
            segment.getDomainIds().forEach((domainId) => {
                selectedDomainIdMap[domainId] = selectedDomainIdMap[domainId] ?? []
                selectedDomainIdMap[domainId].push(segment.getId())
            })
        })

        // find domainIds that are shared across all selected segments
        // and remove them from the available domains
        const domainIdsToFilterOut = Object.keys(selectedDomainIdMap).filter((domainId) => {
            return selectedDomainIdMap[domainId].length === selectedSegments.length
        })
        const availableDomains = accessibleDomains.filter(
            (domain) => !domainIdsToFilterOut.includes(domain.id.toString()),
        )
        availableDomains.sort((a, b) => simpleSort(a.displayName, b.displayName))

        Modal.confirm({
            icon: <GlobalOutlined />,
            title: 'Add Domains',
            okText: 'Submit',
            onOk: () => {
                let resolver: any
                if (this.state.domainIdsToAdd) {
                    resolver = this.props.onAddDomains(this.state.domainIdsToAdd)
                }

                return Promise.resolve(resolver)
            },
            content: (
                <div>
                    <p>Choose one or more domains to add to the selected segments.</p>

                    <Select<number[]>
                        className="segment-list-add-domains-select"
                        dropdownClassName="segment-list-add-domains-dropdown"
                        mode="multiple"
                        placeholder="Select one or more domains"
                        optionFilterProp="title"
                        optionLabelProp="title"
                        filterOption={true}
                        onChange={(domainIdsToAdd) => this.setState({ domainIdsToAdd })}
                    >
                        {availableDomains.map((domain) => (
                            <Select.Option key={domain.id} value={domain.id} title={domain.displayName}>
                                {domain.displayName}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            ),
        })
    }
}

export default SegmentListHeader
