import * as React from 'react'
import { Container, Singleton } from 'typescript-ioc/es5'
import { AppService } from './app'
import { AppState } from '../stores/app'
import { axiosFetch } from '../config/axios-setup'
import { IBatchRequest, IBatchResponse } from '../interfaces/batch-requests'
import IApiCallOptions from '../interfaces/api-call-options'
import { IServiceApiResponse } from '../interfaces/service-api-response'
import aqe from '@pushly/aqe'
import { handleResponseErrorMessage } from '../_utils/response-error-utils'

@Singleton
export class BatchService {
    private appState: AppState
    private appService: AppService

    public constructor() {
        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)
    }

    public get baseBatchUrl(): string {
        if (String(process.env.LOCAL_ENV) === 'true') {
            return `${aqe.defaults.batchApiDomain}/batch`
        } else {
            return `${aqe.defaults.publicApiDomain}/batch`
        }
    }

    public async batch(job: IBatchRequest, opts: IApiCallOptions = {}): Promise<IServiceApiResponse<IBatchResponse>> {
        let ok = false
        let data: IBatchResponse = []
        let cancelled = false
        let error

        if (opts.showLoadingScreen) {
            this.appService.setModuleLoading()
        }

        try {
            const res = await axiosFetch(
                'post',
                {
                    url: this.baseBatchUrl,
                    data: job,
                },
                opts.cancellationKey,
            )

            ok = true
            data = res.data
        } catch (err) {
            error = err
            handleResponseErrorMessage(error, {
                onCancelled: () => (cancelled = true),
            })
        }

        if (opts.showLoadingScreen) {
            this.appService.unsetModuleLoading()
        }

        return { ok, data, cancelled, error }
    }
}
