import { SubjectEntity } from '../enums/ability-entity.enum'
import { FEAT_CHANNEL_ANDROID, FEAT_CHANNEL_IOS, FEAT_CHANNEL_WEB } from '../constants'
import { AppState } from '../stores/app'
import { FlagModel } from '../models/domain/flag.model'

export class AccountDto {
    public static fromApiResponse(fields: Partial<AccountDto>): AccountDto {
        const dto = new AccountDto()

        Object.assign(dto, fields)

        return dto
    }

    public __caslSubjectType__ = SubjectEntity.ORG

    public readonly id: number
    public name: string
    public status: string = 'ACTIVE'
    public statusId: number = 1
    public flags: string[] = []
    public keywords: string[] = []

    public vapidPrivateKey: string
    public vapidPublicKey: string
    public senderId: string
    public fcmServerKey: string
    public fcmCredentialsUploaded: boolean
    public timezone: string
    public defaultNotificationLandingUrl: string
    public defaultNotificationTtlSeconds: number
    public addedNotificationQueryParams: any
    public utmParams: any
    public globalPromptSettings: any
    public frequencyCaps: any
    public mediaQuality: number

    public collapseKeyType: string
    public routingKey: string
    public billingData: any
    public displayMeta: any

    public createdBy: number
    public createdAt: Date
    public createdByUserName: string
    public updatedBy: number
    public updatedAt: Date
    public updatedByUserName: string

    public domains: any[]
    public getDomainCount(): number {
        return (this.domains || []).length
    }

    public isWebEnabled(appState?: AppState) {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_WEB
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_WEB)
    }
    public isIOSEnabled(appState?: AppState) {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_IOS
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_IOS)
    }

    public isAndroidEnabled(appState?: AppState) {
        let flag: string | FlagModel | undefined = FEAT_CHANNEL_ANDROID
        if (appState) {
            flag = appState.flags.findActive(flag)?.getKey()
        }
        return this.flags.includes(flag ?? FEAT_CHANNEL_ANDROID)
    }
}
