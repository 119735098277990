import { AccessRoleId } from '../../enums/access-role.enum'
import { stripUndefined } from '../../_utils/strip-undefined'
import { AccessRole, AccessRoleMap } from '../access-role'
import * as deepEqual from 'react-fast-compare'

export interface ICachedDomainUserRecord {
    id: number
    accountUserId: number
    domainId: number
    roleId: number
    roleName: string
    flags: string[]
}

type NewUserDomainUserRecord = Omit<ICachedDomainUserRecord, 'id' | 'accountUserId'>

class DomainUserModel {
    public static fromCached(value: ICachedDomainUserRecord): DomainUserModel {
        return new DomainUserModel(value)
    }

    protected id?: number
    protected account_user_id: number
    protected domain_id: number
    protected role_id: number
    protected role_name: string
    protected flags: string[]

    public constructor(value: ICachedDomainUserRecord | NewUserDomainUserRecord) {
        this.domain_id = value.domainId
        this.role_id = value.roleId
        this.role_name = value.roleName
        this.flags = value.flags

        if ('id' in value) {
            this.id = value.id
        }
        if ('accountUserId' in value) {
            this.account_user_id = value.accountUserId
        }
    }

    public clone(): DomainUserModel {
        return new DomainUserModel(this.serialize())
    }

    public serialize(): ICachedDomainUserRecord {
        return stripUndefined({
            id: this.id,
            accountUserId: this.account_user_id,
            domainId: this.domain_id,
            roleId: this.role_id,
            roleName: this.role_name,
            flags: this.flags,
        })
    }

    public matches(value: ICachedDomainUserRecord | DomainUserModel): boolean {
        const curr = this.serialize()
        const comparison = value instanceof DomainUserModel ? value.serialize() : value
        return deepEqual(curr, comparison)
    }

    public getId() {
        return this.id
    }
    public getAccountUserId() {
        return this.account_user_id
    }
    public getDomainId() {
        return this.domain_id
    }

    public setRole<T extends AccessRoleId>(role: AccessRole<T>) {
        this.role_id = role.id
        this.role_name = role.name
    }
    public getRole() {
        return AccessRoleMap.get(this.role_id)
    }

    public setRoleId(id: number) {
        this.role_id = id
    }
    public getRoleId() {
        return this.role_id
    }

    public setRoleName(name: string) {
        this.role_name = name
    }
    public getRoleName() {
        return this.role_name
    }

    public setFlags(flags: string[]) {
        this.flags = flags
    }
    public getFlags() {
        return this.flags
    }
}

export default DomainUserModel
