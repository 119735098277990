import * as React from 'react'
import autobind from 'autobind-decorator'
import { Tooltip, Select } from 'antd'
import { BetterComponent } from '../better-component/better-component'
import { IRuleBuilderField } from './rule-builder'
import { baseOperators } from './operators'
import './input-notification-id-clicked.scss'
import { NotificationSearchBar } from '../notification-search-bar/notification-search-bar'
import { AppService } from '../../services/index'
import { Container } from 'typescript-ioc/es5'
import { IRuleValidationResponse } from '../rule-builder-v2/interfaces/rule-validation-response'
import { InfoCircleOutlined } from '@ant-design/icons'

interface IProps {
    mode: 'edit' | 'display'
    field: IRuleBuilderField
    value: any
    operator?: string
    onChange: any
}

interface IState {}

export class InputNotificationIdClicked extends BetterComponent<IProps, IState> {
    public operators = [baseOperators.a_contains, baseOperators.a_not_contains]
    public defaultOperator = baseOperators.a_contains

    private appService: AppService
    private ref: any

    public constructor(props: IProps) {
        super(props)

        this.appService = Container.get(AppService)
    }

    public render() {
        return (
            <div ref={(el) => (this.ref = el)} className="input-notification-id-clicked">
                {this.isEditMode ? (
                    <Select
                        getPopupContainer={() => this.ref}
                        className="operator-select"
                        dropdownClassName="operator-select-options"
                        onChange={this.onOperatorChange}
                        value={this.props.operator || this.defaultOperator.value}
                    >
                        {this.renderOperatorOptions()}
                    </Select>
                ) : (
                    <>
                        <span className="highlight">{this.getOperatorDisplay()}</span>
                        &nbsp;
                    </>
                )}

                <NotificationSearchBar
                    reactive={false}
                    className="notif-search"
                    mode={this.isEditMode ? 'search' : 'display'}
                    value={this.propsValue}
                    onChange={this.onValueChange}
                    getDropdownContainer={this.appService.getAppContainer}
                    autofocus={true}
                    sources={['MANUAL', 'FEED', 'CAMPAIGN']}
                />

                <Tooltip title="Subscribers who clicked within the last 24 hours may not yet be properly attributed to segments using this condition.">
                    <span className="info-icon">
                        <InfoCircleOutlined />
                    </span>
                </Tooltip>
            </div>
        )
    }

    public validateField(): boolean | string {
        let response: boolean | string = true

        if (!this.propsValue) {
            response = 'You must select a valid notification to save a Notification Clicked condition.'
        }

        return response
    }

    // V2 Validation Method
    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        const validation = this.validateField()

        if (validation !== true) {
            response.ok = false
            response.error = 'You must select a valid notification to save a Notification Clicked condition.'
        }

        return response
    }

    protected get isEditMode(): boolean {
        return this.props.mode === 'edit'
    }

    protected get propsValue(): any {
        let propsValue = this.props.value || {}
        if (Array.isArray(propsValue)) propsValue = propsValue[0] || propsValue
        propsValue = typeof propsValue === 'object' ? propsValue.value : propsValue
        if (Array.isArray(propsValue)) propsValue = propsValue[0]
        return propsValue
    }

    protected renderOperatorOptions(): React.ReactNode[] {
        return this.operators.map((o) => (
            <Select.Option key={o.value} value={o.value}>
                {o.display}
            </Select.Option>
        ))
    }

    protected getOperatorDisplay(): string {
        const operator = !this.props.operator ? '' : this.operators.find((o) => o.value === this.props.operator)

        return operator === '' ? operator : operator!.longDisplay
    }

    @autobind
    protected async onOperatorChange(operator: string) {
        this.emitChangeEvent({ operator })
    }

    @autobind
    protected async onValueChange(value: any) {
        this.emitChangeEvent({ value: value === undefined ? value : value.toString() })
    }

    protected emitChangeEvent(changeSet: any) {
        changeSet = {
            field: this.props.field.property,
            operator: this.props.operator || this.defaultOperator.value,
            value: this.propsValue,
            meta: {},
            ...changeSet,
        }

        this.props.onChange(changeSet)
    }
}
