import * as React from 'react'
import { Container } from 'typescript-ioc/es5'
import { BetterComponent } from '../../../components/better-component/better-component'
import { Well } from '../../../components/well/well'
import { AppState } from '../../../stores/app'
import { AppService } from '../../../services'
import { CampaignDto } from '../dtos/campaign-dto'
import * as moment from 'moment-timezone'
import { DEFAULT_HIGHCHARTS_CONFIG, INSIGHTS_SUBS_COLOR } from '../../../constants'
import { HighchartsUtils } from '../../../_utils/highcharts-utils'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import autobind from 'autobind-decorator'

interface IProps {
    campaign: CampaignDto
    stats: any[]
    timeBreakdown: string
    loading: boolean
}

interface IState {}

export class CampaignSubscriberGraph extends BetterComponent<IProps, IState> {
    private appState: AppState
    private appService: AppService

    public constructor(props: IProps) {
        super(props)

        this.appState = Container.get(AppState)
        this.appService = Container.get(AppService)

        this.state = {}
    }

    public async componentDidMount(): Promise<void> {}

    public render(): React.ReactNode {
        return (
            <Well
                className="thin primary-stats-well subscriber-stats-well"
                title="Subscribers Entered by Day"
                showFooter={false}
                ghost={true}
            >
                {this.renderChart()}
            </Well>
        )
    }

    @autobind
    protected renderChart(): React.ReactNode {
        const { stats } = this.props
        if (!stats) {
            return
        }

        const xAxisData = stats.map((stat: any) => {
            return moment.tz(stat.event_date, 'UTC').format('MMM D')
        })

        const entrData = stats.map((stat: any) => stat.entrances || 0)

        const options = {
            ...DEFAULT_HIGHCHARTS_CONFIG,
            swContext: (): IState => this.state,
            chart: {
                type: 'column',
                marginLeft: 50,
                marginRight: 50,
                marginTop: 10,
                padding: 10,
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: xAxisData,
            },
            yAxis: [
                {
                    title: { text: '' },
                    allowDecimals: false,
                    startOnTick: 0,
                    minRange: 0,
                },
            ],
            tooltip: {
                formatter: HighchartsUtils.defaultTooltipFormatter,
                pointFormatter: HighchartsUtils.commaTooltipPointFormatter,
                shared: true,
            },
            series: [
                {
                    type: 'column',
                    name: 'Entrances',
                    data: entrData,
                    color: INSIGHTS_SUBS_COLOR,
                },
            ],
        }

        return <HighchartsReact highcharts={Highcharts} options={options} />
    }
}
