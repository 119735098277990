import * as React from 'react'
import { Input } from '@pushly/aqe/lib/components'
import { BetterSelect } from '../../../components/better-select/better-select'
import { IFilterBuilderProps, IFilterBuilderState } from '../interfaces/filter-builder'
import { getClassNames } from '../../../_utils/classnames'
import './style/simple-text.filter-builder.scss'

interface ISimpleTextFilterBuilder extends IFilterBuilderProps {
    field: string
    onInputPressEnter: () => void
}

export class SimpleTextFilterBuilder extends React.Component<ISimpleTextFilterBuilder, IFilterBuilderState> {
    public state: IFilterBuilderState = {
        operator: 'LIKE',
        value: undefined,
    }

    public render(): React.ReactNode {
        return (
            <div className={getClassNames('filter', 'stxt-filter')}>
                <div className={getClassNames('filter-wrapper')}>
                    <div className={getClassNames('filter-display')}>
                        <span>
                            <span className="filter-op">{this.currentOperatorDisplay}</span>
                            <span> </span>
                            <span className="filter-val">{this.currentValue.value}</span>
                        </span>
                    </div>
                    <div className={getClassNames('filter-editor')}>
                        <div className="filter-op">
                            <BetterSelect
                                size="small"
                                disableSearch={true}
                                dropdownClassName="filter-op-dropdown"
                                options={[
                                    { value: 'LIKE', label: 'contains' },
                                    { value: 'NOT_LIKE', label: 'does not contain' },
                                ]}
                                defaultValue={['LIKE']}
                                value={this.currentValue.operator}
                                onChange={(operator: string) => {
                                    this.handleChange({ operator })
                                }}
                            />
                        </div>
                        <div className="filter-val">
                            <Input
                                reactive={true}
                                minWidth={50}
                                maxWidth={400}
                                size="small"
                                placeholder="..."
                                value={this.currentValue.value}
                                onChange={this.handleValueChange}
                                onPressEnter={this.handlePressEnter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    protected get currentValue(): any {
        const propsValue: any = this.props.value || {}

        let value = this.state.value
        if (value === undefined || value === null) {
            value = propsValue.value
        }

        return {
            field: this.props.field,
            operator: propsValue.operator || 'LIKE',
            value,
        }
    }

    protected get currentOperatorDisplay(): string {
        return this.currentValue.operator === 'LIKE' ? 'contains' : 'does not contain'
    }

    protected handleValueChange = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const value = ev.target.value
        this.handleChange({ value })
    }

    protected handlePressEnter = async () => {
        if (this.props.onInputPressEnter) {
            this.props.onInputPressEnter()
        }
    }

    protected handleChange(config: any): any {
        if (!!config) {
            this.props.onChange({
                field: this.props.field,
                operator: this.currentValue.operator,
                ...this.props.value,
                ...config,
            })
        } else {
            this.props.onChange()
        }
    }
}
