import { createContext } from 'react'
import { DomainDto } from '../../../dtos/domain'
import { CampaignModel } from '../../../models/campaign/campaign.model'
import { CampaignBuilderMode, CampaignEditableState } from '../enums'
import { SegmentDto } from '../../../dtos/segment'
import { IConfigurationFlags } from './interfaces'

interface IConfigurationContext {
    mode: CampaignBuilderMode
    loading: boolean
    domain: DomainDto
    domainDefaultSegment?: SegmentDto
    campaign: CampaignModel
    setCampaign: (changes: CampaignModel, debounce?: boolean) => any
    editableState: CampaignEditableState
    flags: IConfigurationFlags
    setFlags: (flags: Partial<IConfigurationFlags>) => any
}

export const ConfigurationContext = createContext<IConfigurationContext>({} as IConfigurationContext)
