import * as React from 'react'
import { Select, DatePicker } from 'antd'
import autobind from 'autobind-decorator'
import { BetterComponent } from '../../../better-component/better-component'
import { BASE_DATE_FORMAT } from '../../../../constants'
import { baseOperators, RuleBuilderOperator } from '../../../rule-builder/operators'
import * as moment from 'moment'
import { IRule, IRuleDefinition } from '../../interfaces/rule'
import { IRuleValidationResponse } from '../../interfaces/rule-validation-response'

type DateType = 'exact' | 'relative'

interface IDateInputProps {
    rule: IRule
    onChange: (value: any) => any
}

export class DateInput extends BetterComponent<IDateInputProps, {}> {
    public readonly defaultClassName = 'sw-v2-rb-date-input'
    public ref: any

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                <div className="type">
                    <span> using </span>

                    <Select
                        dropdownClassName={this.buildClassName('type-dropdown')}
                        placeholder="Select a type"
                        value={this.currentDateTypeState}
                        onChange={this.handleDateTypeChange}
                    >
                        <Select.Option value="exact">exact dates</Select.Option>
                        <Select.Option value="relative">relative dates</Select.Option>
                    </Select>
                </div>

                <div className="operator">
                    <Select
                        key={`${this.currentDateTypeState}-operator`}
                        dropdownClassName={this.buildClassName('operator-dropdown')}
                        value={this.currentOperatorValue}
                        onChange={this.handleOperatorChange}
                    >
                        {this.getDateTypeOperators().map((opr) => (
                            <Select.Option key={opr.value} value={opr.value}>
                                {opr.longDisplay}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div className="value">
                    {this.currentDateTypeIsRelative ? this.renderRelativeDatePicker() : this.renderStandardDatePicker()}
                </div>
            </div>
        )
    }

    public validate(): IRuleValidationResponse {
        const response: IRuleValidationResponse = { ok: true }
        return response
    }

    protected renderStandardDatePicker(): React.ReactNode {
        return <DatePicker value={this.currentValueState} onChange={this.handleValueChange} format={BASE_DATE_FORMAT} />
    }

    protected renderRelativeDatePicker(): React.ReactNode {
        return (
            <Select
                dropdownClassName={this.buildClassName('relative-value-dropdown')}
                defaultValue={'today'}
                value={this.currentValueState}
                onChange={this.handleValueChange}
            >
                <Select.Option value="today">Today</Select.Option>
                <Select.Option value="yesterday">Yesterday</Select.Option>
                <Select.Option value="last_7_days">Last 7 Days</Select.Option>
                <Select.Option value="last_14_days">Last 14 Days</Select.Option>
                <Select.Option value="last_30_days">Last 30 Days</Select.Option>
                <Select.Option value="last_90_days">Last 90 Days</Select.Option>
                <Select.Option value="this_week">This Week</Select.Option>
                <Select.Option value="last_week">Last Week</Select.Option>
                <Select.Option value="this_month">This Month</Select.Option>
                <Select.Option value="last_month">Last Month</Select.Option>
            </Select>
        )
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get currentDateTypeState(): DateType {
        return this.rule.meta ? this.rule.meta.range || 'exact' : 'exact'
    }

    protected get currentDateTypeIsRelative(): boolean {
        return this.currentDateTypeState === 'relative'
    }

    protected get currentValueState(): any {
        if (!this.rule.value) return
        return this.currentDateTypeIsRelative ? this.rule.value : moment(this.rule.value)
    }

    protected get currentDateTypeOperators(): RuleBuilderOperator[] {
        let operators: any[] = []

        if (this.currentDateTypeIsRelative) {
            operators = [baseOperators.date_in, baseOperators.date_not_in]
        } else {
            operators = [
                baseOperators.eq,
                baseOperators.neq,
                baseOperators.gt,
                baseOperators.gte,
                baseOperators.lt,
                baseOperators.lte,
            ]
        }

        return operators
    }

    public get currentOperatorValue(): string {
        const validOperators = this.currentDateTypeOperators
        let operator = this.getDateTypeDefaultOperator()

        if (!!this.rule.operator) {
            const ruleOperator = validOperators.find((o) => o.value === this.rule.operator)
            if (!!ruleOperator) operator = ruleOperator
        }

        return operator.value
    }

    protected getDateTypeOperators(dateType?: DateType): RuleBuilderOperator[] {
        let operators: any[] = []
        const dateTypeIsRelative = !!dateType ? dateType === 'relative' : this.currentDateTypeIsRelative

        if (dateTypeIsRelative) {
            operators = [baseOperators.date_in, baseOperators.date_not_in]
        } else {
            operators = [
                baseOperators.eq,
                baseOperators.neq,
                baseOperators.gt,
                baseOperators.gte,
                baseOperators.lt,
                baseOperators.lte,
            ]
        }

        return operators
    }

    protected getDateTypeDefaultOperator(dateType?: DateType): RuleBuilderOperator {
        return this.getDateTypeOperators(dateType)[0]
    }

    @autobind
    protected async handleDateTypeChange(dateType: DateType): Promise<void> {
        this.emitChange({
            value: dateType === 'relative' ? 'today' : moment().format('YYYY-MM-DD'),
            operator: this.getDateTypeDefaultOperator(dateType).value,
            meta: { range: dateType },
        })
    }

    @autobind
    protected async handleOperatorChange(operator: string): Promise<void> {
        this.emitChange({ operator })
    }

    @autobind
    protected async handleValueChange(value: any, valueString: any): Promise<void> {
        if (value === undefined || String(value) === '') return
        if (typeof value === 'object') value = value.format('YYYY-MM-DD')
        this.emitChange({ value })
    }

    protected async emitChange(changes: any): Promise<void> {
        const value = {
            operator: changes.operator || this.currentOperatorValue,
            value: changes.value || this.rule.value,
            meta: {
                ...this.rule.meta,
                ...changes.meta,
            },
        }

        this.props.onChange(value)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName, `type-${this.currentDateTypeState}`]

        return classNames.join(' ')
    }
}
