import { SendIntegrationPrivateKeyType } from '../enums/send-integration-private-key-type.enum'

export interface IApnsConfiguration {
    is_active: boolean
    expiration?: string | null
    push_package: {
        website_push_id: string
        icon_source_url: string
    }
    private_key: {
        private_key_file_url: string
        private_key_password?: string
    }
}

export enum SendIntegrationChannel {
    WEB = 'WEB',
    NATIVE = 'NATIVE',
}

export enum SendIntegrationProvider {
    VAPID = 'VAPID',
    APNS = 'APNS',
    FCM = 'FCM',
}

export enum FcmSendIntegrationApiVersion {
    LEGACY = 'LEGACY',
    V1 = 'V1',
}

export interface IAbstractSendIntegration {
    isActive: boolean
}

// Possible Combinations

// WEB VAPID - combination of domains.vapid_public_key + domains.vapid_private_key
export interface IWebVapidSendIntegration extends IAbstractSendIntegration {
    isActive: true // not currently able to disable this integration
    publicKy: string
    privateKey: string
}

// WEB APNS - existing domains.apns_configuration_json JSONB
export interface IWebApnsSendIntegration extends IAbstractSendIntegration {
    readonly expiration?: string // ISO8601 Date String
    privateKey: {
        privateKeyFileUrl: string
        privateKeyPassword: string | null
    }
    pushPackage: {
        websitePushId: string
        iconSourceUrl: string
    }
}

type RequireKeys<T, Key extends keyof T> = T & { [K in Key]-?: T[K] }

// NATIVE APNS - domains.native_apns_configuration JSONB
export interface IAbstractNativeApnsSendIntegration extends IAbstractSendIntegration {
    appBundleId?: string
    privateKeyFileUrl?: string
    privateKeyType?: SendIntegrationPrivateKeyType
    // P12 Only
    privateKeyPassPhrase?: string
    expiration?: string
    // P8 Only
    teamId?: string
    keyId?: string
}

export type P12NativeApnsSendIntegration = {
    is_active: true
    private_key_type: SendIntegrationPrivateKeyType.P12
} & Omit<IAbstractNativeApnsSendIntegration, 'teamId' | 'keyId'>

export type P8NativeApnsSendIntegration = {
    is_active: true
    private_key_type: SendIntegrationPrivateKeyType.P8
} & RequireKeys<
    Omit<IAbstractNativeApnsSendIntegration, 'app_bundle_id' | 'private_key_pass_phrase'>,
    'teamId' | 'keyId'
>
export type NativeApnsSendIntegration = P8NativeApnsSendIntegration | P12NativeApnsSendIntegration

// NATIVE FCM - domains.native_fcm_configuration JSONB
export interface IAbstractNativeFcmSendIntegration extends IAbstractSendIntegration {
    apiVersion?: FcmSendIntegrationApiVersion
    senderId?: string
    // LEGACY Only
    serverApiKey?: string
    // V1 Only
    serviceAccountJsonFileUrl?: string
}

export type NativeLegacyFcmSendIntegration = {
    is_active: true
    api_version: FcmSendIntegrationApiVersion.LEGACY
} & RequireKeys<Omit<IAbstractNativeFcmSendIntegration, 'serviceAccountJsonFileUrl'>, 'serverApiKey'>

export type NativeV1FcmSendIntegration = {
    is_active: true
    api_version: FcmSendIntegrationApiVersion.V1
} & RequireKeys<Omit<IAbstractNativeFcmSendIntegration, 'serverApiKey'>, 'serviceAccountJsonFileUrl'>

export type FcmSendIntegration = NativeLegacyFcmSendIntegration | NativeV1FcmSendIntegration

export interface IPushPackageConfiguration {
    website_push_id?: string
    icon_source_url?: string
}

export interface IAbstractApnsPrivateKeyConfiguration {
    private_key_file_url: string
    private_key_pass_phrase?: string
}
