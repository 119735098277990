import { DomainDto, IDomainGlobalPromptSettings, PromptReferrerAllowType } from '../dtos/domain'
import { AccountDto } from '../dtos/account.dto'

export function calculateFromPromptFcapSeconds(config: any): number {
    let promptFcap = config.fcapSeconds

    switch (config.displayMetric) {
        default:
        case 'days':
            promptFcap = promptFcap / 24
        case 'hours':
            promptFcap = promptFcap / 60
        case 'minutes':
            promptFcap = promptFcap / 60
    }

    return promptFcap
}

export function calculateToPromptFcapSeconds(values: any): number {
    let promptFcap = values.promptFcap

    switch (values.promptFcapDisplayMetric) {
        default:
        case 'days':
            promptFcap = promptFcap * 24
        case 'hours':
            promptFcap = promptFcap * 60
        case 'minutes':
            promptFcap = promptFcap * 60
    }

    return promptFcap
}

export function prepareGlobalPromptSettings(values: any, currentConfig: any): any {
    const config = {
        referrers: {
            enabled: false,
            whenBelf: PromptReferrerAllowType.IGNORE,
            whenBlank: PromptReferrerAllowType.ALLOW,
            persistTtlSeconds: 60 * 60 * 24 * 7,
            ...currentConfig?.referrers,
        },
    } as IDomainGlobalPromptSettings

    config.referrers!.enabled = values.referrerConditionsEnabled
    if (values.referrerConditionsEnabled) {
        config.referrers!.whenBlank = values.referrerWhenBlank || config.referrers!.whenBlank

        if (!values.referrerAllow || values.referrerAllow.length === 0) {
            delete config.referrers!.allowed
        } else {
            const exact = values.referrerAllow
                .filter((pack: any) => pack.type === 'exact')
                .map((pack: any) => pack.value)

            const contains = values.referrerAllow
                .filter((pack: any) => pack.type === 'contains')
                .map((pack: any) => pack.value)

            config.referrers!.allowed = config.referrers!.allowed || {}
            config.referrers!.allowed.exact = exact && exact.length > 0 ? exact : undefined
            if (config.referrers!.allowed.exact === undefined) {
                delete config.referrers!.allowed.exact
            }

            config.referrers!.allowed.contains = contains && contains.length > 0 ? contains : undefined
            if (config.referrers!.allowed.contains === undefined) {
                delete config.referrers!.allowed.contains
            }
        }

        if (!values.referrerBlock || values.referrerBlock.length === 0) {
            delete config.referrers!.blocked
        } else {
            const exact = values.referrerBlock
                .filter((pack: any) => pack.type === 'exact')
                .map((pack: any) => pack.value)

            const contains = values.referrerBlock
                .filter((pack: any) => pack.type === 'contains')
                .map((pack: any) => pack.value)

            config.referrers!.blocked = config.referrers!.blocked || {}
            config.referrers!.blocked.exact = exact && exact.length > 0 ? exact : undefined
            if (config.referrers!.blocked.exact === undefined) {
                delete config.referrers!.blocked.exact
            }

            config.referrers!.blocked.contains = contains && contains.length > 0 ? contains : undefined
            if (config.referrers!.blocked.contains === undefined) {
                delete config.referrers!.blocked.contains
            }
        }
    }

    return config
}

export function getFcapAndReferrerConfigs(entity: DomainDto | AccountDto): any {
    let defaultPromptFcapOccurrences = 1
    let defaultPromptFcapFrequency = 1
    let defaultPromptFcapDisplayMetric = 'days'
    let defaultReferrerAllowValue
    let defaultReferrerBlockValue
    let defaultReferrerWhenBlank = PromptReferrerAllowType.ALLOW

    if (entity) {
        if (entity.frequencyCaps) {
            const promptConfig = entity.frequencyCaps.prompt

            if (promptConfig) {
                defaultPromptFcapOccurrences = promptConfig.occurrences
                defaultPromptFcapFrequency = calculateFromPromptFcapSeconds(promptConfig)
                defaultPromptFcapDisplayMetric = promptConfig.displayMetric
            }
        }

        if (entity.globalPromptSettings) {
            const referrerConfig = entity.globalPromptSettings.referrers

            if (referrerConfig) {
                defaultReferrerWhenBlank = referrerConfig.whenBlank

                if (referrerConfig.allowed) {
                    defaultReferrerAllowValue = [].concat.apply(
                        [],
                        Object.keys(referrerConfig.allowed).map((type) =>
                            referrerConfig.allowed[type].map((value: string) => ({ type, value })),
                        ),
                    )
                }

                if (referrerConfig.blocked) {
                    defaultReferrerBlockValue = [].concat.apply(
                        [],
                        Object.keys(referrerConfig.blocked).map((type) =>
                            referrerConfig.blocked[type].map((value: string) => ({ type, value })),
                        ),
                    )
                }
            }
        }
    }

    return {
        defaultPromptFcapOccurrences,
        defaultPromptFcapFrequency,
        defaultPromptFcapDisplayMetric,
        defaultReferrerAllowValue,
        defaultReferrerBlockValue,
        defaultReferrerWhenBlank,
    }
}
