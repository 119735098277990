import { IChart, ILink, INode } from '@mrblenny/react-flow-chart'
import { ConditionType, LinkType, NodeType, PortId, PortType } from '../enums'
import clone from 'clone-deep'
import titleCase from 'title-case'
import { generateUID } from './uid'
import { CONDITION_BRANCH_ROOT, DEFAULT_CONDITION_BRANCH_ID } from '../constants'
import { getBranchId } from './conditons'

export const createNode = (nodeType: NodeType, properties?: any): INode => {
    const node: INode = {
        id: generateUID(),
        type: nodeType,
        ports: {
            [PortId.INPUT]: {
                id: PortId.INPUT,
                type: PortType.INPUT,
            },
        },
        properties: {
            ...properties,
        },
        position: {} as any,
    }

    if (nodeType !== NodeType.CAMPAIGN_EXIT) {
        if (nodeType === NodeType.CONDITION) {
            const conditionType = node.properties?.[NodeType.CONDITION]?.type

            if (conditionType === ConditionType.BOOLEAN) {
                node.ports[PortId.OUTPUT_POSITIVE] = {
                    id: PortId.OUTPUT_POSITIVE,
                    type: PortType.OUTPUT_POSITIVE,
                }

                node.ports[PortId.OUTPUT_NEGATIVE] = {
                    id: PortId.OUTPUT_NEGATIVE,
                    type: PortType.OUTPUT_NEGATIVE,
                }
            }
        } else {
            node.ports[PortId.OUTPUT] = {
                id: PortId.OUTPUT,
                type: PortType.OUTPUT,
            }
        }
    }

    return node
}

export const removeLinkErrors = <T extends ILink | Partial<ILink> | undefined>(link: T): T => {
    if (link) {
        // Reset any passed errors
        if (link.properties?.hasError) {
            delete link.properties.hasError
            delete link.properties.error
        }
    }

    return link
}

export const createNodeLink = (fromNode: INode, toNode: INode, props?: Partial<ILink>, preserveErrors?: boolean) => {
    return {
        ...(preserveErrors ? props : removeLinkErrors(props)),
        id: generateUID(),
        from: {
            nodeId: fromNode.id,
            portId: PortId.OUTPUT,
        },
        to: {
            nodeId: toNode.id,
            portId: PortId.INPUT,
        },
    }
}

export const addNodeLink = (
    fromNode: INode,
    toNode: INode,
    state: IChart,
    props?: Partial<ILink>,
    preserveErrors?: boolean,
): IChart => {
    if (!(toNode.id in state.nodes)) {
        state.nodes[toNode.id] = toNode
    }

    const link = createNodeLink(fromNode, toNode, props, preserveErrors)
    if (link.properties?.type === LinkType.POSITIVE) {
        link.from.portId = PortId.OUTPUT_POSITIVE
        link.marker = { from: 'check-circle' }
    } else if (link.properties?.type === LinkType.NEGATIVE) {
        link.from.portId = PortId.OUTPUT_NEGATIVE
        link.marker = { from: 'x-circle' }
    } else if (link.properties?.type === LinkType.BRANCH) {
        link.from.portId = link.properties?.branch ?? DEFAULT_CONDITION_BRANCH_ID
    }

    if (fromNode.type === NodeType.DELAY && toNode.type === NodeType.DELAY) {
        link.properties = {
            ...link.properties,
            hasError: true,
            error: 'Delays cannot be adjacent. Please remove one of the delays or add a step between them.',
        }
    }

    if (fromNode.type === NodeType.ACTION && toNode.type === NodeType.ACTION) {
        link.properties = {
            ...link.properties,
            hasError: true,
            error: 'Action Steps cannot be adjacent. Please remove one of the actions or add a step between them.',
        }
    }

    state.links[link.id] = link

    return state
}

export const addNode = (node: INode, originLink: ILink, state: IChart): IChart => {
    // Add node to node tree
    state.nodes[node.id] = node

    // Generate new fromLink
    const fromNode = state.nodes[originLink.from.nodeId]
    state = addNodeLink(fromNode, node, state, {
        properties: {
            ...originLink.properties,
        },
    })

    const toNode = state.nodes[originLink.to.nodeId!]
    if (!!toNode) {
        if (node.type !== NodeType.CONDITION) {
            // Add new standard branch toLink
            state = addNodeLink(node, toNode, state)
        } else {
            const conditionType = node.properties?.[NodeType.CONDITION]?.type

            if (conditionType === ConditionType.BRANCH) {
                // Add initial branch - inherits current children
                state = addConditionBranch(node, toNode, state, 1)
            } else if (conditionType === ConditionType.BOOLEAN) {
                // Add positive branch - inherits current children
                state = addConditionBranch(node, toNode, state, LinkType.POSITIVE)
                // Add negative branch - with exit node
                state = addConditionBranch(node, createNode(NodeType.CAMPAIGN_EXIT), state, LinkType.NEGATIVE)
            }
        }
    }

    delete state.links[originLink.id]

    return state
}

export const addConditionBranch = (
    conditionNode: INode,
    childNode: INode,
    state: IChart,
    branch: 'positive' | 'negative' | 'default' | number,
): IChart => {
    state = clone(state)

    // Ensure childNode is added to node tree
    if (!(childNode.id in state.nodes)) {
        state.nodes[childNode.id] = childNode
    }

    const isBranchType = branch !== 'positive' && branch !== 'negative'
    const branchId = getBranchId(branch)

    if (isBranchType) {
        // Ensure port is added
        if (!(branchId in conditionNode.ports)) {
            conditionNode.ports[branchId] = {
                id: branchId,
                type: PortType.BOTTOM,
            }
        }

        const props = conditionNode.properties?.[conditionNode.type]
        props[CONDITION_BRANCH_ROOT] = props[CONDITION_BRANCH_ROOT] ?? []
        const nextBranchIdx = props[CONDITION_BRANCH_ROOT].length

        props[CONDITION_BRANCH_ROOT].splice(nextBranchIdx, 0, {
            id: getBranchId(branch),
            name: branch === 'default' ? 'Default' : titleCase(getBranchId(branch)),
            params: {},
        })

        state.nodes[conditionNode.id] = conditionNode
    }

    const propKey = isBranchType ? 'branch' : 'type'
    state = addNodeLink(conditionNode, childNode, state, {
        properties: {
            type: isBranchType ? LinkType.BRANCH : null,
            [propKey]: isBranchType ? branchId : branch,
        },
    })

    return state
}

export const removeConditionBranch = (conditionNode: INode, branchId: string, state: IChart) => {
    state = clone(state)

    const conditionProps = conditionNode.properties[conditionNode.type]
    const branches: any[] = conditionProps[CONDITION_BRANCH_ROOT] ?? []

    if (branchId in conditionNode.ports) {
        const allLinks = Object.values(state.links)
        const branchLink = allLinks.find((l) => l.from.portId === branchId && l.from.nodeId === conditionNode.id)

        const branchNode = clone(state.nodes[branchLink!.to!.nodeId!])
        const branchIdx = branches.findIndex((c) => c.id === branchId)

        if (branchIdx !== -1) {
            state = removeNode(branchNode, state, true)

            branches.splice(branchIdx, 1)
            conditionNode.properties[conditionNode.type][CONDITION_BRANCH_ROOT] = branches

            delete conditionNode.ports[branchId]
            state.nodes[conditionNode.id] = conditionNode
        }

        state = redistributeConditionBranches(conditionNode, state)
    }

    return state
}

const redistributeConditionBranches = (conditionNode: INode, state: IChart) => {
    state = clone(state)

    const conditionProps = conditionNode.properties?.[conditionNode.type]
    const branches: any[] = conditionProps[CONDITION_BRANCH_ROOT] ?? []
    const defaultBranch = branches.find((c) => c.id === DEFAULT_CONDITION_BRANCH_ID)
    const nonDefaultBranches = branches.filter((c) => c.id !== DEFAULT_CONDITION_BRANCH_ID)

    const newConditionSet: any[] = []
    if (!!defaultBranch) {
        newConditionSet.push(defaultBranch)
    }

    if (nonDefaultBranches.length > 0) {
        nonDefaultBranches.forEach((b, idx) => {
            const branch = clone(b)
            const cid = branch.id
            const newId = getBranchId(idx + 1)

            if (cid !== newId) {
                const cname = titleCase(cid)
                const newName = titleCase(newId)

                const links = Object.values(state.links)
                const clink = clone(links.find((l) => l.from.portId === cid && l.from.nodeId === conditionNode.id))

                if (!!clink) {
                    // update link
                    clink.from.portId = newId
                    clink.properties.branch = newId
                    state.links[clink.id] = clink

                    // update branch and port
                    const cport = clone(conditionNode.ports[cid])
                    if (!!cport) {
                        delete conditionNode.ports[cid]
                        cport.id = newId

                        conditionNode.ports[cport.id] = cport
                        state.nodes[conditionNode.id] = conditionNode

                        branch.id = newId
                        if (branch.name === cname) {
                            // rename basic name
                            branch.name = newName
                        }
                    }
                }
            }

            newConditionSet.push(branch)
        })
    }

    conditionNode.properties[conditionNode.type][CONDITION_BRANCH_ROOT] = newConditionSet
    state.nodes[conditionNode.id] = conditionNode

    return state
}

export const removeNode = (
    node: INode,
    state: IChart,
    recurse: boolean = false,
    branchPreference?: string | 'all',
): any => {
    const fromLink = Object.values(state.links).find((l) => l.to.nodeId === node.id) as ILink
    const fromNode = state.nodes[fromLink!.from.nodeId]

    // Reset link errors
    removeLinkErrors(fromLink)

    if (node.type === NodeType.CONDITION) {
        const conditionType = node.properties?.[NodeType.CONDITION]?.type
        let conditionLinks = Object.values(state.links).filter((l) => l.from.nodeId === node.id)

        if (conditionType === ConditionType.BOOLEAN) {
            conditionLinks = conditionLinks.filter((l) => l.properties?.type !== branchPreference)
        } else if (conditionType === ConditionType.BRANCH) {
            conditionLinks = conditionLinks.filter((l) => l.properties?.branch !== branchPreference)
        }

        for (const cLink of conditionLinks) {
            const cNode = state.nodes[cLink!.to!.nodeId!]
            state = removeNode(cNode, state, true)
        }

        if (branchPreference === 'all') {
            // remove root condition node
            // and ensure new exit node
            state = addNode(createNode(NodeType.CAMPAIGN_EXIT), fromLink, removeNode(node, state))
        }
    }

    const toLink = Object.values(state.links).find((l) => l.from.nodeId === node.id)
    let toNode: INode

    // branch end nodes will not have a toLink
    if (toLink) {
        toNode = state.nodes[toLink!.to!.nodeId!]

        if (recurse) {
            // Remove all child nodes
            state = removeNode(toNode, state, recurse)
        } else {
            // handle sibling delays
            if (fromNode.type === NodeType.DELAY && toNode.type === NodeType.DELAY) {
                fromLink.properties = {
                    ...fromLink.properties,
                    hasError: true,
                    error: 'Delays cannot be adjacent. Please remove one of the delays or add a step between them.',
                }
            }

            if (fromNode.type === NodeType.ACTION && toNode.type === NodeType.ACTION) {
                fromLink.properties = {
                    ...fromLink.properties,
                    hasError: true,
                    error: 'Action Steps cannot be adjacent. Please remove one of the actions or add a step between them.',
                }
            }

            // Add new link if outgoing node is found
            state = addNodeLink(
                fromNode,
                toNode,
                state,
                {
                    properties: { ...fromLink.properties },
                },
                true,
            )
        }

        delete state.links[toLink.id]
    }

    // Delete original node & link
    delete state.nodes[node.id]
    delete state.links[fromLink.id]

    return state
}

export const updateNodeProps = (node: INode, props: any) => {
    return {
        ...node,
        properties: {
            ...node.properties,
            ...props,
        },
    }
}

export const updateNodeTypeProps = (node: INode, props: any) => {
    return updateNodeProps(node, {
        [node.type]: {
            ...node.properties?.[node.type],
            ...props,
        },
    })
}

export const updateNodeParams = (node: INode, params: any, override?: boolean) => {
    const update = override
        ? params
        : {
              ...node.properties?.[node.type]?.params,
              ...params,
          }

    return updateNodeTypeProps(node, {
        params: update,
    })
}

export const findAndUpdateNodeProps = (nodes: INode[], props: any, filter: (node: INode) => boolean) => {
    const nodeIndex = nodes.findIndex(filter)
    let update

    if (nodeIndex !== -1) {
        update = updateNodeProps(nodes[nodeIndex], props)
    }

    return update
}

export const findAndUpdateNodeTypeProps = (nodes: INode[], props: any, filter: (node: INode) => boolean) => {
    const nodeIndex = nodes.findIndex(filter)
    let update

    if (nodeIndex !== -1) {
        update = updateNodeTypeProps(nodes[nodeIndex], props)
    }

    return update
}

export const findAndUpdateNodeParams = (
    nodes: INode[],
    params: any,
    filter: (node: INode) => boolean,
    override?: boolean,
) => {
    const nodeIndex = nodes.findIndex(filter)
    let update

    if (nodeIndex !== -1) {
        update = updateNodeParams(nodes[nodeIndex], params, override)
    }

    return update
}
